import { $getNearestNodeOfType } from "@lexical/utils";
import {
  $getSelection,
  $isElementNode,
  $isLineBreakNode,
  $isRangeSelection,
  $isTabNode,
  $isTextNode,
  ElementNode,
} from "lexical";
import {
  ClauseNode,
  CustomListItemNode,
  CustomTableCellNode,
} from "../../../../nodes";
import { $isClauseNode } from "../../../../nodes/ClauseNode";
import { $createCustomParagraphNode } from "../../../../nodes/CustomParagraphNode";
import { handleSelectionDelete } from "../../../../utils/handleSelectionDelete";
import { selectionIsAtBeginningOfInline } from "../../../CanveoPlugin/utils/selectionIsAtBeginningOfInline";
import {
  PREVENT_EVENT_PROPAGATION,
  getTargetNodeFromSplitNodes,
  handleNodeDeletion,
} from "../../utils";
import { copyClauseWorkflows } from "../utils/copyClauseWorkflows";
import { getAppendableClauseChildNodes } from "../utils/getAppendableClauseChildNodes";
import { preventEventPropagation } from "../utils/preventEventPropagation";
import { propagateEvent } from "../utils/propagateEvent";
import { removeElementNode } from "../utils/removeElementNode";

/**
 * @param {KeyboardEvent} event
 * @param {RedlineData} defaultRedlineData
 * @returns {boolean}
 */
export function backspaceCommandHandler(event, defaultRedlineData) {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) return preventEventPropagation(event);

  if (selection.isCollapsed()) {
    const selectionFocusNode = selection.focus.getNode();

    // If the selection is an empty element node (e.g., paragraph or list without text) we delegate to
    // the default backspace behaviour.
    if (
      ($isElementNode(selectionFocusNode) &&
        selectionFocusNode.getTextContentSize() === 0) ||
      $isTabNode(selectionFocusNode) ||
      $isLineBreakNode(selectionFocusNode)
    ) {
      return propagateEvent();
    }

    // If the selection is collapsed and at the beginning of the line.
    if (selectionIsAtBeginningOfInline(selection)) {
      const selectionElementNode = $getNearestNodeOfType(
        selectionFocusNode,
        ElementNode
      );
      // If the selection is collapsed and at the beginning of the inline but there is indentation
      // we want to progressively remove that indentation (outdent) instead of handling the backspace.
      if (selectionElementNode && selectionElementNode.getIndent() > 0) {
        return propagateEvent();
      }

      // TODO: Consider using the selection focus node regardless of whether or not the selection is backwards.
      const node = (
        selection.isBackward() ? selection.focus : selection.anchor
      ).getNode();

      const selectionCustomListItemNode = $getNearestNodeOfType(
        node,
        CustomListItemNode
      );

      // If the selection is at the beginning of a list item which has no indentation (first list level),
      // we want to convert it into a regular paragraph.
      if (selectionCustomListItemNode && $isTextNode(node)) {
        let parent = /** @type {ElementNode} */ (node.getParent());

        // Save the current child nodes because we will be deleting the list and list item nodes.
        const listItemChildNodes = selectionCustomListItemNode.getChildren();

        // Find nearest clause node and delete its child (the CustomListNode).
        while (!$isClauseNode(parent)) {
          const newParent = /** @type {ElementNode} */ (parent.getParent());
          if ($isClauseNode(newParent)) {
            parent.remove();
          }
          parent = newParent;
        }

        const clauseNode = parent;

        // Create new paragraph, add the nodes of the list to it and then add everything to the Clause node.
        const paragraph = $createCustomParagraphNode();
        paragraph.append(...listItemChildNodes);
        clauseNode.append(paragraph);

        // We need to select the beginning of the first text node otherwise the selection stays of type
        // element and subsequent backspace behaviour (e.g., joining with the previous list or paragraph)
        // does not work correctly.
        const textNodes = parent.getAllTextNodes();
        if (textNodes.length > 0) textNodes[0].select(0, 0);

        return preventEventPropagation(event);
      }

      // If the selection is inside a table cell that means we do not have to do the clause
      // handling logic so we can just propagate the event and let the appropriate plugins
      // handle the enter behaviour.
      const selectionTableCell = $getNearestNodeOfType(
        node,
        CustomTableCellNode
      );
      if (selectionTableCell) return propagateEvent();

      const clauseNode = $getNearestNodeOfType(node, ClauseNode);
      if (clauseNode) {
        const previousClause = clauseNode.getPreviousSibling();

        if ($isClauseNode(previousClause)) {
          // If both the current clause and the previous are not empty.
          if (
            clauseNode.getTextContentSize() > 0 &&
            previousClause.getTextContentSize() > 0
          ) {
            previousClause.selectEnd();

            const nodes = getAppendableClauseChildNodes(clauseNode);
            copyClauseWorkflows(clauseNode, previousClause);
            clauseNode.remove();

            const elementNode = previousClause
              .getAllTextNodes()
              .at(0)
              ?.getParent();
            if ($isElementNode(elementNode)) {
              elementNode.append(...nodes);
            }
          }
          // If the previous clause is empty we remove it.
          else if (previousClause.getTextContentSize() === 0) {
            previousClause.remove();
          }
          // Otherwise, we select its end and if the current clause is
          // empty, we delete it.
          else {
            previousClause.selectEnd();
            if (clauseNode.getTextContentSize() === 0) {
              clauseNode.remove();
            }
          }
        }
      }
    }
    // Otherwise, we continue with the previous logic.
    else {
      selection.modify("extend", true, "character");

      if (
        selection.anchor.type === "element" ||
        selection.focus.type === "element"
      ) {
        removeElementNode(selection);
      } else {
        const nodes = selection.getNodes().filter((n) => $isTextNode(n));
        let nodeToSelect;
        const isAtBeginningOfListItemNode =
          $getNearestNodeOfType(
            selection.anchor.getNode(),
            CustomListItemNode
          ) && selection.anchor.offset === 0;
        const targetLength = isAtBeginningOfListItemNode ? 1 : nodes.length;
        for (let index = 0; index < targetLength; index++) {
          let node = nodes[index];

          if (nodes.length === 1) {
            const splitNodes = node.splitText(
              ...selection.getCharacterOffsets().reverse()
            );

            const targetNode = getTargetNodeFromSplitNodes(true, splitNodes);

            if (targetNode) {
              const handledNode = handleNodeDeletion(
                targetNode,
                defaultRedlineData
              );

              if (handledNode) {
                handledNode.select(0, 0);
              }
            }

            break;
          }

          const atBeginningOfArray = index === 0;
          const atEndOfArray = index === nodes.length - 1;

          let shouldDelete = true;
          if (atBeginningOfArray) {
            const nodes = node.splitText(selection.focus.offset);
            if (nodes.length === 1) {
              [node] = nodes;
              shouldDelete = false;
            } else {
              [, node] = nodes;
            }
          } else if (atEndOfArray) {
            [node] = node.splitText(selection.anchor.offset);
          }

          if (shouldDelete) {
            const handledNode = handleNodeDeletion(node, defaultRedlineData);

            // We want to bring the cursor to the beginning of the line we've just deleted, so we select the first node of the
            // line with the offset for both its anchor and focus set to 0.
            if (atBeginningOfArray && $isTextNode(handledNode)) {
              nodeToSelect = handledNode;
            }
          } else {
            node.select(selection.focus.offset, selection.focus.offset);
            event.preventDefault();
            return PREVENT_EVENT_PROPAGATION;
          }
        }

        if (nodes.length > 1) {
          if (nodeToSelect) {
            nodeToSelect.select(0, 0);
          } else {
            // If our selection end node is a proposed addition from the author it is removed so we lose the node to
            // select, so we must fetch a new selection and select again.
            const updatedSelection = $getSelection();
            if ($isRangeSelection(updatedSelection)) {
              updatedSelection.anchor.getNode().select(0, 0);
            }
          }
        }
      }
    }
  } else {
    handleSelectionDelete(selection, defaultRedlineData);
  }

  return preventEventPropagation(event);
}
