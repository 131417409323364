import { ElementNode } from "lexical";
import { CROSS_REF_NODE_TYPE } from "../utils/constants";

/**
 * @typedef {Object} CrossRefNodeMetadata
 * @property {boolean} isLink If it's a link, true. Otherwise, false.
 * @property {boolean} isUrl If it's external link, true. Otherwise, false.
 * @property {boolean} hasContext
 * @property {boolean} hasPosition
 * @property {boolean} isPageRef
 * @property {string?} [delimiter]
 */

/**
 * CrossRefNode holds information on the target reference and other metadata.
 */
export class CrossRefNode extends ElementNode {
  /** @type {CrossRefNodeMetadata} */
  __metadata;
  /** @type {boolean} */
  __isTarget;
  /** @type {string} */
  __target;
  /** @type {Array<*>} */
  __data;

  /**
   * @param {*} metadata
   * @param {*} target
   * @param {*} data
   * @param {*} isTarget
   * @param {string} [key]
   */
  constructor(metadata, target, data = [], isTarget = false, key) {
    super(key);
    this.__metadata = metadata;
    this.__isTarget = isTarget;
    this.__target = target;
    this.__data = data;
  }

  static getType() {
    return CROSS_REF_NODE_TYPE;
  }

  get isTarget() {
    const self = this.getLatest();
    return self.__isTarget;
  }

  set isTarget(value) {
    const self = this.getWritable();
    self.__isTarget = value;
  }

  getDataSize() {
    const self = this.getLatest();
    return self.__data.length;
  }

  /**
   * @returns {import("../types/sfdt").Inline[]}
   */
  getData() {
    const self = this.getLatest();
    return self.__data;
  }

  /**
   * @returns {string}
   */
  getTarget() {
    const self = this.getLatest();
    return self.__target;
  }

  getMetadata() {
    const self = this.getLatest();
    return self.__metadata;
  }

  /**
   * @param {*} target
   */
  setTarget(target) {
    const self = this.getWritable();
    self.__target = target;
    return this;
  }

  /**
   * @param {*} data
   */
  setData(data) {
    const self = this.getWritable();
    self.__data = data;
    return this;
  }

  /**
   * @param {*} node
   */
  static clone(node) {
    return new CrossRefNode(
      node.__metadata,
      node.__target,
      node.__data,
      node.__isTarget,
      node.__key
    );
  }

  //if empty it won't render a line break, if is not inline, it will
  isInline() {
    return true;
  }

  createDOM() {
    let element;
    if (this.__isTarget) {
      //if it's referenced by another node
      element = document.createElement("span");
      element.id = `${this.__target}`;
    } else {
      //if references other node
      element = document.createElement(this.__metadata?.isLink ? "a" : "span");
      element.classList.add("cross-ref");
      if (this.__metadata?.isLink) {
        element.role = "link";
        if (this.__metadata?.isUrl) {
          //if it's an external link
          element.setAttribute("href", `${this.__target}`);
        } else {
          element.setAttribute("data-href", `${this.__target}`);
        }
      }
    }
    return element;
  }

  updateDOM() {
    return true;
  }

  /**
   * @param {*} serializedNode
   */
  static importJSON(serializedNode) {
    return new CrossRefNode(
      serializedNode.metadata,
      serializedNode.target,
      serializedNode.data,
      serializedNode.isTarget,
      serializedNode.key
    );
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      metadata: this.getMetadata(),
      target: this.__target,
      data: this.__data,
      isTarget: this.__isTarget,
      type: CROSS_REF_NODE_TYPE,
    };
  }
}

/**
 * @param {CrossRefNodeMetadata} metadata text with the reference
 * @param {string} target if isTarget this is a self reference, else it's a reference to another
 * @param {Array<*>} [data] additional data
 * @param {boolean} [isTarget] is this node a target of a cross-reference
 */
export function $createCrossRefNode(
  metadata = {
    isLink: false,
    hasContext: false,
    hasPosition: false,
    isPageRef: false,
    isUrl: false, // If something breaks remove this.
  },
  target = "",
  data = [],
  isTarget = false
) {
  return new CrossRefNode(metadata, target, data, isTarget);
}

/**
 * @param {import("lexical").LexicalNode | null | undefined} node
 * @returns {node is CrossRefNode}
 */
export function $isCrossRefNode(node) {
  const isInstanceOfCrossRefNode = node instanceof CrossRefNode;
  return isInstanceOfCrossRefNode;
}
