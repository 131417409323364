import { Box, Container, Grid, Stepper, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CanveoCircularProgress, Header } from "../../components";
import CustomStep from "../../components/CustomStep";
import useWidth from "../../hooks/useWidth";
import { globalStore } from "../../state/store";
import AddDocumentSelector from "./components/AddDocumentSelector";
import AreaLabelSelector from "./components/AreaLabelSelector";
import AssigneesSelector from "./components/AssigneesSelector";
import RequestSelector from "./components/RequestSelector";
import { NewTicketContext } from "./contexts/NewTicketContext";
import { createTicket } from "./utils/createTicket";

const steps = [
  {
    title: "What area do you need support with?",
    subtitle:
      "This helps us organize your ticket and assign it to the right pipeline.",
    purpose: "AreaLabel",
    component: AreaLabelSelector,
  },
  {
    title: "Who would you like to assign the ticket to?",
    subtitle:
      "Select the appropriate initial assignee using the dropdown below.",
    purpose: "Assignee",
    component: AssigneesSelector,
  },
  {
    title: "What is your request?",
    subtitle:
      "Please describe your request in detail to enable the assignee to help you efficiently.",
    purpose: "Request",
    component: RequestSelector,
  },
  {
    title: "Do you have any additional documents that go with your request?",
    subtitle:
      "For example, any contract templates and/or exhibits that you received from your counterparty, or any other documents.",
    purpose: "Documents",
    component: AddDocumentSelector,
  },
];

export default function NewTicket() {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const navigate = useNavigate();
  const breakpoint = useWidth();

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [activeStep, setActiveStep] = useState(0);
  const [ticket, setTicket] = useState(createTicket());

  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  /**
   * @param {ReturnType<typeof createTicket>} ticket
   * @param {string} purpose
   * @returns {boolean}
   */
  function checkCanNext(ticket, purpose) {
    switch (purpose) {
      case "AreaLabel":
        return Boolean(ticket.areaLabelId);
      case "Assignee":
        return Boolean(ticket.assignee?._id);
      case "Request": {
        return Boolean(ticket.request.name && ticket.request.description);
      }
      case "Documents": {
        return true;
      }
      default: {
        throw new Error(`${purpose} is not a valid New Ticket step purpose.`);
      }
    }
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      setErrorMsg("");

      const task = {
        type: "Ticket",
        organization: state.user.orgID,
        assignee: ticket.assignee?._id,
        areaLabel: ticket.areaLabelId,
        request: ticket.request,
        requestor: state.user._id,
        documents: ticket.documents,
      };

      await axios.post(state.settings.api + "task", { task });
      navigate("/tasks");
    } catch (error) {
      console.error(error);
      setErrorMsg(
        "Unable to create the ticket, try again or contact Canveo Support if the issue persists."
      );
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CanveoCircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Header page={"New"} />

      <Container sx={{ my: 8, mx: "auto" }} maxWidth={breakpoint}>
        {/* {JSON.stringify(ticket)} */}

        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="stretch"
          sx={{ flexWrap: "nowrap" }}
        >
          <Grid item>
            <Box sx={{ mt: 7, mb: 4 }}>
              <Typography align="center" variant="h4">
                Create New Ticket
              </Typography>
              {errorMsg && (
                <Typography
                  align="center"
                  // @ts-ignore
                  variant="subtitle"
                  color="error"
                >
                  {errorMsg}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item>
            <NewTicketContext.Provider value={{ ticket, setTicket }}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map(
                  (
                    { title, subtitle, purpose, component: StepComponent },
                    index
                  ) => {
                    const canProceed = checkCanNext(ticket, purpose);
                    return (
                      <CustomStep
                        key={index}
                        stepIndex={index}
                        backText={""}
                        title={title}
                        subtitle={subtitle}
                        activeStep={activeStep}
                        handleBack={handleBack}
                        handleNext={
                          index !== steps.length - 1 ? handleNext : handleSubmit
                        }
                        canProceed={canProceed}
                        nextText={index === steps.length - 1 && "Create Ticket"}
                      >
                        <StepComponent active={activeStep === index} />
                      </CustomStep>
                    );
                  }
                )}
              </Stepper>
            </NewTicketContext.Provider>

            {/* {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleSubmit} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Paper>
            )} */}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
