import { faCircleArrowRight, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { v4 as uuid } from "uuid";
import { globalStore } from "../../../../../state/store";
import { baseValueTypes } from "../../../../MergeFieldMenu/dialogs/NewConditionDialog/constants";
import AgreementMetadataBaseValueType from "./baseValueTypes/agreementMetadataBaseValueType";
import MergeFieldBaseValueType from "./baseValueTypes/mergeFieldBaseValueType";
import PartyInformationBaseValueType from "./baseValueTypes/partyInformationBaseValueType";

/**
 * @typedef {object} NewConditionDialogProps
 * @property {import("./condition").Condition | null} condition
 * @property {string} mergeFieldValueType
 * @property {boolean} isList
 * @property {import("../../../../MergeFieldMenu/constants").ListOption[]} listOptions
 * @property {boolean} openNewConditionDialog
 * @property {() => void} closeNewConditionDialog
 * @property {boolean} isLoading
 * @property {string} docId
 * @property {(condition: import("./condition").Condition, type: "create" | "update") => void} submit
 * @property {*[]} exhibits
 */

/**
 * @param {NewConditionDialogProps} props
 */
export default function NewExhibitsConditionDialog({
  condition,
  mergeFieldValueType,
  isList,
  listOptions,
  openNewConditionDialog,
  closeNewConditionDialog,
  isLoading,
  docId,
  submit,
  exhibits,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [baseValueType, setBaseValueType] = useState(
    condition?.baseValueType ? condition.baseValueType : ""
  );
  const [mergeFields, setMergeFields] = useState(
    /** @type {import("../../../../editor/nodes/MarkNode").MergeField[]} */ ([])
  );

  const [baseValue, setBaseValue] = useState(
    condition?.baseValue
      ? condition.baseValue
      : /** @type {import("../../../../editor/nodes/MarkNode").MergeField | null} */ (
          null
        )
  );
  const [operator, setOperator] = useState(
    condition?.operator
      ? condition.operator
      : /** @type {{label: string; value: string;} | null}*/ (null)
  );

  // let defaultMergeFieldValue = getMergeFieldDefaultValue(mergeFieldValueType);

  // if (defaultMergeFieldValue.type === "date") {
  //   defaultMergeFieldValue = {
  //     ...defaultMergeFieldValue,
  //     value: new Date().toISOString(),
  //   };
  // } else if (defaultMergeFieldValue.type === "duration") {
  //   defaultMergeFieldValue = {
  //     ...defaultMergeFieldValue,
  //     durationUnit: { label: "Days", value: "days" },
  //   };
  // }

  // const [mergeFieldValue, setMergeFieldValue] = useState(
  //   condition?.mergeFieldValue
  //     ? condition.mergeFieldValue
  //     : defaultMergeFieldValue
  // );

  const [comparisonValue, setComparisonValue] = useState(
    condition?.comparisonValue
      ? condition.comparisonValue
      : /** @type {string[] | import("../../../../editor/nodes/MarkNode").MergeFieldValue} */ ([])
  );

  const [selectedExhibits, setSelectedExhibits] = useState(
    /** @type {*[]} */ ([])
  );

  const handleSubmit = async () => {
    if (!operator) throw new Error("Invalid operator.");
    if (!baseValue) throw new Error("Invalid base value.");

    const /** @type {Omit<import("../../../../MergeFieldMenu/dialogs/NewConditionDialog/condition").Condition, "mergeFieldValue"> & {selectedExhibits: *[]}} */ newCondition =
        {
          id: condition ? condition.id : uuid(),
          baseValueType,
          baseValue,
          operator,
          comparisonValue,
          selectedExhibits,
        };

    const response = await axios.post(
      state.settings.api + `template/${docId}/exhibits/condition`,
      {
        exhibitCondition: newCondition,
      }
    );
    if (!response) throw new Error("Error creating exhibit condition.");

    const createdCondition = response.data.data;

    submit(createdCondition, !condition ? "create" : "update");
  };

  const options = {
    docId: state.drawerVersions.active?._id,
    isList,
    listOptions,
    condition,
    mergeFields,
    setMergeFields,
    baseValue,
    setBaseValue,
    operator,
    setOperator,
    // mergeFieldValue,
    // setMergeFieldValue,
    comparisonValue,
    setComparisonValue,
    exhibits,
    setSelectedExhibits,
    selectedExhibits,
  };

  const resetStateForBaseValueType = () => {
    setBaseValue(null);
    setOperator(null);
    // setMergeFieldValue(defaultMergeFieldValue);
    setComparisonValue([]);
  };

  return (
    <Dialog
      open={openNewConditionDialog}
      onClose={closeNewConditionDialog}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>Add New Condition</DialogTitle>

      <Box
        sx={{
          position: "absolute",
          top: "11px",
          right: "12px",
        }}
      >
        <IconButton onClick={closeNewConditionDialog}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogContent>
        {isLoading ? (
          <Box>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <CircularProgress size={50} />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            sx={{
              outline: "grey dashed 1px",
              borderRadius: "25px",
            }}
          >
            <Grid container direction="row">
              <br />
            </Grid>

            <Grid container direction="row" justifyContent={"center"}>
              <b>If ...</b>
            </Grid>

            <Grid container direction="row">
              <br />
            </Grid>

            <Grid container direction="row" justifyContent={"center"}>
              <Autocomplete
                sx={{ width: "286px" }}
                options={baseValueTypes}
                autoHighlight
                openOnFocus={!condition}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                value={
                  baseValueTypes.find((x) => x.value === baseValueType) || null
                }
                onChange={(_, option) => {
                  if (option) {
                    setBaseValueType(option.value);
                  } else {
                    setBaseValueType("");
                  }

                  resetStateForBaseValueType();
                }}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      autoFocus={!condition}
                      label="Base Value Type"
                      placeholder="Select type of the base value ..."
                      variant="outlined"
                    />
                  </>
                )}
              />
            </Grid>

            <Grid container direction="row">
              <br />
            </Grid>

            {baseValueType === "mergeField" && (
              <MergeFieldBaseValueType {...options} />
            )}

            {baseValueType === "agreementMetadata" && (
              <AgreementMetadataBaseValueType {...options} />
            )}

            {baseValueType === "partyInformation" && (
              <PartyInformationBaseValueType {...options} />
            )}
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ marginRight: "auto" }}
          onClick={() => {
            closeNewConditionDialog();
          }}
        >
          Cancel
        </Button>

        <Button
          // disabled={
          //   !isCondition({
          //     id: "default",
          //     baseValue: baseValue || undefined,
          //     baseValueType,
          //     comparisonValue,
          //     // mergeFieldValue,
          //     operator: operator || undefined,
          //   })
          // }
          onClick={() => {
            handleSubmit();
            closeNewConditionDialog();
          }}
          endIcon={
            <FontAwesomeIcon
              icon={faCircleArrowRight}
              style={{
                fontSize: "14px",
              }}
            />
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
