import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import axios from "axios";
import {
  $createParagraphNode,
  $createRangeSelection,
  $getNodeByKey,
  $getRoot,
  $setSelection,
  COMMAND_PRIORITY_EDITOR,
  KEY_BACKSPACE_COMMAND,
} from "lexical";
import { useContext, useEffect } from "react";
import { globalStore } from "../../../state/store";
import { useOpenIssues } from "../../OpenIssueMenu";
import {
  ADD_WORKFLOW_COMMAND,
  APPLY_FILTER_COMMAND,
  ASSIGN_TOPICS_COMMAND,
  INSERT_FROM_LIBRARY,
  REMOVE_CLAUSETYPE_COMMAND,
  REMOVE_WORKFLOW,
  SAVE_TO_LIBRARY,
} from "../commands";
import { $createClauseNode, $isClauseNode } from "../nodes/ClauseNode";
import { $createRedlineNode } from "../nodes/RedlineNode";
import { hasMark, hasRedline } from "../utils";
import { PREVENT_EVENT_PROPAGATION } from "./TrackChangesPlugin/utils";

/**
 *
 * @param {*} editor
 * @param {*} props
 */
function useClauses(editor, props) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const { $navigateToNextOpenIssue } = useOpenIssues({
    editor,
    state,
    dispatch,
    currentOpenIssue: state.selectedOpenIssue,
  });

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        REMOVE_CLAUSETYPE_COMMAND,
        (/** @type {{ key: string; ctid: any; }} */ payload) => {
          editor.update(() => {
            const n = $getNodeByKey(payload.key);
            if (!n) throw new Error("Could not find node.");
            n.deleteClauseType(payload.ctid);
            let nextIssue;
            const isLastClauseType = !n.getClauseTypes().length;
            if (isLastClauseType) {
              //was the last clause type
              nextIssue = $navigateToNextOpenIssue();
              if (!nextIssue) {
                //no next open issue

                dispatch({
                  type: "NEW_OPEN_ISSUE_SELECTION",
                  payload: {
                    id: null,
                    type: null,
                    status: null,
                  },
                });
              }
            } else {
              dispatch({
                type: "NEW_OPEN_ISSUE_SELECTION",
                payload: {
                  id: n.id,
                  type: "info",
                  status: "ongoing",
                },
              });
            }
          });
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        SAVE_TO_LIBRARY,
        (/** @type {{ text: string; ctids: *[]; key: string; }} */ payload) => {
          let creationDate = new Date().toISOString();
          let newLibItem = {
            orgID: state.org._id,
            text: payload.text,
            clauseTypes: payload.ctids,
            // rating: payload.rating,
            creationBy: state.user._id,
            creationDate: creationDate,
            lastUpdateBy: state.user._id,
            lastUpdateDate: creationDate,
          };
          // Create the new Agreement type
          axios
            .post(state.settings.api + "clauselib", {
              clauseLibItem: newLibItem,
            })
            .then((resCLI) => {
              if (resCLI.data.success) {
                // Add newly created CLI to the reducer

                dispatch({
                  type: "ADD_CLAUSELIBITEM",
                  payload: resCLI.data.data,
                });

                editor.update(() => {
                  const node = $getNodeByKey(payload.key);
                  if (!node) throw new Error("Could not find node.");
                  node.addLibID(state.org._id + "_" + resCLI.data.data._id);
                });

                editor.dispatchCommand(ASSIGN_TOPICS_COMMAND, {
                  key: payload.key,
                  ctids: payload.ctids,
                  hide: true,
                });
              }
            })
            .catch((err) => {
              console.log("err saving to lib", err);
            });
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        ASSIGN_TOPICS_COMMAND,
        (
          /** @type {{key: string; ctids: string[]; hide: boolean}} */ payload
        ) => {
          editor.update(() => {
            const node = $getNodeByKey(payload.key);
            if (!node) return;
            node.assignClauseTypes(payload.ctids);
            const tmpClsItems = state.clauseOptions.activeClauseItems;
            tmpClsItems.cts = node.getClauseTypes();
            dispatch("SET_ACTIVECLAUSEITEMS", tmpClsItems);

            dispatch({
              type: "NEW_OPEN_ISSUE_SELECTION",
              payload: {
                id: node.id,
                type: "navigation",
                status: payload.hide ? "completed" : "ongoing",
              },
            });
          });
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        ADD_WORKFLOW_COMMAND,
        (/** @type {{ key: string; wfid: string; }} */ payload) => {
          editor.update(() => {
            const node = $getNodeByKey(payload.key);
            if (!$isClauseNode(node)) {
              throw new Error("Can only attach workflow to a clause node.");
            }

            node.addWorkflow(payload.wfid);
          });
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        REMOVE_WORKFLOW,
        (/** @type {{ key: string; wfid: any; }} */ payload) => {
          editor.update(() => {
            const node = $getNodeByKey(payload.key);
            if (!$isClauseNode(node)) {
              throw new Error("Can only remove workflow from a clause node.");
            }
            node.deleteWorkflow(payload.wfid);
          });
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        INSERT_FROM_LIBRARY,
        (/** @type {*} */ payload) => {
          const /** @type {import("../nodes/ClauseNode").ClauseNode | null } */ currentClauseNode =
              $getNodeByKey(payload.key);
          if (!currentClauseNode) throw new Error("Clause does not exist.");

          // Create clauseNode from details
          const creationDate = new Date().toISOString();

          const /** @type {import("../nodes/RedlineNode").RedlineMetadata} */ commonRedlineData =
              {
                // @ts-ignore
                date: creationDate,
                metadata: {
                  partyId: props.partyID,
                  creatorDisplayName: state.user.displayName,
                  creatorId: state.user._id,
                  creationDate: creationDate,
                  creatorEmail: state.user.email,
                  creatorPhotoUrl: state.user.photoURL,
                  // revisionId: uuid(),
                },
                partyId: props.partyID,
                text: payload.libItem.clauseText,
              };

          // @ts-ignore
          const clauseNode = $createClauseNode({
            clauseTypes: payload.libItem.clauseTypes,
            workflows: [],
            libIDs: [payload.libItem.libID],
            filter: "none",
            lock: "none",
          });

          // Create an "empty" clause node for (potentially) inbetween when inserting before/after,
          // @ts-ignore
          const dummyClauseNode = $createClauseNode({
            clauseTypes: [],
            workflows: [],
            libIDs: [],
            filter: "none",
            lock: "none",
          });

          payload.libItem.clauseText
            .split("\n") // For every paragraph:
            .forEach((/** @type {*} */ par) => {
              const parNode = $createParagraphNode();
              clauseNode.append(parNode);
              // @ts-ignore
              const redlineNode = $createRedlineNode({
                redlineType: "add",
                text: par,
                partyID: commonRedlineData.partyId,
                ...commonRedlineData,
              });
              parNode.append(redlineNode);
            });

          // Now insert the clause node - either replace or insert before or after.
          if (["insertAfter"].includes(payload.insertLocation)) {
            currentClauseNode.insertAfter(clauseNode);
            currentClauseNode.insertAfter(dummyClauseNode);
          } else if (["insertBefore"].includes(payload.insertLocation)) {
            currentClauseNode.insertBefore(clauseNode);
            currentClauseNode.insertBefore(dummyClauseNode);
          } else if (["replaceCurrent"].includes(payload.insertLocation)) {
            // Select current node entirely.
            const selection = $createRangeSelection();
            const childTextNodes = currentClauseNode.getAllTextNodes();
            const firstChild = childTextNodes[0];
            const lastChild = childTextNodes[childTextNodes.length - 1];
            selection.anchor.set(firstChild.getKey(), 0, "text");
            selection.focus.set(
              lastChild.getKey(),
              lastChild.getTextContentSize(),
              "text"
            );
            $setSelection(selection);

            editor.dispatchCommand(KEY_BACKSPACE_COMMAND);

            currentClauseNode.assignClauseTypes(payload.libItem.clauseTypes);
            // TODO: Check if inside table or list and act accordingly.
            // @ts-ignore
            currentClauseNode
              .getFirstChild()
              // @ts-ignore
              .append(...clauseNode.getFirstChild().getChildren()); // Insert redlines.
          }

          return PREVENT_EVENT_PROPAGATION;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        APPLY_FILTER_COMMAND,
        (/** @type {*} */ filter) => {
          // FILTER CHANGE

          editor.update(() => {
            $getRoot()
              .getChildren()
              .filter((elementNode) => elementNode.getType() === "clause")
              .forEach((clauseNode) => {
                switch (filter) {
                  case "openIssues":
                    if (
                      hasRedline(clauseNode) ||
                      hasMark(clauseNode, "publicComment")
                    ) {
                      clauseNode.changeFilter("in");
                    } else {
                      clauseNode.changeFilter("out");
                    }
                    break;

                  case "internalComments":
                    if (hasMark(clauseNode, "internalComment")) {
                      clauseNode.changeFilter("in");
                    } else {
                      clauseNode.changeFilter("out");
                    }
                    break;

                  case "publicComments":
                    if (hasMark(clauseNode, "publicComment")) {
                      clauseNode.changeFilter("in");
                    } else {
                      clauseNode.changeFilter("out");
                    }
                    break;

                  case "approvalCreation":
                    if (hasMark(clauseNode, "approvalCreation")) {
                      clauseNode.changeFilter("in");
                    } else {
                      clauseNode.changeFilter("out");
                    }
                    break;

                  case "approvalAssignee":
                    if (hasMark(clauseNode, "approvalAssignee")) {
                      clauseNode.changeFilter("in");
                    } else {
                      clauseNode.changeFilter("out");
                    }
                    break;

                  default:
                    // Remove all filters.
                    clauseNode.changeFilter("none");
                    break;
                }
              });
          });

          dispatch({ type: "CHANGE_SESSION_FILTER", payload: filter });
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);
}

/**
 * @param {*} props
 */
export default function ClausePlugin(props) {
  const [editor] = useLexicalComposerContext();
  useClauses(editor, props);
  return null;
}
