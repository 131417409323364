import { faXmark } from "@fortawesome/pro-light-svg-icons";
import {
  faCheckCircle,
  faCircle,
  faCircleArrowRight,
  faClipboard,
  faComments,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { $getSelection } from "lexical";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { getColorForPartyID } from "../utils";
import { createWorkflow } from "./editor/plugins/commenting";
import { PlainTextEditor } from "./editor/plugins/commenting/PlainTextEditor";
import { getClauseHTML, getClauseNode, useOnChange } from "./editor/utils";

/**
 * @typedef {*} CommentMenuProps
 */

/**
 * @param {CommentMenuProps} _
 */
function CommentMenu({
  isPrivate,
  selectedText,
  clauseKey,
  partyID,
  docID,
  isTemplate,
  editor,
  handleClose,
  createWorkflowAndAttachToDOM,
  createWorkflowAndAttachToClause,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [content, setContent] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [subscribers, setSubscribers] = useState(/** @type {*[]} */ ([]));
  const [visibleTo, setVisibleTo] = useState(/** @type {*[]} */ ([]));

  const onEscape = (/** @type {{ preventDefault: () => void; }} */ event) => {
    event.preventDefault();
    handleClose();
    return true;
  };

  const onChange = useOnChange(setContent, setCanSubmit, setSubscribers);

  const submitComment = () => {
    const clauseHTML = editor.getEditorState().read(() => {
      // @ts-ignore
      const anchor = $getSelection() ? $getSelection().anchor : null;
      const clauseNode = anchor ? getClauseNode(anchor.getNode()) : null;
      const html = clauseNode ? getClauseHTML(clauseNode) : null;
      return html ? html : null;
    });

    const quote =
      selectedText.length > 100
        ? selectedText.slice(0, 99) + "…"
        : selectedText;

    const prefix = isPrivate ? `cp${partyID.substring(5)}_` : "cp_";

    const doc = isTemplate
      ? state.templates.filter(
          (/** @type {{ _id: any; }} */ t) => t._id === docID
        )[0]
      : state.agrs.filter(
          (/** @type {{ _id: any; }} */ a) => a._id === docID
        )[0];

    const emailRecipients = subscribers
      .filter((sub) =>
        state.users.find((/** @type {{ _id: any; }} */ u) => u._id === sub._id)
      )
      .map((sub) => {
        // We filter before to make sure user exists.
        const { password, role } = state.users.find(
          (/** @type {{ _id: any; }} */ u) => u._id === sub._id
        );
        return {
          doc,
          recipient: { ...sub, role, password },
          content,
          isInternal: isPrivate,
          isTemplating: isTemplate,
          clauseHTML,
        };
      });

    // Insert creator as subscriber.
    if (!subscribers.find((s) => s._id === state.user._id)) {
      const { email, orgID, partyID, _id } = state.user;
      subscribers.unshift({ email, orgID, partyID, _id });
    }

    const workFlow = createWorkflow(
      isPrivate ? "ithread" : "pthread",
      prefix,
      quote,
      content,
      state.user,
      null,
      subscribers,
      state.drawerVersions.active._id,
      partyID,
      visibleTo.map((p) => p.orgID)
    );

    if (!isPrivate) {
      createWorkflowAndAttachToDOM(workFlow, emailRecipients, true);
    } else {
      createWorkflowAndAttachToClause(workFlow, emailRecipients, clauseKey);
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (isPrivate) setCanSubmit(content && visibleTo.length > 0);
    else setCanSubmit(!!content);
  }, [isPrivate, visibleTo, content]);

  useEffect(
    () => {
      const document = isTemplate
        ? state.template.find(
            (/** @type {{ _id: any; }} */ t) => t._id === docID
          )
        : state.agrs.find(
            (/** @type {{ _id: any; }} */ agr) => agr._id === docID
          );

      /**
       * @type {any}
       */
      const newParties = subscribers.reduce((prev, curr) => {
        let user = state.users.find(
          (/** @type {{ _id: any; }} */ u) => u._id === curr._id
        );

        if (user) return prev;

        user =
          document.collabs.find(
            (/** @type {{ uid: any; }} */ c) => c.uid === curr._id
          ) ??
          document.signers.find(
            (/** @type {{ uid: any; }} */ c) => c.uid === curr._id
          );

        if (!user) return prev;

        const party = state.parties.find(
          (/** @type {{ partyID: any; }} */ p) => p.partyID === user.partyID
        );
        if (party && !visibleTo.find((v) => v._id === party._id)) {
          return [...prev, party];
        }

        return prev;
      }, []);

      setVisibleTo((prev) => [...prev, ...newParties]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subscribers, isTemplate, docID, state.parties]
  );

  useEffect(
    () => {
      const orgParty =
        state.parties?.find(
          (/** @type {{ orgID: any; }} */ p) => p.orgID === state.org._id
        ) ?? state.subs[0];

      if (isPrivate && orgParty) setVisibleTo([orgParty]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPrivate, state.parties]
  );

  return (
    <Grid
      container
      sx={{
        width: "333px",
        position: "fixed",
        right: "20px",
        top: "75px",
        borderRadius: "30px",
        backgroundColor: "rgba(255,255,255,0.7)",
        backdropFilter: "blur(10px)",
        zIndex: "1260",
        border: "1px solid" + theme.palette.grey[200],
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 3px 24px 0px",
      }}
      p={3}
      pb={1}
    >
      <Grid container item direction="row-reverse">
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon icon={faXmark} style={{ fontSize: "14px" }} />
        </IconButton>
      </Grid>

      <Grid container alignItems="center" pb={4}>
        <Grid item pr={4}>
          <FontAwesomeIcon
            icon={!isPrivate ? faComments : faClipboard}
            style={{
              fontSize: "22px",
              color: theme.palette.grey[700],
            }}
          />
        </Grid>

        <Grid item>
          <Typography sx={{ fontWeight: 700 }}>
            Add {isPrivate ? "Private Note" : "Public Comment"}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        mb={4}
        sx={{
          maxHeight: "200px",
          overflow: "auto",
          userSelect: "text",
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
        }}
      >
        <Typography sx={{ color: theme.palette.grey[500] }}>
          "{selectedText}"
        </Typography>
      </Grid>

      <Grid item xs={12} pb={3}>
        {/* @ts-ignore */}
        <PlainTextEditor
          onEscape={onEscape}
          onChange={onChange}
          placeholder="Use @ to address other users"
          sx={{
            border: "1px solid" + theme.palette.grey[400],
            borderRadius: "15px",
            height: "75px",
          }}
          isTemplate={isTemplate}
          docID={docID}
        />
      </Grid>

      {isPrivate && (
        <Grid item xs={12} pb={3}>
          <Autocomplete
            options={state.parties}
            value={visibleTo}
            onChange={(e, newVal) => setVisibleTo(newVal)}
            getOptionLabel={(option) => option.legalName}
            multiple
            disableClearable
            disabled={isTemplate}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Visible To ..."
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <Checkbox
                  icon={<FontAwesomeIcon icon={faCircle} />}
                  checkedIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                  checked={visibleTo.some((c) => c._id === option._id)}
                />
                <Typography variant="body1" style={{ fontWeight: "500" }}>
                  {option.legalName}
                </Typography>
              </Box>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const isDisabled =
                  option?.orgID === state.org._id ||
                  getTagProps({ index }).disabled;

                const color = getColorForPartyID(
                  isTemplate ? "party0" : option?.partyID
                );
                return (
                  <Chip
                    {...getTagProps({ index })}
                    disabled={isDisabled}
                    sx={{
                      border: "1px solid" + color,
                      opacity: "1!important",
                      "& .MuiChip-deleteIcon": {
                        color: isDisabled ? theme.palette.grey[300] : color,
                        "&:hover": { color: theme.palette.grey[800] },
                      },
                    }}
                    avatar={
                      <Avatar
                        src={option?.logoURL}
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "unset",
                        }}
                      />
                    }
                    label={option?.legalName}
                  />
                );
              })
            }
            getOptionDisabled={(option) => option.orgID === state.org._id}
          />
        </Grid>
      )}

      <Grid container item justifyContent="space-between">
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={submitComment}
          disabled={!canSubmit}
          endIcon={
            <FontAwesomeIcon
              icon={faCircleArrowRight}
              style={{
                fontSize: "14px",
              }}
            />
          }
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}

export default CommentMenu;
