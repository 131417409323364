import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import {
  faCodeMerge,
  faPenToSquare,
  faRefresh,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import theme from "../../../../../theme/theme";
import DialogNewClauseVariant from "../../../../dialogs/DialogNewClauseVariant";
import VariantsTabPanel from "./components/VariantsTabPanel";

/**
 * @typedef {object} VariantsMenuProps
 * @property {ClauseVariant[]} variants
 * @property {(variant: ClauseVariant) => void } valueChange
 */

/**
 * @param {VariantsMenuProps} props
 * @returns {JSX.Element}
 */
export default function VariantsMenu({ variants, valueChange }) {
  const [value, setValue] = useState(0);
  const [openDialogNewClauseVariant, setOpenDialogNewClauseVariant] =
    useState(false);

  /**
   * @param {React.SyntheticEvent} _event
   * @param {number} newValue
   */
  function handleValueChange(_event, newValue) {
    setValue(newValue);
    valueChange(variants[newValue]);
  }

  function closeDialogNewClauseVariant() {
    setOpenDialogNewClauseVariant(false);
  }

  return (
    <>
      <Grid
        container
        sx={{
          marginTop: "10px",
          borderTop: "1px dotted rgba(0, 0, 0, 0.26)",
        }}
      />

      <Box width="100%" px={2} mt={4}>
        <Grid container gap={2} alignItems="center">
          <Grid item>
            <FontAwesomeIcon
              icon={faCodeMerge}
              color={theme.palette.primary.main}
              fontSize="20px"
            />
          </Grid>

          <Grid item>
            <Typography variant="subtitle1" color="primary">
              Clause Variants
            </Typography>
          </Grid>
        </Grid>

        <Tooltip title="Add variant">
          <IconButton
            style={{
              position: "relative",
              left: "200px",
              top: "40px",
              zIndex: 1000,
            }}
            onClick={() => setOpenDialogNewClauseVariant(true)}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              fontSize="15px"
              color={theme.palette.primary.main}
            />
          </IconButton>
        </Tooltip>

        <DialogNewClauseVariant
          documentId={""}
          open={openDialogNewClauseVariant}
          close={closeDialogNewClauseVariant}
          clauseText={""}
          submit={(_clauseVariant) => {}}
        />

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleValueChange}>
            {variants.map((variant, index) => {
              return <Tab key={index} label={variant.name} />;
            })}
          </Tabs>
        </Box>

        <VariantsTabPanel variant={variants[value]} />

        <Grid item mt={6}>
          <Grid container justifyContent={"right"}>
            <Grid item>
              <Tooltip title="Apply this variant now">
                <IconButton>
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    color={theme.palette.primary.main}
                    fontSize="20px"
                  />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip title="Refresh - check conditions">
                <IconButton>
                  <FontAwesomeIcon
                    icon={faRefresh}
                    color={theme.palette.primary.main}
                    fontSize="20px"
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
