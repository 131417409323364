import { Popper } from "@mui/material";
import React from "react";

/**
 * @param {import("@mui/material").PopperProps} props
 * @returns {React.JSX.Element}
 */
export default function FitContentPopper(props) {
  return (
    <Popper
      {...props}
      style={{
        width: "fit-content",
      }}
      placement="bottom-start"
    />
  );
}
