import { faDownload, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import docx from "../assets/img/docx.png";
import pdf from "../assets/img/pdf.png";
import xlsx from "../assets/img/xlsx.png";
import { globalStore } from "../state/store";
import getFileNameFromPathBuilder from "./PathBuilder/utils/getFilenameFromPathBuilder";

const StyledImage = styled("img", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "disabled",
  // @ts-ignore
})(({ disabled }) => ({
  margin: "0px 40px 0px 40px",
  height: "120px",
  width: "auto",
  "&:hover": {
    cursor: !disabled && "pointer",
  },
  opacity: disabled ? "0.5" : "1",
}));

/**
 * @typedef {object} ExportDocumentDialogProps
 * @property {boolean} openDialog
 * @property {boolean} isLoading
 * @property {() => void} closeDialog
 * @property {(event: string, payload: Record<string, string>) => void} onEvent
 * @property {any} agr
 * @property {string} versionType
 * @property {boolean} isTemplate
 * @property {boolean} isAgreementOwner
 * @property {"read" | "full" } editMode
 */

/**
 * Dialog component used to export templates and agreements to PDF or Word files inside the editor.
 *
 * @param {ExportDocumentDialogProps} props
 * @returns {JSX.Element}
 */
export default function ExportDocumentDialog({
  openDialog,
  isLoading,
  closeDialog,
  onEvent,
  agr,
  versionType,
  isTemplate,
  isAgreementOwner,
  editMode,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [docType, setDocType] = useState("pdf");
  const [filename, setFilename] = useState("");

  useEffect(
    () => {
      if (!openDialog) return;

      getFileNameFromPathBuilder(agr, state, isTemplate).then((filename) =>
        setFilename(filename)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openDialog]
  );

  const fileNameIsEmpty = () => {
    return filename === "";
  };

  const exportDocument = () => {
    onEvent("Export", { type: docType, filename, agrId: agr._id });
  };

  const close = () => {
    setDocType("pdf");
    closeDialog();
  };

  const getExportDocumentInfoLabel = () => {
    // If we are exporting a Word document.
    if (docType === "docx") {
      if (isAgreementOwner) {
        return 'The exported Microsoft Word file will be protected for tracked changes, with the password "canveo".';
      }
      // If the user belongs to a counterparty.
      else {
        if (editMode === "read") {
          return "The exported Microsoft Word file will have read-only protection.";
        } else {
          return "The exported Microsoft Word file will be protected for tracked changes.";
        }
      }
    }

    // If we are exporting a PDF document.
    if (agr.agrStatus === "InEffect") {
      return "This will export the signed version of this agreement.";
    } else {
      return "If this contract contains tracked changes or comments, those will be accepted and removed in the exported PDF file.";
    }
  };

  return (
    <Dialog open={openDialog} onClose={close} fullWidth={true} maxWidth="sm">
      <DialogTitle>Export Agreement</DialogTitle>

      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogContent>
        {isLoading ? (
          <Box>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <CircularProgress size={50} />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <Tooltip
                  title={
                    agr.agrStatus === "InEffect"
                      ? "Export Signed Agreement"
                      : "Export to PDF"
                  }
                  arrow
                  placement="bottom"
                  open={docType === "pdf"}
                >
                  <StyledImage
                    src={pdf}
                    alt="Export to PDF"
                    onClick={() => setDocType("pdf")}
                  />
                </Tooltip>
              </Grid>

              {(versionType === "docx" || versionType === "canveo") && (
                <Grid item>
                  <Tooltip
                    title="Export to MS Word"
                    arrow
                    placement="bottom"
                    open={docType === "docx"}
                  >
                    <StyledImage
                      src={docx}
                      alt="Export to MS Word"
                      onClick={() => setDocType("docx")}
                    />
                  </Tooltip>
                </Grid>
              )}

              {versionType === "xlsx" && (
                <Grid item>
                  <Tooltip
                    title="Export to MS Excel"
                    arrow
                    placement="bottom"
                    open={docType === "xlsx"}
                  >
                    <StyledImage
                      src={xlsx}
                      alt="Export to MS Excel"
                      onClick={() => setDocType("xlsx")}
                    />
                  </Tooltip>
                </Grid>
              )}
            </Grid>

            <Grid
              container
              direction="row"
              style={{ marginTop: "50px", textAlign: "left", width: "100%" }}
            >
              {docType !== "xlsx" && (
                <Alert
                  severity="info"
                  style={{ fontSize: "14px", width: "100%" }}
                >
                  <AlertTitle>
                    <b>Info</b>
                  </AlertTitle>
                  <b>{getExportDocumentInfoLabel()}</b>
                </Alert>
              )}
            </Grid>
            <Grid
              container
              direction="row"
              style={{ marginTop: "20px", textAlign: "left" }}
            >
              <TextField
                error={fileNameIsEmpty()}
                required
                fullWidth
                id="standard-helperText"
                label="Exported Filename"
                value={filename}
                helperText={
                  fileNameIsEmpty()
                    ? "Filename cannot be empty."
                    : "You do not need to include the file extension."
                }
                onChange={(event) => {
                  setFilename(event.target.value);
                }}
              />
            </Grid>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          disabled={fileNameIsEmpty() || isLoading}
          disableElevation
          color="primary"
          variant="contained"
          onClick={exportDocument}
        >
          Export&nbsp;&nbsp;
          <FontAwesomeIcon icon={faDownload} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
