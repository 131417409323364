/**
 * @param {"read" | "comment" | "edit" | "full" | "currentOrg" | "none" | "tbd" | "copy" | string & {}} value
 */
export default function getEditModeOption(value) {
  let editModeOption = { title: "", desc: "", desc1: "", desc2: "" };

  if (value === "read") {
    editModeOption.title = "Read-only";
    editModeOption.desc1 = "This party can only view/sign the agreement. ";
    editModeOption.desc2 =
      "Its collaborators will receive updates via notifications.";
    editModeOption.desc = editModeOption.desc1 + editModeOption.desc2;
  } else if (value === "comment") {
    editModeOption.title = "Comment-only";
    editModeOption.desc1 = "This party can add comments only. ";
    editModeOption.desc2 =
      "Its collaborators will receive updates via notifications.";
    editModeOption.desc = editModeOption.desc1 + editModeOption.desc2;
  } else if (value === "edit") {
    editModeOption.title = "Limited editing";
    editModeOption.desc1 =
      "This party can make tracked changes (but not accept their own changes). ";
    editModeOption.desc2 =
      "Its collaborators will receive updates via notifications.";
    editModeOption.desc = editModeOption.desc1 + editModeOption.desc2;
  } else if (value === "full") {
    editModeOption.title = "Full editing";
    editModeOption.desc1 =
      "This party can make and accept any tracked changes (including their own). ";
    editModeOption.desc2 =
      "Its collaborators will receive updates via notifications.";
    editModeOption.desc = editModeOption.desc1 + editModeOption.desc2;
  } else if (value === "currentOrg") {
    editModeOption.title = "Full editing";
    editModeOption.desc1 = "This party is the agreement owner. ";
    editModeOption.desc2 =
      "It has full edit authorization, and its collaborators will receive updates via notifications.";
    editModeOption.desc = editModeOption.desc1 + editModeOption.desc2;
  } else if (value === "none") {
    editModeOption.title = "Not involved";
    editModeOption.desc1 = "This party is not involved in this turn. ";
    editModeOption.desc2 =
      "Its collaborators will not receive any notifications.";
    editModeOption.desc = editModeOption.desc1 + editModeOption.desc2;
  } else if (value === "tbd") {
    editModeOption.title = "To be confirmed";
    editModeOption.desc1 = "Please select an edit access mode, ";
    editModeOption.desc2 = "for the counterparty";
    editModeOption.desc = editModeOption.desc1 + editModeOption.desc2;
  } else if (value === "copy") {
    editModeOption.title = "Copy-only";
    editModeOption.desc1 = "Counterparty has access to a copy ";
    editModeOption.desc2 = "of the shared agreement";
    editModeOption.desc = editModeOption.desc1 + editModeOption.desc2;
  }

  return editModeOption;
}
