import {
  faArrowLeft,
  faFileCirclePlus,
  faFileLines,
  faHashtag,
  faTag,
  faTimes,
  faUpload,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import {
  ButtonGroupPills,
  CanveoCircularProgress,
  SelectLabels,
  SelectOrCreateAgrType,
} from ".";
import docx from "../assets/img/docx.png";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { randomString as generateRandomString } from "../utils";
import { getOrigin } from "../utils/agreementCreation";
import { fileHasAllowedExtension } from "../utils/fileHasAllowedExtension";
import FileConversionErrorDetails from "./FileConversionErrorDetails";
import { convertSfdtToLexical } from "./editor/utils";

/**
 * @param {{ closeDialog: () => void; open: boolean; }} props
 */
export default function DialogTemplate(props) {
  const styles = {
    labelChipSelect: {
      padding: "1px 3px",
      margin: "0px 3px 3px 0px",
      fontSize: "12px",
      fontWeight: "600",
    },
  };

  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [loading, setLoading] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [originalSfdt, setOriginalSfdt] = React.useState({});
  const [errMsg, setErrMsg] = React.useState(
    /** @type {string | null} */ (null)
  );

  const [conversionError, setConversionError] = React.useState(
    /** @type {Error | undefined} */ (undefined)
  );
  const [dropHover, setDropHover] = React.useState(false);
  const [stagedTemplate, setStagedTemplate] = React.useState({
    source: "import",
    agrType: null,
    agrTypeID: null,
    sourceID: null,
    reference: "",
    labels: [],
  });
  const [templateOptions, setTemplateOptions] = React.useState([]);

  useEffect(() => {
    // Initialize the templateOptions searchBox - assign name from Agreement Type
    const topts = [];
    state.templates.forEach((st) => {
      let at =
        state.agrTypes.filter((at) => at._id === st.agrTypeID)[0] !==
          undefined &&
        state.agrTypes.filter((at) => at._id === st.agrTypeID)[0].fullName !==
          undefined
          ? state.agrTypes.filter((at) => at._id === st.agrTypeID)[0]
          : null;
      if (at !== null) {
        let activeString = st.active ? "active" : "draft";
        let labelString = "";
        st.labelIDs.forEach((lid) => {
          let l = state.labels.filter((l) => l._id === lid)[0];
          if (l !== undefined && l.name !== undefined) {
            labelString = labelString + l.name + ",";
          }
        });
        topts.push({
          _id: st._id,
          agrTypeID: at._id,
          name: at.fullName[0],
          shortName: at.shortName,
          reference: st.reference,
          active: st.active,
          version: st.version,
          labelIDs: st.labelIDs,
          activeString: activeString,
          labelString: labelString,
        });
      }
    });
    setTemplateOptions(topts.sort((a, b) => (a.name > b.name ? 1 : -1)));
  }, [state.templates, state.agrTypes, state.labels]);

  const reInitalize = () => {
    setConversionError(undefined);
    setLoading(false);
    setErrMsg(null);
    setDropHover(false);
    setStagedTemplate({
      source: "import",
      agrType: null,
      agrTypeID: null,
      sourceID: null,
      reference: "",
      labels: [],
    });
  };

  const closeDialog = (snack) => {
    props.closeDialog(snack);
    reInitalize();
  };

  const validExtensions = [".doc", "docx"];

  const handleUploadChange = async (/** @type {File} */ file) => {
    setErrMsg(null);
    setLoading(true);

    // Native file picker allows choosing files with any extension even if you
    // configured the input to only allow certain types. We currently only allow
    // uploading .doc or docx files.
    const fileHasValidExtension = fileHasAllowedExtension(
      file,
      validExtensions
    );

    if (!fileHasValidExtension) {
      setErrMsg("Invalid file type");
      setLoading(false);
      return;
    }

    try {
      const uploadSignedUrlResponse = await axios.post(
        state.settings.api + "upload/signedUrl",
        {
          contentType: file.type,
          bucketAlias: "documents",
        }
      );

      const uploadSignedUrl = uploadSignedUrlResponse.data.data.uploadSignedUrl;
      const fileName = uploadSignedUrlResponse.data.data.key;

      await fetch(uploadSignedUrl, { method: "PUT", body: file });

      const importDocumentResponse = await axios.get(
        `${state.settings.api}document/import/${fileName}`
      );

      const sfdt = importDocumentResponse.data.data;
      setOriginalSfdt(sfdt);

      const importedAggreement = await convertSfdtToLexical(sfdt, state);

      const stagedTemplate = {
        file: fileName,
        fileName: file.name,
        uploadType: "import",
        source: "import",
        sourceID: fileName,
        agrType:
          Boolean(importedAggreement) && Boolean(importedAggreement.agrTypeID)
            ? state.agrTypes.filter(
                (at) => at._id === importedAggreement.agrTypeID
              )[0]
            : null,
        agrTypeID:
          Boolean(importedAggreement) && Boolean(importedAggreement.agrTypeID)
            ? importedAggreement.agrTypeID
            : null,
        reference: "",
        labels: state.labels.map(
          (/** @type {{ _id: string; }} */ label) => label._id
        ),
        content:
          Boolean(importedAggreement) && Boolean(importedAggreement.content)
            ? importedAggreement.content
            : [],
        contentMetadata: {
          listsStructure: importedAggreement.listsStructure,
        },
        firstPageHeader: importedAggreement.header,
        firstPageFooter: importedAggreement.footer,
        sfdt: sfdt,
      };

      setStagedTemplate(stagedTemplate);
    } catch (error) {
      if (error instanceof Error) {
        setConversionError(error);
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleLabelSelect = (newLabels) => {
    let newlids = [];
    newLabels.forEach((nl) => {
      newlids.push(nl._id);
    });
    setStagedTemplate({ ...stagedTemplate, labels: newlids });
  };

  const handleSourceSelect = async (newVal) => {
    let st = {
      source: "copy",
      sourceID: newVal._id,
      agrType: state.agrTypes.filter((at) => at._id === newVal.agrTypeID)[0],
      agrTypeID: newVal.agrTypeID,
      reference: "",
      labels: newVal.labelIDs,
    };

    const result = await axios.get(
      state.settings.api + "template/" + newVal._id
    );
    const template = result?.data?.data?.template;

    if (template) {
      st.content = template.content;
      st.sfdt = template.sfdt;
      setOriginalSfdt(template.sfdt);
    }

    setStagedTemplate(st);
  };

  const handleAgrTypeChange = (changeType, value) => {
    if (["newName"].includes(changeType)) {
      // You're creating a new Agr Type => defining name
      setStagedTemplate({
        ...stagedTemplate,
        agrType: {
          newName: value,
          shortName:
            stagedTemplate.agrType.newName !== undefined &&
            stagedTemplate.agrType.shortName !== undefined
              ? stagedTemplate.agrType.shortName
              : "",
        },
      });
    } else if (["shortName"].includes(changeType)) {
      // You're creating a new Agr Type => defining short name
      setStagedTemplate({
        ...stagedTemplate,
        agrType: { ...stagedTemplate.agrType, shortName: value },
      });
    } else if (["agrType"].includes(changeType)) {
      // You've picked an existing Agr Type
      setStagedTemplate({ ...stagedTemplate, agrType: value });
    }
  };

  const handleCreateTemplate = () => {
    setLoading(true);
    let newTemplate = {
      blueprintID: generateRandomString(20),
      defaultChildren: [],
      reference: stagedTemplate.reference,
      orgID: state.org._id,
      content: stagedTemplate.content, // TODO => Fill up content based on previous template
      contentMetadata: stagedTemplate.contentMetadata, // TODO => Fill up content based on previous template
      active: false,
      effectiveDateType: "signoff",
      renewalType: "none",
      effectiveTerm: null,
      version: "001",
      firstPageHeader: stagedTemplate.firstPageHeader,
      firstPageFooter: stagedTemplate.firstPageFooter,
      sfdt: stagedTemplate.sfdt,
      labelIDs: stagedTemplate.labels,
      creationBy: state.user._id,
      creationDate: new Date().toISOString(),
      lastUpdateBy: state.user._id,
      lastUpdateDate: new Date().toISOString(),
      priority: 0,
      origin: getOrigin(stagedTemplate),
    };

    if (
      stagedTemplate.agrType !== undefined &&
      stagedTemplate.agrType !== null &&
      stagedTemplate.agrType.newName !== undefined &&
      stagedTemplate.agrType.shortName !== undefined &&
      stagedTemplate.agrType.newName !== null &&
      stagedTemplate.agrType.shortName !== null &&
      stagedTemplate.agrType.newName.length > 1 &&
      stagedTemplate.agrType.shortName.length > 1
    ) {
      // Creating a new Agreement Type
      let newat = {
        fullName: stagedTemplate.agrType.newName,
        shortName: stagedTemplate.agrType.shortName,
        orgID: state.org._id,
        active: true,
      };
      // Create the new Agreement type
      axios
        .post(state.settings.api + "agrtype", { agrType: newat })
        .then((resAgrt) => {
          if (resAgrt.data.success) {
            // Add newly created agrType to the reducer
            // Create with the new AgrType
            dispatch({ type: "ADD_AGRTYPE", payload: resAgrt.data.data });
            pushTemplate(resAgrt.data.data, newTemplate);
          } else {
            setErrMsg(
              "An error occured while creating the agreement type - refresh your browser"
            );
            setLoading(false);
          }
        })
        .catch((err) => {
          setErrMsg("An error occured while creating the agreement type");
          setLoading(false);
        });
    } else if (
      stagedTemplate.agrType !== undefined &&
      stagedTemplate.agrType !== null &&
      stagedTemplate.agrType.fullName !== undefined &&
      stagedTemplate.agrType.shortName !== undefined
    ) {
      // Selected an Agreement Type
      pushTemplate(stagedTemplate.agrType, newTemplate);
    } else {
      setErrMsg("Please re-select the agreement type");
      setLoading(false);
    }
  };

  const pushTemplate = (at, newtempl) => {
    newtempl.agrTypeID = at._id;
    newtempl.agrTitle = at.fullName[0];
    newtempl.agrShortName = at.shortName;
    axios
      .post(state.settings.api + "template", { template: newtempl })
      .then((resTemp) => {
        if (resTemp.data.success) {
          // Add newly created template to the reducer
          dispatch({ type: "ADD_TEMPLATES", payload: resTemp.data.data });
          setLoading(false);
          closeDialog("snackTemplateCreated");
        } else {
          setErrMsg(
            "An error occured while creating the template - refresh your browser"
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setErrMsg("An error occured while creating the template");
        setLoading(false);
      });
  };

  return (
    <div>
      <Dialog open={props.open} onClose={closeDialog} fullWidth maxWidth="sm">
        <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
          <IconButton onClick={closeDialog}>
            <FontAwesomeIcon
              icon={faTimes}
              style={{ padding: "4px 7px", fontSize: "20px" }}
            />
          </IconButton>
        </Box>
        <DialogTitle>
          {loading ? "Loading ..." : "Create a new Template"}
        </DialogTitle>
        <DialogContent>
          {conversionError ? (
            <FileConversionErrorDetails conversionError={conversionError} />
          ) : (
            <>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 14,
                  }}
                >
                  <CanveoCircularProgress />
                </Box>
              ) : // Step 2 - select the target type, ref and labels
              stagedTemplate.sourceID ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 8,
                    mb: 6,
                  }}
                >
                  <Grid container direction="column" alignItems="center">
                    {errMsg !== null ? ( // An error exists - show it
                      <Grid item>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            my: 4,
                          }}
                        >
                          <Typography align="center" color="error">
                            {errMsg}
                          </Typography>
                        </Box>
                      </Grid>
                    ) : (
                      ""
                    )}

                    <SelectOrCreateAgrType
                      agrType={stagedTemplate.agrType}
                      setAgrType={handleAgrTypeChange}
                      width={"320px"}
                      canCreate
                    />

                    <Grid item sx={{ my: 2, width: "320px" }}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="firstName"
                        label={"New Reference"}
                        autoFocus={Boolean(stagedTemplate.agrType)}
                        placeholder={'e.g. "DEFAULT"'}
                        value={stagedTemplate.reference}
                        onChange={(e) =>
                          setStagedTemplate({
                            ...stagedTemplate,
                            reference: e.target.value,
                          })
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon icon={faHashtag} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item sx={{ my: 2, width: "320px" }}>
                      <SelectLabels
                        adornIcon={faTag}
                        handleLabelSelect={handleLabelSelect}
                        selectedIDs={stagedTemplate.labels}
                        options={state.labels
                          .filter((l) => l.active)
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .sort((a, b) =>
                            a.type.color > b.type.color ? 1 : -1
                          )} // labels
                      />
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                // Step 1 - select the source for the template - Default
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 7,
                    mb: 5,
                  }}
                >
                  <Grid container direction="column" alignItems="center">
                    <Grid item sx={{ mb: 3 }}>
                      <ButtonGroupPills
                        buttons={[
                          { id: "import", title: "Import Template" },
                          // { id: "copy", title: "Copy Template" },
                        ]}
                        selected={stagedTemplate.source}
                        click={(e) =>
                          setStagedTemplate({ ...stagedTemplate, source: e })
                        }
                      />
                    </Grid>

                    {["import"].includes(stagedTemplate.source) ? (
                      <Grid item>
                        <FileUploader
                          handleChange={handleUploadChange}
                          name="uploadfile"
                          types={["doc", "docx"]}
                          label={"Upload or drop a file here"}
                          maxSize={20}
                          minSize={0}
                          onDraggingStateChange={(dragging) =>
                            setDropHover(dragging)
                          }
                          hoverTitle={" "}
                          children={
                            <Box
                              sx={{
                                width: "320px",
                                height: "180px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                border: dropHover
                                  ? "2px solid" + theme.palette.primary.main
                                  : "1px dotted" + theme.palette.grey[300],
                                backgroundColor: dropHover
                                  ? theme.palette.grey[200]
                                  : theme.palette.grey[50],
                                padding: "30px",
                                fontSize: "14px",
                                fontWeight: "600",
                                borderRadius: "20px",
                              }}
                            >
                              <Grid
                                container
                                direction="column"
                                alignItems="center"
                              >
                                <Grid item>
                                  <FontAwesomeIcon
                                    icon={faUpload}
                                    style={{
                                      color: theme.palette.primary.main,
                                      fontSize: "30px",
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Box
                                    sx={{
                                      mt: 1,
                                      display: "block",
                                      textAlign: "center",
                                    }}
                                  >
                                    <>
                                      {dropHover
                                        ? "Time to let the DOC/DOCX go ..."
                                        : "Upload or drop DOC/DOCX file here ..."}
                                      <br />
                                      <Grid
                                        container
                                        direction="row"
                                        spacing={1}
                                        justifyContent="center"
                                        sx={{ mt: 1 }}
                                      >
                                        <Grid item>
                                          <img
                                            src={docx}
                                            alt="docx"
                                            width={20}
                                          />
                                        </Grid>
                                      </Grid>
                                    </>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          }
                        />
                      </Grid>
                    ) : (
                      <Grid item>
                        <Autocomplete
                          id="filter-sourceTemplates"
                          sx={{ width: "380px", mt: 8, mb: 8 }}
                          options={templateOptions}
                          onChange={(e, newVal) => handleSourceSelect(newVal)}
                          filterSelectedOptions
                          /*filterOptions={(options, params) => {
                        console.log(params)
                        const filtered = filter(
                            options.filter((o) =>
                                params.inputValue === '' ||
                                o.name.includes(params.inputValue) || 
                                o.shortName.includes(params.inputValue) ||
                                o.reference.includes(params.inputValue) ||
                                o.activeString.includes(params.inputValue) ||
                                o.labelString.includes(params.inputValue)),
                            params);
                        return filtered;
                    }}*/
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option) => (
                            <Box
                              component={"li"}
                              {...props}
                              key={option._id}
                              sx={{
                                borderBottom:
                                  "1px solid" + theme.palette.grey[200],
                              }}
                            >
                              <Grid container direction="column">
                                <Grid
                                  item
                                  container
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  sx={{ pt: 1 }}
                                >
                                  <Grid item>
                                    <Typography variant="subtitle1">
                                      {option.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      style={{
                                        color: option.active
                                          ? theme.palette.primary.main
                                          : theme.palette.grey[500],
                                        fontWeight: "600",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {option.active ? "Active" : "Draft"}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid item container alignItems="flex-start">
                                  <Typography
                                    variant="body2"
                                    style={{ color: theme.palette.grey[600] }}
                                  >
                                    <span>{option.reference}</span>
                                    &nbsp;&nbsp;-&nbsp;&nbsp;v{option.version}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  alignItems="flex-start"
                                  sx={{ pt: 1, pb: 1 }}
                                >
                                  {option.labelIDs
                                    .filter((li) =>
                                      state.labels.some((l) => l._id === li)
                                    )
                                    .map((li, j) => {
                                      let label = state.labels.filter(
                                        (l) => l._id === li
                                      )[0];
                                      return (
                                        <Chip
                                          key={j}
                                          size="small"
                                          //variant="outlined"
                                          label={label.name}
                                          style={styles.labelChipSelect}
                                          color={label.type.color}
                                        />
                                      );
                                    })}
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Source Template"
                              placeholder="Source Template ..."
                              sx={{ width: "100%" }}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <>
                                    <InputAdornment position="start">
                                      <FontAwesomeIcon icon={faFileLines} />
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {stagedTemplate.sourceID ? (
            <>
              <Button sx={{ marginRight: "auto" }} onClick={reInitalize}>
                <FontAwesomeIcon icon={faArrowLeft} />
                &nbsp;&nbsp;Go back
              </Button>
              <Button
                variant="contained"
                disableElevation
                disabled={
                  stagedTemplate.agrType === undefined ||
                  stagedTemplate.agrType === null ||
                  (stagedTemplate.agrType._id !== undefined &&
                    stagedTemplate.agrType.fullName === undefined) ||
                  (stagedTemplate.agrType._id === undefined &&
                    (stagedTemplate.agrType.newName === undefined ||
                      stagedTemplate.agrType.newName === null ||
                      stagedTemplate.agrType.newName.length < 2 ||
                      stagedTemplate.agrType.shortName === undefined ||
                      stagedTemplate.agrType.shortName === null ||
                      stagedTemplate.agrType.shortName.length < 2)) ||
                  stagedTemplate.reference === undefined ||
                  stagedTemplate.reference === null ||
                  stagedTemplate.reference === "" ||
                  (stagedTemplate.agrType !== undefined &&
                    stagedTemplate.agrType !== null &&
                    stagedTemplate.agrType._id !== undefined &&
                    stagedTemplate.agrType._id !== null &&
                    stagedTemplate.agrType._id !== "" &&
                    state.templates.some(
                      (st) =>
                        st.agrTypeID === stagedTemplate.agrType._id &&
                        st.reference === stagedTemplate.reference
                    ))
                }
                onClick={handleCreateTemplate}
              >
                Create Template&nbsp;&nbsp;
                <FontAwesomeIcon icon={faFileCirclePlus} />
              </Button>
            </>
          ) : (
            <Button sx={{ marginRight: "auto" }} onClick={closeDialog}>
              Cancel
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
