import { $getSelection, $isRangeSelection } from "lexical";
import { handleSelectionDelete } from "../../../../utils/handleSelectionDelete";
import { preventEventPropagation } from "../utils/preventEventPropagation";

/**
 * @param {boolean} isBackwards
 * @param {RedlineData} defaultRedlineData
 * @returns {boolean}
 */
export function deleteLineCommandHandler(isBackwards, defaultRedlineData) {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) return preventEventPropagation();

  selection.modify("extend", isBackwards, "lineboundary");

  // Selection is collapsed when we press the cmd + backspace in the beginning of a line.
  // In that situation we just want to move the cursor to the end of the previous line.
  // Selection is also collapsed when we press the cmd + backspace in the end of a line.
  // In that situation we just want to move the cursor to the beginning of the next line.
  if (selection.isCollapsed()) {
    selection.modify("move", isBackwards, "character");
  }

  handleSelectionDelete(selection, defaultRedlineData, true);

  return preventEventPropagation();
}
