import { faChevronDown, faFont } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $patchStyleText } from "@lexical/selection";
import { IconButton, Popover } from "@mui/material";
import { $getSelection, $isRangeSelection } from "lexical";
import React, { useState } from "react";
import { CompactPicker } from "react-color";

/**
 * @returns {React.JSX.Element}
 */
export default function FontColorControls() {
  const [editor] = useLexicalComposerContext();
  const [currentColor, setCurrentColor] = useState("#000000");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (/** @type {*} */ event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const applyColor = (/** @type {string | undefined} */ color) => {
    editor.update(() => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return;
      $patchStyleText(selection, {
        color: color ?? currentColor,
      });
    });
  };

  const pickerOnChange = (/** @type {{ hex: string}} */ color) => {
    setCurrentColor(color.hex);
    applyColor(color.hex);
    handleClose();
  };

  return (
    <>
      {/* @ts-ignore */}
      <IconButton
        onClick={() => applyColor()}
        sx={{
          marginRight: 0,
          borderRight: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        variant="toolbar-btn"
      >
        <span
          style={{
            borderBottom: `4px solid ${currentColor}`,
          }}
        >
          <FontAwesomeIcon icon={faFont} />
        </span>
      </IconButton>

      {/* @ts-ignore */}
      <IconButton
        onClick={handleClick}
        sx={{
          marginLeft: "-3px",
          borderLeft: 0,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          width: 20,
          padding: "5px",
          justifyContent: "center",
          background: "white",
        }}
        variant="toolbar-btn"
      >
        <FontAwesomeIcon
          style={{
            maxWidth: "100%",
          }}
          icon={faChevronDown}
        />
      </IconButton>

      {currentColor && (
        <Popover
          id="colors-menu"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          PaperProps={{
            sx: {
              borderRadius: "5px",
              padding: "0",
            },
          }}
          TransitionProps={{
            timeout: 0,
          }}
        >
          <CompactPicker
            // Can override colors array.
            // colors={["#f2f2f2"]}
            color={currentColor}
            onChange={pickerOnChange}
          />
        </Popover>
      )}
    </>
  );
}
