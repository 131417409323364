const allowedProductionEmailsForExperimentalTier = new Set([
  // Owner organization emails.
  "owner1@getcanveo.com",
  "owner2@getcanveo.com",
  "owner3@getcanveo.com",
  "owner4@getcanveo.com",
  // Counterparty emails.
  "counterparty1user1@getcanveo.com",
  "counterparty1user2@getcanveo.com",
  "counterparty2user1@getcanveo.com",
  "counterparty2user2@getcanveo.com",
]);

/**
 * @param {string} email
 * @returns {"stable" | "experimental"}
 */
export function getCanveoTier(email) {
  if (process.env.NODE_ENV === "development") {
    return /** @type {"stable" | "experimental"} */ (
      process.env.REACT_APP_CANVEO_TIER
    );
  }

  if (!email) return "stable";

  if (allowedProductionEmailsForExperimentalTier.has(email)) {
    return "experimental";
  }

  const canveoTier = process.env.REACT_APP_CANVEO_TIER;
  if (!canveoTier) {
    return "stable";
  }

  return /** @type {"stable" | "experimental"} */ (canveoTier);
}
