import {
  faCirclePlus,
  faFileCirclePlus,
  faRightFromBracket,
  faTicketPerforated,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  FabStandard,
  Header,
  SearchBar,
  ThankYouScreen,
  ThumbAgr,
} from "../components";
import UploadStatusPopUp from "../components/UploadStatusPopUp";
import DialogFileConversionErrorDetails from "../components/dialogs/DialogFileConverionErrorDetails";
import { convertSfdtToLexical } from "../components/editor/utils";
import useVersionService from "../hooks/useVersionService";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { fileHasAllowedExtension } from "../utils/fileHasAllowedExtension";
import { getCanveoTier } from "../utils/getCanveoTier";

const allowedUploadFileExtensions = ["doc", "docx", "pdf"];

/**
 * @typedef {object} DashboardProps
 */

/**
 * @property {DashboardProps} props
 * @returns {React.JSX.Element}
 */
export default function Dashboard() {
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const styles = {
    searchBar: {
      my: isSmUp ? 19 : 10,
      [theme.breakpoints.only("xs")]: { width: "300px" },
      [theme.breakpoints.only("sm")]: { width: "360px" },
      [theme.breakpoints.up("md")]: { width: "440px" },
    },
  };

  const navigate = useNavigate();

  const params = useParams();
  // 0 / 1 => Counterparty / Subscriber user sent agreement
  // 2 / 3 => Counterparty / Subscriber user signed agreement
  const tweak = params.tweak || "";

  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [anchorNewAgreement, setAnchorNewAgreement] = useState(
    /** @type {null | *} */ (null)
  );
  const [isSearching, setIsSearching] = useState(false);
  const [errMsg, setErrMsg] = useState(/** @type {null | string} */ (null));
  const [agrs, setAgrs] = useState(/** @type {*[]} */ ([]));
  // eslint-disable-next-line no-unused-vars
  const [agrEnts, setAgrEnts] = useState(/** @type {*[]} */ ([]));
  // eslint-disable-next-line no-unused-vars
  const [agrFilter, setAgrFilter] = useState("none");
  // eslint-disable-next-line no-unused-vars
  const [agrQty, setAgrQty] = useState(4);
  const [loading, setLoading] = useState(true);
  const [userRole] = useState(state.user?.role?.name);
  const [uploading, setUploading] = useState(false);
  const [, setUploadError] = useState("");
  const [agrVersionCreated, setAgrVersionCreated] = useState(
    /** @type {null | *} */ (null)
  );
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(
    () => {
      const envelopeId = searchParams.get("envelopeId");
      const event = searchParams.get("event");
      if (envelopeId && event === "Send") {
        const url = `${state.settings.api}agr/enableAgreementExecution/${envelopeId}`;
        axios
          .put(url)
          .then(() => {
            dispatch({
              type: "NEW_SNACKBAR",
              payload: {
                message:
                  "Signature setup successful. Agreement is now ready to sign.",
                severity: "success",
              },
            });
          })
          .catch((error) => {
            console.error(error);
            dispatch({
              type: "NEW_SNACKBAR",
              payload: {
                message: "An error occurred while configuring the signing.",
                severity: "error",
              },
            });
          })
          .finally(() => {
            setSearchParams({});
          });
      }
    },
    // Runs only once on component mount hence the empty array of dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    uploadVersion,
    duplicateVersion,
    getLatestAgreementVersionByOrganization,
  } = useVersionService();

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        state.settings.api +
          "agr/relevant/" +
          state.org._id +
          "/" +
          agrFilter +
          "/" +
          agrQty
      )
      .then((resAgs) => {
        if (resAgs.data.success) {
          setAgrs(resAgs.data.data.ags);
          setAgrEnts(resAgs.data.data.ents);
          setLoading(false);
        } else {
          setErrMsg("An issue occurred while loading the latest agreements");
          setLoading(false);
        }
      })
      .catch((err) => {
        setErrMsg("Unable to load the latest agreements");
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.org._id, agrFilter, agrQty]);

  useEffect(() => {
    if (["0", "1", "2", "3"].includes(tweak)) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: ["0", "1"].includes(tweak)
            ? "The agreement was sent to your counterparty."
            : ["2", "3"].includes(tweak)
            ? "Your signature has been recorded. It may take a few minutes to process."
            : "",
          severity: "success",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tweak]);

  // /**
  //  * @param {*} filter
  //  * @returns {void}
  //  */
  // const handleFilterSelect = (filter) => {
  //   if (agrFilter !== "none") {
  //     setAgrQty(4);
  //     setAgrFilter("none");
  //   } else {
  //     setAgrQty(8);
  //     setAgrFilter(filter);
  //   }
  // };

  const handleLogout = () => {
    setLoading(true);
    axios
      .post(state.settings.api + "auth/logout")
      .then(() => {
        setLoading(false);
        navigate("/");
      })
      .catch((err) => console.log("unable to logout", err));
  };

  /**
   * @param {File} file
   * @returns {Promise<void>}
   */
  async function handleUploadChange(file) {
    try {
      setUploadError("");
      setUploading(true);
      setAgrVersionCreated(null);

      // Native file picker allows choosing files with any extension even if you
      // configured the input to only allow certain types. We currently only allow
      // uploading .doc or .docx files.
      const fileHasValidExtension = fileHasAllowedExtension(
        file,
        allowedUploadFileExtensions
      );
      if (!fileHasValidExtension) {
        setUploadError("Invalid file type");
        setUploading(false);
        return;
      }

      const postUploadSignedUrlResponse = await axios.post(
        state.settings.api + "upload/signedUrl",
        {
          contentType: file.type,
          bucketAlias: "documents",
        }
      );

      const uploadSignedUrl =
        postUploadSignedUrlResponse.data.data.uploadSignedUrl;
      const filename = postUploadSignedUrlResponse.data.data.key;

      const putUploadFileViaSignedUrlResponse = await fetch(uploadSignedUrl, {
        method: "PUT",
        body: file,
      });
      if (
        !putUploadFileViaSignedUrlResponse.ok ||
        !putUploadFileViaSignedUrlResponse.body
      ) {
        setUploadError("An error occurred while uploading the file");
        setUploading(false);
        return;
      }

      const getDocumentImportResponse = await axios
        .get(`${state.settings.api}document/import/${filename}`)
        .catch((error) => {
          console.error(error);
          return null;
        });
      if (
        !getDocumentImportResponse ||
        !getDocumentImportResponse.data.success ||
        !getDocumentImportResponse.data.data
      ) {
        setUploadError("An error has occurred while importing the document.");
        return;
      }

      const sfdt = getDocumentImportResponse.data.data;
      const lexical = await convertSfdtToLexical(sfdt, state);

      // If the owner of the agreement is the one dropping the file on the dashboard,
      // we want to create a new version of that agreement instead of returning it,
      // which we would do in the case where the changes are from a counterparty.
      if (
        lexical &&
        lexical.metadata &&
        lexical.metadata.partyId === "party0" &&
        lexical.metadata.agreementId &&
        lexical.metadata.orgId === state.user.orgID
      ) {
        const getAgreementResult = await axios.get(
          state.settings.api + "agr/" + lexical.metadata?.agreementId
        );
        const agreement = getAgreementResult?.data?.data;

        const latestAgreement = await getLatestAgreementVersionByOrganization(
          lexical.metadata.agreementId,
          lexical.metadata.orgId
        );

        const version = await duplicateVersion(
          latestAgreement._id,
          {
            content: lexical.content,
            sfdt: sfdt,
            origin: { import: `${file.name}#${filename}` },
            source: { orgID: lexical.metadata.orgId },
          },
          false,
          false
        );

        setUploading(false);
        setAgrVersionCreated({
          agreementID: lexical.metadata.agreementId,
          version: version.version,
          agrName: agreement.agrTitle,
        });
        return;
      }

      const lexicalDocument = {
        firstPageHeader: lexical.header,
        firstPageFooter: lexical.footer,
        content: lexical.content,
        contentMetadata: {
          listsStructure: lexical.listsStructure,
        },
      };

      // If lexical has an agreementId then we have to call the duplicate functionality.
      if (lexical.metadata?.agreementId) {
        const getAgreementResult = await axios.get(
          state.settings.api + "agr/" + lexical.metadata?.agreementId
        );
        const agreement = getAgreementResult?.data?.data;

        const { mergeFields, workflows, ...metadata } = lexical.metadata;

        const newVersion = await uploadVersion(
          agreement._id,
          {
            origin: { import: `${file.name}#${filename}` },
            source: { orgID: lexical.metadata.orgId },
            ...lexicalDocument,
            sfdt,
            metadata,
          },
          false
        );
        if (newVersion) {
          setUploading(false);
          setAgrVersionCreated({
            agreementID: lexical.metadata.agreementId,
            version: newVersion[0].version,
            agrName: agreement.agrTitle,
          });
        }
      } else {
        const isDocx = file.name.endsWith("docx");
        dispatch({
          type: "FILE_UPLOAD",
          payload: {
            fileType: isDocx ? "docx" : "doc",
            file: filename,
            fileName: file.name,
            fileSource: {
              label: "Template by counterparty",
              value: "Counterparty",
            },
            uploadType: "import",
            agrType: null,
            ...lexicalDocument,
            sfdt,
          },
        });
        navigate("/new?upload");
      }
    } catch (error) {
      if (error instanceof Error) {
        setConversionError(error);
      }

      setUploading(false);
      console.error(error);
    }
  }

  const [conversionError, setConversionError] = React.useState(
    /** @type {Error | undefined} */ (undefined)
  );

  return (
    <>
      <FileUploader
        handleChange={handleUploadChange}
        name="file"
        types={allowedUploadFileExtensions}
        disabled
        hoverTitle={
          <Typography variant="h2" color="textPrimary">
            Drop file here
          </Typography>
        }
        classes="dashboard-uploader"
        dropMessageStyle={{
          backgroundColor: "white",
          borderWidth: "10px",
          opacity: 0.75,
          zIndex: 99999, // To be sure it will be in front of all popups (header and MUI Menus included)
        }}
        children={
          <div>
            <Header page={"Dashboard"} loading={loading} />

            {!loading && (
              <>
                {(uploading || !!agrVersionCreated) && (
                  <UploadStatusPopUp
                    uploading={uploading}
                    agrVersionCreated={agrVersionCreated}
                    handleClose={() => {
                      setUploading(false);
                      setAgrVersionCreated(null);
                    }}
                  />
                )}
                <FabStandard
                  click={
                    userRole === "Counterparty"
                      ? (e) => handleLogout()
                      : (e) => setAnchorNewAgreement(e.currentTarget)
                  }
                  text={userRole === "Counterparty" ? "Exit" : "New"}
                  icon={
                    userRole === "Counterparty"
                      ? faRightFromBracket
                      : faCirclePlus
                  }
                  sx={{
                    left: "15px",
                    top: "80px",
                    right: "unset",
                  }}
                />
                <Menu
                  anchorEl={anchorNewAgreement}
                  keepMounted
                  open={!!anchorNewAgreement}
                  onClose={() => setAnchorNewAgreement(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  {getCanveoTier(state?.user?.email) === "experimental" && (
                    <MenuItem
                      onClick={() => navigate("/new-ticket")}
                      style={{ width: "260px" }}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faTicketPerforated} />
                      </ListItemIcon>
                      <ListItemText>New Ticket</ListItemText>
                    </MenuItem>
                  )}

                  <MenuItem
                    onClick={() => navigate("/new")}
                    style={{ width: "260px" }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faFileCirclePlus} />
                    </ListItemIcon>
                    <ListItemText>New Agreement</ListItemText>
                  </MenuItem>
                </Menu>
              </>
            )}

            {loading ? (
              <Box sx={{ my: 8, mx: 3 }}>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Box sx={styles.searchBar}>
                      <Skeleton
                        variant="text"
                        height={90}
                        sx={{ width: "100%" }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="center"
                    sx={{
                      width: isMdUp ? "780px" : isSmUp ? "340px" : "300px",
                    }}
                  >
                    {[1, 2, 3, 4].map((i) => (
                      <Grid
                        item
                        container
                        direction="column"
                        xs={6}
                        md={3}
                        alignItems="center"
                        key={i}
                      >
                        <Grid item>
                          <Skeleton
                            variant="text"
                            height={20}
                            width={isMdUp ? 150 : isSmUp ? 135 : 120}
                            sx={{ mb: 1 }}
                          />
                        </Grid>
                        <Grid item>
                          <Skeleton
                            variant="rectangular"
                            width={isMdUp ? 180 : isSmUp ? 160 : 140}
                            height={isMdUp ? 210 : isSmUp ? 185 : 160}
                            sx={{ borderRadius: "20px", marginBottom: "30px" }}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Box>
            ) : ["0", "2"].includes(tweak) ? (
              <>
                <Box sx={{ my: 8, mx: 3 }}>
                  <ThankYouScreen />
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ my: 8, mx: 3 }}>
                  <Grid container direction="column" alignItems="center">
                    {userRole !== "Counterparty" ? (
                      <Grid item>
                        <Box sx={styles.searchBar}>
                          <SearchBar setIsSearching={setIsSearching} />

                          {/*
            Search Bar, can search:<br/>
            - cpty = Filter all ags (recent 16) for that cpty + show button to edit, edit via cpty card<br/>
            - agr type = Filter all ags (recent 16) for the agr type<br/>
            - dates = e.g. Oct-2020 (inbetween creation and lastUpdate)<br/>
            - country = Filters entities, (subs/cpies) in that country<br/>
            - subs, agr title, text inside agr<br/>
            - Tasks (Appr/ Review / @Comment / Obligation)<br/>
            - Smart Fields / Obligations etc.
            - Templates
            */}
                        </Box>
                      </Grid>
                    ) : (
                      ""
                    )}

                    {!isSearching ? ( // Show 4 super-relevant ags - Hide when you're searching
                      <Grid
                        item
                        sx={{ mt: userRole === "Counterparty" ? 15 : 0 }}
                      >
                        <Grid
                          container
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent={
                            Boolean(agrs) && agrs.length > 4
                              ? "flex-start"
                              : "center"
                          }
                          sx={{
                            width: isMdUp
                              ? "880px"
                              : isSmUp
                              ? "400px"
                              : "330px",
                          }}
                        >
                          {errMsg !== null ? ( // An error exists - show it
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                my: 4,
                              }}
                            >
                              <Typography align="center" color="error">
                                {errMsg}
                              </Typography>
                            </Box>
                          ) : Boolean(agrs) && agrs.length > 0 ? (
                            // agrs.map((ag, i) => {
                            //   // TODO => Pull relevant agr details directly from DB vs. from local state (to keep state loosely coupled)
                            //   // Pull 16 ags (max), sorted based on recent update
                            //   // Details to get: legalName 1 and 2 and logoURL
                            //   // Details to get: "recently viewed", "recently updated", "waiting for cpty", "pending signature"
                            //   let ent1id = ag.ents.filter(
                            //     (/** @type {{ partyID: string; }} */ e) =>
                            //       e.partyID === "party1"
                            //   )[0]?.entID;
                            //   let ent2id = ag.ents.filter(
                            //     (/** @type {{ partyID: string; }} */ e) =>
                            //       e.partyID === "party2"
                            //   )[0]?.entID;
                            //   let ent1 =
                            //     agrEnts.filter((e) => e._id === ent1id)[0] !==
                            //     undefined
                            //       ? agrEnts.filter((e) => e._id === ent1id)[0]
                            //       : {};
                            //   let ent2 =
                            //     agrEnts.filter((e) => e._id === ent2id)[0] !==
                            //     undefined
                            //       ? agrEnts.filter((e) => e._id === ent2id)[0]
                            //       : {};

                            //   return (
                            //     <Grid
                            //       item
                            //       key={ag._id}
                            //       xs={6}
                            //       md={3}
                            //       sx={{ mb: 2 }}
                            //     >
                            //       <Typography
                            //         align="center"
                            //         color="textSecondary"
                            //         variant="body2"
                            //         sx={{
                            //           mb: 1.5,
                            //           "&:hover": {
                            //             color: theme.palette.primary.main,
                            //             cursor: "pointer",
                            //           },
                            //         }}
                            //         onClick={(e) => handleFilterSelect("viewed")}
                            //       >
                            //         {agrFilter !== "none" && i === 0 ? (
                            //           <span
                            //             style={{
                            //               color: theme.palette.primary.main,
                            //               fontWeight: "600",
                            //             }}
                            //           >
                            //             <FontAwesomeIcon icon={faFilterSlash} />
                            //             &nbsp;&nbsp;Recently Viewed
                            //           </span>
                            //         ) : agrFilter !== "none" && i !== 0 ? (
                            //           <>&nbsp;&nbsp;</>
                            //         ) : i === 0 ? (
                            //           <>
                            //             <FontAwesomeIcon icon={faEye} />
                            //             &nbsp;&nbsp;Recently Viewed
                            //           </>
                            //         ) : i === 1 ? (
                            //           <>
                            //             <FontAwesomeIcon icon={faUserPen} />
                            //             &nbsp;&nbsp;Recently Updated
                            //           </>
                            //         ) : i === 2 ? (
                            //           <>
                            //             <FontAwesomeIcon icon={faHourglass} />
                            //             &nbsp;&nbsp;Waiting for{" "}
                            //             {isSmUp ? "counterparty" : "cpty"}
                            //           </>
                            //         ) : i === 3 ? (
                            //           <>
                            //             <FontAwesomeIcon icon={faSignature} />
                            //             &nbsp;&nbsp;Pending signature(s)
                            //           </>
                            //         ) : (
                            //           ""
                            //         )}
                            //       </Typography>
                            //       <ThumbAgr
                            //         ag={ag}
                            //         actionReq={ag.avOwners.includes(
                            //           state.org._id
                            //         )}
                            //         thumbClick={() =>
                            //           navigate("/agreement/" + ag._id)
                            //         }
                            //         showLogo={
                            //           state.subs.some(
                            //             (/** @type {{ _id: string; }} */ s) =>
                            //               s._id === ent1id
                            //           )
                            //             ? ent2.logoURL
                            //             : ent1.logoURL
                            //         }
                            //         primaryLegalName={
                            //           ent1.legalName !== undefined
                            //             ? ent1.legalName
                            //             : ""
                            //         }
                            //         // TODO PULL CPENT FOR THIS AG IF YOU'RE NOT THE OWNER (e.g. subscriber or when you're the CP)
                            //         secondaryLegalName={
                            //           ent2.legalName !== undefined
                            //             ? ent2.legalName
                            //             : ""
                            //         }
                            //         additionalParties={ag.ents.filter(
                            //           (/** @type {{ entID: string; }} */ e) =>
                            //             e.entID !== ent1._id &&
                            //             e.entID !== ent2._id
                            //         )}
                            //       />
                            //     </Grid>
                            //   );
                            // })
                            <>
                              <Grid container justifyContent="left">
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                  ml={3}
                                >
                                  Recently Updated
                                </Typography>
                              </Grid>

                              <Grid container direction="row" mt={3} ml={2}>
                                {(() => {
                                  const slicedAgreements = agrs.slice(0, 4);
                                  return slicedAgreements.map((agreement) => {
                                    const ent1id = agreement.ents.filter(
                                      (/** @type {{ partyID: string; }} */ e) =>
                                        e.partyID === "party1"
                                    )[0]?.entID;
                                    const ent2id = agreement.ents.filter(
                                      (/** @type {{ partyID: string; }} */ e) =>
                                        e.partyID === "party2"
                                    )[0]?.entID;
                                    const ent1 =
                                      state?.cpents?.filter(
                                        (/** @type {{ _id: string; }} */ e) =>
                                          e._id === ent1id
                                      )[0] !== undefined
                                        ? state?.cpents?.filter(
                                            (
                                              /** @type {{ _id: string; }} */ e
                                            ) => e._id === ent1id
                                          )[0]
                                        : {};
                                    const ent2 =
                                      state?.cpents?.filter(
                                        (/** @type {{ _id: string; }} */ e) =>
                                          e._id === ent2id
                                      )[0] !== undefined
                                        ? state?.cpents?.filter(
                                            (
                                              /** @type {{ _id: string; }} */ e
                                            ) => e._id === ent2id
                                          )[0]
                                        : {};

                                    return (
                                      <Grid item key={agreement._id} xs={3}>
                                        <ThumbAgr
                                          ag={agreement}
                                          actionReq={agreement.avOwners.includes(
                                            state.org._id
                                          )}
                                          thumbClick={() =>
                                            navigate(
                                              "/agreement/" + agreement._id
                                            )
                                          }
                                          showLogo={
                                            state.subs.some(
                                              (
                                                /** @type {{ _id: string; }} */ s
                                              ) => s._id === ent1id
                                            )
                                              ? ent2.logoURL
                                              : ent1.logoURL
                                          }
                                          primaryLegalName={
                                            ent1.legalName !== undefined
                                              ? ent1.legalName
                                              : ""
                                          }
                                          secondaryLegalName={
                                            ent2.legalName !== undefined
                                              ? ent2.legalName
                                              : ""
                                          }
                                          additionalParties={agreement.ents.filter(
                                            (
                                              /** @type {{ entID: string; }} */ e
                                            ) =>
                                              e.entID !== ent1._id &&
                                              e.entID !== ent2._id
                                          )}
                                        />
                                      </Grid>
                                    );
                                  });
                                })()}
                              </Grid>
                            </>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Box>
              </>
            )}
          </div>
        }
      />

      {conversionError && (
        <DialogFileConversionErrorDetails
          conversionError={conversionError}
          close={() => setConversionError(undefined)}
        />
      )}
    </>
  );
}
