import { NodeEventPlugin } from "@lexical/react/LexicalNodeEventPlugin";
import { $dfs } from "@lexical/utils";
import { $createTabNode, $getNodeByKey } from "lexical";
import React from "react";
import { v4 as uuid } from "uuid";
import {
  HYPERLINK_REGEX,
  HYPERLINK_REGEX2,
  REF_REGEX,
} from "../../../../utils/constants";
import {
  $createCrossRefNode,
  $isCrossRefNode,
  CrossRefNode,
} from "../../nodes/CrossRefNode";
import { $createImageNode } from "../../nodes/ImageNode";
import { $createRedlineNode } from "../../nodes/RedlineNode";
import { createComment } from "../../plugins/commenting";
import { createTextNodeWithFormatting } from "./text";
/**
 * Recursive function to check for references inside inline loop
 *
 * - if current inline is text, we simply add a new textNode
 * - if current inline is beginning of reference field type we begin again
 * - if current inline is beggining of reference bookmark we begin again
 * - ends if is field type 1 (stop conditions)
 *
 * @param {import("../../nodes").CrossRefNode | undefined} node cross reference node
 * @param {import("../../types/sfdt").Sfdt} sfdt sfdt
 * @param {import("../../types/sfdt").Block} block current sfdt block
 * @param {import("../../types/sfdt").Section} section current sfdt section
 * @param {import("../../types/sfdt").Inline[]} inlines inlines
 * @param {import("../../types/sfdt").Inline} inline current inline
 * @param {number} iteratorVar iterator
 * @param  {import("lexical").LexicalNode[]} fragments array where nodes will be added recursively
 * @param  {import("../../types/sfdt").Inline[]} data inlines to add into cross ref node
 * @param {import("./text").ComplementaryData} complementaryData
 * @param  {string} blockType array where nodes will be added recursively
 *
 * @returns {CrossRefNode | undefined}
 */
export const checkForCrossReferences = (
  node,
  sfdt,
  block,
  section,
  inlines,
  inline,
  iteratorVar,
  fragments,
  data,
  complementaryData,
  blockType = "Paragraph"
) => {
  /** Example for result structure
   *
   * CNODE
   *  textnode ...
   * CNODE
   *  textNode (Why do we)
   *  CNODE (within)
   *   textNode (Where can i get some?)
   *  textNode (use it?)
   */

  /** Function to create text node
   *
   * @param {import("../../types/sfdt").Inline} inl
   * @returns {import("lexical").TextNode}
   */
  const createTextNode = (inl) => {
    return createTextNodeWithFormatting(sfdt, block, inl, blockType);
  };

  if (!inline) {
    return node;
  }

  /** Stop conditions are conditions for
   * recursiveness to be halted, therefore when
   * inlines for node are complete
   */
  const stopCondition1 =
      data[0]?.name === inline.name && inline.bookmarkType === 1,
    stopCondition2 = inline.fieldType === 1;
  if (stopCondition1 || stopCondition2) {
    data.push(inline);
    if (fragments && node) {
      node.append(...fragments);
      node.setData(data);
    }
    return node;
  }

  const nextInline = inlines[iteratorVar + 1];
  /** @type {import("lexical").LexicalNode[]} */
  const childFragments = [], //children of current node
    /** @type {import("../../types/sfdt").Inline[]} */
    d = []; //data of current node

  /** @type {boolean} - This is a cross-reference to something */
  const isFirstRefType = !!(inline.hasFieldEnd && inline.fieldType === 0),
    /** @type {boolean} - This is referenced by something */
    isSecondRefType = !!(inline.name && inline.bookmarkType === 0);
  if (inline.text || inline.imageString) {
    const reference = (
      inline.text?.match(REF_REGEX) ?? inline.text?.match(HYPERLINK_REGEX)
    )?.toString();
    if (!reference) {
      //TODO: needs to parse all types of inlines
      if (
        inline.revisionIds &&
        !(inline.bookmarkType === 0 || inline.fieldType === 0)
      ) {
        //means there is tracked changes here
        const revisionsIds = inline.revisionIds;
        const revisionObjects = sfdt.revisions?.filter((r) =>
          revisionsIds.includes(r.revisionId)
        );
        if (revisionObjects) {
          let previousRev = revisionObjects[0],
            /** @type {RedlineType} */
            currentType = ["MoveTo", "Insertion"].includes(
              previousRev.revisionType
            )
              ? "add"
              : "del";
          revisionObjects.sort(
            /**
             *
             * @param {import("../../types/sfdt").Revision} a
             * @param {import("../../types/sfdt").Revision} b
             * @returns {number}
             */
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
          );
          revisionObjects
            .slice(1)
            .forEach(
              (/** @type {import("../../types/sfdt").Revision} */ rev) => {
                if (previousRev.revisionId !== rev.revisionId) {
                  if (rev.revisionType === "Insertion") {
                    currentType = "add";
                  } else {
                    currentType = "del";
                  }
                }
                previousRev = rev;
              }
            );
          const { author, date } = previousRev;
          const {
            user,
            collabs,
            metadata,
            sfdtCommentsMap,
            ongoingCommentsSet,
          } = complementaryData;
          if (inline.text !== null && inline.text !== undefined) {
            const collab = metadata
              ? collabs.find(
                  (/** @type {PartialUser}*/ x) => x.email === author
                )
              : undefined;
            const uploader = collabs.find(
              (x) => x.partyID === metadata?.partyId
            ) ||
              collabs.find((x) => x.email === user?.email) || {
                ...user,
                partyID: "party0",
              };

            const useDisplayNameFromWord = collabs.every(
              (x) => x.email !== author
            );

            const redline = $createRedlineNode({
              redlineType: currentType,
              metadata: {
                partyId: collab ? collab.partyID : uploader.partyID,
                creatorDisplayName: useDisplayNameFromWord
                  ? author
                  : collab
                  ? collab.displayName
                  : uploader.displayName,
                creationDate: date,
                creatorEmail: collab ? collab.email : uploader.email,
                creatorId: collab ? collab._id : uploader._id,
                creatorPhotoUrl: collab ? collab.photoURL : user?.photoURL,
                revisionId: uuid(),
              },
              partyID: collab ? collab.partyID : uploader.partyID,
              date: date,
              text: inline.text,
            });

            if (ongoingCommentsSet.size) {
              const markNode = createComment(
                ongoingCommentsSet,
                sfdtCommentsMap,
                metadata,
                collabs,
                user,
                redline
              );
              fragments.push(markNode);
            } else {
              fragments.push(redline);
            }
          }
        }
      } else if (inline.imageString && sfdt.images) {
        const { user, collabs, metadata, sfdtCommentsMap, ongoingCommentsSet } =
          complementaryData;
        //is an image
        const docWidth =
          section.sectionFormat.pageWidth -
          (section.sectionFormat.leftMargin +
            section.sectionFormat.rightMargin);
        const args = {
          altText: inline.alternativeText,
          height: inline.height,
          maxWidth: 500,
          captionsEnabled: false,
          src: sfdt.images[
            /** @type {keyof typeof sfdt.images} */ (inline.imageString)
          ],
          width: inline.width,
          showCaption: false,
          caption: "",
          isInline: inline.isInlineImage,
          horizontalPosition: inline.horizontalPosition,
          origin: inline.horizontalOrigin,
          float:
            docWidth / 2 < (inline.horizontalPosition ?? 0) ? "right" : "left",
          key: undefined,
        };

        const imageNode = $createImageNode(args, inline);

        if (ongoingCommentsSet.size) {
          const markNode = createComment(
            ongoingCommentsSet,
            sfdtCommentsMap,
            metadata,
            collabs,
            user,
            imageNode
          );
          fragments.push(markNode);
        } else {
          // @ts-ignore
          fragments.push(imageNode);
        }
      } else if (inline.text !== "\t") {
        // Each text fragment can have different formatting, so we create the TextNode taking that into account.
        const textNode = createTextNode(inline);
        fragments.push(textNode);
      } else if (inline.text === "\t") {
        const tabNode = $createTabNode();
        fragments.push(tabNode);
      }
    }
    data.push(inline);
    return checkForCrossReferences(
      node,
      sfdt,
      block,
      section,
      inlines,
      nextInline,
      iteratorVar + 1,
      fragments,
      data,
      complementaryData,
      blockType
    );
  } else if (isFirstRefType || isSecondRefType) {
    const reference = isFirstRefType ? nextInline.text : inline.name;
    //it's a cross reference to something or cross-referred
    const crossReferenceMatch = reference?.match(REF_REGEX)?.toString();
    //it's an hyperlink

    const hyperLinkMatch = reference?.match(HYPERLINK_REGEX2)?.toString();
    /** @type {import("../../nodes").CrossRefNode | undefined} */
    let returnedNode, newNode;
    /** @type {import("../../nodes/CrossRefNode").CrossRefNodeMetadata} */
    let metadata;
    if (crossReferenceMatch) {
      /**
       * PAGEREF => is page reference
       * \\h => is hyperlink (link to text)
       * \\p => is position based link (above/below)
       * \\r => is heading number without context
       * \\w => is heading number with context
       * \\d " ," => has delimiter followed by actual delimiter
       */
      const isLink = isFirstRefType
        ? !!nextInline.text?.match(/\\+h{1}/g)?.toString()
        : false;
      const isPageRef = isFirstRefType
        ? !!nextInline.text?.match(/PAGEREF{1}/g)?.toString()
        : false;
      const hasPosition = isFirstRefType
        ? !!nextInline.text?.match(/\\+p{1}/g)?.toString()
        : false;
      const context = nextInline.text?.match(/\\+w{1}/g)?.toString();
      const noContext = nextInline.text?.match(/\\+r{1}/g)?.toString();
      const hasContext = !!(isFirstRefType ? context ?? noContext : false);
      let delimiter;
      const hasDelimiter = isFirstRefType
        ? nextInline.text?.match(/\\+d{1}\s{1}"(.+)"/g)?.toString()
        : false;
      if (hasDelimiter) {
        delimiter = hasDelimiter.split('"')[1];
      }
      metadata = {
        isLink: isLink,
        hasPosition: hasPosition,
        hasContext: hasContext,
        isPageRef: isPageRef,
        delimiter: delimiter,
      };
      newNode = $createCrossRefNode(
        metadata,
        crossReferenceMatch,
        undefined,
        isSecondRefType
      );
    } else if (hyperLinkMatch) {
      /**
       * HYPERLINK => is hyperlink
       * \\l => is in document link
       */
      const isDocumentLink = isFirstRefType
        ? !!reference?.match(/\\+l{1}/g)?.toString()
        : false;
      //match stuff between quotes ""
      const link = reference.match(/"([^"]+)"/) ?? [];
      const target = link.length ? link[1].toString() : reference;
      const hyperlinkType = reference?.match(HYPERLINK_REGEX2).groups?.type;
      metadata = {
        isLink: hyperlinkType !== "INCLUDEPICTURE",
        isUrl: !isDocumentLink,
        hasPosition: false,
        hasContext: false,
        isPageRef: false,
        delimiter: false,
      };
      newNode = $createCrossRefNode(
        metadata,
        target,
        undefined,
        isSecondRefType
      );
    }
    if (node) {
      let x = iteratorVar;
      returnedNode = checkForCrossReferences(
        undefined,
        sfdt,
        block,
        section,
        inlines,
        inline,
        x,
        childFragments,
        d,
        complementaryData,
        blockType
      );
      if (returnedNode) {
        fragments.push(returnedNode);
        const stopCondition3 = returnedNode
          .getData()
          .findIndex(
            (el) => el.bookmarkType === 1 && el.name === node.getTarget()
          );
        if (stopCondition3 !== -1) {
          data.push(...returnedNode.getData().slice(0, stopCondition3 + 1));
          data = data.filter(
            (el) =>
              (el.name === node.getTarget() &&
                (el.bookmarkType === 0 || el.bookmarkType === 1)) ||
              !el.name
          );
          if (fragments && node) {
            node.append(...fragments);
            node.setData(data);
          }
          return node;
        }
      }
    } else {
      data.push(inline);
    }
    let position = iteratorVar + 1;
    if (returnedNode) {
      //search through children to skip right amount of inlines
      let dataSizeAcc = 0;
      const dfs = $dfs(returnedNode).filter(({ node }) =>
        $isCrossRefNode(node)
      );
      dfs.forEach(({ node }) => {
        dataSizeAcc += node.getDataSize();
      });
      position = iteratorVar + dataSizeAcc;
    }
    const next = inlines[position];
    return checkForCrossReferences(
      node ? node : newNode,
      sfdt,
      block,
      section,
      inlines,
      next,
      position,
      fragments,
      data,
      complementaryData,
      blockType
    );
  } else {
    /**
     * this is for inlines that need to be added to data
     * but are not considered for text content or anything else for that matter
     */
    data.push(inline);
    return checkForCrossReferences(
      node,
      sfdt,
      block,
      section,
      inlines,
      nextInline,
      iteratorVar + 1,
      fragments,
      data,
      complementaryData,
      blockType
    );
  }
};

/**
 * @param {*} stateProp
 */
export const CrossReferenceEventsPlugin = (stateProp) => {
  const { state } = stateProp;
  return (
    <NodeEventPlugin
      nodeType={CrossRefNode}
      eventType={"click"}
      eventListener={(e, editor, key) => {
        /** @type {CrossRefNode} */
        let node;
        editor.getEditorState().read(() => {
          node = $getNodeByKey(key);
          if (!node || !$isCrossRefNode(node)) return;
          e.preventDefault();
          e.stopPropagation();
          const target = node.getTarget();
          const { isUrl } = node.getMetadata();
          if (!isUrl) {
            const dest = document.getElementById(target);
            state.scroller.toElement(dest);
            dest.focus();
            var range = document.createRange();
            var sel = window.getSelection();
            range.setStart(dest, 0);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
          } else {
            //if it's span it's a target not a reference
            if (!node.__isTarget) {
              window.open(target);
            }
          }
        });
      }}
    />
  );
};

/**
 * Generates first field text dynamically from cross reference node
 *
 * @param {CrossRefNode} node
 * @returns {undefined | string}
 */
export const generateCrossReferenceFieldText = (node) => {
  if (!$isCrossRefNode(node)) {
    return;
  }
  const metadata = node.getMetadata();
  const target = node.getTarget();
  if (!metadata || !target) {
    throw new Error("Node does not have metadata or target.");
  }
  let field = "";
  const data = node.getData();
  if (!data.length) return;
  // Get first part.
  const stringForRegex = node.isTarget ? data[0].text : data[1].text;
  if (!stringForRegex) return;

  const matches1 = stringForRegex.match(HYPERLINK_REGEX2);
  if (!matches1) return;

  if (matches1.groups && matches1.groups.type) {
    field += ` ${matches1.groups.type}`;
  } else {
    throw new Error("Unable to find first segment for cross reference inline.");
  }

  const isHyperlink = !["PAGEREF", "REF"].includes(matches1.groups.type);

  //IF HYPERLINK
  // \\l
  //IF CROSSREFERENCE
  // \\h or \\p or \\r or \\w or \\d
  if (isHyperlink) {
    if (!metadata.isUrl) {
      field += " \\l";
    }
    //reference
    field += ` "${target}"`;
  } else {
    //reference
    field += ` ${target}`;
    //is crossreference
    if (metadata.isLink) {
      field += " \\h";
    }
    if (metadata.hasContext) {
      field += " \\w";
    }
    if (!metadata.hasContext) {
      field += " \\r";
    }
    if (metadata.hasPosition) {
      field += " \\p";
    }
    if (metadata.delimiter) {
      field += ` \\d "${metadata.delimiter}"`;
    }
  }

  return field;
};
