import { emptyDefaultHeadersFooters } from "../constants/emptyDefaultHeadersFooters";

/**
 * Check if the `headersAndFooters` property of an SFDT section is indeed empty.
 *
 * By default the `headersAndFooters` property will have `{}` as its value,
 * but with some offline changes (done directly in `Word`) such as adding a tracked change,
 * the `headersAndFooters` gets filled with a bunch other properties giving the wrong
 * appearence that it actually contains content.
 *
 * That content is what the `emptyDefaultHeadersFooters` constant is. We just need to
 * compare the `headersAndFooters` sent via parameter with it and if there is a difference
 * it means that it contains information that we want to keep.
 *
 * @param {unknown} headersAndFooters
 * @returns {boolean}
 */
export function headersAndFootersIsEmpty(headersAndFooters) {
  const result =
    JSON.stringify(headersAndFooters) ===
    JSON.stringify(emptyDefaultHeadersFooters);
  return result;
}
