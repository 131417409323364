import React from "react";
import theme from "../theme/theme";
import {
  Avatar,
  AvatarGroup as MaterialAvatarGroup,
  Badge,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: color,
    color: color,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
    },
  },
}));

const collabs = [
  {
    displayName: "Remy Sharp",
    logoURL: "https://storage.googleapis.com/cvt-images/BztgGzzHvfqZMV20Bewy",
    status: "active",
  },
  { displayName: "Travis Howard", logoURL: null, status: "recent" },
  {
    displayName: "Agnes Walker",
    logoURL: "https://storage.googleapis.com/cvt-images/BztgGzzHvfqZMV20Bewy",
    status: null,
  },
];

const AvatarGroup = (props) => {
  return (
    <MaterialAvatarGroup
      total={8}
      componentsProps={{
        additionalAvatar: {
          sx: {
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.grey[800],
          },
        },
      }}
    >
      {collabs.map((c, i) => (
        <StyledBadge
          key={i}
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
          color={
            ["active"].includes(c.status)
              ? theme.palette.success.main
              : ["recent"].includes(c.status)
              ? theme.palette.grey[500]
              : "#ffffff"
          }
          invisible={
            c.badgeColor === null || !["active", "recent"].includes(c.status)
          }
        >
          <Tooltip
            placement="bottom"
            arrow
            title={
              ["active"].includes(c.status)
                ? "Currently Active"
                : ["recent"].includes(c.status)
                ? "Edited within the last 7 days"
                : ""
            }
          >
            <Avatar alt={c.displayName} src={c.logoURL}>
              {c.displayName.split(" ")[0][0] + c.displayName.split(" ")[1][0]}
            </Avatar>
          </Tooltip>
        </StyledBadge>
      ))}
    </MaterialAvatarGroup>
  );
};
export default AvatarGroup;
