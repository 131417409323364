import { faTable, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  TextField,
  useTheme,
} from "@mui/material";
import {
  $createLineBreakNode,
  $getSelection,
  $isElementNode,
  $isParagraphNode,
  $isRangeSelection,
  $isTextNode,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_EDITOR,
  ElementNode,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  $createCustomTableCellNode,
  CustomTableCellNode,
} from "../../nodes/CustomTableCellNode";
import { $createCustomTableNode } from "../../nodes/CustomTableNode";
import { $createCustomTableRowNode } from "../../nodes/CustomTableRowNode";
import {
  defaultCellFormat,
  defaultRowFormat,
  defaultTableFormat,
} from "../../utils/constants";
import { PROPAGATE_EVENT } from "../TrackChangesPlugin/utils";

const inputStyle = (theme) => ({
  maxWidth: 100,
  height: 40,
  minHeight: 40,
  color: theme.palette.grey[800],
  backgroundColor: theme.palette.primary.contrastText,
  "&.Mui-focused fieldset": {
    borderWidth: "1px !important",
  },
  "& fieldset": {
    borderColor: theme.palette.grey[300],
    borderRadius: "5px",
    borderWidth: "1px",
  },
});

const FormBox = ({ children }) => (
  <Box
    display="flex"
    width="100px"
    maxWidth="100px"
    fontWeight="bold"
    fontSize="1.15rem"
  >
    {children}
  </Box>
);

const FormTextField = (params) => (
  <TextField
    type="number"
    InputProps={{
      sx: (theme) => inputStyle(theme),
    }}
    {...params}
  ></TextField>
);

export default function ToolbarTablePlugin({ sfdt, item }) {
  const [editor] = useLexicalComposerContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [cols] = useState(10);
  const [rows] = useState(8);
  const [desiredRows, setDesiredRows] = useState(5);
  const [desiredCols, setDesiredCols] = useState(2);
  const [currentPoint, setCurrentPoint] = useState(null);
  const theme = useTheme();
  const [createTableDialog, showCreateTableDialog] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const closeDialog = () => {
    showCreateTableDialog(false);
  };

  const generateGrid = (maxX, maxY) => {
    const grid = [];
    for (let y = 0; y < maxY; y++) {
      for (let x = 0; x < maxX; x++) {
        grid.push({ x, y });
      }
    }
    return grid;
  };

  const items = generateGrid(cols, rows);

  const createTable = (receivedCols = 1, receivedRows = 1) => {
    const cols = parseInt(receivedCols),
      rows = parseInt(receivedRows);
    console.log("Chose to create a table with ", cols, rows);
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const isCollapsed = selection.isCollapsed();
        if (isCollapsed && !createTableDialog) {
          showCreateTableDialog(true);
          return;
        }
        const {
          left,
          right,
          bottom,
          top,
          horizontal,
          vertical,
          diagonalDown,
          diagonalUp,
        } = defaultTableFormat.borders; //table borders
        const {
          cLeft,
          cRight,
          cBottom,
          cTop,
          cHorizontal,
          cVertical,
          cDiagonalDown,
          cDiagonalUp,
        } = defaultCellFormat.borders; //cell borders
        /** @type {ElementNode[]} */
        const possibleEmptyParents = [];
        if (isCollapsed) {
          //insert table at point splitting stuff if needed
          const node = selection.anchor.getNode();
          let resultingNode;
          if ($isTextNode(node)) {
            const splittedNodes = node.splitText(selection.anchor.offset);
            if (splittedNodes.length > 1) {
              [resultingNode] = splittedNodes;
              //returns 2 halves
            } else {
              //either returns 1 node (was at end or beginning)
              resultingNode = splittedNodes[0];
            }
          } else if ($isElementNode(node)) {
            resultingNode = node;
          }
          possibleEmptyParents.push(node.getParent());
          const newTable = $createCustomTableNode(
            undefined,
            undefined,
            undefined,
            {
              borderLeft: left,
              borderRight: right,
              borderTop: top,
              borderBottom: bottom,
              borderHorizontal: horizontal,
              borderVertical: vertical,
              borderDiagonalUp: diagonalUp,
              borderDiagonalDown: diagonalDown,
            }
          );
          newTable.setTableFormat(defaultTableFormat);
          /** @type {CustomTableCellNode} */
          let firstCol;
          for (let i = 0; i < rows; i++) {
            const row = $createCustomTableRowNode(defaultRowFormat);
            for (let j = 0; j < cols; j++) {
              const col = $createCustomTableCellNode(
                undefined,
                1,
                undefined,
                1,
                {
                  borderLeft: cLeft,
                  borderRight: cRight,
                  borderTop: cBottom,
                  borderBottom: cTop,
                  borderHorizontal: cHorizontal,
                  borderVertical: cVertical,
                  borderDiagonalUp: cDiagonalDown,
                  borderDiagonalDown: cDiagonalUp,
                },
                defaultCellFormat
              );
              row.append(col);
              if (j === 0 && i === 0) {
                firstCol = col;
              }
            }
            newTable.append(row);
          }
          if ($isTextNode(node)) {
            resultingNode.insertAfter(newTable);
          } else if ($isElementNode(node)) {
            resultingNode.append(newTable);
          }
          firstCol.select();
        } else {
          //get content and put inside first cell (1X1)

          const selectionNodes = selection.getNodes();
          const isBackwards = selection.isBackward();
          const anchorKey = isBackwards
            ? selection.focus.key
            : selection.anchor.key;
          const focusKey = isBackwards
            ? selection.anchor.key
            : selection.focus.key;
          const startOffset = isBackwards
            ? selection.focus.offset
            : selection.anchor.offset;
          const endOffset = isBackwards
            ? selection.anchor.offset
            : selection.focus.offset;

          let resultingNode;

          const nodesToAdd = [];
          selectionNodes.forEach((n) => {
            if ($isTextNode(n)) {
              if (n.getKey() === anchorKey) {
                const splittedNodes = n.splitText(startOffset);
                if (splittedNodes.length > 1) {
                  if (isBackwards) {
                    [, resultingNode] = splittedNodes;
                  } else {
                    [resultingNode] = splittedNodes;
                  }
                  //returns 2 halves
                } else {
                  //either returns 1 node (was at end or beginning)
                  resultingNode = splittedNodes[0];
                }
                const existingParent = resultingNode.getParent();
                if (
                  nodesToAdd
                    .map((el) => el.getKey())
                    .includes(existingParent.getKey())
                ) {
                  existingParent.append(resultingNode);
                } else {
                  nodesToAdd.push(resultingNode);
                }
              } else if (n.getKey() === focusKey) {
                const splittedNodes = n.splitText(endOffset);
                if (splittedNodes.length > 1) {
                  if (isBackwards) {
                    [resultingNode] = splittedNodes;
                  } else {
                    [, resultingNode] = splittedNodes;
                  }
                  //or returns 2 halves
                } else {
                  //either returns 1 node (was at end or beginning)
                  resultingNode = splittedNodes[0];
                }
                const existingParent = resultingNode.getParent();
                if (
                  nodesToAdd
                    .map((el) => el.getKey())
                    .includes(existingParent.getKey())
                ) {
                  existingParent.append(resultingNode);
                } else {
                  nodesToAdd.push(resultingNode);
                }
              } else {
                nodesToAdd.push(n);
              }
            } else if ($isElementNode(n)) {
              possibleEmptyParents.push(n.getParent());
              if ($isParagraphNode(n) && n.isEmpty()) {
                nodesToAdd.push($createLineBreakNode(), $createLineBreakNode());
              }
            }
          });
          const newTable = $createCustomTableNode(
            undefined,
            undefined,
            undefined,
            {
              borderLeft: left,
              borderRight: right,
              borderTop: top,
              borderBottom: bottom,
              borderHorizontal: horizontal,
              borderVertical: vertical,
              borderDiagonalUp: diagonalUp,
              borderDiagonalDown: diagonalDown,
            }
          );
          newTable.setTableFormat(defaultTableFormat);
          /** @type {CustomTableCellNode} */
          //1 row
          const row = $createCustomTableRowNode(defaultRowFormat);
          //1 column
          const col = $createCustomTableCellNode(
            undefined,
            1,
            undefined,
            1,
            {
              borderLeft: cLeft,
              borderRight: cRight,
              borderTop: cBottom,
              borderBottom: cTop,
              borderHorizontal: cHorizontal,
              borderVertical: cVertical,
              borderDiagonalUp: cDiagonalDown,
              borderDiagonalDown: cDiagonalUp,
            },
            defaultCellFormat
          );
          //append col to row
          row.append(col);
          //append row to table
          newTable.append(row);
          resultingNode.insertAfter(newTable);

          //append text
          col.append(...nodesToAdd);
          col.select();
        }
        possibleEmptyParents.forEach((p) => {
          if (p.isEmpty() && p.getTextContentSize() === 0) {
            p.remove(false);
          }
        });
      }
    });
    editor.dispatchCommand(CAN_UNDO_COMMAND, true);
    handleClose();
    setDesiredRows(5);
    setDesiredCols(2);
  };

  const [canDrawTable, setCanDrawTable] = useState(false);
  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          //NOT FULLY RELIABLE
          const selection = $getSelection();
          setCanDrawTable(
            $isRangeSelection(selection) && selection.isCollapsed()
          );
          return PROPAGATE_EVENT;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  const getTableStyles = useCallback(
    (isSelected) => {
      if (canDrawTable) {
        return {
          border: `1px solid ${
            isSelected ? theme.palette.primary.main : "black"
          }`,
          background: isSelected ? "#8e68e34d" : theme.palette.background,
        };
      } else {
        return {
          border: "1px solid " + theme.palette.grey[400],
          background: theme.palette.grey[300],
        };
      }
    },
    [canDrawTable, theme.palette]
  );

  const disabled = useMemo(() => {
    const parsed = {
      cols: parseInt(desiredCols ?? 0),
      rows: parseInt(desiredRows ?? 0),
    };
    const { cols, rows } = parsed;
    return !cols || !rows || cols < 1 || rows < 1 || cols > 63 || rows > 63;
  }, [desiredCols, desiredRows]);
  return (
    <>
      <IconButton
        id="table-button"
        aria-controls={open ? "table-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="toolbar-btn"
      >
        <FontAwesomeIcon icon={faTable} />
      </IconButton>
      <Popover
        id="table-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        PaperProps={{
          sx: {
            borderRadius: "5px",
            padding: "0",
          },
        }}
        TransitionProps={{
          timeout: 0,
        }}
      >
        <Grid
          container
          sx={{
            padding: "10px",
          }}
        >
          <Grid item xs={12}>
            {`${
              currentPoint
                ? parseInt(currentPoint.x + 1) +
                  "x" +
                  parseInt(currentPoint.y + 1) +
                  " "
                : ""
            }Table`}
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            padding: "0 10px",
            marginBottom: "10px",
            gap: "2px",
            justifyContent: "center",
            maxWidth: `calc(${cols} * (20px + 2px) + 20px)`, //20px padding + (2px gap + 20px square) * number of cols
            flexWrap: "wrap",
          }}
          onMouseLeave={() => setCurrentPoint(null)}
        >
          {items.map((point) => {
            const isSelected =
              currentPoint?.x >= point.x && currentPoint?.y >= point.y;
            return (
              <Grid
                key={`${point.x},${point.y}`}
                item
                onMouseOver={() =>
                  canDrawTable ? setCurrentPoint(point) : () => {}
                }
                onClick={() =>
                  canDrawTable
                    ? createTable(point.x + 1, point.y + 1)
                    : () => {}
                }
                sx={{
                  width: "20px",
                  height: "20px",
                  cursor: canDrawTable ? "pointer" : "default",
                  ...getTableStyles(isSelected),
                }}
              ></Grid>
            );
          })}
        </Grid>
        <MenuItem onClick={() => createTable()}>Insert table...</MenuItem>
      </Popover>
      {createTableDialog && (
        <Dialog open={createTableDialog} onClose={closeDialog} maxWidth="sm">
          <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
            <IconButton onClick={closeDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{ padding: "4px 7px", fontSize: "20px" }}
              />
            </IconButton>
          </Box>
          <DialogTitle>Insert table</DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: 1,
            }}
          >
            <Grid
              item
              xs={10}
              display="flex"
              gap={1}
              p={1}
              alignItems="center"
              justifyContent="space-around"
            >
              <FormBox>Rows</FormBox>
              <FormTextField
                id="rows"
                value={desiredRows}
                error={
                  !desiredRows ||
                  parseInt(desiredRows) < 1 ||
                  parseInt(desiredRows) > 63
                }
                onChange={(event) => {
                  setDesiredRows(event.target.value);
                }}
              />
            </Grid>
            <Grid
              item
              xs={10}
              display="flex"
              gap={1}
              p={1}
              alignItems="center"
              justifyContent="space-around"
            >
              <FormBox>Columns</FormBox>
              <FormTextField
                id="cols"
                value={desiredCols}
                error={
                  !desiredCols ||
                  parseInt(desiredCols) < 1 ||
                  parseInt(desiredCols) > 63
                }
                onChange={(event) => {
                  setDesiredCols(event.target.value);
                }}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={disabled}
              sx={{ marginLeft: "auto" }}
              onClick={() => {
                closeDialog();
                createTable(desiredCols, desiredRows);
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
