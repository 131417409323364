import { Link } from "@mui/material";
import React from "react";
import defaultLogo from "../../../assets/img/defaultlogo.png";
import { getCanveoTier } from "../../../utils/getCanveoTier";
import { dateColumnFormatter } from "../../utils/dateColumnFormatter";

/**
 * @param {import("react-router-dom").NavigateFunction} navigate
 * @param {string} email
 */
export function getColumns(navigate, email, isLawFirm = false) {
  const columns = [
    {
      field: "logo",
      headerName: "Logo",
      renderCell: (/** @type {*} */ params) => {
        return (
          <img
            src={params.row?.logo || defaultLogo}
            alt=""
            height="30px"
            style={{ borderRadius: "50%" }}
          />
        );
      },
      valueGetter: (/** @type {*} */ params) => params.row?.agreement?.agrTitle,
    },
    {
      field: !isLawFirm ? "counterpartyLegalName" : "clientLegalName",
      headerName: !isLawFirm ? "Counterparty Legal Name" : "Client Legal Name",
    },
    {
      field: "area",
      headerName: "Area",
    },
    {
      field: "name",
      headerName: "Name",
      renderCell: (/** @type {*} */ params) => {
        return (
          <Link
            href={"blank"}
            fontWeight="bold"
            onClick={(event) => {
              event.preventDefault();
              navigate(`/agreement/${params.row?.name?._id}`);
            }}
          >
            {params.row?.name?.agrTitle}
          </Link>
        );
      },
      valueGetter: (/** @type {*} */ params) => params.row?.agreement?.agrTitle,
      width: 150,
    },
    {
      field: "type",
      headerName: "Type",
    },
    {
      field: "status",
      headerName: "Status",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      type: "date",
      valueFormatter: dateColumnFormatter,
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      type: "date",
      valueFormatter: dateColumnFormatter,
    },
    {
      field: "signedDate",
      headerName: "Signed Date",
      type: "date",
      valueFormatter: dateColumnFormatter,
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      type: "date",
      valueFormatter: dateColumnFormatter,
    },
    {
      field: "noticeDate",
      headerName: "Notice Date",
      type: "date",
      valueFormatter: dateColumnFormatter,
    },
    {
      field: "source",
      headerName: "Source",
    },
    {
      field: "labels",
      headerName: "Labels",
    },
  ];

  if (getCanveoTier(email) === "experimental") {
    columns.push(
      // @ts-ignore
      {
        field: "agreementParameters",
        headerName: "Properties",
        width: 200,
      },
      {
        field: "nextActionLiesWith",
        headerName: "Next Action Lies With ...",
      }
    );
  }

  columns.push(
    {
      field: "currentAgreementOwner",
      headerName: "Current Agreement Owner",
    },
    {
      field: "agreementCreator",
      headerName: "Agreement Creator",
    }
  );

  return columns;
}
