import {
  faBuilding,
  faCirclePlus,
  faHandshake,
  faPeople,
  faScrewdriverWrench,
  faSection,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FabStandard } from "../../components";
import DialogCreateRole from "../../components/dialogs/DialogCreateRole";
import DialogManageRoles from "../../components/dialogs/DialogManageRoles";
import { globalStore } from "../../state/store";
import { getCanveoTier } from "../../utils/getCanveoTier";

export default function Roles() {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [, setLoading] = useState(true);
  const [roles, setRoles] = useState(/** @type {*[]} */ ([]));
  const [rolesNeedUpdate, setRolesNeedUpdate] = useState(true);
  const [dialogManageRolesOpen, setDialogManageRolesOpen] = useState(false);
  const [dialogCreateRoleOpen, setDialogCreateRoleOpen] = useState(false);
  const [currentRole, setCurrentRole] = useState({});
  const [curPage, setCurPage] = useState(1);
  const pageSize = 10;
  const [nonDeletableRoles] = useState([
    "Admin",
    "Legal",
    "Business",
    "HR",
    "Corporate",
  ]);

  useEffect(
    () => {
      if (rolesNeedUpdate) {
        setLoading(true);
        axios
          .get(`${state.settings.api}role/${state.user.orgID}`)
          .then(({ data }) => {
            const newRoles = data.data.filter(
              (/** @type {{ active: any; }} */ r) => r.active
            );
            setRoles(newRoles);
            setLoading(false);
            setRolesNeedUpdate(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rolesNeedUpdate]
  );

  /**
   * @param {string} action
   * @param {string} message
   * @returns {void}
   */
  function closeRolesDialog(action, message) {
    switch (action) {
      case "roleUpdated":
        setRolesNeedUpdate(true);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: { message: "Role updated", severity: "success" },
        });
        setDialogManageRolesOpen(false);
        break;
      case "roleDeleted":
        setRolesNeedUpdate(true);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: { message: "Role deleted", severity: "success" },
        });
        setDialogManageRolesOpen(false);
        break;
      case "roleCreated":
        setRolesNeedUpdate(true);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: { message: "Role created", severity: "success" },
        });
        setDialogCreateRoleOpen(false);
        break;
      case "error":
        dispatch({
          type: "NEW_SNACKBAR",
          payload: { message, severity: "error" },
        });
        setDialogCreateRoleOpen(false);
        setDialogManageRolesOpen(false);
        break;
      default:
        setDialogCreateRoleOpen(false);
        setDialogManageRolesOpen(false);
        break;
    }
  }

  /**
   * @param {string} roleName
   * @returns {JSX.Element}
   */
  function getIcon(roleName) {
    const styles = {
      height: "1.1rem",
    };
    switch (roleName) {
      case "Admin":
        return <FontAwesomeIcon style={styles} icon={faScrewdriverWrench} />;
      case "Legal":
        return <FontAwesomeIcon style={styles} icon={faSection} />;
      case "Business":
        return <FontAwesomeIcon style={styles} icon={faHandshake} />;
      case "HR":
        return <FontAwesomeIcon style={styles} icon={faPeople} />;
      case "Corporate":
        return <FontAwesomeIcon style={styles} icon={faBuilding} />;
      default:
        return <></>;
    }
  }

  return (
    <Box>
      {getCanveoTier(state?.user?.email) === "experimental" && (
        <FabStandard
          click={() => setDialogCreateRoleOpen(true)}
          text="New"
          icon={faCirclePlus}
          sx={{
            left: "100px",
            top: "80px",
            right: "unset",
          }}
        />
      )}

      <Grid container direction="column" alignItems="center" sx={{ my: 5 }}>
        <Grid item>
          <Typography variant="h4">Manage Roles</Typography>
        </Grid>

        <Grid item sx={{ mt: 2 }}>
          {/* KEEP <Typography variant="body1" display="block" align="center">
            Define for each role which level of access they have, and whether
            they can edit contracts
          </Typography> */}
          <Typography variant="body1" display="block" align="center">
            Define for each role which level of access they will have
          </Typography>
        </Grid>

        <Grid item sx={{ mt: 5 }}>
          <Stack
            direction={"column"}
            sx={{ width: "200px", gap: "1rem", justifyContent: "center" }}
          >
            {roles
              // .filter((u) => u.active)
              .slice((curPage - 1) * pageSize, curPage * pageSize)
              .map((r) => {
                const icon = getIcon(r.name);
                return (
                  <Chip
                    icon={icon}
                    // @ts-ignore
                    color={
                      !nonDeletableRoles.includes(r.name) ? "senary" : "primary"
                    }
                    label={`${r.name} ${
                      r._id === state.user.role._id ? " (You) " : ""
                    }`}
                    sx={{
                      fontWeight: 600,
                    }}
                    key={r._id}
                    onClick={() => {
                      setCurrentRole(r);
                      setDialogManageRolesOpen(true);
                    }}
                  />
                );
              })}
          </Stack>

          {Object.keys(currentRole).length > 0 && (
            <DialogManageRoles
              open={dialogManageRolesOpen}
              currentRole={currentRole}
              closeDialog={closeRolesDialog}
            />
          )}

          <DialogCreateRole
            open={dialogCreateRoleOpen}
            closeDialog={closeRolesDialog}
          />
        </Grid>

        <Grid item sx={{ mt: 5 }}>
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil(
                state.users.filter(
                  (/** @type {{ active: any; }} */ u) => u.active
                ).length / pageSize
              )}
              defaultPage={1}
              page={curPage}
              onChange={(e, newVal) => setCurPage(newVal)}
              siblingCount={0}
              boundaryCount={2}
              renderItem={(item) => <PaginationItem {...item} />}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
