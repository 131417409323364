import { $isTextNode } from "lexical";

/**
 * Recursive function that traverses all child nodes of the parameter node using a depth-first search (DFS)
 * and checks whether some of them are mark nodes.
 *
 * @param {import("lexical").LexicalNode} node
 * @param {string} markType
 * @returns
 */
export function hasMark(node, markType) {
  let isMark = false;

  if (
    node.getType() === "mark" &&
    node.getIDs().some(
      (/** @type {string} */ id) =>
        markType === "publicComment" && id.startsWith("cp_")
      // || (markType === 'internalComment' && id.startsWith("cp") && props.partyID.substring(5) === id.substring(2, id.indexOf('_')))
    )
  ) {
    isMark = true;
  } else if (
    !$isTextNode(node) &&
    Boolean(node.getChildren()) &&
    node.getChildren().length > 0
  ) {
    node
      .getChildren()
      .forEach((/** @type {import("lexical").LexicalNode} */ n) => {
        if (hasMark(n, markType)) {
          isMark = true;
        }
      });
  }

  return isMark;
}
