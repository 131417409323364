import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faArrowLeft, faUserPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import useAgreementData from "../../hooks/useAgreementData";
import { globalStore } from "../../state/store";
import { CanveoCircularProgress } from "../index";
import CreateUserSection from "./SendComponents/CreateUserSection";
import RecipientListSection from "./SendComponents/RecipientListSection";

/**
 * @typedef {object} DialogSendAddCollaboratorProps
 * @property {boolean} open
 * @property {() => {}} handleCloseGroup
 * @property {*} agreement
 */

/**
 * @param {DialogSendAddCollaboratorProps} props
 * @returns {JSX.Element}
 */
export default function DialogSendAddCollaborator({
  open,
  handleCloseGroup,
  agreement,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [party] = useState(
    state.parties?.find(
      (/** @type {{ orgID: string; }} */ p) => p.orgID === state.org._id
    )
  );

  const [closing, setClosing] = useState(false);

  const {
    setCollaboratorCreation,
    setSelectedOrganizationID,
    addCollaborators,
    updateAgreement,
    allUsers,
    collaborators,
    originalCollaborators,
    userCreationType,
    setUserCreationType,
    loading,
    handleSubmitUserForm,
    sendUserInvitations,
  } = useAgreementData(agreement, open, false, state.org._id);

  const initiateClosing = () => {
    const original = originalCollaborators.map((c) => c._id);
    const newVal = collaborators.map((c) => c._id);
    if (closing || JSON.stringify(original) === JSON.stringify(newVal)) {
      return closeDialog();
    }

    setClosing(true);
  };

  const closeDialog = () => {
    handleCloseGroup();
  };

  const handleConfirmationClose = () => {
    sendUserInvitations();
    updateAgreement(true, true);

    closeDialog();
  };

  const handleBack = () => {
    if (closing) return setClosing(false);
    if (!!userCreationType && userCreationType === "collaborator") {
      return setUserCreationType(null);
    }
    if (!userCreationType) initiateClosing();
  };

  const handleChange = useCallback(
    /**
     * @param {*} collabList
     * @param {string} orgID
     */
    (collabList, orgID) => {
      addCollaborators(collabList, orgID, false);
    },
    [addCollaborators]
  );

  return (
    <Dialog open={open} onClose={initiateClosing} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={initiateClosing}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle sx={{ mb: 6 }}>Add Collaborator</DialogTitle>

      <DialogContent>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 12,
            }}
          >
            <CanveoCircularProgress />
          </Box>
        )}

        {!loading && (
          <Box sx={{ my: 2 }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: "center",
                  mb: 2,
                }}
              >
                {!closing && (
                  <>
                    <Typography variant="h4">Add Collaborators</Typography>
                    <Typography
                      // @ts-ignore
                      variant="p"
                      sx={{ mb: 5, mt: 1, display: "block" }}
                    >
                      New collaborators will receive an email to access the
                      agreement
                    </Typography>
                  </>
                )}

                {closing && (
                  <Box>
                    <Typography
                      sx={{ fontWeight: "600", mb: 2 }}
                      align="center"
                    >
                      Do you want to persist the changes made to the
                      collaborators?
                    </Typography>
                    <Typography sx={{ fontWeight: "600" }} align="center">
                      If you added new collaborators, they will receive an email
                      notification with a link to the agreement.
                    </Typography>
                  </Box>
                )}

                {!!userCreationType === false && !closing && (
                  <RecipientListSection
                    party={[party]}
                    collaborators={collaborators}
                    users={allUsers}
                    handleChangeCollaborators={handleChange}
                    handleCreateUser={(/** @type {string} */ orgID) => {
                      setCollaboratorCreation();
                      setSelectedOrganizationID(orgID);
                    }}
                  />
                )}
                {userCreationType === "collaborator" && !closing && (
                  <CreateUserSection
                    handleSubmit={(/** @type {*} */ params) => {
                      handleSubmitUserForm(params);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {!closing && (
          <>
            <Button sx={{ marginRight: "auto" }} onClick={handleBack}>
              <FontAwesomeIcon icon={faArrowLeft} />
              &nbsp;&nbsp;Back
            </Button>
            <Button
              color="secondary"
              disabled={loading} // Only allow adding collab if cpty is not a subscriber
              onClick={setCollaboratorCreation}
            >
              <FontAwesomeIcon icon={faUserPlus} />
              &nbsp;&nbsp;Create collaborator
            </Button>
            <Button
              variant="contained"
              size="small"
              disableElevation
              disabled={
                !!(
                  loading === true ||
                  JSON.stringify(originalCollaborators) ===
                    JSON.stringify(collaborators)
                )
              }
              onClick={handleBack}
            >
              Update collaborators
            </Button>
          </>
        )}

        {closing && (
          <>
            <Button sx={{ marginRight: "auto" }} onClick={handleBack}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disableElevation
              style={{ marginLeft: "12px" }}
              onClick={handleConfirmationClose}
            >
              Confirm
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
