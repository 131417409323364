import { $isTextNode } from "lexical";

/**
 * @param {import("lexical").RangeSelection} selection
 * @returns {boolean}
 */
export function selectionIsAtBeginningOfInline(selection) {
  if (selection.isCollapsed()) {
    // When the selection is collapsed, its anchor and focus both point to the same node
    // so we can get it from either one.
    const node = selection.focus.getNode();
    if ($isTextNode(node)) {
      // If there are no more previous siblings that means we are at the beginning of the inline.
      if (node.getPreviousSiblings().length === 0) {
        // If both the anchor and offset are 0 that means that the selection is at the end of the node.
        if (selection.anchor.offset === 0 && selection.focus.offset === 0) {
          return true;
        }
      }
    } else {
      // TODO: Handle element node.
    }
  } else {
    // TODO: Handle not collapsed selection.
  }

  return false;
}
