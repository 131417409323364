import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { Grid } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { globalStore } from "../../../../state/store";
import CanveoCircularProgress from "../../../CanveoCircularProgress";
import {
  CanveoPlugin,
  ListMaxIndentLevelPlugin,
} from "../../../editor/plugins";
import ClauseTopicScrollerPlugin from "../../../editor/plugins/ClauseTopicScrollerPlugin";
import FloatingContextMenuPlugin from "../../../editor/plugins/FloatingContextMenuPlugin/FloatingContextMenuPlugin";
import ImagesPlugin from "../../../editor/plugins/ImagesPlugin";
import ListExtendedPlugin from "../../../editor/plugins/ListExtendedPlugin";
import PlaybookPlugin from "../../../editor/plugins/PlaybookPlugin";
import { TableMenuPlugin } from "../../../editor/plugins/TableMenuPlugin";
import { editorConfig } from "../../../editor/utils";

/**
 * @typedef {object} PlaybookDrawerEditorProps
 * @property {string} playbookId
 * @property {(clauseTypes: string[]) => void} [onSelectedClauseTypesChange]
 */

/**
 * @param {PlaybookDrawerEditorProps} props
 * @returns {JSX.Element}
 */
export default function PlaybookDrawerEditor({
  playbookId,
  onSelectedClauseTypesChange,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [isLoading, setIsLoading] = useState(false);
  const [playbookVersion, setPlaybookVersion] = useState(
    /** @type {* | undefined} */ (undefined)
  );
  // eslint-disable-next-line no-unused-vars
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(
    /** @type {* | null} */ (null)
  );

  useEffect(
    () => {
      loadPlaybooks();
    },
    // Runs only once, on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  async function loadPlaybooks() {
    try {
      setIsLoading(true);
      const getLatestPlaybookVersionResponse = await axios.get(
        `${state.settings.api}playbook/${playbookId}/versions/latest`
      );
      const playbookVersion = getLatestPlaybookVersionResponse.data.data;
      setPlaybookVersion(playbookVersion);
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "Error getting playbooks",
          severity: "error",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <Grid container justifyContent="center">
        <CanveoCircularProgress />
      </Grid>
    );
  }

  return (
    <>
      {playbookVersion && (
        <>
          {/* <LexicalComposer
            initialConfig={{
              ...editorConfig,
              namespace: "playbook",
              editorState: JSON.stringify(playbookVersion.content),
              editable: false,
            }}
          >
            <RichTextPlugin
              contentEditable={<ContentEditable />}
              placeholder={<div>Enter some text...</div>}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <AutoFocusPlugin />
          </LexicalComposer> */}

          <LexicalComposer
            // @ts-ignore
            initialConfig={{
              ...editorConfig,
              // We need to specify the namespace as "mainBody" here in
              // order to be able to distinguish the main editor (this one)
              // from the editors used below for the header and footer.
              //
              // This is needed so that we can prevent the footer and header
              // editor from populating the global list handler. This logic is
              // implemented in the first useEffect of the ListExtendedPlugin.
              //
              // At the moment, the global list handler is a singleton and only
              // supports one instance across the application hence why we give
              // it priority to the main body instead of the footers and headers.
              //
              // This means that at the moment it is not possible to manually create
              // lists in the editor on footers and headers. To allow this we need to
              // find a way of having a list handler per editor.
              namespace: "playbook-drawer",
              editorState: JSON.stringify(playbookVersion.content),
              // The real editable config is done in the isEditable={isEditable()} in the
              // tracked changes plugin below.
              editable: false,
            }}
          >
            <div
            // className="editor-container"
            // style={{ marginLeft: "40px" }}
            >
              {/* EDITOR START */}
              <CanveoPlugin />

              <RichTextPlugin
                ErrorBoundary={LexicalErrorBoundary}
                contentEditable={
                  <div
                    className="editor"
                    // style={{ opacity: readOnly ? 0.5 : 1 }}
                  >
                    <ContentEditable
                      className="playbook-drawer-editor-input"
                      spellCheck={false}
                    />
                  </div>
                }
                placeholder={<></>}
              />
              <ListExtendedPlugin
                partyId={"party0"}
                // @ts-ignore
                userDisplayName={state.user.displayName}
                user={state.user}
                sfdt={playbookVersion.sfdt}
                listsStructure={playbookVersion.contentMetadata?.listsStructure}
              />
              <HistoryPlugin />
              <TabIndentationPlugin />

              <ImagesPlugin />
              <ListPlugin />
              <LinkPlugin />

              <TablePlugin />
              {floatingAnchorElem && (
                <>
                  <TableMenuPlugin anchorElem={floatingAnchorElem} />

                  <FloatingContextMenuPlugin
                    anchorElem={floatingAnchorElem}
                    isInEffect={true}
                    isTemplate={true}
                  />
                </>
              )}
              <ListMaxIndentLevelPlugin maxDepth={8} />
              <TabIndentationPlugin />
              {/* <TrackChangesPlugin
                partyId={"party0"}
                // @ts-ignore
                userDisplayName={state.user.displayName}
                user={state.user}
                isTemplate={true}
                docID={playbookId}
                isEditable={false}
              /> */}
              {/* <WorkflowPlugin
                partyID={partyID}
                isTemplating={isTemplating}
                docID={docID}
                agrvID={state.drawerVersions.active?._id}
                user={state.user}
              /> */}
              {/* <ClausePlugin partyID={partyID} /> */}
              {/* <ClauseOptionsPlugin
                partyID={partyID}
                docID={docID}
                isTemplating={isTemplating}
                isInEffect={
                  isTemplating
                    ? props.template.active
                    : ["InEffect", "Execution"].includes(mainAg.agrStatus)
                }
              />
              <OpenIssuesPlugin
                anchorElem={floatingAnchorElem}
                partyId={partyID}
                isTemplating={isTemplating}
                docID={docID}
              /> */}

              {/* <CrossReferenceEventsPlugin state={state} /> */}
              <PlaybookPlugin
                type="subscriber"
                onSelectedClauseTypesChange={onSelectedClauseTypesChange}
              />
              <ClauseTopicScrollerPlugin />
            </div>
          </LexicalComposer>
        </>
      )}
    </>
  );
}
