import {
  Autocomplete,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { globalStore } from "../state/store";

const filter = createFilterOptions();

/**
 * @typedef {*} SelectUserForOrgProps
 */

/**
 *
 * @param {SelectUserForOrgProps} props
 * @returns {React.JSX.Element}
 */
export default function SelectUserForOrg({ canCreate, ...props }) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [userOptions, setUserOptions] = useState([]);
  const [hiddenUsers] = useState(props.hiddenUsers ?? []);

  const autoCompleteOnChange = function (
    /** @type {*} */ e,
    /** @type {*} */ newVal
  ) {
    return props.handleSelectUser(newVal);
  };

  const getOptionLabel = function (
    /** @type {{ displayName: string; }} */ option
  ) {
    return option.displayName;
  };

  const renderOption = function (
    /** @type {*} */ props,
    /** @type {*} */ option
  ) {
    return (
      <Typography
        variant={option._id !== undefined ? "body1" : "subtitle1"}
        color={"textPrimary"}
        {...props}
      >
        {option.displayName}
      </Typography>
    );
  };

  const renderInput = function (/** @type {*} */ params) {
    return (
      <TextField
        {...params}
        autoFocus
        label="Search and select user ..."
        inputProps={{
          ...params.inputProps,
          autoComplete: "new-password", // Disables autocomplete and autofill.
        }}
      />
    );
  };

  useEffect(() => {
    // TODO: Pull from online - search as you type.
    if (state.user.orgID === props.orgID) {
      setUserOptions(
        state.users
          .filter((/** @type {{ active: boolean; }} */ u) => u.active)
          .filter(
            (/** @type {{ _id: string; }} */ u) =>
              !hiddenUsers.some(
                (/** @type {{ _id: string; }} */ hu) => hu._id === u._id
              )
          )
      );
    } else {
      setUserOptions(
        props.cpUsers
          .filter((/** @type {{ active: boolean; }} */ u) => u.active)
          .filter(
            (/** @type {{ _id: string; }} */ u) =>
              !hiddenUsers.some(
                (/** @type {{ _id: string; }} */ hu) => hu._id === u._id
              )
          )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterOptions = (
    /** @type {any[]} */ options,
    /** @type {import("@mui/material").FilterOptionsState<any>} */ params
  ) => {
    const filtered = filter(options, params);

    if (params.inputValue === "" && canCreate) {
      filtered.unshift({
        inputValue: params.inputValue,
        displayName: `Create New Signer ...`,
      });
    }
    return filtered;
  };

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      options={userOptions}
      autoHighlight
      openOnFocus
      onChange={autoCompleteOnChange}
      getOptionLabel={getOptionLabel}
      // @ts-ignore
      renderOption={renderOption}
      renderInput={renderInput}
      filterOptions={filterOptions}
    />
  );
}
