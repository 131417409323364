/**
 * @param {import("../../../components/MergeFieldMenu/dialogs/NewConditionDialog/condition").Condition} condition
 */
export const containsAnyOf = ({
  baseValue,
  comparisonValue,
  mergeFieldValue,
}) => {
  switch (baseValue.value.type) {
    case "freeText": {
      if (!(comparisonValue instanceof Array)) {
        throw new Error(
          "Comparison value needs to be a string array when the base value is of type 'freeText'."
        );
      }

      const value = baseValue.value.value;

      if (comparisonValue.some((x) => value.includes(x))) {
        return mergeFieldValue;
      }

      break;
    }

    default:
      throw new Error(
        `Base value type '${baseValue.value.type}' is not a valid type for operator 'containsAnyOf'.`
      );
  }
};
