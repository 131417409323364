import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $patchStyleText } from "@lexical/selection";
import { mergeRegister } from "@lexical/utils";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { $getSelection, $isRangeSelection, $isTextNode } from "lexical";
import React, { useEffect, useState } from "react";
import { FONT_FAMILY_REGEX } from "../../../../utils/constants";
// import { defaultBodyStyles } from "../../utils/constants";
import FontSizeControls from "./ToolbarFontsPlugin/FontSizeControls";

// const defaultFonts = [
//   { name: "Default", value: defaultBodyStyles.fontFamily },
//   { name: "Arial" },
//   { name: "Times New Roman" },
// ];

/**
 * @typedef {*} ToolbarFontsPluginProps
 */

/**
 * @param {ToolbarFontsPluginProps} props
 * @returns {JSX.Element}
 */
export default function ToolbarFontsPlugin({ sfdt, item }) {
  const [editor] = useLexicalComposerContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  /**
   * @param {*} event
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [currentFont, setCurrentFont] = useState("");
  const [availableFonts] = useState(() => {
    /**
     * @type {Set<string>}
     */
    const fontsSet = new Set(["Arial", "Times New Roman"]);

    if (sfdt?.characterFormat?.fontFamily) {
      fontsSet.add(sfdt.characterFormat.fontFamily);
    }

    for (const style of sfdt.styles) {
      const font = style?.characterFormat?.fontFamily;
      if (font && !fontsSet.has(font)) {
        fontsSet.add(font);
      }
    }

    for (const section of sfdt?.sections || []) {
      for (const block of section?.blocks || []) {
        for (const inline of block?.inlines || []) {
          const font = inline?.characterFormat?.fontFamily;
          if (!font) continue;

          fontsSet.add(font);
        }
      }
    }

    const fonts = Array.from(fontsSet).map((font) => ({ name: font }));
    return fonts;
  });

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const target = !selection.isBackward()
              ? selection.anchor
              : selection.focus;
            let textNode;
            if (target.type === "element") {
              textNode = target
                .getNode()
                .getChildren()
                .find((el) => $isTextNode(el));
            } else {
              textNode = target.getNode();
            }
            if (textNode) {
              // const match = textNode.getStyle().match(FONT_FAMILY_REGEX);
              // if (match?.groups?.family) {
              //   const splitted = match.groups.family.split(",");
              //   if (splitted.length > 1) {
              //     setCurrentFont(splitted[0]);
              //   } else {
              //     setCurrentFont(match.groups.family);
              //   }
              // } else {

              const htmlElement = editor.getElementByKey(textNode.getKey());
              if (htmlElement) {
                const computedStyle = window.getComputedStyle(htmlElement);
                // Fonts can come in this format: 'undefined, "Proxima Nova", sans-serif'.
                const font = computedStyle.fontFamily
                  .replaceAll("undefined, ", "")
                  .replaceAll(", sans-serif", "")
                  .replaceAll('"', "");
                setCurrentFont(font);
              }
              // }
            }
          }
        });
      })
    );
  }, [editor]);

  /**
   * @param {{ value: string; name: string; }} fontToApply
   * @returns {void}
   */
  function applyFont(fontToApply) {
    editor.update(() => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return;

      const font = fontToApply.value ?? fontToApply.name;
      $patchStyleText(selection, {
        "font-family": font,
      });
      setCurrentFont(font);
    });
  }

  // useEffect(() => {
  //   if (currentFont !== undefined && currentFont !== undefined) {
  //     const newCurrentStyle = state.editor.currentStyle;
  //     if (newCurrentStyle) {
  //       newCurrentStyle["font-family"] = currentFont;
  //       dispatch({ type: UPDATE_CURRENT_STYLE, payload: newCurrentStyle });
  //     }
  //   }
  // }, [currentFont, dispatch, state.editor.currentStyle]);

  return (
    <>
      <IconButton
        id="fonts-button"
        aria-controls={open ? "fonts-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={(theme) => ({
          fontSize: "14px",
          padding: "10px",
          minWidth: 150,
          maxWidth: 150,
          justifyContent: "left",
          border: "1px solid" + theme.palette.grey[300],
          fontWeight: "bold",
          color: item.disabled
            ? theme.palette.grey[500]
            : theme.palette.grey[800],
          backgroundColor: item.isActive
            ? theme.palette.grey[200]
            : theme.palette.primary.contrastText,
          borderRadius: "5px",
        })}
        disabled={!!currentFont === false}
      >
        <span
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {!!currentFont ? currentFont : "No font selected"}
        </span>
      </IconButton>
      {currentFont && (
        <Menu
          id="fonts-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "fonts-button",
            sx: {
              padding: "0",
              "& .Mui-selected": {
                fontWeight: "bold",
              },
            },
          }}
          PaperProps={{
            sx: {
              borderRadius: "5px",
              padding: "0",
            },
          }}
          TransitionProps={{
            timeout: 0,
          }}
        >
          {/* <MenuItem key={"default"} selected>
            {currentFont}
          </MenuItem>
          <Divider sx={{ mx: 2 }} orientation="horizontal" /> */}
          {availableFonts.map((font, i) => {
            const { name } = font;
            return (
              <MenuItem
                key={`${i}-${name}`}
                disabled={currentFont === name}
                // @ts-ignore
                onClick={() => applyFont(font)}
              >
                {name}
              </MenuItem>
            );
          })}
        </Menu>
      )}
      <FontSizeControls />
    </>
  );
}
