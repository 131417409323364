import { faRepeat } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import { countryToFlag, getColorForPartyID, trunc } from "../utils";

/**
 * @typedef {{ partyID: string; selectEntity: (arg0: any) => void; variant: string; type: *; canCreateEntity: boolean; entities: any[]; }} MenuPopEntitiesProps
 */

/**
 * @param {MenuPopEntitiesProps} props
 */
export default function MenuPopEntities(props) {
  const styles = {
    removeIcon: {
      fontSize: "14px",
      color: getColorForPartyID(props.partyID),
      "&:hover": {
        color: getColorForPartyID(props.partyID),
        cursor: "pointer",
      },
    },
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSwitchClick = (/** @type {*} */ event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectEntity = (/** @type {*} */ id) => {
    props.selectEntity(id);
    handleClose();
  };

  return (
    <>
      {["bigbutton"].includes(props.variant) ? (
        <Button
          variant="contained"
          color={props.type}
          disableElevation
          size="large"
          sx={{ padding: "7px 30px" }}
          onClick={handleSwitchClick}
        >
          Select&nbsp;&nbsp;
          <FontAwesomeIcon icon={faRepeat} />
        </Button>
      ) : (
        <FontAwesomeIcon
          icon={faRepeat}
          style={styles.removeIcon}
          onClick={handleSwitchClick}
        />
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.canCreateEntity && (
          <MenuItem onClick={(e) => selectEntity({ toCreate: true })}>
            <ListItemText
              primary={
                <Typography variant="subtitle1" color={"textPrimary"}>
                  Create New Legal Entity …
                </Typography>
              }
            />
          </MenuItem>
        )}
        {props.entities
          .sort((a, b) =>
            a.legalName > b.legalName ? 1 : b.legalName > a.legalName ? -1 : 0
          )
          .map((ent, i) => (
            <MenuItem key={i} onClick={(e) => selectEntity(ent._id)}>
              <ListItemText
                primary={
                  <>
                    {trunc(ent.legalName, 27)}
                    {ent.address !== undefined &&
                    ent.address[0] !== undefined &&
                    ent.address[0].country !== undefined
                      ? countryToFlag(ent.address[0].country)
                      : ""}

                    {ent.defaultLegalEntity ? (
                      <>
                        {" "}
                        <b>{"(Default)"}</b>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                }
              />
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
