/**
 * @param {string} version
 * @param {{version: string}[]} allVersions
 */
export const getNewVersion = (version, allVersions) => {
  const [major] = version.split(".");
  const result = allVersions
    .find((v) => v.version.startsWith(major))
    ?.version?.split(".");

  if (result) {
    const [, biggestMinor] = result;

    if (biggestMinor) return `${major}.${parseInt(biggestMinor) + 1}`;

    return `${major}.1`;
  }

  throw new Error("Malformed versions.");
};
