import { $isListNode, ListItemNode } from "@lexical/list";
import { $dfs, $getNearestNodeOfType } from "@lexical/utils";
import { $applyNodeReplacement } from "lexical";
import { GlobalListHandler } from "../plugins/ListExtendedPlugin/GlobalListHandler";
import { getListItemsFromDfs } from "../plugins/ListExtendedPlugin/getListItemsFromDfs";
import { $populateListHandler } from "../plugins/ListExtendedPlugin/populateListHandler";
import {
  getIndentationStyles,
  getNonThemePaddingStyles,
} from "../plugins/ListExtendedPlugin/utils";
import {
  NOT_ACCEPTED_BULLET_MARKERS,
  TYPE_LISTITEM_NODE,
} from "../utils/constants";
import {
  $createCustomListNode,
  $isCustomListNode,
  CustomListNode,
} from "./CustomListNode";
import { getFormattedListStyleType } from "./utils/getFormattedListStyleType";

export class CustomListItemNode extends ListItemNode {
  /** @private @type {string?} */ __hasMarker;
  /** @private @type {number} */ __listId;
  /** @private @type {string} The styleName according to the SFDT. */ __styleName;
  /** @private @type {string} */ __paddingStyles;
  /** @type {string} */ __blockStyles;

  static getType() {
    return TYPE_LISTITEM_NODE;
  }

  /**
   * @param {*} value
   * @param {*} checked
   * @param {*} key
   * @param {*} hasMarker
   * @param {*} blockStyles
   * @param {*} paddingStyles
   * @param {*} styleName
   */
  constructor(
    value,
    checked,
    key,
    hasMarker = true,
    blockStyles = "",
    paddingStyles = "",
    styleName = "List Paragraph"
  ) {
    super(value, checked, key);
    this.__hasMarker = hasMarker;
    this.__blockStyles = blockStyles;
    this.__paddingStyles = paddingStyles;
    this.__listId = -1;
    this.__styleName = styleName;
  }

  /**
   * @type {string}
   */
  get styleName() {
    const self = this.getLatest();
    return self.__styleName;
  }

  set styleName(newStyleName) {
    const self = this.getWritable();
    self.__styleName = newStyleName;
  }

  /**
   * @param {*} blockStyles
   */
  setBlockStyles(blockStyles) {
    const self = this.getWritable();
    self.__blockStyles = blockStyles;
    return this;
  }

  /**
   * @param {*} padding
   */
  setPaddingStyles(padding) {
    const self = this.getWritable();
    self.__paddingStyles = padding;
    return this;
  }

  getPaddingStyles() {
    const self = this.getLatest();
    return self.__paddingStyles;
  }

  getBlockStyles() {
    const self = this.getLatest();
    return self.__blockStyles;
  }

  /**
   * @param {number} listId
   */
  setListId(listId) {
    const self = this.getWritable();
    self.__listId = listId;
    return this;
  }

  getListId() {
    const self = this.getLatest();
    return self.__listId;
  }

  /**
   * @param {number} val
   */
  setValue(val) {
    super.setValue(val);
  }

  getHasMarker() {
    const self = this.getLatest();
    return self.__hasMarker;
  }

  /**
   * @param {string} hasMarker
   */
  setHasMarker(hasMarker) {
    const self = this.getWritable();
    self.__hasMarker = hasMarker;
  }

  updateDOM() {
    return true;
  }

  static transform() {
    return (/** @type {*} */ node) => {
      const parent = node.getParent();
      if ($isListNode(parent)) {
        if (parent.getListType() !== "check" && node.getChecked() != null) {
          node.setChecked(undefined);
        }
      }
    };
  }

  /**
   * @param {*} config
   */
  createDOM(config) {
    // console.log("CustomListItemNode > createDOM");
    const element = super.createDOM(config);
    element.style.listStyleType = "none";

    const listHandler = GlobalListHandler.getInstance();
    const isValidListItem = !element.classList.contains("nestedListItem");

    let listStyleType;
    let level;

    if (isValidListItem && listHandler.root?.length && listHandler.lists) {
      let current = listHandler.root.find((el) => el.id === this.getKey());

      if (!current) {
        const listItems = getListItemsFromDfs($dfs());
        const result = $populateListHandler(
          listItems,
          listHandler.currentSfdt.abstractLists
        );

        listHandler.lists = result.lists;
        listHandler.root = result.root;
      }

      current = listHandler.root.find((el) => el.id === this.getKey());

      if (current) {
        // TODO: Change to new struct.
        const parent = listHandler.root.find((p) => p.id === current.parentId);
        let listHandlerList = listHandler.lists.find(
          (l) => l.abstractListId === current.listId
        );
        // if (!listHandlerList) {
        //   const listId = listHandler?.currentSfdt?.styles?.find(
        //     (x) => x.name === this.styleName
        //   )?.paragraphFormat?.listFormat?.listId;

        //   listHandlerList = listHandler.lists.find(
        //     (l) => l.abstractListId === listId
        //   );
        // }

        if (!listHandlerList) {
          // TODO: This should never happen; temporary fix.
          console.warn(
            `Could not find a corresponding abstract list on the list handler with ID ${current.listId}.`
          );
          element.className = `${element.className} ${
            !this.getHasMarker() ? config.theme.list.listitemnomarker : ""
          }`;
          return element;
        }

        const list = listHandlerList.levels[current.level];
        const parentListType = parent
          ? list.listLevelPattern.toLowerCase()
          : "number"; //default number?

        if (
          !NOT_ACCEPTED_BULLET_MARKERS.includes(list.numberFormat.charCodeAt(0))
        ) {
          const cleanNumberFormat = list.numberFormat.replace("­", "-");
          listStyleType = getFormattedListStyleType(
            GlobalListHandler.getInstance(),
            this.getKey(),
            cleanNumberFormat,
            /** @type {MarkerPattern} */ (list.listLevelPattern),
            this.getValue(),
            parentListType === "Bullet" ? "bullet" : "number",
            this.styleName
          );
          if (listStyleType) {
            element.style.setProperty("--before-content", listStyleType);
            // element.style.listStyleType = listStyleType;
          }
        }
        // else stays default

        let listHandlerList2 = listHandler.lists.find(
          (l) => l.abstractListId === current.listId
        );
        // if (!listHandlerList2) {
        //   const listId = listHandler?.currentSfdt?.styles?.find(
        //     (x) => x.name === this.styleName
        //   )?.paragraphFormat?.listFormat?.listId;

        //   listHandlerList2 = listHandler.lists.find(
        //     (l) => l.abstractListId === listId
        //   );
        // }
        if (!listHandlerList2) {
          // TODO: This should never happen; temporary fix.
          console.warn(
            `Could not find a corresponding abstract list on the list handler with ID ${current.listId}.`
          );
          element.className = `${element.className} ${
            !this.getHasMarker() ? config.theme.list.listitemnomarker : ""
          }`;
          return element;
        }

        const levels = listHandlerList2.levels;
        level = levels[current.level];

        // Does have style?
        let paddingStyles = "";
        const themeStyle = listHandler.currentSfdt.styles.find((el) => {
          const listFormat = el.paragraphFormat?.listFormat;
          if (listFormat) {
            // if has listFormat
            if (listFormat.listId === current.listId) {
              // if it's same list id
              return (
                el.name === this.__styleName
                // && (listFormat.listLevelNumber
                //   ? listFormat.listLevelNumber === current.level
                //   : current.level === 0)
              ); // if has same level of indentation (when it's 0, listLevelNumber is undefined)
            }
            // else theme style doesn't have style for list
          }
          return false;
        });

        if (themeStyle) {
          if (themeStyle?.characterFormat?.allCaps === true) {
            element.style.setProperty("text-transform", "uppercase");
          }

          // Does style provide any padding?
          const { left, right } = getIndentationStyles(
            undefined,
            themeStyle,
            undefined
          );
          if (left !== undefined || right !== undefined) {
            // Yes
            paddingStyles += `padding-left: ${left}px;`;
            if (right) paddingStyles += `padding-right: ${right}px;`;
            // Override (don't use block or level)
          } else {
            // No, get block and level padding
            const result = getNonThemePaddingStyles(level, paddingStyles);
            if (result) {
              paddingStyles += result;
            } else {
              if (current.level > 0) {
                const referenceLevelStylesWithFallback =
                  getNonThemePaddingStyles(
                    levels[level.restartLevel - 1],
                    paddingStyles,
                    true
                  );
                if (referenceLevelStylesWithFallback) {
                  // plus 1 defaultTabWidth
                  paddingStyles += referenceLevelStylesWithFallback;
                  level.blockPaddingStyles = paddingStyles;
                } else {
                  const message =
                    "Something went wrong calculating indentation styles.";
                  console.error(message);
                  // throw new Error(message);
                }
              }
            }
          }
        } else {
          const parent =
            /** @type {import("./CustomListNode").CustomListNode} */ (
              this.getParent()
            );

          /** @type {Partial<import("./CustomListNode").ParagraphFormat> | undefined} */
          const parenteParagraphFormat =
            parent?.getParagraphFormat && parent?.getParagraphFormat();
          // No, get block and level padding.
          paddingStyles += getNonThemePaddingStyles(
            level,
            paddingStyles,
            false,
            false,
            parenteParagraphFormat
              ? { paragraphFormat: parenteParagraphFormat }
              : undefined
          );
        }

        // Need to fetch padding styles from style and list and join them together.
        element.style.cssText += `${paddingStyles}${this.__blockStyles ?? ""}`;
        // Make sure font weight is not inherited from block styles.
        element.style.fontWeight = "";

        // For marker.
        if (level.blockStyles) {
          // Only inherit if it's a corresponding styleName or similar approach
          // it might be same list but not same marker styling.
          const stylesArray = level.blockStyles.split(";");
          for (const st of stylesArray) {
            if (
              st.startsWith("font-size") ||
              st.startsWith("font-family") ||
              st.startsWith("color") ||
              st.startsWith("font-style") ||
              st.startsWith("font-weight")
            ) {
              const propertySplitted = st.split(":");
              element.style.cssText += ` --marker-${propertySplitted[0]}: ${propertySplitted[1]};`;
            }
          }
        }
      }
    }

    element.className = `${element.className} ${
      !this.getHasMarker() ? config.theme.list.listitemnomarker : ""
    }`;

    // In order to not change the previous existing logic we do this hacky solution
    // to replace the padding-left with margin-left if it exists.
    if (element.style.paddingLeft) {
      const paddingLeftValue = element.style.removeProperty("padding-left");
      element.style.setProperty("margin-left", paddingLeftValue);
      // element.style.setProperty(
      //   "margin-left",
      //   `calc(${paddingLeftValue} + ${element.style.fontSize})`
      // );
      // element.style.setProperty(
      //   "--list-item-level-indentation",
      //   paddingLeftValue
      // );
    } else {
      // Try getting font size from child this.getChildren()[0]
      // element.style.setProperty("margin-left", "16px");
    }

    const customListNodeAncestor = $getNearestNodeOfType(this, CustomListNode);
    if (customListNodeAncestor) {
      const blockParagraphFormat = customListNodeAncestor.getParagraphFormat();

      // Overrides previous code and enforces the right way of calculating the left margin on
      // lists. The margin can come from three different places with the following precedence:
      // first the block paragraph format, then the list level paragraph format and finally the
      // style paragraph format.
      if (level && level.paragraphFormat) {
        let firstLineIndent = 0;
        if (
          blockParagraphFormat &&
          typeof blockParagraphFormat.firstLineIndent === "number" &&
          Number.isFinite(blockParagraphFormat.firstLineIndent)
        ) {
          firstLineIndent = blockParagraphFormat.firstLineIndent;
        } else {
          const listLevelParagraphFormat = level?.paragraphFormat;
          if (
            listLevelParagraphFormat &&
            typeof listLevelParagraphFormat.firstLineIndent === "number" &&
            Number.isFinite(listLevelParagraphFormat.firstLineIndent)
          ) {
            firstLineIndent = listLevelParagraphFormat.firstLineIndent;
          } else {
            const styleName = blockParagraphFormat?.styleName;
            const style = listHandler?.currentSfdt?.styles?.find(
              (style) => style?.name === styleName
            );

            if (style && style.paragraphFormat) {
              const styleParagraphFormat = style.paragraphFormat;
              if (
                styleParagraphFormat &&
                typeof styleParagraphFormat.firstLineIndent === "number" &&
                Number.isFinite(styleParagraphFormat.firstLineIndent)
              ) {
                firstLineIndent = styleParagraphFormat.firstLineIndent;
              }
            }
          }
        }

        let leftIndent = 0;
        if (
          blockParagraphFormat &&
          typeof blockParagraphFormat.leftIndent === "number" &&
          Number.isFinite(blockParagraphFormat.leftIndent)
        ) {
          leftIndent = blockParagraphFormat.leftIndent;
        } else {
          const listLevelParagraphFormat = level?.paragraphFormat;
          if (
            listLevelParagraphFormat &&
            typeof listLevelParagraphFormat.leftIndent === "number" &&
            Number.isFinite(listLevelParagraphFormat.leftIndent)
          ) {
            leftIndent = listLevelParagraphFormat.leftIndent;
          } else {
            const styleName = blockParagraphFormat?.styleName;
            const style = listHandler?.currentSfdt?.styles?.find(
              (style) => style?.name === styleName
            );

            if (style && style.paragraphFormat) {
              const styleParagraphFormat = style.paragraphFormat;
              if (
                styleParagraphFormat &&
                typeof styleParagraphFormat.leftIndent === "number" &&
                Number.isFinite(styleParagraphFormat.leftIndent)
              ) {
                leftIndent = styleParagraphFormat.leftIndent;
              }
            }
          }
        }

        const indent = leftIndent + firstLineIndent;
        element.style.setProperty("margin-left", indent + "px");
      }

      let beforeSpacing = -1;
      if (
        blockParagraphFormat &&
        typeof blockParagraphFormat.beforeSpacing === "number" &&
        Number.isFinite(blockParagraphFormat.beforeSpacing)
      ) {
        beforeSpacing = blockParagraphFormat.beforeSpacing;
      } else {
        const listLevelParagraphFormat = level?.paragraphFormat;
        if (
          listLevelParagraphFormat &&
          typeof listLevelParagraphFormat.beforeSpacing === "number" &&
          Number.isFinite(listLevelParagraphFormat.beforeSpacing)
        ) {
          beforeSpacing = listLevelParagraphFormat.beforeSpacing;
        } else {
          const styleName = blockParagraphFormat?.styleName;
          const style = listHandler?.currentSfdt?.styles?.find(
            (style) => style?.name === styleName
          );

          if (style && style.paragraphFormat) {
            const styleParagraphFormat = style.paragraphFormat;
            if (
              styleParagraphFormat &&
              typeof styleParagraphFormat.beforeSpacing === "number" &&
              Number.isFinite(styleParagraphFormat.beforeSpacing)
            ) {
              beforeSpacing = styleParagraphFormat.beforeSpacing;
            }
          }
        }
      }
      if (beforeSpacing > 0) element.style.marginTop = `${beforeSpacing}px`;

      let lineSpacing = -1;
      let lineSpacingType = "";
      if (
        blockParagraphFormat &&
        typeof blockParagraphFormat.lineSpacing === "number" &&
        Number.isFinite(blockParagraphFormat.lineSpacing)
      ) {
        lineSpacing = blockParagraphFormat.lineSpacing;
        lineSpacingType = blockParagraphFormat.lineSpacingType || "";
      } else {
        const listLevelParagraphFormat = level?.paragraphFormat;
        if (
          listLevelParagraphFormat &&
          typeof listLevelParagraphFormat.lineSpacing === "number" &&
          Number.isFinite(listLevelParagraphFormat.lineSpacing)
        ) {
          lineSpacing = listLevelParagraphFormat.lineSpacing;
          lineSpacingType = listLevelParagraphFormat.lineSpacingType || "";
        } else {
          const styleName = blockParagraphFormat?.styleName;
          const style = listHandler?.currentSfdt?.styles?.find(
            (style) => style?.name === styleName
          );

          if (style && style.paragraphFormat) {
            const styleParagraphFormat = style.paragraphFormat;
            if (
              styleParagraphFormat &&
              typeof styleParagraphFormat.lineSpacing === "number" &&
              Number.isFinite(styleParagraphFormat.lineSpacing)
            ) {
              lineSpacing = styleParagraphFormat.lineSpacing;
              lineSpacingType = styleParagraphFormat.lineSpacingType || "";
            }
          }
        }
      }
      if (lineSpacing > 0 && lineSpacingType) {
        switch (lineSpacingType) {
          case "AtLeast":
          case "Exactly": {
            element.style.lineHeight = `${lineSpacing}px`;
            break;
          }

          case "Multiple":
          default: {
            element.style.lineHeight = `${lineSpacing}`;
            break;
          }
        }
      }

      let afterSpacing = -1;
      if (
        blockParagraphFormat &&
        typeof blockParagraphFormat.afterSpacing === "number" &&
        Number.isFinite(blockParagraphFormat.afterSpacing)
      ) {
        afterSpacing = blockParagraphFormat.afterSpacing;
      } else {
        const listLevelParagraphFormat = level?.paragraphFormat;
        if (
          listLevelParagraphFormat &&
          typeof listLevelParagraphFormat.afterSpacing === "number" &&
          Number.isFinite(listLevelParagraphFormat.afterSpacing)
        ) {
          afterSpacing = listLevelParagraphFormat.afterSpacing;
        } else {
          const styleName = blockParagraphFormat?.styleName;
          const style = listHandler?.currentSfdt?.styles?.find(
            (style) => style?.name === styleName
          );

          if (style && style.paragraphFormat) {
            const styleParagraphFormat = style.paragraphFormat;
            if (
              styleParagraphFormat &&
              typeof styleParagraphFormat.afterSpacing === "number" &&
              Number.isFinite(styleParagraphFormat.afterSpacing)
            ) {
              afterSpacing = styleParagraphFormat.afterSpacing;
            }
          }
        }
      }
      if (afterSpacing > 0) element.style.marginBottom = `${afterSpacing}px`;

      if (blockParagraphFormat.styleName) {
        const styleName = blockParagraphFormat.styleName;
        const themeStyle = listHandler?.currentSfdt?.styles?.find(
          (style) => style?.name === styleName
        );
        if (themeStyle?.characterFormat?.allCaps === true) {
          element.style.setProperty("text-transform", "uppercase");
        }
      }

      const blockCharacterFormat = customListNodeAncestor.getCharacterFormat();
      if (
        blockCharacterFormat &&
        typeof blockCharacterFormat.fontSize === "number" &&
        Number.isFinite(blockCharacterFormat.fontSize)
      ) {
        element.style.setProperty(
          "--list-item-marker-font-size", // Variable defined in index.css.
          `${blockCharacterFormat.fontSize}px`
        );
      } else {
        const fontSize = element.style.getPropertyValue("font-size");
        if (fontSize) {
          element.style.setProperty(
            "--list-item-marker-font-size", // Variable defined in index.css.
            fontSize
          );
        }
      }
    }

    return element;
  }

  /**
   * @returns {*}
   */
  static importDOM() {
    return {
      li: () => ({
        conversion: convertListItemElement,
        priority: 0,
      }),
    };
  }

  /** @param {CustomListItemNode} node */
  static clone(node) {
    const cloneNode = new CustomListItemNode(
      node.__value,
      node.__checked,
      node.__key,
      node.__hasMarker,
      node.__blockStyles,
      node.__paddingStyles,
      node.__styleName
    );
    cloneNode.__listId = node.__listId;
    return cloneNode;
  }

  /**
   * @param {*} replaceWithNode
   * @param {*} includeChildren
   * @returns {*}
   */
  replace(replaceWithNode, includeChildren) {
    if ($isCustomListItemNode(replaceWithNode)) {
      return super.replace(replaceWithNode);
    }
    this.setIndent(0);
    const list = this.getParentOrThrow();
    if (!$isListNode(list)) return replaceWithNode;
    if (list.__first === this.getKey()) {
      list.insertBefore(replaceWithNode);
    } else if (list.__last === this.getKey()) {
      list.insertAfter(replaceWithNode);
    } else {
      // Split the list
      const newList = $createCustomListNode(
        list.getListType(),
        list.getListType()
      );
      newList.setMarker(list.getMarker(), list.getMarkerPattern());
      let nextSibling = this.getNextSibling();
      while (nextSibling) {
        const nodeToAppend = nextSibling;
        nextSibling = nextSibling.getNextSibling();
        newList.append(nodeToAppend);
      }
      list.insertAfter(replaceWithNode);
      replaceWithNode.insertAfter(newList);
    }
    if (includeChildren) {
      this.getChildren().forEach((child) => {
        replaceWithNode.append(child);
      });
    }
    this.remove();
    if (list.getChildrenSize() === 0) {
      list.remove();
    }
    return replaceWithNode;
  }

  /**
   * @param {*} serializedNode
   */
  static importJSON(serializedNode) {
    const node = new CustomListItemNode(
      serializedNode.value,
      serializedNode.checked,
      serializedNode.key,
      serializedNode.hasMarker,
      serializedNode.blockStyles,
      serializedNode.paddingStyles,
      serializedNode.styleName
    );
    node.setListId(serializedNode.listId);
    node.setFormat(serializedNode.format);
    node.setIndent(serializedNode.indent);
    node.setDirection(serializedNode.direction);
    return node;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      hasMarker: this.__hasMarker,
      blockStyles: this.__blockStyles,
      paddingStyles: this.__paddingStyles,
      type: TYPE_LISTITEM_NODE,
      value: this.__value,
      listId: this.__listId,
      styleName: this.__styleName,
      version: 1,
    };
  }
}

/**
 *  Convert hyphen/underscore string format to camel case.
 *
 * @param {string} str
 */
export const toCamelCase = (str) => {
  let newStr = "";
  if (str) {
    let wordArr = str.split(/[-_]/g);
    for (let i in wordArr) {
      // @ts-ignore
      if (i > 0) {
        newStr += wordArr[i].charAt(0).toUpperCase() + wordArr[i].slice(1);
      } else {
        newStr += wordArr[i];
      }
    }
  } else {
    return newStr;
  }
  return newStr;
};

function convertListItemElement() {
  // @ts-ignore
  return { node: $createCustomListItemNode(this.__hasMarker) };
}

/**
 * @param {*} node
 */
export const isNestedListNode = (node) => {
  return $isCustomListItemNode(node) && $isCustomListNode(node.getFirstChild());
};

/**
 * @deprecated
 * @param {*} node
 * @param {*} nodesToAppend
 */
// eslint-disable-next-line no-unused-vars
function append(node, nodesToAppend) {
  node.splice(node.getChildrenSize(), 0, nodesToAppend);
}

/**
 * @param {*} listItem
 */
function $getListItemValue(listItem) {
  const list = listItem.getParent();

  let value = 1;

  if (list != null) {
    if (!$isListNode(list)) {
      throw new Error(
        "$getListItemValue: list node is not parent of list item node"
      );
    } else {
      value = list.getStart();
    }
  }

  const siblings = listItem.getPreviousSiblings();
  for (let i = 0; i < siblings.length; i++) {
    const sibling = siblings[i];

    if (
      $isCustomListItemNode(sibling) &&
      !$isListNode(sibling.getFirstChild())
    ) {
      value++;
    }
  }
  return value;
}

/**
 * @param {*} list
 * @param {*} children
 */
export function updateChildrenListItemValue(list, children) {
  const childrenOrExisting = children || list.getChildren();
  if (childrenOrExisting !== undefined) {
    for (let i = 0; i < childrenOrExisting.length; i++) {
      const child = childrenOrExisting[i];
      if ($isCustomListItemNode(child)) {
        const prevValue = child.getValue();
        const nextValue = $getListItemValue(child);

        if (prevValue !== nextValue) {
          child.setValue(nextValue);
        }
      }
    }
  }
}

/**
 *
 * @param {boolean} hasMarker
 * @param {number} value
 * @returns {CustomListItemNode}
 */
export function $createCustomListItemNode(
  hasMarker = true,
  value = 1,
  blockStyles = "",
  paddingStyles = "",
  styleName = "List Paragraph"
) {
  return $applyNodeReplacement(
    new CustomListItemNode(
      value,
      undefined,
      undefined,
      hasMarker,
      blockStyles,
      paddingStyles,
      styleName
    )
  );
}

/**
 * @param {*} node
 * @returns {node is CustomListItemNode}
 */
export function $isCustomListItemNode(node) {
  return node instanceof CustomListItemNode;
}
