import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import useFloatingContextMenu from "./useFloatingContextMenu";

/**
 * @typedef {object} FloatingContextMenuPluginProps
 * @property {HTMLElement} anchorElem
 * @property {boolean} isInEffect
 * @property {boolean} isTemplate
 */

/**
 * @param {FloatingContextMenuPluginProps} props
 * @returns {React.ReactPortal | null}
 */
export default function FloatingContextMenuPlugin({
  anchorElem = document.body,
  isInEffect,
  isTemplate,
}) {
  const [editor] = useLexicalComposerContext();
  return useFloatingContextMenu(editor, anchorElem, isInEffect, isTemplate);
}
