import { $isHeadingNode } from "@lexical/rich-text";
import { $patchStyleText, getStyleObjectFromCSS } from "@lexical/selection";
import { $getNearestNodeOfType } from "@lexical/utils";
import {
  $createLineBreakNode,
  $createTabNode,
  $createTextNode,
  $isElementNode,
  $isParagraphNode,
  $isRangeSelection,
  $isTextNode,
} from "lexical";
import { v4 as uuid } from "uuid";
import defaultUserAvatar from "../../../../assets/img/defaultuser.png";
import { TESTING } from "../../../../utils/constants";
import {
  $isCustomHeadingNode,
  CustomHeadingNode,
} from "../../nodes/CustomHeadingNode";
import { $createImageNode } from "../../nodes/ImageNode";
import { $createMarkNode } from "../../nodes/MarkNode";
import { $createRedlineNode } from "../../nodes/RedlineNode";
import { createUID } from "../../plugins/commenting";
import { SUPPORTED_FORMAT_TYPES } from "../../utils/constants";
import { createComment } from "./createComment";
import getInitials from "./getInitials";

/**
 * Receives an SFDT Block and Inline
 * and returns the equivalent Lexical TextNode with the same formatting.
 *
 * @param {import("../../types/sfdt").Sfdt} sfdt SFDT
 * @param {import("../../types/sfdt").Block} block Current SFDT Block
 * @param {import("../../types/sfdt").Inline} inline Current SFDT Inline
 * @param {string | null | undefined} blockType Block type
 * @returns {import("lexical").TextNode}}
 */
const createTextNodeWithFormatting = (sfdt, block, inline, blockType) => {
  if (!inline.text) throw new Error("Missing inline text.");

  const textNode = $createTextNode(inline?.text);

  // If it is a paragraph with custom theme styling.
  if (!!blockType && blockType !== "Paragraph") {
    applyThemeStyle(sfdt, block, blockType, textNode);
  }

  applySimpleFormatting(inline, textNode);
  applyParagraphFormatting(block, inline, textNode, blockType);

  return textNode;
};

/**
 * Receives an SFDT Block and Inline and returns the equivalent Lexical
 * TabNode with equivalent formatting.
 *
 * @param {import("../../types/sfdt").Sfdt} sfdt SFDT
 * @param {import("../../types/sfdt").Block} block Current SFDT Block
 * @param {import("../../types/sfdt").Inline} inline Current SFDT Inline
 * @param {string | null | undefined} blockType Block type
 * @returns {import("lexical").TabNode}}
 */
function createTabNodeWithFormatting(sfdt, block, inline, blockType) {
  if (!inline.text) throw new Error("Missing inline text.");

  const tabNode = $createTabNode();

  // If it is a paragraph with custom theme styling.
  if (!!blockType && blockType !== "Paragraph") {
    applyThemeStyle(sfdt, block, blockType, tabNode);
  }

  applySimpleFormatting(inline, tabNode);
  applyParagraphFormatting(block, inline, tabNode, blockType);

  return tabNode;
}

/**
 *  Parse font family based on
 *  fontFamilyNonFarEast, fontFamilyAscii & fontFamilyBidi
 *
 * @param {any[]} value
 * @param {string} fallback
 * @returns {string | null} Return font family string in case it's a theme font, else it returns null.
 */
const parseSFDTFontFamily = (value, fallback = "") => {
  //0 => NonFarEast
  //1 => Ascii
  //2 => fontFamilyBidi
  if ([value[0], value[1], value[2]].every((v) => v === "majorHAnsi")) {
    return "Calibri Light" + fallback;
  } else if (
    [value[0], value[1]].every((v) => v === undefined) &&
    value[2] === "minorHAnsi"
  ) {
    return "Calibri" + fallback;
  } else if (
    [value[0], value[1]].every((v) => v === "majorEastAsia") &&
    value[2] === "majorHAnsi"
  ) {
    return "DengXian Light" + fallback;
  } else if (
    [value[0], value[1]].every((v) => v === "minorEastAsia") &&
    value[2] === "majorHAnsi"
  ) {
    return "DengXian";
  } else if ([value[0], value[1], value[2]].every((v) => v === "majorBidi")) {
    return "Times New Roman" + fallback;
  } else if (
    [value[0], value[1]].every((v) => v === "minorBidi") &&
    value[2] === undefined
  ) {
    return "Arial" + fallback;
  } else {
    return null;
  }
};

/**
 * Applies simple formatting to Text Node
 *
 * @param {import("../../types/sfdt").Inline | import("../../types/sfdt").styles} inline Current SFDT Inline or Theme style
 * @param {import("lexical").TextNode} textNode Text node to apply styles on
 */
const applySimpleFormatting = (inline, textNode) => {
  if (inline.characterFormat?.bold) {
    textNode.toggleFormat("bold");
  }
  if (inline.characterFormat?.italic) {
    textNode.toggleFormat("italic");
  }
  if (
    inline.characterFormat?.underline &&
    inline.characterFormat?.underline !== "None"
  ) {
    textNode.toggleFormat("underline");
  }
  if (
    inline.characterFormat?.strikethrough &&
    inline.characterFormat?.strikethrough !== "None"
  ) {
    textNode.toggleFormat("strikethrough");
  }
  if (inline.characterFormat?.highlightColor) {
    textNode.toggleFormat("highlight");
    const parsedHighlightColor = encodeHighlightColor(
      // @ts-ignore
      inline.characterFormat?.highlightColor
    );

    textNode.setStyle(
      `--highlight-color: ${parsedHighlightColor};${textNode.getStyle()}`
    );
  }
  if (inline.characterFormat?.baselineAlignment === "Subscript") {
    textNode.toggleFormat("subscript");
  }
  if (inline.characterFormat?.baselineAlignment === "Superscript") {
    textNode.toggleFormat("superscript");
  }
};

/**
 * Applies paragraph formatting to Text Node
 *
 * @param {import("../../types/sfdt").Block} block Current SFDT block
 * @param {import("../../types/sfdt").Inline | import("../../types/sfdt").styles} inline Current SFDT Inline or Theme style
 * @param {string | null | undefined} blockType Block type
 * @param {import("lexical").TextNode} textNode Text node to apply styles on
 */
const applyParagraphFormatting = (block, inline, textNode, blockType) => {
  const patchStyles = {},
    unit = "px";
  if (!!blockType && blockType !== "Paragraph") {
    patchStyles["styleName"] = blockType;
  }
  /**
   * Font family comes in 4 properties
   * fontFamily, fontFamilyNonFarEast, fontFamilyAscii & fontFamilyBidi
   * The first one defines the font-family
   * The latter three combined define theme fonts
   */
  const fontFamilyOptions = [
    inline.characterFormat?.fontFamilyNonFarEast,
    inline.characterFormat?.fontFamilyAscii,
    inline.characterFormat?.fontFamilyBidi,
  ];
  if (fontFamilyOptions.some((k) => k !== undefined)) {
    const fallback = ', "Proxima Nova", sans-serif';
    patchStyles["font-family"] =
      parseSFDTFontFamily(fontFamilyOptions, fallback) ??
      inline.characterFormat?.fontFamily + fallback;
    patchStyles["fontFamilyNonFarEast"] = fontFamilyOptions[0];
    patchStyles["fontFamilyAscii"] = fontFamilyOptions[1];
    patchStyles["fontFamilyBidi"] = fontFamilyOptions[2];
  }
  /**
   * Font size shows up in 2 properties
   * fontSize & fontSizeBidi
   * Default representation is in pt although we need to append "pt"
   * when applying on lexical
   */
  if (inline.characterFormat?.fontSize) {
    patchStyles["font-size"] = `${inline.characterFormat?.fontSize}${unit}`;
  }
  /**
   * Font colors shows up as 1 properties
   * fontColor
   * Default representation is in hexadecimal
   */
  if (inline.characterFormat?.fontColor) {
    const shouldRemoveTransparency =
      inline.characterFormat?.fontColor === "#00000000";
    patchStyles["color"] = `${
      !shouldRemoveTransparency
        ? inline.characterFormat?.fontColor
        : inline.characterFormat?.fontColor.substring(0, 7) //7 characters => #123456
    }`;
  }

  if (JSON.stringify(patchStyles) !== JSON.stringify({})) {
    const selection = textNode.select(0, textNode.getTextContent().length);
    if ($isRangeSelection(selection)) {
      // @ts-ignore
      $patchStyleText(selection, patchStyles);
      return selection.style;
    }
  }
};

/**
 * Applies theme styles to Text Node
 *
 * @param {import("../../types/sfdt").Sfdt} sfdt Current SFDT
 * @param {import("../../types/sfdt").Block} block Current SFDT block
 * @param {string | null | undefined} blockType Block type
 * @param {import("lexical").TextNode} textNode Text Node to apply styles on
 */
const applyThemeStyle = (sfdt, block, blockType, textNode) => {
  const { styles } = sfdt;
  if (styles.length > 0) {
    //if there's a custom style on theme
    let blockThemeStyle = styles.find((s) => s.name === blockType);
    if (blockThemeStyle) {
      if (
        !(
          (!blockThemeStyle?.characterFormat ||
            Object.keys(blockThemeStyle?.characterFormat).length) &&
          (!blockThemeStyle?.paragraphFormat ||
            Object.keys(blockThemeStyle?.paragraphFormat).length)
        )
      ) {
        /**
         *
         * @param {import("../../types/sfdt").styles} target
         * @returns {import("../../types/sfdt").styles | undefined}
         */
        const findFn = (target) =>
          styles.find((s) => s.name === target?.basedOn);
        while (
          !(
            (!blockThemeStyle?.characterFormat ||
              Object.keys(blockThemeStyle?.characterFormat).length) &&
            (!blockThemeStyle?.paragraphFormat ||
              Object.keys(blockThemeStyle?.paragraphFormat).length)
          ) &&
          blockThemeStyle?.basedOn
        ) {
          const basedOn = findFn(blockThemeStyle);
          if (basedOn) blockThemeStyle = basedOn;
          else break;
        }
        if (blockThemeStyle?.basedOn === "Normal") {
          blockThemeStyle.characterFormat = sfdt.characterFormat;
        }
      }
      //apply theme styles
      applySimpleFormatting(blockThemeStyle, textNode);
      applyParagraphFormatting(block, blockThemeStyle, textNode, blockType);
    }
  }
};

/**
 * @typedef styleResult
 * @property {string} key
 * @property {string} value
 */
/**
 * Receives node styles and parses them into an array of key/value
 *
 * @param {string} style
 * @return {styleResult[]}
 */
const parseStyles = (style) => {
  const splittedStyles = style.split(";").filter((v) => !!v && v.length > 0);
  const result = splittedStyles.map((st) => {
    const [key, value] = st.split(":", 2);
    return { key: key, value: value.trim() };
  });
  return result;
};

/**
 * Formats font properties for sfdt
 *
 * @param {string} p styleResult key
 * @returns {string}
 */
const getFontPropertyFormatted = (p) => {
  const excludeProperties = [
    "fontFamilyBidi",
    "fontFamilyAscii",
    "fontFamilyNonFarEast",
  ];
  if (!excludeProperties.includes(p)) {
    //color property comes as color (css)
    const property = p === "color" ? "font-color".substring(5) : p.substring(5);
    return `font${property.charAt(0).toUpperCase()}${property.slice(1)}`; //fontFamily, fontSize, fontColor
  }
  return p;
};

/**
 * Gets value from styleResult
 *
 * @param {styleResult} kv styleResult pair
 * @returns {string | number | undefined}
 */
const getValue = (kv) => {
  if (kv.key === "font-family") {
    return kv.value.split(",")[0];
  } else if (kv.key === "font-size") {
    return Number(kv.value.slice(0, -2));
  } else if (["font-color", "color"].includes(kv.key)) {
    const shouldRemoveTransparency = kv.value === "#00000000";
    if (shouldRemoveTransparency) {
      return kv.value.slice(0, 7);
    }
    return kv.value;
  }
  return undefined;
};

/** Gets text node format and returns css rule string
 *
 * @param {import("lexical").TextNode} textNode
 * @returns {string} css rule string
 */
const parseTextNodeFormat = (textNode) => {
  let parsedFormat = "";
  if (textNode.hasFormat("bold")) {
    parsedFormat += "font-weight: bold;";
  }
  if (textNode.hasFormat("italic")) {
    parsedFormat += "font-style: italic;";
  }
  if (textNode.hasFormat("underline")) {
    parsedFormat += "text-decoration: underline;";
  }
  if (textNode.hasFormat("strikethrough")) {
    parsedFormat += "text-decoration: line-through;";
  }
  if (textNode.hasFormat("highlight")) {
    parsedFormat += "background-color: yellow;";
  }
  if (textNode.hasFormat("subscript")) {
    parsedFormat += "vertical-align: sub;";
  }
  if (textNode.hasFormat("superscript")) {
    parsedFormat += "vertical-align: super;";
  }
  return parsedFormat;
};

/**
 *
 * @param {import("../../types/sfdt").Sfdt} sfdt
 * @param {import("../../types/sfdt").Block | import("../../types/sfdt").AbstractListLevel} arg
 */
const convertBlockStylesToCSS = (sfdt, arg) => {
  let css = "";
  const styleName = arg.paragraphFormat?.styleName;
  const blockHasStyleName = !!arg.paragraphFormat?.styleName;
  if (blockHasStyleName) {
    const themeStyleBlock = sfdt.styles.find((el) => el.name === styleName);
    if (themeStyleBlock && themeStyleBlock.characterFormat) {
      //derive styles from theme style
      css = generateCSSfromSfdt(themeStyleBlock);
    } else {
      css = generateCSSfromSfdt(arg);
    }
  } else {
    //can it get both if so need to merge stuff first
    css = generateCSSfromSfdt(arg);
  }
  return css;
};

/**
 *
 * @param {string} css
 * @param {import("../../types/sfdt").Block} block
 * @returns {void}
 */
export const convertCSStoBlockIndent = (css, block) => {
  /** @type {RegExpMatchArray | null} */
  // @ts-ignore
  const matchLeft = css.match(/padding-left:\s*(?<val>[0-9]+)[a-zA-Z]{2,3}/);
  if (matchLeft?.groups?.val) {
    if (block.paragraphFormat) {
      //found leftIndent
      block.paragraphFormat.leftIndent = parseInt(matchLeft?.groups?.val);
    }
  }
  // @ts-ignore
  const matchRight = css.match(/padding-right:\s*(?<val>[0-9]+)[a-zA-Z]{2,3}/);
  if (matchRight?.groups?.val) {
    if (block.paragraphFormat) {
      //found leftIndent
      block.paragraphFormat.rightIndent = parseInt(matchRight?.groups?.val);
    }
  }
};
/**
 * Generates css from an sfdt block or theme style
 *
 * @param {any} arg
 * @returns {string}
 */
const generateCSSfromSfdt = (arg) => {
  //arg can be {import("../../types/sfdt").styles | import("../../types/sfdt").Block}
  let css = "",
    unit = "px";
  if (arg.characterFormat) {
    /**
     * Font family comes in 4 properties
     * fontFamily, fontFamilyNonFarEast, fontFamilyAscii & fontFamilyBidi
     * The first one defines the font-family
     * The latter three combined define theme fonts
     */
    const fontFamilyOptions = [
      arg.characterFormat.fontFamilyNonFarEast,
      arg.characterFormat.fontFamilyAscii,
      arg.characterFormat.fontFamilyBidi,
    ];
    if (fontFamilyOptions.some((k) => k !== undefined)) {
      const fallback = ', "Proxima Nova", sans-serif';
      css +=
        "font-family:" +
        (parseSFDTFontFamily(fontFamilyOptions, fallback) ??
          arg.characterFormat?.fontFamily + fallback) +
        ";";
      css += "fontFamilyNonFarEast:" + fontFamilyOptions[0] + ";";
      css += "fontFamilyAscii:" + fontFamilyOptions[1] + ";";
      css += "fontFamilyBidi:" + fontFamilyOptions[2] + ";";
    }
    /**
     * Font size shows up in 2 properties
     * fontSize & fontSizeBidi
     * Default representation is in pt although we need to append "pt"
     * when applying on lexical
     */
    if (arg.characterFormat?.fontSize) {
      css += `font-size:${arg.characterFormat?.fontSize}${unit};`;
    }
    /**
     * Font colors shows up as 1 properties
     * fontColor
     * Default representation is in hexadecimal
     */
    if (arg.characterFormat?.fontColor) {
      const shouldRemoveTransparency =
        arg.characterFormat.fontColor === "#00000000";
      css += `color:${
        !shouldRemoveTransparency
          ? arg.characterFormat?.fontColor
          : arg.characterFormat?.fontColor.slice(0, 7)
      };`;
    }
    if (arg.characterFormat?.bold) {
      css += "font-weight: bold;";
    }
    if (arg.characterFormat?.italic) {
      css += "font-style: italic;";
    }
    if (
      arg.characterFormat?.underline &&
      arg.characterFormat?.underline !== "None"
    ) {
      css += "text-decoration: underline;";
    }
    if (
      arg.characterFormat?.strikethrough &&
      arg.characterFormat?.strikethrough !== "None"
    ) {
      css += "text-decoration: line-through;";
    }
    if (arg.characterFormat?.highlightColor) {
      css += "background-color: yellow;";
    }
    if (arg.characterFormat?.baselineAlignment === "Subscript") {
      css += "vertical-align: sub;";
    }
    if (arg.characterFormat?.baselineAlignment === "Superscript") {
      css += "vertical-align: super;";
    }
  }

  return css;
};

/**
 *
 * @param {undefined | null | "Black"| "Blue"| "Red"| "Teal"| "Green"| "DarkRed"| "DarkBlue"| "Turquoise"| "Pink"| "Violet"| "DarkYellow"| "Gray50"| "Gray25"| "BrightGreen"| "Yellow"} color
 * @returns {string}
 */
const encodeHighlightColor = (color) => {
  if (!color) return "Yellow";
  switch (color) {
    case "Black":
    case "Blue":
    case "Red":
    case "Teal":
    case "Green":
    case "DarkRed":
    case "DarkBlue":
      return color;
    case "Turquoise":
      return "#02ffff";
    case "Pink":
      return "#ff00ff";
    case "Violet":
      return "purple";
    case "DarkYellow":
      return "#949400";
    case "Gray50":
      return "Gray";
    case "Gray25":
      return "LightGray";
    case "BrightGreen":
      return "Lime";
    default:
      return "Yellow";
  }
};
/**
 *
 * @param {undefined | null | "#02ffff"| "#ff00ff"| "purple"| "#949400"| "Gray"| "LightGray"| "Lime" | "Black"| "Blue"| "Red"| "Teal"| "Green"| "DarkRed"| "DarkBlue"} color
 * @returns {string}
 */
const decodeHighlightColor = (color) => {
  if (!color) return "Yellow";
  switch (color) {
    case "Black":
    case "Blue":
    case "Red":
    case "Teal":
    case "Green":
    case "DarkRed":
    case "DarkBlue":
      return color;
    case "#02ffff":
      return "Turquoise";
    case "#ff00ff":
      return "Pink";
    case "purple":
      return "Violet";
    case "#949400":
      return "DarkYellow";
    case "Gray":
      return "Gray50";
    case "LightGray":
      return "Gray25";
    case "Lime":
      return "BrightGreen";
    default:
      return "Yellow";
  }
};

/**
 * Generates an inline (typically for export purposes).
 * Converts node format to styles, indentation on a block level
 * and font related formatting
 *
 * @param {import("lexical").TextNode | import("../../nodes").RedlineNode} node
 * @param {import("../../types/sfdt").Block} block
 * @param {styleResult[]} styles
 * @returns {import("../../types/sfdt").Inline}
 */
const generateInline = (node, block, styles) => {
  /** @type {import("../../types/sfdt").Inline} */
  const result = {
    text: node.getTextContent(),
  };
  if ((node.getFormat() ?? -1) !== 0) {
    result.characterFormat = { bidi: false };
    SUPPORTED_FORMAT_TYPES.forEach((format) => {
      if (node.hasFormat(format)) {
        switch (format) {
          case "highlight":
            //@ts-ignore
            result.characterFormat.highlightColor = "Yellow";
            break;
          case "strikethrough":
            //@ts-ignore
            result.characterFormat.strikethrough = "SingleStrike";
            break;
          case "underline":
            //@ts-ignore
            result.characterFormat.underline = "Single";
            break;
          case "subscript":
            //@ts-ignore
            result.characterFormat.baselineAlignment = "Subscript";
            break;
          case "superscript":
            //@ts-ignore
            result.characterFormat.baselineAlignment = "Superscript";
            break;
          default:
            //@ts-ignore
            result.characterFormat[format] = true;
            break;
        }
      }
    });
  }
  //Export indentation
  const indentation = styles.filter((st) =>
    ["padding-left", "indentBlock", "indentFirstLine"].includes(st.key)
  );
  if (indentation.length > 0) {
    const padding = indentation.find((el) => el.key === "padding-left");
    const indentBlock = indentation.find((el) => el.key === "indentBlock");
    const indentFirstLine = indentation.find(
      (el) => el.key === "indentFirstLine"
    );
    if (padding) {
      if (!block.paragraphFormat) {
        block.paragraphFormat = {};
      }
      const valueToExport = parseInt(padding.value.slice(0, -2));
      if (indentBlock?.value) {
        block.paragraphFormat.leftIndent = valueToExport;
      } else if (indentFirstLine?.value) {
        block.paragraphFormat.firstLineIndent = valueToExport;
      }
    }
  }

  //Export font family/size/color
  const fontFormats = styles.filter((st) =>
    [
      "font-family",
      "fontFamilyNonFarEast",
      "fontFamilyAscii",
      "fontFamilyBidi",
      "font-size",
      "color",
    ].includes(st.key)
  );
  if (fontFormats.length > 0) {
    for (const ff of fontFormats) {
      const key = getFontPropertyFormatted(ff.key);
      const rawValue = getValue(ff);
      const value = rawValue === "undefined" ? undefined : rawValue;
      if (!result.characterFormat) {
        result.characterFormat = {
          [key]: value,
        };
      } else {
        //@ts-ignore
        result.characterFormat[key] = value;
      }
    }
    if (
      !result.characterFormat?.fontFamilyNonFarEast &&
      !result.characterFormat?.fontFamilyAscii &&
      !result.characterFormat?.fontFamilyBidi &&
      result.characterFormat?.fontFamily
    ) {
      const ff = result.characterFormat?.fontFamily;
      result.characterFormat.fontFamilyNonFarEast = ff;
      result.characterFormat.fontFamilyAscii = ff;
      result.characterFormat.fontFamilyBidi = ff;
    }
  }

  const parentIsCustomHeadingNode = $getNearestNodeOfType(
    node,
    CustomHeadingNode
  );
  if (parentIsCustomHeadingNode) {
    if (block.characterFormat) {
      if (result.characterFormat) {
        result.characterFormat = {
          ...result.characterFormat,
          ...block.characterFormat,
        };
      } else {
        result.characterFormat = block.characterFormat;
      }
    }
  }

  return result;
};

/**
 * @typedef {object} ComplementaryData
 * @property {Map<string, import("../../types/sfdt").Comment>} sfdtCommentsMap
 * @property {Set<string>} ongoingCommentsSet
 * @property {any} user - Current user information
 * @property {any[]} collabs - Current user information
 * @property {Record<string, any>} metadata
 */

/**
 * Parse inlines recursively
 *
 * @param {import("../../types/sfdt").Block} block
 * @param {import("../../types/sfdt").Inline[]} inlines
 * @param {import("lexical").LexicalNode[]} nodes
 * @param {import("../../types/sfdt").Sfdt} sfdt
 * @param {ComplementaryData} complementaryData
 * @param {import("../../types/sfdt").Section} section
 * @param {*[]} organizationUsers
 * @param {string} [blockType]
 */
const parseInlines = (
  block,
  inlines,
  nodes,
  sfdt,
  complementaryData,
  section,
  organizationUsers,
  blockType = ""
) => {
  const { sfdtCommentsMap, ongoingCommentsSet, metadata, collabs, user } =
    complementaryData;
  let skip = 0;
  for (let i = 0; i < inlines.length; i++) {
    const currentInline = inlines[i];
    if (currentInline.contentControlProperties) {
      const innerInlines = currentInline.inlines;
      if (innerInlines && inlines.length) {
        // Iterate inlines.
        parseInlines(
          block,
          innerInlines,
          nodes,
          sfdt,
          complementaryData,
          section,
          organizationUsers,
          blockType
        );
      }
    } else {
      if (skip === 0) {
        if (currentInline.commentId) {
          /** @type {import("../convertLexicalToSfdt").CanveoComment | undefined} */
          let workflow, sfdtCommentIndex;
          const currentCommentId = currentInline.commentId; //sfdt.comments
          let realCommentId =
            (inlines[i - 1]?.name ?? inlines[i + 1]?.name)?.substring(
              5
            ) /** _cid_ (5 chars) */ ?? currentInline.commentId;
          if (currentCommentId !== realCommentId) {
            try {
              workflow =
                /** @type {import("../convertLexicalToSfdt").CanveoComment[]} */ (
                  metadata.workflows
                ).find(
                  (el) => el.lid === realCommentId || el._id === realCommentId
                );
              const sfdtComment = sfdt.comments?.find(
                (e) =>
                  e.commentId === currentCommentId ||
                  e.commentId === realCommentId
              );
              if (!sfdtComment || !sfdt.comments || !workflow) {
                throw new Error("Couldn't find comment to compare.");
              }

              sfdtCommentIndex = sfdt.comments.indexOf(sfdtComment);
              sfdt.comments[sfdtCommentIndex].commentId = realCommentId;
            } catch (e) {
              //ignore error
            }
            // ir a base dados => sacar info
            //workflows/realcommentid
            //sfdt.comments[currentCommentid]
          }

          let hasComment = Array.from(sfdtCommentsMap.values()).find(
            (v) => v.commentId === realCommentId
          );
          // If the comment ID is not on the `sfdtCommentsMap` it means that it
          // is a comment ID of a reply comment instead of a top-level comment
          // so we ignore it.
          if (!!hasComment && sfdt.comments) {
            switch (currentInline.commentCharacterType) {
              case 0:
                if (workflow) {
                  /** @type {import("../../types/sfdt").Comment[]} */
                  const mappedComments = workflow.comments
                    .slice(1) //remove first
                    .filter((el) => el.content !== "===RESOLVED===") //remove resolved comments
                    .reverse() //order
                    .map((el) => ({
                      author: el.creator.displayName,
                      blocks: [{ inlines: [{ text: el.content }] }],
                      commentId: el._id,
                      date: el.date,
                      initial: getInitials(el.creator.displayName),
                      done: false,
                      replyComments: [],
                    }));

                  if (mappedComments.length && sfdtCommentIndex) {
                    sfdt.comments[sfdtCommentIndex].replyComments.forEach(
                      (existingReply) => {
                        const newReply = mappedComments.find(
                          (x) => x.commentId === existingReply.commentId
                        );
                        if (newReply) {
                          existingReply = newReply;
                        }
                      }
                    );
                  }
                }
                if (!realCommentId.startsWith("cp_")) {
                  if (!hasComment.realId) {
                    realCommentId = createUID() + "_party0"; //pseudo random might result in future errors
                    //@ts-ignore
                    hasComment.realId = realCommentId;
                  } else {
                    realCommentId = hasComment.realId;
                  }
                } else {
                  realCommentId = realCommentId.substring(3);
                }
                ongoingCommentsSet.add(realCommentId); //vai ser adicionado ao editor => tem que existir
                //damos flag
                break;
              case 1:
                const { realId } = hasComment;
                if ((realId ?? realCommentId).startsWith("cp_")) {
                  realCommentId = realCommentId.substring(3);
                }
                ongoingCommentsSet.delete(realId ?? realCommentId);
                if (realId) {
                  const sfdtComment = sfdt.comments?.find(
                    (e) => e.commentId === realCommentId
                  );
                  if (!sfdtComment) {
                    throw new Error("Could not find comment.");
                  }
                  const sfdtCommentIndex = sfdt.comments.indexOf(sfdtComment);
                  sfdt.comments[sfdtCommentIndex].commentId = realId;
                  delete hasComment.realId;
                }
                break;
              default:
                break;
            }
          }
        }

        // Skip bookmarks used just for metadata persistence.
        if (
          currentInline.name?.startsWith("_c_") ||
          currentInline.name?.startsWith("_a_") ||
          currentInline.name?.startsWith("_e_") ||
          currentInline.name?.startsWith("_o_") ||
          currentInline.name?.startsWith("_p_") ||
          currentInline.name?.startsWith("_cid_") // Comment ID.
        ) {
          continue;
        }

        if (
          currentInline.revisionIds &&
          !(currentInline.bookmarkType === 0 || currentInline.fieldType === 0)
        ) {
          //means there is tracked changes here
          const revisionsIds = currentInline.revisionIds;
          const revisionObjects = sfdt.revisions?.filter((r) =>
            revisionsIds.includes(r.revisionId)
          );
          if (revisionObjects) {
            let previousRev = revisionObjects[0],
              /** @type {RedlineType} */
              currentType = ["MoveTo", "Insertion"].includes(
                previousRev.revisionType
              )
                ? "add"
                : "del";
            revisionObjects.sort(
              /**
               *
               * @param {import("../../types/sfdt").Revision} a
               * @param {import("../../types/sfdt").Revision} b
               * @returns {number}
               */
              (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
            );
            revisionObjects
              .slice(1)
              .forEach(
                (/** @type {import("../../types/sfdt").Revision} */ rev) => {
                  if (previousRev.revisionId !== rev.revisionId) {
                    if (rev.revisionType === "Insertion") {
                      currentType = "add";
                    } else {
                      currentType = "del";
                    }
                  }
                  previousRev = rev;
                }
              );
            const { author, date } = previousRev;
            if (
              currentInline.text !== null &&
              currentInline.text !== undefined
            ) {
              const redline = createRedlineFromInlineWithRevision(
                metadata,
                collabs,
                organizationUsers,
                author,
                user,
                currentType,
                date,
                currentInline
              );

              // Same logic that we do inside createTextNodeWithFormatting for text nodes.
              // If it's a paragraph with custom theme styling e.g., Title, Subtitle.
              if (!!blockType && blockType !== "Paragraph") {
                applyThemeStyle(sfdt, block, blockType, redline);
              }
              applySimpleFormatting(currentInline, redline);
              applyParagraphFormatting(
                block,
                currentInline,
                redline,
                blockType
              );

              if (ongoingCommentsSet.size) {
                const markNode = createComment(
                  ongoingCommentsSet,
                  sfdtCommentsMap,
                  metadata,
                  collabs,
                  user,
                  redline,
                  organizationUsers
                );
                nodes.push(markNode);
              } else {
                nodes.push(redline);
              }
            }
          }
        } else if (currentInline.text) {
          if (currentInline.text === "\t") {
            const tabNode = createTabNodeWithFormatting(
              sfdt,
              block,
              currentInline,
              blockType
            );
            nodes.push(tabNode);
            continue;
          }

          if (currentInline.text === "\v") {
            const lineBreakNode = $createLineBreakNode();
            nodes.push(lineBreakNode);
            continue;
          }

          // Each text fragment can have different formatting so we create the TextNode taking that into account.
          const textNode = createTextNodeWithFormatting(
            sfdt,
            block,
            currentInline,
            blockType
          );

          if (ongoingCommentsSet.size) {
            const markNode = createComment(
              ongoingCommentsSet,
              sfdtCommentsMap,
              metadata,
              collabs,
              user,
              textNode,
              organizationUsers
            );
            nodes.push(markNode);
          } else {
            nodes.push(textNode);
          }
        } else if (sfdt.images && currentInline.imageString) {
          //is an image
          const docWidth =
            section.sectionFormat.pageWidth -
            (section.sectionFormat.leftMargin +
              section.sectionFormat.rightMargin);
          const args = {
            altText: currentInline.alternativeText,
            height: currentInline.height,
            maxWidth: 500,
            captionsEnabled: false,
            src: sfdt.images[
              /** @type {keyof typeof sfdt.images} */ (
                currentInline.imageString
              )
            ],
            width: currentInline.width,
            showCaption: false,
            caption: "",
            isInline: currentInline.isInlineImage,
            horizontalPosition: currentInline.horizontalPosition,
            origin: currentInline.horizontalOrigin,
            float:
              docWidth / 2 < (currentInline.horizontalPosition ?? 0)
                ? "right"
                : "left",
            key: undefined,
          };

          const imageNode = $createImageNode(args, currentInline);

          if (ongoingCommentsSet.size) {
            const markNode = createComment(
              ongoingCommentsSet,
              sfdtCommentsMap,
              metadata,
              collabs,
              user,
              imageNode,
              organizationUsers
            );
            nodes.push(markNode);
          } else {
            // @ts-ignore
            nodes.push(imageNode);
          }
        } else if (
          currentInline.bookmarkType === 0 ||
          currentInline.fieldType === 0
        ) {
          // If we're in the context of a comment ignore handling merge fields and cross-references.
          if (ongoingCommentsSet.size === 0) {
            // Handle merge field creation.
            if (
              currentInline.name &&
              currentInline.name.startsWith("_mf_") &&
              currentInline.bookmarkType === 0
            ) {
              // @ts-ignore
              const match = currentInline.name.match(/_mf_(?<id>\w+)/);
              const id = match?.groups?.id;
              if (id && metadata?.mergeFields) {
                let mergeFieldContainsCommentInside = false;
                let index = i + 1;
                let inline = inlines[index];
                // Do a first pass through the merge field's contents to check if there are any
                // comments inside.
                while (
                  !inline?.name?.startsWith("_mf_") &&
                  inline?.bookmarkType !== 1
                ) {
                  if (inline?.commentId) {
                    mergeFieldContainsCommentInside = true;
                    break;
                  }
                  index++;
                  inline = inlines[index];
                }

                // We ignore merge fields if they have comments inside or starting inside.
                if (!mergeFieldContainsCommentInside) {
                  //import merge field
                  //bookmarktype 0
                  //merge field content
                  //bookmark type 1
                  /** @type {import("../../nodes/MarkNode").MergeField[]} */
                  const mergeFieldsArr = metadata.mergeFields;
                  let thisMergeField = mergeFieldsArr.find(
                    (mf) => mf.editorMarkNodeId === id
                  );
                  if (TESTING) {
                    //@ts-ignore
                    thisMergeField = {
                      editorMarkNodeId: uuid(),
                    };
                  }
                  if (!thisMergeField) {
                    throw new Error("Unexistent merge field.");
                  }

                  const uploader = collabs.find(
                      (x) => x.partyID === metadata?.partyId
                    ) ||
                      collabs.find((x) => x.email === user?.email) || {
                        ...user,
                        partyID: "party0",
                      },
                    collab = metadata
                      ? collabs.find((x) => x.email === user?.email)
                      : undefined;

                  /** @type {import("../../nodes/RedlineNode").NodeMetadata} */
                  const meta = {
                    partyId: collab ? collab.partyID : uploader.partyID,
                    creatorDisplayName: collab
                      ? collab.displayName
                      : uploader.displayName,
                    creationDate: metadata.createdAt,
                    creatorEmail: collab ? collab.email : uploader.email,
                    creatorId: collab ? collab._id : uploader.email,
                    creatorPhotoUrl: collab ? collab.photoURL : user?.photoURL,
                  };
                  const mergeField = $createMarkNode(
                    [!TESTING ? id : uuid()],
                    meta,
                    thisMergeField
                  );

                  let currentIndex = i + 1;
                  let currentInline = inlines[currentIndex];
                  while (!currentInline?.name?.startsWith("_mf_")) {
                    // TODO: If we find a comment ignore the merge field.

                    // TODO: This is the exact same code as above; we should refactor
                    // it to only exist in one place.
                    if (
                      currentInline.revisionIds &&
                      !(
                        currentInline.bookmarkType === 0 ||
                        currentInline.fieldType === 0
                      )
                    ) {
                      // Means there are tracked changes here.
                      const revisionsIds = currentInline.revisionIds;
                      const revisionObjects = sfdt.revisions?.filter((r) =>
                        revisionsIds.includes(r.revisionId)
                      );
                      if (revisionObjects) {
                        let previousRev = revisionObjects[0],
                          /** @type {RedlineType} */
                          currentType = ["MoveTo", "Insertion"].includes(
                            previousRev.revisionType
                          )
                            ? "add"
                            : "del";
                        revisionObjects.sort(
                          /**
                           * @param {import("../../types/sfdt").Revision} a
                           * @param {import("../../types/sfdt").Revision} b
                           * @returns {number}
                           */
                          (a, b) =>
                            new Date(a.date).getTime() -
                            new Date(b.date).getTime()
                        );
                        revisionObjects
                          .slice(1)
                          .forEach(
                            (
                              /** @type {import("../../types/sfdt").Revision} */ rev
                            ) => {
                              if (previousRev.revisionId !== rev.revisionId) {
                                if (rev.revisionType === "Insertion") {
                                  currentType = "add";
                                } else {
                                  currentType = "del";
                                }
                              }
                              previousRev = rev;
                            }
                          );
                        const { author, date } = previousRev;
                        if (
                          currentInline.text !== null &&
                          currentInline.text !== undefined
                        ) {
                          const redline = createRedlineFromInlineWithRevision(
                            metadata,
                            collabs,
                            organizationUsers,
                            author,
                            user,
                            currentType,
                            date,
                            currentInline
                          );

                          if (ongoingCommentsSet.size) {
                            const markNode = createComment(
                              ongoingCommentsSet,
                              sfdtCommentsMap,
                              metadata,
                              collabs,
                              user,
                              redline,
                              organizationUsers
                            );
                            mergeField.append(markNode);
                          } else {
                            mergeField.append(redline);
                          }
                        }
                      }
                    } else if (currentInline.text) {
                      if (currentInline.text !== "\t") {
                        // Each text fragment can have different formatting, so we create
                        // the text node taking that into account.
                        const textNode = createTextNodeWithFormatting(
                          sfdt,
                          block,
                          currentInline,
                          blockType
                        );

                        if (ongoingCommentsSet.size) {
                          const markNode = createComment(
                            ongoingCommentsSet,
                            sfdtCommentsMap,
                            metadata,
                            collabs,
                            user,
                            textNode,
                            organizationUsers
                          );
                          mergeField.append(markNode);
                        } else {
                          mergeField.append(textNode);
                        }
                      } else {
                        const tabNode = createTabNodeWithFormatting(
                          sfdt,
                          block,
                          currentInline,
                          blockType
                        );
                        mergeField.append(tabNode);
                      }
                    }

                    currentIndex++;
                    skip++;
                    currentInline = inlines[currentIndex];
                  }

                  if (ongoingCommentsSet.size) {
                    const publicCommentIds = Array.from(ongoingCommentsSet).map(
                      (cId) => ["cp", "_", cId].join("")
                    );
                    publicCommentIds.forEach((id) => mergeField.addID(id));
                  }
                  nodes.push(mergeField);
                }
              } else {
                throw new Error("Malformed merge field.");
              }
            }
            // Handle cross-reference creation.
            else {
              // Ignore cross-references for now.
              //returns a cross reference node
              //checking if exists any in between
              // const newCrossRefNode = checkForCrossReferences(
              //   undefined,
              //   sfdt,
              //   block,
              //   section,
              //   inlines,
              //   currentInline,
              //   i,
              //   [],
              //   [],
              //   complementaryData,
              //   blockType
              // );
              // if (newCrossRefNode) {
              //   if (ongoingCommentsSet.size) {
              //     const markNode = createComment(
              //       ongoingCommentsSet,
              //       sfdtCommentsMap,
              //       metadata,
              //       collabs,
              //       user,
              //       newCrossRefNode
              //     );
              //     nodes.push(markNode);
              //   } else {
              //     // @ts-ignore
              //     nodes.push(newCrossRefNode);
              //   }
              //   const dfs = $dfs(newCrossRefNode).filter(({ node }) =>
              //     $isCrossRefNode(node)
              //   );
              //   //must do dfs to count all child, grandchild,...
              //   if (dfs.length) {
              //     let skip2 = 0;
              //     dfs.forEach(({ node }) => {
              //       const filteredData = node
              //         .getData()
              //         .filter(
              //           (/** @type {import("../../types/sfdt").Inline}*/ el) =>
              //             (el.name && el.name === node.getTarget()) || !el.name
              //         );
              //       skip2 += filteredData.length;
              //     });
              //     skip += skip2;
              //   }
              //   skip--;
              // }
            }
          }
        }
      } else if (skip > 0) {
        skip--;
      }
    }
  }
};

/**
 * @callback PatchFn
 * @param {import("lexical").TextNode} node
 */

/**
 * @param {import("lexical").RangeSelection} target
 * @param {PatchFn} patch
 */
export const customPatchStyles = (target, patch) => {
  if (!$isRangeSelection(target)) {
    return;
  }
  const selectedNodes = target.getNodes();
  const selectedNodesLength = selectedNodes.length;
  const lastIndex = selectedNodesLength - 1;
  let firstNode = selectedNodes[0];
  let lastNode = selectedNodes[lastIndex];
  if (target.isCollapsed()) {
    if (target.anchor.type === "text") {
      /** @type {import("lexical").TextNode} */
      const node = target.anchor.getNode();
      patch(node);
      return;
    }
  }

  const anchor = target.anchor;
  const focus = target.focus;
  const firstNodeText = firstNode.getTextContent();
  const firstNodeTextLength = firstNodeText.length;
  const focusOffset = focus.offset;
  let anchorOffset = anchor.offset;
  const isBefore = anchor.isBefore(focus);
  let startOffset = isBefore ? anchorOffset : focusOffset;
  let endOffset = isBefore ? focusOffset : anchorOffset;
  const startType = isBefore ? anchor.type : focus.type;
  const endType = isBefore ? focus.type : anchor.type;
  const endKey = isBefore ? focus.key : anchor.key;

  // This is the case where the user only selected the very end of the
  // first node so we don't want to include it in the formatting change.
  if ($isTextNode(firstNode) && startOffset === firstNodeTextLength) {
    const nextSibling = firstNode.getNextSibling();

    if ($isTextNode(nextSibling)) {
      // we basically make the second node the firstNode, changing offsets accordingly
      anchorOffset = 0;
      startOffset = 0;
      firstNode = nextSibling;
    }
  }

  // This is the case where we only selected a single node
  if (selectedNodes.length === 1) {
    if ($isTextNode(firstNode)) {
      startOffset =
        startType === "element"
          ? 0
          : anchorOffset > focusOffset
          ? focusOffset
          : anchorOffset;
      endOffset =
        endType === "element"
          ? firstNodeTextLength
          : anchorOffset > focusOffset
          ? anchorOffset
          : focusOffset;

      // No actual text is selected, so do nothing.
      if (startOffset === endOffset) {
        return;
      }

      // The entire node is selected, so just format it
      if (startOffset === 0 && endOffset === firstNodeTextLength) {
        patch(firstNode);
        firstNode.select(startOffset, endOffset);
      } else {
        // The node is partially selected, so split it into two nodes
        // and style the selected one.
        const splitNodes = firstNode.splitText(startOffset, endOffset);
        const replacement = startOffset === 0 ? splitNodes[0] : splitNodes[1];
        patch(replacement);
        replacement.select(0, endOffset - startOffset);
      }
    } // multiple nodes selected.
  } else {
    if (
      $isTextNode(firstNode) &&
      startOffset < firstNode.getTextContentSize()
    ) {
      if (startOffset !== 0) {
        // the entire first node isn't selected, so split it
        firstNode = firstNode.splitText(startOffset)[1];
        startOffset = 0;
      }
      patch(/** @type {import("lexical").TextNode} */ (firstNode));
    }

    if ($isTextNode(lastNode)) {
      const lastNodeText = lastNode.getTextContent();
      const lastNodeTextLength = lastNodeText.length;

      // The last node might not actually be the end node
      //
      // If not, assume the last node is fully-selected unless the end offset is
      // zero.
      if (lastNode.__key !== endKey && endOffset !== 0) {
        endOffset = lastNodeTextLength;
      }

      // if the entire last node isn't selected, split it
      if (endOffset !== lastNodeTextLength) {
        [lastNode] = lastNode.splitText(endOffset);
      }

      if (endOffset !== 0) {
        patch(/** @type {import("lexical").TextNode} */ (lastNode));
      }
    }

    // style all the text nodes in between
    for (let i = 1; i < lastIndex; i++) {
      const selectedNode = selectedNodes[i];
      const selectedNodeKey = selectedNode.getKey();

      if (
        $isTextNode(selectedNode) &&
        selectedNodeKey !== firstNode.getKey() &&
        selectedNodeKey !== lastNode.getKey() &&
        !selectedNode.isToken()
      ) {
        patch(selectedNode);
      }
    }
  }
};

/**
 *
 * @param {import("../../types/sfdt").Block | import("../../types/sfdt").styles} blockOrStyle
 * @param {import("lexical").ElementNode} node
 */
const applyBlockFormatting = (blockOrStyle, node) => {
  if (!blockOrStyle.paragraphFormat) return;
  const {
    textAlignment,
    lineSpacing,
    lineSpacingType,
    afterSpacing,
    beforeSpacing,
    contextualSpacing,
    firstLineIndent,
    leftIndent,
    rightIndent,
    tabs,
  } = blockOrStyle.paragraphFormat;
  if (textAlignment) {
    const align = textAlignment.toLowerCase();
    // @ts-ignore
    node.setFormat(align);
  }
  if ($isParagraphNode(node) || $isHeadingNode(node)) {
    if (lineSpacing !== undefined && lineSpacingType !== undefined) {
      node.lineSpacing = {
        lineSpacing,
        lineSpacingType,
      };
    }
    if (afterSpacing || beforeSpacing || contextualSpacing) {
      /** @type {import("../../types/lexical").ParagraphSpacing} */
      const paragraphSpacing = {};
      if (afterSpacing) paragraphSpacing.afterSpacing = afterSpacing;
      if (beforeSpacing) paragraphSpacing.beforeSpacing = beforeSpacing;
      if (contextualSpacing) {
        paragraphSpacing.contextualSpacing = contextualSpacing;
      }
      node.paragraphSpacing = paragraphSpacing;
    }
    if (firstLineIndent || leftIndent || rightIndent || tabs) {
      const indentation = {};
      if (firstLineIndent) indentation.firstLineIndent = firstLineIndent;
      if (leftIndent) indentation.leftIndent = leftIndent;
      if (rightIndent) indentation.rightIndent = rightIndent;
      if (tabs) indentation.tabs = tabs;
      node.indentation = indentation;
    }
  }
};

/**
 * @param {*} metadata
 * @param {*} collabs
 * @param {*} organizationUsers
 * @param {*} author
 * @param {*} user
 * @param {*} currentType
 * @param {*} date
 * @param {*} currentInline
 * @returns {RedlineNode}
 */
function createRedlineFromInlineWithRevision(
  metadata,
  collabs,
  organizationUsers,
  author,
  user,
  currentType,
  date,
  currentInline
) {
  // Checks if document has metadata.
  const hasMetadata = JSON.stringify(metadata) !== "{}";

  // Checks what is the party ID of the organization users.
  const { partyID: organizationUsersPartyID } = collabs.find(
    (/** @type {{ email: string; }} */ collab) =>
      organizationUsers.some(
        (/** @type {{ email: string; }} */ organizationUser) =>
          collab.email === organizationUser.email
      )
  ) || { partyID: "party0" };

  // Joins the agreement collaborators with the organization users.
  const collaboratorsAndOrganizationUsers = [
    ...collabs,
    ...organizationUsers.map((/** @type {*} */ organizationUser) => ({
      ...organizationUser,
      partyID: organizationUsersPartyID,
    })),
  ];

  const collab = hasMetadata
    ? collaboratorsAndOrganizationUsers.find(
        (/** @type {PartialUser}*/ x) => x.email === author
      )
    : undefined;

  const uploader = collaboratorsAndOrganizationUsers.find(
    (x) => x.partyID === metadata?.partyId
  ) ||
    collaboratorsAndOrganizationUsers.find((x) => x.email === user?.email) || {
      ...user,
      partyID: metadata?.partyId ?? "party0",
    };

  const useDisplayNameFromWord = collaboratorsAndOrganizationUsers.every(
    (x) => x.email !== author
  );

  const redline = $createRedlineNode({
    redlineType: currentType,
    metadata: {
      partyId: collab ? collab.partyID : uploader.partyID,
      creatorDisplayName: useDisplayNameFromWord
        ? author
        : collab
        ? collab.displayName
        : uploader.displayName,
      creationDate: date,
      creatorEmail: collab ? collab.email : uploader.email,
      creatorId: collab ? collab._id : uploader._id,
      creatorPhotoUrl: collab ? collab.photoURL : defaultUserAvatar,
      revisionId: uuid(),
    },
    partyID: collab ? collab.partyID : uploader.partyID,
    date: date,
    text: currentInline.text,
  });

  return redline;
}

//code from lexical v0.11
/**
 *
 * @param {import("lexical").TextNode | import("lexical").RangeSelection} target
 * @param {Record<string, string | null>} patch
 */
export function patchStyle(target, patch) {
  const prevStyles = getStyleObjectFromCSS(
    "getStyle" in target ? target.getStyle() : target.style
  );
  const newStyles = Object.entries(patch).reduce((styles, [key, value]) => {
    if (value === null) {
      delete styles[key];
    } else {
      styles[key] = value;
    }
    return styles;
  }, { ...prevStyles } || {});
  const newCSSText = getCSSFromStyleObject(newStyles);
  target.setStyle(newCSSText);
  // CSS_TO_STYLES.set(newCSSText, newStyles);
}

/** Turns map into css string
 *
 * @param {Record<string, string>} styles
 * @returns {string}
 */
export function getCSSFromStyleObject(styles) {
  let css = "";

  for (const style in styles) {
    if (style) {
      css += `${style}: ${styles[style]};`;
    }
  }

  return css;
}
/** Parses sfdt paragraphformat styles, finds corresponding style if applicable
 * and applies to lexical node
 *
 * @param {import("../../types/sfdt").Sfdt} sfdt
 * @param {import("../../types/sfdt").Block} block
 * @param {import("lexical").ElementNode} node
 */
const elementNodeBlockFormatting = (sfdt, block, node) => {
  if (!$isElementNode(node)) {
    throw new Error("Can't apply block formatting on non-element node.");
  }

  if (block?.paragraphFormat) {
    if (block.paragraphFormat.styleName) {
      const themeStyleFromSfdtTyles = sfdt.styles.find(
        (st) => st.name === block.paragraphFormat?.styleName
      );

      // Clone so that we never change the SFDT styles.
      let themeStyle =
        JSON.parse(JSON.stringify(themeStyleFromSfdtTyles || null)) ||
        undefined; // Make sure we return styles or undefined like to keep existing behaviour.
      if (
        !(
          (!themeStyle?.characterFormat ||
            Object.keys(themeStyle?.characterFormat).length) &&
          (!themeStyle?.paragraphFormat ||
            Object.keys(themeStyle?.paragraphFormat).length)
        )
      ) {
        /**
         * @param {import("../../types/sfdt").styles} target
         * @returns {import("../../types/sfdt").styles | undefined}
         */
        const findFn = (target) =>
          sfdt.styles.find((s) => s.name === target?.basedOn);
        while (
          !(
            (!themeStyle?.characterFormat ||
              Object.keys(themeStyle?.characterFormat).length) &&
            (!themeStyle?.paragraphFormat ||
              Object.keys(themeStyle?.paragraphFormat).length)
          ) &&
          themeStyle?.basedOn
        ) {
          const basedOn = findFn(themeStyle);
          if (basedOn) {
            // Clone so that we never change the SFDT styles.
            themeStyle = JSON.parse(JSON.stringify(basedOn));
          } else {
            break;
          }
        }
        if (themeStyle?.basedOn === "Normal") {
          themeStyle.characterFormat = sfdt.characterFormat;
        }
      }
      if (themeStyle) {
        // If both the theme and the block both have styles, we combine them but give
        // priority to the block styles.
        themeStyle.paragraphFormat = {
          ...themeStyle.paragraphFormat,
          ...block.paragraphFormat,
        };

        applyBlockFormatting(themeStyle, node);
      } else applyBlockFormatting(block, node);
    } else {
      applyBlockFormatting(block, node);
    }
  }

  if (block?.characterFormat) {
    if (
      // TODO: Add character format to
      // $isParagraphNode(node) ||
      $isCustomHeadingNode(node)
    ) {
      node.characterFormat = block.characterFormat;
    }
  }
};

export {
  applyBlockFormatting,
  applyParagraphFormatting,
  applySimpleFormatting,
  applyThemeStyle,
  convertBlockStylesToCSS,
  createTextNodeWithFormatting,
  decodeHighlightColor,
  elementNodeBlockFormatting,
  encodeHighlightColor,
  generateInline,
  getFontPropertyFormatted,
  getValue,
  parseInlines,
  parseStyles,
  parseTextNodeFormat,
};
