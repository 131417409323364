import { PREVENT_EVENT_PROPAGATION } from "../../utils";

/**
 * @param {Event | null} [event]
 * @returns {boolean}
 */
export function preventEventPropagation(event) {
  if (
    event &&
    event.preventDefault &&
    typeof event.preventDefault === "function"
  ) {
    event.preventDefault();
  }
  return PREVENT_EVENT_PROPAGATION;
}
