import React, { useContext } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { UploadImg } from "../index";
import axios from "axios";
import { globalStore } from "../../state/store";

const DialogLogo = ({ isOpen, onCloseDialog, orgID, currentLogo }) => {
  const [state, _dispatch] = useContext(globalStore);
  const [errMsg, setErrMsg] = React.useState(null);

  const handleSubmitImage = (logoURL) => {
    if (!logoURL) return;

    axios
      .put(state.settings.api + "org/orglogo/" + orgID, {
        newLogo: logoURL,
      })
      .then(() => {
        onCloseDialog(logoURL);
      })
      .catch(() => {
        setErrMsg("Unable to update the logo");
      });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => onCloseDialog()}
      fullWidth
      maxWidth="sm"
    >
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={() => onCloseDialog()}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>
      <DialogTitle>Add logo to Organization</DialogTitle>
      <DialogContent>
        {!!errMsg && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Typography align="center" color="error">
              {errMsg}
            </Typography>
          </Box>
        )}
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography variant="body2" color="textSecondary" align="center">
              Click to upload a logo
            </Typography>
          </Grid>
          <Grid item>
            <UploadImg
              type="logo"
              handleSubmitImage={handleSubmitImage}
              initialImg={currentLogo}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button sx={{ marginRight: "auto" }} onClick={() => onCloseDialog()}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogLogo;
