/**
 * @typedef {object} Condition
 * @property {string} id
 * @property {string} baseValueType
 * @property {import("../../../../../../editor/nodes/MarkNode").MergeField} baseValue
 * @property {{label: string; value: string;}} operator
 * @property {string[] | import("../../../../../../editor/nodes/MarkNode").MergeFieldValue} comparisonValue
 * @property {import("../../../../../../editor/nodes/MarkNode").MergeFieldValue} mergeFieldValue
 */

import { mergeFieldValueIsValid } from "../../../../../../MergeFieldMenu/utils";

/**
 * @param {Partial<Condition> | null | undefined} condition
 * @returns {condition is Condition}
 */
export const isCondition = (condition) => {
  if (!condition) return false;
  if (!condition.baseValueType) return false;

  if (
    ["mergeField", "agreementMetadata", "partyInformation"].includes(
      condition.baseValueType
    )
  ) {
    if (!condition.baseValue) return false;

    if (
      condition.baseValue.type === "partyInformation" &&
      !condition.baseValue.partyRole
    ) {
      return false;
    }
    if (!condition.operator) return false;
    if (
      condition.operator.value === "isKnown" ||
      condition.operator.value === "isUnknown"
    ) {
      if (!condition.mergeFieldValue) return false;
      if (!mergeFieldValueIsValid(condition.mergeFieldValue)) {
        return false;
      }

      return true;
    }

    if (!condition.comparisonValue) return false;

    if (condition.comparisonValue instanceof Array) {
      if (!condition.comparisonValue.length) return false;
    } else {
      if (!mergeFieldValueIsValid(condition.comparisonValue)) return false;
    }

    // if (!condition.mergeFieldValue) return false;
    // if (!mergeFieldValueIsValid(condition.mergeFieldValue)) return false;

    return true;
  }

  return false;
};
