import { useEffect, useState } from "react";
import {
  defaultElements,
  defaultOptions,
  defaultSeparators,
} from "./constants";

/**
 * @param {PathBuilder} builder
 * @param {boolean} isLawFirm
 * @param {boolean} isFile
 */
export default function usePathBuilderState(builder, isLawFirm, isFile) {
  const [elements, setElements] = useState(
    builder.length ? builder : defaultElements
  );
  const [options, setOptions] = useState(
    /** @type {typeof defaultOptions} */ ([])
  );
  const [separators, setSeparators] = useState(
    /** @type {typeof defaultSeparators} */ ([])
  );

  useEffect(() => {
    setOptions(
      isLawFirm
        ? defaultOptions
        : defaultOptions.filter((option) => !option.isLawFirmRequired)
    );
  }, [isLawFirm]);

  useEffect(() => {
    setSeparators(
      isFile
        ? defaultSeparators.filter(
            (separator) => separator.label !== "/               [new subfolder]"
          )
        : defaultSeparators
    );
  }, [isFile]);

  return {
    elements,
    setElements,
    options,
    separators,
  };
}
