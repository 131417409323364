const userReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_USER": {
      let newVal = action.payload;
      return { user: newVal };
    }
    default:
      return { user: state.user };
  }
};

export default userReducer;
