import { styled } from "@mui/material";
import React from "react";
import Editor from "./editor";
import styles from "./styles.css";

const StyledEditor = styled(Editor)(styles);

/**
 * @param {{serializedState: *; onChange: (editorState: import("lexical").EditorState, editor: import("lexical").LexicalEditor, tags: Set<string>) => void}} props
 * @returns {React.JSX.Element}
 */
export default function PolicyEditor({ serializedState, onChange }) {
  return <StyledEditor serializedState={serializedState} onChange={onChange} />;
}
