import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React from "react";

/**
 * @typedef {object} ConfigurationStepProps
 * @property {ClauseVariant} clauseVariant
 * @property {React.Dispatch<React.SetStateAction<ClauseVariant>>} setClauseVariant
 */

/**
 * @param {ConfigurationStepProps} props
 * @returns {JSX.Element}
 */
export default function ConfigurationStep({ clauseVariant, setClauseVariant }) {
  return (
    <>
      <Grid container>
        <FormControl>
          <RadioGroup
            value={clauseVariant.type}
            onChange={(event) =>
              setClauseVariant((previous) => ({
                ...previous,
                type: event.target.value,
              }))
            }
          >
            <FormControlLabel
              value="removeClause"
              control={<Radio />}
              label="Add a variant to remove the clause entirely from the agreement"
            />
            <FormControlLabel
              value="alternativeClause"
              control={<Radio />}
              label="Add a variant to insert a clause with alternative wording:"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid container mt={2}>
        {clauseVariant.type === "alternativeClause" && (
          <TextField
            fullWidth
            multiline
            label="Variant Text"
            placeholder="Variant Text"
            value={clauseVariant.text}
            onChange={(event) =>
              setClauseVariant((previous) => ({
                ...previous,
                text: event.target.value,
              }))
            }
          />
        )}
      </Grid>

      <Grid container mt={2}>
        <TextField
          fullWidth
          label="Variant Name"
          placeholder={"E.g. “For customers in the UK”"}
          value={clauseVariant.name}
          onChange={(event) =>
            setClauseVariant((previous) => ({
              ...previous,
              name: event.target.value,
            }))
          }
        />
      </Grid>

      <Grid container mt={2}>
        <TextField
          fullWidth
          multiline
          rows={2}
          label="Variant Description"
          placeholder={
            "E.g. “This clause variant should apply automatically if the customer is located in the UK.”"
          }
          value={clauseVariant.description}
          onChange={(event) =>
            setClauseVariant((previous) => ({
              ...previous,
              description: event.target.value,
            }))
          }
        />
      </Grid>
    </>
  );
}
