import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createHeadingNode,
  $isHeadingNode,
  HeadingNode,
} from "@lexical/rich-text";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { IconButton, Menu, MenuItem } from "@mui/material";
import {
  $getRoot,
  $getSelection,
  $isElementNode,
  $isLineBreakNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  $setSelection,
  DEPRECATED_$isGridSelection,
} from "lexical";
import React, { useCallback, useEffect, useState } from "react";
import { createTextNodeWithFormatting } from "../../../converters/utils/text";
import { $isClauseNode } from "../../../nodes/ClauseNode";
import {
  SUPPORTED_HEADING_TAGS,
  defaultBodyStyles,
  defaultHeadingsStylesMap,
  supportedStylesMap,
} from "../../../utils/constants";

/** Toolbar plugin for heading related content
 * @param {{item: any, sfdt: import("../../../types/sfdt").Sfdt}} props
 * @returns
 */
const ToolbarStylesComponent = ({ item, sfdt }) => {
  const defaultValue = "Normal";
  const [editor] = useLexicalComposerContext();
  const [supportedStyles] = useState(supportedStylesMap);
  const [stylesArray] = useState(Array.from(supportedStyles));
  const [selectedField, setSelectedField] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const node = selection.anchor.getNode();
            const headingParent = $getNearestNodeOfType(node, HeadingNode);
            if ($isHeadingNode(headingParent)) {
              const tag = headingParent.getTag();
              setSelectedField(
                stylesArray.find((el) => el[1] === tag)?.[0] ?? defaultValue
              );
            } else {
              setSelectedField(defaultValue);
            }
          }
        });
      })
    );
  }, [editor]);

  const isBlock = (node) => {
    if (!$isElementNode(node) || $isRootOrShadowRoot(node)) {
      return false;
    }

    const firstChild = node.getFirstChild();
    const isLeafElement =
      firstChild === null ||
      $isLineBreakNode(firstChild) ||
      $isTextNode(firstChild) ||
      firstChild.isInline();

    return !node.isInline() && node.canBeEmpty() !== false && isLeafElement;
  };

  const convertElementToStyle = (tag, shouldUpdate) => {
    if (shouldUpdate) {
      editor.update(() => {
        const selection = $getSelection();
        if (
          $isRangeSelection(selection) ||
          DEPRECATED_$isGridSelection(selection)
        ) {
          if (selection.anchor.key === "root") {
            const element = $createHeadingNode(tag);
            const root = $getRoot();
            const firstChild = root.getFirstChild();

            if (firstChild) {
              firstChild.replace(element, true);
            } else {
              root.append(element);
            }

            return;
          }

          const nodes = selection.getNodes();
          let maybeBlock = selection.anchor.getNode().getParentOrThrow();

          if (nodes.indexOf(maybeBlock) === -1) {
            nodes.push(maybeBlock);
          }

          if (maybeBlock.isInline()) {
            maybeBlock = maybeBlock.getParentOrThrow();

            if (nodes.indexOf(maybeBlock) === -1) {
              nodes.push(maybeBlock);
            }
          }

          const removedNodesKeys = [];
          for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];

            if (!isBlock(node)) {
              //if it's not a element node or root/shadow node
              if ($isTextNode(node)) {
                const styleName = stylesArray.find((el) => el[1] === tag);
                const themeStyle = sfdt.styles?.find(
                  (st) => st.name === styleName?.[0]
                );
                let currentStyle;
                if (themeStyle) {
                  currentStyle = themeStyle;
                  while (currentStyle.basedOn) {
                    const basedOn = currentStyle.basedOn;
                    const basedOnStyle = sfdt.styles?.find(
                      (st) => st.name === basedOn
                    );
                    if (
                      basedOnStyle &&
                      (basedOnStyle.characterFormat ||
                        basedOnStyle.paragraphFormat)
                    ) {
                      currentStyle = basedOnStyle;
                    } else {
                      break;
                    }
                  }
                }
                if (
                  themeStyle &&
                  (currentStyle.characterFormat || currentStyle.paragraphFormat)
                ) {
                  //get styling or apply default styling
                  const tmpNode = createTextNodeWithFormatting(
                    sfdt,
                    null,
                    { text: "temp" },
                    styleName[0]
                  );
                  node.setStyle(tmpNode.getStyle());
                  node.setFormat(tmpNode.getFormat());
                } else {
                  const defaultHeadingStyle = defaultHeadingsStylesMap.get(tag);
                  //need to apply defaul
                  const tmpNode = createTextNodeWithFormatting(
                    sfdt,
                    null,
                    { text: "temp", characterFormat: defaultHeadingStyle },
                    styleName[0]
                  );
                  node.setStyle(tmpNode.getStyle());
                  node.setFormat(tmpNode.getFormat());
                }
              }
              continue;
            }

            //is block
            if (SUPPORTED_HEADING_TAGS.includes(tag)) {
              const targetElement = $createHeadingNode(tag);
              targetElement.setFormat(node.getFormatType());
              targetElement.setIndent(node.getIndent());
              if ($isClauseNode(node)) {
                const children = node.getChildren();
                node.splice(0, node.getChildren().length, []);
                targetElement.append(...children);
                node.append(targetElement);
              } else {
                node.replace(targetElement, true);
              }
            } else if (
              $isElementNode(node) &&
              !$isRootOrShadowRoot(node) &&
              !$isClauseNode(node) &&
              !removedNodesKeys.includes(node.getKey())
            ) {
              const parent = node.getParent();
              const n = parent
                .getChildren()
                .find((n) => n.getKey() === node.getKey());
              const index = parent.getChildren().indexOf(n);
              parent.splice(index, 0, node.getChildren());
              node.remove();
              removedNodesKeys.push(node.getKey());
            }
          }
          $setSelection(null);
          setAnchorEl(null);
        }
      });
    }
  };

  const getStyleForItem = (tag) => {
    switch (tag) {
      case "h1":
      case "h2":
      case "h3":
      case "h4":
      case "h5":
      case "h6": {
        return defaultHeadingsStylesMap.get(tag);
      }
      default: {
        return defaultBodyStyles;
      }
    }
  };

  return (
    <>
      <IconButton
        id="styles-button"
        aria-controls={open ? "styles-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disableRipple
        sx={(theme) => ({
          fontSize: "15px",
          padding: "10px",
          minWidth: "100px",
          justifyContent: "left",
          border: "1px solid" + theme.palette.grey[300],
          fontWeight: "bold",
          color: item.disabled
            ? theme.palette.grey[500]
            : theme.palette.grey[800],
          backgroundColor: item.isActive
            ? theme.palette.grey[200]
            : theme.palette.primary.contrastText,
          borderRadius: "5px",
        })}
      >
        <span style={{ marginRight: "8px" }}>{selectedField}</span>
        <FontAwesomeIcon
          style={{
            margin: "0 0 0 auto",
          }}
          icon={faAngleDown}
        ></FontAwesomeIcon>
      </IconButton>
      <Menu
        id="styles-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "styles-button",
          sx: {
            padding: "0",
            "& .Mui-selected": {
              fontWeight: "bold",
            },
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: "5px",
            padding: "0",
          },
        }}
        TransitionProps={{
          timeout: 0,
        }}
      >
        {stylesArray.map((h, i) => {
          const styles = getStyleForItem(h[1]);
          return (
            <MenuItem
              key={`${h[1]}-${i}`}
              selected={h[0] === selectedField}
              sx={{
                fontSize: "15px",
                fontWeight: "500",
                padding: "10px 20px",
                ...styles,
              }}
              onClick={() => convertElementToStyle(h[1], true)}
            >
              <span>{h[0]}</span>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ToolbarStylesComponent;
