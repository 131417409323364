import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import CanveoCircularProgress from "../CanveoCircularProgress";
import DialogCreateTicket from "../dialogs/DialogCreateTicket";

/**
 * @typedef {object} CreateTicketProps
 * @property {import("../../views/CreateAgreement").AgreementData} agreement
 * @property {(property: "tickets", value: *) => void} handleAgreementChange
 */

/**
 * @param {CreateTicketProps} props
 * @returns {React.JSX.Element}
 */
export default function CreateTicket({ agreement, handleAgreementChange }) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [isLoading, setIsLoading] = useState(true);

  const [owners, setOwners] = useState(
    /** @type {import("../dialogs/DialogCreateTicket").HubSpotOwner[]} */ ([])
  );
  const [pipelines, setPipelines] = useState(
    /** @type {import("../dialogs/DialogCreateTicket").HubSpotPipeline[]} */ ([])
  );
  const [openCreateTicketDialog, setOpenCreateTicketDialog] = useState(false);
  const [openRemoveTicketDialog, setOpenRemoveTicketDialog] = useState(false);
  const [organizations, setOrganizations] = useState(
    /** @type {{ _id: string; shortName: string }[]} */ ([])
  );
  const [ticketToEdit, setTicketToEdit] = useState(
    /** @type {import("../dialogs/DialogCreateTicket").Ticket | undefined} */ (
      undefined
    )
  );

  function handleCloseCreateTicketDialog() {
    setTicketToEdit(undefined);
    setOpenCreateTicketDialog(false);
  }

  function handleCloseRemoveTicketDialog() {
    setTicketToEdit(undefined);
    setOpenRemoveTicketDialog(false);
  }

  useEffect(
    () => {
      loadData();
    },
    // Run once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    (async function () {
      const organizationsWithData =
        /** @type {{ _id: string; shortName: string }[]} */ ([]);
      for (const { orgID: organizationId } of agreement.parties) {
        const getOrganizationWithDataResponse = await axios.get(
          `${state.settings.api}org/${organizationId}`
        );
        const organizationWithData = getOrganizationWithDataResponse.data.data;
        organizationsWithData.push(organizationWithData);
      }
      setOrganizations(organizationsWithData);
    })();
  }, [agreement.parties, state.settings.api]);

  async function loadData() {
    try {
      const [getHubSpotPipelinesResponse, getHubSpotOwnersResponse] =
        await Promise.all([
          axios.get(`${state.settings.api}hubspot/pipelines`),
          axios.get(`${state.settings.api}hubspot/owners`),
        ]);

      setPipelines(getHubSpotPipelinesResponse.data.data);
      setOwners(getHubSpotOwnersResponse.data.data);
      setOrganizations(organizations);
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "Error loading HubSpot data.",
          severity: "error",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          my: 12,
        }}
      >
        <CanveoCircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Grid mt={2}>
        {agreement.tickets.length === 0 && (
          <Typography
            color={theme.palette.primary.main}
            fontWeight="bold"
            onClick={() => setOpenCreateTicketDialog(true)}
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            Create a ticket ...
          </Typography>
        )}

        {Boolean(agreement.tickets.length) && (
          <Grid container direction="row" mt={2} gap={2}>
            {agreement.tickets.map((ticket, i) => {
              return (
                <Grid item xs={3} key={i}>
                  <Card
                    variant="outlined"
                    onClick={() => {
                      setTicketToEdit(ticket);
                      setOpenCreateTicketDialog(true);
                    }}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <CardHeader
                      titleTypographyProps={{
                        sx: { fontSize: "18px !important" },
                      }}
                      title={`Ticket: ${ticket.name}`}
                      action={
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            setTicketToEdit(ticket);
                            setOpenRemoveTicketDialog(true);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{ padding: "4px 7px" }}
                          />
                        </IconButton>
                      }
                      sx={{ paddingBottom: 0, fontSize: "20px" }}
                    />
                    <CardContent>
                      <Typography color="text.secondary">
                        {`Owner: ${ticket.owner.firstName} ${ticket.owner.lastName}`}
                      </Typography>

                      <Typography color="text.secondary">
                        {`Status: ${ticket.status.label}`}
                      </Typography>

                      <Typography color="text.secondary">
                        Last updated:{" "}
                        {DateTime.fromISO(ticket.updatedAt).toFormat(
                          "dd LLLL yyyy"
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>

      {openCreateTicketDialog && (
        <DialogCreateTicket
          open={openCreateTicketDialog}
          close={handleCloseCreateTicketDialog}
          agreement={agreement}
          handleAgreementChange={handleAgreementChange}
          owners={owners}
          pipelines={pipelines}
          organizations={organizations}
          addTicket={(ticket) => {
            const ticketsCopy = [...agreement.tickets];
            let existingTicketIndex = ticketsCopy.findIndex(
              (t) => t.id === ticket.id
            );
            if (existingTicketIndex > -1) {
              ticketsCopy[existingTicketIndex] = ticket;
            } else {
              ticketsCopy.push(ticket);
            }
            handleAgreementChange("tickets", ticketsCopy);
          }}
          existingTicket={ticketToEdit}
        />
      )}

      {openRemoveTicketDialog && ticketToEdit && (
        <Dialog
          open={openRemoveTicketDialog}
          onClose={handleCloseRemoveTicketDialog}
        >
          <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
            <IconButton onClick={handleCloseRemoveTicketDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{ padding: "4px 7px", fontSize: "20px" }}
              />
            </IconButton>
          </Box>
          <DialogTitle>Remove Ticket</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to remote the ticket?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ marginRight: "auto" }}
              onClick={handleCloseRemoveTicketDialog}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleAgreementChange(
                  "tickets",
                  agreement.tickets.filter(
                    (ticket) => ticket.id !== ticketToEdit.id
                  )
                );
                handleCloseRemoveTicketDialog();
              }}
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
