import { faPenCircle as farPenCircle } from "@fortawesome/pro-regular-svg-icons";
import {
  faCircleBookOpen,
  faCircleQuestion,
  faCircleXmark,
  faClone,
  faComment,
  faPenCircle as fasPenCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { globalStore } from "../../../state/store";
import theme from "../../../theme/theme";
import { getColorForPartyID, getEditModeOption } from "../../../utils";
import SelectCollaborator from "../../SelectCollaborator";

const editModes = [
  { id: "full", icon: fasPenCircle },
  { id: "edit", icon: farPenCircle },
  // TODO: To bring back the remaining edit modes, uncomment the following code.
  // { id: "comment", icon: faComment },
  {
    id: "read",
    icon: faCircleBookOpen,
  },
  { id: "none", icon: faCircleXmark },
];

/**
 * @param {*} props
 * @returns {React.JSX.Element}
 */
export default function RecipientListSection({
  party,
  collaborators,
  users,
  owner,
  handleChangeCollaborators,
  handleCreateUser,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [anchorElEditMode, setAnchorElEditMode] = useState(
    /** @type {{ target: EventTarget & HTMLButtonElement, pid: string} | null} */ (
      null
    )
  );

  /**
   * @param {string} editMode
   * @param {string} partyColor
   * @returns {JSX.Element}
   */
  function getIconForEditMode(editMode, partyColor) {
    const icon =
      "currentOrg" === editMode
        ? fasPenCircle
        : "full" === editMode
        ? fasPenCircle
        : "edit" === editMode
        ? farPenCircle
        : "comment" === editMode
        ? faComment
        : "read" === editMode
        ? faCircleBookOpen
        : "copy" === editMode
        ? faClone
        : "none" === editMode
        ? faCircleXmark
        : faCircleQuestion;

    const color =
      editMode === "tbd"
        ? theme.palette.grey[400]
        : "none" === editMode
        ? theme.palette.grey[200]
        : partyColor;

    return (
      <FontAwesomeIcon
        icon={icon}
        style={{
          padding: "10px",
          fontSize: "34px",
          color: color,
          opacity: "currentOrg" === editMode ? 0.5 : 1,
        }}
      />
    );
  }

  /**
   * @param {string} partyID
   * @param {string} editMode
   */
  function handleEditModeChange(partyID, editMode) {
    const newParties = state.parties;

    const idx = newParties.findIndex(
      (/** @type {{ _id: string; }} */ np) => np._id === partyID
    );
    newParties[idx].editMode = editMode;

    // If the editMode is full, then we need to go to all other parties (except owner) and set to read (added as October Cleanup)
    if (editMode === "full") {
      newParties.forEach(
        (/** @type {{ editMode: string; _id: string; }} */ np) => {
          if (np.editMode !== "currentOrg" && np._id !== partyID) {
            np.editMode = "read";
          }
        }
      );
    }

    // If the  situation is: only none or currentOrg => change back to 'tbd'
    if (
      newParties.every((/** @type {{ editMode: string; }} */ p) =>
        ["currentOrg", "none"].includes(p.editMode)
      )
    ) {
      newParties.forEach(
        (/** @type {{ orgID: any; editMode: string; }} */ np) => {
          if (np.orgID !== state.org._id) np.editMode = "tbd";
        }
      );
    }

    dispatch({ type: "INIT_PARTIES", payload: newParties }); // Update State
    setAnchorElEditMode(null);
  }

  /**
   * @param {{ id: string }} props
   * @returns {boolean}
   */
  function isEditModeSelected({ id }) {
    const editModeIsSelected =
      state.parties.find(
        (/** @type {{ _id: string; }} */ p) => p._id === anchorElEditMode?.pid
      )?.editMode === id;

    return editModeIsSelected;
  }

  /**
   * @param {{ _id: string }[]} collaborators
   * @param {string} orgID
   * @returns {void}
   */
  function onChangeCollaborators(collaborators, orgID) {
    const createCollaborator = collaborators.find((c) => !c._id);
    if (createCollaborator) return handleCreateUser(orgID);

    handleChangeCollaborators(
      collaborators.filter((c) => c._id),
      orgID
    );
  }

  /**
   * @param {{ orgID: string }} party
   * @returns {boolean}
   */
  function showParty(party) {
    return (
      state.org._id === owner ||
      party.orgID === owner ||
      party.orgID === state.org._id
    );
  }

  return (
    <>
      {(party ? party : state.parties.filter(showParty)).map(
        (
          /** @type {{ _id: string; editMode: string; partyID: string; orgID: any; }} */ p,
          /** @type {number} */ i
        ) => {
          return (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%", my: 2 }}
              key={p._id}
            >
              {!party && state.org._id === owner && (
                <Grid item xs={2}>
                  <Tooltip
                    title={getEditModeOption(p.editMode).desc}
                    placement="left"
                  >
                    <span>
                      <IconButton
                        onClick={(e) =>
                          setAnchorElEditMode({
                            target: e.currentTarget,
                            pid: p._id,
                          })
                        }
                        disabled={
                          p.editMode === "currentOrg" || state.org._id !== owner
                        }
                      >
                        {getIconForEditMode(
                          p.editMode,
                          getColorForPartyID(p.partyID)
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              )}

              <Grid item xs={!party && state.org._id === owner ? 10 : 12}>
                <SelectCollaborator
                  canCreate={p.orgID !== owner}
                  party={p}
                  collaborators={collaborators?.filter(
                    (/** @type {{ orgID: string; }} */ c) => c.orgID === p.orgID
                  )}
                  disabled={
                    state.user.role.name === "Counterparty" &&
                    p.orgID !== state.org._id
                  }
                  users={users.filter(
                    (/** @type {{ active: boolean; orgID: string; }} */ u) =>
                      u.active && u.orgID === p.orgID
                  )}
                  owner={owner}
                  handleChangeCollaborators={onChangeCollaborators}
                />
              </Grid>
            </Grid>
          );
        }
      )}

      {anchorElEditMode && (
        <Menu
          id="menu-editMode"
          anchorEl={anchorElEditMode?.target}
          keepMounted
          open={!!anchorElEditMode?.target}
          onClose={() => setAnchorElEditMode(null)}
          PaperProps={{ sx: { width: "550px" } }}
        >
          {editModes.map((item) => (
            <MenuItem
              key={item.id}
              onClick={() =>
                handleEditModeChange(anchorElEditMode.pid, item.id)
              }
              selected={isEditModeSelected(item)}
            >
              <ListItemIcon style={{ width: "46px" }}>
                <FontAwesomeIcon
                  icon={item.icon}
                  style={{
                    fontSize: "22px",
                    color: isEditModeSelected(item)
                      ? theme.palette.primary.main
                      : theme.palette.grey[600],
                  }}
                />
              </ListItemIcon>

              <ListItemText>
                <span style={{ fontWeight: "700" }}>
                  {getEditModeOption(item.id).title}
                </span>
                <span
                  style={{
                    display: "block",
                    width: "200px",
                    fontSize: "14px",
                    color: theme.palette.grey[600],
                  }}
                >
                  {getEditModeOption(item.id).desc1}
                  <br />
                  {getEditModeOption(item.id).desc2}
                </span>
              </ListItemText>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}
