/**
 * @param {*} state
 * @param {*} action
 * @returns
 */
const selectedClauseTopicReducer = (state, action) => {
  switch (action.type) {
    case "NEW_CLAUSE_TOPIC_SELECTION": {
      const selectedClauseTopic = { ...action.payload, event: action.type };
      return { selectedClauseTopic };
    }
    default: {
      return { selectedClauseTopic: null };
    }
  }
};

export default selectedClauseTopicReducer;
