import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faCircleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useContext, useEffect, useMemo } from "react";
import * as Yup from "yup";
import useVersionService from "../../hooks/useVersionService";
import { globalStore } from "../../state/store";
import { getNewVersion } from "../../utils/versionsUtils";

function DialogDuplicateVersion({ open, originVersion, handleClose }) {
  const [state] = useContext(globalStore);
  const { loading, duplicateVersion } = useVersionService();

  const version = useMemo(() => {
    const newVersion = getNewVersion(
      originVersion.version,
      state.drawerVersions.versions
    );
    return `Version ${newVersion}`;
  }, [originVersion, state.drawerVersions.versions]);

  const formSchema = Yup.object({
    versionName: Yup.string().required("Version name is required"),
    description: Yup.string()
      .min(3, "The version description is too short")
      .max(500, "The version description is too long")
      .required("Version description is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      versionName: version,
      description: `Contains work done by ${state.org.shortName}`,
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      // if versionName is the defaulted one, we don't set it in the db
      const valuesToSubmit = { ...values };
      if (valuesToSubmit.versionName === version) {
        valuesToSubmit.versionName = null;
      }

      const result = await duplicateVersion(originVersion._id, valuesToSubmit);

      if (result) handleClose();
    },
  });

  useEffect(() => {
    if (!open) formik.resetForm();
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>
      <DialogTitle>Create New Version From Duplicate</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ my: 1 }}
          >
            <Grid item container xs={12}>
              <TextField
                required
                fullWidth
                variant="outlined"
                label="Version Name"
                {...formik.getFieldProps("versionName")}
                error={
                  !!(formik.touched.versionName && formik.errors.versionName)
                }
                helperText={
                  formik.touched.versionName && formik.errors.versionName
                    ? formik.errors.versionName
                    : ""
                }
              />
            </Grid>
            <Grid item container xs={12}>
              <TextField
                required
                fullWidth
                variant="outlined"
                multiline
                rows={3}
                label="Version Description"
                {...formik.getFieldProps("description")}
                error={
                  !!(formik.touched.description && formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                    ? formik.errors.description
                    : ""
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button sx={{ marginRight: "auto" }} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!formik.dirty}
          onClick={() => formik.resetForm()}
        >
          Reset
        </Button>
        <Button color="primary" onClick={formik.handleSubmit}>
          <FontAwesomeIcon icon={faCircleRight} />
          &nbsp; Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogDuplicateVersion;
