import React from "react";
import { ListItemButton, ListItemText, Typography } from "@mui/material";
import theme from "../../theme/theme";
import { trunc } from "../../utils";

function ContentDrawerItem({ content }) {
  return (
    <ListItemButton
      onClick={() =>
        content.element.scrollIntoView({
          block: "center",
          behavior: "smooth",
        })
      }
    >
      <ListItemText
        disableTypography
        sx={{ padding: "2px 5px" }}
        primary={
          <Typography
            style={
              [3, 4].includes(content.level)
                ? {
                    paddingLeft: "20px",
                    fontSize: "14px",
                    color: theme.palette.grey[700],
                  }
                : [2].includes(content.level)
                ? {
                    paddingLeft: "10px",
                    fontSize: "15px",
                    color: theme.palette.grey[800],
                    fontWeight: "500",
                  }
                : {
                    paddingLeft: "0px",
                    fontSize: "16px",
                    color: theme.palette.grey[900],
                    fontWeight: "600",
                  }
            }
          >
            {trunc(content.item, content.level === 1 ? 22 : 24)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default ContentDrawerItem;
