const cpentsReducer = (state, action) => {
  switch (action.type) {
    case "INIT_CPENTS":
      return { cpents: action.payload };
    case "ADD_CPENT":
      return {
        cpents: [...state.cpents, action.payload],
      };
    case "UPDATE_CPENT": {
      let newVal = action.payload;
      let newCpents = state.cpents;
      let idx = state.cpents.findIndex((item) => item._id === newVal._id);
      if (idx > -1) {
        newCpents[idx] = newVal;
      }
      return { cpents: newCpents };
    }
    default:
      return { cpents: state.cpents };
  }
};

export default cpentsReducer;
