import React, { useContext, useEffect } from "react";
import { globalStore } from "../../../state/store";
import { getPartyID } from "../../../utils/getPartyID";
import PartyList from "../../PartyList";
import RemovePartyConfirmation from "../../RemovePartyConfirmation";
import PartyForm from "../../forms/PartyForm";

/**
 * @typedef {object} PartyManagementSectionProps
 * @property {*} owner
 * @property {*} handlePartyChange
 * @property {*} roles
 * @property {*} setRoles
 * @property {*} setAgreementUpdate
 */

/**
 * @param {PartyManagementSectionProps} props
 * @returns {React.JSX.Element}
 */
export default function PartyManagementSection({
  roles,
  setRoles,
  owner,
  handlePartyChange,
  setAgreementUpdate,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  /**
   * @param {*} property
   * @param {*} value
   */
  const handleNewPartyChange = (property, value) => {
    if (
      property === "role" &&
      !!value &&
      !roles.find((/** @type {{ label: string; }} */ r) => r?.label === value)
    ) {
      handleAddRole("create", value);
    }

    dispatch({
      type: "SEND_FLOW_PARTY_CHANGE",
      payload: {
        key: property,
        value: value,
      },
    });
  };

  const handleAddRole = (
    /** @type {string} */ type,
    /** @type {*} */ value
  ) => {
    setAgreementUpdate(true);
    setRoles((/** @type {*} */ prev) => [...prev, { label: value }]);
  };

  /**
   * @param {*} ent
   * @param {*} [role]
   */
  const getPartyData = (ent, role) => {
    const party = state.parties.find(
      (/** @type {{ orgID: string; }} */ p) => p.orgID === ent.orgID
    );
    let editMode = party?.editMode ?? "edit";
    if (ent.orgID === state.org._id) editMode = "currentOrg";
    const partyID =
      party?.partyID ?? getPartyID(state.parties, ent.orgID === owner);
    const partyRole = party?.role ?? role;

    return {
      ...ent,
      editMode: editMode,
      partyID: partyID,
      role: partyRole,
    };
  };

  const handleEntityChange = (/** @type {*} */ newEntity) => {
    const entity = getPartyData(newEntity);
    handlePartyChange("ChangeEntity", entity);
  };

  const handleEntityUpdate = (/** @type {*} */ newEntity) => {
    const entity = getPartyData(newEntity);
    handlePartyChange("UpdateEntity", entity);
  };

  useEffect(() => {
    if (state.sendFlow?.addParty?.submit) {
      handleAddParty(state.sendFlow?.addParty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sendFlow?.addParty?.submit]);

  const handleAddParty = (/** @type {*} */ party) => {
    handlePartyChange("AddParty", getPartyData(party.entity, party.role));

    dispatch({ type: "SEND_FLOW_ADD_PARTY", payload: false });
  };

  useEffect(() => {
    if (state.sendFlow?.removeParty?.submit) {
      handleRemoveParty(state.sendFlow?.removeParty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sendFlow?.removeParty?.submit]);

  const initiateRemoveParty = (/** @type {*} */ party) => {
    dispatch({ type: "SEND_FLOW_REMOVE_PARTY", payload: party });
  };

  const handleRemoveParty = (/** @type {*} */ party) => {
    handlePartyChange("RemoveParty", party);
    dispatch({ type: "SEND_FLOW_REMOVE_PARTY", payload: null });
  };

  if (state.sendFlow.addParty) {
    return (
      <PartyForm
        party={state.sendFlow.addParty}
        roles={roles}
        handleChange={handleNewPartyChange}
        selectedParties={state.parties.filter(
          (/** @type {{ role: string; }} */ p) => p.role
        )}
      />
    );
  }

  if (!!state.sendFlow.removeParty) {
    return (
      <RemovePartyConfirmation
        legalName={state.sendFlow.removeParty?.legalName}
        isOwnOrganization={state.sendFlow.removeParty?.orgID === state.org._id}
      />
    );
  }

  return (
    <PartyList
      owner={owner}
      parties={state.parties}
      handleAddParty={() =>
        dispatch({ type: "SEND_FLOW_ADD_PARTY", payload: true })
      }
      handleRemoveParty={initiateRemoveParty}
      handleEntityChange={handleEntityChange}
      handleEntityUpdate={handleEntityUpdate}
      handlePartyChange={(party) => {
        handlePartyChange("UpdateRole", party);
      }}
      handleRoleChange={handleAddRole}
      roles={roles}
      displayAddParty={true}
    />
  );
}
