import * as React from "react";
import { Box, Grid, List, Typography } from "@mui/material";
import ContentDrawerItem from "./ContentDrawerItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-solid-svg-icons";
import theme from "../../theme/theme";

export default function ContentDrawer({ tableContent }) {
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        p={2}
        sx={{
          position: "relative",
          backgroundColor: "white",
        }}
      >
        <Grid item>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "14px",
            }}
          >
            <FontAwesomeIcon
              icon={faList}
              color={theme.palette.primary.main}
              size="lg"
            />
            &nbsp;&nbsp;&nbsp;Contents
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={{
          flexGrow: 1,
          position: "relative",
          backgroundColor: "white",
          overflow: "auto",
        }}
        px={2}
      >
        <List sx={{ p: 0 }}>
          {tableContent.map((content, index) => (
            <ContentDrawerItem content={content} key={index} />
          ))}
        </List>
      </Box>
    </>
  );
}
