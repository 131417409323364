import { mergeRegister } from "@lexical/utils";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  FOCUS_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useEffect } from "react";
import { $isClauseNode } from "../../nodes/ClauseNode";
import { $isMarkNode } from "../../nodes/MarkNode";
import { $isRedlineNode } from "../../nodes/RedlineNode";
import { PROPAGATE_EVENT } from "./utils";

/**
 * @typedef {{ id: string | null; type: "text" | "navigation" | null, status: "ongoing" | "completed" | null}} SelectedOpenIssue
 */

/**
 * @param {import("lexical").LexicalEditor} editor
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setShowOpenIssueMenu
 * @param {import("../OpenIssuesPlugin").OpenIssue[]} openIssues
 * @param {any} state
 * @param {any} dispatch
 */
export function useOpenIssueMenu(
  editor,
  setShowOpenIssueMenu,
  openIssues,
  state,
  dispatch
) {
  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          /** @type {SelectedOpenIssue} */
          const selectedOpenIssue = state.selectedOpenIssue;
          if (
            selectedOpenIssue &&
            selectedOpenIssue.type === "navigation" &&
            selectedOpenIssue.status === "ongoing"
          ) {
            const payload = {
              ...selectedOpenIssue,
              status: "completed",
            };

            dispatch({
              type: "NEW_OPEN_ISSUE_SELECTION",
              payload,
            });
          } else {
            const selection = $getSelection();
            if (!$isRangeSelection(selection)) {
              setShowOpenIssueMenu(false);
              return PROPAGATE_EVENT;
            }
            if (!openIssues.length) return PROPAGATE_EVENT;

            const selectionNodes = selection.getNodes();

            let openIssue;

            for (const selectionNode of selectionNodes) {
              /** @type {string} */
              let searchKey;

              // Redline nodes takes precedence over Mark nodes to account
              // for the situation where a Redline is inside a comment or a
              // merge field.
              if ($isRedlineNode(selectionNode)) {
                searchKey = selectionNode.getKey();
              } else if ($isClauseNode(selectionNode)) {
                searchKey = selectionNode.id;
              } else {
                const parent = selectionNode.getParent();

                searchKey = $isMarkNode(parent)
                  ? `${parent.getKey()}_${parent.getIDs()[0]}`
                  : selectionNode.getKey();
              }

              if (searchKey === state.selectedOpenIssue.id) {
                return PROPAGATE_EVENT;
              }

              openIssue = openIssues.find(
                (openIssue) => openIssue.id === searchKey
              );

              if (openIssue) break;
            }
            /** @type {SelectedOpenIssue} */
            const payload = openIssue
              ? { id: openIssue.id, type: "text", status: "completed" }
              : { id: null, type: null, status: null };

            dispatch({
              type: "NEW_OPEN_ISSUE_SELECTION",
              payload,
            });
          }

          return PROPAGATE_EVENT;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          setShowOpenIssueMenu(true);
          return PROPAGATE_EVENT;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [editor, setShowOpenIssueMenu, openIssues, state.selectedOpenIssue]);
}
