import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import theme from "../theme/theme";
import { trunc } from "../utils";

/**
 * @param {*} props
 * @returns {JSX.Element}
 */
function Column({ id, exhibits, canDelete, handleRemoveExhibit }) {
  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {exhibits.map(
            (
              /** @type {{ tempKey: any; _id: any; }} */ ex,
              /** @type {any} */ index
            ) => {
              return (
                <Task
                  id={ex.tempKey ? ex.tempKey : ex._id} // tempKey is needed for new Agreements where not all staged exhibits have an _id yet
                  key={index}
                  index={index}
                  item={ex}
                  handleRemoveExhibit={handleRemoveExhibit}
                  canDelete={canDelete}
                />
              );
            }
          )}

          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

/**
 * @param {*} props
 * @returns {JSX.Element}
 */
function Task({ id, index, item, canDelete, handleRemoveExhibit }) {
  return (
    <Draggable draggableId={id} key={id} index={index}>
      {(provided) => (
        // @ts-ignore
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          align="center"
        >
          <RenderBox
            item={item}
            handleRemoveExhibit={handleRemoveExhibit}
            canDelete={canDelete}
          />
        </Box>
      )}
    </Draggable>
  );
}

/**
 * @param {*} props
 * @returns {JSX.Element}
 */
const RenderBox = ({ item, canDelete, handleRemoveExhibit }) => {
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const styles = {
    boxStyle: {
      width: "200px",
      "&:hover": {
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 24px 0px",
        cursor: "pointer",
      }, //props.thumbClick !== null ? : {},
      padding: "2px 0px 2px 0px",
      border: "1px solid" + theme.palette.grey[300],
      borderRadius: "20px",
      textAlign: "center",
      backgroundColor: theme.palette.primary.contrastText,
      my: 1,
    },
  };

  const [isHover, setIsHover] = React.useState(false);

  return (
    <Tooltip title={item.agrTitle} placement="right">
      <Box
        key={item._id}
        sx={styles.boxStyle}
        style={
          item.isNew
            ? {
                backgroundColor: theme.palette.grey[50],
                border: "1px dotted" + theme.palette.grey[400],
              }
            : {}
        }
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ height: "46px" }}
        >
          {canDelete && (
            <Grid item xs={2}>
              {isHover && (
                <IconButton onClick={() => handleRemoveExhibit(item._id)}>
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    style={{
                      fontSize: "14px",
                      color: theme.palette.error.main,
                      padding: "1px 2px",
                    }}
                  />
                </IconButton>
              )}
            </Grid>
          )}
          <Grid item xs={8}>
            <Typography
              align="center"
              style={{
                fontWeight: "700",
                lineHeight: "15px",
                fontSize: smUp ? "14px" : "12px",
              }}
            >
              {trunc(item.agrTitle, 20)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Tooltip>
  );
};

/**
 * @param {*} props
 * @returns {JSX.Element}
 */
export default function ThumbExhibitView(props) {
  /**
   * @param {*} list
   * @param {*} startIndex
   * @param {*} endIndex
   * @returns {*[]}
   */
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
   * @param {*} result
   * @returns {void}
   */
  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    // Commenting for now as this is messing up the drag and drop.
    // const ol = props.exhibits.sort(
    //   (
    //     /** @type {{ priority: number; }} */ a,
    //     /** @type {{ priority: number; }} */ b
    //   ) => (a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0)
    // );

    const reorderedExhibits = reorder(
      props.exhibits,
      result.source.index,
      result.destination.index
    );

    props.handleUpdate(reorderedExhibits);
  };

  /**
   * @param {*} id
   * @returns {void}
   */
  const handleRemoveExhibit = (id) => {
    const newExhibits = props.exhibits.filter(
      (/** @type {{ _id: any; }} */ e) => e._id !== id
    );
    props.handleUpdate(newExhibits);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Column
        id="1"
        handleRemoveExhibit={handleRemoveExhibit}
        exhibits={props.exhibits}
        canDelete={!props.preventDelete}
      />
    </DragDropContext>
  );
}
