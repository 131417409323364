import { faBuilding, faUpload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import { $getRoot } from "lexical";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import docx from "../../assets/img/docx.png";
import pdf from "../../assets/img/pdf.png";
import word_merge from "../../assets/img/word_merge.png";
import word_unlock from "../../assets/img/word_unlock.png";
import xlsx from "../../assets/img/xlsx.png";
import useVersionService from "../../hooks/useVersionService";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import { fileHasAllowedExtension } from "../../utils/fileHasAllowedExtension";
import generateDefaultExportFilename from "../../utils/generateDefaultExportFileName";
import { getNewVersion } from "../../utils/versionsUtils";
import CanveoCircularProgress from "../CanveoCircularProgress";
import DialogFileConversionErrorDetails from "../dialogs/DialogFileConverionErrorDetails";
import $convertLexicalToSfdt from "../editor/converters/convertLexicalToSfdt";
import { convertSfdtToLexical } from "../editor/utils";

/**
 * @typedef {object} UploadVersionFormProps
 * @property {*} originVersion
 * @property {boolean} isOwner
 * @property {*} acceptedType
 * @property {*} handleCanSubmit
 * @property {*} handleFormSubmit
 * @property {boolean} isTemplate
 */

const UploadVersionForm = forwardRef(
  /**
   * @param {UploadVersionFormProps} props
   * @param {*} ref
   * @returns {React.JSX.Element}
   */
  function UploadVersionForm(
    {
      originVersion,
      isOwner,
      acceptedType,
      handleCanSubmit,
      handleFormSubmit,
      isTemplate,
    },
    ref
  ) {
    // @ts-ignore
    const [state] = useContext(globalStore);
    const [searchParams, setSearchParams] = useSearchParams();
    const newVersionFromFilename = searchParams.get("newVersionFromFilename");
    const [editor] = useLexicalComposerContext();
    const {
      loading,
      updateVersion,
      uploadVersion,
      duplicateVersion,
      fetchVersion,
    } = useVersionService();
    const [dropHover, setDropHover] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [uploadLoading, setUploadLoading] = useState(false);
    const [invalidFile, setInvalidFile] = useState(false);

    const formSchema = Yup.object({
      source: Yup.object().nullable().required("Party of origin is required"),
      versionAction: Yup.string().nullable(),
      description: Yup.string().nullable(),
      sfdt: Yup.object().nullable(),
      content: Yup.object().nullable(),
      origin: Yup.object().nullable(),
      embeddedFileId: Yup.string().nullable(),
    });

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        source: !isOwner
          ? state.parties.find(
              (/** @type {{ orgID: string; }} */ p) => p.orgID === state.org._id
            )
          : /** @type {* | null} */ (null),
        versionAction: /** @type {* | null} */ (null),
        description: "",
        sfdt: /** @type {* | null} */ (null),
        content: /** @type {* | null} */ (null),
        origin: /** @type {* | null} */ (null),
        metadata: /** @type {* | null} */ (null),
        embeddedFileId: /** @type {* | null} */ (null),
      },
      validationSchema: formSchema,
      onSubmit: async (values) => {
        // TODO: Should we checked for the hashed password?
        if (
          acceptedType === "docx" &&
          values.versionAction !== "new" &&
          values?.metadata?.agreementId !== originVersion.agrID

          // Previous version of condition where we also checked if the document
          // had disabled the password protection. Useful if we ever want to go
          // back to that logic.
          // acceptedType === "docx" &&
          // (values?.metadata?.agreementId !== originVersion.agrID ||
          //   !(values.sfdt?.enforcement && values.sfdt?.trackChanges)) &&
          // values.versionAction !== "new"
        ) {
          return setInvalidFile(true);
        }

        const submitValues = {
          ...values,
          receiverOrg: values.source.orgID,
          origin: {
            import: `${values.origin.fileName}#${values.origin.fileID}`,
          },
        };

        let result;

        if (isOwner) {
          // when source is Canveo or anny other file type
          if (submitValues.versionAction || acceptedType !== "docx") {
            result = await duplicateVersion(
              originVersion._id,
              submitValues,
              isTemplate
            );
          } else {
            result = await uploadVersion(originVersion.agrID, submitValues);
          }
        } else {
          if (acceptedType === "docx") {
            result = await updateVersion(originVersion._id, {
              content: values.content,
              sfdt: values.sfdt,
              origin: { ...originVersion.origin, ...submitValues.origin },
            });
          } else {
            result = await updateVersion(originVersion._id, {
              embeddedFileId: values.embeddedFileId,
              origin: { ...originVersion.origin, ...submitValues.origin },
            });
          }
        }

        handleFormSubmit(result);
      },
    });

    /**
     * @param {string} filename
     */
    async function openNewVersionFromFilename(filename) {
      try {
        const fileImport = await axios
          .get(`${state.settings.api}document/import/${filename}`)
          .catch(() => {
            setErrorMsg("Unable to convert file (interim step)");
          })
          .finally(() => {
            setUploadLoading(false);
          });
        if (!fileImport) return;

        if (!fileImport.data.success || !fileImport.data.data) {
          setErrorMsg(
            "An error occurred while converting the file (interim step)"
          );
          return;
        }

        const sfdt = fileImport.data.data;
        const lexical = await convertSfdtToLexical(sfdt, state);

        if (lexical?.metadata?.orgId) {
          const party = state.parties.find(
            (/** @type {{ orgID: string; }} */ p) =>
              p.orgID === lexical?.metadata?.orgId
          );
          if (party) onPartyChange(party);
        }
        formik.setFieldValue("metadata", lexical.metadata);
        formik.setFieldValue("sfdt", sfdt);
        formik.setFieldValue("content", lexical.content);
        formik.setFieldValue("versionAction", "new");
        formik.setFieldValue("origin", {
          fileID: filename,
          fileName: "Agreement.docx",
        });

        setSearchParams({});
      } catch (error) {
        // setErrMsg("An error occurred while converting the file (interim step)");
      } finally {
        // setLoading(false);
      }
    }

    useEffect(() => {
      if (newVersionFromFilename) {
        openNewVersionFromFilename(newVersionFromFilename);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newVersionFromFilename]);

    useEffect(() => {
      if (acceptedType === "docx") {
        handleCanSubmit(
          formik.values.sfdt &&
            formik.values.content &&
            formik.values.origin &&
            !invalidFile
        );
      } else {
        handleCanSubmit(
          formik.values.embeddedFileId && formik.values.origin && !invalidFile
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values, invalidFile, acceptedType]);

    const onPartyChange = (
      /** @type {{ orgID: string; shortName: string; }} */ value
    ) => {
      if (!value) return;

      const isOwnerParty = value.orgID === state.agrs[0].owner;

      if (acceptedType === "docx") {
        formik.setFieldValue("versionAction", isOwnerParty ? "merge" : null);
      }

      formik.setFieldValue("source", value);
      formik.setFieldValue(
        "description",
        `Contains work done by ${value.shortName}`
      );
    };

    const validExtensions = [".doc", "docx", "xlsx", "pdf"];

    const [conversionError, setConversionError] = React.useState(
      /** @type {Error | undefined} */ (undefined)
    );

    async function handleUploadChange(
      /** @type {{ name: string; type: string; }} */ file
    ) {
      try {
        setErrorMsg("");
        setUploadLoading(true);

        // Native file picker allows choosing files with any extension even if you
        // configured the input to only allow certain types. We currently only allow uploading
        // .doc or docx files.
        const fileHasValidExtension = fileHasAllowedExtension(
          file,
          validExtensions
        );

        if (!fileHasValidExtension) {
          setErrorMsg("Invalid file type");
          setUploadLoading(false);
          return;
        }

        const uploadSignedUrlResponse = await axios.post(
          state.settings.api + "upload/signedUrl",
          {
            contentType: file.type,
            bucketAlias: "documents",
          }
        );

        const uploadSignedUrl =
          uploadSignedUrlResponse.data.data.uploadSignedUrl;
        const fileName = uploadSignedUrlResponse.data.data.key;

        await fetch(uploadSignedUrl, {
          method: "PUT",
          // @ts-ignore
          body: file,
        });

        if (acceptedType === "docx") {
          const fileImport = await axios
            .get(`${state.settings.api}document/import/${fileName}`)
            .catch(() => {
              setErrorMsg("Unable to convert file (interim step)");
            })
            .finally(() => {
              setUploadLoading(false);
            });
          if (!fileImport) return;

          if (!fileImport.data.success || !fileImport.data.data) {
            setErrorMsg(
              "An error occurred while converting the file (interim step)"
            );
            return;
          }

          const sfdt = fileImport.data.data;
          const lexical = await convertSfdtToLexical(sfdt, state);

          if (lexical?.metadata?.orgId) {
            const party = state.parties.find(
              (/** @type {{ orgID: string; }} */ p) =>
                p.orgID === lexical?.metadata?.orgId
            );
            if (party) onPartyChange(party);
          }
          formik.setFieldValue("metadata", lexical.metadata);
          formik.setFieldValue("sfdt", sfdt);
          formik.setFieldValue("content", lexical.content);
        } else {
          setUploadLoading(false);

          formik.setFieldValue("embeddedFileId", fileName);
        }

        formik.setFieldValue("origin", {
          fileID: fileName,
          fileName: file.name,
        });
      } catch (error) {
        if (error instanceof Error) {
          setConversionError(error);
        }
        console.error(error);
      }
    }

    const getMergeActionDescription = () => {
      const newVersion = getNewVersion(
        originVersion.version,
        state.drawerVersions.versions
      );

      return `Merge the most recent previous version (v${originVersion.version}) with the uploaded file, and combine them into a new version ${newVersion}`;
    };

    const getOriginalVersion = async () => {
      const party = formik.values.source;
      if (!party) {
        console.log("No party was selected");
        return;
      }
      const version = state.drawerVersions.versions.find(
        (/** @type {{ owner: any[]; }} */ v) =>
          v.owner.some(
            (/** @type {{ orgID: any; }} */ o) => o.orgID === party.orgID
          )
      );

      if (!version) {
        console.log("No version sent to the party was found");
        return;
      }

      const { content, sfdt, contentMetadata } = await fetchVersion(
        version.agrID,
        version._id,
        isTemplate
      );

      const documentCommentsResponse = await axios({
        url: `${state.settings.api}document/${version.agrID}/comments`, //your url
        method: "GET",
      });

      const documentComments = documentCommentsResponse.data.data;

      const editorState = editor.parseEditorState(content);
      const listsStructure = contentMetadata?.listsStructure || [];

      const generatedSfdt = editorState.read(() => {
        const root = $getRoot();
        const generatedSfdt = $convertLexicalToSfdt(
          root,
          sfdt,
          {
            agreementId: version.agrID,
            entityId: party._id,
            orgId: party.orgID,
            partyId: party.partyID,
          },
          documentComments,
          listsStructure
        );
        return generatedSfdt;
      });

      if (!generatedSfdt) {
        console.log(
          "There was an issue converting the version content to SFDT"
        );
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(generatedSfdt),
        redirect: "follow",
        credentials:
          process.env.NODE_ENV === "production" ? "include" : undefined,
      };

      return fetch(
        `${state.settings.api}document/export`,
        // @ts-ignore
        requestOptions
      )
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          const fileName = generateDefaultExportFilename(
            state.org.shortName,
            state.agrs[0],
            state.parties
          );
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = fileName ? `${fileName}.docx` : "agreement.docx";
          a.click();
        })
        .catch((error) => console.log("error", error));
    };

    return (
      <form
        onSubmit={formik.handleSubmit}
        ref={ref}
        style={{ padding: "0 40px" }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ my: 1 }}
        >
          {loading && (
            <Grid item container xs={12} justifyContent="center">
              <CanveoCircularProgress />
            </Grid>
          )}

          {!loading && invalidFile && (
            <Grid item container xs={12}>
              <Typography
                mt={2}
                sx={{ fontWeight: "600" }}
                // @ts-ignore
                variant="subtitle"
                color="error"
              >
                Error - some information has been removed from this file, which
                prevents Canveo from uploading it correctly. To continue, please
                take the following steps:
              </Typography>
              <Typography
                mt={2}
                sx={{ fontWeight: "600" }}
                // @ts-ignore
                variant="subtitle"
                color="error"
              >
                1. Export the{" "}
                <Link
                  component="button"
                  // @ts-ignore
                  color={theme.palette.link.main}
                  onClick={getOriginalVersion}
                  sx={{ fontSize: "inherit", fontWeight: "inherit" }}
                >
                  original version
                </Link>{" "}
                sent to this counterparty into a Microsoft Word file.
              </Typography>
              <Typography
                my={2}
                sx={{ fontWeight: "600" }}
                // @ts-ignore
                variant="subtitle"
                color="error"
              >
                2. Unlock the file using the password "canveo", and save it.
              </Typography>
              <img
                src={word_unlock}
                alt="word_unlock"
                style={{ maxWidth: "100%" }}
              />
              <Typography
                mt={2}
                sx={{ fontWeight: "600" }}
                // @ts-ignore
                variant="subtitle"
                color="error"
              >
                3. In the same way, unlock the version you just tried to upload
                (i.e., the file you received from the counterparty), and save
                it.
              </Typography>
              <Typography
                my={2}
                sx={{ fontWeight: "600" }}
                // @ts-ignore
                variant="subtitle"
                color="error"
              >
                4. Then, using Microsoft Word's "Combine Documents" feature,
                merge the originally sent version with the file you received
                from the counterparty.
              </Typography>
              <img
                src={word_merge}
                alt="word_merge"
                style={{ maxWidth: "100%" }}
              />
              <Typography
                mt={2}
                sx={{ fontWeight: "600" }}
                // @ts-ignore
                variant="subtitle"
                color="error"
              >
                5. Using the “Protect Document” functionality shown in point 2
                above, lock the merged file for tracked changes with the
                password "canveo".
              </Typography>
              <Typography
                mt={2}
                sx={{ fontWeight: "600" }}
                // @ts-ignore
                variant="subtitle"
                color="error"
              >
                6. Then, save the merged file, and re-upload it.
              </Typography>
              <Typography
                mt={2}
                sx={{ fontWeight: "600" }}
                // @ts-ignore
                variant="subtitle"
                color="error"
              >
                If you run into any issues, please contact Canveo support.
              </Typography>
            </Grid>
          )}

          {!loading && !invalidFile && (
            <>
              {errorMsg && (
                <Typography
                  align="center"
                  // @ts-ignore
                  variant="subtitle"
                  color="error"
                >
                  {errorMsg}
                </Typography>
              )}
              <Grid item container xs={12} justifyContent="center">
                {uploadLoading && <CanveoCircularProgress />}
                {!uploadLoading && !formik.values.origin && (
                  <FileUploader
                    handleChange={handleUploadChange}
                    name="uploadfile"
                    types={
                      acceptedType === "docx" ? ["doc", "docx"] : [acceptedType]
                    }
                    label={"Upload or drop a file here"}
                    maxSize={20}
                    minSize={0}
                    onDraggingStateChange={(
                      /** @type {boolean | ((prevState: boolean) => boolean)} */ dragging
                    ) => setDropHover(dragging)}
                    hoverTitle={" "}
                    classes="version_upload"
                    children={
                      <Box
                        sx={{
                          width: "100%",
                          height: "180px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          border: dropHover
                            ? "2px solid" + theme.palette.primary.main
                            : "1px dotted" + theme.palette.grey[300],
                          backgroundColor: dropHover
                            ? theme.palette.grey[200]
                            : theme.palette.grey[50],
                          padding: "30px",
                          fontSize: "14px",
                          fontWeight: "600",
                          borderRadius: "20px",
                        }}
                      >
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                            <FontAwesomeIcon
                              icon={faUpload}
                              style={{
                                color: theme.palette.primary.main,
                                fontSize: "30px",
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <Box
                              sx={{
                                mt: 1,
                                display: "block",
                                textAlign: "center",
                              }}
                            >
                              <>
                                {dropHover
                                  ? "Time to let the file go..."
                                  : "Upload or drop your file here ..."}
                                <br />
                                <Grid
                                  container
                                  direction="row"
                                  spacing={1}
                                  justifyContent="center"
                                  sx={{ mt: 1 }}
                                >
                                  {acceptedType === "docx" && (
                                    <Grid item>
                                      <img src={docx} alt="docx" width={20} />
                                    </Grid>
                                  )}
                                  {acceptedType === "xlsx" && (
                                    <Grid item>
                                      <img src={xlsx} alt="xlsx" width={20} />
                                    </Grid>
                                  )}
                                  {acceptedType === "pdf" && (
                                    <Grid item>
                                      <img src={pdf} alt="pdf" width={20} />
                                    </Grid>
                                  )}
                                </Grid>
                              </>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    }
                  />
                )}
                {formik.values.origin && (
                  <Grid item container direction="column" alignItems="center">
                    {acceptedType === "docx" && (
                      <Grid item>
                        <img src={docx} alt="docx" height={30} />
                      </Grid>
                    )}
                    {acceptedType === "xlsx" && (
                      <Grid item>
                        <img src={xlsx} alt="xlsx" height={30} />
                      </Grid>
                    )}
                    {acceptedType === "pdf" && (
                      <Grid item>
                        <img src={pdf} alt="pdf" height={30} />
                      </Grid>
                    )}
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        color={"textSecondary"}
                        mt={1}
                        align="center"
                      >
                        {formik.values.origin.fileName}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {isOwner && (
                <Grid item container xs={12}>
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={state.parties}
                    value={formik.values.source}
                    autoHighlight
                    onChange={(e, value) => onPartyChange(value)}
                    getOptionLabel={(option) => option.legalName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label={"Source"}
                        variant="outlined"
                        placeholder="Which party provided this file?"
                        error={
                          !!(formik.touched.source && formik.errors.source)
                        }
                        // @ts-ignore
                        helperText={
                          formik.touched.source && formik.errors.source
                            ? formik.errors.source
                            : ""
                        }
                        InputProps={{
                          ...params.InputProps,
                          autoComplete: "new-password",
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon icon={faBuilding} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {isOwner &&
                formik.values.source?.orgID === state.agrs[0].owner &&
                acceptedType === "docx" && (
                  <Grid item container xs={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="entityCategory"
                        value={formik.values.versionAction}
                        onChange={(e, value) =>
                          formik.setFieldValue("versionAction", value)
                        }
                      >
                        <FormControlLabel
                          sx={{ paddingBottom: "8px" }}
                          value="merge"
                          control={<Radio color="primary" />}
                          label={getMergeActionDescription()}
                        />
                        <FormControlLabel
                          value="new"
                          control={<Radio color="primary" />}
                          label="Don’t perform a merge - instead, simply set the content of the uploaded file as the latest version"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}
            </>
          )}
        </Grid>

        {conversionError && (
          <DialogFileConversionErrorDetails
            conversionError={conversionError}
            close={() => setConversionError(undefined)}
          />
        )}
      </form>
    );
  }
);

export default UploadVersionForm;
