import { faCheckCircle, faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  Chip,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useContext } from "react";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { getColorForPartyID, trunc } from "../utils";

const styles = {
  optionEmail: {
    color: theme.palette.grey[600],
    fontWeight: "300",
    fontSize: "15px",
    marginLeft: "10px",
  },
  addCollaboratorTextField: {
    width: "100%",
    "& .MuiOutlinedInputRoot": {
      backgroundColor: theme.palette.primary.contrastText,
      "& fieldset": {
        borderColor: theme.palette.grey[400],
      },
    },
  },
};

const filter = createFilterOptions();

/**
 * @typedef {*} SelectCollaboratorProps
 */

/**
 * @param {SelectCollaboratorProps} props
 * @returns {React.JSX.Element}
 */
export default function SelectCollaborator({
  party,
  collaborators,
  users,
  canCreate,
  disabled,
  owner,
  handleChangeCollaborators,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  /**
   * @param {*} props
   * @param {*} option
   */
  const renderOption = (props, option) => {
    const color = getColorForPartyID(party.partyID);
    if (!option._id) {
      return (
        <Typography variant="subtitle1" color={"textPrimary"} {...props}>
          {option.displayName}
        </Typography>
      );
    }

    return (
      <Box
        component="li"
        {...props}
        sx={{
          backgroundColor:
            (props["aria-selected"]
              ? getColorForPartyID(party.partyID, undefined, "1f")
              : "none") + " !important",
        }}
      >
        <Checkbox
          icon={<FontAwesomeIcon icon={faCircle} />}
          checkedIcon={<FontAwesomeIcon icon={faCheckCircle} />}
          sx={{
            "&.Mui-checked": {
              color: color,
            },
          }}
          checked={collaborators.some(
            (/** @type {{ _id: string; }} */ c) => c._id === option._id
          )}
        />
        <Typography variant="body1" style={{ fontWeight: "500" }}>
          {trunc(option.displayName + " (" + option.title + ")", 28)}
          <span style={styles.optionEmail}>{trunc(option.email, 20)}</span>
        </Typography>
      </Box>
    );
  };

  function userSelectChipStyle(isDisabled = false) {
    const notReceivingColor = theme.palette.grey[300];
    const color =
      party.editMode === "none"
        ? notReceivingColor
        : getColorForPartyID(party.partyID);

    return {
      border: "1px solid" + color,
      margin: "2px 2px 2px 0px",
      height: "36px",
      backgroundColor: theme.palette.primary.contrastText,
      color:
        party.editMode === "none" ? notReceivingColor : theme.palette.grey[800],
      "& .MuiChip-deleteIcon": {
        color: isDisabled ? theme.palette.grey[300] : color,
        "&:hover": { color: theme.palette.grey[800] },
      },
      opacity: "1 !important",
    };
  }

  /**
   * @param {*} value
   * @param {*} getTagProps
   */
  const renderTags = (value, getTagProps) => {
    return value.map((/** @type {*} */ option, /** @type {number} */ index) => {
      const isDisabled =
        (owner !== state.org._id &&
          value.length === 1 &&
          value[0]._id === option._id) ||
        getTagProps({ index }).disabled;

      return (
        <Chip
          {...getTagProps({ index })}
          disabled={isDisabled}
          sx={userSelectChipStyle(isDisabled)}
          label={
            <Typography
              style={{
                padding: "5px",
                fontWeight: "600",
              }}
            >
              {trunc(option.displayName, 22)}
            </Typography>
          }
          avatar={
            <Avatar
              src={option.photoURL}
              style={{
                opacity: party.editMode === "none" ? 0.2 : 1.0,
                backgroundColor:
                  party.editMode === "none"
                    ? theme.palette.grey[600]
                    : theme.palette.grey[800],
                color: theme.palette.primary.contrastText,
                width: "28px",
                height: "28px",
              }}
            >
              {option.firstName[0] + option.lastName[0]}
            </Avatar>
          }
        />
      );
    });
  };

  /**
   * @param {*} params
   */
  const renderInput = (params) => {
    const label = party.myClient
      ? `${party.legalName} (Client)`
      : party.legalName;
    return (
      <TextField
        {...params}
        variant="outlined"
        label={label}
        style={styles.addCollaboratorTextField}
        placeholder="Add collaborators"
        InputLabelProps={{ shrink: true }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              borderColor: getColorForPartyID(party.partyID),
            },
            "&.Mui-focused fieldset": {
              borderColor: getColorForPartyID(party.partyID),
            },
          },
        }}
      />
    );
  };

  /**
   * @param {*} options
   * @param {*} params
   */
  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue === "" && canCreate) {
      filtered.unshift({
        inputValue: params.inputValue,
        displayName: `Create New Collaborator ...`,
      });
    }
    return filtered;
  };

  return (
    <Autocomplete
      multiple
      disableClearable
      openOnFocus
      onChange={(e, value) => handleChangeCollaborators(value, party.orgID)}
      options={users}
      value={collaborators}
      disableCloseOnSelect
      disabled={disabled}
      getOptionLabel={(option) => option?.displayName}
      getOptionDisabled={(option) =>
        owner !== state.org._id &&
        collaborators.length === 1 &&
        collaborators[0]._id === option._id
      }
      renderOption={renderOption}
      style={{ width: "100%" }}
      renderTags={renderTags}
      renderInput={renderInput}
      filterOptions={filterOptions}
    />
  );
}
