import { Avatar, Box, Typography } from "@mui/material";
import React, { Fragment } from "react";

/**
 * @typedef {*} UsersByPartyProps
 */

/**
 * @param {UsersByPartyProps} props
 * @returns {React.JSX.Element}
 */
export default function UsersByParty({ parties, users, ...props }) {
  return parties.map((/** @type {*} */ party) => {
    const partyUsers = users.filter(
      (/** @type {{ partyID: string; }} */ user) =>
        user.partyID === party.partyID
    );

    if (!partyUsers.length) return <Fragment key={party._id} />;

    return (
      <Box {...props} key={party._id}>
        <Box sx={{ display: "flex", alignItems: "center" }} py={1}>
          <Avatar src={party.logoURL} sx={{ backgroundColor: "transparent" }} />
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "14px",
            }}
            pl={1}
          >
            {party.myClient ? `${party.shortName} (Client)` : party.shortName}
          </Typography>
        </Box>

        {partyUsers.map((/** @type {*} */ user) => (
          <Box
            px={2}
            py={1}
            key={user._id}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              src={user.photoURL}
              style={{
                width: "30px",
                height: "30px",
              }}
            >
              {user.firstName[0] + user.lastName[0]}
            </Avatar>
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                }}
                pl={1}
              >
                {user.displayName}
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                }}
                pl={1}
              >
                {user.email}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    );
  });
}
