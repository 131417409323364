import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fab } from "@mui/material";
import React from "react";

/**
 * @typedef {object} FabStandardProps
 * @property {import("react").MouseEventHandler<HTMLButtonElement>} click
 * @property {import("@mui/material").SxProps<import("@mui/material").Theme> | undefined} sx
 * @property {import("@fortawesome/fontawesome-svg-core").IconProp} icon
 * @property {string} text
 */

/**
 * @param {FabStandardProps} props
 * @returns {React.JSX.Element}
 */
export default function FabStandard({ click, sx, icon, text }) {
  return (
    <Fab variant="extended" color="secondary" onClick={click} sx={sx}>
      <FontAwesomeIcon icon={icon} />
      &nbsp;&nbsp;{text}
    </Fab>
  );
}
