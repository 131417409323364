const findMissingID = (ids) => {
  let missingNum = 1;
  while (ids.includes(missingNum)) {
    missingNum++;
  }
  return missingNum;
};

export const getPartyID = (parties, isOwner, findMissing = false) => {
  if (isOwner) return "party0";
  if (!parties.length) return "party1";

  const ids = parties.map((p) => p.partyID.slice(5)).map(Number);

  if (findMissing) return `party${findMissingID(ids)}`;
  return `party${Math.max(...ids) + 1}`;
};
