import convertSfdtToLexical from "./convertSfdtToLexical";

// This SFDT corresponds to a blank word document with all default settings after a clean install.
const /** @type {import('../types/sfdt').Sfdt}  */ defaultSfdt = {
    defaultSfdt: true,
    optimizeSfdt: false,
    sections: [
      {
        blocks: [
          {
            inlines: [],
          },
        ],
        // @ts-ignore
        headersFooters: {},
        sectionFormat: {
          headerDistance: 35.400001525878906,
          footerDistance: 35.400001525878906,
          pageWidth: 595.2999877929688,
          pageHeight: 841.9000244140625,
          leftMargin: 72,
          rightMargin: 72,
          topMargin: 72,
          bottomMargin: 72,
          differentFirstPage: false,
          differentOddAndEvenPages: false,
          bidi: false,
          restartPageNumbering: false,
          pageStartingNumber: 0,
          endnoteNumberFormat: "LowerCaseRoman",
          footNoteNumberFormat: "Arabic",
          restartIndexForFootnotes: "DoNotRestart",
          restartIndexForEndnotes: "DoNotRestart",
          pageNumberStyle: "Arabic",
          breakCode: "NewPage",
        },
      },
    ],
    fontSubstitutionTable: {
      // @ts-ignore
      DengXian: "等线",
      "DengXian Light": "等线 Light",
    },
    characterFormat: {
      fontSize: 12,
      fontFamily: "Aptos",
      fontSizeBidi: 12,
      fontFamilyBidi: "minorBidi",
      fontFamilyAscii: "minorHAnsi",
      // @ts-ignore
      fontFamilyFarEast: "minorEastAsia",
      fontFamilyNonFarEast: "minorHAnsi",
      localeIdFarEast: 2052,
      localeIdBidi: 1025,
    },
    paragraphFormat: {
      afterSpacing: 8,
      lineSpacing: 1.1583333015441895,
      lineSpacingType: "Multiple",
    },
    background: {
      color: "#FFFFFFFF",
    },
    styles: [
      {
        type: "Paragraph",
        name: "Normal",
        // @ts-ignore
        next: "Normal",
      },
      {
        type: "Paragraph",
        name: "Heading 1",
        basedOn: "Normal",
        next: "Normal",
        link: "Heading 1 Char",
        characterFormat: {
          fontSize: 20,
          fontFamily: "Aptos Display",
          fontColor: "#0F4761FF",
          fontSizeBidi: 20,
          fontFamilyBidi: "majorBidi",
          fontFamilyAscii: "majorHAnsi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
          fontFamilyNonFarEast: "majorHAnsi",
        },
        paragraphFormat: {
          beforeSpacing: 18,
          afterSpacing: 4,
          // @ts-ignore
          outlineLevel: "Level1",
          keepWithNext: true,
          keepLinesTogether: true,
        },
      },
      {
        type: "Paragraph",
        name: "Heading 2",
        basedOn: "Normal",
        next: "Normal",
        link: "Heading 2 Char",
        characterFormat: {
          fontSize: 16,
          fontFamily: "Aptos Display",
          fontColor: "#0F4761FF",
          fontSizeBidi: 16,
          fontFamilyBidi: "majorBidi",
          fontFamilyAscii: "majorHAnsi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
          fontFamilyNonFarEast: "majorHAnsi",
        },
        paragraphFormat: {
          beforeSpacing: 8,
          afterSpacing: 4,
          // @ts-ignore
          outlineLevel: "Level2",
          keepWithNext: true,
          keepLinesTogether: true,
        },
      },
      {
        type: "Paragraph",
        name: "Heading 3",
        basedOn: "Normal",
        next: "Normal",
        link: "Heading 3 Char",
        characterFormat: {
          fontSize: 14,
          fontColor: "#0F4761FF",
          fontSizeBidi: 14,
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
        },
        paragraphFormat: {
          beforeSpacing: 8,
          afterSpacing: 4,
          // @ts-ignore
          outlineLevel: "Level3",
          keepWithNext: true,
          keepLinesTogether: true,
        },
      },
      {
        type: "Paragraph",
        name: "Heading 4",
        basedOn: "Normal",
        next: "Normal",
        link: "Heading 4 Char",
        characterFormat: {
          italic: true,
          fontColor: "#0F4761FF",
          italicBidi: true,
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
        },
        paragraphFormat: {
          beforeSpacing: 4,
          afterSpacing: 2,
          // @ts-ignore
          outlineLevel: "Level4",
          keepWithNext: true,
          keepLinesTogether: true,
        },
      },
      {
        type: "Paragraph",
        name: "Heading 5",
        basedOn: "Normal",
        next: "Normal",
        link: "Heading 5 Char",
        characterFormat: {
          fontColor: "#0F4761FF",
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
        },
        paragraphFormat: {
          beforeSpacing: 4,
          afterSpacing: 2,
          // @ts-ignore
          outlineLevel: "Level5",
          keepWithNext: true,
          keepLinesTogether: true,
        },
      },
      {
        type: "Paragraph",
        name: "Heading 6",
        basedOn: "Normal",
        next: "Normal",
        link: "Heading 6 Char",
        characterFormat: {
          italic: true,
          fontColor: "#595959FF",
          italicBidi: true,
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
        },
        paragraphFormat: {
          beforeSpacing: 2,
          afterSpacing: 0,
          // @ts-ignore
          outlineLevel: "Level6",
          keepWithNext: true,
          keepLinesTogether: true,
        },
      },
      {
        type: "Paragraph",
        name: "Heading 7",
        basedOn: "Normal",
        next: "Normal",
        link: "Heading 7 Char",
        characterFormat: {
          fontColor: "#595959FF",
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
        },
        paragraphFormat: {
          beforeSpacing: 2,
          afterSpacing: 0,
          // @ts-ignore
          outlineLevel: "Level7",
          keepWithNext: true,
          keepLinesTogether: true,
        },
      },
      {
        type: "Paragraph",
        name: "Heading 8",
        basedOn: "Normal",
        next: "Normal",
        link: "Heading 8 Char",
        characterFormat: {
          italic: true,
          fontColor: "#272727FF",
          italicBidi: true,
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
        },
        paragraphFormat: {
          afterSpacing: 0,
          // @ts-ignore
          outlineLevel: "Level8",
          keepWithNext: true,
          keepLinesTogether: true,
        },
      },
      {
        type: "Paragraph",
        name: "Heading 9",
        basedOn: "Normal",
        next: "Normal",
        link: "Heading 9 Char",
        characterFormat: {
          fontColor: "#272727FF",
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
        },
        paragraphFormat: {
          afterSpacing: 0,
          // @ts-ignore
          outlineLevel: "Level9",
          keepWithNext: true,
          keepLinesTogether: true,
        },
      },
      // @ts-ignore
      {
        type: "Character",
        name: "Default Paragraph Font",
      },
      {
        type: "Character",
        name: "Heading 1 Char",
        basedOn: "Default Paragraph Font",
        characterFormat: {
          fontSize: 20,
          fontFamily: "Aptos Display",
          fontColor: "#0F4761FF",
          fontSizeBidi: 20,
          fontFamilyBidi: "majorBidi",
          fontFamilyAscii: "majorHAnsi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
          fontFamilyNonFarEast: "majorHAnsi",
        },
      },
      {
        type: "Character",
        name: "Heading 2 Char",
        basedOn: "Default Paragraph Font",
        characterFormat: {
          fontSize: 16,
          fontFamily: "Aptos Display",
          fontColor: "#0F4761FF",
          fontSizeBidi: 16,
          fontFamilyBidi: "majorBidi",
          fontFamilyAscii: "majorHAnsi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
          fontFamilyNonFarEast: "majorHAnsi",
        },
      },
      {
        type: "Character",
        name: "Heading 3 Char",
        basedOn: "Default Paragraph Font",
        characterFormat: {
          fontSize: 14,
          fontColor: "#0F4761FF",
          fontSizeBidi: 14,
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
        },
      },
      {
        type: "Character",
        name: "Heading 4 Char",
        basedOn: "Default Paragraph Font",
        characterFormat: {
          italic: true,
          fontColor: "#0F4761FF",
          italicBidi: true,
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
        },
      },
      {
        type: "Character",
        name: "Heading 5 Char",
        basedOn: "Default Paragraph Font",
        characterFormat: {
          fontColor: "#0F4761FF",
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
        },
      },
      {
        type: "Character",
        name: "Heading 6 Char",
        basedOn: "Default Paragraph Font",
        characterFormat: {
          italic: true,
          fontColor: "#595959FF",
          italicBidi: true,
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
        },
      },
      {
        type: "Character",
        name: "Heading 7 Char",
        basedOn: "Default Paragraph Font",
        characterFormat: {
          fontColor: "#595959FF",
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
        },
      },
      {
        type: "Character",
        name: "Heading 8 Char",
        basedOn: "Default Paragraph Font",
        characterFormat: {
          italic: true,
          fontColor: "#272727FF",
          italicBidi: true,
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
        },
      },
      {
        type: "Character",
        name: "Heading 9 Char",
        basedOn: "Default Paragraph Font",
        characterFormat: {
          fontColor: "#272727FF",
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
        },
      },
      {
        type: "Paragraph",
        name: "Title",
        basedOn: "Normal",
        next: "Normal",
        link: "Title Char",
        characterFormat: {
          fontSize: 28,
          fontFamily: "Aptos Display",
          fontSizeBidi: 28,
          fontFamilyBidi: "majorBidi",
          fontFamilyAscii: "majorHAnsi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
          fontFamilyNonFarEast: "majorHAnsi",
          characterSpacing: -0.5,
        },
        paragraphFormat: {
          afterSpacing: 4,
          lineSpacing: 1,
          lineSpacingType: "Multiple",
          // @ts-ignore
          contextualSpacing: true,
        },
      },
      {
        type: "Character",
        name: "Title Char",
        basedOn: "Default Paragraph Font",
        characterFormat: {
          fontSize: 28,
          fontFamily: "Aptos Display",
          fontSizeBidi: 28,
          fontFamilyBidi: "majorBidi",
          fontFamilyAscii: "majorHAnsi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
          fontFamilyNonFarEast: "majorHAnsi",
          characterSpacing: -0.5,
        },
      },
      {
        type: "Paragraph",
        name: "Subtitle",
        basedOn: "Normal",
        next: "Normal",
        link: "Subtitle Char",
        characterFormat: {
          fontSize: 14,
          fontColor: "#595959FF",
          fontSizeBidi: 14,
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
          characterSpacing: 0.75,
        },
        paragraphFormat: {
          // @ts-ignore
          listFormat: {
            listLevelNumber: 1,
          },
        },
      },
      {
        type: "Character",
        name: "Subtitle Char",
        basedOn: "Default Paragraph Font",
        characterFormat: {
          fontSize: 14,
          fontColor: "#595959FF",
          fontSizeBidi: 14,
          fontFamilyBidi: "majorBidi",
          // @ts-ignore
          fontFamilyFarEast: "majorEastAsia",
          characterSpacing: 0.75,
        },
      },
      {
        type: "Paragraph",
        name: "Quote",
        basedOn: "Normal",
        // @ts-ignore
        next: "Normal",
        link: "Quote Char",
        characterFormat: {
          italic: true,
          fontColor: "#404040FF",
          italicBidi: true,
        },
        paragraphFormat: {
          beforeSpacing: 8,
          textAlignment: "Center",
        },
      },
      {
        type: "Character",
        name: "Quote Char",
        basedOn: "Default Paragraph Font",
        characterFormat: {
          italic: true,
          fontColor: "#404040FF",
          italicBidi: true,
        },
      },
      {
        type: "Paragraph",
        name: "List Paragraph",
        basedOn: "Normal",
        next: "List Paragraph",
        paragraphFormat: {
          leftIndent: 36,
          // @ts-ignore
          contextualSpacing: true,
        },
      },
      {
        type: "Character",
        name: "Intense Emphasis",
        basedOn: "Default Paragraph Font",
        characterFormat: {
          italic: true,
          fontColor: "#0F4761FF",
          italicBidi: true,
        },
      },
      {
        type: "Paragraph",
        name: "Intense Quote",
        basedOn: "Normal",
        next: "Normal",
        link: "Intense Quote Char",
        characterFormat: {
          italic: true,
          fontColor: "#0F4761FF",
          italicBidi: true,
        },
        paragraphFormat: {
          leftIndent: 43.20000076293945,
          rightIndent: 43.20000076293945,
          beforeSpacing: 18,
          afterSpacing: 18,
          textAlignment: "Center",
          // @ts-ignore
          borders: {
            top: {
              lineStyle: "Single",
              lineWidth: 0.5,
              shadow: false,
              space: 10,
              hasNoneStyle: false,
              color: "#0F4761FF",
            },
            bottom: {
              lineStyle: "Single",
              lineWidth: 0.5,
              shadow: false,
              space: 10,
              hasNoneStyle: false,
              color: "#0F4761FF",
            },
          },
        },
      },
      {
        type: "Character",
        name: "Intense Quote Char",
        basedOn: "Default Paragraph Font",
        characterFormat: {
          italic: true,
          fontColor: "#0F4761FF",
          italicBidi: true,
        },
      },
      {
        type: "Character",
        name: "Intense Reference",
        basedOn: "Default Paragraph Font",
        characterFormat: {
          bold: true,
          fontColor: "#0F4761FF",
          boldBidi: true,
          // @ts-ignore
          characterSpacing: 0.25,
        },
      },
    ],
    defaultTabWidth: 36,
    formatting: false,
    trackChanges: false,
    protectionType: "NoProtection",
    enforcement: false,
    dontUseHTMLParagraphAutoSpacing: false,
    alignTablesRowByRow: false,
    formFieldShading: true,
    footnotes: {
      separator: [
        {
          inlines: [
            {
              text: "\u0003",
            },
          ],
        },
      ],
      continuationSeparator: [
        {
          inlines: [
            {
              text: "\u0004",
            },
          ],
        },
      ],
      continuationNotice: [
        {
          inlines: [],
        },
      ],
    },
    endnotes: {
      separator: [
        {
          inlines: [
            {
              text: "\u0003",
            },
          ],
        },
      ],
      continuationSeparator: [
        {
          inlines: [
            {
              text: "\u0004",
            },
          ],
        },
      ],
      continuationNotice: [
        {
          inlines: [],
        },
      ],
    },
    compatibilityMode: "Word2013",
    allowSpaceOfSameStyleInTable: false,
    themeFontLanguages: {
      // @ts-ignore
      localeIdFarEast: 2052,
      localeIdBidi: 1025,
    },
    themes: {
      fontScheme: {
        fontSchemeName: "Office",
        majorFontScheme: {
          fontSchemeList: [
            {
              name: "latin",
              // @ts-ignore
              typeface: "Aptos Display",
              panose: "02110004020202020204",
            },
            {
              name: "ea",
              // @ts-ignore
              panose: "02110004020202020204",
            },
            {
              name: "cs",
              // @ts-ignore
              panose: "02110004020202020204",
            },
          ],
          fontTypeface: {
            Jpan: "游ゴシック Light",
            Hang: "맑은 고딕",
            Hans: "等线 Light",
            Hant: "新細明體",
            Arab: "Times New Roman",
            Hebr: "Times New Roman",
            Thai: "Angsana New",
            Ethi: "Nyala",
            Beng: "Vrinda",
            Gujr: "Shruti",
            Khmr: "MoolBoran",
            Knda: "Tunga",
            Guru: "Raavi",
            Cans: "Euphemia",
            Cher: "Plantagenet Cherokee",
            Yiii: "Microsoft Yi Baiti",
            Tibt: "Microsoft Himalaya",
            Thaa: "MV Boli",
            Deva: "Mangal",
            Telu: "Gautami",
            Taml: "Latha",
            Syrc: "Estrangelo Edessa",
            Orya: "Kalinga",
            Mlym: "Kartika",
            Laoo: "DokChampa",
            Sinh: "Iskoola Pota",
            Mong: "Mongolian Baiti",
            Viet: "Times New Roman",
            Uigh: "Microsoft Uighur",
            Geor: "Sylfaen",
            Armn: "Arial",
            Bugi: "Leelawadee UI",
            Bopo: "Microsoft JhengHei",
            Java: "Javanese Text",
            Lisu: "Segoe UI",
            Mymr: "Myanmar Text",
            Nkoo: "Ebrima",
            Olck: "Nirmala UI",
            Osma: "Ebrima",
            Phag: "Phagspa",
            Syrn: "Estrangelo Edessa",
            Syrj: "Estrangelo Edessa",
            Syre: "Estrangelo Edessa",
            Sora: "Nirmala UI",
            Tale: "Microsoft Tai Le",
            Talu: "Microsoft New Tai Lue",
            Tfng: "Ebrima",
          },
        },
        minorFontScheme: {
          fontSchemeList: [
            {
              name: "latin",
              // @ts-ignore
              typeface: "Aptos",
              panose: "02110004020202020204",
            },
            {
              name: "ea",
              // @ts-ignore
              panose: "02110004020202020204",
            },
            {
              name: "cs",
              // @ts-ignore
              panose: "02110004020202020204",
            },
          ],
          fontTypeface: {
            Jpan: "游ゴシック",
            Hang: "맑은 고딕",
            Hans: "等线",
            Hant: "新細明體",
            Arab: "Arial",
            Hebr: "Arial",
            Thai: "Cordia New",
            Ethi: "Nyala",
            Beng: "Vrinda",
            Gujr: "Shruti",
            Khmr: "DaunPenh",
            Knda: "Tunga",
            Guru: "Raavi",
            Cans: "Euphemia",
            Cher: "Plantagenet Cherokee",
            Yiii: "Microsoft Yi Baiti",
            Tibt: "Microsoft Himalaya",
            Thaa: "MV Boli",
            Deva: "Mangal",
            Telu: "Gautami",
            Taml: "Latha",
            Syrc: "Estrangelo Edessa",
            Orya: "Kalinga",
            Mlym: "Kartika",
            Laoo: "DokChampa",
            Sinh: "Iskoola Pota",
            Mong: "Mongolian Baiti",
            Viet: "Arial",
            Uigh: "Microsoft Uighur",
            Geor: "Sylfaen",
            Armn: "Arial",
            Bugi: "Leelawadee UI",
            Bopo: "Microsoft JhengHei",
            Java: "Javanese Text",
            Lisu: "Segoe UI",
            Mymr: "Myanmar Text",
            Nkoo: "Ebrima",
            Olck: "Nirmala UI",
            Osma: "Ebrima",
            Phag: "Phagspa",
            Syrn: "Estrangelo Edessa",
            Syrj: "Estrangelo Edessa",
            Syre: "Estrangelo Edessa",
            Sora: "Nirmala UI",
            Tale: "Microsoft Tai Le",
            Talu: "Microsoft New Tai Lue",
            Tfng: "Ebrima",
          },
        },
      },
    },
  };

// eslint-disable-next-line no-unused-vars
// @ts-ignore
const sfdtWithBookmarks = {
  sections: [
    {
      blocks: [
        {
          characterFormat: { localeId: 1033 },
          inlines: [
            { name: "id3a393bbcea9b49b78822e288a9d2ab4e", bookmarkType: 0 },
            { name: "id3a393bbcea9b49b78822e288a9d2ab4e", bookmarkType: 1 },
            {
              text: "This is a text paragraph that ",
              characterFormat: { localeId: 1033 },
            },
            {
              text: "contains a secret bookmark. We can use this native word feature to store metadata across the import and export document process so that we can at a given point reconcile the documents.",
              characterFormat: { localeId: 1033 },
            },
          ],
        },
        { characterFormat: { localeId: 1033 }, inlines: [] },
        {
          characterFormat: { localeId: 1033 },
          inlines: [
            {
              text: "This is another paragraph that does not contain any secret bookmarks. It’s just a simple piece of text and a couple of sentences.",
              characterFormat: { localeId: 1033 },
            },
          ],
        },
        { characterFormat: { localeId: 1033 }, inlines: [] },
        {
          characterFormat: { localeId: 1033 },
          inlines: [
            { name: "CoolParagraph", bookmarkType: 0 },
            { name: "CoolParagraph", bookmarkType: 1 },
            {
              text: "This is a paragraph that contains a regular bookmark! Pretty cool!",
              characterFormat: { localeId: 1033 },
            },
          ],
        },
      ],
      headersFooters: {},
      sectionFormat: {
        headerDistance: 35.400001525878906,
        footerDistance: 35.400001525878906,
        pageWidth: 595.2999877929688,
        pageHeight: 841.9000244140625,
        leftMargin: 72.0,
        rightMargin: 72.0,
        topMargin: 72.0,
        bottomMargin: 72.0,
        differentFirstPage: false,
        differentOddAndEvenPages: false,
        bidi: false,
        restartPageNumbering: false,
        pageStartingNumber: 0,
        endnoteNumberFormat: "LowerCaseRoman",
        footNoteNumberFormat: "Arabic",
        restartIndexForFootnotes: "DoNotRestart",
        restartIndexForEndnotes: "DoNotRestart",
        pageNumberStyle: "Arabic",
        breakCode: "NewPage",
      },
    },
  ],
  fontSubstitutionTable: { DengXian: "等线", "DengXian Light": "等线 Light" },
  characterFormat: {
    fontSize: 12.0,
    fontFamily: "Calibri",
    fontSizeBidi: 12.0,
    fontFamilyBidi: "minorBidi",
    fontFamilyAscii: "minorHAnsi",
    fontFamilyFarEast: "minorEastAsia",
    fontFamilyNonFarEast: "minorHAnsi",
    localeIdEastAsia: 2052,
    localeIdBidi: 1025,
  },
  background: { color: "#FFFFFFFF" },
  styles: [
    {
      type: "Paragraph",
      name: "Normal",
      next: "Normal",
    },
    {
      type: "Character",
      name: "Default Paragraph Font",
    },
    {
      type: "Character",
      name: "Heading 1 Char",
      basedOn: "Default Paragraph Font",
      characterFormat: {
        fontSize: 16,
        fontFamily: "Calibri Light",
        fontColor: "#2E74B5FF",
        fontSizeBidi: 16,
        fontFamilyBidi: "majorBidi",
        fontFamilyAscii: "majorHAnsi",
        fontFamilyFarEast: "majorEastAsia",
        fontFamilyNonFarEast: "majorHAnsi",
      },
    },
    {
      type: "Paragraph",
      name: "Heading 1",
      basedOn: "Normal",
      next: "Normal",
      link: "Heading 1 Char",
      characterFormat: {
        fontSize: 16,
        fontFamily: "Calibri Light",
        fontColor: "#2E74B5FF",
        fontSizeBidi: 16,
        fontFamilyBidi: "majorBidi",
        fontFamilyAscii: "majorHAnsi",
        fontFamilyFarEast: "majorEastAsia",
        fontFamilyNonFarEast: "majorHAnsi",
      },
      paragraphFormat: {
        beforeSpacing: 12,
        afterSpacing: 0,
        outlineLevel: "Level1",
        keepWithNext: true,
        keepLinesTogether: true,
      },
    },
    {
      type: "Character",
      name: "Header Char",
      basedOn: "Default Paragraph Font",
    },
    {
      type: "Paragraph",
      name: "Header",
      basedOn: "Normal",
      next: "Header",
      link: "Header Char",
      paragraphFormat: {
        afterSpacing: 0,
        lineSpacing: 1,
        lineSpacingType: "Multiple",
        tabs: [
          {
            tabJustification: "Center",
            position: 234,
            tabLeader: "None",
            deletePosition: 0,
          },
          {
            tabJustification: "Right",
            position: 468,
            tabLeader: "None",
            deletePosition: 0,
          },
        ],
      },
    },
    {
      type: "Character",
      name: "Footer Char",
      basedOn: "Default Paragraph Font",
    },
    {
      type: "Paragraph",
      name: "Footer",
      basedOn: "Normal",
      next: "Footer",
      link: "Footer Char",
      paragraphFormat: {
        afterSpacing: 0,
        lineSpacing: 1,
        lineSpacingType: "Multiple",
        tabs: [
          {
            tabJustification: "Center",
            position: 234,
            tabLeader: "None",
            deletePosition: 0,
          },
          {
            tabJustification: "Right",
            position: 468,
            tabLeader: "None",
            deletePosition: 0,
          },
        ],
      },
    },
    {
      type: "Character",
      name: "Heading 2 Char",
      basedOn: "Default Paragraph Font",
      characterFormat: {
        fontSize: 13,
        fontFamily: "Calibri Light",
        fontColor: "#2E74B5FF",
        fontSizeBidi: 13,
        fontFamilyBidi: "majorBidi",
        fontFamilyAscii: "majorHAnsi",
        fontFamilyFarEast: "majorEastAsia",
        fontFamilyNonFarEast: "majorHAnsi",
      },
    },
    {
      type: "Paragraph",
      name: "Heading 2",
      basedOn: "Normal",
      next: "Normal",
      link: "Heading 2 Char",
      characterFormat: {
        fontSize: 13,
        fontFamily: "Calibri Light",
        fontColor: "#2E74B5FF",
        fontSizeBidi: 13,
        fontFamilyBidi: "majorBidi",
        fontFamilyAscii: "majorHAnsi",
        fontFamilyFarEast: "majorEastAsia",
        fontFamilyNonFarEast: "majorHAnsi",
      },
      paragraphFormat: {
        beforeSpacing: 2,
        afterSpacing: 0,
        outlineLevel: "Level2",
        keepWithNext: true,
        keepLinesTogether: true,
      },
    },
    {
      type: "Character",
      name: "Heading 3 Char",
      basedOn: "Default Paragraph Font",
      characterFormat: {
        fontSize: 12,
        fontFamily: "Calibri Light",
        fontColor: "#1F4D78FF",
        fontSizeBidi: 12,
        fontFamilyBidi: "majorBidi",
        fontFamilyAscii: "majorHAnsi",
        fontFamilyFarEast: "majorEastAsia",
        fontFamilyNonFarEast: "majorHAnsi",
      },
    },
    {
      type: "Paragraph",
      name: "Heading 3",
      basedOn: "Normal",
      next: "Normal",
      link: "Heading 3 Char",
      characterFormat: {
        fontSize: 12,
        fontFamily: "Calibri Light",
        fontColor: "#1F4D78FF",
        fontSizeBidi: 12,
        fontFamilyBidi: "majorBidi",
        fontFamilyAscii: "majorHAnsi",
        fontFamilyFarEast: "majorEastAsia",
        fontFamilyNonFarEast: "majorHAnsi",
      },
      paragraphFormat: {
        beforeSpacing: 2,
        afterSpacing: 0,
        outlineLevel: "Level3",
        keepWithNext: true,
        keepLinesTogether: true,
      },
    },
    {
      type: "Character",
      name: "Heading 4 Char",
      basedOn: "Default Paragraph Font",
      characterFormat: {
        italic: true,
        fontFamily: "Calibri Light",
        fontColor: "#2E74B5FF",
        italicBidi: true,
        fontFamilyBidi: "majorBidi",
        fontFamilyAscii: "majorHAnsi",
        fontFamilyFarEast: "majorEastAsia",
        fontFamilyNonFarEast: "majorHAnsi",
      },
    },
    {
      type: "Paragraph",
      name: "Heading 4",
      basedOn: "Normal",
      next: "Normal",
      link: "Heading 4 Char",
      characterFormat: {
        italic: true,
        fontFamily: "Calibri Light",
        fontColor: "#2E74B5FF",
        italicBidi: true,
        fontFamilyBidi: "majorBidi",
        fontFamilyAscii: "majorHAnsi",
        fontFamilyFarEast: "majorEastAsia",
        fontFamilyNonFarEast: "majorHAnsi",
      },
      paragraphFormat: {
        beforeSpacing: 2,
        afterSpacing: 0,
        outlineLevel: "Level4",
        keepWithNext: true,
        keepLinesTogether: true,
      },
    },
  ],
  defaultTabWidth: 36.0,
  formatting: false,
  trackChanges: false,
  protectionType: "NoProtection",
  enforcement: false,
  dontUseHTMLParagraphAutoSpacing: false,
  alignTablesRowByRow: false,
  formFieldShading: true,
  footnotes: {
    separator: [{ inlines: [{ text: "\u0003" }] }],
    continuationSeparator: [{ inlines: [{ text: "\u0004" }] }],
    continuationNotice: [{ inlines: [] }],
  },
  endnotes: {
    separator: [{ inlines: [{ text: "\u0003" }] }],
    continuationSeparator: [{ inlines: [{ text: "\u0004" }] }],
    continuationNotice: [{ inlines: [] }],
  },
  compatibilityMode: "Word2013",
  themeFontLanguages: { localeIdEastAsia: 2052, localeIdBidi: 1025 },
  themes: {
    fontScheme: {
      fontSchemeName: "Office",
      majorFontScheme: {
        fontSchemeList: [
          {
            name: "latin",
            typeface: "Calibri Light",
            panose: "020F0302020204030204",
          },
          { name: "ea", panose: "020F0302020204030204" },
          { name: "cs", panose: "020F0302020204030204" },
        ],
        fontTypeface: {
          Jpan: "游ゴシック Light",
          Hang: "맑은 고딕",
          Hans: "等线 Light",
          Hant: "新細明體",
          Arab: "Times New Roman",
          Hebr: "Times New Roman",
          Thai: "Angsana New",
          Ethi: "Nyala",
          Beng: "Vrinda",
          Gujr: "Shruti",
          Khmr: "MoolBoran",
          Knda: "Tunga",
          Guru: "Raavi",
          Cans: "Euphemia",
          Cher: "Plantagenet Cherokee",
          Yiii: "Microsoft Yi Baiti",
          Tibt: "Microsoft Himalaya",
          Thaa: "MV Boli",
          Deva: "Mangal",
          Telu: "Gautami",
          Taml: "Latha",
          Syrc: "Estrangelo Edessa",
          Orya: "Kalinga",
          Mlym: "Kartika",
          Laoo: "DokChampa",
          Sinh: "Iskoola Pota",
          Mong: "Mongolian Baiti",
          Viet: "Times New Roman",
          Uigh: "Microsoft Uighur",
          Geor: "Sylfaen",
          Armn: "Arial",
          Bugi: "Leelawadee UI",
          Bopo: "Microsoft JhengHei",
          Java: "Javanese Text",
          Lisu: "Segoe UI",
          Mymr: "Myanmar Text",
          Nkoo: "Ebrima",
          Olck: "Nirmala UI",
          Osma: "Ebrima",
          Phag: "Phagspa",
          Syrn: "Estrangelo Edessa",
          Syrj: "Estrangelo Edessa",
          Syre: "Estrangelo Edessa",
          Sora: "Nirmala UI",
          Tale: "Microsoft Tai Le",
          Talu: "Microsoft New Tai Lue",
          Tfng: "Ebrima",
        },
      },
      minorFontScheme: {
        fontSchemeList: [
          {
            name: "latin",
            typeface: "Calibri",
            panose: "020F0502020204030204",
          },
          { name: "ea", panose: "020F0502020204030204" },
          { name: "cs", panose: "020F0502020204030204" },
        ],
        fontTypeface: {
          Jpan: "游明朝",
          Hang: "맑은 고딕",
          Hans: "等线",
          Hant: "新細明體",
          Arab: "Arial",
          Hebr: "Arial",
          Thai: "Cordia New",
          Ethi: "Nyala",
          Beng: "Vrinda",
          Gujr: "Shruti",
          Khmr: "DaunPenh",
          Knda: "Tunga",
          Guru: "Raavi",
          Cans: "Euphemia",
          Cher: "Plantagenet Cherokee",
          Yiii: "Microsoft Yi Baiti",
          Tibt: "Microsoft Himalaya",
          Thaa: "MV Boli",
          Deva: "Mangal",
          Telu: "Gautami",
          Taml: "Latha",
          Syrc: "Estrangelo Edessa",
          Orya: "Kalinga",
          Mlym: "Kartika",
          Laoo: "DokChampa",
          Sinh: "Iskoola Pota",
          Mong: "Mongolian Baiti",
          Viet: "Arial",
          Uigh: "Microsoft Uighur",
          Geor: "Sylfaen",
          Armn: "Arial",
          Bugi: "Leelawadee UI",
          Bopo: "Microsoft JhengHei",
          Java: "Javanese Text",
          Lisu: "Segoe UI",
          Mymr: "Myanmar Text",
          Nkoo: "Ebrima",
          Olck: "Nirmala UI",
          Osma: "Ebrima",
          Phag: "Phagspa",
          Syrn: "Estrangelo Edessa",
          Syrj: "Estrangelo Edessa",
          Syre: "Estrangelo Edessa",
          Sora: "Nirmala UI",
          Tale: "Microsoft Tai Le",
          Talu: "Microsoft New Tai Lue",
          Tfng: "Ebrima",
        },
      },
    },
  },
};

/**
 * Returns the Lexical equivalent to a blank Word document created with File -> New.
 */
export const defaultLexical = () => {
  // TODO: Check if this needs user and agreement.
  return convertSfdtToLexical(defaultSfdt, {});
};

export default defaultSfdt;
