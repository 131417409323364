import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const primaryColor = "#7243dd"; // lighter shade: #7E50DF, darker shade: #572CCE
const secondaryColor = "#E80064";
const white = "#ffffff";
const errorColor = "#D0252E";
const successColor = "#44b700"; //'#44AF69';

const grey900 = "#212121";
const grey800 = "#424242";
const grey700 = "#616161";
const grey600 = "#757575";
const grey500 = "#9e9e9e";
const grey400 = "#bdbdbd";
const grey300 = "#e0e0e0";
const grey250 = "#ececec";
const grey200 = "#eeeeee";
const grey100 = "#f5f5f5";
const grey50 = "#f9f9f9";

const secondaryGrey = "#827f8f";

let theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: white,
    },
    secondary: {
      main: secondaryColor,
      contrastText: white,
    },
    tertiary: {
      main: "#00BBF9",
      contrastText: white,
    },
    quaternary: {
      main: "#00F5D4",
      contrastText: grey900,
    },
    quinary: {
      main: "#FFBE0B",
      contrastText: grey900,
    },
    senary: {
      main: "#B99AFF",
      contrastText: white,
    }, //senary, septenary, octonary, nonary, and denary
    error: {
      main: errorColor,
    },
    success: {
      main: successColor,
    },
    grey: {
      900: grey900,
      800: grey800,
      700: grey700,
      600: grey600,
      500: grey500,
      400: grey400,
      300: grey300,
      250: grey250,
      200: grey200,
      100: grey100,
      50: grey50,
    },
    text: {
      primary: grey900,
      secondary: secondaryGrey,
    },
    link: {
      main: "#2047D2",
    },
  },
  typography: {
    fontFamily: "proxima-nova", // 100, 300, 400, 500, 700, 900
    h4: { fontWeight: 700 },
    h5: { fontWeight: 700 },
    h6: { fontWeight: 700 },
    subtitle1: { fontWeight: 700 },
    body1: { fontWeight: 300 },
    body2: { fontSize: "13px", fontWeight: 300 },
    button: { textTransform: "none" },
  },
  shape: {
    borderRadius: 20,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: "1000",
          color: primaryColor,
          backgroundColor: white,
          borderBottom: "1px solid" + grey200,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: "35px",
          height: "35px",
          backgroundColor: grey800,
          color: white,
          fontSize: "12px",
          fontWeight: "600",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "600",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          zIndex: "2000",
          backgroundColor: "rgba(40, 40, 40, 0.8)",
          color: white,
          fontSize: "12px",
          padding: "3px 14px 3px 14px",
          fontWeight: 700,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          "& .MuiMenu-paper": {
            //padding: '5px',
            //boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 24px 0px',
            boxShadow: "rgba(0, 0, 0, 0.05) 0px 3px 24px 0px",
            //backgroundColor: grey50,
            border: "1px solid" + grey200,
            padding: "2px 0px",
          },
          "& .Mui-selected": {
            backgroundColor: grey100,
            "&:hover": {
              backgroundColor: grey100,
            },
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: grey100,
            "&:hover": {
              backgroundColor: grey100,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: primaryColor,
          padding: "0px 5px",
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: {
            variant: "toolbar-btn",
          },
          style: {
            cursor: "pointer",
            fontSize: "15px",
            padding: "10px",
            justifyContent: "left",
            border: "1px solid " + grey300,
            fontWeight: "bold",
            borderRadius: "5px",
            height: "40px",
            minHeight: "40px",
            maxHeight: "40px",
            color: grey800,
            backgroundColor: white,
          },
        },
        {
          props: {
            variant: "toolbar-btn-text",
          },
          style: {
            fontSize: "15px",
            padding: "10px",
            justifyContent: "center",
            fontWeight: "bold",
            minWidth: "35px",
            maxWidth: "40px",
            borderRadius: "5px",
            maxHeight: "40px",
            color: grey800,
            backgroundColor: white,
          },
        },
      ],
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          padding: "2px 10px",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          position: "fixed",
          bottom: "20px",
          right: "20px",
          fontSize: "18px",
          fontWeight: "600",
          boxShadow: "rgba(0, 0, 0, 0.25) 0px 3px 24px 0px",
        },
        extended: {
          borderRadius: "30px",
          padding: "30px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          //padding: '5px 10px',
          minHeight: "60px",
          backgroundColor: grey50,
          "&.Mui-focused": {
            backgroundColor: white,
          },
          "& fieldset": {
            //borderColor: grey100,
            borderColor: grey200,
          },
          "&:hover fieldset": {
            borderColor: grey700,
          },
          "&.Mui-focused fieldset": {
            borderColor: primaryColor,
          },
        },
        input: {
          "&::placeholder": {
            color: grey900,
            fontWeight: "400",
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          fontWeight: "500",
          fontSize: "14px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "30px",
          "& .MuiInputAdornment-root": {
            padding: "2px 5px 2px 5px",
          },
          "& .MuiInputLabel-root": {
            color: grey800,
            paddingTop: "1px",
            //padding: '3px',
            "&.Mui-focused": {
              color: grey900,
            },
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
