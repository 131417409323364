import {
  faTimes,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import theme from "../../theme/theme";

/**
 * @typedef {object} DialogRemoveQuestionProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {() => void} submit
 * @property {import("../editor/nodes/MarkNode").MergeField} mergeField
 */

/**
 * @param {DialogRemoveQuestionProps} props
 */
export default function DialogRemoveQuestion({
  open,
  close,
  submit,
  mergeField,
}) {
  const handleClose = () => {
    close();
  };

  const handleSubmit = () => {
    submit();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>
      <DialogTitle>Remove Agreement Property</DialogTitle>
      <DialogContent sx={{ my: 3 }}>
        <Box
          sx={{
            textAlign: "center",
            mb: 2,
            fontSize: "30px",
            color: theme.palette.primary.main,
          }}
        ></Box>
        <Box sx={{ mb: 2 }}>
          <Typography align="center">
            Are you sure you want to remove the <b>{mergeField?.name}</b>{" "}
            Agreement Property?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ marginRight: "auto" }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" disableElevation>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
