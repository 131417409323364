export const paramtypes = [
  {
    value: "auto",
    type: "Automatic",
    desc: "(e.g. company / counterparty details)",
  },
  { value: "string-text", type: "Free text", desc: '(e.g. "several words")' },
  //{ value: 'date-stamp', type: 'Date', desc: '(e.g. 30-JAN 2025)' },
  { value: "date-period", type: "Period", desc: "(e.g. thirty (30) days)" },
  { value: "number", type: "Number", desc: "(e.g. 30)" },
  { value: "amount", type: "Amount", desc: "(e.g. $30)" },
  { value: "email", type: "Email", desc: "(e.g. name@domain.com)" },
  { value: "link", type: "Link", desc: "(e.g. https://www.domain.com)" },
  {
    value: "list-standard",
    type: "List Standard",
    desc: "(e.g. countries, jurisdictions)",
  },
  //{ value: 'list-custom', type: 'List Custom', desc: '(self created list of options)' }
];
