import { faPen, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useContext, useState } from "react";
import { globalStore } from "../../state/store";
import { getCanveoTier } from "../../utils/getCanveoTier";
import StyledFormHelperText from "../styledComponents/StyledFormHelperText";

const initialRole = {
  active: true,
  name: "",
  description: "",
  hasReadOnly: false,
  description2: "",
  // TODO: Add labels.
};

/**
 * @typedef {object} DialogCreateRoleProps
 * @property {boolean} open
 * @property {function} closeDialog
 */

/**
 *
 * @param {DialogCreateRoleProps} props
 * @returns {React.JSX.Element}
 */
export default function DialogCreateRole({ closeDialog, open }) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [loading] = useState(false);
  const [localRole, setLocalRole] = useState(initialRole);
  const [errors, setErrors] = useState({
    name: false,
    description: false,
    hasReadOnly: false,
    description2: false,
  });

  const validate = useCallback(() => {
    if (localRole !== initialRole) {
      let /** @type {*} */ errors = {};
      if (!(localRole.name.length > 0)) {
        errors.name = "This field is required.";
      }
      if (!(localRole.description.length > 0)) {
        errors.description = "This field is required.";
      }
      if (localRole.hasReadOnly && !(localRole.description2.length > 0)) {
        errors.description2 = "This field is required.";
      }
      if (Object.keys(errors).length > 0) {
        return {
          errors: errors,
          isValid: false,
        };
      }
      return {
        isValid: true,
      };
    }
  }, [localRole]);

  const cancel = useCallback(() => {
    setLocalRole(initialRole);
    closeDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeDialog]);

  const close = useCallback(
    (/** @type {*} */ action, /** @type {*} */ message) => {
      setLocalRole(initialRole);
      closeDialog(action, message);
    },
    [closeDialog]
  );

  /**
   * @param {keyof typeof errors} field
   * @param {*} newValue
   */
  const changeRole = (field, newValue) => {
    if (errors[field] && field !== "hasReadOnly") {
      setErrors((s) => ({ ...s, [field]: false }));
    }
    if (field === "hasReadOnly") {
      setErrors((s) => ({ ...s, description2: false }));
    }

    setLocalRole((s) => ({ ...s, [field]: newValue }));
  };

  const isDirty = useCallback(
    (/** @type {*} */ original, /** @type {*} */ dirty) => {
      const a = Object.keys(original);
      const b = Object.keys(dirty);
      if (a.length !== b.length) {
        return false;
      }
      let isDirty = false;
      for (let key of a) {
        if (original[key] !== dirty[key]) {
          isDirty = true;
          break;
        }
      }
      return isDirty;
    },
    []
  );

  const createRole = useCallback(() => {
    // Validate fields.
    const validation = validate();
    // @ts-ignore
    if (!validation.isValid) {
      // @ts-ignore
      setErrors(validation.errors);
    } else {
      // Remove second description if value hasReadOnly === false.
      let roleToSend = Object.assign({}, localRole);
      if (!roleToSend.hasReadOnly) {
        roleToSend.description2 = "";
      }
      // Attempt to create.
      axios
        .post(`${state.settings.api}role`, { role: roleToSend })
        .then(() => {
          close("roleCreated");
        })
        .catch((err) => {
          console.log("error", err);
          if (err.response.data.message === "Non-unique name for role.") {
            // @ts-ignore
            setErrors((s) => ({
              ...s,
              name: "A role with this name already exists. Please try a different one.",
            }));
          } else {
            close("error", err.response.data.message);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localRole, validate]);

  return (
    <Dialog open={open} onClose={cancel} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>{loading ? "Loading ..." : `Create New Role`}</DialogTitle>

      <DialogContent>
        <Box
          sx={{
            py: 3,
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            fullWidth
            required
            error={!!errors.name}
            helperText={errors.name ?? ""}
            value={localRole.name}
            // @ts-ignore
            onInput={(e) => changeRole("name", e.target.value)}
            label="Role Name"
            placeholder="Role name (e.g. Sales)"
          />

          <TextField
            fullWidth
            multiline
            required
            error={!!errors.description}
            helperText={errors.description ?? ""}
            value={localRole.description}
            // @ts-ignore
            onInput={(e) => changeRole("description", e.target.value)}
            label="Role Description"
            placeholder='E.g. Can access contracts with the label "Customers"'
          />

          {getCanveoTier(state?.user?.email) === "experimental" && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={localRole.hasReadOnly}
                    onChange={(e) =>
                      changeRole("hasReadOnly", e.target.checked)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Has read-only option?"
              />

              <StyledFormHelperText sx={{ mt: 0 }}>
                When checked, this role can also be assigned as read-only,
                preventing users with this role from making edits to the text of
                a contract.
              </StyledFormHelperText>
            </>
          )}

          {localRole.hasReadOnly && (
            <TextField
              fullWidth
              multiline
              required
              error={!!errors.description2}
              helperText={errors.description2 ?? ""}
              value={localRole.description2}
              // @ts-ignore
              onInput={(e) => changeRole("description2", e.target.value)}
              label="Read-Only Role Description"
              placeholder='E.g. Has read-only access to the contracts with the label "Customers"'
            />
          )}
          {/* TODO: Add Labels logic. */}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button sx={{ marginRight: "auto" }} onClick={cancel}>
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={!isDirty(initialRole, localRole)}
          onClick={() => setLocalRole(initialRole)}
        >
          Reset
        </Button>

        <Button
          color="primary"
          disabled={!isDirty(initialRole, localRole)}
          onClick={createRole}
        >
          <FontAwesomeIcon icon={faPen} />
          &nbsp;&nbsp; Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
