import { faUserHelmetSafety } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  createFilterOptions,
  InputAdornment,
  TextField,
} from "@mui/material";
import React from "react";
import theme from "../theme/theme";

/**
 * @typedef {object} SelectPartyRoleProps
 * @property {*[]} options
 * @property {*[]} value
 * @property {(value: any) => void} handleChange
 */

/**
 * @param {SelectPartyRoleProps} props
 * @returns {React.JSX.Element}
 */
export default function SelectPartyRole({ options, handleChange, ...props }) {
  const filter = createFilterOptions();

  /**
   * @param {*} _
   * @param {*} newValue
   */
  const onChange = (_, newValue) => {
    if (newValue?.inputValue) return handleChange(newValue.inputValue);
    if (newValue?.inputValue === "") return;
    return handleChange(newValue);
  };

  /**
   * @param {*} options
   * @param {*} params
   */
  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some(
      (/** @type {{ label: string; }} */ option) => inputValue === option.label
    );

    if (params.inputValue === "") {
      filtered.unshift({
        disabled: true,
        inputValue: params.inputValue,
        label: `Start typing to create a role`,
      });
    }

    if (inputValue !== "" && !isExisting) {
      filtered.push({
        inputValue,
        label: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  return (
    <Autocomplete
      selectOnFocus
      openOnFocus
      clearOnBlur
      handleHomeEndKeys
      sx={{ width: "100%" }}
      freeSolo
      {...props}
      options={options?.length ? options : []}
      onChange={onChange}
      filterOptions={filterOptions}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label ?? "";
      }}
      getOptionDisabled={(option) => option.disabled}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          label={"Select Party Role"}
          placeholder={"Role name (e.g. Customer) ..."}
          autoFocus
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            autoComplete: "new-password",
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faUserHelmetSafety}
                  color={theme.palette.primary.main}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
