import { $getRoot } from "lexical";
import { useCallback } from "react";
import { $dfs } from "@lexical/utils";
import { $isMentionNode } from "../nodes/MentionNode";

export function useOnChange(setContent, setCanSubmit, setSubscribers) {
  return useCallback(
    (editorState, _editor) => {
      editorState.read(() => {
        let newContent =
          Boolean($getRoot) && Boolean($getRoot().getTextContent())
            ? $getRoot().getTextContent()
            : "";
        let subs = [];
        const mentionNodes = $dfs()
          .filter((el) => $isMentionNode(el.node))
          .map((el) => el.node);
        mentionNodes.forEach((node) => {
          if (!subs.some((s) => s === node.__user)) {
            subs.push(node.__user);
          }
        });
        setSubscribers(subs);
        setContent(newContent);
        setCanSubmit(newContent.length > 2);
      });
    },
    [setCanSubmit, setContent, setSubscribers]
  );
}
