import { faGear, faPlug, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DialogDocuSignIntegrationSettings from "../../../components/dialogs/DialogDocuSignIntegrationSettings";
import DialogIntegrationSettings from "../../../components/dialogs/DialogIntegrationSettings";
import useGoogleDrive from "../../../hooks/useGoogleDrive";
import { globalStore } from "../../../state/store";
import theme from "../../../theme/theme";
import { getDefaultIntegrations } from "../../../utils/constants";

/**
 * @typedef {object} IntegrationsProps
 * @property {*} pushOrg
 */

/**
 * @param {IntegrationsProps} props
 */
const Integrations = ({ pushOrg }) => {
  const [state] = /** @type {*} */ (useContext(globalStore));

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const oAuthRedirectState =
      /** @type {{scope: "docusign" | "hubspot" | "sharepoint" | "azure"} | false} */ (
        JSON.parse(searchParams.get("state") || "false")
      );

    if (oAuthRedirectState) {
      const { scope } = oAuthRedirectState;

      switch (scope) {
        case "docusign": {
          const token = searchParams.get("code");
          if (!token) {
            throw new Error("Missing token.");
          }

          axios
            .post(`${state.settings.api}docusign/handleRedirect`, {
              organizationId: state.org._id,
              token,
            })
            .then(() => {
              window.location.href =
                window.location.origin + window.location.pathname;
            });
          break;
        }

        case "hubspot": {
          const code = searchParams.get("code");
          if (!code) {
            throw new Error("Missing token.");
          }

          axios
            .post(`${state.settings.api}hubspot/handleRedirect`, {
              organizationId: state.org._id,
              code,
            })
            .then(() => {
              window.location.href =
                window.location.origin + window.location.pathname;
            });
          break;
        }

        case "azure": {
          const tenant = searchParams.get("tenant");
          if (!tenant) {
            throw new Error("Missing token.");
          }

          axios
            .post(`${state.settings.api}azure/handleRedirect`, {
              organizationId: state.org._id,
              tenantId: tenant,
            })
            .then(() => {
              window.location.href =
                window.location.origin + window.location.pathname;
            });
          break;
        }

        default:
          throw new Error(`${scope} is not a valid scope.`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [integrations, setIntegrations] = useState(
    getDefaultIntegrations(state?.user?.email)
  );
  const [currentIntegration, setCurrentIntegration] = useState(
    /** @type {null | typeof integrations[number]} */ (null)
  );
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [confirmDisconnect, setConfirmDisconnect] = useState(false);
  const [skribbleUsername, setSkribbleUsername] = useState("");
  const [skribbleApiKey, setSkribbleApiKey] = useState("");
  const [openSkribbleConnectDialog, setOpenSkribbleConnectDialog] =
    useState(false);

  const navigate = useNavigate();

  const { createGoogleAuthLink, signOut } = useGoogleDrive({
    next: (/** @type {string} */ id, /** @type {*} */ info) =>
      disconnect(id, info),
  });

  const connect = async function (/** @type {string} */ integrationId) {
    switch (integrationId) {
      case "gdrive":
        createGoogleAuthLink();
        break;
      case "salesforce":
        console.log("Not yet implemented.");
        break;
      case "hubspot":
        axios.get(`${state.settings.api}hubspot/oAuthUrl`).then((response) => {
          const url = response.data.data.url;
          window.location.href = url;
        });
        break;
      case "azure":
        axios.get(`${state.settings.api}azure/oAuthUrl`).then((response) => {
          const url = response.data.data.url;
          window.location.href = url;
        });
        break;
      case "docusign":
        axios
          .get(`${state.settings.api}docusign/redirectUrl`)
          .then((response) => {
            const url = response.data.data.url;
            window.location.href = url;
          });
        break;
      case "skribble": {
        setOpenSkribbleConnectDialog(true);
        break;
      }
      default:
        throw new Error(`${integrationId} is not a valid integration ID.`);
    }
  };

  const disconnect = useCallback(
    async (/** @type {string} */ id, /** @type {*} */ info) => {
      if (!!id === false) return; //no id no action
      const isAcceptedId = [
        "hubspot",
        "salesforce",
        "gdrive",
        "docusign",
      ].includes(id);
      if (!isAcceptedId) return; //wrong id
      const stateIntegration = state.org?.integrations?.[id];
      if (!!stateIntegration === false) {
        /**
         * TODO: NEW INTEGRATION
         * NOTE FOR HUBSPOT: CHANGES from refreshToken string to Object { refreshToken }
         * */
        switch (id) {
          case "hubspot":
            break;
          case "salesforce":
            break;
          case "gdrive":
            if (!!info) {
              let newOrg = state.org;
              if (!!newOrg.integrations === false) {
                newOrg.integrations = { gdrive: {} };
              }
              if (!!newOrg.integrations.gdrive === false) {
                newOrg.integrations.gdrive = {};
              }
              //credentials
              newOrg.integrations.gdrive.accessToken = info.accessToken;
              newOrg.integrations.gdrive.refreshToken = info.refreshToken;
              newOrg.integrations.gdrive.expirationDate = info.expirationDate;
              pushOrg(newOrg);
            }
            break;
          case "docusign": {
            pushOrg({ ...state.org, integrations: { docusign: undefined } });
            break;
          }
          default:
            /** do nothing */
            break;
        }
      } else if (!!stateIntegration) {
        // Uninstall integration
        let newIntegrations =
          state.org.integrations !== undefined &&
          state.org.integrations !== null
            ? state.org.integrations
            : {};
        delete newIntegrations?.[id];
        let newOrg = state.org;
        newOrg.integrations = newIntegrations;
        pushOrg(newOrg);
        switch (id) {
          case "gdrive":
            signOut();
            break;
          default:
            break;
        }
      }
      navigate("/admin/integrations");
    },
    [pushOrg, state.org, signOut, navigate]
  );

  const handleClick = (/** @type {*} */ integration) => {
    setCurrentIntegration(integration);
    setSettingsDialogOpen(true);
  };

  const handleClose = () => {
    setSettingsDialogOpen(false);
  };

  const filterIntegrations = (/** @type {string} */ search) => {
    setIntegrations(
      getDefaultIntegrations(state?.user?.email).filter((integration) =>
        integration.name.toLowerCase().startsWith(search.toLowerCase())
      )
    );
  };

  return (
    <>
      <Box>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ my: 5 }}
        >
          <Grid item display={"flex"} justifyContent={"center"} xs={8}>
            <Typography variant="h4">Integrations</Typography>
          </Grid>
          <Grid
            item
            display={"flex"}
            justifyContent={"center"}
            xs={8}
            sx={{ m: 2, mb: 5 }}
          >
            <Typography variant="body1" textAlign={"center"}>
              Connect Canveo to your other software for a seamless work
              experience
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent={"right"}
            sx={{
              minWidth: "637px",
              maxWidth: "637px",
            }}
          >
            <Grid item>
              <TextField
                onChange={(event) => {
                  const search = event.target.value;
                  filterIntegrations(search);
                }}
                sx={{ borderRadius: "100px" }}
                InputProps={{
                  style: { borderRadius: "200px" },
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <FontAwesomeIcon
                          icon={faSearch}
                          style={{
                            fontSize: "14px",
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <br />
          </Grid>

          <Grid item>
            {integrations.map((integration) => (
              <Fragment key={integration.id}>
                <Grid
                  key={integration.id}
                  container
                  direction="row"
                  sx={{
                    minWidth: "637px",
                    maxWidth: "637px",
                    backgroundColor: "#F5F5F5",
                    padding: "30px",
                  }}
                >
                  <Grid container direction="row">
                    <Grid item xs={1}>
                      <img
                        src={integration.src}
                        alt={integration.name}
                        height="50px"
                        style={{
                          objectFit: "contain",
                          aspectRatio: "1",
                          opacity: "1",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        fontSize: "17px",
                        fontWeight: "700",
                        marginTop: "13px",
                        marginLeft: "20px",
                      }}
                    >
                      {integration.name}
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    {integration.description.paragraphs.map(
                      (paragraph, index) => (
                        <Fragment key={index}>
                          <Grid item>
                            <Typography variant="body2" color={"#989898"}>
                              {paragraph}
                            </Typography>
                          </Grid>

                          <br />
                        </Fragment>
                      )
                    )}
                  </Grid>

                  <Grid container direction="row">
                    {!state.org?.integrations?.[integration.id] ? (
                      <Stack direction="row" spacing={1}>
                        <Button
                          id={integration.id}
                          size="small"
                          sx={{ width: 110 }}
                          variant="outlined"
                          onClick={() => connect(integration.id)}
                        >
                          <FontAwesomeIcon icon={faPlug} />
                          &nbsp;&nbsp;Connect ...
                        </Button>
                      </Stack>
                    ) : (
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ width: 110 }}
                          onClick={() => {
                            setConfirmDisconnect(true);
                            setCurrentIntegration(integration);
                          }}
                        >
                          <FontAwesomeIcon icon={faPlug} />
                          &nbsp;&nbsp;Connected
                        </Button>

                        {integration.displaySettings && (
                          <Tooltip title={`${integration.name} settings`}>
                            <IconButton
                              onClick={() => handleClick(integration)}
                              aria-label="integration settings"
                              size="small"
                            >
                              <FontAwesomeIcon
                                icon={faGear}
                                color={theme.palette.primary.main}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                    )}
                  </Grid>
                </Grid>

                <br />
                <br />
              </Fragment>
            ))}

            {currentIntegration && settingsDialogOpen && (
              <>
                {currentIntegration.id === "docusign" ? (
                  <DialogDocuSignIntegrationSettings
                    open={settingsDialogOpen}
                    pushOrg={pushOrg}
                    currentIntegration={currentIntegration}
                    closeDialog={handleClose}
                  />
                ) : (
                  <DialogIntegrationSettings
                    open={settingsDialogOpen}
                    pushOrg={pushOrg}
                    currentIntegration={currentIntegration}
                    closeDialog={handleClose}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* TODO: Move this into its own file. */}
      <Dialog open={openSkribbleConnectDialog} fullWidth maxWidth="sm">
        <DialogTitle>Skribble Configuration</DialogTitle>

        <DialogContent>
          <br />

          <Grid container justifyContent={"center"}>
            <Grid item xs={8}>
              <TextField
                autoFocus
                fullWidth
                label="Skribble Username"
                placeholder="Skribble Username"
                onChange={(event) => {
                  const value = event.target.value;
                  setSkribbleUsername(value);
                }}
              />
            </Grid>
          </Grid>

          <br />

          <Grid container justifyContent={"center"}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Skribble API Key"
                placeholder="Skribble API Key"
                onChange={(event) => {
                  const value = event.target.value;
                  setSkribbleApiKey(value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            onClick={() => setOpenSkribbleConnectDialog(false)}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={!skribbleUsername || !skribbleApiKey}
            onClick={() => {
              pushOrg({
                ...state.org,
                integrations: {
                  ...state.org.integrations,
                  skribble: {
                    userName: skribbleUsername,
                    apiKey: skribbleApiKey,
                  },
                },
              });
              setOpenSkribbleConnectDialog(false);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmDisconnect}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to disconnect this integration?
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setConfirmDisconnect(false);
              setCurrentIntegration(null);
            }}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            onClick={() => {
              disconnect(currentIntegration?.id || "");
              setConfirmDisconnect(false);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Integrations;
