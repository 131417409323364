/**
 * @param {{ level: *; id: *; value: *; parentId: *; listId: *; }[]} listItems
 * @param {import("../../types/sfdt").AbstractList[]} abstractLists
 * @returns {Omit<ListHandlerInstance, "currentSfdt">}
 */
export function $populateListHandler(listItems, abstractLists) {
  for (let index = 0; index < listItems.length; index++) {
    const listItem = listItems[index];

    const listLevelNumber = listItem.level; //2
    const previousListItem = listItems[index - 1]; //last item
    const currentAbstractListId = listItem?.listId; //current list id (5)
    // const existingList = lists[currentAbstractListId] !== undefined; //if exists list
    const abstractListExists = abstractLists.some(
      (abstractList) => abstractList.abstractListId === currentAbstractListId
    ); //if exists list

    // List of reversed list items until the current list item.
    const reversedListItems = listItems.slice(0, index).reverse();

    // Default value of list is 1.
    let listItemValue = 1;
    let parentId = "";
    let allowListItemValueUpdate = true;

    if (previousListItem && previousListItem.listId === currentAbstractListId) {
      //Calculate list item value
      //if there's a list before this one
      //and it's same list
      let previousListItemAtSameLevel;
      for (const item of reversedListItems) {
        if (item.listId === currentAbstractListId) {
          if (item.level === listLevelNumber && allowListItemValueUpdate) {
            previousListItemAtSameLevel = item;
            allowListItemValueUpdate = false;
          } else if (item.level <= listLevelNumber - 1) {
            if (item.level === listLevelNumber - 1) {
              parentId = item.id;
            }
            break;
          }
        }
        continue;
      }
      const continueNumbering = true; //unsure how to know if should continue or not
      if (previousListItemAtSameLevel && continueNumbering) {
        //and should continue numbering
        listItemValue = previousListItemAtSameLevel.value + 1;
      }
      //else val it's 1
    } else if (abstractListExists) {
      //if there's no list before or it's not same list and
      //it's not same list
      //but list exists somewhere
      let previousListItemAtSameLevel;
      for (const item of reversedListItems) {
        if (item.listId === currentAbstractListId) {
          if (item.level === listLevelNumber && allowListItemValueUpdate) {
            previousListItemAtSameLevel = item;
            allowListItemValueUpdate = false;
          } else if (item.level <= listLevelNumber - 1) {
            if (item.level === listLevelNumber - 1) {
              parentId = item.id;
            }
            break;
          }
        }
        continue;
      }
      if (previousListItemAtSameLevel) {
        listItemValue = previousListItemAtSameLevel.value + 1;
      }
      //else val it's 1
    } else {
      //it doesn't exist so val it's 1
    }

    listItem.value = listItemValue;
    listItem.parentId = parentId;
  }

  const result = {
    lists: abstractLists,
    root: listItems,
  };

  return result;
}
