import {
  faCircleArrowLeft,
  faCircleArrowRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ApplicationStep from "../steps/ApplicationStep";
import ConfigurationStep from "../steps/ConfigurationStep";
import TopicStep from "../steps/TopicStep";
import VisibilityStep from "../steps/VisibilityStep";
import createDefaultClauseVariant from "../utils/createDefaultClauseVariant";

/**
 * @param {DialogNewClauseVariantProps} props
 */
export default function useDialogNewClauseVariant(props) {
  const { open, close, clauseText, documentId, submit } = props;

  const [step, setStep] = useState(
    /** @type {"configuration" | "application" | "topic" | "visibility"} */ (
      "configuration"
    )
  );

  const [clauseVariant, setClauseVariant] = useState(
    createDefaultClauseVariant(clauseText)
  );

  const steps = {
    configuration: {
      component: ConfigurationStep,
      actions: {
        secondary: {
          label: "Cancel",
          buttonProps: /** @type {import("@mui/material").ButtonProps} */ ({
            disabled: false,
            onClick: close,
          }),
        },
        primary: {
          label: "Next",
          buttonProps: /** @type {import("@mui/material").ButtonProps} */ ({
            color: "primary",
            disabled: (() => {
              if (clauseVariant.type === "alternativeClause") {
                const isDisabled =
                  !clauseVariant.text ||
                  !clauseVariant.name ||
                  !clauseVariant.description;
                return isDisabled;
              } else {
                const isDisabled =
                  !clauseVariant.name || !clauseVariant.description;
                return isDisabled;
              }
            })(),
            onClick: () => setStep("application"),
            endIcon: (
              <FontAwesomeIcon
                icon={faCircleArrowRight}
                style={{
                  fontSize: "14px",
                }}
              />
            ),
          }),
        },
      },
    },
    application: {
      component: ApplicationStep,
      actions: {
        secondary: {
          label: "Back",
          buttonProps: /** @type {import("@mui/material").ButtonProps} */ ({
            disabled: false,
            onClick: () => setStep("configuration"),
            startIcon: (
              <FontAwesomeIcon
                icon={faCircleArrowLeft}
                style={{
                  fontSize: "14px",
                }}
              />
            ),
          }),
        },
        primary: {
          label: "Next",
          buttonProps: /** @type {import("@mui/material").ButtonProps} */ ({
            color: "primary",
            disabled: (() => {
              if (clauseVariant.application.type === "automatic") {
                const isDisabled = !clauseVariant.application.conditions.length;
                return isDisabled;
              } else {
                return false;
              }
            })(),
            onClick: () => setStep("topic"),
            endIcon: (
              <FontAwesomeIcon
                icon={faCircleArrowRight}
                style={{
                  fontSize: "14px",
                }}
              />
            ),
          }),
        },
      },
    },
    topic: {
      component: TopicStep,
      actions: {
        secondary: {
          label: "Back",
          buttonProps: /** @type {import("@mui/material").ButtonProps} */ ({
            disabled: false,
            onClick: () => setStep("application"),
            startIcon: (
              <FontAwesomeIcon
                icon={faCircleArrowLeft}
                style={{
                  fontSize: "14px",
                }}
              />
            ),
          }),
        },
        primary: {
          label: "Next",
          buttonProps: /** @type {import("@mui/material").ButtonProps} */ ({
            color: "primary",
            // disabled: (() => {
            //   const isDisabled = !clauseVariant.topicsIds.length;
            //   return isDisabled;
            // })(),
            disabled: false,
            onClick: () => setStep("visibility"),
            endIcon: (
              <FontAwesomeIcon
                icon={faCircleArrowRight}
                style={{
                  fontSize: "14px",
                }}
              />
            ),
          }),
        },
      },
    },
    visibility: {
      component: VisibilityStep,
      actions: {
        secondary: {
          label: "Back",
          buttonProps: /** @type {import("@mui/material").ButtonProps} */ ({
            disabled: false,
            onClick: () => setStep("topic"),
            startIcon: (
              <FontAwesomeIcon
                icon={faCircleArrowLeft}
                style={{
                  fontSize: "14px",
                }}
              />
            ),
          }),
        },
        primary: {
          label: "Submit",
          buttonProps: /** @type {import("@mui/material").ButtonProps} */ ({
            color: "primary",
            disabled: (() => {
              const isDisabled = !clauseVariant.visibility;
              return isDisabled;
            })(),
            onClick: () => submit(clauseVariant),
          }),
        },
      },
    },
  };

  return {
    documentId,
    open,
    close,
    submit,
    steps,
    step,
    setStep,
    clauseVariant,
    setClauseVariant,
  };
}
