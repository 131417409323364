import { faSearch, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { globalStore } from "../../../state/store";
import theme from "../../../theme/theme";
import { NewTicketContext } from "../contexts/NewTicketContext";

const minLabelCountForSearch = 5;

export default function AreaLabelSelector() {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [filterText, setFilterText] = useState("");
  const [filteredLabels, setFilteredLabels] = useState(/** @type {*[]} */ ([]));
  // @ts-ignore
  const { ticket, setTicket } = useContext(NewTicketContext);

  const areaLabels = useMemo(() => {
    return state.labels.filter(
      (/** @type {{ type: { name: string; }; }} */ label) =>
        label.type.name === "Area"
    );
  }, [state.labels]);

  useEffect(() => {
    if (!filterText) setFilteredLabels([...areaLabels]);

    const lowerText = filterText.toLowerCase();

    const filteredLabels = areaLabels.filter(
      (/** @type {{ name: string; }} */ label) =>
        label.name.toLowerCase().includes(lowerText)
    );

    setFilteredLabels(filteredLabels);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText]);

  function isSelected(/** @type {{ _id: string; }} */ label) {
    const isSelected = ticket.areaLabelId === label._id;
    return isSelected;
  }

  function toggleLabel(/** @type {{ _id: string; }} */ label) {
    const remove = isSelected(label);

    if (remove) {
      setTicket((ticket) => {
        return {
          ...ticket,
          areaLabelId: "",
        };
      });
    } else {
      setTicket((ticket) => {
        return {
          ...ticket,
          areaLabelId: label._id,
        };
      });
    }
  }

  return (
    <Grid
      container
      direction="column"
      sx={{
        px: 2,
        mt: 4,
        maxHeight: 420,
        overflow: "auto",
        maxWidth: 800,
      }}
    >
      {(areaLabels.length > minLabelCountForSearch || filterText) && (
        <Grid item sx={{ my: 2, ml: "auto" }}>
          <TextField
            variant="outlined"
            size="small"
            onChange={(e) => setFilterText(e.target.value)}
            value={filterText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{ padding: "4px 7px" }}
                  />
                </InputAdornment>
              ),
              endAdornment: !!filterText && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setFilterText("")}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                // @ts-ignore
                backgroundColor: theme.palette.grey[250],
                borderRadius: "10px",
                minHeight: "unset",
              },
              inputProps: {
                style: { fontWeight: "bold" },
              },
            }}
          />
        </Grid>
      )}
      <Grid item sx={{ width: "fit-content" }}>
        {filteredLabels.map((label) => (
          <Chip
            key={label._id}
            // @ts-ignore
            variant={isSelected(label) ? "contained" : "outlined"}
            label={label.name}
            color={label.type.color}
            sx={{
              fontWeight: "700",
              m: 1,
              px: 2,
            }}
            onClick={() => toggleLabel(label)}
          />
        ))}
      </Grid>
    </Grid>
  );
}
