import { Grid, TextField } from "@mui/material";
import React, { useContext } from "react";
import { NewTicketContext } from "../contexts/NewTicketContext";

/**
 * @param {{ active: boolean }} props
 * @returns {React.JSX.Element}
 */
export default function AreaLabelSelector({ active }) {
  const { ticket, setTicket } = useContext(NewTicketContext);

  if (!active) return <></>;

  return (
    <Grid
      container
      direction="column"
      sx={{
        px: 2,
        pt: 4,
        maxHeight: 420,
        overflow: "auto",
        maxWidth: 800,
      }}
    >
      <TextField
        autoFocus={!ticket.request.name}
        value={ticket.request.name}
        label="Name"
        placeholder="Enter the ticket name ..."
        onChange={(event) => {
          setTicket((ticket) => {
            return {
              ...ticket,
              request: {
                ...ticket.request,
                name: event.target.value,
              },
            };
          });
        }}
      />

      <TextField
        label="Description"
        placeholder="Describe your request ..."
        rows={3}
        multiline
        value={ticket.request.description}
        onChange={(event) => {
          setTicket((ticket) => {
            return {
              ...ticket,
              request: {
                ...ticket.request,
                description: event.target.value,
              },
            };
          });
        }}
        sx={{ marginTop: 2 }}
      />
    </Grid>
  );
}
