import { $getSelection, $isRangeSelection } from "lexical";
import { handleSelectionDelete } from "../../../../utils/handleSelectionDelete";
import { preventEventPropagation } from "../utils/preventEventPropagation";

/**
 * @param {boolean} isBackwards
 * @param {RedlineData} defaultRedlineData
 * @returns {boolean}
 */
export function deleteWordCommandHandler(isBackwards, defaultRedlineData) {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) return preventEventPropagation();

  selection.modify("extend", isBackwards, "word");

  handleSelectionDelete(selection, defaultRedlineData);

  return preventEventPropagation();
}
