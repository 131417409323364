import { Box, CircularProgress } from "@mui/material";
import { circularProgressClasses } from "@mui/material/CircularProgress";
import React from "react";
import logo from "../assets/img/ct-logo.png";
import theme from "../theme/theme";

/**
 * @typedef {*} CanveoCircularProgressProps
 */

/**
 * @param {CanveoCircularProgressProps} props
 * @returns {React.JSX.Element}
 */
export default function CanveoCircularProgress(props) {
  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: `url(${logo})`,
        backgroundSize: "50px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "35px 35px",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: theme.palette.grey[100],
        }}
        size={120}
        thickness={6}
        {...props}
        value={100}
      />

      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: theme.palette.grey[50],
          animationDuration: "1000ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={120}
        thickness={6}
        {...props}
      />
    </Box>
  );
}
