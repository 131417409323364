import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "@mui/material";
import React from "react";
import theme from "../theme/theme";

/**
 * @typedef {object} ChipButtonBaseProps
 * @property {() => void} buttonClick
 * @property {string} iconPos
 * @property {string} text
 * @property {import("@fortawesome/fontawesome-svg-core").IconProp} icon
 * @property {string} color
 * @property {string} [toolttipTitle]
 */

/**
 * @typedef {Omit<import("@mui/material").ButtonProps, "color"> & ChipButtonBaseProps} ChipButtonProps
 */

/**
 * @param {ChipButtonProps} props
 * @returns {JSX.Element}
 */
export default function ChipButton({
  disabled,
  variant,
  color,
  buttonClick,
  iconPos,
  text,
  icon,
  toolttipTitle,
}) {
  return (
    <Tooltip title={toolttipTitle} arrow>
      <span>
        <Button
          disabled={disabled}
          variant={variant}
          size="small"
          color={["secondary"].includes(color || "") ? "secondary" : "primary"}
          disableElevation
          sx={
            ["lightGrey"].includes(color || "")
              ? {
                  boxSizing: "border-box",
                  border: "1px solid" + theme.palette.grey[300],
                  color: theme.palette.grey[700],
                  //backgroundColor: theme.palette.grey[100],
                  "&:hover": {
                    color: theme.palette.grey[900],
                    backgroundColor: theme.palette.grey[200],
                  },
                }
              : {}
          }
          style={{
            padding: "0px 18px",
            height: "30px",
            fontWeight: "700",
            ...(disabled ? { pointerEvents: "none" } : {}),
          }}
          onClick={buttonClick}
        >
          {iconPos === "right" ? text : ""}

          {icon ? (
            <FontAwesomeIcon
              icon={icon}
              style={{
                padding:
                  iconPos === "right" ? "0px 0px 0px 10px" : "0px 10px 0px 0px",
              }}
            />
          ) : (
            text
          )}

          {iconPos === "left" ? text : ""}
        </Button>
      </span>
    </Tooltip>
  );
}
