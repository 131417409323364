import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  $getRoot,
  $getSelection,
  COMMAND_PRIORITY_EDITOR,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useContext, useEffect } from "react";
import SweetScroll from "sweet-scroll";
import { NAVIGATE_TO_CLAUSE_TYPES } from "../../../state/reducers/playbookEvents";
import { globalStore } from "../../../state/store";
import { ClauseNode } from "../nodes";
import { $isClauseNode } from "../nodes/ClauseNode";
import { PROPAGATE_EVENT } from "./TrackChangesPlugin/utils";

/**
 * @typedef {object} PlaybookPluginProps
 * @property {"publisher" | "subscriber"} type
 * @property {(clauseTypes: string[]) => void} [onSelectedClauseTypesChange]
 */

/**
 * @param {PlaybookPluginProps} props
 * @returns {JSX.Element | null}
 */
export default function PlaybookPlugin({ type, onSelectedClauseTypesChange }) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();

  useEffect(
    () => {
      return mergeRegister(
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          (_payload, _editor) => {
            if (type !== "publisher") return PROPAGATE_EVENT;

            const selection = $getSelection();
            if (!selection) return PROPAGATE_EVENT;

            const [selectionNode] = selection.getNodes();
            if (!selectionNode) return PROPAGATE_EVENT;

            const clauseNode = $getNearestNodeOfType(selectionNode, ClauseNode);
            if (!clauseNode) return PROPAGATE_EVENT;

            const clauseTypes = clauseNode.getClauseTypes();
            if (!(clauseTypes instanceof Array)) {
              return PROPAGATE_EVENT;
            }

            dispatch({
              type: NAVIGATE_TO_CLAUSE_TYPES,
              payload: {
                event: NAVIGATE_TO_CLAUSE_TYPES,
                clauseTypes,
              },
            });

            return PROPAGATE_EVENT;
          },
          COMMAND_PRIORITY_EDITOR
        )
      );
    },
    // The only dependency we care about is the editor.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor]
  );

  useEffect(
    () => {
      if (type !== "subscriber") return;
      if (!state?.playbookEvents) return;

      // Dispatch clause type changes to parent.
      if (onSelectedClauseTypesChange) {
        onSelectedClauseTypesChange(state?.playbookEvents?.clauseTypes);
      }

      if (!state?.playbookEvents?.clauseTypes?.length) return;

      editor.getEditorState().read(() => {
        const sectionNodes = $getRoot().getChildren();
        const clauseNodes = sectionNodes.flatMap((node) => node.getChildren());
        for (const clauseNode of clauseNodes) {
          if (!$isClauseNode(clauseNode)) continue;

          const clauseTypes = clauseNode.getClauseTypes();
          if (
            clauseTypes.some((clauseType) =>
              state?.playbookEvents?.clauseTypes.some(
                (/** @type {string} */ ct) => ct === clauseType
              )
            )
          ) {
            const element = editor.getElementByKey(clauseNode.getKey());
            if (!element) return;

            const containerElement = document.getElementById(
              "playbook-drawer-editor-container"
            );
            if (!containerElement) return;

            const scroller = new SweetScroll(
              {
                duration: 800,
                offset: -30,
                easing: "easeInOutQuart",
              },
              containerElement
            );
            scroller.toElement(element);
            return;
          }
        }
      });
    },
    // We only want this to useEffect to react to changes to playbookEvents.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.playbookEvents]
  );

  return null;
}
