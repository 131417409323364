const sortParties = (a, b) => {
  if (a.partyID === "party0") return -1;
  if (b.partyID === "party0") return 1;
  return 0;
};

const partiesReducer = (state, action) => {
  switch (action.type) {
    case "INIT_PARTIES":
      return { parties: action.payload.sort(sortParties) };
    default:
      return { parties: state.parties };
  }
};

export default partiesReducer;
