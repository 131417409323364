import axios from "axios";
import { DateTime } from "luxon";
import countries from "../../../assets/static/countries";

/**
 * @param {*} document
 * @param {*} state
 * @param {boolean} isTemplate
 * @returns {Promise<string>}
 */
export default async function getFileNameFromPathBuilder(
  document,
  state,
  isTemplate
) {
  const { org, subs, agrTypes, avs, agrExec } = state;

  let filename = "";
  let areaLabelsCounter = 0;
  let labelsCounter = 0;
  let counterpartyEntityWithOrganization = { organization: {} };
  let myClientEntityWithOrganization = { organization: {} };
  let hasMultipleCounteparties = false;

  if (!isTemplate) {
    const counterparty = document?.ents?.find(
      (/** @type {{ partyID: string; }} */ party) => party.partyID === "party1"
    );

    if (counterparty?.entID) {
      const conterpartyEntityId = counterparty.entID;

      const result = await axios.get(
        `${state.settings.api}entity/${conterpartyEntityId}`
      );

      counterpartyEntityWithOrganization = result.data.data;
    }

    const myClient = document?.ents?.find(
      (/** @type {{ myClient: boolean; }} */ party) => party.myClient
    );
    if (myClient?.entID) {
      const myClientEntityId = myClient.entID;

      const result = await axios.get(
        `${state.settings.api}entity/${myClientEntityId}`
      );

      myClientEntityWithOrganization = result.data.data;
    }

    hasMultipleCounteparties = document.ents.some(
      (/** @type {{ partyID: string; }} */ party) => party.partyID === "party2"
    );
  }

  const pathBuilder = state.org.defaultFileNameBuilder;

  for (const { label, selectedOption } of pathBuilder) {
    if (!selectedOption) continue;

    if (label === "Separator") {
      filename +=
        selectedOption.label === "               [space]"
          ? " "
          : selectedOption.label;
      continue;
    }

    switch (selectedOption.label) {
      // Agreement Info Options
      case "Name": {
        filename += document?.agrTitle || "";
        break;
      }
      case "Type": {
        if (isTemplate) {
          filename += "Template ";
          break;
        }

        /** @type {{ fullName: string[], shortName: string} | null} */
        let agrType =
          agrTypes.find(
            (/** @type {{ _id: string; }} */ type) =>
              type._id === document?.agrTypeID
          ) || null;

        if (!agrType) {
          const result = await axios.get(
            `${state.settings.api}agrType/${document?.agrTypeID}`
          );
          agrType = result.data.data;
          if (!agrType) {
            agrType = { fullName: [""], shortName: "" };
          }
        }

        filename += `${agrType?.fullName?.at(0) || ""} (${
          agrType?.shortName || ""
        })`;
        break;
      }
      case "Area": {
        filename +=
          document?.areaLabels[areaLabelsCounter]?.labelID?.name || "";
        areaLabelsCounter++;
        break;
      }
      case "Labels": {
        filename += document?.labels[labelsCounter]?.labelID?.name || "";
        areaLabelsCounter++;
        break;
      }
      case "Status": {
        let agreementStatus = document?.agrStatus || "";
        if (agreementStatus === "InEffect") {
          agreementStatus = "In Effect";
        }
        filename += agreementStatus;
        break;
      }
      case "Version": {
        let /** @type {string} */ version;

        // Export correct version if exporting document from version selector.
        if (state?.drawerVersions?.active) {
          version =
            state?.drawerVersions?.versions?.find(
              (/** @type {{ _id: string; }} */ drawerVersion) =>
                drawerVersion._id === state?.drawerVersions?.active?._id
            )?.version || "";
        }
        // Otherwise, export latest version.
        else {
          version = avs?.at(0)?.version || "";
        }

        filename += version ? `v${version}` : "";

        // If the agreement status is "In Effect" then "_signed" should be added to the end
        // of the version e.g., "v12_signed".
        if (document?.agrStatus === "InEffect") {
          filename += `_signed`;
        }
        break;
      }
      // Party Info Options
      case "Counterparty Org Name": {
        filename +=
          // @ts-ignore
          counterpartyEntityWithOrganization?.organization?.shortName || "";
        if (hasMultipleCounteparties) {
          filename += " et. al.";
        }
        break;
      }
      case "Counterparty Legal Name": {
        // @ts-ignore
        filename += counterpartyEntityWithOrganization?.legalName || "";
        break;
      }
      case "Counterparty Country": {
        filename +=
          countries.find(
            (country) =>
              country.code ===
              // @ts-ignore
              counterpartyEntityWithOrganization?.address?.at(0)?.country
          )?.label || "";
        break;
      }
      case "Client Org Name": {
        filename +=
          // @ts-ignore
          myClientEntityWithOrganization?.organization?.shortName || "";
        break;
      }
      case "Client Legal Name": {
        // @ts-ignore
        filename += myClientEntityWithOrganization?.legalName || "";
        break;
      }
      case "Client Country": {
        filename +=
          countries.find(
            (country) =>
              country.code ===
              // @ts-ignore
              myClientEntityWithOrganization?.address?.at(0)?.country
          )?.label || "";
        break;
      }
      case "Our Org Name": {
        filename += org?.shortName || "";
        break;
      }
      case "Our Legal Name": {
        filename += subs?.at(0)?.legalName || "";
        if (subs?.length > 1) {
          filename += " et. al.";
        }
        break;
      }
      case "Our Country": {
        filename +=
          countries.find((c) => c.code === subs?.at(0)?.address?.at(0)?.country)
            ?.label || "";
        break;
      }
      // Dates Options
      case "Created Date": {
        filename +=
          DateTime.fromISO(document?.creationDate).toFormat("dd LLL yyyy") ||
          "";
        break;
      }
      case "Last Updated Date": {
        if (document.agrStatus === "In Effect") {
          filename +=
            DateTime.fromISO(agrExec?.creationDate).toFormat("dd LLL yyyy") ||
            "";

          break;
        }
        filename +=
          DateTime.fromISO(document?.lastUpdateDate).toFormat("dd LLL yyyy") ||
          "";
        break;
      }
      case "Signed Date": {
        filename +=
          DateTime.fromISO(agrExec?.creationDate).toFormat("dd LLL yyyy") || "";
        break;
      }
      case "Signed Year": {
        filename +=
          DateTime.fromISO(agrExec?.creationDate).toFormat("yyyy") || "";
        break;
      }
      // Collaborators
      case "Current Agreement Owner": {
        // Since we currently do not allow changing owners, the current owner is always the creator.
        filename += document?.creationBy?.displayName || "";
        break;
      }

      case "Agreement Creator": {
        filename += document?.creationBy?.displayName || "";
        break;
      }

      default: {
        throw new Error(`${selectedOption.label} is not a valid option.`);
      }
    }
  }

  return filename;
}
