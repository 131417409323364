/**
 * @param {*} state
 * @param {*} action
 */
const agrsReducer = (state, action) => {
  switch (action.type) {
    case "INIT_AGRS":
      return { agrs: action.payload };
    case "UPDATE_AGRS":
      const updatedAgrs = state.agrs.map(
        (/** @type {{ _id: string; }} */ agr) => {
          if (agr._id === action.payload._id) return { ...action.payload };
          return agr;
        }
      );
      return { agrs: updatedAgrs };
    case "ADD_AGRS":
      return { agrs: [...state.agrs, action.payload] };
    case "DELETE_AGRS":
      const filteredAgrs = state.agrs.filter(
        (/** @type {{ _id: string; }} */ agr) => agr._id !== action.payload
      );
      return { agrs: filteredAgrs };
    default:
      return { agrs: state.agrs };
  }
};

export default agrsReducer;
