import docusign from "../assets/img/docusign.png";
import gdrive from "../assets/img/gdrive.png";
import hubspot from "../assets/img/hubspot.png";
import salesforce from "../assets/img/salesforce.png";
import sharepoint from "../assets/img/sharepoint.png";
import skribble from "../assets/img/skribble.png";
import { getCanveoTier } from "./getCanveoTier";

/**
 * @param {string} email
 */
export function getDefaultIntegrations(email) {
  const integrations = [
    {
      id: "hubspot",
      name: "HubSpot",
      src: hubspot,
      description: {
        paragraphs: [
          "Connect your Hubspot account with Canveo and use your Hubspot data in Canveo.",
          "Create an agreement directly from within Hubspot, and access the agreement and its metadata in Hubspot. Use data from Hubspot to create parties and collaborators in Canveo.",
        ],
      },
      displaySettings: false,
    },
  ];

  if (getCanveoTier(email) === "experimental") {
    integrations.push(
      {
        id: "salesforce",
        name: "Salesforce",
        src: salesforce,
        description: {
          paragraphs: [
            "Connect your Salesforce account with Canveo and sync data in both directions.",
            "Create an agreement directly from within Salesforce, and access the agreement and its metadata in Salesforce. Use data from Salesforce to create parties and collaborators in Canveo.",
          ],
        },
        displaySettings: true,
      },
      {
        id: "gdrive",
        name: "Google Drive",
        src: gdrive,
        description: {
          paragraphs: [
            "Connect your Google Drive account with Canveo and store agreements directly in Google Drive.",
            "All signed agreement will automatically be saved to a designated folder in your Google Drive.",
          ],
        },
        displaySettings: true,
      },
      {
        id: "azure",
        name: "SharePoint",
        src: sharepoint,
        description: {
          paragraphs: [
            "SharePoint is a web-based collaborative platform that integrates natively with Microsoft 365. Launched in 2001, SharePoint is primarily sold as a document management and storage system.",
            "It is also used for sharing information through an intranet, implementing internal applications, and for implementing business processes.",
          ],
        },
        displaySettings: true,
      }
    );
  }

  integrations.push({
    id: "docusign",
    name: "Docusign",
    src: docusign,
    description: {
      paragraphs: [
        "Canveo uses DocuSign as the default e-signature solution for most contracts. This comes included in your Canveo subscription.",
        "However, if you have previously told us that you would like to use your pre-existing DocuSign subscription together with Canveo, you can do so by connecting your account here. All contracts in Canveo will then be signed using your DocuSign subscription.",
      ],
    },
    displaySettings: true,
  });

  if (getCanveoTier(email) === "experimental") {
    integrations.push({
      id: "skribble",
      name: "Skribble",
      src: skribble,
      description: {
        paragraphs: [
          "Canveo uses Skribble by default for Qualified Electronic Signatures (QES) pursuant to Swiss law (ZertES). This comes included in your Canveo subscription.",
          "However, if you have previously told us that you would like to use your pre-existing Skribble subscription together with Canveo, you can do so by connecting your account here. All contracts in Canveo will then be signed using your Skribble subscription.",
        ],
      },
      displaySettings: true,
    });
  }

  return integrations;
}

export const CLIENT_ID = process.env.REACT_APP_GDRIVE_CLIENT_ID;
export const API_KEY = process.env.REACT_APP_GDRIVE_API_KEY;
export const DISCOVERY_DOC =
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";
// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
export const SCOPES = "https://www.googleapis.com/auth/drive.file";

export const dateTime = new Intl.DateTimeFormat("en-GB", {
  weekday: "short",
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});

export const REF_REGEX = /(_Ref|SEQ.+)[0-9]*/g;

/** This is to check if the inline is an hyperlink.*/
export const HYPERLINK_REGEX =
  /(?<type>HYPERLINK|_top|INCLUDEPICTURE|^_[a-zA-Z0-9_]+)/;

/** This is to check if the inline is an hyperlink.
 *
 *  **Can't be used when it can be a regular inline because it considers any possible combination by default**
 */
export const HYPERLINK_REGEX2 =
  /(?<type>HYPERLINK|_top|INCLUDEPICTURE|^_[a-zA-Z0-9_]+|[a-zA-Z0-9_]+)/;

/** This is to get the font-family property from a css string. */
export const FONT_FAMILY_REGEX =
  /font-family:\s*(?<family>[\w\-\s,"(&\w+;)]+);/;

/** This is to get the font-size property from a css string. */
export const FONT_SIZE_REGEX = /font-size:\s*(?<size>[0-9]+)\w+;/;

/** This is to get the font color property from a css string.
 *
 * Grabs color: rgb(---,---,---) or #-------- (max 8 chars) or just color name
 */
export const FONT_COLOR_REGEX =
  /color:\s*(?<color>#[0-9]{6,8}|\w+|rgb\s*\(\s*[0-9]{1,3},\s*[0-9]{1,3},\s*[0-9]{1,3}\))\s*;/;

export const TESTING = false;
