import { faCircleArrowRight, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { currencyCodes, durationUnits } from "../constants";
import { getMergeFieldDefaultValue, mergeFieldValueIsValid } from "../utils";

/**
 * @typedef {object} ListOptionsDialogProps
 * @property {boolean} open
 * @property {import("../constants").ListOption[]} existingListOptions,
 * @property {string} mergeFieldValueType
 * @property {() => void} close
 * @property {(listOptions: import("../constants").ListOption[], allowSelectingMultipleListOptions: boolean) => void} submit
 * @property {boolean} allowSelectingMultipleListOptionsOption
 * @property {boolean} allowSelectingMultipleListOptions
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setAllowSelectingMultipleListOptions
 */

/**
 * @param {ListOptionsDialogProps} props
 */
export default function ListOptionsDialog({
  open,
  existingListOptions,
  mergeFieldValueType,
  close,
  submit,
  allowSelectingMultipleListOptionsOption,
  allowSelectingMultipleListOptions,
  setAllowSelectingMultipleListOptions,
}) {
  /** @type {import("../constants").ListOption[]} */
  const defaultListOptions = [...existingListOptions];
  const [listOptions, setListOptions] = useState(defaultListOptions);

  const addListOption = () => {
    const lastElement = listOptions[listOptions.length - 1];

    let defaultOptions = {};

    if (lastElement.mergeFieldValue.type === "duration") {
      defaultOptions = {
        durationUnit: lastElement.mergeFieldValue.durationUnit,
      };
    }

    if (lastElement.mergeFieldValue.type === "currency") {
      defaultOptions = {
        currencyUnit: lastElement.mergeFieldValue.currencyUnit,
      };
    }

    setListOptions([
      ...listOptions,
      {
        id: uuid(),
        mergeFieldValue: {
          ...getMergeFieldDefaultValue(mergeFieldValueType),
          ...defaultOptions,
        },
      },
    ]);
  };

  const handleListOptionChange = (
    /** @type {number}*/ index,
    /** @type {import("../../editor/nodes/MarkNode").MergeFieldValue}*/ value
  ) => {
    const newListOptions = [...listOptions];
    newListOptions[index].mergeFieldValue = value;
    setListOptions(newListOptions);
  };

  const removeListOption = (/** @type {number}*/ index) => {
    const newListOptions = [...listOptions];
    newListOptions.splice(index, 1);
    setListOptions(newListOptions);
  };

  const canSubmit = () => {
    const invalidValue = listOptions.some(
      (x) => !mergeFieldValueIsValid(x.mergeFieldValue)
    );

    return !invalidValue;
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        close();
      }}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>List Options</DialogTitle>

      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton
          onClick={() => {
            close();
          }}
        >
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogContent id="list-options-dialog-content">
        <Box
          sx={{
            height: "250px",
          }}
        >
          <Grid container direction="row">
            <br />
          </Grid>

          {listOptions.map(({ id, mergeFieldValue }, index) => (
            <Grid
              key={`list-option-control-${id}`}
              container
              direction={"row"}
              alignItems={"center"}
              sx={{ marginTop: "20px" }}
            >
              <Grid item xs={3}></Grid>

              <Grid item xs={7}>
                {mergeFieldValue.type === "freeText" && (
                  <>
                    <TextField
                      autoFocus={true}
                      value={mergeFieldValue.value}
                      sx={{ width: "286px" }}
                      variant="outlined"
                      onChange={(event) => {
                        handleListOptionChange(index, {
                          ...mergeFieldValue,
                          type: "freeText",
                          value: event.target.value,
                        });
                      }}
                    />
                  </>
                )}

                {mergeFieldValue.type === "date" && (
                  <TextField
                    autoFocus={true}
                    value={
                      mergeFieldValue.value
                        ? format(new Date(mergeFieldValue.value), "yyyy-MM-dd")
                        : format(new Date(), "yyyy-MM-dd")
                    }
                    type="date"
                    sx={{ width: "286px" }}
                    variant="outlined"
                    onChange={(event) => {
                      const date = new Date(event.target.value);
                      if (!isNaN(date.getDate())) {
                        const isoStringDate = date.toISOString();
                        handleListOptionChange(index, {
                          ...mergeFieldValue,
                          value: isoStringDate,
                        });
                      } else {
                        handleListOptionChange(index, {
                          ...mergeFieldValue,
                          value: "",
                        });
                      }
                    }}
                  />
                )}

                {mergeFieldValue.type === "duration" && (
                  <>
                    <Grid container direction={"row"} justifyContent={"center"}>
                      <Grid item xs={5}>
                        <TextField
                          type="number"
                          autoFocus={true}
                          value={mergeFieldValue.durationValue}
                          variant="outlined"
                          onChange={(event) => {
                            const number = Number(event.target.value);
                            if (number) {
                              handleListOptionChange(index, {
                                ...mergeFieldValue,
                                durationValue: event.target.value,
                              });
                            } else {
                              handleListOptionChange(index, {
                                ...mergeFieldValue,
                                durationValue: "",
                              });
                            }
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={7}
                        sx={{ paddingLeft: "20px", paddingRight: "20px" }}
                      >
                        <Autocomplete
                          value={mergeFieldValue.durationUnit}
                          options={durationUnits}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          onChange={(_, option) => {
                            if (option) {
                              handleListOptionChange(index, {
                                ...mergeFieldValue,
                                durationUnit: option,
                              });
                            } else {
                              handleListOptionChange(index, {
                                ...mergeFieldValue,
                                durationUnit: {
                                  label: "",
                                  value: "",
                                },
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                label="Duration Unit"
                                placeholder="Select Duration Unit ..."
                                variant="outlined"
                              />
                            </>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}

                {mergeFieldValue.type === "number" && (
                  <>
                    <TextField
                      autoFocus={true}
                      type="number"
                      value={mergeFieldValue.value}
                      sx={{ width: "286px" }}
                      variant="outlined"
                      onChange={(event) => {
                        const number = Number(event.target.value);
                        if (number) {
                          handleListOptionChange(index, {
                            ...mergeFieldValue,
                            value: event.target.value,
                          });
                        } else {
                          handleListOptionChange(index, {
                            ...mergeFieldValue,
                            value: "",
                          });
                        }
                      }}
                    />
                  </>
                )}

                {mergeFieldValue.type === "currency" && (
                  <>
                    <Grid container direction={"row"} justifyContent={"center"}>
                      <Grid item xs={5}>
                        <Autocomplete
                          options={currencyCodes}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          value={mergeFieldValue.currencyUnit}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          onChange={(_, option) => {
                            if (option) {
                              handleListOptionChange(index, {
                                ...mergeFieldValue,
                                currencyUnit: option,
                              });
                            } else {
                              handleListOptionChange(index, {
                                ...mergeFieldValue,
                                currencyUnit: {
                                  label: "",
                                  value: "",
                                },
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                autoFocus={index === 0}
                                label="Currency Unit"
                                placeholder="Select Currency Unit ..."
                                variant="outlined"
                              />
                            </>
                          )}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={7}
                        sx={{ paddingLeft: "20px", paddingRight: "20px" }}
                      >
                        <TextField
                          autoFocus={true}
                          type="number"
                          value={mergeFieldValue.currencyValue}
                          variant="outlined"
                          onChange={(event) => {
                            const number = Number(event.target.value);
                            if (number) {
                              handleListOptionChange(index, {
                                ...mergeFieldValue,
                                currencyValue: event.target.value,
                              });
                            } else {
                              handleListOptionChange(index, {
                                ...mergeFieldValue,
                                currencyValue: "",
                              });
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}

                {mergeFieldValue.type === "percentage" && (
                  <>
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Grid item xs={10} sx={{ paddingLeft: "20px" }}>
                        <TextField
                          type="number"
                          autoFocus={true}
                          value={mergeFieldValue.value}
                          variant="outlined"
                          sx={{ width: "250px" }}
                          onChange={(event) => {
                            const number = Number(event.target.value);
                            if (number) {
                              handleListOptionChange(index, {
                                ...mergeFieldValue,
                                value: event.target.value,
                              });
                            } else {
                              handleListOptionChange(index, {
                                ...mergeFieldValue,
                                value: "",
                              });
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={2} sx={{ paddingLeft: "10px" }}>
                        <div>{"%"}</div>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid item xs={2}>
                <IconButton onClick={() => removeListOption(index)}>
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ padding: "4px 7px", fontSize: "20px" }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          ))}

          <Grid container direction="row">
            <br />
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent={"center"}
            style={{ color: "#7243DD", cursor: "pointer" }}
          >
            <span
              style={{ marginBottom: "20px" }}
              onClick={() => {
                addListOption();

                // Scroll to the bottom after new option is added.
                setTimeout(() => {
                  const dialogContent = document.getElementById(
                    "list-options-dialog-content"
                  );
                  if (dialogContent) {
                    dialogContent.scrollTop =
                      dialogContent.scrollHeight - dialogContent.clientHeight;
                  }
                }, 0);
              }}
            >
              Add list option ...
            </span>
          </Grid>

          <Grid container direction="row"></Grid>
        </Box>
      </DialogContent>

      {allowSelectingMultipleListOptionsOption && (
        <>
          <br />
          <Grid container justifyContent={"space-around"}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() =>
                      setAllowSelectingMultipleListOptions(
                        !allowSelectingMultipleListOptions
                      )
                    }
                  />
                }
                label="Allow multiple options"
              />
            </FormGroup>
          </Grid>
        </>
      )}

      <DialogActions>
        <Button
          onClick={() => {
            setListOptions([]);
            close();
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={!canSubmit()}
          onClick={() => {
            // Check if there are valid options and remove the invalid ones.
            const validListOptions = listOptions.filter((option) =>
              mergeFieldValueIsValid(option.mergeFieldValue)
            );

            if (validListOptions.length) {
              setListOptions(validListOptions);
            }

            submit(listOptions, allowSelectingMultipleListOptions);
          }}
          endIcon={
            <FontAwesomeIcon
              icon={faCircleArrowRight}
              style={{
                fontSize: "14px",
              }}
            />
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
