import {
  $createHeadingNode,
  $isHeadingNode,
  HeadingNode,
} from "@lexical/rich-text";
import {
  $getNearestBlockElementAncestorOrThrow,
  $getNearestNodeOfType,
} from "@lexical/utils";
import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
} from "lexical";
import { useCallback } from "react";
import { $createClauseNode, ClauseNode } from "../../nodes/ClauseNode";
import {
  $createCustomListItemNode,
  $isCustomListItemNode,
  CustomListItemNode,
} from "../../nodes/CustomListItemNode";
import {
  $createCustomListNode,
  CustomListNode,
} from "../../nodes/CustomListNode";
import { $createRedlineNode } from "../../nodes/RedlineNode";
import {
  PREVENT_EVENT_PROPAGATION,
  PROPAGATE_EVENT,
  isAtEndOfCustomListItemNode,
} from "../TrackChangesPlugin/utils";
import { GlobalListHandler } from "./GlobalListHandler";
import {
  createNewParagraph,
  createNewPseudoItem,
  updateStyleName,
} from "./utils";
/**
 *
 * @param {ExtendedListProps} props
 * @return
 */
const useExtendedLists = (props) => {
  /** @deprecated */
  // eslint-disable-next-line no-unused-vars
  const getListItem = useCallback((node) => {
    return $isCustomListItemNode(node)
      ? node
      : $isCustomListItemNode(node.getParent())
      ? node.getParent()
      : node.getParent().getParent();
  }, []);

  const customHandleListInsertParagraph = useCallback((editor) => {
    const listHandler = GlobalListHandler.getInstance();
    const selection = $getSelection();
    const isCollapsed = selection.isCollapsed();
    if (!$isRangeSelection(selection)) {
      return PROPAGATE_EVENT;
    }
    const anchor = selection.anchor.getNode();
    const parentIsHeader = !!$getNearestNodeOfType(anchor, HeadingNode);

    /** @type {CustomListItemNode | null} */
    let parent = $getNearestNodeOfType(anchor, CustomListItemNode);

    /** @type {import("../../nodes").CustomListItemNode | import("lexical").ParagraphNode} */
    let replacementNode;
    const current = listHandler.root.find((it) => it.id === parent.getKey());
    if (!current) throw new Error("List handler error: couldn't find node id");

    const isTopLevel = current.level === 0;
    const isEmpty = parent.getTextContent().trim().length === 0; //0 => false, true otherwise

    if (isTopLevel && isEmpty) {
      //should check if is empty or something
      const topLevel = $getNearestNodeOfType(anchor, ClauseNode);
      replacementNode = $createParagraphNode();
      topLevel.append(replacementNode);
      replacementNode.select();
      const previous = replacementNode.getPreviousSibling();
      if (previous) previous.remove();
    } else if ($isCustomListItemNode(parent)) {
      const prevClause = $getNearestNodeOfType(anchor, ClauseNode); //current clause
      const newClause = $createClauseNode({
        clauseTypes: [],
        workflows: [],
        libIDs: [],
        filter: "none",
        lock: "none",
        variants: [],
      }); //new clause to create

      /** @type {CustomListNode} */
      const list = $getNearestNodeOfType(anchor, CustomListNode);
      let listParent = $createCustomListNode(
        current.listId,
        list.getListType(),
        list.getStart(),
        undefined,
        list.getHasMarker()
      );
      listParent.setParagraphFormat(list.getParagraphFormat());
      listParent.setCharacterFormat(list.getCharacterFormat());

      let replacementNode = $createCustomListItemNode(
        parent.getHasMarker(),
        parent.getValue(),
        parent.getBlockStyles(),
        parent.getPaddingStyles(),
        parent.styleName
      );
      replacementNode.setListId(current.listId);
      listParent.append(replacementNode);
      const indexOfCurrentItem = listHandler.root.indexOf(current);
      const id = replacementNode.getKey();

      if (parentIsHeader || $isHeadingNode(anchor)) {
        //TYPE: HEADING (might apply to any styled component)
        //behavior for enter on new item
        const isAtEnd = isAtEndOfCustomListItemNode(selection);
        if (isAtEnd && !isEmpty) {
          return createNewParagraph(prevClause);
        } else {
          //if empty different behavior, so we'll check that first
          if (isEmpty) {
            return createNewParagraph(prevClause);
          } else {
            //is beginning or middle (same behaviour)
            //split to new item
            newClause.append(listParent);
            prevClause.insertAfter(newClause);
            replacementNode.setIndent(parent.getIndent());
            //TODO: FIX THIS CONDITION, IT'S NOT CORRECT
            const newCurrent = createNewPseudoItem(
              indexOfCurrentItem,
              id,
              current
            );
            const anchorTextNode = selection.anchor.getNode();
            if (!anchorTextNode) return PROPAGATE_EVENT; //TODO: analyze if it's desired behavior
            const size = anchorTextNode.getTextContentSize();
            const selectionNode = selection.anchor.getNode();
            const headerParent = anchorTextNode.getParent();
            /** @type {import("lexical").TextNode[]} */
            const splittedText = anchorTextNode.splitText(
              selection.anchor.offset
            );
            if (selection.anchor.offset === 0) {
              anchorTextNode.remove();
              splittedText[0].setFormat(selectionNode.getFormat());
              splittedText[0].setStyle(selectionNode.getStyle());
              const newHeader = $createHeadingNode(headerParent.getTag());
              newHeader.append(splittedText[0]);
              replacementNode.append(newHeader);
            } else {
              anchorTextNode.spliceText(
                selection.anchor.offset,
                size - selection.anchor.offset,
                "",
                true
              );
              let textNodesToAdd;
              if (splittedText.length > 1) {
                splittedText[1].setFormat(selectionNode.getFormat());
                splittedText[1].setStyle(selectionNode.getStyle());
                textNodesToAdd = [
                  splittedText[1],
                  ...(splittedText[1].getNextSiblings() ?? []),
                ];
              } else if (splittedText.length === 1) {
                //end of word
                textNodesToAdd = splittedText[0].getNextSiblings() ?? [];
              }

              /** @type {HeadingNode} */
              const textNodeParentHeader = anchorTextNode.getParent();
              const newHeader = $createHeadingNode(
                textNodeParentHeader.getTag()
              );
              if (textNodesToAdd.length) newHeader.append(...textNodesToAdd);
              replacementNode.append(newHeader);
            }
            updateValuesOnNewItem(
              newCurrent.level,
              replacementNode,
              newCurrent
            );
          }
        }
      } else {
        //TYPE: NORMAL
        const isAtEnd = isAtEndOfCustomListItemNode(selection);
        if (isAtEnd && !isEmpty) {
          newClause.append(listParent);
          prevClause.insertAfter(newClause);
          replacementNode.setIndent(parent.getIndent());
          const newCurrent = createNewPseudoItem(
            indexOfCurrentItem,
            id,
            current
          );

          updateValuesOnNewItem(newCurrent.level, replacementNode, newCurrent);
        } else {
          //if empty different behavior, so we'll check that first
          if (isEmpty) {
            //outdent
            const indent = parent.getIndent();
            if (indent > 0) {
              const level = indent - 1;
              parent.setIndent(level);

              //change
              let previousListVal = current.value;
              updateValuesOnOutdent(previousListVal, level, parent);
              updateStyleName(parent, editor);
              parent.select();
              return PREVENT_EVENT_PROPAGATION;
            }
          } else {
            newClause.append(listParent);
            prevClause.insertAfter(newClause);
            //is beginning or middle (same behaviour)
            //split to new item
            replacementNode.setIndent(parent.getIndent());
            const newCurrent = createNewPseudoItem(
              indexOfCurrentItem,
              id,
              current
            );
            const anchorTextNode = selection.anchor.getNode();
            if (!anchorTextNode) return PROPAGATE_EVENT; //TODO: analyze if it's desired behavior
            const selectionNode = selection.anchor.getNode();
            /** @type {import("lexical").TextNode[]} */
            const splittedText = anchorTextNode.splitText(
              selection.anchor.offset,
              !isCollapsed ? selection.focus.offset : selection.anchor.offset
            ); //if not collapsed returns 3 nodes, before, selected range and continuation of selected range if exists
            if (selection.anchor.offset === 0) {
              // Previous approach.
              // anchorTextNode.remove();
              // splittedText[0].setFormat(selectionNode.getFormat());
              // splittedText[0].setStyle(selectionNode.getStyle());
              // replacementNode.append(splittedText[0]);

              const nodesToCopy = [
                anchorTextNode,
                ...anchorTextNode.getNextSiblings(),
              ];
              replacementNode.append(...nodesToCopy);
            } else {
              // anchorTextNode.spliceText(selection.anchor.offset, 0, "", true);
              let textNodesToAdd;
              if (splittedText.length > 1) {
                if (isCollapsed) {
                  splittedText[1].setFormat(selectionNode.getFormat());
                  splittedText[1].setStyle(selectionNode.getStyle());
                  textNodesToAdd = [
                    splittedText[1],
                    ...(splittedText[1].getNextSiblings() ?? []),
                  ];
                } else {
                  const selectedRange = splittedText[1]; //second node
                  const defaultRedlineData = {
                    partyID: props.partyId,
                    metadata: {
                      creatorId: props.user._id,
                      creatorEmail: props.user.email,
                      creatorDisplayName: props.user.displayName,
                      creatorPhotoUrl: props.user.photoURL,
                      creationDate: new Date().toISOString(),
                      partyId: props.partyId,
                    },
                    // TODO: Double-check if having the latest date every time we create a redline node is the desired behaviour.
                    date: new Date().toISOString(),
                  };
                  const newRedlineNode = $createRedlineNode({
                    ...defaultRedlineData,
                    redlineType: "del",
                    text: selectedRange.getTextContent(),
                  });
                  textNodesToAdd = [
                    newRedlineNode,
                    ...(splittedText[1].getNextSiblings() ?? []),
                  ];
                  const nodesToRemove = splittedText.slice(1);
                  nodesToRemove.forEach((n) => n.remove());
                }
              } else if (splittedText.length === 1) {
                //end of word
                textNodesToAdd = splittedText[0].getNextSiblings() ?? [];
              }
              replacementNode.append(...textNodesToAdd);
            }
            updateValuesOnNewItem(
              newCurrent.level,
              replacementNode,
              newCurrent
            );
          }
        }
      }
      replacementNode.select(0, 0);
    }
    return PREVENT_EVENT_PROPAGATION;
  }, []);

  /**
   *
   * @param {(node: import("lexical").LexicalNode) => void} insertTab
   * @param {(block: import("lexical").ElementNode) => void} indentOrOutdent
   * @returns {void}
   */
  const customHandleIndentAndOutdent = useCallback((indentOrOutdent) => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
      return false;
    }
    const alreadyHandled = new Set();
    const nodes = selection.getNodes();
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      const key = node.getKey();
      if (alreadyHandled.has(key)) {
        continue;
      }
      let parentBlock = $getNearestBlockElementAncestorOrThrow(node);
      if (
        parentBlock &&
        $isElementNode(parentBlock) &&
        !$isCustomListItemNode(parentBlock)
      ) {
        const grandparent = parentBlock.getParent();
        if ($isCustomListItemNode(grandparent)) {
          parentBlock = grandparent;
        }
      }
      const parentKey = parentBlock.getKey();
      if (parentBlock.canIndent() && !alreadyHandled.has(parentKey)) {
        alreadyHandled.add(parentKey);
        indentOrOutdent(parentBlock);
      }
    }
    return alreadyHandled.size > 0;
  }, []);

  /** Finds siblings at the same level.
   * - Returns empty array if no siblings or array with siblings if there any.
   */
  const findNextSiblingsAtLevel = useCallback(
    /**
     * @param {import("../../converters/utils/lists").ListItemInfo[]} currentListItems
     * @param {number} currentIndex
     * @param {number} level
     *
     * @returns {import("../../converters/utils/lists").ListItemInfo[]}
     */
    (currentListItems, currentIndex, level) => {
      const targetArr = currentListItems.slice(currentIndex);
      if (!targetArr.length) return [];
      let currentItem = targetArr[0],
        result = [],
        index = 0;
      while (currentItem?.level <= level) {
        if (currentItem.level === level) {
          result.push(currentItem);
        }
        currentItem = currentListItems[++index];
      }
      return result;
    },
    []
  );

  /**
   * Updates values of list items according to a indent action
   *
   * @param {number} previousListVal
   * @param {number} level
   * @param {CustomListItemNode} listItemNode
   */
  const updateValuesOnIndent = (previousListVal, level, listItemNode) => {
    const listHandler = GlobalListHandler.getInstance();
    /** @type {import("../../converters/utils/lists").ListItemInfo} */
    let lastItemInLevel;
    /** @type {import("../../converters/utils/lists").ListItemInfo[]} */
    const currentListItems = listHandler.root.filter(
        (it) => it.listId === listItemNode.getListId()
      ), //need to filter only this listid
      currentItem = currentListItems.filter(
        (li) => li.id === listItemNode.getKey()
      )[0],
      currentIndex = currentListItems.indexOf(currentItem),
      nextSiblings = [],
      hasNoPredecessor =
        currentListItems[currentIndex - 1]?.level < currentItem.level,
      hasNoSuccessor = currentListItems[currentIndex + 1]
        ? currentListItems[currentIndex + 1]?.level < currentItem.level
        : true,
      searchArray = hasNoPredecessor
        ? [currentItem]
        : currentListItems.slice(0, currentIndex);

    if (!hasNoPredecessor) {
      const reversedArray = searchArray
        .filter((el) => el.listId === currentItem.listId)
        .reverse();
      for (const el of reversedArray) {
        if (el.level === level) {
          lastItemInLevel = el;
          break;
        } else if (el.level < level) {
          break;
        }
      }
    }

    //these are flags that represent the destination conditions of the block
    //if is at the end when indented, or beginning or middle
    const isAtEnd = hasNoSuccessor
        ? true
        : currentListItems[currentIndex + 1]
        ? currentListItems[currentIndex + 1].level < level
        : false,
      isAtBeginning = hasNoPredecessor
        ? true
        : currentListItems[currentIndex - 1]
        ? currentListItems[currentIndex - 1].level < level
        : false,
      isAtMiddle =
        (hasNoPredecessor // beginning of list
          ? false
          : currentListItems[currentIndex - 1]) &&
        (hasNoSuccessor // end of list
          ? false
          : !!findNextSiblingsAtLevel(currentListItems, currentIndex, level)
              .length);
    let nextVal = 0;
    if (lastItemInLevel) {
      if (isAtEnd || isAtMiddle) {
        nextVal = lastItemInLevel.value + 1;
        //set value to list item
        listItemNode.setValue(nextVal);
      } else if (isAtBeginning) {
        nextVal = lastItemInLevel.value;
        //set value to list item
        listItemNode.setValue(nextVal);
      }
      //update current item
      currentItem.value = listItemNode.getValue();
      currentItem.level = level;
      currentItem.parentId = lastItemInLevel.parentId;
    } else {
      //new list
      nextVal = 1;
      //set value to list item
      listItemNode.setValue(nextVal);
      //update current item
      currentItem.value = listItemNode.getValue();
      currentItem.level = level;
      currentItem.parentId = currentListItems[currentIndex - 1]
        ? currentListItems[currentIndex - 1].id
        : "";
    }

    //update remaining items
    const siblings = currentListItems.slice(currentIndex + 1);

    const previousListSiblings = [];

    let shouldUpdateNextList = true;
    for (const sibling of siblings) {
      const node = $getNodeByKey(sibling.id);
      if (sibling.listId !== currentItem.listId) continue;
      if (sibling.level === level && shouldUpdateNextList) {
        nextSiblings.push(node);
      } else if (sibling.level === level - 1) {
        //if we don't do this we update other lists in the same level but at a different parent wrongly
        if (shouldUpdateNextList) shouldUpdateNextList = false;
        previousListSiblings.push(node);
      } else if (sibling.level < level) {
        break;
      }
    }
    //PREVIOUS LIST SIBLINGS UPDATE
    previousListSiblings.forEach((sib) => {
      const sibItem = currentListItems.find((it) => it.id === sib.getKey());
      sib.setValue(previousListVal++);
      //update structure
      sibItem.value = sib.getValue();
    });
    //NEW LIST SIBLINGS UPDATE
    nextSiblings.forEach((sib) => {
      const sibItem = currentListItems.find((it) => it.id === sib.getKey());
      sib.setValue(++nextVal);
      //update structure
      sibItem.value = sib.getValue();
    });
    //force item update visual
    siblings.forEach((sib) => {
      if (sib.listId === currentItem.listId) {
        const it = $getNodeByKey(sib.id);
        it.setValue(it.getValue());
      }
    });
  };

  /**
   * Updates values of list items according to a outdent action
   *
   * @param {number} previousListVal
   * @param {number} level
   * @param {CustomListItemNode} listItemNode
   */
  const updateValuesOnOutdent = (previousListVal, level, listItemNode) => {
    const listHandler = GlobalListHandler.getInstance();

    //OUTDENT
    let shouldUpdateNextList = true,
      nextVal = 1,
      /** @type {import("../../converters/utils/lists").ListItemInfo} */
      shouldRestart,
      /** @type {import("../../converters/utils/lists").ListItemInfo} */
      lastItemInLevel;
    const currentListItems = listHandler.root, //need to filter only this listid
      currentItem = currentListItems.filter(
        (li) => li.id === listItemNode.getKey()
      )[0],
      currentIndex = currentListItems.indexOf(currentItem),
      nextSiblings = [],
      previousListSiblings = [];
    const reversedArray = currentListItems
      .slice(0, currentListItems.indexOf(currentItem))
      .filter((el) => el.listId === currentItem.listId)
      .reverse();
    for (const el of reversedArray) {
      if (el.level < level) {
        shouldRestart = el;
        break;
      } else if (el.level === level) {
        lastItemInLevel = el;
        break;
      }
    }
    if (!shouldRestart) {
      if (lastItemInLevel) {
        nextVal = lastItemInLevel.value + 1;
      }

      //set value to list item
      listItemNode.setValue(nextVal);
      //update structure
      currentItem.value = listItemNode.getValue();
      currentItem.level = level;
      currentItem.parentId = !lastItemInLevel ? "" : lastItemInLevel.parentId;
    } else if (lastItemInLevel) {
      //this is likely to be faulty
      //should restart
      //set value to list item
      listItemNode.setValue(1);
      //update structure
      currentItem.value = listItemNode.getValue();
      currentItem.level = level;
      currentItem.parentId = lastItemInLevel.parentId;
    } else {
      //this is likely to be faulty
      //should restart
      //set value to list item
      listItemNode.setValue(1);
      //update structure
      currentItem.value = listItemNode.getValue();
      currentItem.level = level;
      currentItem.parentId = shouldRestart.id;
    }

    //update remaining items,
    const siblings = currentListItems.slice(currentIndex + 1);
    // level = -1, level + 1 = previousLevel
    for (const sibling of siblings) {
      const node = $getNodeByKey(sibling.id);
      if (sibling.listId !== currentItem.listId) continue;
      if (sibling?.level === level) {
        //next level
        nextSiblings.push(node);
      } else if (sibling?.level === level + 1 && shouldUpdateNextList) {
        if (shouldUpdateNextList) shouldUpdateNextList = false;
        // previousLevel
        previousListSiblings.push(node);
      } else if (sibling?.level === level - 1) {
        //-2
        break;
      }
    }
    //update siblings
    nextSiblings.forEach((sib) => {
      const sibItem = currentListItems.find((it) => it.id === sib.getKey());
      sib.setValue(++nextVal);
      //update structure
      sibItem.value = sib.getValue();
    });
    //PREVIOUS LIST SIBLINGS UPDATE
    previousListSiblings.forEach((sib) => {
      const sibItem = currentListItems.find((it) => it.id === sib.getKey());
      sib.setValue(previousListVal++);
      //update structure
      sibItem.value = sib.getValue();
    });
    //force item update visual
    siblings.forEach((sib) => {
      if (sib.listId === currentItem.listId) {
        const it = $getNodeByKey(sib.id);
        it.setValue(it.getValue());
      }
    });
  };

  /**
   * Updates values of list items according to a outdent action
   *
   * @param {number} level
   * @param {CustomListItemNode} listItemNode
   * @param {import("./types").PseudoListItem} currentItem
   */
  const updateValuesOnNewItem = (level, listItemNode, currentItem) => {
    const listHandler = GlobalListHandler.getInstance();
    /** @type {import("../../converters/utils/lists").ListItemInfo} */
    let lastItemInLevel;
    /** @type {import("../../converters/utils/lists").ListItemInfo[]} */
    const currentListItems = listHandler.root.filter(
        (it) => it.listId === currentItem.listId
      ), //need to filter only this listid
      currentIndex = currentListItems.indexOf(currentItem),
      nextSiblings = [],
      hasNoPredecessor = currentIndex === 0,
      searchArray = hasNoPredecessor
        ? [currentItem]
        : currentListItems.slice(0, currentIndex);

    if (!hasNoPredecessor) {
      const reversedArray = searchArray
        .filter((el) => el.listId === currentItem.listId)
        .reverse();
      for (const el of reversedArray) {
        if (el.level === level) {
          lastItemInLevel = el;
          break;
        } else if (el.level < level) {
          break;
        }
      }
    } else {
      /** being the first item in this list means the last one before it, is it itself */
      lastItemInLevel = currentItem;
    }

    let nextVal = 0;
    if (lastItemInLevel) {
      nextVal = lastItemInLevel.value + 1;
      //set value to list item
      listItemNode.setValue(nextVal);
      //update current item
      currentItem.value = listItemNode.getValue();
      currentItem.level = level;
      currentItem.parentId = lastItemInLevel.parentId;
    } else {
      //is first item of list
      //new list
      nextVal = 1;
      //set value to list item
      listItemNode.setValue(nextVal);
      //update current item
      currentItem.value = listItemNode.getValue();
      currentItem.level = level;
      currentItem.parentId = currentListItems[currentIndex - 1].parentId;
    }

    //update remaining items
    const siblings = currentListItems.slice(currentIndex + 1); // exlude current item from another update
    const nextLevelListSiblings = [];
    let shouldUpdateNextList = true;

    for (const sibling of siblings) {
      const node = $getNodeByKey(sibling.id);
      if (sibling?.level === level && shouldUpdateNextList) {
        nextSiblings.push(node);
      } else if (sibling?.level > level) {
        nextLevelListSiblings.push(node);
        shouldUpdateNextList = false;
      } else {
        break;
      }
    }

    //NEW LIST SIBLINGS UPDATE
    nextSiblings.forEach((sib) => {
      const sibItem = currentListItems.find((it) => it.id === sib.getKey());
      sib.setValue(++nextVal);
      //update structure
      sibItem.value = sib.getValue();
    });
    //make all sibling nodes update
    nextLevelListSiblings.forEach((sib) => {
      const sibItem = currentListItems.find((it) => it.id === sib.getKey());
      sib.setValue(sibItem.value);
    });
    //force item update visual
    siblings.forEach((sib) => {
      if (sib.listId === currentItem.listId) {
        const it = $getNodeByKey(sib.id);
        it.setValue(it.getValue());
      }
    });
  };
  return {
    customHandleListInsertParagraph,
    customHandleIndentAndOutdent,
    findNextSiblingsAtLevel,
    updateValuesOnIndent,
    updateValuesOnOutdent,
    updateValuesOnNewItem,
  };
};
export default useExtendedLists;
