import * as React from "react";
import theme from "../theme/theme";
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { TooltipProminent } from ".";
import { trunc } from "../utils";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/pro-solid-svg-icons";

export default function CoreDrawerDefinitions(props) {
  const DefinitionRow = ({ index, style }) => (
    <TooltipProminent title={props.definitions[index].text} placement="right">
      <ListItemButton
        key={index}
        style={style}
        onClick={(e) =>
          props.definitions[index].element.scrollIntoView({
            block: "center",
            behavior: "smooth",
          })
        }
      >
        <ListItemIcon>
          <FontAwesomeIcon icon={faQuoteLeft} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography
              style={{
                paddingLeft: "0px",
                fontSize: "16px",
                color: theme.palette.grey[900],
                fontWeight: "600",
              }}
            >
              {trunc(props.definitions[index].item, 22)}
            </Typography>
          }
        />
      </ListItemButton>
    </TooltipProminent>
  );

  //props.toc[index].level === 1 ? 48 : props.toc[index].level === 2 ? 43 : [3,4].includes(props.toc[index].level) ? 32 : 0
  function getDefinitionItemSize(index) {
    return 48;
  }

  return (
    <Box
      sx={{ width: "100%", height: "100%", maxWidth: 240, bgcolor: "#ffffff" }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <VariableSizeList
            height={height}
            itemCount={
              props.definitions === undefined || props.definitions === null
                ? 0
                : props.definitions.length
            }
            itemSize={getDefinitionItemSize}
            width={width}
          >
            {DefinitionRow}
          </VariableSizeList>
        )}
      </AutoSizer>
    </Box>
  );
}
