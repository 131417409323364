import { $isClauseNode } from "../nodes/ClauseNode";

/**
 * @param {*} startingNode
 * @returns {import("../nodes").ClauseNode | null}
 */
export function getClauseNode(startingNode) {
  if ($isClauseNode(startingNode)) {
    return startingNode;
  }

  if (startingNode.getParent()) {
    // Climb up the ancestors recursively until we find a Clause node.
    return getClauseNode(startingNode.getParent());
  }

  return null;
}

// Previous implementation.
// export function getClauseNode(node) {
//   let n = null;
//   if (node.getType() === "root") {
//     n = null;
//   } else if (node.getType() === "clause") {
//     n = node;
//   } else if (node.getParent()) {
//     n = getClauseNode(node.getParent());
//   }
//   return n;
// }
