import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React from "react";

/**
 * @typedef {object} VisibilityStepProps
 * @property {string} documentId
 * @property {ClauseVariant} clauseVariant
 * @property {React.Dispatch<React.SetStateAction<ClauseVariant>>} setClauseVariant
 */

const visibilities = [
  { value: "visible", label: "Visible" },
  { value: "notVisible", label: "Not Visible" },
];

/**
 * @param {VisibilityStepProps} props
 * @returns {JSX.Element}
 */
export default function VisibilityStep({ clauseVariant, setClauseVariant }) {
  return (
    <>
      <Grid container>
        <Typography fontWeight="bold">
          Should this variant be visible to counterparties, so they can choose
          it as an option?
        </Typography>
      </Grid>

      <Grid container mt={4}>
        <Autocomplete
          fullWidth
          options={visibilities}
          getOptionLabel={(option) => option.label}
          value={visibilities.find(
            (visibility) => visibility.value === clauseVariant.visibility
          )}
          onChange={(_event, item) => {
            setClauseVariant((previous) => ({
              ...previous,
              visibility: item.value,
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Visibility"
              placeholder="Select Visibility ..."
            />
          )}
          disableClearable
        />
      </Grid>
    </>
  );
}
