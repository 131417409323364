/**
 * @param {import("../../../components/MergeFieldMenu/dialogs/NewConditionDialog/condition").Condition} condition
 */
export const isGreaterThanOrEqual = ({
  baseValue,
  comparisonValue,
  mergeFieldValue,
}) => {
  switch (baseValue.value.type) {
    case "duration": {
      if (
        comparisonValue instanceof Array ||
        comparisonValue.type !== baseValue.value.type
      ) {
        throw new Error(
          `Comparison value needs to be of type '${baseValue.value.type}'.`
        );
      }

      const baseValueNumber = Number(baseValue.value.durationValue);
      if (isNaN(baseValueNumber)) {
        throw new Error("Invalid base value number.");
      }

      const comparisonValueNumber = Number(comparisonValue.durationValue);
      if (isNaN(comparisonValueNumber)) {
        throw new Error("Invalid comparison value number.");
      }

      if (baseValueNumber >= comparisonValueNumber) {
        return mergeFieldValue;
      }
      break;
    }

    case "currency": {
      if (
        comparisonValue instanceof Array ||
        comparisonValue.type !== baseValue.value.type
      ) {
        throw new Error(
          `Comparison value needs to be of type '${baseValue.value.type}'.`
        );
      }

      const baseValueNumber = Number(baseValue.value.currencyValue);
      if (isNaN(baseValueNumber)) {
        throw new Error("Invalid base value number.");
      }

      const comparisonValueNumber = Number(comparisonValue.currencyValue);
      if (isNaN(comparisonValueNumber)) {
        throw new Error("Invalid comparison value number.");
      }

      if (baseValueNumber >= comparisonValueNumber) {
        return mergeFieldValue;
      }
      break;
    }

    case "number":
    case "percentage": {
      if (
        comparisonValue instanceof Array ||
        comparisonValue.type !== baseValue.value.type
      ) {
        throw new Error(
          `Comparison value needs to be of type '${baseValue.value.type}'.`
        );
      }

      const baseValueNumber = Number(baseValue.value.value);
      if (isNaN(baseValueNumber)) {
        throw new Error("Invalid base value number.");
      }

      const comparisonValueNumber = Number(comparisonValue.value);
      if (isNaN(comparisonValueNumber)) {
        throw new Error("Invalid comparison value number.");
      }

      if (baseValueNumber >= comparisonValueNumber) {
        return mergeFieldValue;
      }
      break;
    }

    default:
      throw new Error(
        `Base value type '${baseValue.value.type}' is not a valid type for operator 'isGreaterThanOrEqual'.`
      );
  }
};
