const orgReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_ORG": {
      let newVal = action.payload;
      return { org: newVal };
    }
    default:
      return { org: state.org };
  }
};

export default orgReducer;
