import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";

/**
 * @typedef {object} DialogAddNewMergeFieldQuestionProps
 * @property {boolean} open
 * @property {() => void} close
 */

/**
 * @param {DialogAddNewMergeFieldQuestionProps} props
 */
export default function DialogAddNewQuestionToQuestionnaire({ open, close }) {
  return (
    <Dialog open={open}>
      <DialogTitle>Add New Merge Field Question</DialogTitle>

      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogContent>
        <Box>
          To add a new Merge Field, please select text in the agreement and
          click "Add Merge Field".
        </Box>
      </DialogContent>
    </Dialog>
  );
}
