import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faArrowLeft,
  faArrowRight,
  faBuildings,
  faSignature,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAgreementData from "../../hooks/useAgreementData";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import { getCanveoTier } from "../../utils/getCanveoTier";
import { CanveoCircularProgress } from "../index";
import CreateUserSection from "./SendComponents/CreateUserSection";
import PartyManagementSection from "./SendComponents/PartyManagementSection";
import SelectSignerSection from "./SendComponents/SelectSignerSection";
import SignersListSection from "./SendComponents/SignersListSection";
import UserManagementSection from "./SendComponents/UserManagementSection";

const DialogSendSigning = ({ open, handleCloseGroup, agreement }) => {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [userRole] = useState(state.user.role?.name);
  const navigate = useNavigate();
  const [partyManagement, setPartyManagement] = useState(false);
  const [userManagement, setUserManagement] = useState(false);
  const [editUser, setEditUser] = useState(null);

  const [closing, setClosing] = useState(false);

  const {
    loading,
    errorMsg,
    allUsers,
    signers,
    addSigner,
    changeSigners,
    oidsForWhichYouCanAddUser,
    signingOrder,
    handleSigningOrderChange,
    selectedOrganizationID,
    setSelectedOrganizationID,
    userCreationType,
    closeUserSections,
    setSignerCreation,
    handleSubmitUserForm,
    canSign,
    isOwner,
    updateAgreement,
    sendUserInvitations,
    sendAgreement,
    reminders,
    setReminders,
    collaborators,
    handleRevokeUser,
    handlePartyChange,
    handleEditUserForm,
    agreementUpdate,
    attachPdfFileToEmailType,
    setAttachPdfFileToEmailType,
    comparisonVersion,
    setComparisonVersion,
    roles,
    setRoles,
    setAgreementUpdate,
  } = useAgreementData(agreement, open, true);

  useEffect(() => {
    dispatch({ type: "RESET_SEND_FLOW_STATE" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initiateClosing = () => {
    if (closing || !agreementUpdate) return closeDialog();

    setClosing(true);
  };

  const closeDialog = () => {
    handleCloseGroup();
  };

  const handleConfirmationClose = () => {
    sendUserInvitations();
    updateAgreement();
    if (errorMsg) return;

    closeDialog();
  };

  const handleBack = () => {
    if (closing) return setClosing(false);
    if (!!userCreationType || !!selectedOrganizationID) {
      return closeUserSections();
    }
    if (editUser) return setEditUser(null);
    if (state.sendFlow?.addParty) {
      return dispatch({ type: "SEND_FLOW_ADD_PARTY", payload: false });
    }
    if (state.sendFlow?.removeParty) {
      return dispatch({ type: "SEND_FLOW_REMOVE_PARTY", payload: null });
    }
    if (state.sendFlow?.removeUser) {
      return dispatch({ type: "SEND_FLOW_REMOVE_USER", payload: null });
    }
    if (partyManagement) return setPartyManagement(false);
    if (userManagement) return setUserManagement(false);

    initiateClosing();
  };

  const getSelectedUsers = () => {
    return allUsers.filter((au) => signers.some((s) => s._id === au._id));
  };

  /**
   * Sign directly flow.
   */
  const handleSend = () => {
    sendAgreement({ directSign: true });
    if (errorMsg) return;

    if (agreement.sigConfig?.provider === "docusign") {
      handleCloseGroup("preparingYourDocumentForSignatureSetup");
    } else {
      navigate("/dashboard/" + (userRole === "Counterparty" ? "0" : "1"));
    }
  };

  const getDialogTitle = () => {
    if (partyManagement) return "Manage Parties";
    if (userManagement) return "Manage Collaborators";

    if (userCreationType) return `Create Signer`;

    return "Add Signers";
  };

  const handleConfirm = () => {
    if (state.sendFlow?.removeParty) {
      return dispatch({ type: "SEND_FLOW_SUBMIT_REMOVE_PARTY" });
    }

    if (state.sendFlow?.removeUser) {
      return dispatch({ type: "SEND_FLOW_SUBMIT_REMOVE_USER" });
    }
  };

  const handleUserFormSubmission = async (formData) => {
    if (userCreationType) return handleSubmitUserForm(formData);
    if (editUser) {
      const result = await handleEditUserForm({
        ...editUser,
        ...formData,
        displayName: `${formData.firstName} ${formData.lastName}`,
      });
      if (result) setEditUser(null);
    }
  };

  // const [attachPdfFileToEmail, setAttachPdfFileToEmail] = useState(false);
  // const [attachPdfFileToEmailType, setAttachPdfFileToEmailType] =
  //   useState("comparison");
  // const [comparisonVersion, setComparisonVersion] = useState(undefined);
  // Ignore the first version because that's the one we are currently seeing.
  const [, ...versionsOptions] = state.drawerVersions.versions.map(
    (/** @type {*} */ version) => ({
      label: `Version ${version.version}`,
      author: version.creationBy.displayName,
      description: version.description,
      lastUpdated: `${DateTime.fromISO(version.lastUpdateDate).toFormat(
        "dd LLL yyyy"
      )} by ${version.lastUpdateBy.displayName}`,
      version,
    })
  );

  return (
    <Dialog open={open} onClose={initiateClosing} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={initiateClosing}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>
      <DialogTitle>Initiating signing</DialogTitle>
      <DialogContent>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 12,
            }}
          >
            <CanveoCircularProgress />
          </Box>
        )}
        {!loading && (
          <Box sx={{ my: 2 }}>
            <Grid
              container
              direction="column"
              justifyContent={"center"}
              alignItems="center"
              sx={{ minHeight: !closing ? "360px" : "unset" }}
            >
              {!closing && (
                <Grid
                  item
                  sx={{
                    textAlign: "center",
                    mb: !!state.parties && state.parties.length > 2 ? 3 : 4,
                  }}
                >
                  <Typography variant="h4">{getDialogTitle()}</Typography>

                  {errorMsg && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 3,
                      }}
                    >
                      <Typography align="center" color="error">
                        {errorMsg}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              )}

              <Grid item sx={{ mb: 0, px: 1, width: "100%" }}>
                {closing ? (
                  <Box>
                    <Typography
                      sx={{ fontWeight: "600", mb: 2 }}
                      align="center"
                    >
                      Do you want to persist the changes made?
                    </Typography>
                    <Typography sx={{ fontWeight: "600" }} align="center">
                      If you added new collaborators, they will receive email
                      notifications once you send the agreement.
                    </Typography>
                  </Box>
                ) : userCreationType || editUser ? (
                  <CreateUserSection
                    handleSubmit={handleUserFormSubmission}
                    user={editUser}
                  />
                ) : selectedOrganizationID ? (
                  <SelectSignerSection
                    allowedOrganizations={oidsForWhichYouCanAddUser}
                    orgID={selectedOrganizationID}
                    users={allUsers.filter(
                      (au) => au.orgID === selectedOrganizationID
                    )}
                    owner={agreement.owner}
                    hiddenUsers={getSelectedUsers()}
                    handleCreateUser={setSignerCreation}
                    handleSelection={(signer) => {
                      addSigner(signer);
                      closeUserSections();
                    }}
                  />
                ) : partyManagement ? (
                  <PartyManagementSection
                    roles={roles}
                    setRoles={setRoles}
                    owner={agreement.owner}
                    handlePartyChange={handlePartyChange}
                    setAgreementUpdate={setAgreementUpdate}
                  />
                ) : userManagement ? (
                  <UserManagementSection
                    collaborators={collaborators}
                    signers={signers}
                    owner={agreement.owner}
                    handleEditUserForm={handleEditUserForm}
                    handleRevokeUser={handleRevokeUser}
                  />
                ) : (
                  <>
                    <SignersListSection
                      signConfig={agreement.sigConfig}
                      signers={signers}
                      signingOrder={signingOrder}
                      reminders={reminders}
                      isOwner={agreement.owner === state.org._id}
                      owner={agreement.owner}
                      handleSignersChange={changeSigners}
                      handleOrganizationSelection={(orgID) =>
                        setSelectedOrganizationID(orgID)
                      }
                      handleChangeReminder={(reminder) =>
                        setReminders(reminder)
                      }
                      handleSigningOrderChange={handleSigningOrderChange}
                      handleEditUser={(user) => setEditUser(user)}
                    />

                    {getCanveoTier(state?.user?.email) === "experimental" && (
                      <Grid container>
                        <Grid item>
                          <Grid pl={1}>
                            <RadioGroup
                              value={attachPdfFileToEmailType}
                              onChange={(event) =>
                                setAttachPdfFileToEmailType(event.target.value)
                              }
                            >
                              <FormControlLabel
                                value="clean"
                                control={<Radio />}
                                label="Sign clean PDF"
                              />

                              <FormControlLabel
                                value="comparison"
                                control={<Radio />}
                                label="Sign comparison vs. earlier version ..."
                              />

                              {attachPdfFileToEmailType === "comparison" && (
                                <>
                                  <Grid mt={1}>
                                    <Autocomplete
                                      value={comparisonVersion}
                                      onChange={(_event, value) =>
                                        setComparisonVersion(value)
                                      }
                                      disablePortal
                                      options={versionsOptions}
                                      sx={{ width: 300 }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Second Document for Comparison"
                                          placeholder="Select second document ..."
                                        />
                                      )}
                                      renderOption={(props, option) => (
                                        <Box
                                          component="li"
                                          sx={{
                                            "& > img": {
                                              mr: 2,
                                              flexShrink: 0,
                                            },
                                          }}
                                          {...props}
                                        >
                                          <Grid container direction="column">
                                            <Grid item>
                                              <Typography
                                                variant="body1"
                                                fontWeight="bolder"
                                                color={theme.palette.grey[800]}
                                              >
                                                {option.label}
                                              </Typography>
                                            </Grid>

                                            <Grid item>
                                              <Typography
                                                variant="subtitle2"
                                                color={theme.palette.grey[500]}
                                              >
                                                Author: {option.author}
                                              </Typography>
                                            </Grid>

                                            <Grid item>
                                              <Typography
                                                variant="subtitle2"
                                                color={theme.palette.grey[500]}
                                              >
                                                Description:{" "}
                                                {option.description}
                                              </Typography>
                                            </Grid>

                                            <Grid item>
                                              <Typography
                                                variant="subtitle2"
                                                color={theme.palette.grey[500]}
                                              >
                                                Last updated:{" "}
                                                {option.lastUpdated}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      )}
                                    />
                                  </Grid>
                                </>
                              )}
                            </RadioGroup>
                          </Grid>

                          {/* <Grid
                              pl={3}
                              mt={1}
                              onClick={() => {
                                // TODO: Add preview download.
                              }}
                            >
                              <Button>Preview ...</Button>
                            </Grid> */}
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {!closing && (
          <Button sx={{ marginRight: "auto" }} onClick={handleBack}>
            <FontAwesomeIcon icon={faArrowLeft} />
            &nbsp;&nbsp;Back
          </Button>
        )}
        {!userCreationType &&
          !userManagement &&
          !partyManagement &&
          !editUser &&
          !closing && (
            <Button disabled={loading} onClick={() => setUserManagement(true)}>
              <FontAwesomeIcon icon={faUser} />
              &nbsp;&nbsp;Manage Collaborators
            </Button>
          )}
        {!userCreationType &&
          !partyManagement &&
          !userManagement &&
          !editUser &&
          !closing && (
            <Button disabled={loading} onClick={() => setPartyManagement(true)}>
              <FontAwesomeIcon icon={faBuildings} />
              &nbsp;&nbsp;Manage Parties
            </Button>
          )}
        {state.sendFlow?.addParty && !closing && (
          <Button
            variant="contained"
            disableElevation
            style={{ marginLeft: "12px" }}
            disabled={
              !(
                state.sendFlow?.addParty.organization &&
                state.sendFlow?.addParty.entity &&
                state.sendFlow?.addParty.role
              )
            }
            onClick={() => dispatch({ type: "SEND_FLOW_SUBMIT_ADD_PARTY" })}
          >
            Add&nbsp;&nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        )}

        {(state.sendFlow?.removeParty || state.sendFlow?.removeUser) &&
          !closing && (
            <Button
              variant="contained"
              disableElevation
              style={{ marginLeft: "12px" }}
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          )}
        {!state.sendFlow?.addParty &&
          !state.sendFlow?.removeParty &&
          !state.sendFlow?.removeUser &&
          !closing && (
            <Button
              variant="contained"
              disableElevation
              style={{ marginLeft: "12px" }}
              disabled={
                loading ||
                userCreationType ||
                !(canSign || isOwner) ||
                partyManagement ||
                userManagement ||
                !!editUser ||
                !signers.length
              }
              onClick={handleSend}
            >
              Initiate Signing&nbsp;&nbsp;
              <FontAwesomeIcon icon={faSignature} />
            </Button>
          )}

        {closing && (
          <>
            <Button sx={{ marginRight: "auto" }} onClick={handleBack}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disableElevation
              style={{ marginLeft: "12px" }}
              onClick={handleConfirmationClose}
            >
              Confirm
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogSendSigning;
