import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $dfs } from "@lexical/utils";
import axios from "axios";
import { $getSelection, $isRangeSelection } from "lexical";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { OpenIssueMenu } from "../../..";
import { globalStore } from "../../../../state/store";
import { $isMarkNode } from "../../nodes/MarkNode";
import { RedlineNode } from "../../nodes/RedlineNode.js";
import { $getOpenIssuesList } from "../OpenIssuesPlugin";
import { useOpenIssueMenu } from "./useOpenIssueMenu";
import { useRedline } from "./useRedline";

/**
 * @typedef {object} TrackChangesPluginProps
 * @property {PartyId} partyId
 * @property {PartialUser} user
 * @property {string} docID
 * @property {boolean} isTemplate
 * @property {boolean} isEditable
 * @property {*} agreementVersion
 * @property {boolean} isAgreementOwner
 */

/**
 * @param {TrackChangesPluginProps} props
 * @returns {JSX.Element}
 */
export default function TrackChangesPlugin({
  partyId,
  user,
  isTemplate,
  docID,
  isEditable = false,
  agreementVersion,
  isAgreementOwner,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [editor] = useLexicalComposerContext();
  if (!editor.hasNodes([RedlineNode])) {
    throw new Error(
      "TrackChangesPlugin: RedlineNode not registered in editor."
    );
  }

  useRedline(editor, partyId, user, isEditable);

  const [showOpenIssueMenu, setShowOpenIssueMenu] = useState(false);

  /** @type {import("../OpenIssuesPlugin").OpenIssue[]} */
  const defaultOpenIssues = [];
  const [openIssues, setOpenIssues] = useState(defaultOpenIssues);

  // TODO: There may be a better way to react to changes other than depending on the editor state
  // since this seems to change on selection change.
  const editorState = editor.getEditorState();

  useEffect(() => {
    editorState.read(() => {
      const openIssues = $getOpenIssuesList(
        editor,
        state.workflows,
        state.org._id
      );
      setOpenIssues(openIssues);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, editorState, state.workflows]);

  useOpenIssueMenu(editor, setShowOpenIssueMenu, openIssues, state, dispatch);

  // This hook is necessary so that we can display the menu if a user clicks on an icon
  // before clicking on the text when first entering the editor.
  useEffect(() => {
    if (state.selectedOpenIssue.id) {
      setShowOpenIssueMenu(true);
    }
  }, [state.selectedOpenIssue.id]);

  const handleDelete = useCallback(
    async (/** @type {string} */ id) => {
      let existingInstancesOfMergeField = 0;

      editor.update(
        () => {
          const dfs = $dfs();
          for (const { node } of dfs) {
            if (
              $isMarkNode(node) &&
              ["mergeField", "both"].includes(node.getMarkType())
            ) {
              const mergeField = node.getMergeField();
              if (mergeField && mergeField._id === id) {
                existingInstancesOfMergeField++;
              }
            }
          }

          const selection = $getSelection();
          if (!$isRangeSelection(selection)) return;

          const parents = selection.anchor.getNode().getParents();
          for (const parent of parents) {
            if ($isMarkNode(parent) && parent.getMarkType() === "mergeField") {
              const children = parent.getChildren();
              parent.remove();

              selection.insertNodes(children);

              dispatch({
                type: "NEW_OPEN_ISSUE_SELECTION",
                payload: { id: null, type: null, status: null },
              });

              return;
            } else if (parent.getMarkType() === "both") {
              const mergeFieldId = parent.getMergeField()?.editorMarkNodeId;
              if (mergeFieldId) {
                parent.deleteID(mergeFieldId);
                dispatch({
                  type: "NEW_OPEN_ISSUE_SELECTION",
                  payload: {
                    id: null,
                    type: null,
                    status: null,
                  },
                });
              } else {
                throw new Error("Unable to recognize merge field.");
              }

              return;
            }
          }
        },
        {
          onUpdate: async () => {
            // If we are removing the last instance of a merge field present on a document,
            // we also remove it from the database, otherwise it will show up in the agreement
            // creation process.
            // TODO: Take exhibits' merge fields into account.
            if (existingInstancesOfMergeField === 1) {
              await axios
                .delete(state.settings.api + `mergefield/${id}`)
                .catch((error) => {
                  console.error(error);
                  throw new Error("Error deleting Merge Field.");
                });
            }
          },
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, editor, state.settings.api]
  );

  useEffect(() => {
    if (state?.selectedMergeField?.event === "DELETED_MERGE_FIELD") {
      delete state.selectedMergeField.event;

      if (!state?.selectedMergeField?._id) {
        throw new Error("Merge Field ID is required for delete.");
      }
      handleDelete(state.selectedMergeField._id);
    }
  }, [state.selectedMergeField, handleDelete]);

  return createPortal(
    <OpenIssueMenu
      selectedOpenIssue={state.selectedOpenIssue}
      partyId={partyId}
      showOpenIssueMenu={showOpenIssueMenu}
      isTemplate={isTemplate}
      docID={docID}
      deleteMergeField={handleDelete}
      agreementVersion={agreementVersion}
      isAgreementOwner={isAgreementOwner}
    />,
    document.body
  );
}
