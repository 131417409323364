import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  createFilterOptions,
} from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import React, { useCallback, useContext, useEffect } from "react";
import { globalStore } from "../../../../../../state/store";
import {
  currencyCodes,
  durationUnits,
} from "../../../../../MergeFieldMenu/constants";
import { operators } from "../../../../../MergeFieldMenu/dialogs/NewConditionDialog/constants";
import {
  getMergeFieldDefaultValue,
  getMergeFieldDisplayValue,
  mergeFieldValueIsValid,
} from "../../../../../MergeFieldMenu/utils";

const filter = createFilterOptions();

/**
 * @typedef {object} MergeFieldBaseValueTypeProps
 * @property {string} docId
 * @property {boolean} isList
 * @property {import("../../../constants").ListOption[]} listOptions,
 * @property {import("../condition").Condition | null} condition
 * @property {import("../../../../editor/nodes/MarkNode").MergeField[]} mergeFields
 * @property {React.Dispatch<React.SetStateAction<import("../../../../editor/nodes/MarkNode").MergeField[]>>} setMergeFields
 * @property {import("../../../../editor/nodes/MarkNode").MergeField | null} baseValue
 * @property {React.Dispatch<React.SetStateAction<import("../../../../editor/nodes/MarkNode").MergeField | null>>} setBaseValue
 * @property {{label: string; value: string;} | null} operator
 * @property {React.Dispatch<React.SetStateAction<{label: string; value: string;} | null>>} setOperator
 * @property {import("../../../../editor/nodes/MarkNode").MergeFieldValue} mergeFieldValue
 * @property {React.Dispatch<React.SetStateAction<import("../../../../editor/nodes/MarkNode").MergeFieldValue>>} setMergeFieldValue
 * @property {string[] | import("../../../../editor/nodes/MarkNode").MergeFieldValue} comparisonValue
 * @property {React.Dispatch<React.SetStateAction<string[] | import("../../../../editor/nodes/MarkNode").MergeFieldValue>>} setComparisonValue
 */

/**
 * @param {MergeFieldBaseValueTypeProps} props
 */
export default function MergeFieldBaseValueType({
  docId,
  isList,
  listOptions,
  condition,
  mergeFields,
  setMergeFields,
  baseValue,
  setBaseValue,
  operator,
  setOperator,
  mergeFieldValue,
  setMergeFieldValue,
  comparisonValue,
  setComparisonValue,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const loadDocumentMergeFields = useCallback(async () => {
    let url = `${state.settings.api}document/${docId}/mergeFields`;

    const response = await axios.get(url).catch((error) => {
      console.error(error);
    });

    if (!response) throw new Error("Error getting response.");

    /** @type {import("../../../../editor/nodes/MarkNode").MergeField[]} */
    const documentMergeFields = response.data.data;
    if (!documentMergeFields) throw new Error("Error getting Merge Fields");

    setMergeFields(
      documentMergeFields.filter(
        (documentMergeField) =>
          !documentMergeField.isTemplate &&
          documentMergeField.scope === "document" &&
          !documentMergeField.conditions.length
      )
    );
  }, [docId, setMergeFields, state.settings.api]);

  useEffect(() => {
    loadDocumentMergeFields();
  }, [loadDocumentMergeFields]);

  return (
    <>
      <Grid container direction="row" justifyContent={"center"}>
        <Autocomplete
          sx={{ width: "286px" }}
          options={mergeFields.map((mf) => ({
            label: mf.name,
            value: mf,
          }))}
          openOnFocus={!condition}
          autoHighlight
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={
            mergeFields
              .map((mf) => ({
                label: mf.name,
                value: mf,
              }))
              .find((x) => x.value._id === baseValue?._id) || null
          }
          onChange={(_, option) => {
            if (option) {
              setBaseValue(option.value);
              if (option.value.valueType === "freeText") {
                setComparisonValue([]);
              } else {
                let defaultMergeFieldValue = getMergeFieldDefaultValue(
                  option.value.valueType
                );
                if (defaultMergeFieldValue.type === "date") {
                  defaultMergeFieldValue = {
                    ...defaultMergeFieldValue,
                    value: new Date().toISOString(),
                  };
                } else if (defaultMergeFieldValue.type === "duration") {
                  defaultMergeFieldValue = {
                    ...defaultMergeFieldValue,
                    durationUnit: { label: "Days", value: "days" },
                  };
                }
                setComparisonValue(defaultMergeFieldValue);
              }
            } else {
              setBaseValue(null);
              setComparisonValue([]);
            }

            setOperator(null);
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} title={option.label}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                autoFocus={!condition}
                label="Base Value"
                placeholder="Merge field name ..."
                variant="outlined"
              />
            </>
          )}
        />
      </Grid>

      <Grid container direction="row">
        <br />
      </Grid>

      {baseValue && (
        <>
          <Grid container direction="row" justifyContent={"center"}>
            <Autocomplete
              sx={{ width: "286px" }}
              options={
                operators[baseValue.isList ? "isList" : baseValue.valueType]
              }
              autoHighlight
              openOnFocus={!condition}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              value={
                operators[baseValue.valueType].find(
                  (x) => x.value === operator?.value
                ) || null
              }
              onChange={(_, option) => {
                if (option) {
                  setOperator(option);
                } else {
                  setOperator(null);
                }
              }}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    autoFocus={!condition}
                    label="Operator"
                    placeholder="Select operator ..."
                    variant="outlined"
                  />
                </>
              )}
            />
          </Grid>

          <Grid container direction="row">
            <br />
          </Grid>

          {operator && (
            <>
              {operator.value !== "isKnown" &&
                operator.value !== "isUnknown" && (
                  <>
                    <Grid container direction="row" justifyContent={"center"}>
                      {baseValue.isList ? (
                        <>
                          <Autocomplete
                            openOnFocus={!condition}
                            sx={{ width: "286px" }}
                            options={baseValue.listOptions.map(
                              (x) => x.mergeFieldValue
                            )}
                            getOptionLabel={(option) => {
                              return getMergeFieldDisplayValue(option);
                            }}
                            value={
                              comparisonValue instanceof Array
                                ? null
                                : comparisonValue || null
                            }
                            autoHighlight
                            onChange={(_, option) => {
                              if (option) {
                                setComparisonValue(option);
                              } else {
                                setComparisonValue({
                                  type: "freeText",
                                  value: "",
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField
                                  autoFocus={!condition}
                                  {...params}
                                  label={"Comparison Value"}
                                  placeholder={"Select Comparison Value ..."}
                                  variant="outlined"
                                />
                              </>
                            )}
                          />
                        </>
                      ) : (
                        <>
                          {baseValue.value.type === "freeText" &&
                            comparisonValue instanceof Array && (
                              <Autocomplete
                                multiple
                                openOnFocus={!condition}
                                getOptionDisabled={(option) =>
                                  option ===
                                  "Type to add a value to this filter"
                                }
                                value={
                                  comparisonValue instanceof Array
                                    ? comparisonValue
                                    : []
                                }
                                // @ts-ignore
                                onChange={(
                                  _,
                                  /** @type {string[] | {inputValue: string; title: string}[]} */ values
                                ) => {
                                  if (values.length) {
                                    const mappedValues = values.map((value) => {
                                      if (typeof value === "string") {
                                        return value;
                                      }

                                      return value.inputValue;
                                    });

                                    // We do not allow duplicate values so we use a Set to ensure that.
                                    setComparisonValue([
                                      ...Array.from(new Set(mappedValues)),
                                    ]);
                                  } else {
                                    setComparisonValue([]);
                                  }
                                }}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  const { inputValue } = params;
                                  // Suggest the creation of a new value
                                  const isExisting = options.some(
                                    (option) => inputValue === option
                                  );
                                  if (inputValue !== "" && !isExisting) {
                                    filtered.push({
                                      inputValue,
                                      title: `Press enter to add "${inputValue}"`,
                                    });
                                  }

                                  return filtered;
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                options={[]}
                                getOptionLabel={(
                                  /** @type {string | {inputValue: string; title: string}} */ option
                                ) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }

                                  return option.inputValue;
                                }}
                                renderOption={(
                                  props,
                                  /** @type {string | {title: string}} */ option
                                ) => {
                                  if (typeof option === "string") {
                                    return <li {...props}>{option}</li>;
                                  }

                                  return <li {...props}>{option.title}</li>;
                                }}
                                sx={{ width: "286px" }}
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Comparison Value"
                                    variant="outlined"
                                    placeholder="Enter comparison value"
                                    autoFocus={!condition}
                                  />
                                )}
                              />
                            )}

                          {baseValue.value.type === "date" &&
                            !(comparisonValue instanceof Array) &&
                            comparisonValue.type === "date" && (
                              <>
                                <TextField
                                  autoFocus={!condition}
                                  type="date"
                                  placeholder={"Enter comparison date ..."}
                                  label={"Date"}
                                  value={
                                    comparisonValue.value
                                      ? format(
                                          new Date(comparisonValue.value),
                                          "yyyy-MM-dd"
                                        )
                                      : format(new Date(), "yyyy-MM-dd")
                                  }
                                  sx={{ width: "286px" }}
                                  variant="outlined"
                                  onChange={(event) => {
                                    const date = new Date(event.target.value);
                                    if (!isNaN(date.getDate())) {
                                      const isoStringDate = date.toISOString();
                                      setComparisonValue({
                                        ...comparisonValue,
                                        value: isoStringDate,
                                      });
                                    } else {
                                      setComparisonValue({
                                        ...comparisonValue,
                                        value: "",
                                      });
                                    }
                                  }}
                                />
                              </>
                            )}

                          {baseValue.value.type === "duration" &&
                            !(comparisonValue instanceof Array) &&
                            comparisonValue.type === "duration" && (
                              <>
                                <Grid
                                  container
                                  direction={"row"}
                                  justifyContent={"center"}
                                >
                                  <Grid
                                    item
                                    xs={3}
                                    sx={{ paddingLeft: "20px" }}
                                  >
                                    <TextField
                                      type="number"
                                      autoFocus={!condition}
                                      placeholder={"Enter Merge Field value"}
                                      label={"Comparison Value"}
                                      value={comparisonValue.durationValue}
                                      variant="outlined"
                                      onChange={(event) => {
                                        const number = Number(
                                          event.target.value
                                        );
                                        if (number) {
                                          setComparisonValue({
                                            ...comparisonValue,
                                            durationValue: event.target.value,
                                          });
                                        } else {
                                          setComparisonValue({
                                            ...comparisonValue,
                                            durationValue: "",
                                          });
                                        }
                                      }}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      paddingLeft: "20px",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    <Autocomplete
                                      getOptionDisabled={(_) => true}
                                      options={durationUnits}
                                      autoHighlight
                                      getOptionLabel={(option) => option.label}
                                      value={
                                        durationUnits.find(
                                          (x) =>
                                            x.value ===
                                            comparisonValue.durationUnit.value
                                        ) || baseValue.value.durationUnit
                                      }
                                      isOptionEqualToValue={(option, value) =>
                                        option.value === value.value
                                      }
                                      onChange={(_, option) => {
                                        if (option) {
                                          setComparisonValue({
                                            ...comparisonValue,
                                            durationUnit: option,
                                          });
                                        } else {
                                          setComparisonValue({
                                            ...comparisonValue,
                                            durationUnit: {
                                              label: "",
                                              value: "",
                                            },
                                          });
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <>
                                          <TextField
                                            {...params}
                                            label="Duration Unit"
                                            placeholder="Select Duration Unit ..."
                                            variant="outlined"
                                          />
                                        </>
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            )}

                          {baseValue.value.type === "number" &&
                            !(comparisonValue instanceof Array) &&
                            comparisonValue.type === "number" && (
                              <>
                                <Grid
                                  container
                                  direction={"row"}
                                  justifyContent={"center"}
                                >
                                  <TextField
                                    type="number"
                                    autoFocus={!condition}
                                    placeholder={"Enter Merge Field value"}
                                    label={"Merge Field Value"}
                                    value={comparisonValue.value}
                                    sx={{ width: "286px" }}
                                    variant="outlined"
                                    onChange={(event) => {
                                      const number = Number(event.target.value);
                                      if (number) {
                                        setComparisonValue({
                                          ...comparisonValue,
                                          value: event.target.value,
                                        });
                                      } else {
                                        setComparisonValue({
                                          ...comparisonValue,
                                          value: "",
                                        });
                                      }
                                    }}
                                  />
                                </Grid>
                              </>
                            )}

                          {baseValue.value.type === "currency" &&
                            !(comparisonValue instanceof Array) &&
                            comparisonValue.type === "currency" && (
                              <>
                                <Grid
                                  container
                                  direction={"row"}
                                  justifyContent={"center"}
                                >
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{ paddingLeft: "20px" }}
                                  >
                                    <Autocomplete
                                      getOptionDisabled={(_) => true}
                                      options={currencyCodes}
                                      autoHighlight
                                      getOptionLabel={(option) => option.label}
                                      isOptionEqualToValue={(option, value) =>
                                        option.value === value.value
                                      }
                                      value={
                                        currencyCodes.find(
                                          (x) =>
                                            x.value ===
                                            comparisonValue.currencyUnit.value
                                        ) || baseValue.value.currencyUnit
                                      }
                                      onChange={(_, option) => {
                                        if (option) {
                                          setComparisonValue({
                                            ...comparisonValue,
                                            currencyUnit: option,
                                          });
                                        } else {
                                          setComparisonValue({
                                            ...comparisonValue,
                                            currencyUnit: {
                                              label: "",
                                              value: "",
                                            },
                                          });
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <>
                                          <TextField
                                            {...params}
                                            label="Currency Unit"
                                            placeholder="Select Currency Unit ..."
                                            variant="outlined"
                                          />
                                        </>
                                      )}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={3}
                                    sx={{
                                      paddingLeft: "20px",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    <TextField
                                      autoFocus={!condition}
                                      type="number"
                                      placeholder={"Enter comparison value"}
                                      label={"Comparison Value"}
                                      value={comparisonValue.currencyValue}
                                      variant="outlined"
                                      onChange={(event) => {
                                        const number = Number(
                                          event.target.value
                                        );
                                        if (number) {
                                          setComparisonValue({
                                            ...comparisonValue,
                                            currencyValue: event.target.value,
                                          });
                                        } else {
                                          setComparisonValue({
                                            ...comparisonValue,
                                            currencyValue: "",
                                          });
                                        }
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            )}

                          {baseValue.value.type === "percentage" &&
                            !(comparisonValue instanceof Array) &&
                            comparisonValue.type === "percentage" && (
                              <>
                                <Grid
                                  container
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                >
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{ paddingLeft: "20px" }}
                                  >
                                    <TextField
                                      type="number"
                                      autoFocus={!condition}
                                      placeholder={"Enter comparison value"}
                                      label={"Comparison Value"}
                                      value={comparisonValue.value}
                                      variant="outlined"
                                      sx={{ width: "250px" }}
                                      onChange={(event) => {
                                        const number = Number(
                                          event.target.value
                                        );
                                        if (number) {
                                          setComparisonValue({
                                            ...comparisonValue,
                                            value: event.target.value,
                                          });
                                        } else {
                                          setComparisonValue({
                                            ...comparisonValue,
                                            value: "",
                                          });
                                        }
                                      }}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={1}
                                    sx={{ paddingLeft: "10px" }}
                                  >
                                    <div>{"%"}</div>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                        </>
                      )}
                    </Grid>

                    <Grid container direction="row">
                      <br />
                    </Grid>
                  </>
                )}

              <Grid container direction="row" justifyContent={"center"}>
                <b>Then the value should be ...</b>
              </Grid>

              <Grid container direction="row">
                <br />
              </Grid>

              {isList ? (
                <>
                  <Grid container direction={"row"} justifyContent={"center"}>
                    <Autocomplete
                      openOnFocus={!condition}
                      sx={{ width: "286px" }}
                      options={listOptions.map((x) => x.mergeFieldValue)}
                      value={
                        mergeFieldValueIsValid(mergeFieldValue)
                          ? mergeFieldValue
                          : null
                      }
                      getOptionLabel={(option) => {
                        return getMergeFieldDisplayValue(option);
                      }}
                      autoHighlight
                      onChange={(_, option) => {
                        if (option) {
                          setMergeFieldValue(option);
                        } else {
                          setComparisonValue({ type: "freeText", value: "" });
                        }
                      }}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            label={"Merge Field Value"}
                            placeholder={"Select Merge Field Value ..."}
                            variant="outlined"
                          />
                        </>
                      )}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  {mergeFieldValue.type === "freeText" && (
                    <>
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"center"}
                      >
                        <>
                          <TextField
                            placeholder={"Enter Merge Field value"}
                            label={"Merge Field Value"}
                            value={mergeFieldValue.value}
                            sx={{ width: "286px" }}
                            variant="outlined"
                            onChange={(event) => {
                              setMergeFieldValue({
                                ...mergeFieldValue,
                                type: "freeText",
                                value: event.target.value,
                              });
                            }}
                          />
                        </>
                      </Grid>
                    </>
                  )}

                  {mergeFieldValue.type === "date" && (
                    <>
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"center"}
                      >
                        <TextField
                          type="date"
                          placeholder={"Enter Merge Field value"}
                          label={"Merge Field Value"}
                          value={
                            mergeFieldValue.value
                              ? format(
                                  new Date(mergeFieldValue.value),
                                  "yyyy-MM-dd"
                                )
                              : ""
                          }
                          sx={{ width: "286px" }}
                          variant="outlined"
                          onChange={(event) => {
                            const date = new Date(event.target.value);
                            if (!isNaN(date.getDate())) {
                              const isoStringDate = date.toISOString();
                              setMergeFieldValue({
                                ...mergeFieldValue,
                                value: isoStringDate,
                              });
                            } else {
                              setMergeFieldValue({
                                ...mergeFieldValue,
                                value: "",
                              });
                            }
                          }}
                        />
                      </Grid>
                    </>
                  )}

                  {mergeFieldValue.type === "duration" && (
                    <>
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"center"}
                      >
                        <Grid item xs={3} sx={{ paddingLeft: "20px" }}>
                          <TextField
                            type="number"
                            placeholder={"Enter Merge Field value"}
                            label={"Merge Field Value"}
                            value={mergeFieldValue.durationValue}
                            variant="outlined"
                            onChange={(event) => {
                              const number = Number(event.target.value);
                              if (number) {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  durationValue: event.target.value,
                                });
                              } else {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  durationValue: "",
                                });
                              }
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          sx={{
                            paddingLeft: "20px",
                            paddingRight: "20px",
                          }}
                        >
                          <Autocomplete
                            options={durationUnits}
                            autoHighlight
                            getOptionLabel={(option) => option.label}
                            value={
                              durationUnits.find(
                                (x) =>
                                  x.value === mergeFieldValue.durationUnit.value
                              ) || durationUnits[2]
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            onChange={(_, option) => {
                              if (option) {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  durationUnit: option,
                                });
                              } else {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  durationUnit: {
                                    label: "",
                                    value: "",
                                  },
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField
                                  {...params}
                                  label="Duration Unit"
                                  placeholder="Select Duration Unit ..."
                                  variant="outlined"
                                />
                              </>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {mergeFieldValue.type === "number" && (
                    <>
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"center"}
                      >
                        <TextField
                          type="number"
                          placeholder={"Enter Merge Field value"}
                          label={"Merge Field Value"}
                          value={mergeFieldValue.value}
                          sx={{ width: "286px" }}
                          variant="outlined"
                          onChange={(event) => {
                            const number = Number(event.target.value);
                            if (number) {
                              setMergeFieldValue({
                                ...mergeFieldValue,
                                value: event.target.value,
                              });
                            } else {
                              setMergeFieldValue({
                                ...mergeFieldValue,
                                value: "",
                              });
                            }
                          }}
                        />
                      </Grid>
                    </>
                  )}

                  {mergeFieldValue.type === "currency" && (
                    <>
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"center"}
                      >
                        <Grid item xs={4} sx={{ paddingLeft: "20px" }}>
                          <Autocomplete
                            options={currencyCodes}
                            autoHighlight
                            openOnFocus={!condition}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            value={
                              currencyCodes.find(
                                (x) =>
                                  x.value === mergeFieldValue.currencyUnit.value
                              ) || null
                            }
                            onChange={(_, option) => {
                              if (option) {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  currencyUnit: option,
                                });
                              } else {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  currencyUnit: {
                                    label: "",
                                    value: "",
                                  },
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField
                                  {...params}
                                  label="Currency Unit"
                                  placeholder="Select Currency Unit ..."
                                  variant="outlined"
                                  autoFocus={!condition}
                                />
                              </>
                            )}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          sx={{
                            paddingLeft: "20px",
                            paddingRight: "20px",
                          }}
                        >
                          <TextField
                            type="number"
                            placeholder={"Enter Merge Field value"}
                            label={"Merge Field Value"}
                            value={mergeFieldValue.currencyValue}
                            variant="outlined"
                            onChange={(event) => {
                              const number = Number(event.target.value);
                              if (number) {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  currencyValue: event.target.value,
                                });
                              } else {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  currencyValue: "",
                                });
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {mergeFieldValue.type === "percentage" && (
                    <>
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Grid item xs={10} sx={{ paddingLeft: "20px" }}>
                          <TextField
                            type="number"
                            placeholder={"Enter Merge Field value"}
                            label={"Merge Field Value"}
                            value={mergeFieldValue.value}
                            variant="outlined"
                            sx={{ width: "250px" }}
                            onChange={(event) => {
                              const number = Number(event.target.value);
                              if (number) {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  value: event.target.value,
                                });
                              } else {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  value: "",
                                });
                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={2} sx={{ paddingLeft: "10px" }}>
                          <div>{"%"}</div>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}

              <Grid container direction="row">
                <br />
              </Grid>

              {/* TODO: Uncomment when we implement calculations. */}
              {/* {baseValue.type !== "freeText" &&
                mergeFieldValue.type === "freeText" && (
                  <>
                    <Grid container direction="row" justifyContent={"center"}>
                      <div style={{ width: "270px", fontSize: "12px" }}>
                        <span>
                          Add an Equal Sign (=) in front to calculate the value
                        </span>

                        <br />

                        <span
                          style={{
                            color: "#6200EE",
                            cursor: "pointer",
                          }}
                        >
                          Insert {`{${baseValue.name}}`}
                        </span>
                      </div>

                      <Grid container direction="row">
                        <br />
                      </Grid>
                    </Grid>
                  </>
                )} */}
            </>
          )}
        </>
      )}
    </>
  );
}
