/**
 * Checks if a selection is at the very beginning of a TextNode.
 *
 * @param {import("lexical").RangeSelection} selection
 * @returns {boolean}
 */
export function selectionIsAtBeginningOfTextNode(selection) {
  const [anchor, focus] = selection.getCharacterOffsets();

  // We can tell if a selection is at the beginning of a TextNode if both its anchor and
  // focus have the value of 0.
  const selectionIsAtEndOfTextNode = anchor === 0 && focus === 0;
  return selectionIsAtEndOfTextNode;
}
