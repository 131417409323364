const getDOMRangeRect = (nativeSelection, rootElement) => {
  const domRange = nativeSelection.getRangeAt(0);
  if (nativeSelection.anchorNode === rootElement) {
    let inner = rootElement;
    while (inner.firstElementChild != null) {
      inner = inner.firstElementChild;
    }
    return inner.getBoundingClientRect();
  } else {
    return domRange.getBoundingClientRect();
  }
};

export default getDOMRangeRect;
