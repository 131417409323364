import { $getNearestNodeOfType } from "@lexical/utils";
import { $getSelection, $isRangeSelection, $isTextNode } from "lexical";
import { $isClauseNode, ClauseNode } from "../nodes/ClauseNode";
import { $isRedlineNode } from "../nodes/RedlineNode";
import { handleNodeDeletion } from "../plugins/TrackChangesPlugin/utils";

/**
 * Creates proposed deletion redlines across all nodes in the selection.
 *
 * @param {import("lexical").RangeSelection} selection
 * @param {RedlineData} defaultRedlineData
 * @param {boolean} removeEmptyElementNodeIfSelectionCollapsed
 * @returns {void}
 */
export function handleSelectionDelete(
  selection,
  defaultRedlineData,
  removeEmptyElementNodeIfSelectionCollapsed = false
) {
  if (selection.isCollapsed()) {
    if (removeEmptyElementNodeIfSelectionCollapsed) {
      const node = selection.focus.getNode();
      const clauseNode = $getNearestNodeOfType(node, ClauseNode);
      if (clauseNode && clauseNode.getTextContentSize() === 0) {
        clauseNode.remove();
      }
    }

    return;
  }

  const isBackward = selection.isBackward();

  let focusNodeSplitNodeKeyToIgnore = "";
  let anchorNodeSplitNodeKeyToIgnore = "";

  if (!isBackward) {
    const selectionFocusNode = selection.focus.getNode();
    if ($isTextNode(selectionFocusNode)) {
      const focusNodeSplitNodes = selectionFocusNode.splitText(
        selection.focus.offset
      );
      focusNodeSplitNodeKeyToIgnore =
        focusNodeSplitNodes.length === 2
          ? focusNodeSplitNodes[isBackward ? 0 : 1].getKey()
          : "";
    }

    const selectionAnchorNode = selection.anchor.getNode();
    if ($isTextNode(selectionAnchorNode)) {
      const anchorNodeSplitNodes = selectionAnchorNode.splitText(
        selection.anchor.offset
      );

      anchorNodeSplitNodeKeyToIgnore =
        anchorNodeSplitNodes.length === 2
          ? anchorNodeSplitNodes[isBackward ? 1 : 0].getKey()
          : "";
    }
  } else {
    const selectionAnchorNode = selection.anchor.getNode();
    if ($isTextNode(selectionAnchorNode)) {
      const anchorNodeSplitNodes = selectionAnchorNode.splitText(
        selection.anchor.offset
      );

      anchorNodeSplitNodeKeyToIgnore =
        anchorNodeSplitNodes.length === 2
          ? anchorNodeSplitNodes[isBackward ? 1 : 0].getKey()
          : "";
    }

    const selectionFocusNode = selection.focus.getNode();
    if ($isTextNode(selectionFocusNode)) {
      const focusNodeSplitNodes = selectionFocusNode.splitText(
        selection.focus.offset
      );
      focusNodeSplitNodeKeyToIgnore =
        focusNodeSplitNodes.length === 2
          ? focusNodeSplitNodes[isBackward ? 0 : 1].getKey()
          : "";
    }
  }

  // Whenever there is the possibility that we deleted or replaced nodes that were part of
  // the selection, we need to refetch the selection so that it contains the updated nodes.
  const selectionAfterSplits = $getSelection();
  if (!$isRangeSelection(selectionAfterSplits)) return;

  const nodes = selectionAfterSplits.getNodes();
  for (const node of nodes) {
    // Ignore the nodes that sit outside of the selection after splitting the selection edge nodes.
    if (
      [anchorNodeSplitNodeKeyToIgnore, focusNodeSplitNodeKeyToIgnore].includes(
        node.getKey()
      )
    ) {
      continue;
    }

    if ($isTextNode(node)) {
      handleNodeDeletion(node, defaultRedlineData);
      continue;
    }

    if ($isClauseNode(node) && node.getTextContentSize() === 0) {
      node.remove();
    }
  }

  // Whenever there is the possibility that we deleted or replaced nodes that were part of
  // the selection, we need to refetch the selection so that it contains the updated nodes.
  const updatedSelection = $getSelection();
  if (!$isRangeSelection(updatedSelection)) return;

  const updatedSelectionIsBackward = updatedSelection.isBackward();
  const updatedSelectionNodes = updatedSelectionIsBackward
    ? updatedSelection.getNodes()
    : updatedSelection.getNodes().reverse();
  for (const node of updatedSelectionNodes) {
    if ($isRedlineNode(node) && node.getRedlineType() !== "add") {
      if (updatedSelectionIsBackward) {
        node.select(0, 0);
      } else {
        const nodeTextContentSize = node.getTextContentSize();
        node.select(nodeTextContentSize, nodeTextContentSize);
      }

      break;
    }
  }
}
