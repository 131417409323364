import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { COMMAND_PRIORITY_CRITICAL, KEY_ENTER_COMMAND } from "lexical";
import { useEffect } from "react";
import { getDefaultRedlineData } from "../../utils/getDefaultRedlineData";
import { enterCommandHandler } from "./handlers/enterCommandHandler";

/**
 * @typedef {object} CanveoPluginProps
 * @property {PartyId} partyId
 * @property {PartialUser} user
 */

/**
 * @param {CanveoPluginProps} props
 * @returns {null}
 */
export default function CanveoPlugin({ partyId, user }) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    const defaultRedlineData = getDefaultRedlineData(partyId, user);

    return mergeRegister(
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        (event) => enterCommandHandler(editor, event, defaultRedlineData),
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [editor, partyId, user]);

  return null;
}
