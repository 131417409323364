import {
  faBook,
  faBookBookmark,
  // faBookLaw,
  faBooks,
  faCircleInfo,
  faClipboardQuestion,
  faClone,
  // faComments,
  faDownload,
  faFileContract,
  faFileLines,
  faGear,
  faList,
  faPalette,
  faPlugCirclePlus,
  faShieldKeyhole,
  faSignature,
  faTags,
  faUniversity,
  faUser,
  faUserSecret,
} from "@fortawesome/pro-solid-svg-icons";
import { getCanveoTier } from "../../utils/getCanveoTier";

/**
 * @param {string} active
 * @param {(route: string) => {}} handleClick
 * @param {string} email
 * @param {boolean} isSuperAdmin
 */
const getAdminDrawerItems = (
  active,
  handleClick,
  email,
  isSuperAdmin = false
) => {
  const drawerItems = [
    {
      name: "Settings",
      icon: faGear,
      active: active === "profile",
      click: () => handleClick("/admin/profile"),
    },
    {
      name: "Companies",
      icon: faUniversity,
      active: active === "entities",
      click: () => handleClick("/admin/entities"),
    },
    {
      name: "Users",
      icon: faUser,
      active: active === "users",
      click: () => handleClick("/admin/users"),
    },
    {
      name: "Roles",
      icon: faShieldKeyhole,
      active: active === "roles",
      click: () => handleClick("/admin/roles"),
    },
    {
      name: "Signers",
      icon: faSignature,
      active: active === "signers",
      click: () => handleClick("/admin/signers"),
    },
  ];

  if (getCanveoTier(email) === "experimental") {
    drawerItems.push(
      ...[
        {
          name: "Branding",
          icon: faPalette,
          active: active === "branding",
          click: () => handleClick("/admin/branding"),
        },
      ]
    );
  }

  drawerItems.push({
    name: "Integrations",
    icon: faPlugCirclePlus,
    active: active === "integrations",
    click: () => handleClick("/admin/integrations"),
  });

  if (isSuperAdmin) {
    return [
      ...drawerItems,
      {
        name: "ADMIN",
        icon: faUserSecret,
        active: active === "superadmin",
        click: () => handleClick("/superadmin"),
      },
    ];
  }

  return drawerItems;
};

/**
 * @param {string} active
 * @param {(route: string) => void} handleClick
 * @param {string} email
 */
const getLegalDrawerItems = (active, handleClick, email) => {
  const drawerItems = [
    {
      name: "Templates",
      icon: faFileLines,
      active: active === "main",
      click: () => handleClick("/templates/main"),
    },
  ];

  if (getCanveoTier(email) === "experimental") {
    drawerItems.push(
      {
        name: "Clause Library",
        icon: faBooks,
        active: active === "library",
        click: () => handleClick("/templates/library"),
      },
      {
        name: "Key Topics",
        icon: faBookBookmark,
        active: active === "topics",
        click: () => handleClick("/templates/topics"),
      }
    );
  }

  drawerItems.push(
    ...[
      {
        name: "Labels",
        icon: faTags,
        active: active === "labels",
        click: () => handleClick("/templates/labels"),
      },
    ]
  );

  if (getCanveoTier(email) === "experimental") {
    drawerItems.push(
      {
        name: "Policy",
        icon: faBookBookmark,
        active: active === "policy",
        click: () => handleClick("/templates/policy"),
      },
      {
        name: "Playbooks",
        icon: faBook,
        active: active === "playbook",
        click: () => handleClick("/templates/playbook"),
      }
    );
  }

  return drawerItems;
};

/**
 * @param {string} email
 */
const getTemplateDrawerItems = (email) => {
  const drawerItems = [
    {
      name: "Summary",
      icon: faCircleInfo,
    },
    {
      name: "Exhibits",
      icon: faFileContract,
    },
    {
      name: "Versions",
      icon: faClone,
    },
  ];

  if (getCanveoTier(email) === "experimental") {
    drawerItems.push(
      {
        name: "Questionnaire",
        icon: faClipboardQuestion,
      },
      {
        name: "Contents",
        icon: faList,
      }
    );
  }

  drawerItems.push(
    ...[
      // Hidden for now.
      // {
      //   name: "Discussion",
      //   icon: faComments,
      // },
      {
        name: "Export",
        icon: faDownload,
      },
    ]
  );

  return drawerItems;
};

/**
 * @param {string} email
 */
const getAgreementDrawerItems = (email) => {
  const drawerItems = [
    {
      id: "editor-drawer-summary",
      name: "Summary",
      icon: faCircleInfo,
    },
    {
      id: "editor-drawer-exhibits",
      name: "Exhibits",
      icon: faFileContract,
    },
    {
      id: "editor-drawer-versions",
      name: "Versions",
      icon: faClone,
    },
  ];

  if (getCanveoTier(email) === "experimental") {
    drawerItems.push(
      {
        id: "editor-drawer-playbook",
        name: "Playbook",
        icon: faBookBookmark,
      },
      {
        id: "editor-drawer-questionnaire",
        name: "Questionnaire",
        icon: faClipboardQuestion,
      },
      {
        id: "editor-drawer-contents",
        name: "Contents",
        icon: faList,
      }
    );
  }

  drawerItems.push(
    ...[
      // Hidden for now.
      // {
      //   name: "Discussion",
      //   icon: faComments,
      // },
      {
        id: "editor-drawer-export",
        name: "Export",
        icon: faDownload,
      },
    ]
  );

  return drawerItems;
};

export {
  getAdminDrawerItems,
  getAgreementDrawerItems,
  getLegalDrawerItems,
  getTemplateDrawerItems,
};
