import React from "react";
import theme from "../theme/theme";
import { Chip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-solid-svg-icons";

const ChipUserType = (props) => {
  console.log("props", props);
  const styles = {
    pillStd: {
      width: "200px",
      fontWeight: "500",
    },
    pillTiny: {
      width: "120px",
      fontWeight: "500",
    },
    pillTinyLegal: {
      width: "120px",
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.tertiary.contrastText,
    },
    pillTinyCpty: {
      width: "120px",
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.primary.contrastText,
    },
    pillLegal: {
      width: "200px",
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.tertiary.contrastText,
    },
    pillCpty: {
      width: "200px",
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.primary.contrastText,
    },
    iconUser: {
      color: theme.palette.tertiary.contrastText,
      marginLeft: "8px",
      fontSize: "11px",
    },
  };

  let size = "medium";

  if (props.type === "tiny") {
    size = "small";
  }

  return (
    <Chip
      size={size}
      label={
        <>
          {props.role}
          {props.readOnly ? (
            <FontAwesomeIcon icon={faEye} style={styles.iconUser} />
          ) : (
            ""
          )}
        </>
      }
      color={props.role === "Admin" ? "secondary" : "primary"}
      style={
        props.type === "tiny" && props.role === "Legal"
          ? styles.pillTinyLegal
          : props.type !== "tiny" && props.role === "Legal"
          ? styles.pillLegal
          : props.type === "tiny" && props.role === "Counterparty"
          ? styles.pillTinyCpty
          : props.type !== "tiny" && props.role === "Counterparty"
          ? styles.pillCpty
          : props.type === "tiny"
          ? styles.pillTiny
          : styles.pillStd
      }
    />
  );
};

export default ChipUserType;
