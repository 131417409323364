import { getMergeFieldDisplayValue } from "../../components/MergeFieldMenu/utils";
import { calculatePartyInformationMergeFieldValue } from "../calculatePartyInformationMergeFieldValue";
import * as operatorHandlers from "./operatorHandlers";

/**
 * Calculates the value of a merge field at the time of agreement creation.
 *
 * @param {import("../../components/editor/nodes/MarkNode").MergeField} mergeField
 * @param {import("../calculatePartyInformationMergeFieldValue").AgreementParty[]} agreementParties
 * @param {{ _id: string; shortName: string }[]} organizations
 * @returns {string}
 */
export const calculateMergeFieldValue = (
  mergeField,
  agreementParties,
  organizations
) => {
  if (mergeField.type === "partyInformation") {
    return calculatePartyInformationMergeFieldValue(
      mergeField,
      agreementParties,
      organizations
    );
  }

  if (mergeField.conditions && mergeField.conditions.length) {
    for (const condition of mergeField.conditions) {
      const { baseValue } = condition;

      if (baseValue.conditions.length) {
        throw new Error("Base values with conditions are not allowed.");
      }

      // Party information base values do not have a value since the value
      // can only be known at the point of agreement creation, which is why
      // we need to calculate them at this moment.
      if (baseValue.type === "partyInformation") {
        baseValue.value = {
          type: "freeText",
          value: calculatePartyInformationMergeFieldValue(
            mergeField,
            agreementParties,
            organizations
          ),
        };
      }

      const result = calculateCondition(condition);
      if (!result) continue;

      return getMergeFieldDisplayValue(result);
    }
  }

  return mergeField.displayValue;
};

/**
 * @param {import("../../components/MergeFieldMenu/dialogs/NewConditionDialog/condition").Condition} condition
 */
export const calculateCondition = (condition) => {
  const { operator } = condition;

  const handler =
    operatorHandlers[
      /** @type {keyof typeof operatorHandlers} */ (operator.value)
    ];

  if (!handler) {
    throw new Error(`${operator.value} is not a valid operator.`);
  }

  const result = handler(condition);
  return result;
};
