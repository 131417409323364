import { parseValueForHTML } from "./parseValueForHTML";

/**
 * @param {ListHandlerInstance} listHandler
 * @param {string} listItemKey
 * @param {string} listPrefix
 * @param {string} listLevelPattern
 * @param {number} value
 * @param {string} listType
 */
export const getFormattedListStyleType = (
  listHandler,
  listItemKey,
  listPrefix,
  listLevelPattern,
  value,
  listType = "number",
  styleName = ""
) => {
  if (listType !== "number") return `"${listPrefix} "`;

  // Useful for unit tests.
  // console.log({
  //   listHandler,
  //   listItemKey,
  //   listPrefix,
  //   listLevelPattern,
  //   value,
  //   listType,
  // });

  const listItem = listHandler.root.find((list) => list.id === listItemKey);
  if (!listItem) throw new Error("Could not find list item.");

  const index = listHandler.root.indexOf(listItem);
  const searchArray = listHandler.root.slice(0, index).reverse();
  let level = listItem.level;

  let abstractList = listHandler.lists.find(
    (abstractList) => abstractList.abstractListId === listItem.listId
  );
  // if (!abstractList && styleName) {
  //   const listId = listHandler?.currentSfdt?.styles?.find(
  //     (x) => x.name === styleName
  //   )?.paragraphFormat?.listFormat?.listId;

  //   abstractList = listHandler.lists.find((l) => l.abstractListId === listId);
  // }
  if (!abstractList) throw new Error("Could not find abstract list.");

  let /** @type {PseudoListItem | undefined} */ listItemPointer = listItem;
  let listItemPrefix = listPrefix;

  // Goes until -1 to address level 0.
  while (level >= -1) {
    const levelPattern = /** @type {MarkerPattern} */ (
      abstractList.levels[listItemPointer?.level ?? -1]?.listLevelPattern
    );

    const calculation = parseValueForHTML(
      listItemPointer?.value ?? 1,
      levelPattern
    );

    if (listItemPointer) {
      const currentLevel = `%${listItemPointer?.level + 1}`;
      listItemPrefix = listItemPrefix.replace(currentLevel, calculation);
    }
    // else {
    //   //else we're defaulting to 1
    //   const currentIndent = `%${level + 1}`;
    //   markerCopy = markerCopy.replace(currentIndent, calc);
    // }

    // Has parent (priority) or item in level.
    const nextListItem = searchArray.find(
      // eslint-disable-next-line no-loop-func
      (element) => element.id === listItemPointer?.parentId
    );
    if (!nextListItem) break;

    listItemPointer = nextListItem;
    level--;
  }
  // Replace values that were left out of the loop with "1"
  // because we could not find a match.
  listItemPrefix = listItemPrefix.replace(/%(\d)/gm, "1");

  return `"${listItemPrefix} "`;
};
