import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useContext, useState } from "react";
import useExhibitService from "../../hooks/useExhibitService";
import { globalStore } from "../../state/store";
import { SelectOrUploadTemplate, ThumbAgr } from "../index";

/**
 * @param {*} props
 * @returns {React.JSX.Element}
 */
function DialogCreateExhibit({
  open,
  mainBody,
  isTemplate,
  handleClose,
  taskId,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [stagedExhibit, setStagedExhibit] = useState(null);

  const { createExhibit } = useExhibitService();

  const handleSelectExhibit = (type, exhibit) => {
    if (Array.isArray(exhibit)) setStagedExhibit(exhibit[0]);
    else setStagedExhibit(exhibit);
  };

  const handleBack = () => {
    if (!stagedExhibit) handleClose();
    if (stagedExhibit) setStagedExhibit(null);
  };

  const submitExhibit = async () => {
    const count = isTemplate ? state.template.length : state.agrs.length;
    const description = `Contains work done by ${state.org.shortName}`;

    const exhibitData = {
      ...stagedExhibit,
      priority: count * 10,
      parentID: mainBody._id,
      description: description,
      organizations: mainBody.orgs,
      entities: mainBody.ents,
    };

    const success = await createExhibit(exhibitData, isTemplate);

    if (success) handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "650px",
          },
        },
      }}
    >
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>
      <DialogTitle>Add Exhibit</DialogTitle>
      <DialogContent sx={{ p: 5 }}>
        {stagedExhibit && (
          <Grid item container justifyContent={"center"} sx={{ my: 2 }}>
            <ThumbAgr
              ag={{
                ...stagedExhibit,
                lastUpdateDate: "To be created",
                agrStatus: "Exhibit",
              }}
              actionReq={false}
              thumbClick={null}
              showLogo={state.org.logoURL}
              primaryLegalName={""}
              secondaryLegalName={""}
            />
          </Grid>
        )}
        {!stagedExhibit && (
          <Grid item container justifyContent={"center"} sx={{ my: 2 }}>
            <SelectOrUploadTemplate
              isMain={false}
              handleSelectOrUploadTemplate={handleSelectExhibit}
              isCounterparty={state.user.role?.name === "Counterparty"}
              taskId={taskId}
            />
          </Grid>
        )}
      </DialogContent>
      {stagedExhibit && (
        <DialogActions>
          <Button onClick={handleBack} sx={{ marginRight: "auto" }}>
            Cancel
          </Button>
          <Button
            onClick={submitExhibit}
            variant="contained"
            disableElevation
            disabled={!stagedExhibit}
          >
            Confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default DialogCreateExhibit;
