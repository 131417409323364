import {
  faCalendar,
  faCirclePlus,
  faHourglassStart,
  faInputNumeric,
  faInputText,
} from "@fortawesome/pro-regular-svg-icons";
import { faPercentage, faSquareDollar } from "@fortawesome/pro-solid-svg-icons";

export const /** @type {import("./../editor/nodes/MarkNode").MergeFieldValue} */ defaultMergeFieldValue =
    {
      type: "freeText",
      value: "",
    };

/**
 * @typedef {"default" | "new" | "submit" | string} MergeFieldMenuPage
 */

export const /** @type {MergeFieldMenuPage} **/ defaultStep = "default";

/**
 * @typedef {object} DefaultMergeField
 * @property {string} [id]
 * @property {string} type
 * @property {string} label
 * @property {string} value
 * @property {*} icon
 * @property {boolean} isTemplate
 */

/**
 * @param {{ includeCreateAction: boolean, createActionLabel: string }} props
 * @returns {DefaultMergeField[]}
 */
export function getDefaultMergeFields({
  includeCreateAction,
  createActionLabel = "Create New Merge Field",
}) {
  /** @type {DefaultMergeField[]} */
  const defaultMergeFields = [];

  if (includeCreateAction) {
    defaultMergeFields.push({
      id: undefined,
      type: "action",
      label: createActionLabel,
      value: "",
      icon: faCirclePlus,
      isTemplate: false,
    });
  }

  defaultMergeFields.push(
    {
      id: undefined,
      type: "partyInformation",
      label: "Party Address (L1)",
      value: "Party Address (L1)",
      icon: faInputText,
      isTemplate: true,
    },
    {
      id: undefined,
      type: "partyInformation",
      label: "Party Address (L2)",
      value: "Party Address (L2)",
      icon: faInputText,
      isTemplate: true,
    },
    {
      id: undefined,
      type: "partyInformation",
      label: "Party City",
      value: "Party City",
      icon: faInputText,
      isTemplate: true,
    },
    {
      id: undefined,
      type: "partyInformation",
      label: "Party Country",
      value: "Party Country",
      icon: faInputText,
      isTemplate: true,
    },
    {
      id: undefined,
      type: "partyInformation",
      label: "Party Entity Name",
      value: "Party Entity Name",
      icon: faInputText,
      isTemplate: true,
    },
    {
      id: undefined,
      type: "partyInformation",
      label: "Party Org Name",
      value: "Party Org Name",
      icon: faInputText,
      isTemplate: true,
    },
    {
      id: undefined,
      type: "partyInformation",
      label: "Party State/Province",
      value: "Party State/Province",
      icon: faInputText,
      isTemplate: true,
    },
    {
      id: undefined,
      type: "partyInformation",
      label: "Party ZIP Code",
      value: "Party ZIP Code",
      icon: faInputText,
      isTemplate: true,
    }
  );

  return defaultMergeFields;
}

/**
 * @typedef {object} ListOption
 * @property {string} id
 * @property {import("./../editor/nodes/MarkNode").MergeFieldValue} mergeFieldValue
 */

export const /** @type {ListOption[]} */ defaultListOptions = [];

export const /** @type {import("./../editor/nodes/MarkNode").MergeField[]} */ defaultDocumentMergeFields =
    [];

export const /** @type {{ title: string; inputValue?: string, disabled: boolean; label: string; value: string; type: string; } []} */ defaultPartyRoles =
    [];

// Previous harcoded default party roles.
// [
//   {
//     label: "Buyer",
//     value: "Buyer",
//     type: "default",
//   },
//   {
//     label: "Seller",
//     value: "Seller",
//     type: "default",
//   },
//   {
//     label: "Counterparty",
//     value: "Counterparty",
//     type: "default",
//   },
//   {
//     label: "Client",
//     value: "Client",
//     type: "default",
//   },
// ];

export const defaultParties = [
  { label: "Party 0", value: "party0" },
  {
    label: "Party 1",
    value: "party1",
  },
  {
    label: "Party 2",
    value: "party2",
  },
  {
    label: "Party 3",
    value: "party3",
  },
  {
    label: "Party 4",
    value: "party4",
  },
  {
    label: "Party 5",
    value: "party5",
  },
];

export const mergeFieldValueTypes = /** @type {const} */ ([
  {
    icon: faInputText,
    label: "Free Text",
    value: "freeText",
  },
  {
    icon: faCalendar,
    label: "Date",
    value: "date",
  },
  {
    icon: faHourglassStart,
    label: "Duration",
    value: "duration",
  },
  {
    icon: faInputNumeric,
    label: "Number",
    value: "number",
  },
  {
    icon: faSquareDollar,
    label: "Currency",
    value: "currency",
  },
  {
    icon: faPercentage,
    label: "Percentage",
    value: "percentage",
  },
]);

export const visibilities = [
  { label: "Not Visible", value: "notVisible" },
  {
    label: "Visible to a specific counterparty",
    value: "visibleToSpecificCounterparty",
  },
  {
    label: "Visible to all",
    value: "visibleToAll",
  },
];

export const durationUnits = [
  { label: "Minutes", value: "minutes" },
  { label: "Hours", value: "hours" },
  { label: "Days", value: "days" },
  { label: "Business Days", value: "businessDays" },
  { label: "Weeks", value: "weeks" },
  { label: "Months", value: "months" },
  { label: "Years", value: "years" },
];

export const currencyCodes = [
  { label: "USD", value: "usd" },
  { label: "EUR", value: "eur" },
  { label: "GBP", value: "gbp" },
  { label: "JPY", value: "jpy" },
  { label: "CAD", value: "cad" },
  { label: "AUD", value: "aud" },
  { label: "CHF", value: "chf" },
  { label: "CNY", value: "cny" },
  { label: "SEK", value: "sek" },
  { label: "NZD", value: "nzd" },
  { label: "MXN", value: "mxn" },
  { label: "SGD", value: "sgd" },
  { label: "HKD", value: "hkd" },
  { label: "NOK", value: "nok" },
  { label: "KRW", value: "krw" },
  { label: "TRY", value: "try" },
  { label: "INR", value: "inr" },
  { label: "RUB", value: "rub" },
  { label: "BRL", value: "brl" },
  { label: "ZAR", value: "zar" },
  { label: "AED", value: "aed" },
  { label: "AFN", value: "afn" },
  { label: "ALL", value: "all" },
  { label: "AMD", value: "amd" },
  { label: "ANG", value: "ang" },
  { label: "AOA", value: "aoa" },
  { label: "ARS", value: "ars" },
  { label: "AWG", value: "awg" },
  { label: "AZN", value: "azn" },
  { label: "BAM", value: "bam" },
  { label: "BBD", value: "bbd" },
  { label: "BDT", value: "bdt" },
  { label: "BGN", value: "bgn" },
  { label: "BHD", value: "bhd" },
  { label: "BIF", value: "bif" },
  { label: "BMD", value: "bmd" },
  { label: "BND", value: "bnd" },
  { label: "BOB", value: "bob" },
  { label: "BSD", value: "bsd" },
  { label: "BTC", value: "btc" },
  { label: "BTN", value: "btn" },
  { label: "BWP", value: "bwp" },
  { label: "BYN", value: "byn" },
  { label: "BYR", value: "byr" },
  { label: "BZD", value: "bzd" },
  { label: "CDF", value: "cdf" },
  { label: "CLF", value: "clf" },
  { label: "CLP", value: "clp" },
  { label: "COP", value: "cop" },
  { label: "CRC", value: "crc" },
  { label: "CUC", value: "cuc" },
  { label: "CUP", value: "cup" },
  { label: "CVE", value: "cve" },
  { label: "CZK", value: "czk" },
  { label: "DJF", value: "djf" },
  { label: "DKK", value: "dkk" },
  { label: "DOP", value: "dop" },
  { label: "DZD", value: "dzd" },
  { label: "EGP", value: "egp" },
  { label: "ERN", value: "ern" },
  { label: "ETB", value: "etb" },
  { label: "FJD", value: "fjd" },
  { label: "FKP", value: "fkp" },
  { label: "GEL", value: "gel" },
  { label: "GGP", value: "ggp" },
  { label: "GHS", value: "ghs" },
  { label: "GIP", value: "gip" },
  { label: "GMD", value: "gmd" },
  { label: "GNF", value: "gnf" },
  { label: "GTQ", value: "gtq" },
  { label: "GYD", value: "gyd" },
  { label: "HNL", value: "hnl" },
  { label: "HRK", value: "hrk" },
  { label: "HTG", value: "htg" },
  { label: "HUF", value: "huf" },
  { label: "IDR", value: "idr" },
  { label: "ILS", value: "ils" },
  { label: "IMP", value: "imp" },
  { label: "IQD", value: "iqd" },
  { label: "IRR", value: "irr" },
  { label: "ISK", value: "isk" },
  { label: "JEP", value: "jep" },
  { label: "JMD", value: "jmd" },
  { label: "JOD", value: "jod" },
  { label: "KES", value: "kes" },
  { label: "KGS", value: "kgs" },
  { label: "KHR", value: "khr" },
  { label: "KMF", value: "kmf" },
  { label: "KPW", value: "kpw" },
  { label: "KWD", value: "kwd" },
  { label: "KYD", value: "kyd" },
  { label: "KZT", value: "kzt" },
  { label: "LAK", value: "lak" },
  { label: "LBP", value: "lbp" },
  { label: "LKR", value: "lkr" },
  { label: "LRD", value: "lrd" },
  { label: "LSL", value: "lsl" },
  { label: "LTL", value: "ltl" },
  { label: "LVL", value: "lvl" },
  { label: "LYD", value: "lyd" },
  { label: "MAD", value: "mad" },
  { label: "MDL", value: "mdl" },
  { label: "MGA", value: "mga" },
  { label: "MKD", value: "mkd" },
  { label: "MMK", value: "mmk" },
  { label: "MNT", value: "mnt" },
  { label: "MOP", value: "mop" },
  { label: "MRO", value: "mro" },
  { label: "MUR", value: "mur" },
  { label: "MVR", value: "mvr" },
  { label: "MWK", value: "mwk" },
  { label: "MYR", value: "myr" },
  { label: "MZN", value: "mzn" },
  { label: "NAD", value: "nad" },
  { label: "NGN", value: "ngn" },
  { label: "NIO", value: "nio" },
  { label: "NPR", value: "npr" },
  { label: "OMR", value: "omr" },
  { label: "PAB", value: "pab" },
  { label: "PEN", value: "pen" },
  { label: "PGK", value: "pgk" },
  { label: "PHP", value: "php" },
  { label: "PKR", value: "pkr" },
  { label: "PLN", value: "pln" },
  { label: "PYG", value: "pyg" },
  { label: "QAR", value: "qar" },
  { label: "RON", value: "ron" },
  { label: "RSD", value: "rsd" },
  { label: "RWF", value: "rwf" },
  { label: "SAR", value: "sar" },
  { label: "SBD", value: "sbd" },
  { label: "SCR", value: "scr" },
  { label: "SDG", value: "sdg" },
  { label: "SHP", value: "shp" },
  { label: "SLL", value: "sll" },
  { label: "SOS", value: "sos" },
  { label: "SRD", value: "srd" },
  { label: "STD", value: "std" },
  { label: "SVC", value: "svc" },
  { label: "SYP", value: "syp" },
  { label: "SZL", value: "szl" },
  { label: "THB", value: "thb" },
  { label: "TJS", value: "tjs" },
  { label: "TMT", value: "tmt" },
  { label: "TND", value: "tnd" },
  { label: "TOP", value: "top" },
  { label: "TTD", value: "ttd" },
  { label: "TWD", value: "twd" },
  { label: "TZS", value: "tzs" },
  { label: "UAH", value: "uah" },
  { label: "UGX", value: "ugx" },
  { label: "UYU", value: "uyu" },
  { label: "UZS", value: "uzs" },
  { label: "VEF", value: "vef" },
  { label: "VND", value: "vnd" },
  { label: "VUV", value: "vuv" },
  { label: "WST", value: "wst" },
  { label: "XAF", value: "xaf" },
  { label: "XAG", value: "xag" },
  { label: "XAU", value: "xau" },
  { label: "XCD", value: "xcd" },
  { label: "XDR", value: "xdr" },
  { label: "XOF", value: "xof" },
  { label: "XPF", value: "xpf" },
  { label: "YER", value: "yer" },
  { label: "ZMK", value: "zmk" },
  { label: "ZMW", value: "zmw" },
  { label: "ZWL", value: "zwl" },
];
