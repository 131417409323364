const versionsReducer = (state, action) => {
  switch (action.type) {
    case "INIT_VERSIONS":
      const { active, versions } = action.payload;
      return { drawerVersions: { versions: versions, active: active } };
    case "UPDATE_VERSION":
      const updatedVersions = state.drawerVersions.versions.map((version) => {
        if (version._id === action.payload._id) return action.payload;
        return version;
      });
      return {
        drawerVersions: { ...state.drawerVersions, versions: updatedVersions },
      };
    case "DELETE_VERSION":
      const filteredVersions = state.drawerVersions.versions.filter(
        (version) => version._id !== action.payload._id
      );
      return {
        drawerVersions: { ...state.drawerVersions, versions: filteredVersions },
      };
    case "ADD_VERSION":
      const newVersions = [
        ...state.drawerVersions.versions,
        action.payload,
      ].sort((versionA, versionB) => {
        const [majorA, minorA = 0] = versionA.version.split(".").map(Number);
        const [majorB, minorB = 0] = versionB.version.split(".").map(Number);
        const compareMajor = majorB - majorA;
        const compareMinor = minorB - minorA;

        return compareMajor !== 0 ? compareMajor : compareMinor;
      });

      return {
        drawerVersions: { ...state.drawerVersions, versions: newVersions },
      };
    case "CHANGE_ACTIVE":
      return {
        drawerVersions: { ...state.drawerVersions, active: action.payload },
      };
    case "CHANGE_ACTIVE_SFDT":
      return {
        drawerVersions: {
          ...state.drawerVersions,
          active: { ...state.drawerVersions.active, sfdt: action.payload },
        },
      };
    default:
      return {
        drawerVersions: { ...state.drawerVersions },
      };
  }
};

export default versionsReducer;
