export function createTicket() {
  return {
    areaLabelId: "",
    assignee: /** @type {{ _id: string; email: string; } | null} */ (null),
    request: {
      name: "",
      description: "",
    },
    documents:
      /** @type {{ fileType: string; file: any; fileName: string; uploadType: string; agrType: null; fileSource: { label: string;value: string; }; tempKey: string; }[]} */ ([]),
  };
}
