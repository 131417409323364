import { $getHtmlContent, $getLexicalContent } from "@lexical/clipboard";
import { $getSelection, $isRangeSelection } from "lexical";
import { handleSelectionDelete } from "../../../../utils/handleSelectionDelete";
import { isClipboardEvent } from "../../utils";
import { preventEventPropagation } from "../utils/preventEventPropagation";
import { propagateEvent } from "../utils/propagateEvent";

/**
 * @param {KeyboardEvent | ClipboardEvent | null} event
 * @param {import("lexical").LexicalEditor} editor
 * @param {RedlineData} defaultRedlineData
 * @returns {boolean}
 */
export function cutCommandHandler(event, editor, defaultRedlineData) {
  const selection = $getSelection();
  // Copy existing selection into clipboard.
  if (event && isClipboardEvent(event)) {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const htmlString = $getHtmlContent(editor);
      if (htmlString !== null) {
        clipboardData.setData("text/html", htmlString);
      }

      const lexicalString = $getLexicalContent(editor);
      if (lexicalString !== null) {
        clipboardData.setData("application/x-lexical-editor", lexicalString);
      }

      if (selection) {
        const plainTextString = selection.getTextContent();
        clipboardData.setData("text/plain", plainTextString);
      }
    }
  }

  if (!$isRangeSelection(selection) || selection.isCollapsed()) {
    return propagateEvent();
  }

  // Handle deletion of text nodes from the selection.
  handleSelectionDelete(selection, defaultRedlineData);

  return preventEventPropagation(event);
}
