import { $getSelection, $isRangeSelection } from "lexical";
import { handleSelectionDelete } from "../../../../utils/handleSelectionDelete";
import {
  handleNodeCreationAtSelection,
  keyboardEventProducesCharacterValue,
} from "../../utils";
import { preventEventPropagation } from "../utils/preventEventPropagation";
import { propagateEvent } from "../utils/propagateEvent";

/**
 * @param {KeyboardEvent} event
 * @param {boolean} isEditable
 * @param {*} state
 * @param {RedlineData} defaultRedlineData
 * @returns {boolean}
 */
export function keyDownCommandHandler(
  event,
  isEditable,
  state,
  defaultRedlineData
) {
  // This is where we control if the editor can be editable or not. We cannot use the regular way
  // because that also prevents creating selections and we need that to highlight tracked changes
  // and open issues when navigating the open issues.
  if (!isEditable) return preventEventPropagation(event);

  // We only want to handle the event that is fired when a key that produces a character value is pressed.
  if (!keyboardEventProducesCharacterValue(event)) return propagateEvent();

  const selection = $getSelection();
  if (!$isRangeSelection(selection)) return propagateEvent();

  if (!selection.isCollapsed()) {
    handleSelectionDelete(selection, defaultRedlineData);
  }

  handleNodeCreationAtSelection(event.key, defaultRedlineData, state);

  return preventEventPropagation(event);
}
