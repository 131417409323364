import React, { Suspense, useRef } from "react";

const imageCache = new Set();

/**
 * @param {string} src
 */
export function useSuspenseImage(src) {
  if (!imageCache.has(src)) {
    throw new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        imageCache.add(src);
        resolve(null);
      };
    });
  }
}

/**
 * @param {{ altText: string; className: string; imageRef: React.MutableRefObject<null>; src: string; }} props
 * @returns {React.JSX.Element}
 */
export function LazyImage({ altText, className, imageRef, src }) {
  useSuspenseImage(src);

  return (
    <img
      className={className || undefined}
      src={src}
      alt={altText}
      ref={imageRef}
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  );
}

/**
 * @typedef {{ src: string; altText: string;  }} ImageComponentProps
 */

/**
 * @param {ImageComponentProps} props
 * @returns {React.JSX.Element}
 */
export default function ImageComponent({
  src,
  altText,
  // width,
  // height,
  // aspectRatio,
  // maxWidth,
  // isInline,
  // horizontalPosition,
  // _nodeKey,
  // _showCaption,
  // _caption,
  // _captionsEnabled,
  // _resizable,
}) {
  const imageRef = useRef(null);

  return (
    <Suspense fallback={null}>
      <>
        <LazyImage
          className=""
          src={src}
          altText={altText}
          imageRef={imageRef}
          // width={width}
          // height={height}
          // maxWidth={maxWidth}
          // aspectRatio={aspectRatio}
          // isInline={isInline}
          // horizontalPosition={horizontalPosition}
        />
      </>
    </Suspense>
  );
}
