import { faClone, faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  List,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import { getCanveoTier } from "../../utils/getCanveoTier";
import DialogUploadVersion from "../dialogs/DialogUploadVersion";
import { CanveoCircularProgress } from "../index";
import VersionDrawerItem from "./VersionDrawerItem";

/**
 * @typedef {*} VersionsDrawerProps
 */

/**
 * @param {VersionsDrawerProps} props
 * @returns {React.JSX.Element}
 */
export default function VersionsDrawer({
  isTemplate,
  loading,
  docID,
  isInEffect,
  handleExport,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [menuAnchor, setMenuAnchor] = useState(/** @type {* | null} */ (null));
  const [searchParams, setSearchParams] = useSearchParams();
  const openNewVersionDialog = searchParams.get("openNewVersionDialog");
  let defaultOpenNewVersionDialog = false;
  if (Boolean(openNewVersionDialog)) {
    defaultOpenNewVersionDialog = true;

    if (searchParams.size === 1) {
      setSearchParams({});
    }
  }
  const [uploadVersion, setUploadVersion] = useState(
    defaultOpenNewVersionDialog
  );
  const [isMainBody, setIsMainBody] = useState(false);

  useEffect(() => {
    if (isTemplate) return;

    const agreement = state.agrs.find(
      (/** @type {{ _id: string; }} */ agr) => agr._id === docID
    );
    const isMainBody = !agreement?.parentID;

    setIsMainBody(isMainBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docID, isTemplate]);

  const versionsOptions = state.drawerVersions.versions.map(
    (/** @type {*} */ version) => ({
      label: `Version ${version.version}`,
      author: version.creationBy.displayName,
      description: version.description,
      lastUpdated: `${DateTime.fromISO(version.lastUpdateDate).toFormat(
        "dd LLL yyyy"
      )} by ${version.lastUpdateBy.displayName}`,
      version,
    })
  );

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        p={2}
        sx={{
          position: "relative",
          backgroundColor: "white",
        }}
      >
        <Grid item>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "14px",
            }}
          >
            <FontAwesomeIcon
              icon={faClone}
              color={theme.palette.primary.main}
              size="lg"
            />
            &nbsp;&nbsp;&nbsp;Versions
          </Typography>
        </Grid>

        {!isTemplate &&
          // Allow counterparty to also upload new versions.
          // state.agrs?.[0].owner === state.org._id &&
          !isInEffect && (
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                padding: "0 8px",
              }}
              onClick={(e) => setMenuAnchor(e.currentTarget)}
              color={theme.palette.grey[800]}
            />
          )}
      </Grid>

      <Box
        sx={{
          flexGrow: 1,
          position: "relative",
          backgroundColor: "white",
          overflow: "auto",
        }}
      >
        {loading && (
          <Box
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CanveoCircularProgress />
          </Box>
        )}

        {getCanveoTier(state?.user?.email) === "experimental" && (
          <Grid container direction="column" px={2} mb={4}>
            <Grid item>
              <Typography>Version Comparison</Typography>
            </Grid>

            <Grid item mt={2}>
              <Autocomplete
                options={versionsOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="First Document for Comparison"
                    placeholder="Select first document ..."
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      "& > img": {
                        mr: 2,
                        flexShrink: 0,
                      },
                    }}
                    {...props}
                  >
                    <Grid container direction="column">
                      <Grid item>
                        <Typography
                          variant="body1"
                          fontWeight="bolder"
                          color={theme.palette.grey[800]}
                        >
                          {option.label}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.grey[500]}
                        >
                          Author: {option.author}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.grey[500]}
                        >
                          Description: {option.description}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.grey[500]}
                        >
                          Last updated: {option.lastUpdated}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              />
            </Grid>

            <Grid item mt={2}>
              <Autocomplete
                options={versionsOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Second Document for Comparison"
                    placeholder="Select second document ..."
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      "& > img": {
                        mr: 2,
                        flexShrink: 0,
                      },
                    }}
                    {...props}
                  >
                    <Grid container direction="column">
                      <Grid item>
                        <Typography
                          variant="body1"
                          fontWeight="bolder"
                          color={theme.palette.grey[800]}
                        >
                          {option.label}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.grey[500]}
                        >
                          Author: {option.author}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.grey[500]}
                        >
                          Description: {option.description}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.grey[500]}
                        >
                          Last updated: {option.lastUpdated}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              />
            </Grid>

            <Grid item mt={2}>
              <Grid container direction="row" justifyContent="right">
                <Grid item>
                  <Button variant="contained" size="small" disableElevation>
                    Compare
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <List sx={{ p: 0, px: 2 }}>
          {state.drawerVersions.versions.map((/** @type {*} */ version) => (
            <VersionDrawerItem
              key={version._id}
              item={version}
              isTemplate={isTemplate}
              isInEffect={isInEffect}
              handleExport={handleExport}
            />
          ))}
        </List>
      </Box>

      {!isTemplate && !isInEffect && (
        <Menu
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          disableScrollLock={true}
          open={!!menuAnchor}
          onClose={() => setMenuAnchor(null)}
        >
          <MenuItem
            onClick={() => {
              setUploadVersion(true);
              setMenuAnchor(null);
            }}
          >
            <ListItemText>Upload new version ...</ListItemText>
          </MenuItem>
        </Menu>
      )}

      {uploadVersion && !isTemplate && (
        <DialogUploadVersion
          open={uploadVersion}
          originVersion={state.drawerVersions.versions[0]}
          isOwner={state.agrs[0].owner === state.org._id}
          canSubmitSigned={state.agrs[0].avOwners.every(
            (/** @type {string} */ o) => o === state.org._id
          )}
          isMainBody={isMainBody}
          versionType={state.drawerVersions.versions[0].versionType}
          handleClose={() => setUploadVersion(false)}
          isTemplate={isTemplate}
        />
      )}
    </>
  );
}
