import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { globalStore } from "../state/store";

/**
 * @param {*} props
 */
export default function useGoogleDrive({ next }) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    handleTokenFromQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createGoogleAuthLink = async () => {
    try {
      axios
        .post(`${state.settings.api}gdrive/createAuthLink`)
        .then((response) => {
          window.location.href = response.data.url;
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      // @ts-ignore
      throw new Error("Issue with Login", error.message);
    }
  };

  const handleTokenFromQueryParams = () => {
    const query = new URLSearchParams(window.location.search);
    const accessToken = query.get("accessToken");
    const refreshToken = query.get("refreshToken");
    const expirationDate = newExpirationDate();
    if (accessToken && refreshToken) {
      if (!!state.org?.integrations?.gdrive === false) {
        next("gdrive", { accessToken, refreshToken, expirationDate });
      }
      setIsLoggedIn(true);
    }
  };

  const newExpirationDate = () => {
    const expiration = new Date();
    expiration.setHours(expiration.getHours() + 1);
    return expiration;
  };

  const signOut = useCallback(() => {
    setIsLoggedIn(false);
    sessionStorage.clear();
  }, []);

  const getToken = async () => {
    if (tokenExpired()) {
      const refreshtoken = sessionStorage.getItem("refreshToken");
      const token = await getValidTokenFromServer(refreshtoken);
      sessionStorage.setItem("accessToken", token.accessToken);
      // @ts-ignore
      sessionStorage.setItem("expirationDate", newExpirationDate());
      return token.accessToken;
    } else {
      return sessionStorage.getItem("accessToken");
    }
  };

  const tokenExpired = () => {
    const now = Date.now();

    const expirationDate = sessionStorage.getItem("expirationDate");
    // @ts-ignore
    const expDate = new Date(expirationDate);
    return now > expDate.getTime(); // valid token
  };

  const getValidTokenFromServer = async (
    /** @type {string | null} */ refreshToken
  ) => {
    // get new token from server with refresh token
    try {
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/getValidToken`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refreshToken: refreshToken,
          }),
          credentials:
            process.env.NODE_ENV === "production" ? "include" : undefined,
        }
      );
      const token = await request.json();
      return token;
    } catch (error) {
      // @ts-ignore
      throw new Error("Issue getting new token", error.message);
    }
  };

  return {
    isLoggedIn,
    createGoogleAuthLink,
    handleTokenFromQueryParams,
    newExpirationDate,
    getToken,
    signOut,
  };
}
