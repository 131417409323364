import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Button, Grid, Snackbar } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CanveoCircularProgress } from "./components";
import ApplicationRoutes from "./routes";
import { initializeUser } from "./state/initializeUser";
import { globalStore } from "./state/store";
import theme from "./theme/theme";

function Main() {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [actResult, setActResult] = React.useState(
    /** @type {string | null } */ (null)
  );
  const [nextButton, setNextButton] = React.useState({
    text: "Login to Canveo",
    route: "/login",
  });

  useEffect(
    () => {
      if (state.user._id === undefined || state.user.email === undefined) {
        if (
          location.pathname.startsWith("/loginc/") &&
          location.pathname.split("/").length === 5
        ) {
          // SCENARIO: The user is accessing through a custom link
          let uid = location.pathname.split("/")[2];
          let aid = location.pathname.split("/")[3];
          let cl = location.pathname.split("/")[4];
          axios
            .post(state.settings.api + "auth/customlink", {
              uid: uid,
              aid: aid,
              cl: cl,
            })
            .then((res) => {
              initializeUser(dispatch, res.data.data); // Initialize based on custom link
              navigate("/agreement/" + aid);
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              navigate("/");
            });
        } else {
          // You're re-initializing OR not logged in

          axios
            .get(state.settings.api + "auth/loggedinuser") // Check if the user is already logged in (ie. has a back-end Cookie)
            .then((res) => {
              if (!res.data.success) {
                navigate("/");
              }
              const isLoggedIn =
                res.data?.data?.user?._id && res.data?.data?.user?.email;

              if (
                location.pathname.startsWith("/act/") &&
                location.pathname.split("/").length === 8
              ) {
                // SCENARIO: User clicked on an "ACT" link (e.g. to approve)

                let uid = location.pathname.split("/")[2];
                let isTemplate = location.pathname.split("/")[3] === "t";
                let docid = location.pathname.split("/")[4];
                let wfid = location.pathname.split("/")[5];
                let lid = location.pathname.split("/")[6];
                let response = location.pathname.split("/")[7];
                if (
                  Boolean(uid) &&
                  Boolean(docid) &&
                  Boolean(wfid) &&
                  Boolean(lid) &&
                  Boolean(response) &&
                  uid.length > 10 &&
                  uid.length < 30 &&
                  docid.length > 10 &&
                  docid.length < 30 &&
                  wfid.length > 10 &&
                  wfid.length < 30 &&
                  lid.length > 2 &&
                  lid.length < 30 &&
                  ["a", "r"].includes(response)
                ) {
                  axios
                    .put(state.settings.api + "workflow/actlink/" + wfid, {
                      uid,
                      docid,
                      isTemplate,
                      lid,
                      response,
                    })
                    .then((resAct) => {
                      let nb = nextButton;
                      if (isLoggedIn) {
                        initializeUser(dispatch, res.data.data); // Re-initialize
                        nb = {
                          text:
                            "View " + (isTemplate ? "Template" : "Agreement"),
                          route:
                            "/" +
                            (isTemplate ? "templates" : "agreement") +
                            "/" +
                            docid,
                        };
                      }
                      setActResult("complete-" + response);
                      setNextButton(nb);
                      setLoading(false);
                    })
                    .catch((err) => {
                      setActResult("fail");
                      setLoading(false);
                    });
                } else {
                  setActResult("fail");
                  setLoading(false);
                }
              } else if (isLoggedIn) {
                // SCENARIO: The user is already logged in
                // TODO: potentially reroute for loginp
                initializeUser(dispatch, res.data.data); // Re-initialize
                if (location.pathname === "/") {
                  navigate("/dashboard");
                } // Route to Dashboard
                setLoading(false);
              } else {
                if (!location.pathname.startsWith("/createpassword/")) {
                  navigate({
                    pathname: "/",
                    search:
                      window.location.pathname && window.location.search
                        ? `?redirect=${window.location.pathname}/${window.location.search}`
                        : undefined,
                  });
                }
                setLoading(false);
              }
            })
            .catch((err) => {
              navigate("/");
            }); // Unable to pull whether the user is logged in or not
        }
      }
    },
    // This use effect should only re-run whenever the logged in user email changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.user.email]
  );

  return (
    <div
      // We want to disable the context menu (menu that pops up when you right click) in production.
      onContextMenu={(event) => {
        if (process.env.NODE_ENV !== "production") return;
        event.preventDefault();
      }}
    >
      {loading ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          <Grid item sx={{ mb: 1 }}>
            <CanveoCircularProgress />
          </Grid>
        </Grid>
      ) : actResult ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          <Grid item sx={{ mb: 2, textAlign: "center" }}>
            <FontAwesomeIcon
              style={{
                fontSize: "80px",
                color: ["complete-a"].includes(actResult)
                  ? theme.palette.success.main
                  : ["complete-r"].includes(actResult)
                  ? theme.palette.error.main
                  : theme.palette.warning.main,
              }}
              icon={
                ["complete-a"].includes(actResult)
                  ? faCheckCircle
                  : ["complete-r"].includes(actResult)
                  ? faTimesCircle
                  : faExclamationCircle
              }
            />
            <Box sx={{ mt: 4, mb: 3 }}>
              {actResult.startsWith("complete-")
                ? "Your response has been recorded."
                : "Unable to record your response."}
            </Box>
            <Box>
              <Button onClick={() => window.location.assign(nextButton.route)}>
                {nextButton.text}
              </Button>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <ApplicationRoutes />
      )}

      <Snackbar
        open={Boolean(state.snackbar)}
        autoHideDuration={5000}
        onClose={() => dispatch({ type: "NEW_SNACKBAR", payload: null })}
      >
        {state.snackbar ? (
          <Alert variant="filled" severity={state.snackbar?.severity ?? "info"}>
            {state.snackbar?.message}
          </Alert>
        ) : (
          <div></div>
        )}
      </Snackbar>
    </div>
  );
}

export default Main;
