import { $createTextNode, ElementNode } from "lexical";

/**
 * @typedef {*} TocElementNodeMetadata
 */

/**
 * @typedef {ReturnType<TocElementNode["exportJSON"]>} SerializedTocElementNode
 */

/**
 * Stores information regarding an element of a Table of Contents. An element can be a
 * heading or a line that refers to a chapter.
 */
export class TocElementNode extends ElementNode {
  /** @private @type {TocElementNodeMetadata} Object containing Syncfusion TOC element metadata. */ __block;

  /**
   * @param {TocElementNodeMetadata} block
   * @param {string | undefined} [key]
   */
  constructor(block, key) {
    super(key);
    this.__block = block;
  }

  /**
   * @returns {'tocElement'}
   */
  static getType() {
    return "tocElement";
  }

  /**
   * @param {TocElementNode} node
   * @returns {TocElementNode}
   */
  static clone(node) {
    const clonedTocElementNode = new TocElementNode(node.__block, node.__key);
    return clonedTocElementNode;
  }

  /**
   * @param {SerializedTocElementNode} serializedNode
   * @returns {TocElementNode}
   */
  static importJSON(serializedNode) {
    const node = $createTocElementNode(serializedNode);
    return node;
  }

  exportJSON() {
    const serializedTocElementNode = {
      ...super.exportJSON(),
      block: this.getBlock(),
      type: TocElementNode.getType(),
      version: 1,
    };
    return serializedTocElementNode;
  }

  /**
   * @param {import("lexical").LexicalEditor} editor
   * @returns {import("lexical").DOMExportOutput}
   */
  exportDOM(editor) {
    const domExportOutput = super.exportDOM(editor);
    return domExportOutput;
  }

  /**
   * @returns {HTMLElement}
   */
  createDOM() {
    const tocElementHtmlElement = document.createElement("div");

    // If the child of the previous clause is not a a TocElement that means
    // the current TocElement is the first one of the Table of Contents. When
    // that happens we display a message.
    if (
      !$isTocElementNode(
        this?.getParent()?.getPreviousSibling()?.getFirstChild()
      )
    ) {
      tocElementHtmlElement.textContent =
        "[Table of Contents - export to view]";
      tocElementHtmlElement.classList.add("toc-first-element");
    } else {
      tocElementHtmlElement.classList.add("toc-element");
    }

    return tocElementHtmlElement;
  }

  /**
   * @param {TocElementNode} _previousNode
   * @param {HTMLElement} _htmlElement
   * @returns {boolean}
   */
  updateDOM(_previousNode, _htmlElement) {
    // Returning false tells Lexical that this node does not need its
    // DOM element replaced with a new copy from createDOM.
    return false;
  }

  /**
   * @returns {TocElementNodeMetadata}
   */
  getBlock() {
    const self = this.getLatest();
    return self.__block;
  }
}

/**
 * @param {{ block : TocElementNodeMetadata}} props
 * @returns {TocElementNode}
 */
export function $createTocElementNode({ block }) {
  const tocElementNode = new TocElementNode(block);
  return tocElementNode;
}

/**
 * @param {import("lexical").LexicalNode | null | undefined} node
 * @return {node is TocElementNode}
 */
export function $isTocElementNode(node) {
  const isInstanceOfTocElementNode = node instanceof TocElementNode;
  return isInstanceOfTocElementNode;
}
