import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import {
  defaultPartyRoles,
  durationUnits,
  currencyCodes,
} from "../../../../../MergeFieldMenu/constants";
import {
  partyInformationFields,
  operators,
} from "../../../../../MergeFieldMenu/dialogs/NewConditionDialog/constants";
import {
  getMergeFieldDefaultValue,
  mergeFieldValueIsValid,
  getMergeFieldDisplayValue,
} from "../../../../../MergeFieldMenu/utils";

const filter = createFilterOptions();

/**
 * @typedef {object} PartyInformationBaseValueTypeProps
 * @property {string} docId
 * @property {boolean} isList
 * @property {import("../../../constants").ListOption[]} listOptions,
 * @property {import("../condition").Condition | null} condition
 * @property {import("../../../../editor/nodes/MarkNode").MergeField[]} mergeFields
 * @property {React.Dispatch<React.SetStateAction<import("../../../../editor/nodes/MarkNode").MergeField[]>>} setMergeFields
 * @property {import("../../../../editor/nodes/MarkNode").MergeField | null} baseValue
 * @property {React.Dispatch<React.SetStateAction<import("../../../../editor/nodes/MarkNode").MergeField  | null>>} setBaseValue
 * @property {{label: string; value: string;} | null} operator
 * @property {React.Dispatch<React.SetStateAction<{label: string; value: string;} | null>>} setOperator
 * @property {import("../../../../editor/nodes/MarkNode").MergeFieldValue} mergeFieldValue
 * @property {React.Dispatch<React.SetStateAction<import("../../../../editor/nodes/MarkNode").MergeFieldValue>>} setMergeFieldValue
 * @property {string[] | import("../../../../editor/nodes/MarkNode").MergeFieldValue} comparisonValue
 * @property {React.Dispatch<React.SetStateAction<string[] | import("../../../../editor/nodes/MarkNode").MergeFieldValue>>} setComparisonValue
 * /

/**
 * @param {PartyInformationBaseValueTypeProps} props
 */
export default function PartyInformationBaseValueType({
  isList,
  listOptions,
  condition,
  baseValue,
  setBaseValue,
  operator,
  setOperator,
  mergeFieldValue,
  setMergeFieldValue,
  comparisonValue,
  setComparisonValue,
}) {
  const [partyRole, setPartyRole] = useState(baseValue?.partyRole || "");
  const [partyRoles, setPartyRoles] = useState([...defaultPartyRoles]);

  if (baseValue?.partyRole) {
    if (!partyRoles.find((x) => x.value === partyRole)) {
      partyRoles.push({
        label: partyRole,
        value: partyRole,
        type: "custom",
      });
    }
  }

  return (
    <>
      <Grid container direction="row" justifyContent={"center"}>
        <Autocomplete
          openOnFocus={true}
          sx={{ width: "286px" }}
          options={partyRoles}
          autoHighlight
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                autoFocus={!condition}
                label="Party Role"
                placeholder="Select Applicable Party ..."
                variant="outlined"
              />
            </>
          )}
          filterOptions={(options, params) => {
            let filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value.
            const isExisting = options.some(
              (option) => inputValue === option.label
            );
            if (inputValue !== "" && !isExisting) {
              filtered = filtered.filter((option) => option.type !== "action");
              filtered.push({
                label: `Create "${inputValue}" as New Party Role`,
                value: inputValue,
                type: "action",
              });
            }

            return filtered;
          }}
          renderOption={(props, option) => {
            if (option.type === "action") {
              return (
                <Box component="li" {...props}>
                  <div
                    style={{
                      marginLeft: "13px",
                      marginRight: "13px",
                    }}
                  >
                    <b>{option.label}</b>
                  </div>
                </Box>
              );
            }
            return (
              <Box component="li" {...props}>
                <div
                  style={{
                    marginLeft: "13px",
                    marginRight: "13px",
                  }}
                >
                  {option.label}
                </div>
              </Box>
            );
          }}
          onChange={(_, option) => {
            if (option?.value) {
              setPartyRole(option.value);
              if (option.type === "action") {
                option.label = option.value;
                option.type = "custom";
              }

              setPartyRoles([...partyRoles, option]);

              if (baseValue?.partyRole) {
                const newBaseValue = {
                  ...baseValue,
                  partyRole: option.value,
                };
                setBaseValue(newBaseValue);
              }
            } else {
              setPartyRole("");
              if (baseValue?.partyRole) {
                setBaseValue({ ...baseValue, partyRole: "" });
              }
            }
          }}
          value={
            (baseValue?.partyRole &&
              partyRoles.find((x) => x.value === baseValue?.partyRole)) ||
            undefined
          }
        />
      </Grid>

      <Grid container direction="row">
        <br />
      </Grid>

      {partyRole && (
        <>
          <Grid container direction="row" justifyContent={"center"}>
            <Autocomplete
              openOnFocus={!condition}
              sx={{ width: "286px" }}
              options={partyInformationFields.map((mf) => ({
                label: mf.name,
                value: mf,
              }))}
              autoHighlight
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              value={
                partyInformationFields
                  .map((mf) => ({
                    label: mf.name,
                    value: mf,
                  }))
                  .find((x) => x.value._id === baseValue?._id) || null
              }
              onChange={(_, option) => {
                if (option) {
                  setBaseValue({ ...option.value, partyRole });
                  setComparisonValue(getMergeFieldDefaultValue("freeText"));
                } else {
                  setBaseValue(null);
                  setComparisonValue([]);
                }

                setOperator(null);
              }}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    autoFocus={!condition}
                    label="Party Information Field"
                    placeholder="Search party information field ..."
                    variant="outlined"
                  />
                </>
              )}
            />
          </Grid>

          <Grid container direction="row">
            <br />
          </Grid>

          {baseValue && (
            <>
              <Grid container direction="row" justifyContent={"center"}>
                <Autocomplete
                  sx={{ width: "286px" }}
                  options={operators["freeText"]}
                  autoHighlight
                  openOnFocus={!condition}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  value={
                    operators["freeText"].find(
                      (x) => x.value === operator?.value
                    ) || null
                  }
                  onChange={(_, option) => {
                    if (option) {
                      setOperator(option);
                    } else {
                      setOperator(null);
                    }
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        autoFocus={!condition}
                        label="Operator"
                        placeholder="Select operator ..."
                        variant="outlined"
                      />
                    </>
                  )}
                />
              </Grid>

              <Grid container direction="row">
                <br />
              </Grid>

              {operator && (
                <>
                  {operator.value !== "isKnown" &&
                    operator.value !== "isUnknown" && (
                      <>
                        <Grid
                          container
                          direction="row"
                          justifyContent={"center"}
                        >
                          <Autocomplete
                            multiple
                            openOnFocus={!condition}
                            getOptionDisabled={(option) =>
                              option === "Type to add a value to this filter"
                            }
                            value={
                              comparisonValue instanceof Array
                                ? comparisonValue
                                : []
                            }
                            // @ts-ignore
                            onChange={(
                              _,
                              /** @type {string[] | {inputValue: string; title: string}[]} */ values
                            ) => {
                              if (values.length) {
                                const mappedValues = values.map((value) => {
                                  if (typeof value === "string") {
                                    return value;
                                  }

                                  return value.inputValue;
                                });

                                // We do not allow duplicate values so we use a Set to ensure that.
                                setComparisonValue([
                                  ...Array.from(new Set(mappedValues)),
                                ]);
                              } else {
                                setComparisonValue([]);
                              }
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              const { inputValue } = params;
                              // Suggest the creation of a new value
                              const isExisting = options.some(
                                (option) => inputValue === option
                              );
                              if (inputValue !== "" && !isExisting) {
                                filtered.push({
                                  inputValue,
                                  title: `Press enter to add "${inputValue}"`,
                                });
                              }

                              return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            options={[]}
                            getOptionLabel={(
                              /** @type {string | {inputValue: string; title: string}} */ option
                            ) => {
                              if (typeof option === "string") {
                                return option;
                              }

                              return option.inputValue;
                            }}
                            renderOption={(
                              props,
                              /** @type {string | {title: string}} */ option
                            ) => {
                              if (typeof option === "string") {
                                return <li {...props}>{option}</li>;
                              }

                              return <li {...props}>{option.title}</li>;
                            }}
                            sx={{ width: "286px" }}
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Comparison Value"
                                variant="outlined"
                                placeholder="Enter comparison value"
                                autoFocus={!condition}
                              />
                            )}
                          />
                        </Grid>

                        <Grid container direction="row">
                          <br />
                        </Grid>
                      </>
                    )}

                  <Grid container direction="row" justifyContent={"center"}>
                    <b>Then the value should be ...</b>
                  </Grid>

                  <Grid container direction="row">
                    <br />
                  </Grid>

                  {isList ? (
                    <>
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"center"}
                      >
                        <Autocomplete
                          openOnFocus={!condition}
                          sx={{ width: "286px" }}
                          options={listOptions.map((x) => x.mergeFieldValue)}
                          value={
                            mergeFieldValueIsValid(mergeFieldValue)
                              ? mergeFieldValue
                              : null
                          }
                          getOptionLabel={(option) => {
                            return getMergeFieldDisplayValue(option);
                          }}
                          autoHighlight
                          onChange={(_, option) => {
                            if (option) {
                              setMergeFieldValue(option);
                            } else {
                              setComparisonValue({
                                type: "freeText",
                                value: "",
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                label={"Merge Field Value"}
                                placeholder={"Select Merge Field Value ..."}
                                variant="outlined"
                              />
                            </>
                          )}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      {mergeFieldValue.type === "freeText" && (
                        <>
                          <Grid
                            container
                            direction={"row"}
                            justifyContent={"center"}
                          >
                            <>
                              <TextField
                                placeholder={"Enter Merge Field value"}
                                label={"Merge Field Value"}
                                value={mergeFieldValue.value}
                                sx={{ width: "286px" }}
                                variant="outlined"
                                onChange={(event) => {
                                  setMergeFieldValue({
                                    ...mergeFieldValue,
                                    type: "freeText",
                                    value: event.target.value,
                                  });
                                }}
                              />
                            </>
                          </Grid>
                        </>
                      )}

                      {mergeFieldValue.type === "date" && (
                        <>
                          <Grid
                            container
                            direction={"row"}
                            justifyContent={"center"}
                          >
                            <TextField
                              type="date"
                              placeholder={"Enter Merge Field value"}
                              label={"Merge Field Value"}
                              value={
                                mergeFieldValue.value
                                  ? format(
                                      new Date(mergeFieldValue.value),
                                      "yyyy-MM-dd"
                                    )
                                  : ""
                              }
                              sx={{ width: "286px" }}
                              variant="outlined"
                              onChange={(event) => {
                                const date = new Date(event.target.value);
                                if (!isNaN(date.getDate())) {
                                  const isoStringDate = date.toISOString();
                                  setMergeFieldValue({
                                    ...mergeFieldValue,
                                    value: isoStringDate,
                                  });
                                } else {
                                  setMergeFieldValue({
                                    ...mergeFieldValue,
                                    value: "",
                                  });
                                }
                              }}
                            />
                          </Grid>
                        </>
                      )}

                      {mergeFieldValue.type === "duration" && (
                        <>
                          <Grid
                            container
                            direction={"row"}
                            justifyContent={"center"}
                          >
                            <Grid item xs={3} sx={{ paddingLeft: "20px" }}>
                              <TextField
                                type="number"
                                placeholder={"Enter Merge Field value"}
                                label={"Merge Field Value"}
                                value={mergeFieldValue.durationValue}
                                variant="outlined"
                                onChange={(event) => {
                                  const number = Number(event.target.value);
                                  if (number) {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      durationValue: event.target.value,
                                    });
                                  } else {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      durationValue: "",
                                    });
                                  }
                                }}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={4}
                              sx={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}
                            >
                              <Autocomplete
                                options={durationUnits}
                                autoHighlight
                                getOptionLabel={(option) => option.label}
                                value={
                                  durationUnits.find(
                                    (x) =>
                                      x.value ===
                                      mergeFieldValue.durationUnit.value
                                  ) || durationUnits[2]
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option.value === value.value
                                }
                                onChange={(_, option) => {
                                  if (option) {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      durationUnit: option,
                                    });
                                  } else {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      durationUnit: {
                                        label: "",
                                        value: "",
                                      },
                                    });
                                  }
                                }}
                                renderInput={(params) => (
                                  <>
                                    <TextField
                                      {...params}
                                      label="Duration Unit"
                                      placeholder="Select Duration Unit ..."
                                      variant="outlined"
                                    />
                                  </>
                                )}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {mergeFieldValue.type === "number" && (
                        <>
                          <Grid
                            container
                            direction={"row"}
                            justifyContent={"center"}
                          >
                            <TextField
                              type="number"
                              placeholder={"Enter Merge Field value"}
                              label={"Merge Field Value"}
                              value={mergeFieldValue.value}
                              sx={{ width: "286px" }}
                              variant="outlined"
                              onChange={(event) => {
                                const number = Number(event.target.value);
                                if (number) {
                                  setMergeFieldValue({
                                    ...mergeFieldValue,
                                    value: event.target.value,
                                  });
                                } else {
                                  setMergeFieldValue({
                                    ...mergeFieldValue,
                                    value: "",
                                  });
                                }
                              }}
                            />
                          </Grid>
                        </>
                      )}

                      {mergeFieldValue.type === "currency" && (
                        <>
                          <Grid
                            container
                            direction={"row"}
                            justifyContent={"center"}
                          >
                            <Grid item xs={4} sx={{ paddingLeft: "20px" }}>
                              <Autocomplete
                                options={currencyCodes}
                                autoHighlight
                                openOnFocus={!condition}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) =>
                                  option.value === value.value
                                }
                                value={
                                  currencyCodes.find(
                                    (x) =>
                                      x.value ===
                                      mergeFieldValue.currencyUnit.value
                                  ) || null
                                }
                                onChange={(_, option) => {
                                  if (option) {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      currencyUnit: option,
                                    });
                                  } else {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      currencyUnit: {
                                        label: "",
                                        value: "",
                                      },
                                    });
                                  }
                                }}
                                renderInput={(params) => (
                                  <>
                                    <TextField
                                      {...params}
                                      label="Currency Unit"
                                      placeholder="Select Currency Unit ..."
                                      variant="outlined"
                                      autoFocus={!condition}
                                    />
                                  </>
                                )}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={3}
                              sx={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}
                            >
                              <TextField
                                type="number"
                                placeholder={"Enter Merge Field value"}
                                label={"Merge Field Value"}
                                value={mergeFieldValue.currencyValue}
                                variant="outlined"
                                onChange={(event) => {
                                  const number = Number(event.target.value);
                                  if (number) {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      currencyValue: event.target.value,
                                    });
                                  } else {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      currencyValue: "",
                                    });
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {mergeFieldValue.type === "percentage" && (
                        <>
                          <Grid
                            container
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Grid item xs={10} sx={{ paddingLeft: "20px" }}>
                              <TextField
                                type="number"
                                placeholder={"Enter Merge Field value"}
                                label={"Merge Field Value"}
                                value={mergeFieldValue.value}
                                variant="outlined"
                                sx={{ width: "250px" }}
                                onChange={(event) => {
                                  const number = Number(event.target.value);
                                  if (number) {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      value: event.target.value,
                                    });
                                  } else {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      value: "",
                                    });
                                  }
                                }}
                              />
                            </Grid>

                            <Grid item xs={2} sx={{ paddingLeft: "10px" }}>
                              <div>{"%"}</div>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  )}

                  <Grid container direction="row">
                    <br />
                  </Grid>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
