import { Box, Button, Grid, Typography, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { globalStore } from "../../../state/store";
import CreateUserSection from "./CreateUserSection";

const UserBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: theme.palette.text.primary,

  "& .user-type": {
    color: theme.palette.grey[500],
  },

  "& .user-actions": {
    display: "flex",
    gap: "10px",
  },
}));

/**
 * @typedef {*} UserManagementSectionProps
 */

/**
 * @param {UserManagementSectionProps} props
 * @returns {React.JSX.Element}
 */
export default function UserManagementSection({
  collaborators,
  signers,
  owner,
  handleEditUserForm,
  handleRevokeUser,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [userEdit, setUserEdit] = useState(
    /** @type {* | undefined} */ (undefined)
  );

  /**
   * @param {*} entity
   */
  const getPartyUsers = (entity) => {
    const partySigners = signers
      .filter((/** @type {{ orgID: string; }} */ s) => s.orgID === entity.orgID)
      .map((/** @type {*} */ s) => ({ ...s, type: "signer" }));

    const partyCollaborators = collaborators.filter(
      (/** @type {*} */ c) =>
        !partySigners.find(
          (/** @type {{ _id: string; }} */ s) => s._id === c._id
        ) && c.orgID === entity.orgID
    );

    return [...partyCollaborators, ...partySigners];
  };

  /**
   *
   * @param {*} data
   */
  const handleSubmitUserForm = (data) => {
    const updateUser = {
      ...userEdit,
      ...data,
      displayName: `${data.firstName} ${data.lastName}`,
    };
    const success = handleEditUserForm(updateUser);
    if (success) setUserEdit(null);
  };

  useEffect(() => {
    if (state.sendFlow?.removeUser?.submit) {
      handleRemoveUser(state.sendFlow?.removeUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sendFlow?.removeUser?.submit]);

  /**
   * @param {*} user
   */
  const initiateRemoveUser = (user) => {
    dispatch({ type: "SEND_FLOW_REMOVE_USER", payload: user });
  };

  /**
   * @param {*} user
   */
  const handleRemoveUser = (user) => {
    handleRevokeUser(user);
    dispatch({ type: "SEND_FLOW_REMOVE_USER", payload: null });
  };

  /**
   * @param {*} party
   * @param {*} partySize
   */
  const isRemoveDisabled = (party, partySize) => {
    if (state.org._id === owner) return false;
    if (party.orgID !== state.org._id) return true;

    return partySize === 1;
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
      spacing={2}
      sx={{ mt: 2, flexWrap: "nowrap" }}
    >
      {userEdit ? (
        <Grid item sx={{ margin: "0 auto" }}>
          <CreateUserSection
            handleSubmit={handleSubmitUserForm}
            user={userEdit}
          />
        </Grid>
      ) : state.sendFlow?.removeUser ? (
        <Grid item sx={{ textAlign: "center" }}>
          <Typography sx={{ fontWeight: "600", mb: 3 }} align="center">
            Are you sure you want to remove{" "}
            {state.sendFlow.removeUser.displayName} as a collaborator to this
            agreement?
          </Typography>
        </Grid>
      ) : (
        state.parties.map((/** @type {*} */ party) => (
          <Grid
            container
            direction="column"
            item
            alignItems="flex-start"
            xs={12}
            sx={{ width: "100%", gap: "15px" }}
            key={party._id}
            pb={2}
          >
            <Typography sx={{ fontWeight: "600" }}>
              {party.myClient ? `${party.shortName} (Client)` : party.shortName}
            </Typography>

            {party.orgID === owner && (
              <Typography variant="subtitle2" color="textSecondary">
                (Note that other {party.shortName} users might still be able to
                access the agreement based on their access privileges)
              </Typography>
            )}

            {getPartyUsers(party).map((user, _, users) => (
              <UserBox key={user._id}>
                <span>
                  {user.displayName}&nbsp;&nbsp;
                  {user.type && (
                    <span className="user-type"> ({user.type})</span>
                  )}
                </span>

                <Box className="user-actions">
                  {party.orgID !== owner &&
                    (party.orgID === state.org._id ||
                      state.org._id === owner) && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setUserEdit(user)}
                      >
                        Edit
                      </Button>
                    )}

                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => initiateRemoveUser(user)}
                    disabled={isRemoveDisabled(party, users.length)}
                  >
                    Remove
                  </Button>
                </Box>
              </UserBox>
            ))}
          </Grid>
        ))
      )}
    </Grid>
  );
}
