const selectedOpenIssueReducer = (state, action) => {
  switch (action.type) {
    case "NEW_OPEN_ISSUE_SELECTION":
      return { selectedOpenIssue: action.payload };
    default:
      return { selectedOpenIssue: state.selectedOpenIssue };
  }
};

export default selectedOpenIssueReducer;
