import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import LabelList from "../../components/LabelList";
import DialogLabel from "../../components/dialogs/DialogLabel";
import useLabelService from "../../hooks/useLabelService";

export default function Labels() {
  const [selectedLabel, setSelectedLabel] = useState(
    /** @type {* | null} */ (null)
  );
  const [selectedType, setSelectedType] = useState(
    /** @type {* | null} */ (null)
  );
  const { labelTypes } = useLabelService(true);

  const handleAddLabel = (/** @type {*} */ type) => {
    setSelectedType(type);
  };

  const handleEditLabel = (/** @type {*} */ label) => {
    setSelectedLabel(label);
  };

  const closeDialog = () => {
    setSelectedType(null);
    setSelectedLabel(null);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Typography variant="h4">Manage Labels</Typography>
      </Grid>

      <Grid item sx={{ mt: 2 }}>
        <Typography variant="body1" textAlign={"center"}>
          Apply labels to your agreements and keep your contracts organized
          {/* KEEP FOR LATER Labels can be applied to agreements, parties, clauses, and more,
          helping you to organize your contracts */}
        </Typography>
      </Grid>

      {labelTypes.map((type, index) => (
        <Grid
          item
          key={index}
          sx={{
            width: "100%",
          }}
        >
          <LabelList
            type={type}
            handleAddLabel={handleAddLabel}
            handleEditLabel={handleEditLabel}
          />
        </Grid>
      ))}

      <DialogLabel
        open={!!selectedType || !!selectedLabel}
        type={selectedType}
        label={selectedLabel}
        options={labelTypes}
        handleClose={closeDialog}
        handleCreate={() => {}}
      />
    </Grid>
  );
}
