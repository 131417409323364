export const NAVIGATE_TO_CLAUSE_TYPES = "NAVIGATE_TO_CLAUSE_TYPES";

/**
 * @param {*} state
 * @param {{ type: string; payload: * }} action
 */
export default function playbookEventsReducer(state, action) {
  switch (action.type) {
    case NAVIGATE_TO_CLAUSE_TYPES: {
      const playbookEvents = { ...action.payload };
      return { playbookEvents };
    }

    default: {
      return { playbookEvents: state.playbookEvents };
    }
  }
}
