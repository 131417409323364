import { Box, Grid, useMediaQuery } from "@mui/material";
import React from "react";
import theme from "../../../theme/theme";
import { SelectUserForOrg } from "../../index";

/**
 * @typedef {*} SelectSignerSectionProps
 */

/**
 * @param {SelectSignerSectionProps} props
 * @returns {React.JSX.Element}
 */
export default function SelectSignerSection({
  orgID,
  users,
  owner,
  hiddenUsers,
  handleCreateUser,
  handleSelection,
}) {
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const selectUser = (/** @type {{ _id: string; }} */ user) => {
    if (!user._id) return handleCreateUser();

    handleSelection(user);
  };

  return (
    <Box sx={{ px: isSmUp ? 3 : 0 }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <SelectUserForOrg
          orgID={orgID}
          cpUsers={users}
          handleSelectUser={selectUser}
          hiddenUsers={hiddenUsers}
          userSelected={null}
          canCreate={orgID !== owner}
        />
      </Grid>
    </Box>
  );
}
