import React, { useContext, useEffect } from "react";
import {
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { randomString as generateRandomString } from "../utils";
import { UserForm } from "./forms";
import { globalStore } from "../state/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faTriangleExclamation,
  faUserSlash,
} from "@fortawesome/pro-solid-svg-icons";
import theme from "../theme/theme";
import axios from "axios";

// type: "newusr" => Setting up a new User for an Org (Admin Module)
// type: "updusr" => Updating an existing User for an Org (Admin Module)

export default function DialogUser(props) {
  const [state, dispatch] = useContext(globalStore);
  const [loading, setLoading] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(null);
  const [confirmDeactivate, setConfirmDeactivate] = React.useState(false);
  const [stagedUser, setStagedUser] = React.useState(null);

  const uid = state.user._id;
  const orgID = state.org._id;
  const orgName = state.org.shortName;
  const reInitalize = () => {
    setLoading(false);
    setConfirmDeactivate(false);
    setStagedUser(null);
    setErrMsg(null);
  };

  const closeDialog = (snack, newUser) => {
    props.closeDialog(snack, newUser);
    reInitalize();
  };

  const handleSubmit = (child) => {
    setLoading(true);
    setErrMsg(null);
    const { email, firstName, lastName, phone, readOnly, role, title } = child;

    let newUser = ["updusr"].includes(props.type) ? props.userToUpdate : child;

    if (child === "deactivate") {
      // todo : deleted users need to be anonymized
      newUser.active = false;
      newUser.deletedBy = uid;
      newUser.deletedDate = new Date().toISOString();
    } else if (["updusr"].includes(props.type)) {
      // Define or Update the User characteristics
      Object.keys(child).forEach((k) => {
        if (newUser[k] === child[k]) {
          console.log("delete ", k);
          delete child[k];
        } else if (k === "role") {
          child[k] = child[k]._id;
        }
      });
    }

    if (["newusr"].includes(props.type)) {
      // Set additional fields for a completely new user
      //newUser._id = generateRandomString(20);
      newUser.role = newUser.role._id;
      newUser.displayName = firstName + " " + lastName;
      newUser.orgID = orgID;
      newUser.password = generateRandomString(20);
      newUser.photoURL = null;
      newUser.creationBy = uid;
      newUser.creationDate = new Date().toISOString();
      newUser.active = true;
      // TODO: Check that User Email not yet exists in the DB!
      // TODO: Create notification
      axios
        .post(state.settings.api + "user", { user: newUser })
        .then((resUser) => {
          if (resUser.data.success) {
            // Add newly created entity to the reducer

            axios.post(state.settings.api + "mail/welcomeuser", {
              recpEmail: resUser.data.data.email,
              orgShortName: orgName,
              randomPWString: newUser.password,
            });

            closeDialog("snackUserCreated", resUser.data.data);
          } else {
            setErrMsg(
              "An error occured while creating the user - refresh your browser"
            );
            setLoading(false);
          }
        })
        .catch((err) => {
          setErrMsg(err.response.data.message);
          setLoading(false);
        });
    } else if (["updusr"].includes(props.type)) {
      axios
        .put(
          state.settings.api +
            "user/" +
            (child === "deactivate" ? "delete/" : "") +
            props.userToUpdate._id,
          { user: child }
        )
        .then((resUser) => {
          if (resUser.data.success) {
            // Add newly created entity to the reducer
            closeDialog(
              child === "deactivate" ? "snackUserDeleted" : "snackUserUpdated",
              resUser.data.data
            );
          } else {
            setErrMsg(
              "An error occured while updating the user - refresh your browser"
            );
            setLoading(false);
          }
        })
        .catch((err) => {
          setErrMsg("An error occured while updating the user");
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Dialog open={props.open} onClose={closeDialog} fullWidth maxWidth="sm">
        <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
          <IconButton onClick={closeDialog}>
            <FontAwesomeIcon
              icon={faTimes}
              style={{ padding: "4px 7px", fontSize: "20px" }}
            />
          </IconButton>
        </Box>
        <DialogTitle>
          {loading
            ? "Loading ..."
            : ["newusr"].includes(props.type)
            ? "Create New User"
            : ["updusr"].includes(props.type)
            ? "Manage User"
            : ""}
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 20,
              }}
            >
              <CircularProgress size={24} />
            </Box>
          ) : // Ask whether they really want to deactivate
          confirmDeactivate ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 12,
              }}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    style={{
                      fontSize: "40px",
                      color: theme.palette.error.main,
                    }}
                  />
                </Grid>
                <Grid item sx={{ mt: 2, pb: 2 }}>
                  <Typography align="center">
                    Are you sure you want to delete this user?
                  </Typography>
                  <Typography align="center">
                    This action cannot be undone.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="error"
                    variant="contained"
                    disableElevation
                    sx={{ mr: 1 }}
                    onClick={(e) => handleSubmit("deactivate")}
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={(e) => setConfirmDeactivate(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : // Show the FORM
          ["newusr", "updusr"].includes(props.type) ? (
            <Box sx={{ mt: 2 }}>
              {errMsg !== null ? ( // An error exists - show it
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 0,
                  }}
                >
                  <Typography align="center" color="error">
                    {errMsg}
                  </Typography>
                </Box>
              ) : (
                ""
              )}

              {["newusr", "updusr"].includes(props.type) ? (
                <UserForm
                  selectRole
                  handleSubmit={handleSubmit}
                  hideFields={
                    ["newusr", "updusr"].includes(props.type) ? ["phone"] : []
                  }
                  disableFields={
                    ["updusr"].includes(props.type)
                      ? ["email"] // Todo: Enable updating of the Email, would need to update places where this is stored in dB (e.g. collab/signer arrays)
                      : []
                  }
                  initialValues={{
                    title: ["updusr"].includes(props.type)
                      ? props.userToUpdate.title
                      : "",
                    email: ["updusr"].includes(props.type)
                      ? props.userToUpdate.email
                      : "",
                    firstName: ["updusr"].includes(props.type)
                      ? props.userToUpdate.firstName
                      : "",
                    lastName: ["updusr"].includes(props.type)
                      ? props.userToUpdate.lastName
                      : "",
                    phone: ["updusr"].includes(props.type)
                      ? props.userToUpdate.phone
                      : "",
                    role: ["updusr"].includes(props.type)
                      ? props.userToUpdate.role
                      : null,
                    readOnly: ["updusr"].includes(props.type)
                      ? props.userToUpdate.readOnly
                      : false,
                  }}
                />
              ) : (
                ""
              )}
            </Box>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Button sx={{ marginRight: "auto" }} onClick={closeDialog}>
            Cancel
          </Button>

          {["updusr"].includes(props.type) && // You are Updating the entity
          props.userToUpdate._id !== uid && // You are not trying to update/delete yourself (that's not possible)
          !confirmDeactivate ? ( // AND you're not yet deactivating
            <Button color="error" onClick={(e) => setConfirmDeactivate(true)}>
              Delete&nbsp;&nbsp;
              <FontAwesomeIcon icon={faUserSlash} />
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
