import { $isElementNode } from "lexical";
import { getColorForPartyID } from "../../../utils";
import { $isRedlineNode } from "../nodes/RedlineNode";

/**
 * @param {import("../nodes").ClauseNode} clauseNode
 * @returns {string}
 */
export function getClauseHTML(clauseNode) {
  let htmlString = `<table width="100%"><tr><td align="center">
        <div style="border-radius: 20px; border: 1px solid #e0e0e0; background-color: #f9f9f9; max-width: 600px; padding: 15px 25px; font-size: 15px">`;
  clauseNode.getChildren().forEach((n) => {
    let htmlTemp = getChildHTML(n);
    htmlString = htmlString + htmlTemp;
  });

  htmlString =
    htmlString +
    `</div>
        </td></tr></table>`;

  return htmlString;
}

/**
 * @param {import("lexical").LexicalNode } node
 * @returns
 */
function getChildHTML(node) {
  let html = "",
    childHtml = "";

  if ($isElementNode(node) && node.getChildren().length > 0) {
    node.getChildren().forEach((child) => {
      childHtml = childHtml + getChildHTML(child);
    });
  } else {
    if ($isRedlineNode(node)) {
      let style = getRedlineStyle(node);
      childHtml = "<span" + style + ">" + node.getTextContent() + "</span>";
    } else {
      childHtml = node.getTextContent();
    }
  }

  html =
    html +
    (["paragraph"].includes(node.getType())
      ? '<p style="text-align:left;">' + childHtml + "</p>"
      : ["list"].includes(node.getType())
      ? "<ul>" + childHtml + "</ul>" // TODO: List items - for emails
      : ["heading"].includes(node.getType())
      ? "<" + node.getTag() + ">" + childHtml + "</" + node.getTag() + ">"
      : ["mark"].includes(node.getType()) &&
        node.getIDs().some((/** @type {string} */ id) => id.startsWith("cp_"))
      ? '<span style="background-color: #fcedb5; padding: 2px 0px; border-bottom: 2px solid #f9d548">' +
        childHtml +
        "</span>"
      : childHtml);

  return html;
}

/**
 * @param {import("../nodes").RedlineNode} redlineNode
 * @returns {string}
 */
function getRedlineStyle(redlineNode) {
  let style = "color: " + getColorForPartyID(redlineNode.getPartyID()) + ";";
  style =
    style +
    (["del"].includes(redlineNode.getRedlineType())
      ? "text-decoration: line-through;"
      : ["add"].includes(redlineNode.getRedlineType())
      ? "border-bottom: 1px dotted " +
        getColorForPartyID(redlineNode.getPartyID()) +
        ";"
      : "");

  // Todo: Add xdel, xadd for emails.

  return ' style="' + style + '"';
}
