import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelectionStyleValueForProperty,
  $patchStyleText,
} from "@lexical/selection";
import { mergeRegister } from "@lexical/utils";
import { IconButton, Menu, MenuItem, TextField } from "@mui/material";
import {
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_CRITICAL,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import React, { useEffect, useState } from "react";
import { propagateEvent } from "../../TrackChangesPlugin/useRedline/utils/propagateEvent";

/**
 * @typedef {object} FontSizeControlsProps
 */

/**
 * @param {FontSizeControlsProps} props
 * @returns {JSX.Element}
 */
export default function FontSizeControls(props) {
  const [editor] = useLexicalComposerContext();

  const [availableFontSizes] = useState([
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "22",
    "24",
    "26",
    "28",
    "36",
    "48",
    "72",
  ]);
  const [currentFontSize, setCurrentFontSize] = useState(
    /** @type {number | string } */ ("")
  );

  const [anchorEl, setAnchorEl] = useState(
    /** @type {(EventTarget & HTMLButtonElement) | null} */ (null)
  );
  const open = Boolean(anchorEl);

  /**
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
   */
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  // /**
  //  * @param {LexicalNode} node
  //  * @param {number} operation
  //  */
  // function fontChange(node, operation) {
  //   const fontSize = node.getStyle().match(FONT_SIZE_REGEX)?.groups?.size;
  //   const newFontSize = parseInt(fontSize ?? currentFontSize) + operation;
  //   if (fontSize) {
  //     const newStyle = node
  //       .getStyle()
  //       .replace(FONT_SIZE_REGEX, `font-size: ${newFontSize}px;`);
  //     node.setStyle(newStyle);
  //   } else {
  //     node.setStyle(`${node.getStyle()}font-size: ${newFontSize}px;`);
  //   }
  // }

  // function sizeUp() {
  //   editor.update(() => {
  //     // const selection = $getSelection();
  //     // if (!$isRangeSelection(selection)) return;
  //     // const index = availableFontSizes.findIndex(
  //     //   (fontSize) => fontSize === currentFontSize.toString()
  //     // );
  //     // /**
  //     //  * @type {number}
  //     //  */
  //     // let newFontSize;
  //     // if (index > -1 && index < availableFontSizes.length - 1) {
  //     //   newFontSize = parseInt(availableFontSizes[index + 1].slice(0, -2));
  //     // } else {
  //     //   newFontSize = currentFontSize;
  //     // }
  //     // $patchStyleText(selection, {
  //     //   "font-size": `${newFontSize}px`,
  //     // });
  //     // setCurrentFontSize(newFontSize);
  //     // const selection = $getSelection();
  //     // if (!$isRangeSelection(selection)) return;
  //     // /** @param {TextNode} node */
  //     // const patch = (node) => {
  //     //   fontChange(node, 1); //addition
  //     // };
  //     // customPatchStyles(selection, patch);
  //   });
  // }

  // function sizeDown() {
  //   editor.update(() => {
  //     const selection = $getSelection();
  //     if (!$isRangeSelection(selection)) return;

  //     /** @param {TextNode} node */
  //     const patch = (node) => {
  //       fontChange(node, -1); //subtraction
  //     };
  //     customPatchStyles(selection, patch);
  //   });
  // }

  /**
   * @param {React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent>} event
   * @param {string} [newValue]
   * @returns {void}
   */
  function applyFontSize(event, newValue) {
    event.preventDefault();
    event.stopPropagation();

    const value = newValue ?? currentFontSize;
    if (!value) return;

    const newFontSize = typeof value === "number" ? value : parseInt(value);
    editor.update(() => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return;

      $patchStyleText(selection, {
        "font-size": `${newFontSize}px`,
      });
      setCurrentFontSize(newFontSize);
    });
  }

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, editor) => {
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) return propagateEvent();

          const fontSizeWithUnit = $getSelectionStyleValueForProperty(
            selection,
            "font-size"
          );

          if (fontSizeWithUnit) {
            const fontSizeString = fontSizeWithUnit.slice(0, -2);
            const fontSize = parseInt(fontSizeString);
            setCurrentFontSize(fontSize);
          } else {
            const node = selection.focus.getNode();
            if ($isTextNode(node)) {
              const htmlElement = editor.getElementByKey(node.getKey());
              if (htmlElement) {
                // Get the computed style of the element
                const computedStyle = window.getComputedStyle(htmlElement);

                // Get the inherited font size
                const inheritedFontSizeWithUnit =
                  computedStyle.getPropertyValue("font-size");
                const fontSizeString = inheritedFontSizeWithUnit.slice(0, -2);
                const fontSize = parseInt(fontSizeString);
                setCurrentFontSize(fontSize);
              }
            }
          }

          return propagateEvent();
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [editor]);

  return (
    <>
      <TextField
        size="small"
        type="text"
        inputProps={{ inputMode: "numeric", pattern: "^[0-9]+$" }}
        onInput={(event) => {
          // @ts-ignore
          if (!(event.target.value ?? "").match(/^[0-9]{1,4}$/)) {
            event.preventDefault();
            event.stopPropagation();
          } else {
            // @ts-ignore
            setCurrentFontSize(event.target.value);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            applyFontSize(e);
          }
        }}
        sx={(theme) => ({
          borderColor: theme.palette.grey[300],
        })}
        InputProps={{
          sx: (theme) => ({
            maxWidth: 60,
            height: 40,
            fontWeight: "bold",
            minHeight: 40,
            borderRadius: "5px",
            color: theme.palette.grey[800],
            backgroundColor: theme.palette.primary.contrastText,

            "& fieldset, :hover fieldset.MuiOutlinedInput-notchedOutline, :active":
              {
                borderColor: theme.palette.grey[300],
                borderRadius: "5px",
                borderWidth: "1px",
                marginRight: 0,
                borderRight: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              },
          }),
        }}
        value={currentFontSize}
      />

      {/* @ts-ignore */}
      <IconButton
        onClick={handleClick}
        sx={{
          marginLeft: "-3px",
          borderLeft: 0,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          width: 20,
          padding: "5px",
          justifyContent: "center",
          background: "white",
        }}
        variant="toolbar-btn"
        size="small"
      >
        <FontAwesomeIcon
          style={{
            maxWidth: "100%",
          }}
          icon={faChevronDown}
        />
      </IconButton>

      <Menu
        id="fontsize-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        sx={{
          maxHeight: "300px",
        }}
        PaperProps={{
          sx: {
            borderRadius: "5px",
            padding: "0",
          },
        }}
        TransitionProps={{
          timeout: 0,
        }}
      >
        {availableFontSizes.map((opt, idx) => (
          <MenuItem
            component="div"
            selected={opt === `${currentFontSize}`}
            key={`${opt}-${idx}`}
            sx={{ padding: "0.25rem 1.25rem" }}
            onClick={(e) => {
              applyFontSize(e, opt);
              handleClose();
            }}
          >
            {opt}
          </MenuItem>
        ))}
      </Menu>

      {/* @ts-ignore */}
      {/* <IconButton id="size-up-button" onClick={sizeUp} variant="toolbar-btn">
        <FontAwesomeIcon icon={faFont} />
        <FontAwesomeIcon icon={faCaretUp} style={{ paddingBottom: "10px" }} />
      </IconButton> */}

      {/* @ts-ignore */}
      {/* <IconButton
        id="size-down-button"
        onClick={sizeDown}
        variant="toolbar-btn"
      >
        <FontAwesomeIcon icon={faFont} />
        <FontAwesomeIcon
          icon={faCaretUp}
          rotation={180}
          style={{ paddingTop: "13px" }}
        />
      </IconButton> */}
    </>
  );
}
