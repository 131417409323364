const appBarReducer = (state, action) => {
  switch (action.type) {
    case "TOOLBAR_OPEN": {
      return {
        appBar: {
          ...state.appBar,
          toolbarOpen: action.payload,
        },
      };
    }
    default:
      return { appBar: state.appBar };
  }
};

export default appBarReducer;
