import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { registerLicense } from "@syncfusion/ej2-base";
import axios from "axios";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Main from "./Main";
import "./index.css";
import { StateProvider } from "./state/store";
import theme from "./theme/theme";

if (process.env.NODE_ENV === "production") {
  // Send jwt cookie on every request. This is needed when using CORS otherwise
  // the cookie is not sent. Only needed in production.
  axios.defaults.withCredentials = true;
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // If we are in the login page (pathname === "/") don't do anything.
      if (window.location.pathname === "/") return Promise.reject(error);
      // Otherwise, we redirect to the login page.
      window.location.href = window.location.href + "";
      return;
    }

    return Promise.reject(error);
  }
);

if (process.env.REACT_APP_SYNCFUSION_LICENSE) {
  // TODO: Check if there is another way of registering the Syncfusion license
  // without leaking the license on the frontend.
  registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE);
}

const container = /** @type {HTMLElement} */ (document.getElementById("root"));
const root = createRoot(container);

root.render(
  <StateProvider>
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <Main />
      </BrowserRouter>
    </MuiThemeProvider>
  </StateProvider>
);
