import { Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { CanveoCircularProgress } from "../../components";
import PolicyEditor from "../../components/PolicyEditor";
import { globalStore } from "../../state/store";

/**
 * @typedef {import("lexical").SerializedEditorState<import("lexical").SerializedLexicalNode>} SerializedEditorState
 */

/**
 * @returns {React.JSX.Element}
 */
export default function Policy() {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [policy, setPolicy] = useState(/** @type {* | null} */ (null));
  const [isLoading, setIsLoading] = useState(false);
  const [editorInitialized, setEditorInitialized] = useState(false);

  useEffect(() => {
    axios
      .get(`${state.settings.api}org/${state.user.orgID}`)
      .then((response) => {
        const org = response.data.data;
        setPolicy(org.policy);
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message: "An error occurred while retrieving the data.",
            severity: "error",
          },
        });
      })
      .finally(() => {
        setIsLoading(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChange(/** @type {import("lexical").EditorState} */ editorState) {
    if (!editorInitialized) {
      setEditorInitialized(true);
      return;
    }
    if (JSON.stringify(editorState) === JSON.stringify(policy)) return;

    editorState.read(() => {
      const serializedJson = editorState.toJSON();
      processChange(serializedJson);
    });
  }

  const processChange = debounce((/** @type {SerializedEditorState} */ data) =>
    saveEditorState(data)
  );

  function saveEditorState(/** @type {SerializedEditorState} */ data) {
    axios
      .put(`${state.settings.api}org/${state.user.orgID}/policy`, {
        policy: data,
      })
      .then((res) => {
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message: "Policy changes updated.",
            severity: "success",
          },
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message: "An error occurred while saving your changes.",
            severity: "error",
          },
        });
      });
  }

  if (!isLoading) {
    return (
      <Grid container direction="row" justifyContent="center">
        <Grid item>
          <CanveoCircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Typography variant="h4">Manage Company Policy</Typography>
        </Grid>

        <Grid item sx={{ mt: 2 }}>
          <Typography variant="body1" textAlign={"center"}>
            Manage your company policy so that it can be used to validate your
            agreements
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="center" sx={{ mt: 2 }}>
        <Grid item>
          <PolicyEditor serializedState={policy} onChange={onChange} />
        </Grid>
      </Grid>
    </Grid>
  );
}

function debounce(/** @type {Function} */ func, timeout = 2000) {
  let /** @type {*} */ timer;
  return (/** @type {*} */ ...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args);
    }, timeout);
  };
}
