import { $getNearestNodeOfType } from "@lexical/utils";
import {
  $getSelection,
  $isElementNode,
  $isLineBreakNode,
  $isParagraphNode,
  $isRangeSelection,
  $isTextNode,
} from "lexical";
import { CustomListItemNode } from "../../nodes";
import { $isCrossRefNode, CrossRefNode } from "../../nodes/CrossRefNode";
import { $createRedlineNode, $isRedlineNode } from "../../nodes/RedlineNode";
/** @constant */
export const PROPAGATE_EVENT = false;
/** @constant */
export const PREVENT_EVENT_PROPAGATION = true;

/**
 * @param {KeyboardEvent} event
 *
 * @returns {boolean}
 */
export function keyboardEventProducesCharacterValue(event) {
  const keyboardEventProducesCharacterValue =
    event.key.length === 1 && !event.metaKey && !event.ctrlKey;
  return keyboardEventProducesCharacterValue;
}

/**
 * Checks if a selection is at the very beginning of its node.
 *
 * @param {import("lexical").RangeSelection} selection
 *
 * @returns {boolean}
 */
export function selectionIsAtBeginningOfTextNode(selection) {
  if (!selection.isBackward()) {
    const selectionIsAtEndOfTextNode = selection.anchor.offset === 0;
    return selectionIsAtEndOfTextNode;
  } else {
    // If the selection is backwards the logic is the reverse.
    const selectionIsAtEndOfTextNode = selection.focus.offset === 0;
    return selectionIsAtEndOfTextNode;
  }
}

/**
 * Returns the index of the target node where the selection is being applied
 * after splitting a node with the selection offsets.
 *
 * @param {import("lexical").TextNode[]} splitNodes
 * @param {import("lexical").RangeSelection} selection
 * @returns {number}
 */
export function getTargetNodeIndex(splitNodes, selection) {
  if (splitNodes.length === 1) {
    return 0;
  } else if (splitNodes.length === 2) {
    if (selectionIsAtBeginningOfTextNode(selection)) {
      return 0;
    } else {
      return 1;
    }
  } else if (splitNodes.length === 3) {
    return 1;
  }

  throw new Error("Invalid target node index.");
}

/**
 * Returns target node where the selection is being applied after splitting a
 * node with the selection offsets.
 *
 * @template {import("lexical").TextNode} T
 *
 * @param {T[]} splitNodes
 * @param {import("lexical").RangeSelection} selection
 *
 * @returns {T}
 */
export function getTargetNode(splitNodes, selection) {
  const targetNodeIndex = getTargetNodeIndex(splitNodes, selection);
  const targetNode = splitNodes[targetNodeIndex];

  return targetNode;
}

/**
 * Checks if a `PasteCommandType` event is a `ClipboardEvent`.
 *
 * @param {import("lexical").PasteCommandType} event
 *
 * @returns {event is ClipboardEvent}
 */
export function isClipboardEvent(event) {
  const isClipboardEvent = event instanceof ClipboardEvent;
  return isClipboardEvent;
}

/**
 *
 * @param {string} text
 * @param {{partyID: PartyId, metadata: import("../../nodes/RedlineNode").NodeMetadata; date: string;}} defaultRedlineData
 * @param {any} state
 */
export function handleNodeCreationAtSelection(text, defaultRedlineData, state) {
  const selection = $getSelection();

  if (!$isRangeSelection(selection)) {
    return null;
  }

  if (!selection.isCollapsed()) {
    throw new Error("Selection must be collapsed.");
  }

  let [node] = selection.getNodes();
  /**
   *
   * @param {import("lexical").LexicalNode} n
   * @param {import("lexical").LexicalNode} nodeToInsert
   */
  const selectionInsert = (n, nodeToInsert) => {
    const parent = $getNearestNodeOfType(n, CrossRefNode);
    let children = [],
      sum = 0;
    //calculate if it is at the end of the element node
    if (parent) {
      const parentChildren = parent.getChildren();
      for (const c of parentChildren) {
        if (!c.is(node)) {
          children.push(c);
        } else {
          break;
        }
      }
      sum =
        children.reduce((pSum, a) => pSum + a.getTextContentSize(), 0) +
        selection.focus.offset +
        1 /** if it ends with linebreak needs one more */;
    }
    if (
      parent &&
      $isCrossRefNode(parent) &&
      text === " " &&
      sum >= parent.getTextContentSize()
    ) {
      //if it's at the end of link node and insert a space
      //it should leave the link node and create redline
      parent.insertAfter(nodeToInsert);
      parent.selectNext();
    } else {
      selection.insertNodes([nodeToInsert]);
    }
  };

  if (
    $isElementNode(node) ||
    $isTextNode(node) ||
    $isParagraphNode(node) ||
    $isLineBreakNode(node)
  ) {
    if ($isRedlineNode(node)) {
      const partyId = defaultRedlineData.partyID;
      const redlineType = node.getRedlineType();
      switch (redlineType) {
        case "xadd":
        case "xdel":
        case "add":
          if (node.getPartyID() === partyId) {
            // If the current selection format is the same as the current node we replace the text of the node. The reason why we do
            // not replace the node with a new one is to preserve the history functionalities i.e., cmd + z or cmd + shift + z, which
            // otherwise breaks.
            if (selection.format === node.getFormat()) {
              const textContent = node.getTextContent();
              const selectionOffset = selection.anchor.offset;

              const newText = [
                textContent.slice(0, selectionOffset),
                text,
                textContent.slice(selectionOffset),
              ].join("");
              const parent = $getNearestNodeOfType(node, CrossRefNode);
              let children = [],
                sum = 0;
              //calculate if it is at the end of the element node
              if (parent) {
                const parentChildren = parent.getChildren();
                for (const c of parentChildren) {
                  if (!c.is(node)) {
                    children.push(c);
                  } else {
                    break;
                  }
                }
                sum =
                  children.reduce(
                    (pSum, a) => pSum + a.getTextContentSize(),
                    0
                  ) +
                  selection.focus.offset +
                  1 /** if it ends with linebreak needs one more */;
              }
              if (
                parent &&
                $isCrossRefNode(parent) &&
                text === " " &&
                sum >= parent.getTextContentSize()
              ) {
                const redlineNode = $createRedlineNode({
                  ...defaultRedlineData,
                  redlineType: "add",
                  text,
                });
                redlineNode.setFormat(node.getFormat());
                redlineNode.setStyle(node.getStyle());
                //if it's at the end of link node and insert a space
                //it should leave the link node and create redline
                parent.insertAfter(redlineNode);
                parent.selectNext();
              } else {
                node.setTextContent(newText);

                // Point the selection to the end of the character we have just added.
                selection.setTextNodeRange(
                  node,
                  selection.anchor.offset + 1,
                  node,
                  selection.focus.offset + 1
                );
              }
            }
            // If the current selection format is different than the current node we create a new redline node the new formatting.
            else {
              const redlineNode = $createRedlineNode({
                ...defaultRedlineData,
                redlineType: "add",
                text,
              });
              redlineNode.setFormat(node.getFormat());
              redlineNode.setStyle(node.getStyle());
              selectionInsert(node, redlineNode);
            }
          }
          // If the redline node is from another party i.e., a counterparty.
          else {
            node.splitText(...selection.getCharacterOffsets());

            const redlineNode = $createRedlineNode({
              ...defaultRedlineData,
              redlineType: "add",
              text,
            });
            redlineNode.setFormat(node.getFormat());
            redlineNode.setStyle(node.getStyle());
            selectionInsert(node, redlineNode);
          }

          break;

        case "del":
          if (node.getPartyID() === partyId) {
            node.splitText(...selection.getCharacterOffsets());

            const redlineNode = $createRedlineNode({
              ...defaultRedlineData,
              redlineType: "add",
              text,
            });
            redlineNode.setFormat(node.getFormat());
            redlineNode.setStyle(node.getStyle());
            selectionInsert(node, redlineNode);
          }
          // If the redline node is from another party i.e., a counterparty.
          else {
            node.splitText(...selection.getCharacterOffsets());

            const redlineNode = $createRedlineNode({
              ...defaultRedlineData,
              redlineType: "add",
              text,
            });
            redlineNode.setFormat(node.getFormat());
            redlineNode.setStyle(node.getStyle());
            selectionInsert(node, redlineNode);
          }

          break;

        default:
          throw new Error(`${redlineType} is not a valid redline type.`);
      }
    }
    // If we are adding text on top of a text node.
    else {
      const redlineNode = $createRedlineNode({
        ...defaultRedlineData,
        redlineType: "add",
        text,
      });

      if ($isTextNode(node)) {
        redlineNode.setFormat(node.getFormat());
        redlineNode.setStyle(node.getStyle());
      } else if ($isElementNode(node)) {
        // If the block level node (e.g., paragraph) is empty and the selection has style
        // it means we pressed enter on an existing paragraph and want to keep its style.
        if (node.isEmpty() && selection.style) {
          redlineNode.setStyle(selection.style);
        } else {
          const currentStyle = state?.editor?.currentStyle;
          let newStyle = "";
          if (currentStyle) {
            Object.keys(currentStyle).forEach((k) => {
              newStyle += `${k}:${currentStyle[k]};`;
            });
            if (newStyle.length) redlineNode.setStyle(newStyle);
          }
        }
      }
      selectionInsert(node, redlineNode);
    }
  }
}

/**
 * @deprecated use `handleTextNodeDeletion` from the utils folder instead.
 * @param {import("lexical").LexicalNode} node
 * @param {{partyID: PartyId, metadata: import("../../nodes/RedlineNode").NodeMetadata; date: string;}} defaultRedlineData
 */
export function handleNodeDeletion(node, defaultRedlineData) {
  if ($isTextNode(node) || $isLineBreakNode(node)) {
    if ($isRedlineNode(node)) {
      const partyId = defaultRedlineData.partyID;
      const nodePartyId = node.getPartyID();
      const redlineType = node.getRedlineType();

      if (nodePartyId === partyId) {
        switch (redlineType) {
          case "add":
            node.remove();
            return null;
          case "del":
          case "xadd":
          case "xdel":
            // Do nothing in these situations.
            break;

          default:
            throw new Error(`${redlineType} is not a valid redline type.`);
        }
      } else {
        switch (redlineType) {
          case "add": {
            const redlineNode = $createRedlineNode({
              ...defaultRedlineData,
              redlineType: "xadd",
              text: node.getTextContent(),
            });
            redlineNode.setPartyID([nodePartyId, partyId].join("_"));
            redlineNode.setFormat(node.getFormat());
            redlineNode.setStyle(node.getStyle());
            node.replace(redlineNode);
            return redlineNode;
          }

          case "del":
          case "xadd":
          case "xdel":
            break;

          default:
            throw new Error(`${redlineType} is not a valid redline type.`);
        }
      }
    } else if ($isTextNode(node)) {
      const redlineNode = $createRedlineNode({
        ...defaultRedlineData,
        redlineType: "del",
        text: node.getTextContent(),
      });
      redlineNode.setFormat(node.getFormat());
      redlineNode.setStyle(node.getStyle());

      // We only want to merge with the sibling if the user is deleting one character at a time
      // and if the author of the sibling Merge Field is the same.
      const selection = $getSelection();
      if (selection?.getTextContent()?.length === 1) {
        const nextSibling = node.getNextSibling();
        if ($isRedlineNode(nextSibling)) {
          //merge siblings to avoid single character redlines
          const { creatorId, partyId } = nextSibling.getMetadata();
          const siblingRedlineType = nextSibling.getRedlineType();
          const nodeMetadata = redlineNode.getMetadata();
          const nodeRedlineType = redlineNode.getRedlineType();

          const hasSameMetadata =
            creatorId === nodeMetadata.creatorId &&
            partyId === nodeMetadata.partyId &&
            siblingRedlineType === nodeRedlineType;

          if (hasSameMetadata) {
            redlineNode.setTextContent(
              `${redlineNode.getTextContent()}${nextSibling.getTextContent()}`
            );
            nextSibling.remove();
          }
        }
      }

      node.replace(redlineNode);

      return redlineNode;
    }
  }

  return node;
}

/**
 * Returns the target node for a list of text nodes.
 *
 * @param {boolean} isBackwards
 * @param {import("lexical").TextNode[]} splitNodes
 */
export function getTargetNodeFromSplitNodes(isBackwards, splitNodes) {
  // Since we are working with the selection we want to make sure we are working with the latest most updated version
  // of it. This is especially useful to prevent issues when working with a selection after having called the splitText
  // function on a redline node, which makes the selection contain nodes that are outdated.
  const selection = $getSelection();

  if (!$isRangeSelection(selection)) {
    return null;
  }

  // If split nodes has a length of 3 this means that we have made a selection in the middle of a node, so we always
  // return the middle node.
  if (splitNodes.length === 3) {
    const [, targetNode] = splitNodes;
    return targetNode;
  }

  // Due to selection.isBackward() sometimes throwing errors, with the fear of it happening to selection.isCollapsed()
  // as well, we calculate it ourselves.
  const isCollapsed = selection.anchor.offset === selection.focus.offset;

  if (isCollapsed) {
    return selection.anchor.getNode();
  }

  if (isBackwards) {
    return selection.anchor.getNode();
  } else {
    return selection.focus.getNode();
  }
}

/**
 * Check whether selection is at the end of the corresponding custom list item node
 *
 * @param {import("lexical").RangeSelection} selection - target selection
 * @returns {boolean} If it's at the end, returns true. False, otherwise.
 */
export const isAtEndOfCustomListItemNode = (selection) => {
  if (!$isRangeSelection(selection)) {
    throw new Error(
      "Invalid argument exception: selection argument is not a range selection."
    );
  }
  const node = selection.focus.getNode();
  const parent = $getNearestNodeOfType(node, CustomListItemNode);
  //calculate if it is at the end of the element node
  if (parent) {
    const parentChildren = parent.getChildren();

    const sum =
      parentChildren.reduce(
        (pSum, a) => pSum + ($isLineBreakNode(a) ? 1 : a.getTextContentSize()),
        0
      ) - selection.focus.offset;
    return sum === 0;
  }
  return false;
};
