import { faUniversity } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React from "react";
import theme from "../theme/theme";
import { countryToFlag } from "../utils";

const filter = createFilterOptions();

/**
 * @typedef {*} SelectEntityProps
 */

/**
 * @param {SelectEntityProps} props
 * @returns {React.JSX.Element}
 */
export default function SelectEntity({ handleChange, canCreate, ...props }) {
  const onChange = (/** @type {*} */ event, /** @type {*} */ newValue) => {
    let value;
    if (typeof newValue === "string") {
      value = { toBeCreated: true, legalName: newValue };
    } else if (newValue?.inputValue || newValue?.inputValue === "") {
      value = { toBeCreated: true, legalName: newValue.inputValue };
    } else value = newValue;

    handleChange(value);
  };

  const filterOptions = (/** @type {*} */ options, /** @type {*} */ params) => {
    const filtered = filter(options, params);

    if (
      params.inputValue !== "" &&
      !filtered.some(
        (f) => f.legalName.toLowerCase() === params.inputValue.toLowerCase()
      ) &&
      !props.options.some((/** @type {{ entityCategory: string; }} */ cpo) =>
        ["Person"].includes(cpo.entityCategory)
      ) && // Cannot create another entity for a Person
      canCreate
    ) {
      filtered.unshift({
        inputValue: params.inputValue,
        legalName: `Create "${params.inputValue}" as a new legal entity`,
      });
    } else if (params.inputValue === "" && canCreate) {
      filtered.unshift({
        inputValue: params.inputValue,
        legalName: `Create New Legal Entity`,
      });
    }
    return filtered;
  };

  const renderOption = (/** @type {*} */ option) => {
    let value = option.legalName;
    if (option?.address?.[0].country) {
      value += countryToFlag(option.address[0].country);
    }

    return value;
  };

  return (
    <Autocomplete
      clearOnBlur
      handleHomeEndKeys
      style={{ width: "100%" }}
      freeSolo
      {...props}
      autoFocus
      selectOnFocus
      openOnFocus
      onChange={onChange}
      filterOptions={filterOptions}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input.
        if (typeof option === "string") return option;
        // Add "xxx" option created dynamically.
        if (option.inputValue) return option.inputValue;
        // Regular option.
        return renderOption(option);
      }}
      renderOption={(props, option) => (
        <Typography
          variant={option._id !== undefined ? "body1" : "subtitle1"}
          color={"textPrimary"}
          {...props}
        >
          <span>
            {renderOption(option)}{" "}
            {option.defaultLegalEntity ? <b>{"(Default)"}</b> : <></>}
          </span>
        </Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={props.value ? false : true}
          label={
            props.value?.entityCategory === "Person"
              ? "Full Legal Name"
              : "Select Legal Entity"
          }
          variant="outlined"
          fullWidth
          placeholder="Select legal entity ..."
          InputProps={{
            ...params.InputProps,
            autoComplete: "new-password",
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faUniversity}
                  color={theme.palette.primary.main}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
