import { ClickAwayListener, InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const Input = styled(InputBase)(() => ({
  minHeight: "unset",
  fontSize: "13px",
  lineHeight: "1.43",
  padding: "0",

  "& .MuiInputBase-input": {
    padding: 0,
  },
}));

/**
 * @param {*} props
 * @returns
 */
const EditableField = ({ value, handleChange, handleSubmit, ...props }) => {
  /**
   *
   * @param {*} event
   * @param {*} stateHandler
   * @param {*} submitHandler
   * @returns
   */
  const handleEnterKey = (event, stateHandler, submitHandler) => {
    if (event.key !== "Enter" || event.shiftKey) return;

    event.preventDefault();
    submit(stateHandler, submitHandler);
  };

  /**
   *
   * @param {*} value
   * @param {*} stateHandler
   */
  const onChange = (value, stateHandler) => {
    stateHandler((prev) => ({ ...prev, value }));
  };

  /**
   *
   * @param {*} stateHandler
   * @param {*} submitHandler
   */
  const submit = (stateHandler, submitHandler) => {
    stateHandler((prev) => ({ ...prev, editing: false }));
    submitHandler();
  };

  return (
    <ClickAwayListener onClickAway={() => submit(handleChange, handleSubmit)}>
      <Input
        autoFocus
        value={value}
        onChange={(event) => onChange(event.target.value, handleChange)}
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          )
        }
        onKeyDown={(event) => handleEnterKey(event, handleChange, handleSubmit)}
        {...props}
      />
    </ClickAwayListener>
  );
};

export default EditableField;
