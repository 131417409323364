import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useContext, useEffect } from "react";
import { analyzeNode } from "../utils";
import { CoreDrawerDefinitions } from "../../";
import { globalStore } from "../../../state/store";
import { $getRoot } from "lexical";

export default function DefinitionsPlugin() {
  const [state] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();
  const [definitions, setDefinitions] = React.useState([]);

  useEffect(() => {
    let definitionItems = []; // List of definitions (that have the clauseType)
    let definitionCTID = state.clauseTypes.filter(
      (ct) => ct.name === "Definition"
    )[0]
      ? state.clauseTypes.filter((ct) => ct.name === "Definition")[0]._id
      : "notapplicable";

    editor.getEditorState().read(() => {
      const root = $getRoot();

      root.getChildren().forEach((node) => {
        if (
          Boolean(node.getChildren()) &&
          node.getChildren().length > 0 &&
          node.getTextContent().trim().length > 3
        ) {
          let nodeDetails = analyzeNode(node.getTextContent());

          let endPos =
            nodeDetails.firstDotPositionAfterLead > -1 &&
            nodeDetails.firstDotPositionAfterLead < 50
              ? nodeDetails.firstDotPositionAfterLead
              : 50;

          let item = nodeDetails.isDefinition
            ? nodeDetails.clauseTitle
            : node.getTextContent().substring(0, endPos);

          const element = editor.getElementByKey(node.getKey());

          if (
            Boolean(node.__clauseTypes) &&
            node.__clauseTypes.includes(definitionCTID)
          ) {
            definitionItems.push({
              element: element,
              text: node.getTextContent(),
              item: item.replace('"', "").replace("“", "").replace("”", ""),
            });
          }
        }
      });
    });
    setDefinitions(definitionItems);
  }, [editor, state.clauseTypes]);

  return <CoreDrawerDefinitions definitions={definitions} />;
}
