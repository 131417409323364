import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faArrowRightArrowLeft,
  faSignature,
  faTurnDownLeft,
  faUserPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext, useMemo, useState } from "react";
import { globalStore } from "../../state/store";
import { getCanveoTier } from "../../utils/getCanveoTier";

// @ts-ignore
const HoverableBox = styled(Box)(({ theme, disabled }) => ({
  textAlign: "center",
  cursor: disabled ? "initial" : "pointer",
  color: disabled ? theme.palette.grey[500] : theme.palette.text.primary,

  "&: hover": {
    textDecoration: disabled ? "none" : "underline",
  },

  "& svg": {
    color: disabled ? theme.palette.grey[200] : theme.palette.primary.main,
    padding: "4px 7px",
    fontSize: "86px",
  },
}));

/**
 * @param {*} _
 * @returns {React.JSX.Element}
 */
export default function DialogSendOptions({
  open,
  canInitiate,
  handleClose,
  handleToggleTarget,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [userRole] = useState(state.user.role?.name);
  const [isCounterparty] = useState(userRole === "Counterparty");

  const onFlowClick = (/** @type {*} */ target) => {
    if (target === "signing" && !canInitiate) return;
    handleToggleTarget(target);
  };

  const iconStyles = useMemo(() => {
    return { height: 75 };
  }, []);

  function canSign() {
    if (getCanveoTier(state?.user?.email) === "experimental") return true;
    if (getCanveoTier(state?.user?.email) === "stable") {
      if (canInitiate) return true;
      return false;
    }

    return false;
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>{isCounterparty ? "Continue" : "Send"}</DialogTitle>

      <DialogContent>
        <Grid
          container
          direction="row"
          alignItems="stretch"
          sx={{ my: 2 }}
          justifyContent="center"
        >
          {isCounterparty && (
            <Grid item xs={4} onClick={() => onFlowClick("addcollab")}>
              <HoverableBox sx={{ mx: 2, mb: 4 }}>
                <FontAwesomeIcon style={iconStyles} icon={faUserPlus} />
                <Typography
                  variant="body1"
                  align="center"
                  style={{ marginTop: "40px" }}
                >
                  Add a collaborator
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  style={{ marginTop: "10px" }}
                >
                  Add your reviewers or approvers to this agreement
                </Typography>
              </HoverableBox>
            </Grid>
          )}

          <Grid item xs={4} onClick={() => onFlowClick("review")}>
            <HoverableBox sx={{ mx: 2, mb: 4 }}>
              {isCounterparty ? (
                <FontAwesomeIcon style={iconStyles} icon={faTurnDownLeft} />
              ) : (
                <FontAwesomeIcon
                  style={iconStyles}
                  icon={faArrowRightArrowLeft}
                />
              )}
              <Typography
                variant="body1"
                align="center"
                style={{ marginTop: "40px" }}
              >
                {isCounterparty ? "Return to the sender" : "Send for Review"}
              </Typography>
              <Typography
                variant="body2"
                align="center"
                style={{ marginTop: "10px" }}
              >
                {isCounterparty
                  ? "Return the agreement to the sender, with your comments"
                  : "Allow counterparties to read, add comments, or insert tracked changes"}
              </Typography>
            </HoverableBox>
          </Grid>

          {canSign() && (
            <Grid item xs={4} onClick={() => onFlowClick("signing")}>
              <HoverableBox
                sx={{ mx: 2, mb: 4 }}
                // @ts-ignore
                disabled={!canInitiate}
              >
                <FontAwesomeIcon style={iconStyles} icon={faSignature} />
                <Typography
                  variant="body1"
                  align="center"
                  style={{ marginTop: "40px" }}
                >
                  {isCounterparty ? "Sign" : "Send for Signature"}
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  style={{ marginTop: "10px" }}
                >
                  {isCounterparty
                    ? "Initiate signing of the agreement"
                    : "Start the signing process (counterparties can still accept tracked changes)"}
                </Typography>
              </HoverableBox>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
