import { $createHeadingNode } from "@lexical/rich-text";
import { $createTabNode } from "lexical";
import { supportedHeadingsMap } from "../../utils/constants";
import { elementNodeBlockFormatting, parseInlines } from "./text";

/**
 * Receives an SFDT Block and returns the equivalent Lexical HeadingNode.
 *
 * @param {import("../../types/sfdt").Sfdt} sfdt
 * @param {import("../../types/sfdt").Section} section
 * @param {import("../../types/sfdt").Block} block
 * @param {Map<string, import("../../types/sfdt").Comment>} sfdtCommentsMap
 * @param {Set<string>} ongoingCommentsSet
 * @param {any} user - Current user information
 * @param {any[]} collabs - Current user information
 * @param {Record<string, string>} metadata
 * @param {string} blockType
 * @param {*[]} organizationUsers
 * @param {boolean} [isList]
 * @returns {{node: import("@lexical/rich-text").HeadingNode; ongoingCommentsSet: Set<string>}}
 */
const createHeadingNode = (
  sfdt,
  section,
  block,
  sfdtCommentsMap,
  ongoingCommentsSet,
  user,
  collabs = [],
  metadata,
  blockType,
  organizationUsers,
  isList = false
) => {
  if (!blockType) {
    throw new Error(
      "'styleName' field is required in order to determine the appropriate heading type."
    );
  }

  const supportedHeadingType = supportedHeadingsMap.get(blockType);

  if (!supportedHeadingType) {
    throw new Error(`${blockType} is not a valid heading type.`);
  }
  /** @type {import("lexical").LexicalNode[]} */
  const headingNodes = [];
  // SFDT inlines are equivalent to fragments of Lexical paragraphs.
  const { inlines } = block;
  const defaultTabWidth = sfdt.defaultTabWidth ?? 36; //default 36
  const sumOfIndentation =
    (block.paragraphFormat?.firstLineIndent ?? 0) +
    (block.paragraphFormat?.leftIndent ?? 0);
  const numberOfInitialTabs = sumOfIndentation / defaultTabWidth;
  if (inlines && inlines?.length) {
    // Iterate inlines.
    parseInlines(
      block,
      inlines,
      headingNodes,
      sfdt,
      {
        ongoingCommentsSet,
        sfdtCommentsMap,
        user,
        collabs,
        metadata,
      },
      section,
      organizationUsers,
      blockType
    );
  }

  const headingNode = $createHeadingNode(supportedHeadingType);

  elementNodeBlockFormatting(sfdt, block, headingNode);

  let content;
  if (!isList) {
    /** @type {import("lexical").TabNode[]} */
    const tabs = [];
    for (let i = 0; i < numberOfInitialTabs; i++) {
      const tab = $createTabNode();
      /** @ts-ignore*/
      tabs.push(tab);
    }
    content = [...tabs, ...headingNodes];
  } else {
    content = headingNodes;
  }

  headingNode.append(...content);

  return { node: headingNode, ongoingCommentsSet };
};

export { createHeadingNode, supportedHeadingsMap };
