import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React, { useContext } from "react";
import { globalStore } from "../../state/store";

/**
 * @typedef {object} DialogRemoveMergeFieldProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {import("../editor/nodes/MarkNode").MergeField} mergeField
 */

/**
 * @param {DialogRemoveMergeFieldProps} props
 */
export default function DialogRemoveMergeField({ open, close, mergeField }) {
  // @ts-ignore
  const [, dispatch] = useContext(globalStore);

  const handleDeleteMergeField = () => {
    const mergeFieldId = mergeField._id;
    if (!mergeFieldId) throw new Error("Merge field missing ID.");

    dispatch({
      type: "DELETED_MERGE_FIELD",
      payload: mergeField,
    });

    close();
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="sm">
      <DialogTitle>Remove Merge Field</DialogTitle>

      <DialogContent>
        <Grid container direction="row">
          <br />
        </Grid>

        <Grid container direction="row">
          Are you sure you want to remove the&nbsp;
          <b>{mergeField.name}</b>&nbsp;Merge Field?
        </Grid>

        <Grid container direction="row"></Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={handleDeleteMergeField}>Remove</Button>
      </DialogActions>
    </Dialog>
  );
}
