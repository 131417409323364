/**
 * @typedef {object} AutocompleteOption
 * @property {string} label
 * @property {string} value
 */

/**
 * @typedef {object} AssigneeOption
 * @property {string} _id
 * @property {string} name
 * @property {string} email
 */

export const assignees = [{ name: "", email: "" }];

export const renewalModes = [
  {
    label: "Automatic",
    value: "automatic",
    description: "This agreement renews automatically (if no notice is given)",
  },
  {
    label: "Manual",
    value: "manual",
    description: "This agreement requires manual notice to be renewed",
  },
  {
    label: "No Renewal",
    value: "noRenewal",
    description: "This agreement does not have a renewal option",
  },
];

export const durationUnits = [
  { label: "Days", value: "days" },
  { label: "Weeks", value: "weeks" },
  { label: "Months", value: "months" },
  { label: "Years", value: "years" },
];
