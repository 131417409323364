import {
  faCircleInfo,
  faDownload,
  faList,
  faUpload,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import axios from "axios";
import download from "js-file-download";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import docx from "../../../../assets/img/docx.png";
import pptx from "../../../../assets/img/pptx.png";
import xlsx from "../../../../assets/img/xlsx.png";
import {
  AgrExecBox,
  CanveoCircularProgress,
  ExportDocumentDialog,
  Header,
} from "../../../../components";
import LockBox from "../../../../components/LockBox";
import CoreDrawer from "../../../../components/drawer/CoreDrawer";
import ExhibitsDrawer from "../../../../components/drawer/ExhibitsDrawer";
import PlaybookDrawer from "../../../../components/drawer/PlaybookDrawer";
import QuestionnaireDrawer from "../../../../components/drawer/QuestionnaireDrawer";
import SummaryDrawer from "../../../../components/drawer/SummaryDrawer";
import defaultSfdt, {
  defaultLexical,
} from "../../../../components/editor/converters/defaultSfdt";
import { CrossReferenceEventsPlugin } from "../../../../components/editor/converters/utils/cross_references";
import {
  CanveoPlugin,
  ClausePlugin,
  CopyLexicalContentsToClipboardPlugin,
  DefinitionsPlugin,
  ExportEditorToDocumentPlugin,
  ListMaxIndentLevelPlugin,
  OpenIssuesPlugin,
  TemplateVersionPlugin,
  TocPlugin,
  ToolbarPlugin,
  TrackChangesPlugin,
  TreeViewPlugin,
  WorkflowPlugin,
} from "../../../../components/editor/plugins";
import ClauseOptionsPlugin from "../../../../components/editor/plugins/ClauseOptionsPlugin";
import FloatingContextMenuPlugin from "../../../../components/editor/plugins/FloatingContextMenuPlugin/FloatingContextMenuPlugin";
import ImagesPlugin from "../../../../components/editor/plugins/ImagesPlugin";
import ListExtendedPlugin from "../../../../components/editor/plugins/ListExtendedPlugin";
import { TableMenuPlugin } from "../../../../components/editor/plugins/TableMenuPlugin";
import VersionsPlugin from "../../../../components/editor/plugins/VersionsPlugin";
import { editorConfig } from "../../../../components/editor/utils";
import { EDITOR_WIDTH } from "../../../../components/editor/utils/constants";
import useFileService from "../../../../hooks/useFileService";
import useVersionService from "../../../../hooks/useVersionService";
import { globalStore } from "../../../../state/store";
import theme from "../../../../theme/theme";

// Change to true to enable additional Lexical debugging tools.
const devMode = false;

const drawerItems = [
  {
    id: "editor-drawer-summary",
    name: "Summary",
    icon: faCircleInfo,
  },
  {
    name: "Contents",
    icon: faList,
  },
  {
    id: "editor-drawer-export",
    name: "Export",
    icon: faDownload,
  },
];

/**
 * @typedef {{ page: "Template" | "Agreement"; template: *; aid?: string }} EditorProps
 */

/**
 * @param {EditorProps} props
 * @returns {React.JSX.Element}
 */
export default function PlaybookEditor(props) {
  // Used to check if we don't run into bugs where renders happen infinitely.
  console.debug("EDITOR_INFINITE_RENDER_DEBUG_TOKEN");

  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const containerWithScrollRef = useRef(/** @type {* | null} */ (null));
  const exportEditorToDocumentPluginRef = useRef(
    /** @type {* | null} */ (null)
  );
  const copyLexicalJsonToClipboardPluginRef = useRef(
    /** @type {* | null} */ (null)
  );

  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const { downloadFile } = useFileService();

  const [file, setFile] = useState(/** @type {* | null} */ (null));

  const [openExportDocumentDialog, setOpenExportDocumentDialog] =
    useState(false);
  const [exportDocumentDialogIsLoading, setExportDocumentDialogIsLoading] =
    useState(false);

  const [exportFullAgreement, setExportFullAgreement] = useState(false);

  const [defaultLexicalValue, setDefaultLexicalValue] = useState(
    /** @type {* | null} */ (null)
  );

  const getDefaultLexical = async () => {
    const lexical = await defaultLexical();

    setDefaultLexicalValue(lexical.content);
  };
  useEffect(() => {
    getDefaultLexical();
  }, []);

  const isTemplating = ["Template"].includes(props.page);
  const docID = isTemplating ? props.template._id : props.aid;

  const mainAg = isTemplating
    ? props.template
    : state.agrs.find((/** @type {{ parentID: string; }} */ a) => !a.parentID);

  const av = useMemo(
    () =>
      isTemplating
        ? {
            content: mainAg.content,
            contentMetadata: mainAg.contentMetadata,
            sfdt: mainAg.sfdt,
            versionType: "canveo",
            _id: state.drawerVersions.active?._id,
          } // Templating Scenario
        : state.avs.find(
            (/** @type {{ agrID: string; }} */ av) => av.agrID === props.aid
          ),
    [isTemplating, mainAg, state.avs, props.aid, state.drawerVersions.active]
  );

  const editMode = av.owner?.find(
    (/** @type {{ orgID: string; }} */ organization) =>
      organization.orgID === state.org._id
  )?.editMode;
  const partyRoleIsReadonly = editMode === "read";

  // Agreement Scenario
  const [firstPageHeader] = useState(
    /** @type {import("../../../../components/editor/types/sfdt").Sfdt}*/ (
      isTemplating ? props.template.firstPageHeader : av.firstPageHeader
    )
  );
  const [firstPageFooter] = useState(
    /** @type {import("../../../../components/editor/types/sfdt").Sfdt}*/ (
      isTemplating ? props.template.firstPageFooter : av.firstPageFooter
    )
  );

  const isAgrExec = Boolean(state.agrExec) && Boolean(state.agrExec._id);
  const /** @type {boolean} */ isCurrentVersionOwner =
      !isAgrExec &&
      (isTemplating ||
        (!isTemplating &&
          Boolean(mainAg) &&
          mainAg.avOwners.some(
            (/** @type {string} */ owner) => owner === state.org._id
          )));

  const isAgreementOwner = isTemplating
    ? true
    : mainAg.owner === state.user.orgID;

  const isNonPDFAttachment =
    !isTemplating && ["docx", "pptx", "xlsx"].includes(av.versionType);

  const partyID = isTemplating
    ? "party0"
    : mainAg.ents.filter((/** @type {{ entID: string; }} */ e) =>
        state.subs.some(
          (/** @type {{ _id: string; }} */ s) => s._id === e.entID
        )
      )[0] !== undefined
    ? mainAg.ents.filter((/** @type {{ entID: string; }} */ e) =>
        state.subs.some(
          (/** @type {{ _id: string; }} */ s) => s._id === e.entID
        )
      )[0].partyID
    : "party0"; // possible temporary fix to prevent infinite loading mode if no parties attached to agreement

  // TODO: Update to use playbook routes.
  useVersionService(docID, isTemplating);

  const [readOnly, setReadOnly] = useState(false);
  useEffect(() => {
    setReadOnly(
      state.drawerVersions.active?._id !==
        state.drawerVersions.versions[0]?._id ||
        (isTemplating && props.template?.active)
    );
  }, [
    state.drawerVersions.active,
    state.drawerVersions.versions,
    isTemplating,
    props.template?.active,
  ]);

  useEffect(() => {
    // Load the PDF file or link to the non-PDF attachment
    if (
      ["pdf", "docx", "pptx", "xlsx"].includes(av.versionType) &&
      av.content !== undefined &&
      av.content !== null &&
      av.content.file !== undefined
    ) {
      axios
        .get(
          // For PDF: Download to arraybuffer, for others - get a "viewable" temporary URL
          state.settings.api +
            "download/" +
            (isNonPDFAttachment ? "read/" : "") +
            av.content.file,
          isNonPDFAttachment ? {} : { responseType: "arraybuffer" }
        )
        .then((res) => {
          setFile({
            // @ts-ignore
            type: av.versionType,
            file: isNonPDFAttachment ? res.data.data : res.data,
            pageNumber: 1,
          });
        })
        .catch((err) => console.log("todo err handling", err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [av]);

  const handleDownloadFile = () => {
    if (["pdf"].includes(file?.type)) {
      download(file.file, "file." + file.type);
    } else {
      // Non-PDF attachment
      window.open(file.file, "_blank");
    }
  };

  /**
   * @param {{size: "small" | "medium" | "large"}} _
   */
  const RenderUpDownButtons = ({ size }) => {
    return (
      <>
        <Button
          size={size}
          variant={size === "large" ? "contained" : "text"}
          sx={
            size === "large"
              ? { padding: "5px 20px", fontSize: "17px", margin: "5px" }
              : { padding: "3px 10px" }
          }
          disableElevation
          onClick={handleDownloadFile}
        >
          Download &nbsp;&nbsp;
          <FontAwesomeIcon icon={faDownload} />
        </Button>
        <Button
          size={size}
          variant={size === "large" ? "contained" : "text"}
          sx={
            size === "large"
              ? { padding: "5px 20px", fontSize: "17px", margin: "5px" }
              : { padding: "3px 10px" }
          }
          disableElevation
        >
          Upload &nbsp;&nbsp;
          <FontAwesomeIcon icon={faUpload} />
        </Button>
      </>
    );
  };

  /**
   * @param {import("lexical").EditorState} editorState
   */
  function onChange(editorState) {
    // if (partyRoleIsReadonly) return;

    editorState.read(() => {
      // Function is debounced to only process changes every 2 seconds.
      processChange(editorState.toJSON());
    });
  }

  /**
   * @param {*} func
   * @param {*} timeout
   */
  function debounce(func, timeout = 2000) {
    let /**  @type {string | number | NodeJS.Timeout | undefined} */ timer;
    return (/** @type {*} */ ...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        // @ts-ignore
        func.apply(this, args);
      }, timeout);
    };
  }

  /**
   * @param {*} data
   */
  async function saveEditorState(data) {
    await axios
      .put(`${state.settings.api}playbook/${docID}/versions/latest/content`, {
        newContent: data,
      })
      .catch(() => {
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message: "An error occurred while saving your changes.",
            severity: "error",
          },
        });
      });
  }

  const processChange = debounce((/** @type {*} */ data) =>
    saveEditorState(data)
  );

  const closeExportDocumentDialog = () => {
    setExportDocumentDialogIsLoading(true);
    setOpenExportDocumentDialog(false);
    window.setTimeout(() => {
      setExportDocumentDialogIsLoading(false);
    }, 1000);
  };

  /**
   * @param {"Export"} event
   */
  const handleDrawerEvents = (event) => {
    switch (event) {
      case "Export":
        exportAgreement(true);
        break;

      default:
        throw new Error("Invalid event.");
    }
  };

  /**
   * @param {*} file
   * @param {*} fileName
   * @param {*} convert
   */
  const downloadPdfFile = async (file, fileName, convert) => {
    try {
      const result = await axios.get(
        // For PDF: Download to arraybuffer, for others - get a "viewable" temporary URL.
        `${state.settings.api}download/${convert ? "convertPdf/" : ""}${file}`,
        { responseType: "arraybuffer" }
      );

      download(result.data, fileName);
    } catch (e) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "Unable to download origin file - contact Canveo Support",
          severity: "error",
        },
      });
    }
  };

  const onExportFinish = () => {
    if (!devMode) {
      setOpenExportDocumentDialog(false);
    }

    window.setTimeout(() => {
      setExportDocumentDialogIsLoading(false);
    }, 1000);
  };

  /**
   * @param {*} event
   * @param {*} payload
   */
  const handleExportDocumentDialogEvents = async (event, payload) => {
    switch (event) {
      case "Export":
        setExportDocumentDialogIsLoading(true);

        if (exportFullAgreement && payload.type !== "docx") {
          const isInEffect = mainAg?.agrStatus === "InEffect";
          exportEditorToDocumentPluginRef.current
            .fullExport(payload, isTemplating, isInEffect)
            .then(onExportFinish);
        } else if (["canveo", "docx"].includes(av.versionType)) {
          exportEditorToDocumentPluginRef.current
            .export(payload, isAgreementOwner, editMode)
            .then(onExportFinish);
        } else if (payload.type === "xlsx") {
          await downloadFile(av.embeddedFileId, `${payload.filename}.xlsx`);
          closeExportDocumentDialog();
        } else {
          await downloadPdfFile(
            av.embeddedFileId,
            `${payload.filename}.pdf`,
            av.versionType === "xlsx"
          );
          closeExportDocumentDialog();
        }
        break;

      default:
        throw new Error(`${event} is not a valid event.`);
    }
  };

  const [floatingAnchorElem, setFloatingAnchorElem] = useState(
    /** @type {* | null} */ (null)
  );

  /**
   * @param {*} _floatingAnchorElem
   */
  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    // Set indentation base value for editor.
    if (av && containerWithScrollRef.current) {
      let tabWidth = 36,
        docWidth = EDITOR_WIDTH,
        docHeight;
      if (isTemplating) {
        tabWidth = props.template.sfdt.defaultTabWidth;
        const { sections } = props.template.sfdt;
        /** @type {import("../../../../components/editor/types/sfdt").Section} */
        const firstSection = sections[0];
        if (firstSection) {
          const { sectionFormat } = firstSection;
          docWidth =
            sectionFormat.pageWidth -
            (sectionFormat.leftMargin + sectionFormat.rightMargin);
          docHeight =
            sectionFormat.pageHeight -
            (sectionFormat.bottomMargin + sectionFormat.topMargin);
        }
      } else {
        tabWidth = av.sfdt?.defaultTabWidth || defaultSfdt.defaultTabWidth;
        const { sections } = av.sfdt || defaultSfdt;
        /** @type {import("../../../../components/editor/types/sfdt").Section} */
        const firstSection = sections[0];
        if (firstSection) {
          const { sectionFormat } = firstSection;
          docWidth =
            sectionFormat.pageWidth -
            (sectionFormat.leftMargin + sectionFormat.rightMargin);
          docHeight =
            sectionFormat.pageHeight -
            (sectionFormat.bottomMargin + sectionFormat.topMargin);
        }
      }
      const editorInput = containerWithScrollRef.current,
        editorHeader = document.getElementsByClassName("editor-header")[0],
        editorFooter = document.getElementsByClassName("editor-footer")[0];
      if (editorInput) {
        editorInput.style.setProperty(
          "--lexical-indent-base-value",
          tabWidth + "px"
        );
        editorInput.style.setProperty(
          "--lexical-doc-width",
          docWidth + 55 + "px" // Adding 55 because it makes it look more like the Word document.
        );
        editorInput.style.setProperty("--lexical-doc-height", docHeight + "px");
        if (docHeight) {
          editorInput.style.setProperty(
            "--lexical-doc-height",
            docHeight + "px"
          );
        }
        //set font-size
        const mainFontSize = isTemplating
          ? props.template.sfdt.characterFormat?.fontSize
          : av.sfdt.characterFormat?.fontSize;
        const headerFontSize = firstPageHeader?.characterFormat?.fontSize;
        const footerFontSize = firstPageFooter?.characterFormat?.fontSize;
        if (mainFontSize) {
          editorInput.style.setProperty(
            "--lexical-doc-fontSize",
            mainFontSize + "px"
          );
        }
        if (headerFontSize) {
          // @ts-ignore
          editorHeader.style.setProperty(
            "--lexical-doc-fontSize",
            headerFontSize + "px"
          );
        }
        if (footerFontSize) {
          // @ts-ignore
          editorFooter.style.setProperty(
            "--lexical-doc-fontSize",
            headerFontSize + "px"
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [av, containerWithScrollRef.current]);

  const isEditable = () => {
    if (!isMdUp) return false;

    if (isTemplating) return !props.template.active;

    if (["InEffect", "Execution"].includes(mainAg.agrStatus)) return false;

    return !partyRoleIsReadonly;
  };

  const exportAgreement = (isFull = false) => {
    setOpenExportDocumentDialog(true);
    setExportFullAgreement(isFull);
  };

  const showLockBox =
    // isAgreementOwner &&
    !isCurrentVersionOwner &&
    !isAgrExec &&
    !["Execution", "InEffect"].includes(mainAg?.agrStatus);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "calc(100%-105px)",
          margin: "0px",
          marginLeft: "105px",
          padding: "0px" /*, backgroundColor: '#ffeeff'*/,
        }}
      >
        {
          // TODO: Check if this code should happen at any moment
          // // Show the Header / Drawer for any non-Canveo Doc - for Canveo docs, the header will be part of the editor context
          // !["canveo"].includes(av.versionType) ? (
          //   <>
          //     <Header page={props.page} mainAg={mainAg} isOwner={isOwner} />
          //     <CoreDrawer page={props.page} template={props.template} />
          //   </>
          // ) : (
          //   ""
          // )
        }

        <Grid container direction="column" alignItems="center">
          {devMode && (
            <>
              <Grid container direction="row" justifyContent="center">
                <Grid item xs={4} />
                <Grid item xs={3}>
                  <Button
                    onClick={() => {
                      copyLexicalJsonToClipboardPluginRef.current.copyJson();
                    }}
                  >
                    Copy Lexical JSON
                  </Button>
                </Grid>

                <Grid item xs={5}>
                  <Button
                    onClick={() => {
                      copyLexicalJsonToClipboardPluginRef.current.copyHtml();
                    }}
                  >
                    Copy Lexical HTML
                  </Button>
                </Grid>
              </Grid>
            </>
          )}

          <Grid
            item
            container
            justifyContent="center"
            sx={{
              mt: state.appBar?.toolbarOpen ? "90px" : "0px",
              width: "100%",
            }}
          >
            {
              // FILE ATTACHMENT DOWN- AND UPLOAD
              Boolean(av) &&
              Boolean(av.content) &&
              ["docx", "pptx"].includes(av.versionType) &&
              file !== null ? (
                <div>
                  <Grid container direction="column" alignItems="center">
                    <Grid item sx={{ my: 2 }}>
                      <img
                        alt={av.versionType}
                        width={70}
                        style={{ cursor: "pointer" }}
                        onClick={handleDownloadFile}
                        src={
                          ["pptx"].includes(av.versionType)
                            ? pptx
                            : ["xlsx"].includes(av.versionType)
                            ? xlsx
                            : docx
                        }
                      />
                    </Grid>
                    <Grid item sx={{ mt: 2 }}>
                      <Typography>
                        <b>Reference:</b> {av.content.file}
                      </Typography>
                      <Typography>
                        <b>Upload on:</b> {av.content.creationDate}
                      </Typography>
                      <Typography>
                        <b>Upload by:</b> {av.content.creationBy}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ my: 4 }}>
                      <RenderUpDownButtons size="large" />
                    </Grid>
                  </Grid>
                </div>
              ) : // CANVEO DOC (WITH EDITOR)
              partyID !== undefined &&
                partyID !== null &&
                defaultLexicalValue ? ( // We know the party ref of the current users' entity
                <Grid
                  item
                  xs={8}
                  justifyContent="center"
                  display="flex"
                  ref={containerWithScrollRef}
                >
                  <LexicalComposer
                    // @ts-ignore
                    initialConfig={{
                      ...editorConfig,
                      // We need to specify the namespace as "mainBody" here in
                      // order to be able to distinguish the main editor (this one)
                      // from the editors used below for the header and footer.
                      //
                      // This is needed so that we can prevent the footer and header
                      // editor from populating the global list handler. This logic is
                      // implemented in the first useEffect of the ListExtendedPlugin.
                      //
                      // At the moment, the global list handler is a singleton and only
                      // supports one instance across the application hence why we give
                      // it priority to the main body instead of the footers and headers.
                      //
                      // This means that at the moment it is not possible to manually create
                      // lists in the editor on footers and headers. To allow this we need to
                      // find a way of having a list handler per editor.
                      namespace: "playbook",
                      editorState: JSON.stringify(
                        av.content || defaultLexicalValue
                      ),
                      // The real editable config is done in the isEditable={isEditable()} in the
                      // tracked changes plugin below.
                      editable: true,
                    }}
                  >
                    <Header
                      page={props.page}
                      mainAg={mainAg}
                      isOwner={isCurrentVersionOwner}
                      toolbar={<ToolbarPlugin sfdt={av.sfdt} />}
                      showToolbarBtn={true}
                      templateVersion={
                        isTemplating ? (
                          <TemplateVersionPlugin template={props.template} />
                        ) : null
                      }
                      isInEffect={
                        isTemplating
                          ? props.template.active
                          : ["InEffect", "Execution"].includes(mainAg.agrStatus)
                      }
                    />

                    <CoreDrawer
                      hasLargeDrawer
                      drawerItems={drawerItems}
                      contents={<TocPlugin />}
                      definitions={<DefinitionsPlugin />}
                      exhibits={
                        <ExhibitsDrawer
                          isTemplate={isTemplating}
                          docID={docID}
                          hasPen={isCurrentVersionOwner}
                          handleExport={exportAgreement}
                          template={props.template}
                          isInEffect={
                            isTemplating
                              ? props.template.active
                              : ["InEffect", "Execution"].includes(
                                  mainAg.agrStatus
                                )
                          }
                        />
                      }
                      versions={
                        <VersionsPlugin
                          isTemplate={isTemplating}
                          docID={docID}
                          handleExport={exportAgreement}
                          isActive={props.template?.active}
                          isInEffect={
                            isTemplating
                              ? props.template.active
                              : ["InEffect", "Execution"].includes(
                                  mainAg.agrStatus
                                )
                          }
                        />
                      }
                      questionnaire={
                        <QuestionnaireDrawer
                          partyId={partyID}
                          documentIsTemplate={isTemplating}
                          docId={docID}
                          // @ts-ignore
                          user={state.user}
                        />
                      }
                      summary={
                        <SummaryDrawer
                          // @ts-ignore
                          docID={docID}
                          isTemplate={isTemplating}
                        />
                      }
                      playbook={<PlaybookDrawer />}
                      onEvent={handleDrawerEvents}
                    />

                    <div className="editor-container" ref={onRef}>
                      {isAgrExec &&
                        ["Execution"].includes(mainAg.agrStatus) && (
                          <Grid item sx={{ my: 2, ml: "30px" }}>
                            <AgrExecBox doc={mainAg} />
                          </Grid>
                        )}

                      {firstPageHeader &&
                        // @ts-ignore
                        firstPageHeader?.root?.children?.length > 0 && (
                          <LexicalComposer
                            // @ts-ignore
                            initialConfig={{
                              ...editorConfig,
                              namespace: "firstPageHeader",
                              editorState: JSON.stringify(firstPageHeader),
                              editable: false,
                            }}
                          >
                            <RichTextPlugin
                              placeholder={null}
                              ErrorBoundary={LexicalErrorBoundary}
                              contentEditable={
                                <div className="editor-header">
                                  <ContentEditable />
                                </div>
                              }
                            />
                            <ListExtendedPlugin
                              partyId={partyID}
                              // @ts-ignore
                              userDisplayName={state.user.displayName}
                              user={state.user}
                              // @ts-ignore
                              sfdt={firstPageFooter.sfdt}
                              listsStructure={
                                av.contentMetadata?.listsStructure
                              }
                            />
                            <ImagesPlugin />
                            <ListPlugin />
                            <LinkPlugin />
                            <TablePlugin />
                            <ListMaxIndentLevelPlugin maxDepth={8} />
                            <TabIndentationPlugin />
                            <CrossReferenceEventsPlugin state={state} />
                            <TabIndentationPlugin />
                          </LexicalComposer>
                        )}

                      {/* If a later version is owned by a counterparty, show a lock box to the current user. */}
                      {showLockBox && (
                        <LockBox agreement={mainAg} parties={state.parties} />
                      )}

                      {/* EDITOR START */}
                      <CanveoPlugin />
                      <ExportEditorToDocumentPlugin
                        ref={exportEditorToDocumentPluginRef}
                        // @ts-ignore
                        agrVersion={av}
                        partyId={partyID}
                      />
                      <CopyLexicalContentsToClipboardPlugin
                        ref={copyLexicalJsonToClipboardPluginRef}
                      />
                      {/* @ts-ignore */}
                      <RichTextPlugin
                        ErrorBoundary={LexicalErrorBoundary}
                        contentEditable={
                          <div
                            className="editor"
                            style={{ opacity: readOnly ? 0.5 : 1 }}
                          >
                            <ContentEditable
                              className="editor-input"
                              spellCheck={false}
                            />
                          </div>
                        }
                      />
                      <ListExtendedPlugin
                        partyId={partyID}
                        // @ts-ignore
                        userDisplayName={state.user.displayName}
                        user={state.user}
                        sfdt={av.sfdt}
                        listsStructure={av.contentMetadata?.listsStructure}
                      />
                      <HistoryPlugin />
                      <TabIndentationPlugin />
                      {/* @ts-ignore */}
                      <ImagesPlugin />
                      <ListPlugin />
                      <LinkPlugin />

                      <TablePlugin />
                      {floatingAnchorElem && (
                        <>
                          <TableMenuPlugin anchorElem={floatingAnchorElem} />
                          <FloatingContextMenuPlugin
                            anchorElem={floatingAnchorElem}
                            isInEffect={
                              isTemplating
                                ? props.template.active
                                : ["InEffect", "Execution"].includes(
                                    mainAg.agrStatus
                                  )
                            }
                            isTemplate={isTemplating}
                          />
                        </>
                      )}
                      <ListMaxIndentLevelPlugin maxDepth={8} />
                      <TabIndentationPlugin />
                      <TrackChangesPlugin
                        partyId={partyID}
                        // @ts-ignore
                        userDisplayName={state.user.displayName}
                        user={state.user}
                        isTemplate={isTemplating}
                        docID={docID}
                        isEditable={isEditable()}
                      />
                      <WorkflowPlugin
                        partyID={partyID}
                        isTemplating={isTemplating}
                        docID={docID}
                        agrvID={state.drawerVersions.active?._id}
                        user={state.user}
                      />
                      <ClausePlugin partyID={partyID} />
                      <ClauseOptionsPlugin
                        partyID={partyID}
                        docID={docID}
                        isTemplating={isTemplating}
                        isInEffect={
                          isTemplating
                            ? props.template.active
                            : ["InEffect", "Execution"].includes(
                                mainAg.agrStatus
                              )
                        }
                      />
                      <OpenIssuesPlugin
                        anchorElem={floatingAnchorElem}
                        partyId={partyID}
                        isTemplating={isTemplating}
                        docID={docID}
                      />
                      <OnChangePlugin
                        onChange={onChange}
                        ignoreSelectionChange
                      />
                      <CrossReferenceEventsPlugin state={state} />
                      {/* Useful for debugging; Turn on devMode for additional debugging information from the editor */}
                      {devMode && <TreeViewPlugin />}
                      {/* EDITOR END */}

                      {firstPageFooter &&
                        // @ts-ignore
                        firstPageFooter?.root?.children?.length > 0 && (
                          <LexicalComposer
                            // @ts-ignore
                            initialConfig={{
                              ...editorConfig,
                              namespace: "firstPageFooter",
                              editorState: JSON.stringify(firstPageFooter),
                              editable: false,
                            }}
                          >
                            <RichTextPlugin
                              placeholder={null}
                              ErrorBoundary={LexicalErrorBoundary}
                              contentEditable={
                                <div className="editor-footer">
                                  <ContentEditable />
                                </div>
                              }
                            />
                            <ImagesPlugin />
                            <ListPlugin />
                            <ListExtendedPlugin
                              partyId={partyID}
                              // @ts-ignore
                              userDisplayName={state.user.displayName}
                              user={state.user}
                              // @ts-ignore
                              sfdt={firstPageFooter.sfdt}
                              listsStructure={
                                av.contentMetadata?.listsStructure
                              }
                            />
                            <LinkPlugin />

                            <TablePlugin />
                            <ListMaxIndentLevelPlugin maxDepth={8} />
                            <TabIndentationPlugin />
                            <CrossReferenceEventsPlugin state={state} />
                          </LexicalComposer>
                        )}
                    </div>
                  </LexicalComposer>
                </Grid>
              ) : (
                <CanveoCircularProgress />
              )
            }
          </Grid>
        </Grid>
      </Box>

      <ExportDocumentDialog
        openDialog={openExportDocumentDialog}
        isLoading={exportDocumentDialogIsLoading}
        closeDialog={closeExportDocumentDialog}
        onEvent={handleExportDocumentDialogEvents}
        agr={mainAg}
        versionType={av.versionType}
        isTemplate={isTemplating}
        isAgreementOwner={isAgreementOwner}
        editMode={editMode}
      />
    </>
  );
}
