import React, { useContext, useEffect, useState } from "react";
import useAgreementData from "../../hooks/useAgreementData";
import { globalStore } from "../../state/store";
import DialogSendAddCollaborator from "./DialogSendAddCollaborator";
import DialogSendOptions from "./DialogSendOptions";
import DialogSendReview from "./DialogSendReview";
import DialogSendSigning from "./DialogSendSigning";
import DialogSigningProgress from "./DialogSigningProgress";

const initialOpenStates = {
  options: false,
  review: false,
  signing: false,
  addcollab: false,
};

/**
 * @typedef {*} DialogSendProps
 */

/**
 * @param {DialogSendProps} props
 * @returns {JSX.Element}
 */
export default function DialogSend({ open, handleClose, agreement }) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [userRole] = useState(state.user.role?.name);
  const [openStates, setOpenStates] = useState(initialOpenStates);
  const [openDialogSigningProgress, setOpenDialogSigningProgress] =
    useState(false);
  const { canSign } = useAgreementData(agreement);

  useEffect(() => {
    setOpenStates({ ...initialOpenStates, options: open });
  }, [open]);

  /**
   * @param {*} dialog
   */
  function closeDialog(dialog) {
    setOpenStates((previous) => ({
      ...previous,
      [dialog]: false,
      options: true,
    }));
  }

  /**
   * @param {*} dialog
   */
  function toggleDialog(dialog) {
    setOpenStates((previous) => ({
      ...previous,
      [dialog]: true,
      options: false,
    }));
  }

  /**
   * @param {string} type
   */
  function handleCloseGroupHandler(type) {
    handleClose();

    if (type === "preparingYourDocumentForSignatureSetup") {
      setOpenDialogSigningProgress(true);
    }
  }

  return (
    <>
      {openStates.options && (
        <DialogSendOptions
          open={openStates.options}
          handleClose={handleClose}
          handleToggleTarget={toggleDialog}
          canInitiate={
            !agreement.isEdited &&
            (!!state.users?.find(
              (/** @type {{ _id: string; }} */ u) =>
                u._id === agreement.creationBy
            ) ||
              canSign)
            // Commented to enable direct sign even if there are no signers.
            // && agreement.signers.length >= 1
          }
        />
      )}

      {openStates.review && (
        <DialogSendReview
          open={openStates.review}
          handleCloseDialog={() => closeDialog("review")}
          handleCloseGroup={handleCloseGroupHandler}
          agreement={agreement}
        />
      )}

      {openStates.signing && (
        <DialogSendSigning
          open={openStates.signing}
          // @ts-ignore
          handleCloseDialog={() => closeDialog("signing")}
          handleCloseGroup={handleCloseGroupHandler}
          agreement={agreement}
        />
      )}

      {openStates.addcollab && userRole === "Counterparty" && (
        <DialogSendAddCollaborator
          open={openStates.addcollab}
          // @ts-ignore
          handleCloseDialog={() => closeDialog("addcollab")}
          handleCloseGroup={handleClose}
          agreement={agreement}
        />
      )}

      {openDialogSigningProgress && (
        <DialogSigningProgress
          open={openDialogSigningProgress}
          close={() => {}}
        />
      )}
    </>
  );
}
