import {
  faChevronDown,
  faChevronRight,
  faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  alpha,
  darken,
  lighten,
  styled,
} from "@mui/material";
import { TreeItem, TreeView, treeItemClasses } from "@mui/x-tree-view";
import React, { Fragment } from "react";
import theme from "../../theme/theme";
import FitContentPopper from "./components/FitContentPopper";
import usePathBuilderState from "./usePathBuilderState";

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.iconContainer} svg`]: {
    fontSize: "10px !important",
  },
  [`& .${treeItemClasses.group}`]: {
    marginTop: 10,
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const GroupHeader = styled("div")(({ theme }) => ({
  fontWeight: "bold",
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

/**
 * @typedef {object} PathBuilderProps
 * @property {PathBuilder} builder
 * @property {boolean} isLawFirm
 * @property {(elements: PathBuilder) => void} onChange
 * @property {boolean} isFile
 */

/**
 * @param {PathBuilderProps} props
 * @returns {React.JSX.Element}
 */
export default function PathBuilder({ builder, isLawFirm, onChange, isFile }) {
  const { elements, setElements, options, separators } = usePathBuilderState(
    builder,
    isLawFirm,
    isFile
  );

  const [anchorEl, setAnchorEl] = React.useState(
    /** @type {* | null} */ (null)
  );
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * @param {PathElement["label"]} label
   */
  function addElement(label) {
    setElements((elements) => {
      return [
        ...elements,
        {
          label,
          selectedOption: null,
        },
      ];
    });
  }

  /**
   * @param {PathElement} element
   * @param {number} key
   * @returns {React.JSX.Element | string | null}
   */
  function getElementLabel(element, key) {
    if (!element.selectedOption) return null;

    if (element.label === "Separator") {
      if (element.selectedOption.label === "               [space]") {
        return (
          <Box key={key} component="span" fontWeight="bold">
            {" "}
          </Box>
        );
      }

      return (
        <Box key={key} component="span" fontWeight="bold">
          {element.selectedOption?.label}
        </Box>
      );
    }

    return (
      <Box
        key={key}
        component="span"
        color={theme.palette.primary.main}
        fontWeight="bold"
      >
        [{element.selectedOption?.label}]
      </Box>
    );
  }

  function getPreviewLabel() {
    const labelElements = [
      ...elements
        .filter((element) => element.selectedOption)
        .map((element, index) => getElementLabel(element, index)),
    ];

    if (isFile) {
      labelElements.push(
        <Box component="span" fontWeight="bold">
          .docx
        </Box>
      );
    }

    return labelElements;
  }

  /**
   * Generate items for tree component.
   */
  function generateTreeItems() {
    let index = 0;
    let count = 1;

    const data = { id: "0", name: "", children: [] };

    let selectedElement = data;

    let name = "";

    while (index < elements.length) {
      const element = elements[index];

      if (element.selectedOption?.label === "/               [new subfolder]") {
        selectedElement.name = name || "";

        // @ts-ignore
        selectedElement.children.push({
          id: count.toString(),
          name: "",
          children: [],
        });

        selectedElement = selectedElement.children[0];
        count++;
        name = "";
      } else {
        name += element.selectedOption?.label || "";
        if (index === elements.length - 1) {
          selectedElement.name = name || "";
        }
      }

      index++;
    }

    return {
      id: "root",
      name: "root folder",
      children: [data],
    };
  }

  /**
   * @param {*} nodes
   */
  function renderTree(nodes) {
    return (
      <StyledTreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
        {Array.isArray(nodes.children)
          ? nodes.children.map((/** @type {*} */ node) => renderTree(node))
          : null}
      </StyledTreeItem>
    );
  }

  return (
    <>
      <Grid container direction="row" mt={1} spacing={1} alignItems="center">
        {elements.map((element, index) => (
          <Fragment key={index}>
            <Grid key={index} item xs={element.label === "Element" ? 6 : 3}>
              {element.label === "Element" ? (
                <Autocomplete
                  PopperComponent={FitContentPopper}
                  disablePortal
                  options={options}
                  value={element.selectedOption}
                  // @ts-ignore
                  groupBy={(option) => option.group}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label={element.label}
                      placeholder={element.label}
                    />
                  )}
                  renderGroup={(params) => (
                    <li key={params.key}>
                      <GroupHeader>{params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  )}
                  onChange={(_, value) => {
                    const updatedElements = [...elements];
                    if (!value) {
                      updatedElements.splice(index, 1);
                      setElements(updatedElements);
                    } else {
                      updatedElements[index].selectedOption = value;
                      setElements([...updatedElements]);
                    }

                    onChange(updatedElements);
                  }}
                />
              ) : (
                <Autocomplete
                  PopperComponent={FitContentPopper}
                  disablePortal
                  options={separators}
                  value={element.selectedOption}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label={element.label}
                      placeholder={element.label}
                    />
                  )}
                  onChange={(_, value) => {
                    const updatedElements = [...elements];
                    if (!value) {
                      updatedElements.splice(index, 1);
                      setElements(updatedElements);
                    } else {
                      updatedElements[index].selectedOption = value;
                      setElements([...updatedElements]);
                    }

                    onChange(updatedElements);
                  }}
                />
              )}
            </Grid>
          </Fragment>
        ))}

        {elements.filter((element) => element.label === "Element").length <=
          10 && (
          <Grid item>
            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
              <FontAwesomeIcon icon={faPlus} size="sm" />
            </IconButton>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  addElement("Element");
                }}
              >
                Element
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleClose();
                  addElement("Separator");
                }}
              >
                Separator
              </MenuItem>
            </Menu>
          </Grid>
        )}
      </Grid>

      {isFile ? (
        <>
          {elements.some((element) => element.selectedOption) && (
            <Grid
              container
              direction="row"
              mt={1}
              alignItems="center"
              rowSpacing={1}
            >
              <Grid item>
                <Box component="span" fontWeight="bolder">
                  Filename Preview:&nbsp;
                </Box>
              </Grid>

              <Grid item>
                <Chip
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                    },
                  }}
                  label={getPreviewLabel()}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <>
          {elements.some((element) => element.selectedOption) && (
            <>
              <Grid
                container
                direction="row"
                mt={1}
                alignItems="center"
                rowSpacing={1}
              >
                <Grid item>
                  <Box component="span" fontWeight="bolder">
                    Folder Structure Preview:&nbsp;
                  </Box>
                </Grid>
              </Grid>

              <Grid container mt={1}>
                <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      fontSize="10px"
                      height="10px"
                    />
                  }
                  defaultExpanded={[
                    "root",
                    ...Array(elements.length).keys(),
                  ].map((v) => v.toString())}
                  defaultExpandIcon={
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      fontSize="10px"
                      height="10px"
                    />
                  }
                  sx={{ width: "100%" }}
                >
                  {renderTree(generateTreeItems())}
                </TreeView>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}
