import { ElementNode } from "lexical";
import { v4 as uuid } from "uuid";
import { TESTING } from "../../../utils/constants";

/**
 * @typedef {object} ClauseMetadata
 * @property {string[]} clauseTypes
 * @property {string} clauseId
 */

/**
 * @typedef {ReturnType<ClauseNode["exportJSON"]>} SerializedClauseNode
 */

export class ClauseNode extends ElementNode {
  /** @type {string} */ __id;
  /** @type {string[]} */ __clauseTypes;
  /** @type {string[]} */ __workflows;
  /** @type {string[]} */ __libIDs;
  /** @type {string} */ __filter;
  /** @type {string} */ __lock;
  /** @type {ClauseVariant[]} */ __variants;

  static getType() {
    return "clause";
  }

  /**
   * @param {ClauseNode} node
   * @returns {ClauseNode}
   */
  static clone(node) {
    return new ClauseNode(
      Array.from(node.__clauseTypes),
      Array.from(node.__workflows),
      Array.from(node.__libIDs),
      node.__filter,
      node.__lock,
      Array.from(node.__variants),
      node.__id,
      node.__key
    );
  }

  /**
   * @param {string[]} clauseTypes
   * @param {string[]} workflows
   * @param {string[]} libIDs
   * @param {string} filter
   * @param {string} lock,
   * @param {ClauseVariant[]} variants
   * @param {string} [id]
   * @param {string} [key]
   */
  constructor(clauseTypes, workflows, libIDs, filter, lock, variants, id, key) {
    super(key);

    this.__id = !TESTING ? id || uuid() : uuid();
    this.__clauseTypes = clauseTypes || [];
    this.__workflows = workflows || [];
    this.__libIDs = libIDs || [];
    this.__filter = filter || "none";
    this.__lock = lock;
    this.__variants = variants || [];
  }

  get id() {
    const self = this.getLatest();
    return self.__id;
  }

  /**
   * @param {SerializedClauseNode} serializedNode
   */
  static importJSON(serializedNode) {
    // clauseTypes, workflows, libIDs, filter, lock.
    const node = $createClauseNode(serializedNode);
    return node;
  }

  exportJSON() {
    const serializedNode = {
      ...super.exportJSON(),
      id: this.__id,
      type: "clause",
      clauseTypes: this.getClauseTypes(),
      workflows: this.getWorkflows(),
      libIDs: this.getLibIDs(),
      lock: this.getLock(),
      variants: this.getVariants(),
      filter: "none",
      version: 1,
    };

    return serializedNode;
  }

  /**
   * @param {import("lexical").LexicalEditor} editor
   * @returns {import("lexical").DOMExportOutput}
   */
  exportDOM(editor) {
    const domExportOutput = super.exportDOM(editor);
    if (
      domExportOutput.element &&
      domExportOutput.element instanceof HTMLElement
    ) {
      domExportOutput.element.setAttribute("type", ClauseNode.getType());
    }

    return domExportOutput;
  }

  createDOM() {
    const element = document.createElement("div");
    element.id = this.getKey();
    return element;
  }

  /**
   * @param {*} prevNode
   * @param {*} element
   * @param {*} config
   */
  updateDOM(prevNode, element, config) {
    element.style = {};
    if (this.__filter === "in") {
      element.style.padding = "30px 35px";
      element.style.margin = "15px 0px";
      element.style.boxShadow = "rgba(0, 0, 0, 0.08) 0px 3px 24px 0px";
      element.style.borderRadius = "20px";
    } else if (this.__filter === "out") {
      element.style.display = "none";
    }
    return false;
  }

  importDOM() {
    return null;
  }

  isInline() {
    return false;
  }

  getFilter() {
    const self = this.getLatest();
    return self.__filter;
  }

  /**
   * @param {"none" | "in" | "out"} newFilter
   */
  changeFilter(newFilter) {
    const self = this.getWritable();
    self.__filter = newFilter;
  }

  getLock() {
    const self = this.getLatest();
    return self.__lock;
  }

  /**
   * @param {string} newLock 'none', 'own', 'cp', 'all'
   */
  changeLock(newLock) {
    const self = this.getWritable();
    self.__lock = newLock;
  }

  /**
   * @param {string} ctid
   */
  hasClauseType(ctid) {
    const clauseTypes = this.getClauseTypes();
    for (let i = 0; i < clauseTypes.length; i++) {
      if (ctid === clauseTypes[i]) {
        return true;
      }
    }
    return false;
  }

  /**
   * @returns {string[]}
   */
  getClauseTypes() {
    const self = this.getLatest();
    return $isClauseNode(self) ? self.__clauseTypes : [];
  }

  /**
   * @param {string[]} ctids
   */
  assignClauseTypes(ctids) {
    const self = this.getWritable();
    if ($isClauseNode(self)) {
      self.__clauseTypes = ctids;
    }
  }

  /**
   * @param {string} ctid
   */
  addClauseType(ctid) {
    const self = this.getWritable();
    if ($isClauseNode(self)) {
      const clauseTypes = self.__clauseTypes;
      self.__clauseTypes = clauseTypes;
      for (let i = 0; i < clauseTypes.length; i++) {
        // If we already have it, don't add again
        if (ctid === clauseTypes[i]) return;
      }
      clauseTypes.push(ctid);
    }
  }

  /**
   * @param {string} ctid
   */
  deleteClauseType(ctid) {
    const self = this.getWritable();
    if ($isClauseNode(self)) {
      const clauseTypes = self.__clauseTypes;
      self.__clauseTypes = clauseTypes;
      for (let i = 0; i < clauseTypes.length; i++) {
        if (ctid === clauseTypes[i]) {
          clauseTypes.splice(i, 1);
          return;
        }
      }
    }
  }

  /**
   * @param {string} wfid
   */
  hasWorkflow(wfid) {
    const workflows = this.getWorkflows();
    for (let i = 0; i < workflows.length; i++) {
      if (wfid === workflows[i]) {
        return true;
      }
    }
    return false;
  }

  /**
   * @param {string} wfid
   */
  addWorkflow(wfid) {
    const self = this.getWritable();
    if ($isClauseNode(self)) {
      const workflows = self.__workflows;
      self.__workflows = workflows;
      for (let i = 0; i < workflows.length; i++) {
        // If we already have it, don't add again
        if (wfid === workflows[i]) return;
      }
      workflows.push(wfid);
    }
  }

  /**
   * @param {string[]} workflowsToAdd
   */
  addWorkflows(workflowsToAdd) {
    const self = this.getWritable();
    const existingWorkflows = self.__workflows;
    for (const workflowToAdd of workflowsToAdd) {
      const workflowAlreadyExists = existingWorkflows.some(
        (existingWorkflow) => existingWorkflow === workflowToAdd
      );
      if (workflowAlreadyExists) continue;

      existingWorkflows.push(workflowToAdd);
    }
  }

  /**
   * @returns {string[]}
   */
  getWorkflows() {
    const self = this.getLatest();
    return $isClauseNode(self) ? self.__workflows : [];
  }

  /**
   * @param {string} wfid
   */
  deleteWorkflow(wfid) {
    const self = this.getWritable();
    if ($isClauseNode(self)) {
      const workflows = self.__workflows;
      self.__workflows = workflows;
      for (let i = 0; i < workflows.length; i++) {
        if (wfid === workflows[i]) {
          workflows.splice(i, 1);
          return;
        }
      }
    }
  }

  /**
   * @param {string} lid
   */
  hasLibID(lid) {
    const libIDs = this.getLibIDs();
    for (let i = 0; i < libIDs.length; i++) {
      if (lid === libIDs[i]) {
        return true;
      }
    }
    return false;
  }

  /**
   * @returns {string[]}
   */
  getLibIDs() {
    const self = this.getLatest();
    return $isClauseNode(self) ? self.__libIDs : [];
  }

  /**
   * @param {string} lid
   */
  addLibID(lid) {
    const self = this.getWritable();
    if ($isClauseNode(self)) {
      const libIDs = self.__libIDs;
      self.__libIDs = libIDs;
      for (let i = 0; i < libIDs.length; i++) {
        // If we already have it, don't add again
        if (lid === libIDs[i]) return;
      }
      libIDs.push(lid);
    }
  }

  /**
   * @param {string} lid
   */
  deleteLibID(lid) {
    const self = this.getWritable();
    if ($isClauseNode(self)) {
      const libIDs = self.__libIDs;
      self.__libIDs = libIDs;
      for (let i = 0; i < libIDs.length; i++) {
        if (lid === libIDs[i]) {
          libIDs.splice(i, 1);
          return;
        }
      }
    }
  }

  /**
   * @param {ClauseVariant} variant
   */
  addVariant(variant) {
    const self = this.getWritable();
    self.__variants.push(variant);
  }

  getVariants() {
    const self = this.getLatest();
    return self.__variants;
  }

  /**
   * @param {number} index
   */
  deleteVariant(index) {
    const self = this.getWritable();
    self.__variants.splice(index, 1);
  }
}

/**
 * @param {{clauseTypes: string[]; workflows: string[]; libIDs: string[]; filter: string; lock:string; id?: string; key?: string; variants: ClauseVariant[]}} _
 */
export function $createClauseNode({
  clauseTypes,
  workflows,
  libIDs,
  filter,
  lock,
  id,
  variants,
  key,
}) {
  const clauseNode = new ClauseNode(
    clauseTypes,
    workflows,
    libIDs,
    filter,
    lock,
    variants,
    id,
    key
  );
  return clauseNode;
}

/**
 * @param {import("lexical").LexicalNode | null | undefined} node
 * @return {node is ClauseNode}
 */
export function $isClauseNode(node) {
  return node instanceof ClauseNode;
}
