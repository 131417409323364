/**
 * Copies the workflows of the `originClauseNode` into the `destinationClauseNode`.
 *
 * @param {ClauseNode} originClauseNode
 * @param {ClauseNode} destinationClauseNode
 * @returns {void}
 */
export function copyClauseWorkflows(originClauseNode, destinationClauseNode) {
  const clauseNodeWorkflows = originClauseNode.getWorkflows() || [];
  if (clauseNodeWorkflows.length === 0) return;

  destinationClauseNode.addWorkflows(clauseNodeWorkflows);
}
