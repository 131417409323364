import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { globalStore } from "../state/store";

const useSummaryService = (docID, isTemplate, noParameters = false) => {
  const [state, dispatch] = useContext(globalStore);

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [signers, setSigners] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [document, setDocument] = useState(
    /** @type {* | undefined} */ (undefined)
  );
  const [parameters, setParameters] = useState([]);

  useEffect(() => {
    const document = isTemplate
      ? state.template.find((t) => t._id === docID)
      : state.agrs.find((agr) => agr._id === docID);

    setDocument(document);
  }, [state.template, state.agrs, docID, isTemplate]);

  useEffect(
    () => {
      getAllUsers();
      if (!noParameters) getParameters();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.parties, document?.signers, document?.collabs]
  );

  const buildUserArrays = (users) => {
    const collaborators = document?.collabs?.reduce((accumulator, current) => {
      const collaborator = users.find((u) => u._id === current.uid);
      if (!collaborator) return accumulator;

      return [
        ...accumulator,
        {
          ...collaborator,
          partyID: current.partyID,
        },
      ];
    }, []);

    const signers = document?.signers?.reduce((accumulator, current) => {
      const signer = users.find((u) => u._id === current.uid);
      if (!signer) return accumulator;

      return [
        ...accumulator,
        {
          ...signer,
          partyID: current.partyID,
          order: current.order,
          entityName: current.entityName,
        },
      ];
    }, []);

    setCollaborators(collaborators ?? []);
    setSigners(signers ?? []);
  };

  const getAllUsers = async () => {
    setLoading(true);

    //if there are parties
    if (!state.parties?.length) return buildUserArrays([]);

    // Now pull all users for counterparties
    // TODO: Need to consider performance if user bases are starting to grow - can't pull ALL users for ALL parties
    const orgString = state.parties
      .flatMap((p) => (p.orgID !== state.org._id ? p.orgID : []))
      .join(",");

    if (!orgString) return buildUserArrays([...state.users]);

    const result = await axios
      .get(`${state.settings.api}user/org/${orgString}`)
      .catch(() => {
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message:
              "Unable to retrieve all users - please reload or contact Canveo Support if the issue persists",
            severity: "error",
          },
        });
      });

    if (!result.data.success) return;

    buildUserArrays([...state.users, ...result.data.data]);
    setLoading(false);
  };

  const getParameters = async () => {
    const result = await axios
      .get(`${state.settings.api}document/${docID}/parameters`)
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message:
              "Unable to retrieve agreement parameters - please reload or contact Canveo Support if the issue persists",
            severity: "error",
          },
        });
      });

    if (!result || !result.data.success) return;

    setParameters(result.data.data);
  };

  return { document, signers, collaborators, parameters };
};

export default useSummaryService;
