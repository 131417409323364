import { createContext } from "react";
import { createPlaybook } from "../utils/createPlaybook";

export const NewPlaybookContext = createContext({
  playbook: createPlaybook(),
  setPlaybook:
    /** @type {React.Dispatch<React.SetStateAction<ReturnType<typeof createPlaybook>>>} */ (
      playbook
    ) => {},
});
