import ThumbAgr from "./ThumbAgr";

import { Box, Typography } from "@mui/material";

const CoverFlow = (props) => {
  return (
    <Box
      style={{
        display: "flex",
        gap: "16px",
        overflow: "auto",
      }}
      p={3}
    >
      {props.templates
        .sort((a, b) => (a.agrTitle > b.agrTitle ? 1 : -1))
        .map((template) => {
          template.agrStatus = "Active";
          return (
            <span key={template._id}>
              <ThumbAgr
                ag={template}
                actionReq={false}
                thumbClick={() =>
                  props.handleSelect(template._id, props.selectionType)
                }
                showLogo={props.logoURL}
                primaryLegalName={template.reference}
                secondaryLegalName={"Version " + template.version}
                isTemplate={true}
              />
            </span>
          );
        })}
      {!props.templates.length && (
        <Typography
          variant="subtitle"
          align="center"
          display="block"
          sx={{ mx: "auto" }}
        >
          You haven't created any templates for this area yet
        </Typography>
      )}
    </Box>
  );
};
export default CoverFlow;
