import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { faCircleXmark, faPen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton } from "@mui/material";
import React, { Fragment, useState } from "react";
import { getMergeFieldDisplayValue } from "../../../../../MergeFieldMenu/utils";
import NewClauseVariantConditionDialog from "./NewClauseVariantConditionDialog";

/**
 * @typedef {object} AddConditionProps
 * @property {Condition[]} conditions
 * @property {(conditions: Condition[]) => void} setConditions
 * @property {Omit<import("./NewClauseVariantConditionDialog").NewClauseVariantConditionDialogProps, "condition" | "closeNewConditionDialog" | "openNewConditionDialog">} newConditionDialogProps
 */

/**
 * @param {AddConditionProps} props
 * @returns {JSX.Element}
 */
export default function AddCondition({
  conditions,
  setConditions,
  newConditionDialogProps,
}) {
  const [conditionToEdit, setConditionToEdit] = useState(
    /** @type {Condition | null} */ (null)
  );
  const [openNewConditionDialog, setOpenNewConditionDialog] = useState(false);

  return (
    <>
      {!conditions.length ? (
        <Grid container>
          <div
            onClick={() => {
              setConditionToEdit(null);
              setOpenNewConditionDialog(true);
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              outline: "#7243dd dashed 1px",
              borderRadius: "20px",
              height: "60px",
              width: "286px",
              color: "#7243dd",
              textAlign: "center",
            }}
          >
            <Grid item xs={1} />

            <Grid item xs={1}>
              <FontAwesomeIcon
                icon={faCirclePlus}
                style={{
                  fontSize: "24px",
                }}
              />
            </Grid>

            <Grid item xs={6} style={{ fontSize: "14px" }}>
              <div>Add Condition ...</div>
            </Grid>

            <Grid item xs={4} />
          </div>
        </Grid>
      ) : (
        <>
          {conditions.map((condition, index) => (
            <Grid key={condition.id} container>
              <Grid container>
                <div
                  style={{
                    padding: "20px 10px 5px 20px",
                    outline: "#7243dd dashed 1px",
                    borderRadius: "20px",
                    width: "286px",
                    color: "#7243dd",
                  }}
                >
                  <Grid container direction="row">
                    <div>{getConditionSummaryHtml(condition)}</div>
                  </Grid>

                  <Grid container direction="row">
                    <Grid item xs={10} />

                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => {
                          setConditionToEdit(condition);
                          setOpenNewConditionDialog(true);
                        }}
                      >
                        <FontAwesomeIcon
                          color="#7243dd"
                          // @ts-ignore
                          icon={faPen}
                          style={{
                            fontSize: "14px",
                          }}
                        />
                      </IconButton>
                    </Grid>

                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => {
                          setConditions([
                            ...conditions.slice(0, index),
                            ...conditions.slice(index + 1),
                          ]);
                        }}
                      >
                        <FontAwesomeIcon
                          color="#7243dd"
                          // @ts-ignore
                          icon={faCircleXmark}
                          style={{
                            fontSize: "14px",
                          }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              {index !== conditions.length - 1 ? (
                <Grid
                  container
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "286px",
                  }}
                >
                  <Grid
                    item
                    xs={5}
                    sx={{
                      paddingLeft: "38px",
                    }}
                  >
                    <hr
                      style={{
                        height: "1px",
                        backgroundColor: "#ccc",
                        border: "none",
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    sx={{
                      textAlign: "center",
                      fontWeight: "400",
                      fontSize: "12px",
                    }}
                  >
                    OR
                  </Grid>

                  <Grid item xs={5} sx={{ paddingRight: "38px" }}>
                    <hr
                      style={{
                        height: "1px",
                        backgroundColor: "#ccc",
                        border: "none",
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          ))}

          <Grid container>
            <br />
          </Grid>

          {/* TODO: Check the limit of conditions. Capped at 3 for the time being. */}
          {conditions.length < 3 && (
            <Grid
              container
              direction="row"
              sx={{ width: "286px" }}
              justifyContent="flex-end"
            >
              <span
                style={{
                  color: "#7243dd",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setConditionToEdit(null);
                  setOpenNewConditionDialog(true);
                }}
              >
                Add Condition ...
              </span>
            </Grid>
          )}
        </>
      )}

      {openNewConditionDialog && (
        <NewClauseVariantConditionDialog
          {...newConditionDialogProps}
          openNewConditionDialog={openNewConditionDialog}
          condition={conditionToEdit}
          closeNewConditionDialog={() => {
            setConditionToEdit(null);
            setOpenNewConditionDialog(false);
          }}
        />
      )}
    </>
  );
}

/**
 * @param {Condition} condition
 */
export const getConditionSummary = (condition) => {
  const summary = {
    baseValueName: condition.baseValue.name,
    conditions: [
      {
        operatorLabel: condition.operator.label,
        comparisonValues: condition.comparisonValue,
      },
    ],
    value: getMergeFieldDisplayValue(condition.mergeFieldValue),
  };

  return summary;
};

/**
 * @param {Condition} condition
 * @returns {JSX.Element}
 */
function getConditionSummaryHtml(condition) {
  const { baseValueName, conditions } = getConditionSummary(condition);

  return (
    <>
      <span style={{ fontSize: "14px", wordBreak: "break-word" }}>
        When <b>{baseValueName}</b>{" "}
        {conditions.map((condition, index) => {
          return (
            <Fragment key={index}>
              {condition.operatorLabel}{" "}
              <b>
                {(() => {
                  if (condition.comparisonValues instanceof Array) {
                    return condition.comparisonValues.join(", ");
                  }

                  return getMergeFieldDisplayValue(condition.comparisonValues);
                })()}
              </b>
            </Fragment>
          );
        })}
        .
      </span>
    </>
  );
}
