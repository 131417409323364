import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import axios from "axios";
import { $getRoot } from "lexical";
import { forwardRef, useContext, useImperativeHandle } from "react";
import { globalStore } from "../../../state/store";
import $convertLexicalToSfdt from "../converters/convertLexicalToSfdt";

const config = {
  docx: {
    urlPath: "document/export",
    defaultDownloadFileName: "agreement.docx",
  },
  pdf: {
    urlPath: "document/exportPdf",
    defaultDownloadFileName: "agreement.pdf",
  },
};

export default forwardRef(
  /**
   * @param {{ agrVersion: *; partyId: string; }} props
   * @param {*} ref
   */
  // @ts-ignore
  ({ agrVersion, partyId }, ref) => {
    // @ts-ignore
    const [state] = useContext(globalStore);
    const [editor] = useLexicalComposerContext();

    /**
     * @param {keyof typeof config} type
     * @param {string} filename
     * @param {*[]} documentComments
     */

    useImperativeHandle(ref, () => ({
      /**
       * Used to export a document to Word or PDF.
       *
       * @param {{type: "docx" | "pdf"; filename: string; agrId: string}} document
       * @param {boolean} isAgreementOwner
       * @param {"read" | "full"} editMode
       */
      async export({ type, filename, agrId }, isAgreementOwner, editMode) {
        const documentCommentsResponse = await axios({
          url: `${state.settings.api}document/${agrId}/comments`,
          method: "GET",
        });
        const documentComments = documentCommentsResponse.data.data;

        const sfdt = editor.getEditorState().read(() => {
          const root = $getRoot();
          // TODO: This should be sent via parameter.
          const documentSfdt =
            state?.drawerVersions?.active?.sfdt ?? agrVersion.sfdt;
          const metadata = {
            agreementId: agrVersion.agrID,
            entityId: state.parties.find(
              (/** @type {{ orgID: string; }} */ party) =>
                party.orgID === state.user.orgID
            )?._id,
            orgId: state.user.orgID,
            partyId,
          };
          const listsStructure =
            state?.drawerVersions?.active?.contentMetadata?.listsStructure ??
            agrVersion?.contentMetadata?.listsStructure ??
            [];

          const generatedSfdt = $convertLexicalToSfdt(
            root,
            documentSfdt,
            metadata,
            documentComments,
            listsStructure
          );

          return generatedSfdt;
        });

        const isReadonly = !isAgreementOwner && editMode === "read";

        const downloadFileResponse = await axios.post(
          `${state.settings.api}${config[type].urlPath}`,
          { sfdt, isReadonly }
        );
        const downloadFileResponseData = await downloadFileResponse.data;

        const fileResponse = await axios.get(
          downloadFileResponseData.data.url,
          { responseType: "blob" }
        );

        const downloadFileAnchor = document.createElement("a");
        downloadFileAnchor.href = window.URL.createObjectURL(fileResponse.data);
        downloadFileAnchor.download = filename
          ? `${filename}.${type}`
          : config[type].defaultDownloadFileName;
        downloadFileAnchor.click();
      },
      /**
       * Exports entire document to PDF i.e., if the document has exhibits it combines
       * the main document plus all exhibits in one PDF.
       *
       * @param {{filename: string; agrId: string}} _
       * @param {boolean} isTemplate
       * @param {boolean} isInEffect
       */
      async fullExport({ filename, agrId }, isTemplate, isInEffect) {
        // If the agreement is in status "In Effect" we download the signed PDF.
        if (isInEffect) {
          const res = await axios({
            url: `${state.settings.api}agr/${agrId}/download`, //your url
            method: "GET",
            validateStatus: () => true,
          });

          const { data } = res;

          const existingSignedPDF = data.data;
          if (existingSignedPDF) {
            const blobFile = new Blob(
              [new Uint8Array(existingSignedPDF.data)],
              {
                type: "application/pdf",
              }
            );
            const href = URL.createObjectURL(blobFile);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", filename + ".pdf");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          }
        }
        // Otherwise, we generate a PDF from the current version.
        else {
          const response = await axios.get(
            `${state.settings.api}${
              isTemplate ? "template" : "agr"
            }/exportDetails/${agrId}`
          );
          const documents = response?.data?.data;
          if (!documents?.length) {
            throw new Error("Error getting agreement details.");
          }

          for (const document of documents) {
            if (!document.content) continue;

            const commentsResponse = await axios.get(
              `${state.settings.api}document/${document._id}/comments`
            );

            document.sfdt = editor
              .parseEditorState(document.content)
              .read(() => {
                const root = $getRoot();
                const documentSfdt = document.sfdt;
                const metadata = {};
                const comments = commentsResponse?.data?.data;
                const listsStructure =
                  document?.contentMetadata?.listsStructure || [];

                const generatedSfdt = $convertLexicalToSfdt(
                  root,
                  documentSfdt,
                  metadata,
                  comments,
                  listsStructure
                );
                return generatedSfdt;
              });
          }

          const downloadFileResponse = await axios.post(
            `${state.settings.api}document/fullExport`,
            {
              agreements: documents,
            }
          );
          const downloadFileResponseData = await downloadFileResponse.data;
          const fileResponse = await axios.get(
            downloadFileResponseData.data.url,
            { responseType: "blob" }
          );

          const downloadFileAnchor = document.createElement("a");
          downloadFileAnchor.href = window.URL.createObjectURL(
            fileResponse.data
          );
          downloadFileAnchor.download = `${filename}.pdf`;
          downloadFileAnchor.click();
        }
      },
    }));
  }
);
