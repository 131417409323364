import { $isCustomListItemNode } from "../../nodes/CustomListItemNode";

/**
 * @param {ReturnType<import("@lexical/utils").$dfs>} dfs
 */
export function getListItemsFromDfs(dfs) {
  const listItemNodes = dfs.filter(
    ({ node }) => $isCustomListItemNode(node)
    // Commented out to allow list elements with ID -1.
    // && node.getListId() !== -1
  );

  const listItems = listItemNodes.map(({ node }) => {
    return {
      level: node.getIndent(),
      id: node.getKey(),
      value: node.getValue(),
      parentId: "",
      listId: node.getListId(),
    };
  });

  return listItems;
}
