/**
 *
 * @param {*} entity
 * @param {*} addressType
 * @returns {*} an Object {} (if empty) or Address Object: { zip, state, etc. }
 */
export default function getAddressForEntity(entity, addressType) {
  let a = {};

  const address = entity?.address?.find(
    (/** @type {{ addressType: string; }} */ a) => a.addressType === addressType
  );

  if (
    entity !== undefined &&
    entity !== null &&
    entity.address !== undefined &&
    entity.address !== null &&
    addressType !== undefined &&
    addressType !== null &&
    address !== undefined
  ) {
    a = address;
  }

  return a;
}
