import { $applyNodeReplacement, ElementNode } from "lexical";

export class CaptionNode extends ElementNode {
  /**
   *
   * @param {string?} [key]
   */
  constructor(key) {
    super(key);
  }

  static getType() {
    return "caption";
  }

  static clone(node) {
    return new CaptionNode(node.__key, node.__for);
  }

  createDOM() {
    //this is called first
    const caption = document.createElement("div");
    caption.setAttribute("role", "caption");
    return caption;
  }

  updateDOM() {
    return false;
  }

  static importJSON(serializedNode) {
    const caption = $createCaptionNode(serializedNode.__key);
    return caption;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: "caption",
    };
  }
}

export function $isCaptionNode(node) {
  return node instanceof CaptionNode;
}

/**
 * @param {string?} [key]
 * @returns {CaptionNode}
 */
export function $createCaptionNode(key) {
  return $applyNodeReplacement(new CaptionNode(key));
}
