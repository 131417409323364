import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Chip,
  Grid,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import React from "react";
import defaultLogo from "../../../assets/img/defaultlogo.png";
import { ThumbAgrTooltip } from "../../../components";
import theme from "../../../theme/theme";
import { getTimeIndicationForDate } from "../../../utils";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.contrastText,
    //backgroundColor: 'rgba(255,255,255,0.7)',
    //backdropFilter: 'blur(10px)',
    color: theme.palette.grey[800],
    maxWidth: 320,
    //fontSize: theme.typography.pxToRem(12),
    border: "1px solid" + theme.palette.grey[300],
    padding: "20px",
    margin: "0px",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 3px 24px 0px",
  },
}));

/**
 * @typedef {*} PlaybookCardProps
 */

/**
 * @param {PlaybookCardProps} props
 * @returns {JSX.Element}
 */
export default function PlaybookCard(props) {
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [openTooltip, setOpenTooltip] = React.useState(false);
  const positionRef = React.useRef({
    x: 0,
    y: 0,
  });

  const popperRef = React.useRef(null);
  const areaRef = React.useRef(null);

  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  const handleContextClick = (e) => {
    e.preventDefault();
    setOpenTooltip(true);
  };

  /*const handleClick = (e) => {
    if (e.type === "click" && ) {
        props.thumbClick();
    } else if (e.type === "contextmenu") {
        console.log("Right click");
    }
  }*/

  return (
    <HtmlTooltip
      open={openTooltip}
      onClose={() => setOpenTooltip(false)} /*onOpen={handleOpen}*/
      placement={"right"}
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(
              positionRef.current.x,
              areaRef.current.getBoundingClientRect().y,
              0,
              0
            );
          },
        },
      }}
      title={<ThumbAgrTooltip ag={props.ag} />}
    >
      <Box
        ref={areaRef}
        onMouseMove={handleMouseMove}
        sx={{
          [theme.breakpoints.only("xs")]: { width: "150px" },
          [theme.breakpoints.only("sm")]: { width: "180px" },
          [theme.breakpoints.up("md")]: { width: "200px" },
          "&:hover":
            props.thumbClick !== null
              ? {
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 24px 0px",
                  cursor: "pointer",
                }
              : {},
          padding: "2px 0px 2px 0px",
          border: "1px solid" + theme.palette.grey[300],
          borderRadius: "20px",
          textAlign: "center",
          pt: 3,
          pb: 1,
          px: 2,
          boxSizing: "border-box",
          minHeight: "241px",
        }}
        onClick={props.thumbClick !== null ? props.thumbClick : (e) => null}
        onContextMenu={(e) => handleContextClick(e)}
      >
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Avatar
              src={
                props.showLogo !== undefined && props.showLogo !== null
                  ? props.showLogo
                  : defaultLogo
              }
              style={{
                height: "30px",
                width: "30px",
                backgroundColor: theme.palette.primary.contrastText,
              }}
            />
          </Grid>
          <Grid item sx={{ mt: 2, mb: 2, height: "30px" }}>
            <Typography
              align="center"
              style={{
                fontWeight: "700",
                lineHeight: "15px",
                fontSize: smUp ? "14px" : "12px",
              }}
            >
              {props.ag.name || props.ag.playbookName}
              {/* {processLongString(
                props.isTemplate &&
                  state.agrTypes.filter(
                    (at) => at._id === props.ag.agrTypeID
                  )[0] !== undefined &&
                  state.agrTypes.filter(
                    (at) => at._id === props.ag.agrTypeID
                  )[0].fullName[0] !== undefined
                  ? state.agrTypes.filter(
                      (at) => at._id === props.ag.agrTypeID
                    )[0].fullName[0] // AgrType title for Templates
                  : props.ag.agrTitle, // Agreement title for Agreements
                19,
                27
              )} */}
            </Typography>
          </Grid>
          <Grid item>
            <Box
              mt={0}
              mb={0}
              sx={{
                display: { xs: "none", sm: "block" },
                marginBottom: "15px",
              }}
            >
              <Typography
                align="center"
                variant={"body2"}
                sx={{
                  fontSize: "11px",
                  fontWeight: props.isTemplate ? "600" : "300",
                }}
              >
                {/* {Boolean(props.additionalParties) &&
                props.additionalParties.filter((ap) => ap.side === "primary")
                  .length > 0 ? (
                  <>
                    {trunc(props.primaryLegalName, 20)}
                    <span className="bold">
                      &nbsp;&nbsp;+
                      {
                        props.additionalParties.filter(
                          (ap) => ap.side === "primary"
                        ).length
                      }
                    </span>
                  </>
                ) : (
                  trunc(props.primaryLegalName, props.fromLegal ? 25 : 27)
                )} */}
              </Typography>
              <Typography
                align="center"
                variant="body2"
                sx={{ fontSize: "11px", fontWeight: "300" }}
              >
                {/* {Boolean(props.additionalParties) &&
                props.additionalParties.filter((ap) => ap.side === "secondary")
                  .length > 0 ? (
                  <>
                    {trunc(props.secondaryLegalName, 20)}
                    <span className="bold">
                      &nbsp;&nbsp;+
                      {
                        props.additionalParties.filter(
                          (ap) => ap.side === "secondary"
                        ).length
                      }
                    </span>
                  </>
                ) : (
                  trunc(props.secondaryLegalName, 27)
                )} */}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ mb: 2 }}>
              <Chip
                variant={
                  ["Active"].includes(props.ag.agrStatus)
                    ? "filled"
                    : "outlined"
                }
                label={
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "0px 5px",
                    }}
                  >
                    {props.ag.agrStatus === "InEffect"
                      ? "In Effect"
                      : props.ag.agrStatus}
                    {props.actionReq &&
                    !["Active", "InEffect"].includes(props.ag.agrStatus) ? (
                      <FontAwesomeIcon
                        icon={faPen}
                        style={{
                          fontSize: "10px",
                          paddingLeft: "6px",
                          color: theme.palette.primary.main,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                }
                size="small"
                style={
                  ["Active"].includes(props.ag.agrStatus)
                    ? {
                        color: theme.palette.primary.contrastText,
                        backgroundColor: theme.palette.primary.main,
                        cursor: props.thumbClick !== null ? "pointer" : "auto",
                      }
                    : {
                        color: theme.palette.grey[700],
                        cursor: props.thumbClick !== null ? "pointer" : "auto",
                      }
                }
              />

              <Typography
                align="center"
                variant="body2"
                color="textSecondary"
                style={{ fontSize: "12px", marginTop: "10px" }}
              >
                {["To be created"].includes(props.ag.lastUpdateDate) ? (
                  "Being created"
                ) : (
                  <>
                    <span style={!smUp ? { display: "none" } : {}}>
                      Last update:{" "}
                    </span>
                    {getTimeIndicationForDate(props.ag.lastUpdateDate)}
                  </>
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </HtmlTooltip>
  );
}
