const workflowsReducer = (state, action) => {
  switch (action.type) {
    case "INIT_WORKFLOWS":
      return { workflows: action.payload };
    case "ADD_WORKFLOW":
      return {
        workflows: [...state.workflows, action.payload],
      };
    case "UPDATE_WORKFLOWS":
      const updatedWorkflows = state.workflows.map((workflow) => {
        if (workflow._id === action.payload._id) return action.payload;
        return workflow;
      });
      return { workflows: updatedWorkflows };
    default:
      return { workflows: state.workflows };
  }
};

export default workflowsReducer;
