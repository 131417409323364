import {
  faCaretRight,
  faFileContract,
  faLoader,
  faPaperPlane,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";

/**
 * @typedef {object} Ticket
 * @property {string} _id
 * @property {object} organization
 * @property {string} organization._id
 * @property {object} agreement
 * @property {string} agreement._id
 * @property {object} assignee
 * @property {string} assignee._id
 * @property {string} assignee.displayName
 * @property {string} assignee.photoURL
 * @property {string} assignee.title
 * @property {object} requestor
 * @property {string} requestor._id
 * @property {string} requestor.displayName
 * @property {string} requestor.photoURL
 * @property {string} requestor.title
 * @property {string} type
 * @property {string} status
 * @property {*[]} documents
 * @property {string} documents.fileType
 * @property {string} documents.file
 * @property {string} documents.fileName
 * @property {string} documents.uploadType
 * @property {null} documents.agrType
 * @property {object} documents.fileSource
 * @property {string} documents.fileSource.label
 * @property {string} documents.fileSource.value
 * @property {object} areaLabel
 * @property {string} areaLabel._id
 * @property {string} areaLabel.name
 * @property {object} request
 * @property {string} request.name
 * @property {string} request.description
 * @property {string} request._id
 * @property {string} createdAt
 * @property {string} updatedAt
 */

/**
 * @typedef {object} DialogTicketTaskDetailsProps
 * @property {string} taskId
 * @property {boolean} open
 * @property {() => void} close
 */

/**
 * @param {DialogTicketTaskDetailsProps} props
 * @returns {React.JSX.Element}
 */
export function DialogTicketTaskDetails({ taskId, open, close }) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [task, setTask] = useState(
    /** @type {Ticket | undefined} */ (undefined)
  );

  useEffect(
    () => {
      loadData();
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  async function loadData() {
    try {
      setIsLoading(true);

      const getTaskByIdResponse = await axios.get(
        `${state.settings.api}task/${taskId}`
      );
      const task = /** @type {Ticket} */ (getTaskByIdResponse.data.data);

      setTask(task);
    } catch (error) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "An error occurred while retrieving the task.",
          severity: "error",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  if (!task) return <></>;

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>
        <Grid container>{`${task.type}: ${task.request.name}`}</Grid>

        <Grid container>
          <Typography variant="body2" color="textSecondary">
            {task.areaLabel.name}
          </Typography>
        </Grid>
      </DialogTitle>

      <DialogContent sx={{ paddingX: 10 }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Grid item mb={2}>
                <Typography>Requestor</Typography>
              </Grid>

              <Grid item>
                <Box
                  component="img"
                  src={task.requestor.photoURL}
                  borderRadius="50%"
                  height="50px"
                  width="50px"
                />
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {task.requestor.displayName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  ({task.requestor.title})
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <FontAwesomeIcon
              color={theme.palette.grey[300]}
              icon={faCaretRight}
              fontSize={30}
            />
          </Grid>

          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Grid item mb={2}>
                Assignee
              </Grid>
              <Grid item>
                <Box
                  component="img"
                  src={task.assignee.photoURL}
                  borderRadius="50%"
                  height="50px"
                  width="50px"
                />
              </Grid>

              <Grid item>
                <Typography variant="caption">
                  {task.assignee.displayName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  ({task.assignee.title})
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <FontAwesomeIcon
              color={theme.palette.grey[300]}
              icon={faCaretRight}
              fontSize={30}
            />
          </Grid>

          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Grid item mb={2}>
                Status
              </Grid>
              <Grid item>
                <Box
                  component="div"
                  borderRadius="50%"
                  height="50px"
                  width="50px"
                  sx={{
                    backgroundColor: theme.palette.grey[400],
                  }}
                >
                  <FontAwesomeIcon
                    color="white"
                    icon={faLoader}
                    fontSize={30}
                    style={{
                      position: "relative",
                      top: 10,
                      left: 10,
                    }}
                  />
                </Box>
              </Grid>

              <Grid item>
                <Typography variant="caption">{task.status}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">&nbsp;</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" mt={6}>
          <Typography fontWeight="bold" fontStyle="italic" textAlign="justify">
            {task.request.description}
          </Typography>
        </Grid>

        <Grid container direction="row" gap={2} mt={6}>
          <Grid item>
            <FontAwesomeIcon
              icon={faFileContract}
              color={theme.palette.grey[800]}
            />
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">Uploaded documents</Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" mt={2}>
          {task.documents.map((document, index) => (
            <Grid
              container
              key={index}
              gap={2}
              mb={task.documents.length - 1 === index ? 2 : undefined}
            >
              <Grid item>
                <Box component="span">
                  <Link
                    sx={{
                      color: "blue",
                      textDecorationColor: "blue",
                      fontSize: "13px",
                    }}
                    href="#"
                  >
                    {document.fileName}
                  </Link>
                </Box>
              </Grid>
              <Grid item>
                <Box component="span">
                  <IconButton onClick={() => {}}>
                    <FontAwesomeIcon
                      icon={faTimes}
                      style={{ fontSize: "10px" }}
                    />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid container direction="row">
          <Typography
            variant="body2"
            fontWeight="600"
            color={theme.palette.primary.main}
            sx={{ cursor: "pointer" }}
            onClick={() => {}}
          >
            Add Document ...
          </Typography>
        </Grid>

        <Grid container direction="row" mt={8}>
          <Grid item xs={1}>
            <Box
              component="img"
              src={task.requestor.photoURL}
              borderRadius="50%"
              height="30px"
              width="30px"
            />
          </Grid>

          <Grid item xs={11}>
            <TextField
              fullWidth
              placeholder="Insert Comment"
              multiline
              rows={2}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="right">
          <Grid item>
            <Button
              endIcon={
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  style={{
                    fontSize: "14px",
                  }}
                />
              }
            >
              Submit
            </Button>
          </Grid>
        </Grid>

        {!task?.agreement?._id && (
          <Grid container mt={4}>
            <Button
              variant="outlined"
              onClick={() => navigate(`/new?ticketId=${task._id}`)}
            >
              Create agreement ...
            </Button>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        {!isLoading && (
          <>
            <Button onClick={close}>Delete Ticket ...</Button>

            <Button
              variant="outlined"
              disableElevation
              color="primary"
              onClick={close}
            >
              Edit Ticket
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
