const allowedCharacters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

/**
 * @param {number} length
 * @returns {string}
 */
export default function randomString(length) {
  let string = "";

  for (let index = 0; index < length; index++) {
    const random = Math.random() * allowedCharacters.length;
    const floor = Math.floor(random);
    const character = allowedCharacters.charAt(floor);

    string += character;
  }

  return string;
}
