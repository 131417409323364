const { useState, useEffect } = require("react");

/**
 * @param {import("lexical").SerializedEditorState<import("lexical").SerializedLexicalNode> | undefined} inputValue
 * @param {number} delay
 * @returns {import("lexical").SerializedEditorState<import("lexical").SerializedLexicalNode> | undefined}
 */
export function useDebouncedValue(inputValue, delay) {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
}
