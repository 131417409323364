const initialState = {
  currentStyle: {},
};

export const INIT_EDITOR = "INIT_EDITOR";
export const UPDATE_CURRENT_STYLE = "UPDATE_CURRENT_STYLE";
const editorReducer = (state, action) => {
  switch (action.type) {
    case INIT_EDITOR:
      return { editor: action.payload };
    case UPDATE_CURRENT_STYLE:
      return {
        editor: {
          ...state.editor,
          currentStyle: {
            ...state.editor.currentStyle,
            ...action.payload,
          },
        },
      };
    default:
      return { editor: initialState };
  }
};

export default editorReducer;
