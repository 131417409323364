/**
 * @param {{ name: string; }} file
 * @param {string[]} allowedExtensions
 * @returns {boolean}
 */
export function fileHasAllowedExtension(file, allowedExtensions = []) {
  const hasValidExtension = allowedExtensions.some((extension) =>
    file.name.toLowerCase().endsWith(extension.toLowerCase())
  );
  return hasValidExtension;
}
