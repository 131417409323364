import { DecoratorNode, createEditor } from "lexical";
import React, { Suspense } from "react";
import ImageComponent from "./ImageComponent";

/**
 * @typedef {object} ImagePayload
 * @property {string} altText
 * @property {import("lexical").LexicalEditor?} caption
 * @property {number?} height
 * @property {import("lexical").NodeKey?}  key
 * @property {number?} maxWidth
 * @property {boolean?} showCaption
 * @property {string} src
 * @property {number?} width
 * @property {boolean} captionsEnabled
 */

/**
 * @param {HTMLImageElement} domNode
 * @returns
 */
function convertImageElement(domNode) {
  if (domNode instanceof HTMLImageElement) {
    const { alt: altText, src } = domNode;
    const node = $createImageNode({ altText, src });
    return { node };
  }
  return null;
}

/**
 * @typedef {Partial<AbstractBaseImageNodeMetadata>} ImageNodeMetadata
 */

/**
 * @typedef {object} AbstractBaseImageNodeMetadata
 * @property {string} name
 * @property {boolean} visible
 * @property {number} width
 * @property {number} height
 * @property {number} widthScale
 * @property {number} heightScale
 * @property {string} textWrappingStyle
 * @property {string} textWrappingType
 * @property {number} verticalPosition
 * @property {string} verticalOrigin
 * @property {string} verticalAlignment
 * @property {number} verticalRelativePercent
 * @property {number} horizontalPosition
 * @property {string} horizontalOrigin
 * @property {string} horizontalAlignment
 * @property {number} horizontalRelativePercent
 * @property {number} heightRelativePercent
 * @property {number} widthRelativePercent
 * @property {number} zOrderPosition
 * @property {boolean} allowOverlap
 * @property {boolean} layoutInCell
 * @property {number} distanceBottom
 * @property {number} distanceLeft
 * @property {number} distanceRight
 * @property {number} distanceTop
 * @property {boolean} belowText
 * @property {string} imageString
 * @property {number} length
 * @property {boolean} isInlineImage
 * @property {boolean} isMetaFile
 * @property {number} top
 * @property {number} bottom
 * @property {number} right
 * @property {number} left
 * @property {number} getimageheight
 * @property {number} getimagewidth
 */

/**
 * @typedef {ReturnType<ImageNode["exportJSON"]>} SerializedImageNode
 */

/**
 * @extends {DecoratorNode<import("react").ReactNode>}
 */
export class ImageNode extends DecoratorNode {
  __src;
  __altText;
  __width;
  __height;
  __maxWidth;
  __showCaption;
  __caption;
  // Captions cannot yet be used within editor cells
  __captionsEnabled;
  __isInline;
  __horizontalPosition;
  __float;
  __origin;

  /** @private @type {ImageNodeMetadata} Object containing Syncfusion image metadata. */
  __metadata;

  /**
   *
   * @param {*} src
   * @param {*} altText
   * @param {*} maxWidth
   * @param {*} width
   * @param {*} height
   * @param {*} showCaption
   * @param {*} caption
   * @param {*} captionsEnabled
   * @param {*} isInline
   * @param {*} horizontalPosition
   * @param {*} origin
   * @param {*} float
   * @param {*} metadata
   * @param {*} key
   */
  constructor(
    src,
    altText,
    maxWidth,
    width,
    height,
    showCaption,
    caption,
    captionsEnabled,
    isInline,
    horizontalPosition,
    origin,
    float = "left",
    metadata = {},
    key
  ) {
    super(key);
    this.__src = src;
    this.__altText = altText;
    this.__maxWidth = maxWidth;
    this.__width = width || "inherit";
    this.__height = height || "inherit";
    this.__showCaption = showCaption || false;
    this.__caption = caption || createEditor();
    this.__captionsEnabled = captionsEnabled || captionsEnabled === undefined;
    this.__isInline = isInline ?? false;
    this.__horizontalPosition = horizontalPosition;
    this.__origin = origin;
    this.__float = float;

    this.__metadata = metadata;
  }
  static getType() {
    return "image";
  }

  /**
   * @param {ImageNode} node
   * @returns {ImageNode}
   */
  static clone(node) {
    return new ImageNode(
      node.__src,
      node.__altText,
      node.__maxWidth,
      node.__width,
      node.__height,
      node.__showCaption,
      node.__caption,
      node.__captionsEnabled,
      node.__isInline,
      node.__horizontalPosition,
      node.__origin,
      node.__float,
      node.__metadata,
      node.__key
    );
  }

  /**
   * @param {SerializedImageNode} serializedNode
   * @returns {ImageNode}
   */
  static importJSON(serializedNode) {
    const {
      altText,
      height,
      width,
      maxWidth,
      caption,
      src,
      showCaption,
      isInline,
      horizontalPosition,
      origin,
      float,
      metadata,
    } = serializedNode;
    const node = $createImageNode(
      {
        altText,
        height,
        maxWidth,
        showCaption,
        src,
        width,
        isInline,
        horizontalPosition,
        origin,
        float,
      },
      metadata
    );
    const nestedEditor = node.__caption;
    const editorState = nestedEditor.parseEditorState(caption.editorState);
    if (!editorState.isEmpty()) {
      nestedEditor.setEditorState(editorState);
    }
    return node;
  }

  exportDOM() {
    const element = document.createElement("img");
    element.setAttribute("src", this.__src);
    element.setAttribute("alt", this.__altText);
    return { element };
  }

  static importDOM() {
    return {
      img: (/** @type {any} */ node) => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    };
  }

  exportJSON() {
    return {
      altText: this.getAltText(),
      caption: this.__caption.toJSON(),
      height: this.__height === "inherit" ? 0 : this.__height,
      maxWidth: this.__maxWidth,
      showCaption: this.__showCaption,
      isInline: this.__isInline,
      float: this.__float,
      origin: this.__origin,
      horizontalPosition: this.__horizontalPosition,
      src: this.getSrc(),
      type: "image",
      version: 1,
      width: this.__width === "inherit" ? 0 : this.__width,
      metadata: this.__metadata,
    };
  }

  /**
   * @param {*} width
   * @param {*} height
   */
  setWidthAndHeight(width, height) {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  /**
   * @param {*} showCaption
   */
  setShowCaption(showCaption) {
    const writable = this.getWritable();
    writable.__showCaption = showCaption;
  }

  /**
   * @param {import("lexical").EditorConfig} config
   * @returns {HTMLElement}
   */
  createDOM(config) {
    const span = document.createElement("span");
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) {
      span.className = className;
    }

    const style = {
      display: "inline-block",
      height: `${this.__height}px`,
      maxWidth: "100%",
      width: `${this.__width}px`,
      aspectRatio: this.__width / this.__height,
    };

    if (!this.__isInline) {
      // @ts-ignore
      style.float = this.__float;
      // @ts-ignore
      style.clear = this.__float;
      if (this.__float === "left") {
        // @ts-ignore
        style.marginLeft = `${this.__horizontalPosition}px`;
      } else {
        // @ts-ignore
        style.marginRight = `calc(var(--lexical-doc-width) - ${this.__width}px - ${this.__horizontalPosition}px)`;
      }
      //144pt is the margins
    }
    Object.keys(style).forEach((key) => {
      // @ts-ignore
      span.style[key] = style[key];
    });
    return span;
  }

  updateDOM() {
    return false;
  }

  getSrc() {
    return this.__src;
  }

  getAltText() {
    return this.__altText;
  }

  /**
   * @returns {ImageNodeMetadata}
   */
  getMetadata() {
    const self = this.getLatest();
    return self.__metadata;
  }

  /**
   * @returns {React.JSX.Element}
   */
  decorate() {
    return (
      <Suspense fallback={null}>
        <ImageComponent
          src={this.__src}
          altText={this.__altText}
          // @ts-ignore
          nodeKey={this.getKey()}
          showCaption={this.__showCaption}
          caption={this.__caption}
          captionsEnabled={this.__captionsEnabled}
          resizable={true}
          isInline={this.__isInline}
          horizontalPosition={this.__horizontalPosition}
        />
      </Suspense>
    );
  }
}

/**
 *
 * @param {*} options
 * @param {ImageNodeMetadata} [metadata]
 * @returns
 */
export function $createImageNode(
  {
    altText,
    height,
    maxWidth = 500,
    captionsEnabled,
    src,
    width,
    showCaption,
    caption,
    isInline,
    horizontalPosition,
    origin,
    float,
    key,
  },
  metadata
) {
  return new ImageNode(
    src,
    altText,
    maxWidth,
    width,
    height,
    showCaption,
    caption,
    captionsEnabled,
    isInline,
    horizontalPosition,
    origin,
    float,
    metadata,
    key
  );
}

/**
 *
 * @param {any} node
 * @returns {node is ImageNode}
 */
export function $isImageNode(node) {
  return node instanceof ImageNode;
}
