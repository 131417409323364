import { normalizeValue } from "./normalizeValue";
import { romanize } from "./romanize";

/**
 * Return number in Decimal or Roman or Letter format
 * depending on format given
 *
 * @param {number} value
 * @param {MarkerPattern | undefined} pattern
 */
export function parseValueForHTML(value, pattern) {
  switch (pattern) {
    case "UpRoman":
      return romanize(value);
    case "LowRoman":
      // @ts-ignore
      return romanize(value).toLowerCase();
    case "UpLetter":
      return String.fromCharCode(64 + normalizeValue(value)); //ASCII lower case
    case "LowLetter":
      return String.fromCharCode(96 + normalizeValue(value)); //ASCII upper case
    default:
      return value;
  }
}
