import {
  faClockRotateLeft,
  faThumbTack,
  faTicketPerforated,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Typography } from "@mui/material";
import React from "react";
import theme from "../../theme/theme";
import { dateColumnFormatter } from "../utils/dateColumnFormatter";

/**
 * @param {import("react-router-dom").NavigateFunction} navigate
 */
export function getColumns(navigate) {
  return [
    {
      field: "type",
      headerName: "Type",
      renderCell: (
        /** @type {{ row: { type: "Renewal" | "Ticket" | "Task"}}} */ params
      ) => {
        const taskType = params.row.type;
        switch (taskType) {
          case "Renewal": {
            return (
              <>
                <FontAwesomeIcon
                  icon={faClockRotateLeft}
                  color={theme.palette.primary.main}
                />
                <Typography marginLeft="10px">{params.row.type}</Typography>
              </>
            );
          }

          case "Ticket": {
            return (
              <>
                <FontAwesomeIcon
                  icon={faTicketPerforated}
                  color={theme.palette.primary.main}
                />
                <Typography marginLeft="10px">{params.row.type}</Typography>
              </>
            );
          }

          case "Task": {
            return (
              <>
                <FontAwesomeIcon
                  icon={faThumbTack}
                  color={theme.palette.primary.main}
                />
                <Typography marginLeft="10px">{params.row.type}</Typography>
              </>
            );
          }

          default:
            throw new Error(`${taskType} is not a valid task type.`);
        }
      },
    },
    {
      field: "logo",
      headerName: "Logo",
    },
    {
      field: "counterparty",
      headerName: "Counterparty",
    },
    {
      field: "area",
      headerName: "Area",
    },
    {
      field: "agreement",
      headerName: "Agreement",
      renderCell: (/** @type {*} */ params) => {
        return (
          <Link
            href={"blank"}
            fontWeight="bold"
            onClick={(event) => {
              event.preventDefault();
              navigate(
                `/agreement/${params.row?.agreement?._id}?openDatesAndRenewalDialog=true`
              );
            }}
          >
            {params.value}
          </Link>
        );
      },
      valueGetter: (/** @type {*} */ params) => params.row?.agreement?.agrTitle,
      width: 150,
    },
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "status",
      headerName: "Status",
    },
    {
      field: "requestor",
      headerName: "Requestor",
    },
    {
      field: "assignee",
      headerName: "Assignee",
    },
    {
      field: "created",
      headerName: "Created",
      type: "date",
      valueFormatter: dateColumnFormatter,
    },
    {
      field: "updated",
      headerName: "Updated",
      type: "date",
      valueFormatter: dateColumnFormatter,
    },
    {
      field: "dueDate",
      headerName: "Due",
      type: "date",
      valueFormatter: dateColumnFormatter,
    },
  ];
}
