/**
 * Removes all content controls from an SFDT. Mutates the object passed as an argument.
 *
 * @param {import("../../types/sfdt").Sfdt} sfdt
 * @returns {void}
 */
export function removeAllContentControlsFromSfdt(sfdt) {
  for (const section of sfdt.sections) {
    const blocks = section.blocks;

    let index = 0;

    while (index < blocks.length) {
      const block = blocks[index];

      // @ts-ignore
      if (block.contentControlProperties && block.blocks) {
        // @ts-ignore
        const contentControlBlocks = block.blocks;
        blocks.splice(index, 1, ...contentControlBlocks);
      } else {
        index++;
      }
    }
  }
}
