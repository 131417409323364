/**
 * @returns {ClauseVariant}
 */
export default function createDefaultClauseVariant(clauseText = "") {
  return {
    type: "alternativeClause",
    text: clauseText,
    name: "",
    description: "",
    application: {
      type: "automatic",
      conditions: [],
    },
    topicsIds: [],
    visibility: "notVisible",
  };
}
