import {
  faBarsFilter,
  faCalendar,
  faCaretDown,
  faCaretUp,
  faFileContract,
  faFileImport,
  faHandshake,
  faSave,
  faTimes,
  faTrashCan,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar, gridClasses } from "@mui/x-data-grid";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CanveoCircularProgress, Header, ThumbAgr } from "../../components";
import DialogAgreementSummary from "../../components/dialogs/DialogAgreementSummary";
import { globalStore } from "../../state/store";
import { getCanveoTier } from "../../utils/getCanveoTier";
import FilterDropdown from "./components/FilterDropdown";
import { getColumns } from "./utils/getColumns";

/**
 * @returns {JSX.Element}
 */
export default function Agreeements({ tableOnly = false }) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [agreements, setAgreements] = useState(/** @type {*[]} */ ([]));
  const [columns] = useState(
    getColumns(navigate, state?.user?.email, state.org.iAmALawFirm)
  );
  const [tasks, setTasks] = useState(/** @type {AgreementRow[]} */ ([]));
  const [selectedAgreementId, setSelectedAgreementId] = useState("");
  const [openDialogAgreementSummary, setOpenDialogAgreementSummary] =
    useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedRowsIds, setSelectedRowsIds] = useState(
    /** @type {string[]} */ ([])
  );
  const [openDeleteAgreeementsDialog, setOpenDeleteAgreementsDialg] =
    useState(false);

  /**
   * @param {string} status
   */
  function getAgreementStatusLabel(status) {
    if (status === "InEffect") {
      return "In Effect";
    }

    if (status === "Execution") return "Being Signed";

    return status;
  }

  async function loadAgreements(enableLoading = true) {
    try {
      enableLoading && setIsLoading(true);

      const result = await axios.get(
        `${state.settings.api}org/${state.user.orgID}/agreements`
      );
      const agreements = result.data.data;
      setAgreements(agreements);

      const rows = agreements.map((/** @type {*} */ agreement) => {
        const counterparty = state?.cpents?.find(
          (/** @type {{ _id: string; }} */ x) =>
            x?._id ===
            agreement?.ents?.find(
              (/** @type {{ partyID: string; }} */ e) => e?.partyID === "party1"
            )?.entID
        );

        const counterpartyLegalName = counterparty?.legalName;
        const counterpartyLogo = counterparty?.logoURL;

        const hasMultipleCounterparties = agreement?.ents?.some(
          (/** @type {{ partyID: string; }} */ x) => x.partyID === "party2"
        );

        const /** @type {AgreementRow} */ row = {
            id: agreement._id,
            logo: counterpartyLogo,
            name: agreement,
            type: state.agrTypes.find(
              (/** @type {{ _id: string; }} */ agrType) =>
                agrType._id === agreement.agrTypeID
            )?.fullName[0],
            area: agreement.areaLabels
              .map(
                (/** @type {{ labelID: { name: string; }; }} */ label) =>
                  label.labelID.name
              )
              .join(","),
            status: getAgreementStatusLabel(agreement.agrStatus),
            counterpartyLegalName: `${counterpartyLegalName || ""} ${
              hasMultipleCounterparties ? " et al." : ""
            }`,
            clientLegalName: `${counterpartyLegalName || ""} ${
              hasMultipleCounterparties ? " et al." : ""
            }`,
            nextActionLiesWith: "",
            createdDate: new Date(agreement.creationDate),
            updatedDate: new Date(agreement.lastUpdateDate),
            signedDate: agreement?.datesAndRenewal?.signedDate
              ? new Date(agreement?.datesAndRenewal?.signedDate)
              : "",
            expiryDate: agreement?.datesAndRenewal?.expiryDate
              ? new Date(agreement?.datesAndRenewal?.expiryDate)
              : "",
            noticeDate: agreement?.datesAndRenewal?.noticeDate
              ? new Date(agreement?.datesAndRenewal?.noticeDate)
              : "",
            source: agreement.fileSource,
            labels: agreement.labels
              .map(
                (/** @type {{ labelID: { name: string; }; }} */ label) =>
                  label.labelID.name
              )
              .join(","),
            agreementParameters: agreement.parameters
              .map(
                (/** @type {{ name: string; displayValue: string; }} */ x) =>
                  `${x.name} : ${x.displayValue}`
              )
              .join(" | "),
            currentAgreementOwner: agreement.creationBy.displayName,
            agreementCreator: agreement.creationBy.displayName,
          };

        return row;
      });

      setTasks(rows);
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "An error occurred while retrieving the agreements.",
          severity: "error",
        },
      });
    } finally {
      enableLoading && setIsLoading(false);
    }
  }

  /**
   * @param {import("@mui/x-data-grid").GridRowSelectionModel} rowSelectionModel
   * @param {import("@mui/x-data-grid").GridCallbackDetails<any>} _details
   * @returns {void}
   */
  function handleRowSelectionModelChange(rowSelectionModel, _details) {
    // @ts-ignore
    setSelectedRowsIds(rowSelectionModel);
  }

  function handleOpenDeleteAgreeementsDialogClose() {
    setOpenDeleteAgreementsDialg(false);
  }

  async function deleteAgreements(enableLoading = true) {
    try {
      enableLoading && setIsLoading(true);

      await axios.delete(`${state.settings.api}agr/bulk/${selectedRowsIds}`);
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "An error occurred while deleting the agreements.",
          severity: "error",
        },
      });
    } finally {
      enableLoading && setIsLoading(false);
    }
  }

  useEffect(() => {
    loadAgreements();
    // Run effect once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedAgreementId) {
      setIsLoading(true);
      axios
        .get(state.settings.api + "agr/agrdetails/" + selectedAgreementId)
        .then((resAgDetail) => {
          if (!resAgDetail.data.success) {
            throw new Error("Error fetching agreement details.");
          }

          dispatch({ type: "INIT_AGRS", payload: resAgDetail.data.data.ags });
          dispatch({ type: "INIT_AVS", payload: resAgDetail.data.data.avs });
          dispatch({
            type: "INIT_PARTIES",
            payload: resAgDetail.data.data.parties,
          });
          dispatch({
            type: "UPDATE_AGREXEC",
            payload: resAgDetail.data.data.agrExec,
          });
          dispatch({
            type: "INIT_WORKFLOWS",
            payload: resAgDetail.data.data.workflows,
          });
          dispatch({
            type: "INIT_PARAMS_DOC",
            payload: resAgDetail.data.data.params,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgreementId]);

  if (isLoading) {
    return (
      <Grid container justifyContent="center">
        <Header page={"Templates"} />

        <Grid container justifyContent="center" sx={{ mt: 50 }}>
          <CanveoCircularProgress />
        </Grid>
      </Grid>
    );
  }

  if (tableOnly) {
    return (
      <DataGrid
        onRowSelectionModelChange={handleRowSelectionModelChange}
        checkboxSelection
        density="comfortable"
        getRowHeight={() => "auto"}
        onRowClick={(params) => {
          const agreementId = params.row.id;
          setIsLoading(true);
          setSelectedAgreementId(agreementId);
          setOpenDialogAgreementSummary(true);
        }}
        columns={columns}
        rows={tasks}
        disableRowSelectionOnClick
        filterMode="client"
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 2,
          },
          [`& .${gridClasses.columnHeaderTitle}`]: {
            fontWeight: 400,
          },
          [`& .${gridClasses.detailPanel}`]: {
            background: "transparent",
          },
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
            {
              outline: "none",
            },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            {
              outline: "none",
            },
          border: 0,
        }}
        hideFooter
        autoHeight
        disableColumnFilter
      />
    );
  }

  return (
    <Grid container justifyContent="center">
      <Header page={"Templates"} />

      <Box sx={{ mt: 16 }}>
        <Grid container justifyContent="center">
          <Grid item display={"flex"} justifyContent={"center"} xs={8}>
            <Typography variant="h4">Manage Agreements</Typography>
          </Grid>

          <Grid
            item
            display={"flex"}
            justifyContent={"center"}
            xs={8}
            sx={{ m: 2 }}
          >
            <Typography variant="body1" textAlign={"center"}>
              Search across all of your agreements, using advanced filters and
              search bars
            </Typography>
          </Grid>
        </Grid>

        <Typography
          color="textSecondary"
          variant="body2"
          sx={{
            mt: 4,
            mb: 1.5,
            ml: 3,
          }}
        >
          Recently Updated
        </Typography>

        <Grid container direction="row" mt={3} ml={2}>
          {(() => {
            const slicedAgreements = agreements.slice(0, 4);
            return slicedAgreements.map((agreement) => {
              const ent1id = agreement.ents.filter(
                (/** @type {{ partyID: string; }} */ e) =>
                  e.partyID === "party1"
              )[0]?.entID;
              const ent2id = agreement.ents.filter(
                (/** @type {{ partyID: string; }} */ e) =>
                  e.partyID === "party2"
              )[0]?.entID;
              const ent1 =
                state?.cpents?.filter(
                  (/** @type {{ _id: string; }} */ e) => e._id === ent1id
                )[0] !== undefined
                  ? state?.cpents?.filter(
                      (/** @type {{ _id: string; }} */ e) => e._id === ent1id
                    )[0]
                  : {};
              const ent2 =
                state?.cpents?.filter(
                  (/** @type {{ _id: string; }} */ e) => e._id === ent2id
                )[0] !== undefined
                  ? state?.cpents?.filter(
                      (/** @type {{ _id: string; }} */ e) => e._id === ent2id
                    )[0]
                  : {};

              return (
                <Grid item key={agreement._id} xs={3}>
                  <ThumbAgr
                    ag={agreement}
                    actionReq={agreement.avOwners.includes(state.org._id)}
                    thumbClick={() => navigate("/agreement/" + agreement._id)}
                    showLogo={
                      state.subs.some(
                        (/** @type {{ _id: string; }} */ s) => s._id === ent1id
                      )
                        ? ent2.logoURL
                        : ent1.logoURL
                    }
                    primaryLegalName={
                      ent1.legalName !== undefined ? ent1.legalName : ""
                    }
                    secondaryLegalName={
                      ent2.legalName !== undefined ? ent2.legalName : ""
                    }
                    additionalParties={agreement.ents.filter(
                      (/** @type {{ entID: string; }} */ e) =>
                        e.entID !== ent1._id && e.entID !== ent2._id
                    )}
                  />
                </Grid>
              );
            });
          })()}
        </Grid>

        {/* <br />

        <Grid container direction="row" spacing={4}>
          <Grid item xs={4}>
            <Card sx={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
              <CardContent>
                <Grid container>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignContent={"center"}
                    sx={{
                      backgroundColor: "#ECE3FF",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faHourglass}
                      size={"xl"}
                      color={theme.palette.primary.main}
                    />
                  </Grid>

                  <Grid item sx={{ paddingLeft: "10px" }}>
                    <Grid container>
                      <Typography variant="body2">
                        Pending tasks assigned to me
                      </Typography>
                    </Grid>

                    <Grid container>
                      <Typography variant="body1">7</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card sx={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
              <CardContent>
                <Grid container>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignContent={"center"}
                    sx={{
                      backgroundColor: "#ECE3FF",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPersonWalkingArrowRight}
                      size={"xl"}
                      color={theme.palette.primary.main}
                    />
                  </Grid>

                  <Grid item sx={{ paddingLeft: "10px" }}>
                    <Grid container>
                      <Typography variant="body2">
                        Pending tasks requested by me
                      </Typography>
                    </Grid>

                    <Grid container>
                      <Typography variant="body1">7</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card sx={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
              <CardContent>
                <Grid container>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignContent={"center"}
                    sx={{
                      backgroundColor: "#ECE3FF",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faAlarmExclamation}
                      size={"xl"}
                      color={theme.palette.primary.main}
                    />
                  </Grid>

                  <Grid item sx={{ paddingLeft: "10px" }}>
                    <Grid container>
                      <Typography variant="body2">Overdue tasks</Typography>
                    </Grid>

                    <Grid container>
                      <Typography variant="body1">7</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid> */}

        {/* <Grid container direction="column" alignItems="center" sx={{ mt: 5 }}>
          <FabStandard
            click={() => {}}
            text="New"
            icon={faCirclePlus}
            sx={{
              left: "100px",
              top: "80px",
              right: "unset",
            }}
          />
        </Grid> */}

        {/* <Grid container justifyContent={"center"}>
          <Chip
            style={{ border: "1px solid", fontWeight: "bolder" }}
            color="primary"
            label="Assigned to me"
            variant="outlined"
            sx={{ mr: 2 }}
            icon={
              <FontAwesomeIcon
                icon={faPersonWalkingArrowLoopLeft}
                style={{ marginLeft: "12px" }}
              />
            }
            onClick={() => {}}
          />

          <Chip
            style={{ border: "1px solid", fontWeight: "bolder" }}
            color="primary"
            label="Requested by me"
            variant="outlined"
            sx={{ mr: 2 }}
            icon={
              <FontAwesomeIcon
                icon={faPersonWalkingArrowRight}
                style={{ marginLeft: "12px" }}
              />
            }
            onClick={() => {}}
          />

          <Chip
            style={{ border: "1px solid", fontWeight: "bolder" }}
            color="primary"
            label="Unassigned"
            variant="outlined"
            sx={{ mr: 2 }}
            icon={
              <FontAwesomeIcon
                icon={faLocationQuestion}
                style={{ marginLeft: "12px" }}
              />
            }
            onClick={() => {}}
          />

          <Chip
            style={{ border: "1px solid", fontWeight: "bolder" }}
            color="primary"
            label="Overdue"
            variant="outlined"
            sx={{ mr: 2 }}
            icon={
              <FontAwesomeIcon
                icon={faAlarmExclamation}
                style={{ marginLeft: "12px" }}
              />
            }
            onClick={() => {}}
          />

          <Chip
            style={{ border: "1px solid", fontWeight: "bolder" }}
            color="primary"
            label="Pending"
            variant="outlined"
            sx={{ mr: 2 }}
            icon={
              <FontAwesomeIcon
                icon={faHourglass}
                style={{ marginLeft: "12px" }}
              />
            }
            onClick={() => {}}
          />

          <Chip
            style={{ border: "1px solid", fontWeight: "bolder" }}
            color="primary"
            label="Done"
            variant="outlined"
            sx={{ mr: 2 }}
            icon={
              <FontAwesomeIcon
                icon={faCircleCheck}
                style={{ marginLeft: "12px" }}
              />
            }
            onClick={() => {}}
          />
        </Grid> */}

        {/* <Grid
          container
          sx={{ mt: 4 }}
          justifyContent="right"
          alignItems="center"
        >
          <FontAwesomeIcon
            icon={faTrash}
            style={{ color: theme.palette.primary.main }}
          />

          <Divider orientation="vertical" flexItem sx={{ mx: 2, my: 2 }} />

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              ),
            }}
          />
        </Grid> */}

        <Grid container direction="row" mt={4} spacing={1}>
          {getCanveoTier(state?.user?.email) === "experimental" && (
            <>
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={
                    <FontAwesomeIcon
                      icon={faBarsFilter}
                      style={{ fontSize: "14px" }}
                    />
                  }
                  endIcon={
                    <FontAwesomeIcon
                      icon={showFilters ? faCaretUp : faCaretDown}
                      style={{ fontSize: "14px" }}
                    />
                  }
                  onClick={() => setShowFilters(!showFilters)}
                >
                  Filters
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={
                    <FontAwesomeIcon
                      icon={faSave}
                      style={{ fontSize: "14px" }}
                    />
                  }
                  endIcon={
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      style={{ fontSize: "14px" }}
                    />
                  }
                >
                  Saved Searches
                </Button>
              </Grid>
            </>
          )}

          <Grid item>
            <Button
              disabled={!selectedRowsIds.length}
              variant="outlined"
              size="small"
              startIcon={
                <FontAwesomeIcon
                  icon={faTrashCan}
                  style={{ fontSize: "14px" }}
                />
              }
              onClick={() => setOpenDeleteAgreementsDialg(true)}
            >
              Delete
            </Button>
          </Grid>
        </Grid>

        {showFilters && (
          <Grid container direction="row" justifyContent="center" width={1020}>
            <Grid container mt={2}>
              <Grid container alignItems="center" gap={1}>
                <FontAwesomeIcon icon={faFileContract} />
                <Typography variant="subtitle1">Agreement</Typography>
              </Grid>

              <Grid container mt={2} gap={2}>
                <Grid item xs={2}>
                  <FilterDropdown label="Name" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Type" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Area" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Status" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Next Action Lies With" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Labels" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Source" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Notes" />
                </Grid>
              </Grid>
            </Grid>

            <Grid container mt={2}>
              <Grid container alignItems="center" gap={1}>
                <FontAwesomeIcon icon={faHandshake} />
                <Typography variant="subtitle1">Parties</Typography>
              </Grid>

              <Grid container mt={2} gap={2}>
                <Grid item xs={2}>
                  <FilterDropdown label="Counterparty Info" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Client Info" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Our Info" />
                </Grid>
              </Grid>
            </Grid>

            <Grid container mt={2}>
              <Grid container alignItems="center" gap={1}>
                <FontAwesomeIcon icon={faCalendar} />
                <Typography variant="subtitle1">Dates & Renewal</Typography>
              </Grid>

              <Grid container mt={2} gap={2}>
                <Grid item xs={2}>
                  <FilterDropdown label="Created Date" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Updated Date" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Signed Date" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Start Date" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Expiry Date" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Term" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Renewal Mode" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Renewal Term" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Notice Period" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Notice Date" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Renewal Task Assignee" />
                </Grid>
              </Grid>
            </Grid>

            <Grid container mt={2}>
              <Grid container alignItems="center" gap={1}>
                <FontAwesomeIcon icon={faFileImport} />
                <Typography variant="subtitle1">
                  Merge Fields & Properties
                </Typography>
              </Grid>

              <Grid container mt={2} gap={2}>
                <Grid item xs={2}>
                  <FilterDropdown label="Merge Fields" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Parameters" />
                </Grid>
              </Grid>
            </Grid>

            <Grid container mt={2}>
              <Grid container alignItems="center" gap={1}>
                <FontAwesomeIcon icon={faUser} />
                <Typography variant="subtitle1">
                  Collaborators & Signers
                </Typography>
              </Grid>

              <Grid container mt={2} gap={2}>
                <Grid item xs={2}>
                  <FilterDropdown label="Counterparty Collaborators" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Counterparty Signers" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Client Collaborators" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Client Signers" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Our Collaborators" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Our Signers" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Current Agreement Owner" />
                </Grid>

                <Grid item xs={2}>
                  <FilterDropdown label="Agreement Creator" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid container mt={2} mb={16} justifyContent="center">
          <Box sx={{ height: 400, width: 1020 }}>
            <DataGrid
              onRowSelectionModelChange={handleRowSelectionModelChange}
              checkboxSelection
              density="comfortable"
              getRowHeight={() => "auto"}
              onRowClick={(params) => {
                const agreementId = params.row.id;
                setIsLoading(true);
                setSelectedAgreementId(agreementId);
                setOpenDialogAgreementSummary(true);
              }}
              columns={columns}
              rows={tasks}
              disableRowSelectionOnClick
              filterMode="client"
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 2,
                },
                [`& .${gridClasses.columnHeaderTitle}`]: {
                  fontWeight: 400,
                },
                [`& .${gridClasses.detailPanel}`]: {
                  background: "transparent",
                },
                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                  {
                    outline: "none",
                  },
                [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                  {
                    outline: "none",
                  },
                border: 0,
              }}
              hideFooter
              autoHeight
              disableColumnFilter
            />

            {openDialogAgreementSummary && (
              <DialogAgreementSummary
                agreementId={selectedAgreementId}
                open={openDialogAgreementSummary}
                close={() => {
                  setSelectedAgreementId("");
                  setOpenDialogAgreementSummary(false);
                }}
              />
            )}
          </Box>
        </Grid>

        <Dialog
          open={openDeleteAgreeementsDialog}
          onClose={handleOpenDeleteAgreeementsDialogClose}
        >
          <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
            <IconButton onClick={handleOpenDeleteAgreeementsDialogClose}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{ padding: "4px 7px", fontSize: "20px" }}
              />
            </IconButton>
          </Box>
          <DialogTitle>Delete Agreement</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the selected agreements?
            </DialogContentText>
            <DialogContentText pt={1}>
              (This action cannot be undone.)
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              sx={{ marginRight: "auto" }}
              onClick={handleOpenDeleteAgreeementsDialogClose}
            >
              Cancel
            </Button>

            <Button
              sx={{ marginLeft: "auto" }}
              onClick={async () => {
                handleOpenDeleteAgreeementsDialogClose();
                setIsLoading(true);
                await deleteAgreements(false);
                await loadAgreements(false);
                setIsLoading(false);
              }}
            >
              Confirm&nbsp;&nbsp;
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Grid>
  );
}
