import { faClone, faUpload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import * as Yup from "yup";
import pdf from "../../assets/img/pdf.png";
import useVersionService from "../../hooks/useVersionService";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import { fileHasAllowedExtension } from "../../utils/fileHasAllowedExtension";
import { getCanveoTier } from "../../utils/getCanveoTier";
import CanveoCircularProgress from "../CanveoCircularProgress";
import DialogFileConversionErrorDetails from "../dialogs/DialogFileConverionErrorDetails";

const validSources = [
  {
    label: "Attach file to latest version",
    value: "latest",
  },
];

const UploadSignedVersionForm = forwardRef(function UploadSignedVersionForm(
  { originVersion, handleCanSubmit, handleFormSubmit },
  ref
) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  if (getCanveoTier(state?.user?.email) === "experimental") {
    validSources.push({
      label: "File contains updates - store it as a new version",
      value: "new",
    });
  }

  const { loading, uploadSignedVersion } = useVersionService();
  const [dropHover, setDropHover] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);

  const formSchema = Yup.object({
    source: Yup.object().nullable().required("Related Version is required"),
    description: Yup.string().nullable(),
    origin: Yup.object().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      source: null,
      description: "",
      origin: null,
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      const submitValues = {
        ...values,
        source: values.source.value,
      };
      const result = await uploadSignedVersion(state.agrs[0]._id, submitValues);

      handleFormSubmit(result);
    },
  });

  useEffect(() => {
    handleCanSubmit(formik.values.source && formik.values.origin);
  }, [formik.values]);

  const validExtensions = ["pdf"];

  const [conversionError, setConversionError] = React.useState(
    /** @type {Error | undefined} */ (undefined)
  );

  const handleUploadChange = async (file) => {
    try {
      setErrorMsg(null);
      setUploadLoading(true);

      // Native file picker allows choosing files with any extension even if you
      // configured the input to only allow certain types. We currently only allow uploading
      // .doc or docx files.
      const fileHasValidExtension = fileHasAllowedExtension(
        file,
        validExtensions
      );

      if (!fileHasValidExtension) {
        setErrorMsg("Invalid file type");
        setUploadLoading(false);
        return;
      }

      const uploadSignedUrlResponse = await axios.post(
        state.settings.api + "upload/signedUrl",
        {
          contentType: file.type,
          bucketAlias: "documents",
        }
      );

      const uploadSignedUrl = uploadSignedUrlResponse.data.data.uploadSignedUrl;
      const fileName = uploadSignedUrlResponse.data.data.key;

      const res = await fetch(uploadSignedUrl, { method: "PUT", body: file });

      formik.setFieldValue("origin", {
        fileID: res.body.data,
        fileName: file.name, // TODO: If facing errors try replacing with fileName above.
      });
      setUploadLoading(false);
    } catch (error) {
      if (error instanceof Error) {
        setConversionError(error);
      }
      console.error(error);
    }
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      ref={ref}
      style={{ padding: "0 40px" }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ my: 1 }}
      >
        {loading && (
          <Grid item container xs={12} justifyContent="center">
            <CanveoCircularProgress />
          </Grid>
        )}
        {!loading && (
          <>
            <Grid item container xs={12} justifyContent="center">
              {uploadLoading && <CanveoCircularProgress />}
              {!uploadLoading && !formik.values.origin && (
                <FileUploader
                  handleChange={handleUploadChange}
                  name="uploadfile"
                  types={["pdf"]}
                  label={"Upload or drop a file here"}
                  maxSize={20}
                  minSize={0}
                  onDraggingStateChange={(dragging) => setDropHover(dragging)}
                  hoverTitle={" "}
                  classes="version_upload"
                  children={
                    <Box
                      sx={{
                        width: "100%",
                        height: "180px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        border: dropHover
                          ? "2px solid" + theme.palette.primary.main
                          : "1px dotted" + theme.palette.grey[300],
                        backgroundColor: dropHover
                          ? theme.palette.grey[200]
                          : theme.palette.grey[50],
                        padding: "30px",
                        fontSize: "14px",
                        fontWeight: "600",
                        borderRadius: "20px",
                      }}
                    >
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <FontAwesomeIcon
                            icon={faUpload}
                            style={{
                              color: theme.palette.primary.main,
                              fontSize: "30px",
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Box
                            sx={{
                              mt: 1,
                              display: "block",
                              textAlign: "center",
                            }}
                          >
                            <>
                              {dropHover
                                ? "Time to let the file go..."
                                : "Upload or drop your file here ..."}
                              <br />
                              <Grid
                                container
                                direction="row"
                                spacing={1}
                                justifyContent="center"
                                sx={{ mt: 1 }}
                              >
                                <Grid item>
                                  <img src={pdf} alt="pdf" width={20} />
                                </Grid>
                              </Grid>
                            </>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  }
                />
              )}
              {formik.values.origin && (
                <Grid item container direction="column" alignItems="center">
                  <Grid item>
                    <img src={pdf} alt="" height={30} />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      color={"textSecondary"}
                      mt={1}
                      align="center"
                    >
                      {formik.values.origin.fileName}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item container xs={12}>
              <Autocomplete
                sx={{ width: "100%" }}
                options={validSources}
                value={formik.values.source}
                autoHighlight
                onChange={(e, value) => formik.setFieldValue("source", value)}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={"Related Version"}
                    variant="outlined"
                    placeholder="Choose which version to attach this to ..."
                    error={!!(formik.touched.source && formik.errors.source)}
                    helperText={
                      formik.touched.source && formik.errors.source
                        ? formik.errors.source
                        : ""
                    }
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: "new-password",
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faClone} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>

      {conversionError && (
        <DialogFileConversionErrorDetails
          conversionError={conversionError}
          close={() => setConversionError(undefined)}
        />
      )}
    </form>
  );
});

export default UploadSignedVersionForm;
