const initialState = {
  clauseOptions: {
    dialogClauseOpen: false,
    activeClauseItems: {
      cts: [],
      wfs: [],
    },
  },
};

const clauseOptionsReducer = (state, action) => {
  switch (action.type) {
    case "SET_ACTIVECLAUSEITEMS": {
      return {
        clauseOptions: {
          ...state.clauseOptions,
          activeClauseItems: action.payload,
        },
      };
    }
    case "SET_DIALOGCLAUSE_OPEN": {
      return {
        clauseOptions: {
          ...state.clauseOptions,
          dialogClauseOpen: action.payload,
        },
      };
    }
    default: {
      return initialState;
    }
  }
};

export default clauseOptionsReducer;
