import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid } from "@mui/material";
import React, { useContext } from "react";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";

const styles = {
  iconForButton: { fontSize: "24px", marginBottom: "5px" },
  iconForButtonActive: {
    fontSize: "24px",
    marginBottom: "5px",
    color: theme.palette.primary.main,
  },
  gridForButton: {
    mb: 3,
    color: theme.palette.grey[700],
    fontSize: "11px",
    fontWeight: "600",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
      fontWeight: "700",
    },
  },
};

/**
 * @typedef {*} MiniDrawerProps
 */

/**
 * @param {MiniDrawerProps} props
 * @returns {React.JSX.Element}
 */
export default function MiniDrawer({ items, handleDrawerOpen }) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  return (
    <Box
      sx={{ pt: `calc(86px + ${state.appBar?.toolbarOpen ? "50px" : "0px"})` }}
    >
      <Grid container direction="column" alignItems={"center"}>
        {items.map((/** @type { * }} */ item, /** @type {number} */ index) => (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            key={index}
            sx={styles.gridForButton}
            onClick={() =>
              item.click ? item.click() : handleDrawerOpen(item.name)
            }
            id={item.id}
          >
            <Grid item>
              <FontAwesomeIcon
                icon={item.icon}
                style={
                  item.active || item.active === undefined
                    ? styles.iconForButtonActive
                    : styles.iconForButton
                }
              />
            </Grid>
            <Grid
              style={item.active ? { color: theme.palette.primary.main } : {}}
            >
              {item.name}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
