import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import FitContentPopper from "../../../components/PathBuilder/components/FitContentPopper";

const /** @type {{ label: string; }[]} */ filterOptions = [];

/**
 * @param {{label: string}} props
 */
export default function FilterDropdown({ label }) {
  return (
    <Autocomplete
      fullWidth
      multiple
      freeSolo
      PopperComponent={FitContentPopper}
      disablePortal
      options={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={label + " ..."}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}
