/**
 * @param {PartyId} partyId
 * @param {PartialUser} user
 * @returns {RedlineData}
 */
export function getDefaultRedlineData(partyId, user) {
  // TODO: Handle undefined user properly.
  if (!user) return {};

  const defaultRedlineData = {
    partyID: partyId,
    metadata: {
      creatorId: user._id,
      creatorEmail: user.email,
      creatorDisplayName: user.displayName,
      creatorPhotoUrl: user.photoURL,
      creationDate: new Date().toISOString(),
      partyId,
    },
    // TODO: Double-check if having the latest date every time we create a redline node is the desired behaviour.
    date: new Date().toISOString(),
  };
  return defaultRedlineData;
}
