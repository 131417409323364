import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const elementsToAdd = [12, 15, 5, 8, 10, 8, 6, 5, 10, 4, 8, 4, 3, 2];
const timesElapsedForUpdate = [
  1500, 1500, 1800, 2000, 2300, 2400, 2700, 2200, 2800, 2600, 2800, 2600, 2800,
  2600,
];

/**
 * @typedef {object} DialogAnalyzingAgreementProgressBarProps
 * @property {boolean} open
 * @property {() => void} close
 */

/**
 * @param {DialogAnalyzingAgreementProgressBarProps} props
 * @returns {JSX.Element}
 */
export default function DialogAnalyzingAgreementProgressBar({ open, close }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let timeoutIds = [];

    const updateProgress = (index) => {
      if (index < elementsToAdd.length) {
        setProgress((prevProgress) => {
          const toAdd = elementsToAdd[index];
          return Math.min(prevProgress + toAdd, 100);
        });

        const timeoutId = setTimeout(() => {
          updateProgress(index + 1);
        }, timesElapsedForUpdate[index]);

        timeoutIds.push(timeoutId);
      }
    };

    updateProgress(0);

    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
    };
  }, []);

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>Analyzing Agreement ...</DialogTitle>

      <DialogContent>
        <Typography>Analyzing Agreement ...</Typography>

        <LinearProgressWithLabel value={progress} />
      </DialogContent>
    </Dialog>
  );
}

/**
 * @param {import("@mui/material").LinearProgressProps & { value: number }} props
 * @returns {JSX.Element}
 */
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
