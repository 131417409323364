import { $isElementNode, $isRootOrShadowRoot } from "lexical";
import { $isClauseNode } from "../../../../nodes/ClauseNode";

/**
 * Removes element node if empty.
 *
 * @param {import("lexical").RangeSelection} selection
 * @returns {void}
 */
export function removeElementNode(selection) {
  const node = (
    selection.isBackward() ? selection.focus : selection.anchor
  ).getNode();

  if ($isElementNode(node) && node.getChildrenSize() === 0) {
    /** @type {import("lexical").ElementNode} **/
    let currentNode = node;
    while (
      currentNode &&
      !$isClauseNode(currentNode) &&
      !$isRootOrShadowRoot(currentNode)
    ) {
      const parent = node.getParent();
      node.remove(true);
      if (parent && parent.getChildrenSize() === 0) {
        currentNode = parent;
      }
    }

    const isCurrentNodeEmpty = currentNode.getChildrenSize() === 0;
    if ($isClauseNode(currentNode) && isCurrentNodeEmpty) {
      currentNode.remove();
    } else {
      selection.modify("move", true, "character");
    }
  }
}
