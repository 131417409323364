import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Box, Grid, Typography } from "@mui/material";
// import axios from "axios";
// import { $getRoot } from "lexical";
import React, { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useSummaryService from "../../hooks/useSummaryService";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import { fileSources } from "../../utils/fileSourceMapping";
import { getCanveoTier } from "../../utils/getCanveoTier";
import Collapsable from "../Collapsable";
import DatesAndRenewal from "../DatesAndRenewal";
import PartyList from "../PartyList";
import UsersByParty from "../UsersByParty";
// import $convertLexicalToSfdt from "../editor/converters/convertLexicalToSfdt";

/**
 * @typedef {object} SummaryDrawerProps
 * @property {string} docID
 * @property {boolean} isTemplate
 * @property {string} partyId
 */

/**
 * @param {SummaryDrawerProps} props
 */
function SummaryDrawer({ docID, isTemplate, partyId }) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  // const [editor] = useLexicalComposerContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const openDatesAndRenewalDialogSearchParamValue = searchParams.get(
    "openDatesAndRenewalDialog"
  );
  let defaultOpenDatesAndRenewalDialog = false;
  if (Boolean(openDatesAndRenewalDialogSearchParamValue)) {
    defaultOpenDatesAndRenewalDialog = true;
    setSearchParams({});
  }

  const [openDatesAndRenewalDialog, setOpenDatesAndRenewalDialog] = useState(
    defaultOpenDatesAndRenewalDialog
  );

  const { document, signers, collaborators, parameters } = useSummaryService(
    docID,
    isTemplate
  );

  if (!document) return;

  function getAgrType() {
    if (isTemplate) {
      return `${document.agrTypeID.fullName[0]} (${document.agrTypeID.shortName})`;
    }

    const agrType = state.agrTypes.find(
      (/** @type {{ _id: string; }} */ at) => at._id === document?.agrTypeID
    );
    if (!agrType) return;

    return `${agrType.fullName[0]} (${agrType.shortName})`;
  }

  // async function analyzeAgainstCompanyPolicy() {
  //   // const response =
  //   await axios.post(
  //     state.settings.api + `chatGpt/${docID}/analyzeAgainstCompanyPolicy/job`
  //   );
  //   // const data = response.data.data;
  // }

  // async function getJobStatus() {
  //   const response = await axios.get(
  //     state.settings.api + `chatGpt/${docID}/analyzeAgainstCompanyPolicy/job`
  //   );
  //   const data = response.data.data;
  //   if (!data) return;

  //   const agreementSfdt = editor.parseEditorState(data.content).read(() => {
  //     const root = $getRoot();
  //     const agreementSfdt = $convertLexicalToSfdt(
  //       root,
  //       data.sfdt,
  //       undefined,
  //       // TODO: Fetch comments and workflows.
  //       [],
  //       data.listsStructure
  //     );

  //     return agreementSfdt;
  //   });

  //   const revisedAgreementSfdt = editor
  //     .parseEditorState(data.revisedAgreement)
  //     .read(() => {
  //       const root = $getRoot();
  //       const agreementSfdt = $convertLexicalToSfdt(
  //         root,
  //         data.sfdt,
  //         undefined,
  //         // TODO: Fetch comments and workflows.
  //         [],
  //         data.listsStructure
  //       );

  //       return agreementSfdt;
  //     });

  //   const compareResponse = await axios.post(
  //     state.settings.api + `document/compare`,
  //     { originalDocument: agreementSfdt, revisedDocument: revisedAgreementSfdt }
  //   );

  //   // TODO: Create new version.
  // }

  function getStatus() {
    if (isTemplate) {
      if (document.active) {
        return "Active";
      }

      if (!document.active) {
        return "Draft";
      }
    }

    if (document.agrStatus === "InEffect") {
      return "In Effect";
    }

    return document.agrStatus;
  }

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        p={2}
        sx={{
          position: "relative",
          backgroundColor: "white",
        }}
      >
        <Grid item>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "14px",
            }}
          >
            <FontAwesomeIcon
              icon={faCircleInfo}
              color={theme.palette.primary.main}
              size="lg"
            />
            &nbsp;&nbsp;&nbsp;Summary
          </Typography>
        </Grid>
      </Grid>

      {/* <Grid container direction="row" justifyContent="center">
        <Grid item>
          <Button variant="contained" onClick={analyzeAgainstCompanyPolicy}>
            Analyze Against Company Policy
          </Button>
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="center" sx={{ mt: 4 }}>
        <Grid item>
          <Button variant="contained" onClick={getJobStatus}>
            Get Job Status
          </Button>
        </Grid>
      </Grid> */}

      <Box
        sx={{
          flexGrow: 1,
          position: "relative",
          backgroundColor: "white",
          overflow: "auto",
        }}
      >
        <Box sx={{ px: 2 }}>
          <Collapsable sx={{ py: 2 }} title={"Overview"} collapsed={undefined}>
            <Box sx={{ py: 1 }}>
              <Box sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: theme.palette.grey[600],
                  }}
                >
                  Name
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "520",
                    fontSize: "16px",
                    color: theme.palette.grey[800],
                  }}
                >
                  {document.agrTitle}
                </Typography>
              </Box>
              <Box sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: theme.palette.grey[600],
                  }}
                >
                  Type
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "520",
                    fontSize: "16px",
                    color: theme.palette.grey[800],
                  }}
                >
                  {getAgrType()}
                </Typography>
              </Box>
              {state.user.role.name !== "Counterparty" && (
                <Box sx={{ py: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: theme.palette.grey[600],
                    }}
                  >
                    Source
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "520",
                      fontSize: "16px",
                      color: theme.palette.grey[800],
                    }}
                  >
                    {
                      fileSources?.find(
                        (fs) => fs.value === document.fileSource
                      )?.label
                    }
                  </Typography>
                </Box>
              )}
              <Box sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: theme.palette.grey[600],
                  }}
                >
                  Status
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "520",
                    fontSize: "16px",
                    color: theme.palette.grey[800],
                  }}
                >
                  {getStatus()}
                </Typography>
              </Box>
              {state.user.role.name !== "Counterparty" && (
                <Box sx={{ py: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: theme.palette.grey[600],
                    }}
                  >
                    Area
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: "520",
                      fontSize: "16px",
                      color: theme.palette.grey[800],
                    }}
                  >
                    {document.areaLabels
                      ?.map((/** @type {*} */ al) => al.labelID.name)
                      .join(", ")}
                  </Typography>
                </Box>
              )}
            </Box>
          </Collapsable>

          {!isTemplate && (
            <>
              <Collapsable
                sx={{ py: 2 }}
                title={"Parties"}
                collapsed={undefined}
              >
                <PartyList
                  readOnly
                  parties={state.parties.filter(
                    (/** @type {{ role: string; }} */ p) => p.role
                  )}
                  sx={{ px: 1, pt: 2 }}
                  roles={document?.roles || []}
                  handleAddParty={() => {}}
                  handleEntityChange={() => {}}
                  handleEntityUpdate={() => {}}
                  handlePartyChange={() => {}}
                  handleRemoveParty={() => {}}
                  handleRoleChange={() => {}}
                  owner={state.org._id}
                />
              </Collapsable>

              <Collapsable sx={{ py: 2 }} collapsed title={"Collaborators"}>
                <UsersByParty
                  py={1}
                  pl={1}
                  parties={state.parties}
                  users={collaborators}
                />
              </Collapsable>

              <Collapsable sx={{ py: 2 }} collapsed title={"Signers"}>
                <UsersByParty
                  py={1}
                  pl={1}
                  parties={state.parties}
                  users={signers}
                />
              </Collapsable>

              {state.user.role.name !== "Counterparty" && (
                <Collapsable
                  sx={{ py: 2 }}
                  collapsed={false}
                  title={"Metadata"}
                >
                  <DatesAndRenewal
                    agreementId={docID}
                    openDatesAndRenewalDialog={openDatesAndRenewalDialog}
                    setOpenDatesAndRenewalDialog={setOpenDatesAndRenewalDialog}
                    document={document}
                    parameters={parameters}
                    documentIsTemplate={isTemplate}
                    partyId={partyId}
                  />
                </Collapsable>
              )}
            </>
          )}

          {/* COMMENTED OUT SECTION TO REMAIN IN THE CODE}
          {/* <Collapsable sx={{ py: 2 }} title={"Dates & Renewal"}>
            <Grid container sx={{ gap: "12px" }} pt={2} px={1}>
              <Grid item container alignItems="center">
                <Grid item xs={1}>
                  <FontAwesomeIcon
                    icon={faCalendarCheck}
                    color={theme.palette.primary.main}
                    size="md"
                  />
                </Grid>
                <Grid item>
                  <Typography pl={1}
                    sx={{
                      fontWeight: "600",
                      fontSize: "14px",
                      color: theme.palette.grey[800],
                    }}
                  >
                    Effective Date: {document.effectiveDate}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container alignItems="center">
                <Grid item xs={1}>
                  <FontAwesomeIcon
                    icon={faHourglassStart}
                    color={theme.palette.primary.main}
                    size="md"
                  />
                </Grid>
                <Grid item>
                  <Typography pl={1}
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: theme.palette.grey[800]
                      }}
                    >   
                    Initial Term: {document.initialTerm}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container alignItems="center">
                <Grid item xs={1}>
                  <FontAwesomeIcon
                    icon={faCalendarXmark}
                    color={theme.palette.primary.main}
                    size="md"
                  />
                </Grid>
                <Grid item>
                  <Typography pl={1}
                        sx={{
                          fontWeight: "600",
                          fontSize: "14px",
                          color: theme.palette.grey[800]
                        }}
                      >   
                    Expiry Date: {document.expiryDate}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container alignItems="center">
                <Grid item xs={1}>
                  <FontAwesomeIcon
                    icon={faArrowsRotate}
                    color={theme.palette.primary.main}
                    size="md"
                  />
                </Grid>
                <Grid item>
                  <Typography pl={1}
                        sx={{
                          fontWeight: "600",
                          fontSize: "14px",
                          color: theme.palette.grey[800]
                        }}
                      >   
                    Renewal: {document.renewalType}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container alignItems="center">
                <Grid item xs={1}>
                  <FontAwesomeIcon
                    icon={faHourglassEnd}
                    color={theme.palette.primary.main}
                    size="md"
                  />
                </Grid>
                <Grid item>
                  <Typography pl={1}
                        sx={{
                          fontWeight: "600",
                          fontSize: "14px",
                          color: theme.palette.grey[800]
                        }}
                      >   
                    Renewal Term: {document.renewalTerm}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Collapsable> */}

          {getCanveoTier(state?.user?.email) === "experimental" &&
            state.user.role.name !== "Counterparty" && (
              <Collapsable sx={{ py: 2 }} title={"Notes"} collapsed={undefined}>
                <Typography pl={1}>{document.notes}</Typography>
              </Collapsable>
            )}
        </Box>
      </Box>
    </>
  );
}

export default SummaryDrawer;
