import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import MergeFieldMenu from "../MergeFieldMenu";

/**
 * @typedef {object} DialogAddNewQuestionToQuestionnaireProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {(question: *) => void} submit
 * @property {string} organizationId
 * @property {string} organizationName
 * @property {boolean} documentIsTemplate
 * @property {string} partyId
 * @property {string} docId
 * @property {string} agrvId
 * @property {import("../editor/nodes/MarkNode").MergeField} [mergeField]
 */

/**
 * @param {DialogAddNewQuestionToQuestionnaireProps} props
 */
export default function DialogAddNewQuestionToQuestionnaire({
  open,
  close,
  submit,
  organizationId,
  organizationName,
  documentIsTemplate,
  partyId,
  docId,
  agrvId,
  mergeField,
}) {
  const [name] = useState("");
  const [wizardQuestion] = useState("");
  const [wizardGuidance] = useState("");

  const handleClose = () => {
    close();
  };

  const handleSubmit = () => {
    submit({ name, wizardQuestion, wizardGuidance });
    handleClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        {mergeField ? "Edit Agreement Property" : "Add Agreement Property"}
      </DialogTitle>

      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogContent id="list-options-dialog-content">
        <Box>
          <MergeFieldMenu
            open={true}
            isQuestionnaire={true}
            organizationId={organizationId}
            organizationName={organizationName}
            mergeFieldText={""}
            documentIsTemplate={documentIsTemplate}
            partyId={partyId}
            docId={agrvId ?? docId}
            handleSubmit={handleSubmit}
            handleUpdate={handleClose}
            handleClose={handleClose}
            existingMergeField={mergeField}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
