import axios from "axios";
import { useContext } from "react";
import { globalStore } from "../state/store";

const useWorkFlowService = () => {
  const [state, dispatch] = useContext(globalStore);

  const updateWorkFlow = async (updatedWorkFlow, emailRecipients = []) => {
    const result = await axios
      .put(state.settings.api + "workflow/" + updatedWorkFlow._id, {
        workflow: updatedWorkFlow,
      })
      .catch(() => {
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message: "An error occurred while updating the comment thread",
            severity: "error",
          },
        });
      });

    if (result.data.success) {
      dispatch({ type: "UPDATE_WORKFLOWS", payload: result.data.data });

      emailRecipients.forEach((r) => {
        axios.post(state.settings.api + "mail/informcomm", {
          doc: r.doc,
          whiteLabel: r.isTemplating ? null : null, // TODO
          partyFullString: r.isTemplating ? null : null, // TODO
          recipient: r.recipient,
          isPublic: !r.isInternal,
          isTemplating: r.isTemplating,
          isApproval: r.isApproval,
          isApprover: r.isApprover,
          comment: r.content,
          clauseHTML: r.clauseHTML,
          recentComments: r.recentComments,
          wfid: updatedWorkFlow._id,
          lid: updatedWorkFlow.lid,
        });
      });

      return result.data.data;
    } else {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message:
            "An error occurred while updating the comment thread - refresh your browser",
          severity: "error",
        },
      });
    }
  };

  return { updateWorkFlow };
};

export default useWorkFlowService;
