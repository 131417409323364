const sendFlowReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_SEND_FLOW_STATE":
      return {
        sendFlow: {
          ...state.sendFlow,
          [action.payload.key]: action.payload.value,
        },
      };
    case "RESET_SEND_FLOW_STATE":
      return { sendFlow: {} };
    case "SEND_FLOW_SUBMIT_REMOVE_PARTY":
      return {
        sendFlow: {
          ...state.sendFlow,
          removeParty: { ...state.sendFlow.removeParty, submit: true },
        },
      };
    case "SEND_FLOW_REMOVE_PARTY":
      if (action.payload) {
        return {
          sendFlow: {
            ...state.sendFlow,
            removeParty: {
              ...action.payload,
              submit: false,
            },
          },
        };
      }
      return {
        sendFlow: {
          ...state.sendFlow,
          removeParty: null,
        },
      };
    case "SEND_FLOW_ADD_PARTY":
      if (action.payload) {
        return {
          sendFlow: {
            ...state.sendFlow,
            addParty: {
              entity: null,
              organization: null,
              role: null,
            },
          },
        };
      }
      return {
        sendFlow: {
          ...state.sendFlow,
          addParty: null,
        },
      };
    case "SEND_FLOW_SUBMIT_ADD_PARTY":
      return {
        sendFlow: {
          ...state.sendFlow,
          addParty: { ...state.sendFlow.addParty, submit: true },
        },
      };
    case "SEND_FLOW_PARTY_CHANGE":
      return {
        sendFlow: {
          ...state.sendFlow,
          addParty: {
            ...state.sendFlow.addParty,
            submit: false,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    case "SEND_FLOW_REMOVE_USER":
      if (action.payload) {
        return {
          sendFlow: {
            ...state.sendFlow,
            removeUser: {
              ...action.payload,
              submit: false,
            },
          },
        };
      }
      return {
        sendFlow: {
          ...state.sendFlow,
          removeUser: null,
        },
      };
    case "SEND_FLOW_SUBMIT_REMOVE_USER":
      return {
        sendFlow: {
          ...state.sendFlow,
          removeUser: { ...state.sendFlow.removeUser, submit: true },
        },
      };
    default:
      return { sendFlow: state.sendFlow };
  }
};

export default sendFlowReducer;
