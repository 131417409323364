import { faHubspot, faSalesforce } from "@fortawesome/free-brands-svg-icons";
import {
  faBuilding,
  faProjectDiagram,
  faSearch,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React from "react";
import theme from "../theme/theme";

const filter = createFilterOptions();

const styles = {
  networkIcon: {
    color: theme.palette.primary.main,
    fontSize: "11px",
    padding: "0px 15px 0px 20px",
  },
  cptyIcon: {
    color: theme.palette.secondary.main,
    fontSize: "11px",
    padding: "0px 15px 0px 20px",
  },
  cptySpan: {
    fontSize: "12px",
    color: theme.palette.secondary.main,
  },
  networkSpan: {
    fontSize: "12px",
    color: theme.palette.primary.main,
  },
};

/**
 * @typedef {*} SelectOrganizationProps
 */

/**
 * @param {SelectOrganizationProps} props
 * @returns {React.JSX.Element}
 */
export default function SelectOrganization({ handleChange, ...props }) {
  const onChange = (/** @type {*} */ event, /** @type {*} */ newValue) => {
    let value;
    if (typeof newValue === "string") {
      value = { shortName: newValue, new: true };
    } else if (newValue?.inputValue || newValue?.inputValue === "") {
      // Create a new value from the user input
      value = {
        shortName: newValue.inputValue,
        toBeCreated: true,
      };
    } else {
      value = newValue;
    } // mouseSelect

    handleChange(value);
  };

  const filterOptions = (/** @type {*} */ options, /** @type {*} */ params) => {
    const filtered = filter(
      params.inputValue !== "" && params.inputValue.length > 1
        ? options
        : options.filter(
            (/** @type {{ isNetwork: boolean; }} */ o) => !o.isNetwork
          ),
      params
    );
    // Suggest the creation of a new value
    if (
      params.inputValue !== "" &&
      !filtered.some(
        (f) => f.shortName.toLowerCase() === params.inputValue.toLowerCase()
      )
    ) {
      filtered.push({
        inputValue: params.inputValue,
        shortName: `Create "${params.inputValue}" as a new counterparty`,
      });
      //Integrations - not yet implemented
      if (props.org?.integrations?.hubspot) {
        filtered.push({
          // When you have a hubspot integration, also show the hubspot search option
          inputValue: params.inputValue,
          shortName: `Search "${params.inputValue}" inside Hubspot`,
          isHubspot: true,
        });
      }
      if (props.org?.integrations?.salesforce?.instanceURL) {
        filtered.push({
          // When you have a salesforce integration, also show the salesforce search option
          inputValue: params.inputValue,
          shortName: `Search "${params.inputValue}" inside Salesforce`,
          isSalesforce: true,
        });
      }
    } else if (params.inputValue === "") {
      filtered.unshift({
        isNew: true,
        inputValue: params.inputValue,
        shortName: `Create New Party`,
      });
    }
    return filtered;
  };

  return (
    <Autocomplete
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      sx={{ width: "100%" }}
      freeSolo
      {...props}
      onChange={onChange}
      filterOptions={filterOptions}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input.
        if (typeof option === "string") return option;
        // Add "xxx" option created dynamically.
        if (option.inputValue) return option.inputValue;
        // Regular option.
        return option.shortName;
      }}
      renderOption={(props, option) => (
        <Typography
          variant={option._id !== undefined ? "body1" : "subtitle1"}
          color="textPrimary"
          {...props}
        >
          {option.shortName}
          {option.isNetwork || option.isHubspot || option.isSalesforce ? (
            <>
              <FontAwesomeIcon
                icon={
                  option.isHubspot
                    ? faHubspot
                    : option.isSalesforce
                    ? faSalesforce
                    : faProjectDiagram
                }
                style={
                  option.isHubspot || option.isSalesforce
                    ? styles.cptyIcon
                    : styles.networkIcon
                }
              />
              <span
                style={
                  option.isHubspot || option.isSalesforce
                    ? styles.cptySpan
                    : styles.networkSpan
                }
              >
                {option.isHubspot
                  ? "Hubspot"
                  : option.isSalesforce
                  ? "Salesforce"
                  : "Canveo Network"}
              </span>
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={
                  ["Person"].includes(option.entityCategory)
                    ? faUser
                    : faBuilding
                }
                style={styles.cptyIcon}
              />
              <span style={styles.cptySpan}>Counterparty</span>
            </>
          )}
        </Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={"Select Party"}
          placeholder={"Party name ..."}
          autoFocus
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            autoComplete: "new-password",
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={!props.value?._id ? faSearch : faBuilding}
                  color={theme.palette.primary.main}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
