import {
  faClipboard,
  faComments,
  faFileImport,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { Box, IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  $getSelection,
  $isElementNode,
  COMMAND_PRIORITY_LOW,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { globalStore } from "../../../../state/store";
import { INSERT_INLINE_COMMAND } from "../../commands";
import { $isMarkNode, MarkNode } from "../../nodes/MarkNode";
import { $isRedlineNode } from "../../nodes/RedlineNode";
import getDOMRangeRect from "../../utils/getDOMRangeRect";
import setFloatingElementPosition from "../../utils/setFloatingElementPosition";

const FloatingMenu = styled(Box)(() => ({
  display: "flex",
  background: "#fff",
  padding: "4px",
  verticalAlign: "middle",
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 10,
  opacity: 0,
  boxShadow: "0 5px 10px rgba(0, 0, 0, 0.3)",
  borderRadius: "8px",
  transition: "opacity 0.5s",
  height: "40px",
  willChange: "transform",
}));

/**
 * @typedef {object} FloatingContextMenuPluginProps
 * @property {import("lexical").LexicalEditor} editor
 * @property {HTMLElement} anchorElem
 * @property {boolean} isInEffect
 * @property {boolean} isTemplate
 */

/**
 * @param {FloatingContextMenuPluginProps} props
 * @returns {React.JSX.Element | undefined}
 */
export default function FloatingContextMenu({
  editor,
  anchorElem,
  isInEffect,
  isTemplate,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [displayMenu, setDisplayMenu] = useState(true);
  const [isPreviousVersion, setIsPreviousVersion] = useState(false);
  const [displayCreateMergeFieldMenu, setDisplayCreateMergeFieldMenu] =
    useState(true);
  const [displayCreateCommentMenu, setDisplayCreateCommentMenu] =
    useState(true);

  // We only want to display the hover menu if the text selection is not done in the
  // context of an open issue navigation.
  useEffect(
    () => {
      if (state.selectedOpenIssue.id) {
        if (state.selectedOpenIssue.type === "text") {
          setDisplayMenu(true);
        } else {
          setDisplayMenu(false);
        }
      } else {
        setDisplayMenu(true);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.selectedOpenIssue.id]
  );

  useEffect(() => {
    setIsPreviousVersion(
      state.drawerVersions.active._id !== state.drawerVersions.versions[0]._id
    );
  }, [state.drawerVersions.active, state.drawerVersions.versions]);

  const contextMenuRef = useRef(
    /** @type {React.MutableRefObject<*> | null} */ (null)
  );

  const mouseMoveListener = (/** @type {{ buttons: number; }} */ e) => {
    if (contextMenuRef?.current && (e.buttons === 1 || e.buttons === 3)) {
      // @ts-ignore
      contextMenuRef.current.style.pointerEvents = "none";
    }
  };

  const mouseUpListener = () => {
    if (contextMenuRef?.current) {
      // @ts-ignore
      contextMenuRef.current.style.pointerEvents = "auto";
    }
  };

  useEffect(() => {
    if (contextMenuRef?.current) {
      document.addEventListener("mousemove", mouseMoveListener);
      document.addEventListener("mouseup", mouseUpListener);

      return () => {
        document.removeEventListener("mousemove", mouseMoveListener);
        document.removeEventListener("mouseup", mouseUpListener);
      };
    }
  }, [contextMenuRef]);

  const updateFloatingMenu = useCallback(() => {
    const selection = $getSelection();

    const contextMenuElem = contextMenuRef.current;
    const nativeSelection = window.getSelection();

    if (contextMenuElem === null) return;

    const rootElement = editor.getRootElement();

    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const nodes = selection.getNodes();
      let selectionInsideMarkNode = false;

      selectionInsideMarkNode = nodes.some((x) => $isMarkNode(x));
      if (!selectionInsideMarkNode) {
        const [node] = nodes;
        if (node) {
          const markNode = $getNearestNodeOfType(node, MarkNode);
          if (markNode) {
            selectionInsideMarkNode = true;
          }
        }
      }

      setDisplayCreateCommentMenu(!selectionInsideMarkNode);

      if (
        selectionInsideMarkNode ||
        nodes.some(
          (node) =>
            $isRedlineNode(node) ||
            ($isMarkNode(node) && node.getMarkType() === "mergeField")
        ) ||
        nodes.filter((node) => $isElementNode(node)).length > 1
      ) {
        setDisplayCreateMergeFieldMenu(false);
      } else {
        setDisplayCreateMergeFieldMenu(true);
      }

      const rangeRect = getDOMRangeRect(nativeSelection, rootElement);

      setFloatingElementPosition(rangeRect, contextMenuElem, anchorElem);
    }
  }, [editor, anchorElem]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;

    const updateMenu = () => {
      editor.getEditorState().read(() => {
        updateFloatingMenu();
      });
    };

    window.addEventListener("resize", updateMenu);
    if (scrollerElem) {
      scrollerElem.addEventListener("scroll", updateMenu);
    }

    return () => {
      window.removeEventListener("resize", updateMenu);
      if (scrollerElem) {
        scrollerElem.removeEventListener("scroll", updateMenu);
      }
    };
  }, [editor, updateFloatingMenu, anchorElem]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateFloatingMenu();
    });

    return mergeRegister(
      // @ts-ignore
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateFloatingMenu();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateFloatingMenu();
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, updateFloatingMenu]);

  const mainAg = state.agrs.find(
    (/** @type {{ parentID: string; }} */ a) => !a.parentID
  );
  const isAgrExec = Boolean(state.agrExec) && Boolean(state.agrExec._id);
  /** @type {boolean} */
  const isCurrentVersionOwner =
    !isAgrExec &&
    Boolean(mainAg) &&
    mainAg.avOwners.some(
      (/** @type {string} */ owner) => owner === state.org._id
    );

  const notVersionOwner =
    !isTemplate &&
    !isCurrentVersionOwner &&
    !isAgrExec &&
    !["Execution", "InEffect"].includes(mainAg?.agrStatus);

  // TODO: We'll need to  have this condition inside te rendering if some actions can happen even in read only mode
  if (displayMenu) {
    return (
      <FloatingMenu ref={contextMenuRef}>
        {state.user.role.name !== "Counterparty" && (
          <Tooltip title="Add Merge Field">
            <span>
              <IconButton
                color="primary"
                disabled={
                  notVersionOwner ||
                  isInEffect ||
                  isPreviousVersion ||
                  !displayCreateMergeFieldMenu
                }
                onClick={() => {
                  editor.dispatchCommand(INSERT_INLINE_COMMAND, "param");
                }}
              >
                <FontAwesomeIcon icon={faFileImport} size="sm" />
              </IconButton>
            </span>
          </Tooltip>
        )}

        {!(
          state.user.role.name === "Counterparty" &&
          state.drawerVersions.active.editMode === "read"
        ) && (
          <Tooltip title="Add Public Comment">
            <span>
              <IconButton
                color="primary"
                disabled={
                  notVersionOwner ||
                  isInEffect ||
                  isPreviousVersion ||
                  !displayCreateCommentMenu
                }
                onClick={() => {
                  editor.dispatchCommand(INSERT_INLINE_COMMAND, "comment");
                }}
              >
                <FontAwesomeIcon icon={faComments} size="sm" />
              </IconButton>
            </span>
          </Tooltip>
        )}

        <Tooltip title="Add Private Note">
          <span>
            <IconButton
              color="primary"
              disabled={isPreviousVersion}
              onClick={() => {
                editor.dispatchCommand(
                  INSERT_INLINE_COMMAND,
                  "internalComment"
                );
              }}
            >
              <FontAwesomeIcon icon={faClipboard} size="sm" />
            </IconButton>
          </span>
        </Tooltip>
      </FloatingMenu>
    );
  }
}
