import theme from "../theme/theme";

const colors = ["secondary", "tertiary", "quaternary", "quinary", "senary"];

/**
 * @param {string} partyID
 * @param {string} [modifier]
 * @param {string} [transparency]
 * @param {boolean} [text]
 * @returns {string}
 */
export default function getColorForPartyID(
  partyID,
  modifier = "",
  transparency = "",
  text = false
) {
  const partyNumber = parseInt(partyID.split("_")[0].split("party")[1]);
  if (isNaN(partyNumber)) {
    console.error(`${partyID} is not a valid party ID.`);
    return "primary";
  }

  const maxColor = colors.length;
  const color =
    partyNumber === 0 ? "primary" : colors[(partyNumber - 1) % maxColor];
  // This was done because the button color property has to be a string
  //  and breaks if the return is from the theme.palette (couldn't find why)
  if (text) return color;

  if (modifier === "theme") return addTransparency(color, transparency);
  if (modifier === "dark") {
    // @ts-ignore
    return addTransparency(theme.palette[color].dark, transparency);
  }

  // @ts-ignore
  return addTransparency(theme.palette[color].main, transparency);
}

/**
 * @param {string} color
 * @param {string} transparency
 * @returns {string}
 */
function addTransparency(color, transparency) {
  return color + transparency;
}
