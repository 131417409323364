import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useEffect, useState } from "react";
import { analyzeNode } from "../utils";
import { $getRoot } from "lexical";
import ContentDrawer from "../../drawer/ContentDrawer";

/**
 * @typedef {object} TocElement
 * @property {HTMLElement | null} element
 * @property {number} level
 * @property {string} item
 */

export default function TocPlugin() {
  const [editor] = useLexicalComposerContext();
  const [content, setContent] = useState([]);

  useEffect(() => {
    /** @type {TocElement[]} */
    const tocItems = []; // tocGen => Table of Contents Generation

    editor.getEditorState().read(() => {
      const rootNode = $getRoot();

      rootNode.getChildren().forEach((node) => {
        const children = node.getChildren();
        const textContent = node.getTextContent();
        if (!children?.length || textContent.trim().length <= 3) return;

        const nodeDetails = analyzeNode(textContent);
        const level = nodeDetails.fourthLeaderLead
          ? 4
          : nodeDetails.thirdLeaderLead
          ? 3
          : nodeDetails.secondLeaderLead
          ? 2
          : 1;

        const endPos =
          nodeDetails.firstDotPositionAfterLead > -1 &&
          nodeDetails.firstDotPositionAfterLead < 50
            ? nodeDetails.firstDotPositionAfterLead
            : 50;

        const item = nodeDetails.isDefinition
          ? nodeDetails.clauseTitle
          : textContent.substring(0, endPos);

        const element = editor.getElementByKey(node.getKey());

        tocItems.push({ element: element, level: level, item: item });
      });
    });

    // @ts-ignore
    setContent(tocItems);
  }, [editor]);

  return <ContentDrawer tableContent={content} />;
}
