import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import {
  faAngleDown,
  faBrain,
  faChevronCircleLeft,
  faChevronCircleRight,
  faCircleX,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { $getRoot } from "lexical";
import React, { useContext, useEffect, useState } from "react";
import SweetScroll from "sweet-scroll";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import Agreeements from "../../views/Agreements";
import CanveoCircularProgress from "../CanveoCircularProgress";
import DialogAnalyzingAgreementProgressBar from "../dialogs/DialogAnalyzingAgreementProgressBar";
import PlaybookDrawer from "./PlaybookDrawer";

/**
 * @typedef {object} ClauseTopic
 * @property {string} _id
 * @property {string} name
 * @property {string} orgID
 * @property {boolean} active
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {number} __v
 */

/**
 * @typedef {object} AnalyzedClauseTopic
 * @property {string} id
 * @property {string} name
 * @property {string} complianceExplanation
 * @property {"compliant" | "nonCompliant"} complianceRating
 * @property {{ id: string; text: string; updatedText: string; changeExplanation: string; publicComment: string; }[]} topicClauses
 */

/**
 * @typedef {object} FastTrackDrawerProps
 * @property {string} agreementVersionId
 */

/**
 * @typedef {object} SelectedClause
 * @property {import("../editor/nodes/ClauseNode").SerializedClauseNode} serializedClauseNode
 * @property {string} textContent
 */

/**
 * @param {FastTrackDrawerProps} props
 */
export function FastTrackDrawer({ agreementVersionId }) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();

  const [analyzedClauseTopics, setAnalyzedClauseTopics] = useState(
    /** @type {AnalyzedClauseTopic[]} */ ([])
  );
  // const [documentClauseTopics, setDocumentClauseTopics] = useState(
  //   /** @type {ClauseTopic[]} */ ([])
  // );
  const [selectedAnalyzedClauseTopic, setSelectedAnalyzedClauseTopic] =
    useState(
      /** @type {{ clauseTopicId: string; clauseTopic: { complianceExplanation: string}; clauseId: string; clause: { id: string; text: string; updatedText: string; changeExplanation: string; publicComment: string; }} | null} */ (
        null
      )
    );
  const [jobComplete, setJobComplete] = useState("initial");
  // const [selectedChip, setSelectedChip] = useState("");
  // const [, setActiveSection] = useState("");
  const [, setInfoDialogOpen] = useState(false);
  const [, setDeleteDialogOpen] = useState(false);
  const [, setCurrentChip] = useState("");
  const [anchorEl, setAnchorEl] = useState(null); // For compliance menu
  // const [selectedClauseTopic, setSelectedClauseTopic] = useState(
  //   /** @type {ClauseTopic | null} */ (null)
  // );
  const [selectedClause, setSelectedClause] = useState(
    /** @type {SelectedClause | null} */ (null)
  );
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(
    () => {
      (async () => {
        const response = await axios.get(
          `${state.settings.api}chatGPT/${agreementVersionId}/analyzeAgreementClauses`
        );

        const data = response.data.data;
        setJobComplete(data.status);
        if (data.status === "finished") {
          setAnalyzedClauseTopics(data.topicResults);
        }
      })();
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const element = document.getElementById(
      "fast-track-drawer-result-container"
    );
    if (element) {
      element.scrollTo(0, 0);
    }
  });

  /**
   * @param {*} event
   */
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  /**
   * @param {*} status
   */
  const handleComplianceChange = (status) => {
    console.log("Compliance Status:", status);
    closeMenu();
  };

  /**
   * @param {AnalyzedClauseTopic} analyzedClauseTopic
   */
  function handleAnalyzedClauseTopicClick(analyzedClauseTopic) {
    if (analyzedClauseTopic.id !== selectedAnalyzedClauseTopic?.clauseTopicId) {
      setSelectedAnalyzedClauseTopic({
        clauseTopicId: analyzedClauseTopic.id,
        clauseTopic: analyzedClauseTopic,
        clauseId: analyzedClauseTopic.topicClauses[0].id,
        clause: analyzedClauseTopic.topicClauses[0],
      });
      dispatch({
        type: "NEW_CLAUSE_TOPIC_SELECTION",
        payload: {
          event: "NEW_CLAUSE_TOPIC_SELECTION",
          clauseTopicId: analyzedClauseTopic.id,
        },
      });
    }

    editor.getEditorState().read(() => {
      const sectionNodes = $getRoot().getChildren();
      const clauseNodes = sectionNodes.flatMap((node) => node.getChildren());

      const clauseNode = clauseNodes.find(
        (clauseNode) => clauseNode.id === analyzedClauseTopic.topicClauses[0].id
      );
      if (clauseNode) {
        const element = editor.getElementByKey(clauseNode.getKey());
        if (!element) return;

        const containerElement = document.getElementById("html-main");
        if (!containerElement) return;

        const selectedClause = {
          serializedClauseNode: clauseNode.exportJSON(),
          textContent: clauseNode.getTextContent(),
        };
        setSelectedClause(selectedClause);

        const scroller = new SweetScroll(
          {
            duration: 800,
            offset: -70,
            easing: "easeInOutQuart",
          },
          containerElement
        );
        scroller.toElement(element);
        return;
      }
    });
  }

  /**
   * @param {string} clauseId
   */
  function selectClauseById(clauseId) {
    editor.getEditorState().read(() => {
      const sectionNodes = $getRoot().getChildren();
      const clauseNodes = sectionNodes.flatMap((node) => node.getChildren());

      const clauseNode = clauseNodes.find(
        (clauseNode) => clauseNode.id === clauseId
      );
      if (clauseNode) {
        const element = editor.getElementByKey(clauseNode.getKey());
        if (!element) return;

        const containerElement = document.getElementById("html-main");
        if (!containerElement) return;

        const selectedClause = {
          serializedClauseNode: clauseNode.exportJSON(),
          textContent: clauseNode.getTextContent(),
        };
        setSelectedClause(selectedClause);

        const scroller = new SweetScroll(
          {
            duration: 800,
            offset: -70,
            easing: "easeInOutQuart",
          },
          containerElement
        );
        scroller.toElement(element);
        return;
      }
    });
  }

  function handleAnalyzedClauseNext() {
    if (!selectedAnalyzedClauseTopic) {
      setSelectedAnalyzedClauseTopic({
        clauseTopicId: analyzedClauseTopics[0].id,
        clauseTopic: analyzedClauseTopics[0],
        clauseId: analyzedClauseTopics[0].topicClauses[0].id,
        clause: analyzedClauseTopics[0].topicClauses[0],
      });
      dispatch({
        type: "NEW_CLAUSE_TOPIC_SELECTION",
        payload: {
          event: "NEW_CLAUSE_TOPIC_SELECTION",
          clauseTopicId: analyzedClauseTopics[0].id,
        },
      });
      return;
    }

    const analyzedClauseTopic = analyzedClauseTopics.find(
      (act) => act.id === selectedAnalyzedClauseTopic?.clauseTopicId
    );
    if (analyzedClauseTopic) {
      const selectedClauseIndex = analyzedClauseTopic.topicClauses.findIndex(
        (x) => x.id === selectedAnalyzedClauseTopic?.clauseId
      );
      if (selectedClauseIndex > -1) {
        if (
          selectedClauseIndex ===
          analyzedClauseTopic.topicClauses.length - 1
        ) {
          const clauseTopicIndex = analyzedClauseTopics.findIndex(
            (act) => act.id === selectedAnalyzedClauseTopic?.clauseTopicId
          );
          if (clauseTopicIndex > -1) {
            if (clauseTopicIndex === analyzedClauseTopics.length - 1) {
              setSelectedAnalyzedClauseTopic({
                clauseTopicId: analyzedClauseTopics[0].id,
                clauseTopic: analyzedClauseTopics[0],
                clauseId: analyzedClauseTopics[0].topicClauses[0].id,
                clause: analyzedClauseTopics[0].topicClauses[0],
              });
              selectClauseById(analyzedClauseTopics[0].topicClauses[0].id);
              dispatch({
                type: "NEW_CLAUSE_TOPIC_SELECTION",
                payload: {
                  event: "NEW_CLAUSE_TOPIC_SELECTION",
                  clauseTopicId: analyzedClauseTopics[0].id,
                },
              });
            } else {
              setSelectedAnalyzedClauseTopic({
                clauseTopicId: analyzedClauseTopics[clauseTopicIndex + 1].id,
                clauseTopic: analyzedClauseTopics[clauseTopicIndex + 1],
                clauseId:
                  analyzedClauseTopics[clauseTopicIndex + 1].topicClauses[0].id,
                clause:
                  analyzedClauseTopics[clauseTopicIndex + 1].topicClauses[0],
              });
              selectClauseById(
                analyzedClauseTopics[clauseTopicIndex + 1].topicClauses[0].id
              );
              dispatch({
                type: "NEW_CLAUSE_TOPIC_SELECTION",
                payload: {
                  event: "NEW_CLAUSE_TOPIC_SELECTION",
                  clauseTopicId: analyzedClauseTopics[clauseTopicIndex + 1].id,
                },
              });
            }
          }
        } else {
          setSelectedAnalyzedClauseTopic({
            clauseTopicId: analyzedClauseTopic.id,
            clauseTopic: analyzedClauseTopic,
            clauseId:
              analyzedClauseTopic.topicClauses[selectedClauseIndex + 1].id,
            clause: analyzedClauseTopic.topicClauses[selectedClauseIndex + 1],
          });
          selectClauseById(
            analyzedClauseTopic.topicClauses[selectedClauseIndex + 1].id
          );
          dispatch({
            type: "NEW_CLAUSE_TOPIC_SELECTION",
            payload: {
              event: "NEW_CLAUSE_TOPIC_SELECTION",
              clauseTopicId: analyzedClauseTopic.id,
            },
          });
        }
      }
    }
  }

  /**
   * @param {*} chipLabel
   * @param {*} event
   */
  const handleInfoClick = (chipLabel, event) => {
    event.stopPropagation();
    setCurrentChip(chipLabel);
    setInfoDialogOpen(true);
  };

  /**
   * @param {*} chipLabel
   * @param {*} event
   */
  const handleDeleteClick = (chipLabel, event) => {
    event.stopPropagation();
    setCurrentChip(chipLabel);
    setDeleteDialogOpen(true);
  };

  // const removeChip = () => {
  //   setDeleteDialogOpen(false);
  // };

  // const closeDialogs = () => {
  //   setInfoDialogOpen(false);
  //   setDeleteDialogOpen(false);
  // };

  return (
    <Grid container direction="row">
      <Grid item xs={6} sx={{ paddingX: 2 }}>
        <Grid container direction="row" alignItems="center" pt={2}>
          <Grid item>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "14px",
              }}
            >
              <FontAwesomeIcon
                icon={faBrain}
                color={theme.palette.primary.main}
                size="lg"
              />
              &nbsp;&nbsp;&nbsp;AI Review
            </Typography>
          </Grid>
        </Grid>

        {jobComplete === "ongoing" && (
          <>
            <Grid container direction="row" justifyContent="center">
              <Grid item mt={4}>
                {"Analyzing Agreement ..."}
              </Grid>
            </Grid>

            <Grid container direction="row" justifyContent="center">
              <Grid item mt={4}>
                <CanveoCircularProgress />
              </Grid>
            </Grid>
            <DialogAnalyzingAgreementProgressBar open={true} close={() => {}} />
          </>
        )}

        {(jobComplete === "initial" || jobComplete === "finished") && (
          <>
            <br />

            {selectedClause && (
              <Chip label={selectedClause.textContent} variant="outlined" />
            )}

            <Grid container mt={2}>
              <Grid item>
                <IconButton
                  color="primary"
                  disabled={jobComplete === "initial"}
                >
                  <FontAwesomeIcon icon={faChevronCircleLeft} size="1x" />
                </IconButton>
              </Grid>

              <Grid item>
                <IconButton
                  color="primary"
                  onClick={handleAnalyzedClauseNext}
                  disabled={jobComplete === "initial"}
                >
                  <FontAwesomeIcon icon={faChevronCircleRight} size="1x" />
                </IconButton>
              </Grid>
            </Grid>

            <br />

            <Autocomplete
              disablePortal
              options={analyzedClauseTopics}
              value={analyzedClauseTopics}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Topics" />}
              multiple
              filterSelectedOptions
              disableClearable
              disabled={jobComplete === "initial"}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={option.name}
                    title={option.name}
                    onClick={() => handleAnalyzedClauseTopicClick(option)}
                    icon={
                      <IconButton
                        onClick={(e) => handleInfoClick(option.name, e)}
                        size="small"
                      >
                        <FontAwesomeIcon icon={faCircleInfo} />
                      </IconButton>
                    }
                    deleteIcon={
                      <>
                        <IconButton onClick={openMenu} size="small">
                          <FontAwesomeIcon icon={faAngleDown} />
                        </IconButton>

                        <IconButton
                          onClick={(e) => handleDeleteClick(option.name, e)}
                          size="small"
                        >
                          <FontAwesomeIcon icon={faCircleX} />
                        </IconButton>
                      </>
                    }
                    style={{
                      backgroundColor:
                        option.complianceRating === "compliant"
                          ? "green"
                          : "red",
                      // color: "white",
                      fontWeight: "600",
                      opacity:
                        selectedAnalyzedClauseTopic?.clauseTopicId ===
                          option.id || !selectedAnalyzedClauseTopic
                          ? 1
                          : 0.2,
                    }}
                  />
                ))
              }
            />

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={closeMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleComplianceChange("Compliant")}>
                Compliant
              </MenuItem>

              <MenuItem
                onClick={() => handleComplianceChange("Partially Compliant")}
              >
                Partially Compliant
              </MenuItem>

              <MenuItem onClick={() => handleComplianceChange("Non-compliant")}>
                Non-compliant
              </MenuItem>
            </Menu>

            <br />

            {selectedAnalyzedClauseTopic && (
              <div
                id="fast-track-drawer-result-container"
                style={{
                  minHeight: "360px",
                  maxHeight: "360px",
                  overflowY: "scroll",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
              >
                <Typography
                  color={theme.palette.primary.main}
                  fontWeight="600"
                  onClick={handleClickOpen}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  Similar contracts out of compliance ...
                </Typography>

                {selectedAnalyzedClauseTopic.clauseTopic
                  .complianceExplanation !== "undefined" && (
                  <>
                    <br />

                    <Typography fontWeight="600">
                      Explanation of compliance assessment
                    </Typography>

                    <Typography fontSize={14}>
                      {
                        selectedAnalyzedClauseTopic.clauseTopic
                          .complianceExplanation
                      }
                    </Typography>
                  </>
                )}

                {selectedAnalyzedClauseTopic.clause.updatedText !==
                  "undefined" && (
                  <>
                    <br />

                    <Typography fontWeight="600">Proposed Clause</Typography>

                    <Typography fontSize={14}>
                      {selectedAnalyzedClauseTopic.clause.updatedText}
                    </Typography>
                  </>
                )}

                {selectedAnalyzedClauseTopic.clause.changeExplanation !==
                  "undefined" && (
                  <>
                    <br />

                    <Typography fontWeight="600">What was changed?</Typography>

                    <Typography fontSize={14}>
                      {selectedAnalyzedClauseTopic.clause.changeExplanation}
                    </Typography>
                  </>
                )}

                {selectedAnalyzedClauseTopic.clause.publicComment !==
                  "undefined" && (
                  <>
                    <br />

                    <Typography fontWeight="600">Proposed comment</Typography>

                    <Typography fontSize={14}>
                      {selectedAnalyzedClauseTopic.clause.publicComment}
                    </Typography>
                  </>
                )}
              </div>
            )}

            <br />

            <Grid container justifyContent="right">
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={async (event) => {
                    setJobComplete("ongoing");
                    axios.post(
                      `${state.settings.api}chatGPT/${agreementVersionId}/analyzeAgreementClauses`
                    );
                  }}
                >
                  Run Analysis
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <Grid item xs={6} sx={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}>
        <PlaybookDrawer />
      </Grid>

      <Dialog open={open} onClose={handleClose} maxWidth={"xl"}>
        <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
          <IconButton onClick={handleClose}>
            <FontAwesomeIcon
              icon={faTimes}
              style={{ padding: "4px 7px", fontSize: "20px" }}
            />
          </IconButton>
        </Box>
        <DialogTitle>Similar Contracts out of Compliance</DialogTitle>
        <DialogContent>
          <Agreeements tableOnly={true} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Grid>
  );
}
