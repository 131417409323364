import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Chip,
  InputAdornment,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useContext, useState } from "react";
import useLabelService from "../hooks/useLabelService";
import { globalStore } from "../state/store";
import DialogLabel from "./dialogs/DialogLabel";

/**
 * @param {*} props
 * @returns
 */
export default function SelectLabels(props) {
  const displayIcon = props?.displayIcon ?? true;

  // @ts-ignore
  const [state] = useContext(globalStore);

  const { labelTypes } = useLabelService(true);

  const [selectedLabel, setSelectedLabel] = useState(null);
  const [creatingLabel, setCreatingLabel] = useState(false);

  const filter = createFilterOptions();

  const closeDialog = () => {
    setSelectedLabel(null);
    setCreatingLabel(false);
  };

  /**
   * @param {*} label
   */
  const handleLabelClick = (label) => {
    setSelectedLabel(label);
  };

  /**
   * @param {*} options
   * @param {*} params
   * @returns
   */
  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (
      params.inputValue !== "" &&
      !filtered.some(
        (option) =>
          option.name.toLowerCase() === params.inputValue.toLowerCase()
      )
    ) {
      filtered.push({
        inputValue: params.inputValue,
        name: `Create "${params.inputValue}" as a new label`,
      });
    } else if (params.inputValue === "") {
      filtered.unshift({
        inputValue: params.inputValue,
        name: `Create New Label`,
      });
    }
    return filtered;
  };

  /**
   * @param {*} e
   * @param {*} newVal
   */
  const onChange = (e, newVal) => {
    const toCreate = newVal.find(
      (/** @type {{ inputValue: string; }} */ label) =>
        !!label.inputValue || label.inputValue === ""
    );
    if (toCreate) setCreatingLabel(true);
    if (toCreate && toCreate.inputValue) {
      // @ts-ignore
      setSelectedLabel({ name: toCreate.inputValue });
    }

    props.handleLabelSelect(newVal);
  };

  /**
   * @param {*} newLabel
   */
  const handleCreate = (newLabel) => {
    const selectedLabels = state.labels.filter(
      (/** @type {{ _id: any; }} */ sl) => props.selectedIDs.includes(sl._id)
    );

    props.handleLabelSelect([...selectedLabels, newLabel]);
  };

  return (
    <>
      <Autocomplete
        id="filter-label"
        fullWidth={!displayIcon ? true : false}
        options={props.options}
        value={state.labels.filter((sl) => props.selectedIDs.includes(sl._id))}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        disableCloseOnSelect
        multiple
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Labels"
            placeholder="Labels ..."
            InputProps={
              props.selectedIDs.length > 0
                ? {
                    ...params.InputProps,
                    autoComplete: "new-password",
                  }
                : {
                    ...params.InputProps,
                    autoComplete: "new-password",
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={props.adornIcon} />
                      </InputAdornment>
                    ),
                  }
            }
          />
        )}
        renderOption={(props, option) => (
          <Box component={"li"} {...props}>
            {option._id ? (
              <Chip
                key={option._id}
                label={option.name}
                color="primary"
                sx={{
                  fontWeight: "700",
                  px: 1,
                }}
              />
            ) : (
              <Typography variant="subtitle1" color={"textPrimary"}>
                {option.name}
              </Typography>
            )}
          </Box>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option._id}
              label={option.name}
              color="primary"
              sx={{
                fontWeight: "700",
                px: 1,
              }}
              icon={
                displayIcon ? (
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    style={{ fontSize: "16px" }}
                  />
                ) : (
                  <></>
                )
              }
              onClick={() => handleLabelClick(option)}
              {...getTagProps({ index })}
            />
          ))
        }
        filterOptions={filterOptions}
      />

      <DialogLabel
        open={!!selectedLabel || creatingLabel}
        // @ts-ignore
        type={labelTypes.find((type) => type.name === props.labelType)}
        label={selectedLabel}
        // @ts-ignore
        options={labelTypes.filter((type) => type.name !== "Area")}
        handleClose={closeDialog}
        handleCreate={handleCreate}
      />
    </>
  );
}
