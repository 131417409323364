//NODE TYPES
export const TYPE_LISTITEM_NODE = "customlistitem";
export const TYPE_LIST_NODE = "customlist";
export const TABLE_NODE_TYPE = "customTable";
export const TABLE_CELL_NODE_TYPE = "customTableCell";
export const TABLE_ROW_NODE_TYPE = "customTableRow";

//UTILS
export const NOT_ACCEPTED_BULLET_MARKERS = [61607, 61623];
export const IMAGE_NODE_TYPE = "customImage";
export const CROSS_REF_NODE_TYPE = "crossRef";
export const CUSTOM_PARAGRAPH_TYPE = "customParagraph";
export const CUSTOM_HEADING_TYPE = "customHeading";

export const EDITOR_WIDTH = 780;

/** @type {import("lexical").TextFormatType[]} */
export const SUPPORTED_FORMAT_TYPES = [
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "highlight",
  "subscript",
  "superscript",
];

/** @type {import("../types/sfdt").CharacterFormat} */
export const defaultBodyStyles = {
  //Arial
  fontFamilyNonFarEast: "Arial",
  fontFamilyAscii: "Arial",
  fontFamilyBidi: "Arial",
  fontFamily: "Arial",
  fontSize: 11,
  //linespacing
  //paragraphSpacing
  //defaultTabStops
};

/** @type {Map<string, import("../types/sfdt").CharacterFormat>} */
export const defaultHeadingsStylesMap = new Map([
  ["h1", { ...defaultBodyStyles, fontSize: 18, bold: true }],
  ["h2", { ...defaultBodyStyles, fontSize: 17 }],
  ["h3", { ...defaultBodyStyles, fontSize: 15 }],
  ["h4", { ...defaultBodyStyles, fontSize: 13 }],
  [
    "h5",
    {
      ...defaultBodyStyles,
      fontSize: 11,
      italic: true,
    },
  ],
  ["h6", { ...defaultBodyStyles, bold: true, fontSize: 10 }],
]);

/** @type {Array<"h1"| "h2"| "h3"| "h4"| "h5"| "h6">} */
export const SUPPORTED_HEADING_TAGS = ["h1", "h2", "h3", "h4", "h5", "h6"];
export const defaultTableFormat = {
  allowAutoFit: true,
  leftIndent: 0,
  tableAlignment: "Left",
  preferredWidthType: "Auto",
  borders: {
    left: {
      lineStyle: "Single",
      lineWidth: 0.5,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
      color: "#000000FF",
    },
    right: {
      lineStyle: "Single",
      lineWidth: 0.5,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
      color: "#000000FF",
    },
    top: {
      lineStyle: "Single",
      lineWidth: 0.5,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
      color: "#000000FF",
    },
    bottom: {
      lineStyle: "Single",
      lineWidth: 0.5,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
      color: "#000000FF",
    },
    vertical: {
      lineStyle: "Single",
      lineWidth: 0.5,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
      color: "#000000FF",
    },
    horizontal: {
      lineStyle: "Single",
      lineWidth: 0.5,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
      color: "#000000FF",
    },
    diagonalDown: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
    diagonalUp: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
  },
  bidi: false,
  horizontalPositionAbs: "Left",
  horizontalPosition: 0,
};

export const defaultRowFormat = {
  allowBreakAcrossPages: true,
  isHeader: false,
  height: 0,
  heightType: "AtLeast",
  borders: {
    left: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
    right: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
    top: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
    bottom: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
    vertical: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
    horizontal: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
    diagonalDown: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
    diagonalUp: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
  },
};

export const defaultCellFormat = {
  columnSpan: 1,
  rowSpan: 1,
  // preferredWidth: 155.8000030517578,
  preferredWidthType: "Point",
  verticalAlignment: "Top",
  borders: {
    left: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
    right: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
    top: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
    bottom: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
    vertical: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
    horizontal: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
    diagonalDown: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
    diagonalUp: {
      lineStyle: "None",
      lineWidth: 0,
      shadow: false,
      space: 0,
      hasNoneStyle: false,
    },
  },
  // cellWidth: 155.8000030517578,
};
/** @type {Map<string, import("@lexical/rich-text").HeadingTagType>} */
export const supportedHeadingsMap = new Map([
  ["Heading 1", "h1"],
  ["Heading 2", "h2"],
  ["Heading 3", "h3"],
  ["Heading 4", "h4"],
  ["Heading 5", "h5"],
  ["Heading 6", "h6"],
]);

export const NON_PARAGRAPH_TYPES = [
  "Caption",
  "Table",
  "Heading",
  "List Paragraph",
  "Subtitle",
  "Title",
];

/** @type {Map<string, import("@lexical/rich-text").HeadingTagType|"span" | "paragraph">} */
export const supportedStylesMap = new Map([
  ["Normal", "paragraph"],
  ...Array.from(supportedHeadingsMap),
]);

export const ALLOWED_CHARACTER_FORMAT_KEYS = [
  "font-family",
  "fontFamily",
  "font-weight",
  "font-style",
  "color",
  "font-size",
  "fontFamilyAscii",
  "fontFamilyNonFarEast",
  "fontFamilyBidi",
  "text-decoration",
];
