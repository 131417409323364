import * as React from "react";
import theme from "../theme/theme";
import { Box, Button } from "@mui/material";

const ButtonGroupPills = (props) => {
  return (
    <Box
      sx={{
        display: "block",
        backgroundColor: theme.palette.grey[100],
        padding: "8px 10px 8px 10px",
        borderRadius: "30px",
      }}
    >
      {props.buttons.map((b) => (
        <Button
          variant={"contained"}
          key={b.title}
          disableElevation
          sx={{
            borderRadius: "30px",
            padding: "8px 28px 8px 28px",
            fontWeight: "700",
            backgroundColor:
              b.id === props.selected
                ? theme.palette.primary.contrastText
                : "transparent",
            color:
              b.id === props.selected
                ? theme.palette.primary.main
                : theme.palette.grey[600],
            "&:hover":
              b.id === props.selected
                ? { backgroundColor: theme.palette.primary.contrastText }
                : {
                    backgroundColor: theme.palette.primary.contrastText,
                    color: theme.palette.primary.main,
                  },
          }}
          onClick={(e) => props.click(b.id)}
        >
          {b.title}
        </Button>
      ))}
    </Box>
  );
};
export default ButtonGroupPills;
