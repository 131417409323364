import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";
import SummaryDrawer from "../drawer/SummaryDrawer";

/**
 * @typedef {object} DialogDatesAndRenewalProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {string} agreementId
 */

/**
 * @param {DialogDatesAndRenewalProps} props
 * @returns {React.JSX.Element}
 */
export default function DialogAgreementSummary({ open, close, agreementId }) {
  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>Agreement Summary</DialogTitle>

      <DialogContent>
        <SummaryDrawer docID={agreementId} isTemplate={false} />
      </DialogContent>
    </Dialog>
  );
}
