const selectedMergeFieldReducer = (state, action) => {
  switch (action.type) {
    case "OPEN_MERGE_FIELD_MENU":
    case "CREATED_MERGE_FIELD":
    case "DELETED_MERGE_FIELD":
      const selectedMergeField = { ...action.payload, event: action.type };
      return { selectedMergeField };
    default:
      return { selectedMergeField: state.selectedMergeField };
  }
};

export default selectedMergeFieldReducer;
