const userSessionReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_SESSION_FILTER":
      return { userSession: { ...state.userSession, filter: action.payload } };
    default:
      return { userSession: state.userSession };
  }
};

export default userSessionReducer;
