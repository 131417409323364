import { faCircleArrowRight, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PartyForm from "../forms/PartyForm";

/**
 * @typedef {object} DialogAddPartyProps
 * @property {boolean} open
 * @property {*[]} parties
 * @property {*[]} roles
 * @property {(role: *) => void} handleAddRole
 * @property {(party: *) => void} handleAddParty
 * @property {() => void} handleClose
 */

/**
 * @param {DialogAddPartyProps} props
 */
export default function DialogAddParty({
  open,
  parties,
  roles,
  handleAddRole,
  handleAddParty,
  handleClose,
}) {
  const [party, setParty] = useState({
    organization: null,
    entity: null,
    role: null,
  });

  useEffect(() => {
    setParty({
      organization: null,
      entity: null,
      role: null,
    });
  }, [open]);

  /**
   * @param {string} property
   * @param {*} value
   */
  const handlePartyChange = (property, value) => {
    if (
      property === "role" &&
      !!value &&
      !roles?.find((/** @type {*} */ r) => r?.label === value)
    ) {
      handleAddRole(value);
    }

    setParty((prev) => ({ ...prev, [property]: value }));
  };

  const submitParty = () => {
    handleAddParty({ ...party });
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>Add Party to Agreement</DialogTitle>

      <DialogContent style={{ paddingTop: "20px" }}>
        <PartyForm
          party={party}
          roles={roles}
          selectedParties={parties}
          handleChange={handlePartyChange}
        />
      </DialogContent>

      <DialogActions>
        <Button sx={{ marginRight: "auto" }} onClick={handleClose}>
          Cancel
        </Button>

        <Button
          sx={{ marginLeft: "auto" }}
          disabled={!(party.organization && party.entity)}
          onClick={submitParty}
        >
          Add&nbsp;&nbsp;
          <FontAwesomeIcon icon={faCircleArrowRight} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
