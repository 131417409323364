import {
  faCircleExclamation,
  faCommentLines,
  faFilter,
  faThumbsUp,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useContext } from "react";
import { globalStore } from "../state/store";
import { APPLY_FILTER_COMMAND } from "./editor/commands";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

const filters = [
  { id: "openIssues", name: "Open Issues", icon: faCircleExclamation },
  { id: "internalComments", name: "Internal Comments", icon: faCommentLines },
  { id: "publicComments", name: "Public Comments", icon: null },
  {
    id: "approvalCreation",
    name: "Approval Requests (by me)",
    icon: faThumbsUp,
  },
  { id: "approvalAssignee", name: "Approval Requests (for me)", icon: null },
];

const FilterMenuButton = (props) => {
  const [state] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);

  /*
  useEffect(() => { // UseEffect => If there is a filter, you cannot select across clauses => automatically deselect

    if(Boolean(activeFilter)) {
        editor.getEditorState().read(() => {
            let selection = $getSelection();
            console.log("SEL", selection.getNodes())
        })
    }

  }, [editor])*/

  const handleOpenFilterMenu = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorElFilter(null);
  };

  const applyFilter = (filter) => {
    //setActiveFilter(filter)
    editor.dispatchCommand(APPLY_FILTER_COMMAND, filter);
    handleCloseFilterMenu();
  };

  return (
    <div>
      {state.userSession.filter ? (
        <>
          <IconButton
            color="secondary"
            disabled
            sx={(theme) => ({
              fontSize: "15px",
              padding: "10px",
              justifyContent: "left",
              fontWeight: "bold",
              border: "none",
              borderRadius: "5px",
              maxHeight: "40px",
              color: theme.palette.secondary.contrastText,
              backgroundColor: theme.palette.secondary.main,
              "&:hover": {
                textDecoration: "none",
                backgroundColor: "rgb(162, 0, 70)",
              },
            })}
            onClick={(e) => applyFilter(null)}
          >
            <FontAwesomeIcon icon={faFilter} fontSize="14px" />
          </IconButton>
        </>
      ) : (
        <IconButton
          disabled
          variant="toolbar-btn-text"
          onClick={handleOpenFilterMenu}
        >
          <FontAwesomeIcon icon={faFilter} fontSize="14px" />
        </IconButton>
      )}

      <Menu
        sx={{ mt: "32px", zIndex: "2000" }}
        id="menu-appbar-filter"
        anchorEl={anchorElFilter}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElFilter)}
        onClose={handleCloseFilterMenu}
      >
        {filters.map((m, i) => (
          <MenuItem
            key={m.id}
            onClick={(e) => applyFilter(m.id)}
            style={{ width: "280px" }}
          >
            <ListItemIcon>
              {m.icon ? <FontAwesomeIcon icon={m.icon} fontSize="14px" /> : ""}
            </ListItemIcon>
            <ListItemText>{m.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default FilterMenuButton;
