import { faCaretLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext } from "react";
import { globalStore } from "../../state/store";

const CloseButton = styled(Box)(({ theme }) => ({
  position: "fixed",
  border: `1px solid ${theme.palette.grey[300]}`,
  color: theme.palette.grey[600],
  padding: "8px",
  left: "329px",
  top: "150px",
  cursor: "pointer",
  borderTopRightRadius: "5px",
  borderBottomRightRadius: "5px",
  backgroundColor: "white",
  borderLeftColor: "white",
  ":hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

/**
 * @param {*} _
 */
function LargeDrawer({ content, handleClose }) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  return (
    <Box
      sx={{
        pt: `calc(65px + ${state.appBar?.toolbarOpen ? "90px" : "0px"})`,
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CloseButton onClick={handleClose}>
        <FontAwesomeIcon icon={faCaretLeft} />
      </CloseButton>

      {content}
    </Box>
  );
}

export default LargeDrawer;
