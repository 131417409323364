/**
 * Removes all cross-references and mail merge fields from an SFDT. Mutates the object passed as an argument.
 *
 * This function recursively searches the SFDT for "inlines" which can show inside blocks, rows, cells, etc.,
 * and then checks whether any of the inlines are cross-reference or mail merge definitions/instances. If they
 * are then they are removed.
 *
 * @param {import("../../types/sfdt").Sfdt | *} obj
 * @returns {void}
 */
export function removeAllCrossReferencesAndMailMergeFieldsFromSfdt(obj) {
  if (typeof obj === "object" && obj !== null) {
    for (let key in obj) {
      if (key === "inlines") {
        const inlines = obj[key];

        for (let index = 0; index < inlines.length; index++) {
          const inline = inlines[index];
          if (!inline) continue;

          // Match cross-reference definition which appears on the SFDT in the following way:
          //
          // {
          //   "name": "_Ref170212976",
          //   "bookmarkType": 0
          // },
          // {
          //   "text": "This is a heading."
          // },
          // {
          //   "name": "_Ref170212976",
          //   "bookmarkType": 1
          // }
          if (
            (inline?.bookmarkType === 0 || inline?.bookmarkType === 1) &&
            inline?.name &&
            inline?.name.startsWith("_Ref")
          ) {
            // Remove cross-reference objects.
            inlines.splice(index, 1);

            // Adjust the loop counter to account for the removed item.
            index--;

            continue;
          }

          const nextInline = inlines[index + 1];
          if (!nextInline) continue;

          // Match cross-reference instance which appears on the SFDT in the following way:
          //
          // {
          //   "hasFieldEnd": true,
          //   "fieldType": 0
          // },
          // {
          //   "text": " REF _Ref170214396 \\r \\h "
          // },
          // {
          //   "fieldType": 2
          // },
          // {
          //   "text": "‎",
          //   "characterFormat": {
          //     "complexScript": true
          //   }
          // },
          // {
          //   "text": "1"
          // },
          // {
          //   "fieldType": 1
          // }
          //
          // We remove all the objects with the exception of the one that contains the text which we
          // preserve, hnece the inlines.splice(index + 1, 1) at the end.
          if (
            inline?.hasFieldEnd === true &&
            inline?.fieldType === 0 &&
            nextInline?.text &&
            (nextInline?.text.startsWith(" REF _Ref") || // Matches cross-references.
              nextInline?.text.startsWith("  REF _Ref")) // Matches mail merge fields.
          ) {
            // Remove cross-reference objects.
            inlines.splice(index, 1);
            inlines.splice(index, 1);
            inlines.splice(index, 1);
            inlines.splice(index, 1);
            inlines.splice(index + 1, 1);

            // Adjust the loop counter to account for the removal.
            index -= 5;
            if (index <= 0) {
              index = -1;
            }

            continue;
          }
        }
      }

      // Recursive call.
      removeAllCrossReferencesAndMailMergeFieldsFromSfdt(obj[key]);
    }
  } else if (Array.isArray(obj)) {
    for (let item of obj) {
      // Recursive call.
      removeAllCrossReferencesAndMailMergeFieldsFromSfdt(item);
    }
  }
}
