/**
 * @param {import("../../../components/MergeFieldMenu/dialogs/NewConditionDialog/condition").Condition} condition
 */
export const isNotEqualTo = ({
  baseValue,
  comparisonValue,
  mergeFieldValue,
}) => {
  if (!baseValue.isList && baseValue.value.type === "freeText") {
    throw new Error(
      `Base value type '${baseValue.value.type}' is not a valid type for operator 'isNotEqualTo'.`
    );
  }

  if (
    comparisonValue instanceof Array ||
    comparisonValue.type !== baseValue.value.type
  ) {
    throw new Error(
      `Comparison value needs to be of type '${baseValue.value.type}'.`
    );
  }

  if (baseValue.isList) {
    const value = baseValue.listOptions.find(
      (x) => x.id === baseValue.selectedListOptionsIds[0]
    )?.mergeFieldValue;

    if (JSON.stringify(value) !== JSON.stringify(comparisonValue)) {
      return mergeFieldValue;
    }
  } else {
    if (JSON.stringify(baseValue.value) !== JSON.stringify(comparisonValue)) {
      return mergeFieldValue;
    }
  }
};
