import { Autocomplete, Grid, TextField } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { globalStore } from "../../../state/store";
import { NewTicketContext } from "../contexts/NewTicketContext";

/**
 * @param {{ active: boolean }} props
 * @returns {React.JSX.Element}
 */
export default function AssigneesSelector({ active }) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const { ticket, setTicket } = useContext(NewTicketContext);
  const [users, setUsers] = useState(/** @type {{ email: string; }[]} */ ([]));

  async function loadData() {
    const getOrganizationUsersResponse = await axios.get(
      `${state.settings.api}user/org/${state.user.orgID}`
    );

    const users = getOrganizationUsersResponse.data.data;
    setUsers(users);
  }

  useEffect(
    () => {
      loadData();
    },
    // Empty dependency array since this only runs once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!active) return <></>;

  return (
    <Grid
      container
      direction="column"
      sx={{
        px: 2,
        pt: 4,
        maxHeight: 420,
        overflow: "auto",
        maxWidth: 800,
      }}
    >
      {users.length && (
        <Autocomplete
          value={ticket.assignee}
          disablePortal
          openOnFocus
          options={users}
          sx={{ width: 300 }}
          getOptionLabel={(option) => option.email}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Assignee ..."
              autoFocus={!ticket.assignee}
            />
          )}
          onChange={(_event, newInputValue) => {
            // @ts-ignore
            setTicket((ticket) => {
              return { ...ticket, assignee: newInputValue };
            });
          }}
        />
      )}
    </Grid>
  );
}
