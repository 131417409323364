import { faBookBookmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { globalStore } from "../../../state/store";
import theme from "../../../theme/theme";
import CanveoCircularProgress from "../../CanveoCircularProgress";
import PlaybookDrawerEditor from "./components/PlaybookDrawerEditor";

export default function PlaybookDrawer() {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  // const [playbooks, setPlaybooks] = useState(/** @type {*[]} */ ([]));
  const [currentPlaybook, setCurrentPlaybook] = useState(
    /** @type {* | undefined} */ (undefined)
  );
  const [selectedClauseTypes, setSelectedClauseTypes] = useState(
    /** @type {string[]} */ ([])
  );

  useEffect(
    () => {
      loadPlaybooks();
    },
    // Runs only once, on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  async function loadPlaybooks() {
    try {
      setIsLoading(true);
      const getPlaybooksResponse = await axios.get(
        `${state.settings.api}playbook`
      );
      const playbooks = getPlaybooksResponse.data.data;
      // setPlaybooks(playbooks);
      if (playbooks.length) {
        setCurrentPlaybook(playbooks[0]);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "Error getting playbooks",
          severity: "error",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  // function getCurrentPlaybookIndex() {
  //   const currentPlaybookIndex = playbooks.findIndex(
  //     (playbook) => playbook._id === currentPlaybook._id
  //   );
  //   return currentPlaybookIndex;
  // }

  // function getCurrentPlaybookPosition() {
  //   const currentPlaybookPosition = getCurrentPlaybookIndex() + 1;
  //   return currentPlaybookPosition;
  // }

  // function canNext() {
  //   const currentPlaybookIndex = getCurrentPlaybookIndex();
  //   const canNext = currentPlaybookIndex + 1 < playbooks.length;
  //   return canNext;
  // }

  // function nextPlaybook() {
  //   const currentPlaybookIndex = getCurrentPlaybookIndex();
  //   if (!canNext()) return;

  //   setCurrentPlaybook(playbooks[currentPlaybookIndex + 1]);
  // }

  // function canPrevious() {
  //   const currentPlaybookIndex = getCurrentPlaybookIndex();
  //   const canPrevious = currentPlaybookIndex > 0;
  //   return canPrevious;
  // }

  // function previousPlaybook() {
  //   const currentPlaybookIndex = getCurrentPlaybookIndex();
  //   if (!canPrevious()) return;

  //   setCurrentPlaybook(playbooks[currentPlaybookIndex - 1]);
  // }

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        p={2}
        sx={{
          position: "relative",
          backgroundColor: "white",
        }}
      >
        <Grid item>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "14px",
            }}
          >
            <FontAwesomeIcon
              icon={faBookBookmark}
              color={theme.palette.primary.main}
              size="lg"
            />
            &nbsp;&nbsp;&nbsp;Playbook
          </Typography>
        </Grid>
      </Grid>

      <Box id="playbook-drawer-container">
        <Box sx={{ px: 2 }}>
          {isLoading ? (
            <Grid container justifyContent="center">
              <CanveoCircularProgress />
            </Grid>
          ) : (
            <>
              {currentPlaybook && (
                <>
                  <Typography variant="caption">Selected Playbook</Typography>
                  <Typography variant="subtitle1">
                    {currentPlaybook.name}
                  </Typography>
                  <Typography
                    mt={1}
                    color={theme.palette.primary.main}
                    fontWeight="600"
                    onClick={() =>
                      navigate(`/templates/playbooks/${currentPlaybook._id}`)
                    }
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                      fontSize: "14px",
                    }}
                  >
                    Switch playbook ...
                  </Typography>

                  {/* <Grid container mt={2}>
                    <Autocomplete
                      fullWidth
                      readOnly
                      multiple
                      options={state.clauseTypes}
                      getOptionLabel={(option) => option.name}
                      value={state.clauseTypes.filter((clauseType) =>
                        selectedClauseTypes.includes(clauseType._id)
                      )}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Clause Topics"
                          placeholder="Select ..."
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            label={
                              <span
                                style={{
                                  fontWeight: "600",
                                  fontSize: "14px",
                                  padding: "5px 4px 5px 5px",
                                }}
                              >
                                {option.name}
                              </span>
                            }
                            color="primary"
                          />
                        ))
                      }
                    />
                  </Grid> */}

                  {/* <Grid
                    mt={4}
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={7}>
                      {currentPlaybook.labels.map((label) => (
                        <Chip
                          key={label._id}
                          color="primary"
                          size="small"
                          style={{
                            padding: "4px",
                            marginRight: "5px",
                          }}
                          label={
                            <span style={{ fontWeight: "600" }}>
                              {label.name}
                            </span>
                          }
                        />
                      ))}
                    </Grid>

                    <Grid item xs={1}>
                      {getCurrentPlaybookPosition()}/{playbooks.length}
                    </Grid>

                    <Grid item xs={1}>
                      <IconButton
                        size="small"
                        onClick={previousPlaybook}
                        disabled={!canPrevious()}
                      >
                        <FontAwesomeIcon
                          icon={faCircleChevronLeft}
                          color={
                            canPrevious() ? theme.palette.primary.main : "gray"
                          }
                        />
                      </IconButton>

                      <IconButton
                        size="small"
                        onClick={nextPlaybook}
                        disabled={!canNext()}
                      >
                        <FontAwesomeIcon
                          icon={faCircleChevronRight}
                          color={
                            canNext() ? theme.palette.primary.main : "gray"
                          }
                        />
                      </IconButton>
                    </Grid>

                    <Grid item xs={1} />
                  </Grid> */}

                  <Grid
                    id="playbook-drawer-editor-container"
                    container
                    mt={4}
                    sx={{ height: "680px", overflowY: "scroll" }}
                  >
                    <PlaybookDrawerEditor
                      key={currentPlaybook._id}
                      playbookId={currentPlaybook._id}
                      onSelectedClauseTypesChange={(clauseTypes) =>
                        setSelectedClauseTypes(clauseTypes)
                      }
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
