import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import theme from "../../theme/theme";

const agreementStatuses = [
  // { label: "Request", value: "Request" },
  { label: "Draft", value: "Draft" },
  { label: "Review", value: "Review" },
  { label: "Negotiation", value: "Negotiation" },
  { label: "Being Signed", value: "Execution" },
  { label: "In Effect", value: "InEffect" },
  // { label: "Terminated", value: "Terminated" },
  // { label: "Expired", value: "Expired" },
  // { label: "Deactive", value: "Deactive" },
  // { label: "Exhibit", value: "Exhibit" },
];

const hubSpotPriorities = [
  { label: "Low", value: "LOW" },
  { label: "Medium", value: "MEDIUM" },
  { label: "High", value: "HIGH" },
];

const defaultTicketName = "[TBC]";

/**
 * @typedef {object} HubSpotPipelineStage
 * @property {string} label
 * @property {number} displayOrder
 * @property {object} metadata
 * @property {string} metadata.ticketState
 * @property {string} metadata.isClosed
 * @property {string} id
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {boolean} archived
 */

/**
 * @typedef {object} HubSpotPipeline
 * @property {string} label
 * @property {number} displayOrder
 * @property {string} id
 * @property {HubSpotPipelineStage[]} stages
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {boolean} archived
 */

/**
 * @typedef {object} HubSpotOwner
 * @property {string} id
 * @property {string} email
 * @property {string} firstName
 * @property {string} lastName
 * @property {number} userId
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {boolean} archived
 */

/**
 * @typedef {object} Automation
 * @property {{ label: string; value: string; } | null} agreementStatus
 * @property {HubSpotPipelineStage | null} hubSpotTicketStatus
 */

/**
 * @typedef {typeof hubSpotPriorities[number]} Priority
 */

/**
 * @typedef {object} Ticket
 * @property {string} id
 * @property {string} name
 * @property {string} description
 * @property {HubSpotOwner} owner
 * @property {HubSpotPipeline} pipeline
 * @property {Priority} priority
 * @property {HubSpotPipelineStage} status
 * @property {Automation[]} automations
 * @property {string} updatedAt
 * @property {string} createdAt
 */

/**
 * @typedef {object} DialogCreateTicketProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {import("../../views/CreateAgreement").AgreementData} agreement
 * @property {(property: "tickets", value: *) => void} handleAgreementChange
 * @property {HubSpotOwner[]} owners
 * @property {HubSpotPipeline[]} pipelines
 * @property {{ _id: string; shortName: string }[]} organizations
 * @property {(ticket: Ticket) => void} addTicket
 * @property {Ticket} [existingTicket]
 */

/**
 * @param {DialogCreateTicketProps} props
 * @returns {React.JSX.Element}
 */
export default function DialogCreateTicket({
  open,
  close,
  agreement,
  addTicket,
  owners,
  pipelines,
  organizations,
  existingTicket,
}) {
  const [name, setName] = useState(existingTicket?.name || getTicketName());
  const [description, setDescription] = useState(
    existingTicket?.description || getDescription()
  );

  const [owner, setOwner] = useState(
    /** @type { HubSpotOwner | null } */ (existingTicket?.owner || null)
  );

  const [pipeline, setPipeline] = useState(
    /** @type { HubSpotPipeline | null } */ (existingTicket?.pipeline || null)
  );
  const [priorities] = useState(hubSpotPriorities);
  const [priority, setPriority] = useState(
    /** @type { Priority | null } */ (
      existingTicket?.priority || hubSpotPriorities[1]
    )
  );

  const [status, setStatus] = useState(
    /** @type { HubSpotPipelineStage | null } */ (
      existingTicket?.status || null
    )
  );
  const [automations, setAutomations] = useState(
    /** @type {Automation[]} */ (
      existingTicket?.automations || [
        {
          agreementStatus: null,
          hubSpotTicketStatus: null,
        },
      ]
    )
  );

  const [ticketStatuses, setTicketStatuses] = useState(
    /** @type {HubSpotPipelineStage[]} */ (
      existingTicket?.pipeline?.stages || []
    )
  );

  function getTicketName() {
    const [counterparty] = agreement?.parties?.filter(
      (party) => party?.partyID !== "party0"
    );
    if (!counterparty) return defaultTicketName;

    const counterpartyOrganization = organizations.find(
      (organization) => organization._id === counterparty.orgID
    );
    if (!counterpartyOrganization?.shortName) return defaultTicketName;

    const agreementType =
      agreement?.template?.agrType?.shortName ||
      agreement?.template?.agrTypeID?.shortName;
    if (!agreementType) return defaultTicketName;

    return `${agreementType} - ${counterpartyOrganization?.shortName}`;
  }

  function getDescription() {
    const [counterparty] = agreement?.parties?.filter(
      (party) => party?.partyID !== "party0"
    );
    if (!counterparty) return defaultTicketName;

    const counterpartyOrganization = organizations.find(
      (organization) => organization._id === counterparty.orgID
    );
    if (!counterpartyOrganization?.shortName) return defaultTicketName;

    const agreementType =
      agreement?.template?.agrType?.shortName ||
      agreement?.template?.agrTypeID?.shortName;
    if (!agreementType) return defaultTicketName;

    return `Please review this ${agreementType} for ${counterpartyOrganization?.shortName}.`;
  }

  function ticketIsValid() {
    if (
      !name ||
      !owner ||
      !pipeline ||
      !priority ||
      !status ||
      !automations.length
    ) {
      return false;
    }

    for (const automation of automations) {
      if (!automation.agreementStatus || !automation.hubSpotTicketStatus) {
        return false;
      }
    }

    return true;
  }

  async function submit() {
    if (
      !name ||
      !owner ||
      !pipeline ||
      !priority ||
      !status ||
      !automations.length
    ) {
      return false;
    }

    for (const automation of automations) {
      if (!automation.agreementStatus || !automation.hubSpotTicketStatus) {
        return false;
      }
    }

    const currentTimestamp = new Date().toISOString();

    const ticket = {
      name,
      description,
      owner,
      pipeline,
      priority,
      status,
      automations,
      updatedAt: currentTimestamp,
    };

    if (existingTicket) {
      addTicket({ ...existingTicket, ...ticket });
    } else {
      addTicket({ ...ticket, id: uuid(), createdAt: currentTimestamp });
    }

    close();
  }

  const dialogHeader = useMemo(
    () => (
      <>
        <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
          <IconButton onClick={close}>
            <FontAwesomeIcon
              icon={faTimes}
              style={{ padding: "4px 7px", fontSize: "20px" }}
            />
          </IconButton>
        </Box>

        <DialogTitle>Create New Task</DialogTitle>
      </>
    ),
    // Constant. This will never change during runtime.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Dialog
      open={open}
      onClose={close}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "700px",
          },
        },
      }}
    >
      {dialogHeader}

      <DialogContent>
        <Grid p={5}>
          <Grid>
            <TextField
              label="Name"
              placeholder="Enter name ..."
              fullWidth
              value={name}
              autoFocus
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </Grid>

          <br />

          <Grid>
            <TextField
              label="Description"
              placeholder="Enter description ..."
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </Grid>

          <br />

          <Grid>
            <Autocomplete
              disablePortal
              options={owners}
              value={owner}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Owner"
                  placeholder="Select owner ..."
                />
              )}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName} (${option.email})`
              }
              onChange={(_event, owner) => {
                setOwner(owner);
              }}
            />
          </Grid>

          <br />

          <Grid>
            <Autocomplete
              disablePortal
              options={pipelines}
              value={pipeline}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pipeline"
                  placeholder="Select pipeline ..."
                />
              )}
              getOptionLabel={(option) => option.label}
              onChange={(_event, pipeline) => {
                setPipeline(pipeline);
                if (pipeline) {
                  setTicketStatuses(pipeline.stages);
                  setStatus(pipeline.stages[0]);
                }
              }}
            />
          </Grid>

          <br />

          <Grid>
            <Autocomplete
              disablePortal
              value={priority}
              options={priorities}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Priority"
                  placeholder="Select priority ..."
                />
              )}
              getOptionLabel={(priority) => priority.label}
              onChange={(_, priority) => {
                setPriority(priority);
              }}
            />
          </Grid>

          {Boolean(pipeline) && (
            <>
              <br />

              <Grid>
                <Autocomplete
                  disablePortal
                  value={status}
                  options={ticketStatuses}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      placeholder="Select status ..."
                    />
                  )}
                  onChange={(_event, status) => {
                    setStatus(status);
                  }}
                />
              </Grid>

              <br />

              <Grid mt={4}>
                <Typography fontWeight="bold">Automations</Typography>

                {automations.map((automation, i) => {
                  return (
                    <Grid key={i} mb={8}>
                      <Grid container direction="row" alignItems="center">
                        <Grid item xs={7}>
                          When the agreement status changes to
                        </Grid>

                        <Grid item xs={5}>
                          <Autocomplete
                            disablePortal
                            options={agreementStatuses}
                            value={automation.agreementStatus}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Agreement Status"
                                placeholder="Select agreement status ..."
                              />
                            )}
                            onChange={(_event, value) => {
                              automation.agreementStatus = value;
                              setAutomations([...automations]);
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid sx={{ position: "relative" }}>
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: "-35px",
                            right: "-50px",
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              setAutomations((automations) => {
                                const result = automations.filter(
                                  (_automation, ii) => ii !== i
                                );
                                return result;
                              })
                            }
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              style={{ padding: "4px 7px", fontSize: "20px" }}
                              color={theme.palette.grey[500]}
                            />
                          </IconButton>
                        </Box>
                      </Grid>

                      <br />

                      <Grid container direction="row" alignItems="center">
                        <Grid item xs={7}>
                          update the status of the HubSpot ticket to
                        </Grid>
                        <Grid item xs={5}>
                          <Autocomplete
                            disablePortal
                            options={ticketStatuses}
                            value={automation.hubSpotTicketStatus}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Ticket Status"
                                placeholder="Select agreement status ..."
                              />
                            )}
                            onChange={(_event, value) => {
                              automation.hubSpotTicketStatus = value;
                              setAutomations([...automations]);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}

                {automations.length < 5 && (
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setAutomations((automations) => [
                        ...automations,
                        { agreementStatus: null, hubSpotTicketStatus: null },
                      ]);
                    }}
                    sx={{ fontWeight: "600" }}
                  >
                    Add another automation ...
                  </Button>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <>
          <Button sx={{ marginRight: "auto" }} onClick={close}>
            Cancel
          </Button>

          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={submit}
            disabled={!ticketIsValid()}
          >
            {existingTicket ? "Update" : "Submit"}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
}
