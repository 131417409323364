export function createUID() {
  // Uncomment the following line when uploading files for tests.
  // return "abcde";
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substring(0, 5);
}

/**
 * @param {string} content
 * @param {*} creator
 */
export function createComment(content, creator) {
  return {
    id: createUID(),
    creator: {
      uid: creator._id,
      email: creator.email,
      displayName: creator.displayName,
      photoURL: creator.photoURL,
      orgID: creator.orgID,
    },
    content: content.trim(),
    date: new Date().toISOString(),
    isEdited: false,
  };
}

/**
 * @param {string} wfType
 * @param {string} prefix
 * @param {string} quote
 * @param {string} content
 * @param {{ _id: any; email: any; displayName: any; photoURL: any; orgID: any; }} user
 * @param {any[] | null} assignee
 * @param {any[]} subscribers
 * @param {any} docID
 * @param {any} partyID
 * @param {any[] | undefined} [visibleTo]
 */
export function createWorkflow(
  wfType,
  prefix,
  quote,
  content,
  user,
  assignee,
  subscribers,
  docID,
  partyID,
  visibleTo
) {
  const creator = {
    uid: user._id,
    email: user.email,
    displayName: user.displayName,
    photoURL: user.photoURL,
  };

  // TODO: Is it okay to default to "party0"?
  const lid = prefix + createUID() + "_" + (partyID ?? "party0");

  const newWF = {
    wfType: wfType,
    orgID: user.orgID,
    lid: lid,
    docID: docID,
    comments: [createComment(content, user)],
    quote: quote,
    note: content.substring(0, 125),
    subscribers: subscribers,
    creator,
    assignee: assignee,
    wfStatus: "open",
    creationDate: new Date().toISOString(),
    visibleTo,
  };

  return newWF;
}
