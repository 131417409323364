import { TextNode } from "lexical";
import getColorForPartyID from "../../../utils/getColorForPartyID";

/**
 * @typedef {object} NodeMetadata
 *
 * @property {string} creatorId
 * @property {string} creatorEmail
 * @property {string} creatorDisplayName
 * @property {string} creatorPhotoUrl
 * @property {string} creationDate Date in the form of a UTC string.
 * @property {string} partyId
 *
 *
 * @typedef {object} RedlineMetadataBase
 * @property {string} [revisionId]
 *
 * @typedef {NodeMetadata & RedlineMetadataBase} RedlineMetadata
 */

export class RedlineNode extends TextNode {
  /** @private @type {RedlineType} The redline type. */
  __redlineType;

  /** @private @type {PartyId} The party ID. */
  __partyID;

  /** @private @type {RedlineMetadata} */
  __metadata;

  /** @deprecated Use `__metadata.creationDate` instead. @private @type {string} The creation date as a string in ISO format. */
  __date;

  static getType() {
    return "redline";
  }

  /**
   * @param {RedlineNode} node
   * @returns {RedlineNode}
   */
  static clone(node) {
    return new RedlineNode(
      node.__redlineType,
      node.__partyID,
      node.__metadata,
      node.__date,
      node.__text,
      node.__key
    );
  }

  /**
   * @param {RedlineType} redlineType
   * @param {PartyId} partyID
   * @param {RedlineMetadata} metadata
   * @param {string} date
   * @param {string} text
   * @param {string} [key]
   */
  constructor(redlineType, partyID, metadata, date, text, key) {
    super(text, key);
    this.__redlineType = redlineType;
    this.__partyID = partyID;
    this.__metadata = {
      ...metadata,
      // revisionId: metadata.revisionId || uuid(),
      // Uncomment when uploading files for tests.
      revisionId: "410787ef-25b2-4715-8e58-46f0c911a014",
      creatorId: "6492fd22484255d10e812824",
    };

    this.__date = date;
  }

  /**
   * @param {import("lexical").EditorConfig} config
   */
  createDOM(config) {
    const element = super.createDOM(config);

    element.id = this.getKey();

    const [firstParty, secondParty] = this.__partyID.split("_");

    const redlineType = this.__redlineType;

    switch (redlineType) {
      case "add":
        element.style.color = getColorForPartyID(firstParty);
        element.style.borderBottom =
          "1px dotted" + getColorForPartyID(firstParty);
        break;

      case "del":
        element.style.color = getColorForPartyID(firstParty);
        if (this.hasFormat("underline")) {
          element.classList.add("editor-text-underlineStrikethrough");
        } else {
          element.style.textDecoration = "line-through";
        }
        if (secondParty) {
          element.style.textDecorationColor = getColorForPartyID(secondParty);
        }

        break;

      case "xadd":
        if (secondParty) {
          element.style.color = getColorForPartyID(secondParty);
        }
        if (this.hasFormat("underline")) {
          element.classList.add("editor-text-underlineStrikethrough");
        } else {
          element.style.textDecoration = "line-through";
        }
        element.style.borderBottom =
          "1px dotted" + getColorForPartyID(firstParty);
        break;

      case "xdel":
        if (secondParty) {
          element.style.borderBottom =
            "1px dotted" + getColorForPartyID(secondParty);
          element.style.color = getColorForPartyID(secondParty);
        }
        break;

      default:
        throw new Error(`${redlineType} is not a valid redline type.`);
    }

    return element;
  }

  /**
   * @returns {import("lexical").DOMConversionMap | null}
   */
  importDOM() {
    return null;
  }

  updateDOM() {
    // Needed to update styles from the toolbar.
    return true;
  }

  getRedlineType() {
    const self = this.getLatest();
    return self.__redlineType;
  }

  /**
   * @param {RedlineType} redlineType
   */
  setRedlineType(redlineType) {
    const self = this.getWritable();
    self.__redlineType = redlineType;
  }

  getMetadata() {
    const self = this.getLatest();
    return self.__metadata;
  }

  /**
   * @param {NodeMetadata & RedlineMetadata} metadata
   */
  setMetadata(metadata) {
    const self = this.getWritable();
    self.__metadata = metadata;
  }

  getPartyID() {
    const self = this.getLatest();
    return self.__partyID;
  }

  /**
   * @param {PartyId} partyID
   */
  setPartyID(partyID) {
    const self = this.getWritable();
    self.__partyID = partyID;
  }

  getDate() {
    const self = this.getLatest();
    return self.__date;
  }

  /**
   * @param {string} date
   */
  setDate(date) {
    const self = this.getWritable();
    self.__date = date;
  }

  getTextContent() {
    const self = this.getLatest();
    return self.__text;
  }

  isInline() {
    return true;
  }
  /**
   * @param {SerializedRedlineNode} serializedNode
   *
   * @returns {RedlineNode}
   */
  static importJSON(serializedNode) {
    const node = $createRedlineNode(serializedNode);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  /**
   * @returns {SerializedRedlineNode}
   */
  exportJSON() {
    return {
      ...super.exportJSON(),
      type: "redline",
      redlineType: this.getRedlineType(),
      partyID: this.getPartyID(),
      metadata: {
        ...this.getMetadata(),
      },
      date: this.getDate(),
      version: 1,
    };
  }

  /**
   * @param {import("lexical").LexicalEditor} editor
   * @returns {import("lexical").DOMExportOutput}
   */
  exportDOM(editor) {
    const domExportOutput = super.exportDOM(editor);
    if (domExportOutput.element) {
      domExportOutput.element.setAttribute("type", RedlineNode.getType());
      domExportOutput.element.setAttribute(
        "redlineType",
        this.getRedlineType()
      );
    }

    return domExportOutput;
  }

  /**
   * @param  {number[]} splitOffsets
   *
   * @return {RedlineNode[]}
   */
  splitText(...splitOffsets) {
    const splitNodes = super.splitText(...splitOffsets);

    const splitRedlineNodes = splitNodes.map((node) => {
      if (!$isRedlineNode(node)) {
        const redlineNode = $createRedlineNode({
          // When we create a node from another one we want to keep its existing properties.
          ...this.exportJSON(),
          text: node.getTextContent(),
        });
        redlineNode.setFormat(node.getFormat());
        redlineNode.setStyle(node.getStyle());
        node.replace(redlineNode);
        return redlineNode;
      } else {
        return node;
      }
    });

    return splitRedlineNodes;
  }
}

/**
 * @param {{redlineType: RedlineType, partyID: PartyId, metadata: RedlineMetadata, date: string, text: string}} _
 *
 * @returns {RedlineNode}
 */
export function $createRedlineNode({
  redlineType,
  partyID,
  metadata,
  date,
  text,
}) {
  return new RedlineNode(redlineType, partyID, metadata, date, text);
}

/**
 * @param {*} node
 *
 * @returns {node is RedlineNode}
 */
export function $isRedlineNode(node) {
  return node instanceof RedlineNode;
}
