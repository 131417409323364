import React from "react";
import { Grid, Typography } from "@mui/material";

function RemovePartyConfirmation({ legalName, isOwnOrganization }) {
  return (
    <Grid item sx={{ textAlign: "center" }}>
      {!isOwnOrganization ? (
        <Typography sx={{ fontWeight: "600", mb: 3 }} align="center">
          Are you sure you want to remove {legalName} and all of their
          collaborators from this agreement?
        </Typography>
      ) : (
        <Typography sx={{ fontWeight: "600", mb: 3 }} align="center">
          Are you sure you want to remove your own entity {legalName} as a party
          to this agreement?
        </Typography>
      )}
    </Grid>
  );
}

export default RemovePartyConfirmation;
