import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { forwardRef, useImperativeHandle } from "react";

/**
 * @param {*} key
 * @param {*} value
 * @returns {*}
 */
function replacer(key, value) {
  if (value && typeof value === "object" && !Array.isArray(value)) {
    const keys = Object.keys(value);
    const hasType = keys.includes("type");
    const hasChildren = keys.includes("children");

    // Create a new object with the desired order of properties.
    /** @type {*} */
    const orderedObj = {};

    if (hasType) {
      orderedObj.type = value.type;
    }

    // Add all other properties except 'type' and 'children'
    keys.forEach((k) => {
      if (k !== "type" && k !== "children") {
        orderedObj[k] = value[k];
      }
    });

    if (hasChildren) {
      orderedObj.children = value.children;
    }

    return orderedObj;
  }
  return value;
}

const CopyLexicalContentsToClipboardPlugin = forwardRef((_, ref) => {
  const [editor] = useLexicalComposerContext();

  useImperativeHandle(ref, () => ({
    async copyJson() {
      const editorJsonString = JSON.stringify(
        editor.getEditorState().toJSON(),
        replacer
      );

      await navigator.clipboard.writeText(editorJsonString).catch((error) => {
        throw new Error(
          `There was an error copying the current editor JSON into the clipboard: ${error}`
        );
      });
    },
    async copyHtml() {
      const htmlString = editor.getEditorState().read(() => {
        return $generateHtmlFromNodes(editor);
      });

      await navigator.clipboard.writeText(htmlString).catch((error) => {
        throw new Error(
          `There was an error copying the current editor HTML into the clipboard: ${error}`
        );
      });
    },
    importHtml(/** @type {string} */ htmlString) {
      editor.update(() => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(htmlString, "text/html");

        $generateNodesFromDOM(editor, dom);
      });
    },
  }));

  return null;
});

export default CopyLexicalContentsToClipboardPlugin;
