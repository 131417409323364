import { faBolt, faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $dfs, $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  $getSelection,
  COMMAND_PRIORITY_CRITICAL,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { DialogParam } from "../../..";
import { globalStore } from "../../../../state/store";
import theme from "../../../../theme/theme";
import { getCanveoTier } from "../../../../utils/getCanveoTier";
import DialogClause from "../../../DialogClause";
import DialogNewClauseVariant from "../../../dialogs/DialogNewClauseVariant";
import { ClauseNode } from "../../nodes";
import { $isClauseNode } from "../../nodes/ClauseNode";
import setFloatingElementPosition from "../../utils/setFloatingElementPosition";
import { PROPAGATE_EVENT } from "../TrackChangesPlugin/utils";
import useClauseOptions from "./useClauseOptions";

/**
 * @param {*} props
 * @returns
 */
const ClauseOptionsPlugin = ({ isTemplating, partyID, docID, isInEffect }) => {
  const [state, dispatch] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();
  const [displayIcon, setDisplayIcon] = useState(true);
  const [clause, setClause] = useState(null);
  const contextMenuRef = useRef(null);
  const {
    dialogClauseOpen,
    clauseOptions,
    anchorElClauseOptions,
    dialogParamOpen,
    handleOpenClauseOptionsMenu,
    handleCloseClauseOptionsMenu,
    handleClauseOptionsMenuOption,
    closeClauseDialog,
    closeParamDialog,
    openDialogNewClauseVariant,
    setOpenDialogNewClauseVariant,
    partialClause,
  } = useClauseOptions(editor, clause?.key);

  const updateFloatingMenu = useCallback(
    () => {
      if (editor && clause) {
        const selection = $getSelection();
        const contextMenuElem = contextMenuRef.current;
        const nativeSelection = window.getSelection();

        if (contextMenuElem === null) return;

        const rootElement = editor.getRootElement();
        if (
          selection !== null &&
          nativeSelection !== null &&
          rootElement !== null &&
          rootElement.contains(nativeSelection.anchorNode)
        ) {
          const element = editor.getElementByKey(clause.key);
          if (element) {
            const rangeRect = element.getBoundingClientRect();
            setFloatingElementPosition(
              rangeRect,
              contextMenuElem,
              document.querySelector(".editor-container"),
              !!anchorElClauseOptions ? -65 : -20,
              40
            );
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, clause]
  );

  const mouseMoveListener = (e) => {
    if (contextMenuRef?.current && (e.buttons === 1 || e.buttons === 3)) {
      contextMenuRef.current.style.pointerEvents = "none";
    }
  };

  const mouseUpListener = () => {
    if (contextMenuRef?.current) {
      contextMenuRef.current.style.pointerEvents = "auto";
    }
  };

  useEffect(() => {
    if (contextMenuRef?.current) {
      document.addEventListener("mousemove", mouseMoveListener);
      document.addEventListener("mouseup", mouseUpListener);

      return () => {
        document.removeEventListener("mousemove", mouseMoveListener);
        document.removeEventListener("mouseup", mouseUpListener);
      };
    }
  }, [contextMenuRef]);

  useEffect(() => {
    if (editor && clause) {
      const element = editor.getElementByKey(clause.key);
      const scrollerElem = element.parentElement;

      const updateMenu = () => {
        editor.getEditorState().read(() => {
          updateFloatingMenu();
        });
      };

      window.addEventListener("resize", updateMenu);
      if (scrollerElem) {
        scrollerElem.addEventListener("scroll", updateMenu);
      }

      return () => {
        window.removeEventListener("resize", updateMenu);
        if (scrollerElem) {
          scrollerElem.removeEventListener("scroll", updateMenu);
        }
      };
    }
  }, [editor, updateFloatingMenu, clause]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateFloatingMenu();
    });

    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateFloatingMenu();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          const selection = $getSelection();
          const node = selection.anchor.getNode();
          const clause = $getNearestNodeOfType(node, ClauseNode);
          if (clause) {
            setClause({ key: clause.getKey(), ...clause.exportJSON() });
            setDisplayIcon(true);
          } else {
            setClause(null);
            setDisplayIcon(false);
          }
          updateFloatingMenu();
          return PROPAGATE_EVENT;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [editor, updateFloatingMenu, dispatch]);

  return (
    <>
      {getCanveoTier(state?.user?.email) === "experimental" &&
        displayIcon &&
        clause &&
        state.user.role.name !== "Counterparty" &&
        state.drawerVersions?.active?._id ===
          state.drawerVersions.versions[0]?._id && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              willChange: "transform",
              // We need this so that the clause options icone shows up on top of the
              // drawer, otherwise it's not clickable.
              zIndex: 501,
            }}
            ref={contextMenuRef}
          >
            <Tooltip title="Clause Options">
              <IconButton
                color="primary"
                onClick={handleOpenClauseOptionsMenu}
                sx={{ maxWidth: "30px", maxHeight: "30px", p: 0 }}
              >
                <Box display="flex">
                  <FontAwesomeIcon
                    icon={faBolt}
                    size="xs"
                    width={15}
                    height={15}
                    style={{
                      marginTop: 2,
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    width={10}
                    height={10}
                    style={{
                      marginBottom: 0,
                      marginTop: "auto",
                      marginLeft: 3,
                    }}
                  />
                </Box>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorElClauseOptions}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              disableScrollLock={true}
              open={!!anchorElClauseOptions}
              onClose={handleCloseClauseOptionsMenu}
            >
              {clauseOptions(isInEffect)
                .filter((co) => {
                  if (co.id === "assignclausetype") {
                    return (
                      !clause.clauseTypes || clause.clauseTypes.length === 0
                    );
                  }
                  return co;
                })
                .map((m, _i) => (
                  <MenuItem
                    dense
                    key={m.id}
                    onClick={() =>
                      handleClauseOptionsMenuOption(
                        m.id,
                        m.id === "saveLibrary" ? "default" : "guidance"
                      )
                    }
                    disabled={m.disabled}
                  >
                    <ListItemIcon>
                      {m.icon ? (
                        <FontAwesomeIcon
                          icon={m.icon}
                          color={
                            m.disabled
                              ? theme.palette.grey[500]
                              : theme.palette.primary.main
                          }
                        />
                      ) : (
                        ""
                      )}
                    </ListItemIcon>
                    <ListItemText>{m.name}</ListItemText>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
        )}

      <DialogClause
        open={!!dialogClauseOpen}
        details={dialogClauseOpen}
        closeDialog={closeClauseDialog}
      />

      {dialogParamOpen && (
        <DialogParam
          open={!!dialogParamOpen}
          details={dialogParamOpen}
          closeDialog={closeParamDialog}
        />
      )}

      {openDialogNewClauseVariant && (
        <DialogNewClauseVariant
          documentId={docID}
          open={openDialogNewClauseVariant}
          close={() => setOpenDialogNewClauseVariant(false)}
          clauseText={partialClause.text}
          submit={(clauseVariant) => {
            setOpenDialogNewClauseVariant(false);
            editor.update(() => {
              for (const { node } of $dfs()) {
                if ($isClauseNode(node) && node.id === partialClause.id) {
                  // If this is the first time adding a variant we need
                  // to add the existing version as the default variant.
                  if (!node.getVariants().length) {
                    node.addVariant({
                      type: "alternativeClause",
                      text: partialClause.text,
                      name: "(Default)",
                      description: "The default version of this clause.",
                      application: {
                        type: "manual",
                        conditions: [],
                      },
                      topicsIds: [],
                      visibility: "visible",
                    });
                  }
                  node.addVariant(clauseVariant);
                }
              }
            });
          }}
        />
      )}

      {/* {!!creatingInternalWorkflow && (
        <div
          style={{
            position: "fixed",
            right: "20px",
            top: "75px",
            width: 250,
            zIndex: 1001,
            display: "flex",
            justifyContent: "end",
          }}
        >
          (
          <ContextItem
            type={creatingInternalWorkflow}
            expandItem={expandItem}
            isExpanded={true}
            activeClauseKey={activeClauseKey}
            keyAction={(e) => openParamDialog(activeClauseKey)}
            isTemplating={isTemplating}
            partyID={partyID}
            docID={docID}
          />
          )
        </div>
      )} */}
    </>
  );
};

export default ClauseOptionsPlugin;
