import {
  faArrowsRotate,
  faCalendarCheck,
  faCalendarXmark,
  faClockRotateLeft,
  faEnvelope,
  faHourglass1,
  faHourglass3,
  faUserPolice,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Chip, Grid, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React, { useContext, useState } from "react";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { getCanveoTier } from "../utils/getCanveoTier";
import DialogAddNewQuestionToQuestionnaire from "./dialogs/DialogAddNewQuestionToQuestionnaire";
import DialogDatesAndRenewal from "./dialogs/DialogDatesAndRenewal";

/**
 * @typedef {object} DatesAndRenewalProps
 * @property {string} agreementId
 * @property {boolean} openDatesAndRenewalDialog
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setOpenDatesAndRenewalDialog
 * @property {*} document
 * @property {*[]} parameters
 * @property {boolean} documentIsTemplate
 * @property {string} partyId
 */

/**
 * @param {DatesAndRenewalProps} props
 * @returns {React.JSX.Element}
 */
export default function DatesAndRenewal({
  agreementId,
  openDatesAndRenewalDialog,
  setOpenDatesAndRenewalDialog,
  document,
  parameters,
  documentIsTemplate,
  partyId,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const agreement = state.agrs.find(
    (/** @type {{ _id: string; }} */ agreement) => agreement._id === agreementId
  );
  if (!agreement) throw new Error("Agreement does not exist.");
  const datesAndRenewal = agreement.datesAndRenewal || {};

  const /** @type {string} */ organizationName = state.org.shortName;
  const /** @type {string} */ organizationId = state.org._id;

  const [selectedMergeField, setSelectedMergeField] = useState(
    /** @type {MergeField | null} */ (null)
  );

  const {
    signedDate,
    startDateIsDifferentFromSignedDate,
    startDate,
    termDurationValue,
    termDurationUnit,
    renewalMode,
    expiryDate,
    noticePeriodDurationValue,
    noticePeriodDurationUnit,
    renewalTermDurationValue,
    noticeDate,
    renewalTermDurationUnit,
    assignee,
  } = datesAndRenewal;

  function getStartDate() {
    if (startDateIsDifferentFromSignedDate && startDate) {
      return DateTime.fromISO(startDate).toFormat("dd LLLL yyyy");
    }

    if (!startDateIsDifferentFromSignedDate && signedDate) {
      return DateTime.fromISO(signedDate).toFormat("dd LLLL yyyy");
    }

    return "[not set yet]";
  }

  /**
   * Returns the proper duration string taking into account
   * the plural and singular forms of the duration units when
   * the value is one e.g., 1 Year and 2 Years.
   *
   * @param {string} value
   * @param {{ label: string; }} unit
   */
  function getDurationString(value, unit) {
    if (value === "1") {
      return `${value} ${unit.label.slice(0, -1)}`;
    }

    return `${value} ${unit.label}`;
  }

  return (
    <>
      <Grid container mt={2}>
        <Typography fontWeight="bold" color={theme.palette.grey[700]}>
          Dates
        </Typography>
      </Grid>
      <Grid container direction="column" spacing={1} sx={{ mt: 1, pl: 1 }}>
        <Grid item>
          <Grid container direction={"row"} alignItems={"center"} spacing={1}>
            <Grid item>
              <FontAwesomeIcon
                icon={faCalendarCheck}
                color={theme.palette.grey[700]}
              />
            </Grid>

            <Grid item>
              <Typography variant="body2">
                Signed Date:{" "}
                {signedDate
                  ? DateTime.fromISO(signedDate).toFormat("dd LLLL yyyy")
                  : "[not set yet]"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction={"row"} alignItems={"center"} spacing={1}>
            <Grid item>
              <FontAwesomeIcon
                icon={faCalendarCheck}
                color={theme.palette.grey[700]}
              />
            </Grid>

            <Grid item>
              <Typography variant="body2">
                Start Date: {getStartDate()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction={"row"} alignItems={"center"} spacing={1}>
            <Grid item>
              <FontAwesomeIcon
                icon={faHourglass1}
                color={theme.palette.grey[700]}
              />
            </Grid>

            <Grid item>
              <Typography variant="body2">
                Initial Term:{" "}
                {termDurationValue && termDurationUnit
                  ? getDurationString(termDurationValue, termDurationUnit)
                  : "[not set yet]"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction={"row"} alignItems={"center"} spacing={1}>
            <Grid item>
              <FontAwesomeIcon
                icon={faCalendarXmark}
                color={theme.palette.grey[700]}
              />
            </Grid>

            <Grid item>
              <Typography variant="body2">
                Expiry Date:{" "}
                {expiryDate
                  ? DateTime.fromISO(expiryDate).toFormat("dd LLLL yyyy")
                  : "[not set yet]"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction={"row"} alignItems={"center"} spacing={1}>
            <Grid item>
              <FontAwesomeIcon
                icon={faArrowsRotate}
                color={theme.palette.grey[700]}
              />
            </Grid>

            <Grid item>
              <Typography variant="body2">
                Renewal Mode: {renewalMode?.label || "[not set yet]"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction={"row"} alignItems={"center"} spacing={1}>
            <Grid item>
              <FontAwesomeIcon
                icon={faHourglass3}
                color={theme.palette.grey[700]}
              />
            </Grid>

            <Grid item>
              <Typography variant="body2">
                Renewal Term:{" "}
                {renewalTermDurationValue && renewalTermDurationUnit
                  ? getDurationString(
                      renewalTermDurationValue,
                      renewalTermDurationUnit
                    )
                  : "[not set yet]"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction={"row"} alignItems={"center"} spacing={1}>
            <Grid item>
              <FontAwesomeIcon
                icon={faClockRotateLeft}
                color={theme.palette.grey[700]}
              />
            </Grid>

            <Grid item>
              <Typography variant="body2">
                Notice Period:{" "}
                {noticePeriodDurationValue && noticePeriodDurationUnit
                  ? getDurationString(
                      noticePeriodDurationValue,
                      noticePeriodDurationUnit
                    )
                  : "[not set yet]"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction={"row"} alignItems={"center"} spacing={1}>
            <Grid item>
              <FontAwesomeIcon
                icon={faEnvelope}
                color={theme.palette.grey[700]}
              />
            </Grid>

            <Grid item>
              <Typography variant="body2">
                Notice Date:{" "}
                {noticeDate
                  ? DateTime.fromISO(noticeDate).toFormat("dd LLLL yyyy")
                  : "[not set yet]"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction={"row"} alignItems={"center"} spacing={1}>
            <Grid item>
              <FontAwesomeIcon
                icon={faUserPolice}
                color={theme.palette.grey[700]}
              />
            </Grid>

            <Grid item>
              <Typography variant="body2">
                Renewal Task Assignee: {assignee?.name || "[not set yet]"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {getCanveoTier(state?.user?.email) === "experimental" && (
        <>
          <Grid container mt={2} mb={1}>
            <Typography fontWeight="bold" color={theme.palette.grey[700]}>
              Labels
            </Typography>
          </Grid>

          <Grid container sx={{ overflow: "auto" }}>
            {document?.labels?.map((/** @type {*} */ label) => (
              <Grid item container key={label.labelID._id}>
                <Grid item>
                  <Chip
                    label={label.labelID.name}
                    color="primary"
                    sx={{
                      fontWeight: "700",
                      my: 1,
                      px: 2,
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid container mt={2} mb={1}>
            <Typography fontWeight="bold" color={theme.palette.grey[700]}>
              Properties
            </Typography>
          </Grid>

          <Grid container sx={{ overflow: "auto" }}>
            {parameters
              ?.filter((p) => p.scope === "questionnaire")
              ?.map((/** @type {*} */ param) => (
                <Grid item container key={param._id}>
                  <Grid item>
                    <Chip
                      label={`${param.name}: ${param.displayValue}`}
                      color="secondary"
                      sx={{
                        fontWeight: "700",
                        m: 1,
                        px: 2,
                        maxWidth: "350px",
                      }}
                      onClick={() => setSelectedMergeField(param)}
                    />
                  </Grid>
                </Grid>
              ))}
          </Grid>

          <Grid container mt={2} mb={1}>
            <Typography fontWeight="bold" color={theme.palette.grey[700]}>
              Merge Field Properties
            </Typography>
          </Grid>

          <Grid container sx={{ overflow: "auto" }}>
            {parameters
              ?.filter((p) => p.scope === "document")
              ?.map((/** @type {*} */ param) => (
                <Grid item container key={param._id}>
                  <Grid item>
                    <Chip
                      label={`${param.name}: ${param.displayValue}`}
                      color="secondary"
                      sx={{
                        fontWeight: "700",
                        m: 1,
                        px: 2,
                        maxWidth: "350px",
                      }}
                      onClick={() => setSelectedMergeField(param)}
                    />
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </>
      )}

      {Boolean(selectedMergeField) && (
        <DialogAddNewQuestionToQuestionnaire
          open={Boolean(selectedMergeField)}
          close={() => {
            setSelectedMergeField(null);
            // loadDocumentMergeFields();
          }}
          mergeField={selectedMergeField || undefined}
          submit={() => {}}
          docId={agreementId}
          agrvId={state.drawerVersions.active?._id}
          organizationId={organizationId}
          organizationName={organizationName}
          documentIsTemplate={documentIsTemplate}
          partyId={partyId}
        />
      )}

      <Grid item>
        <Button
          variant="text"
          color="primary"
          size="small"
          onClick={() => setOpenDatesAndRenewalDialog(true)}
          sx={{
            fontWeight: "600",
            mt: 1,
          }}
        >
          Edit ...
        </Button>

        {openDatesAndRenewalDialog && (
          <DialogDatesAndRenewal
            open={openDatesAndRenewalDialog}
            close={() => setOpenDatesAndRenewalDialog(false)}
            agreementId={agreementId}
            document={document}
            parameters={parameters}
          />
        )}
      </Grid>
    </>
  );
}
