import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import theme from "../../theme/theme";
import { trunc } from "../../utils";

/**
 *
 * @param {{item: import("../editor/nodes/MarkNode").MergeField}} _
 * @returns
 */
const RenderBox = ({ item }) => {
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const styles = {
    boxStyle: {
      width: "200px",
      "&:hover": {
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 24px 0px",
        cursor: "pointer",
      }, //props.thumbClick !== null ? : {},
      padding: "2px 0px 2px 0px",
      border: "1px solid" + theme.palette.grey[300],
      borderRadius: "20px",
      textAlign: "center",
      backgroundColor: theme.palette.primary.contrastText,
      my: 1,
    },
  };

  return (
    <Tooltip title={item.wizardQuestion} placement="right">
      <Box key={item._id} sx={styles.boxStyle}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ height: "46px" }}
        >
          <Grid item xs={8}>
            <Typography
              align="center"
              style={{
                fontWeight: "700",
                lineHeight: "15px",
                fontSize: smUp ? "14px" : "12px",
              }}
            >
              {trunc(item.name, 20)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Tooltip>
  );
};

/**
 * @param {{id: string; index: number; item: import("../editor/nodes/MarkNode").MergeField}} _
 */
function Task({ id, index, item }) {
  return (
    <Draggable draggableId={id} key={id} index={index}>
      {(provided) => (
        // @ts-ignore
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          align="center"
        >
          <RenderBox item={item} />
        </Box>
      )}
    </Draggable>
  );
}

/**
 * @param {{id: string; mergeFields: import("../editor/nodes/MarkNode").MergeField[];}} _
 */
function Column({ id, mergeFields }) {
  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {mergeFields.map((mf, index) => {
            return <Task id={mf._id} key={index} index={index} item={mf} />;
          })}

          <>{provided.placeholder}</>
        </div>
      )}
    </Droppable>
  );
}

/**
 * @typedef {object} DialogChangeQuestionOrderProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {(mergeFields: import("../editor/nodes/MarkNode").MergeField[]) => void} submit
 * @property {import("../editor/nodes/MarkNode").MergeField[]} mergeFields
 */

/**
 * @param {DialogChangeQuestionOrderProps} props
 */
export default function DialogChangeQuestionOrder({
  open,
  close,
  submit,
  mergeFields,
}) {
  const [orderedMergeFields, setOrderedMergeFields] = useState(mergeFields);

  const handleClose = () => {
    close();
  };

  const handleSubmit = () => {
    submit(orderedMergeFields);
    handleClose();
  };

  /**
   * @param {*} list
   * @param {*} startIndex
   * @param {*} endIndex
   */
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
   * @param {*} result
   */
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedExhibits = reorder(
      orderedMergeFields,
      result.source.index,
      result.destination.index
    );

    setOrderedMergeFields([...reorderedExhibits]);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>
      <DialogTitle>Change Question Order</DialogTitle>
      <DialogContent sx={{ my: 3 }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Column id="1" mergeFields={orderedMergeFields} />
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ marginRight: "auto" }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" disableElevation>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
