const clauseTypeGuidesReducer = (state, action) => {
  switch (action.type) {
    case "INIT_CLAUSETYPEGUIDES":
      return { clauseTypeGuides: action.payload };
    case "ADD_CLAUSETYPEGUIDE":
      return {
        clauseTypeGuides: [...state.clauseTypeGuides, action.payload],
      };
    case "UPDATE_CLAUSETYPEGUIDE": {
      let newVal = action.payload;
      let newClauseTypeGuides = state.clauseTypeGuides;
      let idx = state.clauseTypeGuides.findIndex(
        (item) => item._id === newVal._id
      );
      if (idx > -1) {
        newClauseTypeGuides[idx] = newVal;
      }
      return { clauseTypeGuides: newClauseTypeGuides };
    }
    default:
      return { clauseTypeGuides: state.clauseTypeGuides };
  }
};

export default clauseTypeGuidesReducer;
