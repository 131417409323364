import { createTicket } from "../utils/createTicket";

const { createContext } = require("react");

export const NewTicketContext = createContext({
  ticket: createTicket(),
  setTicket:
    /** @type {React.Dispatch<React.SetStateAction<ReturnType<typeof createTicket>>>} */ (
      ticket
    ) => {},
});
