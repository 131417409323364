import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Collapse,
  Grid,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  alpha,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";
import React, { useContext, useEffect, useMemo, useState } from "react";
import defaultLogo from "../../assets/img/defaultlogo.png";
import useFileService from "../../hooks/useFileService";
import useVersionService from "../../hooks/useVersionService";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import { getCanveoTier } from "../../utils/getCanveoTier";
import mapOrigin from "../../utils/mapOrigin";
import EditableField from "../EditableField";
import DialogConfirmDelete from "../dialogs/DialogConfirmDelete";
import DialogDuplicateVersion from "../dialogs/DialogDuplicateVersion";
import DialogUploadVersion from "../dialogs/DialogUploadVersion";
import { CanveoCircularProgress } from "../index";

dayjs.extend(relativeTime);

const VersionBox = styled(Box, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "selected" && prop !== "isReadOnly",
  // @ts-ignore
})(({ theme, selected, isReadOnly }) => ({
  position: "relative",
  cursor: !selected ? "pointer" : "",
  borderRadius: "15px",
  backgroundColor:
    selected && !isReadOnly
      ? alpha(theme.palette.primary.main, 0.15)
      : selected && isReadOnly
      ? theme.palette.grey[100]
      : "",

  whiteSpace: "initial",

  "&:hover": {
    backgroundColor: !selected ? theme.palette.grey[200] : "",
  },

  "&:first-of-type": {
    marginTop: 0,
  },

  "&:last-child": {
    marginBottom: 0,
  },
}));

const dateTimeFormat = "D MMM YYYY, h:mm A";
const dateFormat = "D MMM YYYY";

/**
 * @typedef {*} VersionDrawerItemProps
 */

/**
 * @param {VersionDrawerItemProps} props
 * @returns {React.JSX.Element}
 */
export default function VersionDrawerItem({
  item,
  isTemplate,
  isInEffect,
  handleExport,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [menuAnchor, setMenuAnchor] = useState(/** @type {* | null} */ (null));
  const [editVersionName, setEditVersionName] = useState({
    editing: false,
    value: null,
  });
  const [editDescription, setEditDescription] = useState({
    editing: false,
    value: null,
  });
  const [duplicate, setDuplicate] = useState(false);
  const [deletingVersion, setDeletingVersion] = useState(false);
  const [filteredMenuItems, setFilteredMenuItems] = useState(
    /** @type {*[]} */ ([])
  );
  const { loading, updateVersion, deleteVersion } = useVersionService();
  const [belongsToOrg, setBelongsToOrg] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [editMode, setEditMode] = useState("");
  const [uploadVersion, setUploadVersion] = useState(false);
  const [deleteSignedDoc, setDeleteSignedDoc] = useState(false);
  const [isMainBody, setIsMainBody] = useState(false);

  const { downloadFile } = useFileService();

  const {
    _id,
    version,
    origin,
    lastUpdateBy,
    lastUpdateDate,
    creationBy,
    creationDate,
    description,
    versionName,
    receiverOrg,
    signedVersion,
    versionType,
    embeddedFileId,
  } = item;

  const active = useMemo(
    () => item._id === state.drawerVersions.active._id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.drawerVersions.active]
  );

  const mainAg = state.agrs.find(
    (/** @type {{ parentID: string; }} */ a) => !a.parentID
  );
  const isAgrExec = Boolean(state.agrExec) && Boolean(state.agrExec._id);
  const /** @type {boolean} */ isCurrentVersionOwner =
      !isAgrExec &&
      (isTemplate ||
        (!isTemplate &&
          Boolean(mainAg) &&
          mainAg.avOwners.some(
            (/** @type {string} */ owner) => owner === state.org._id
          )));

  const notVersionOwner =
    // isAgreementOwner &&
    !isCurrentVersionOwner &&
    !isAgrExec &&
    !["Execution", "InEffect"].includes(mainAg?.agrStatus);

  useEffect(() => {
    if (isTemplate) return;
    const editMode = item.owner.find(
      (/** @type {{ orgID: string; }} */ o) => o.orgID === state.org._id
    )?.editMode;

    const agreement = state.agrs.find(
      (/** @type {{ _id: string; }} */ agr) => agr._id === item.agrID
    );
    const isMainBody = !agreement?.parentID;

    const belongsOnlyToOrg =
      item.owner.length === 1 && editMode && version !== "0";
    const isCounterparty = state.user.role.name === "Counterparty";
    const isMajor = version.split(".").length === 1;
    const isReadOnly = editMode === "read";
    const hideDelete = !belongsOnlyToOrg || (!belongsOnlyToOrg && isReadOnly);

    const menuItems = [
      {
        text: "Edit name",
        action: () => initEditVersionName(),
        hidden: isCounterparty || isInEffect,
      },
      {
        text: "Remove name",
        action: () => submitVersionName(true),
        hidden: !versionName || isCounterparty || isInEffect,
      },
      {
        text: "Edit description",
        action: () => initEditDescription(),
        hidden: isCounterparty || isInEffect,
      },
      {
        text: "Duplicate into new version ...",
        action: () => setDuplicate(true),
        hidden: notVersionOwner || isCounterparty || isInEffect,
      },
      {
        text: "Export ...",
        action: () => handleExport(),
      },
      {
        text: "Import ...",
        action: () => setUploadVersion(true),
        hidden: true || !isCounterparty || isReadOnly || isInEffect,
      },
      {
        text: "Invite a party to this version (WIP) ...",
        action: () => console.log("Invite party to collaborate clicked"),
        hidden:
          getCanveoTier(state?.user?.email) !== "experimental" ||
          isReadOnly ||
          isCounterparty ||
          isInEffect,
      },
      {
        text: "Delete this version ...",
        action: () => setDeletingVersion(true),
        hidden:
          notVersionOwner ||
          isMajor ||
          hideDelete ||
          isCounterparty ||
          isInEffect,
      },
    ];

    const filteredMenuItems = menuItems.filter((item) => !item.hidden);

    setFilteredMenuItems(filteredMenuItems);
    setBelongsToOrg(belongsToOrg);
    setReadOnly(isReadOnly);
    setEditMode(editMode);
    setIsMainBody(isMainBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplate, item, state.drawerVersions.versions]);

  /**
   * @param {*} handler
   */
  const menuClick = (handler) => {
    setMenuAnchor(null);
    handler();
  };

  const selectVersion = async () => {
    if (active) return;

    dispatch({
      type: "CHANGE_ACTIVE",
      payload: {
        _id,
        editMode,
        forceReload: true,
        versionType,
        embeddedFileId,
      },
    });
  };

  const initEditVersionName = () => {
    if (state.user.role.name === "Counterparty") return;
    setEditVersionName({
      editing: true,
      value: versionName ? versionName : `Version ${version}`,
    });
  };

  /**
   * @param {*} event
   * @param {*} initHandler
   */
  const handleDoubleClick = (event, initHandler) => {
    if (event?.detail !== 2 || !initHandler) return;

    initHandler();
  };

  const submitVersionName = async (clear = false) => {
    const { value } = editVersionName;
    if (
      (!value || value === versionName || value === `Version ${version}`) &&
      !clear
    ) {
      return;
    }

    await updateVersion(_id, {
      versionName: value,
    });
    setEditVersionName({ editing: false, value: null });
  };

  const initEditDescription = () => {
    if (state.user.role.name === "Counterparty") return;
    setEditDescription({
      editing: true,
      value: description ?? "",
    });
  };

  const submitDescription = async () => {
    const { value } = editDescription;
    if (!value || value === description) return;

    await updateVersion(_id, { description: value });
  };

  const handleDeleteVersion = async () => {
    await deleteVersion(_id, isTemplate);
    setDeletingVersion(false);
  };

  const removeSignedDocument = async () => {
    await updateVersion(_id, {
      signedVersion: null,
    });
    setDeleteSignedDoc(false);
  };

  return (
    <VersionBox
      // @ts-ignore
      selected={active}
      isReadOnly={readOnly}
      p={1}
      my={1}
      onClick={selectVersion}
    >
      {loading && (
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CanveoCircularProgress />
        </Box>
      )}
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ gap: "5px", flexWrap: "noWrap", height: active ? "38px" : "" }}
      >
        <Avatar
          src={receiverOrg?.logoURL ? receiverOrg?.logoURL : defaultLogo}
          sx={{
            width: 30,
            height: 30,
            backgroundColor: theme.palette.primary.contrastText,
          }}
        />
        {!editVersionName.editing ? (
          <Typography
            variant="body2"
            color={theme.palette.grey[800]}
            onClick={(/** @type {*} */ event) =>
              handleDoubleClick(event, initEditVersionName)
            }
            // @ts-ignore
            sx={{
              cursor: state.user.role.name !== "Counterparty" && "pointer",
            }}
          >
            {versionName ? versionName : `Version ${version}`}
          </Typography>
        ) : (
          <EditableField
            value={editVersionName.value}
            handleChange={setEditVersionName}
            handleSubmit={submitVersionName}
          />
        )}
        <Typography variant="body2" color="textSecondary">
          {dayjs(lastUpdateDate).format(dateFormat)}
        </Typography>
        {active && !!filteredMenuItems.length && (
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            style={{
              cursor: "pointer",
              padding: "0 4px",
            }}
            onClick={(e) => setMenuAnchor(e.currentTarget)}
            color={theme.palette.grey[800]}
          />
        )}
      </Grid>
      <Collapse in={active}>
        <Grid container direction="column" sx={{ gap: "16px" }} mt={2}>
          <Grid item container>
            <Grid item xs={4}>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                Author:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                {receiverOrg?.shortName ?? creationBy?.orgID?.shortName}
              </Typography>
            </Grid>
          </Grid>
          {(description || editDescription.editing) && (
            <Grid item container>
              <Grid item xs={4}>
                <Typography variant="body2" color={theme.palette.grey[800]}>
                  Description: (private)
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {!editDescription.editing ? (
                  <Typography
                    variant="body2"
                    color={theme.palette.grey[800]}
                    onClick={(event) =>
                      handleDoubleClick(event, initEditDescription)
                    }
                    sx={{
                      whiteSpace: "pre-line",
                      cursor: "pointer",
                    }}
                  >
                    {description}
                  </Typography>
                ) : (
                  <EditableField
                    value={editDescription.value}
                    handleChange={setEditDescription}
                    handleSubmit={submitDescription}
                    multiline
                    maxRows={3}
                  />
                )}
              </Grid>
            </Grid>
          )}
          <Grid item container>
            <Grid item xs={4}>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                Basis:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                color={theme.palette.grey[800]}
                sx={{
                  whiteSpace: "pre-line",
                }}
              >
                {mapOrigin(origin, downloadFile).map((origin, index) => (
                  <span key={index} style={{ display: "block" }}>
                    {origin}
                  </span>
                ))}
              </Typography>
            </Grid>
          </Grid>
          {signedVersion && (
            <Grid item container>
              <Grid item xs={4}>
                <Typography variant="body2" color={theme.palette.grey[800]}>
                  Signed Version:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant="body2"
                  color={theme.palette.grey[800]}
                  sx={{ display: "flex" }}
                >
                  <Link
                    component="button"
                    // @ts-ignore
                    color={theme.palette.link.main}
                    sx={{ textAlign: "unset", width: "80%" }}
                    onClick={() =>
                      downloadFile(
                        signedVersion?.fileID,
                        signedVersion?.fileName
                      )
                    }
                  >
                    {signedVersion?.fileName}
                  </Link>
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    onClick={() => setDeleteSignedDoc(true)}
                    color={theme.palette.grey[500]}
                    style={{ cursor: "pointer", padding: "8px" }}
                  />
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item container>
            <Grid item xs={4}>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                Last Updated:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                {dayjs(lastUpdateDate).fromNow()}
              </Typography>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                {dayjs(lastUpdateDate).format(dateTimeFormat)}
              </Typography>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                {lastUpdateBy?.displayName} ({lastUpdateBy?.orgID?.shortName})
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={4}>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                Created:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                {dayjs(creationDate).fromNow()}
              </Typography>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                {dayjs(creationDate).format(dateTimeFormat)}
              </Typography>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                {creationBy?.displayName} ({creationBy?.orgID?.shortName})
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
      <Menu
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        disableScrollLock={true}
        open={!!menuAnchor}
        onClose={() => setMenuAnchor(null)}
      >
        {filteredMenuItems.map((item, index) => (
          <MenuItem key={index} onClick={() => menuClick(item.action)}>
            <ListItemText>{item.text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
      {duplicate && (
        <DialogDuplicateVersion
          open={duplicate}
          originVersion={item}
          handleClose={() => setDuplicate(false)}
        />
      )}
      {deletingVersion && (
        <DialogConfirmDelete
          open={deletingVersion}
          title="Delete Version"
          message={`Are you sure you want to delete Version ${version}?`}
          handleClose={() => setDeletingVersion(false)}
          handleConfirm={handleDeleteVersion}
        />
      )}
      {uploadVersion && (
        <DialogUploadVersion
          open={uploadVersion}
          originVersion={item}
          versionType={versionType}
          isMainBody={isMainBody}
          handleClose={() => setUploadVersion(false)}
          isTemplate={isTemplate}
        />
      )}
      {deleteSignedDoc && (
        <DialogConfirmDelete
          open={deleteSignedDoc}
          title="Remove Signed Document"
          message={`Are you sure you want to remove the signed document (${signedVersion?.fileName})?`}
          handleClose={() => setDeleteSignedDoc(false)}
          handleConfirm={removeSignedDocument}
        />
      )}
    </VersionBox>
  );
}
