import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import AddClauseVariantCondition from "./components/AddClauseVariantCondition";

/**
 * @typedef {object} ApplicationStepProps
 * @property {string} documentId
 * @property {ClauseVariant} clauseVariant
 * @property {React.Dispatch<React.SetStateAction<ClauseVariant>>} setClauseVariant
 */

/**
 * @param {ApplicationStepProps} props
 * @returns {JSX.Element}
 */
export default function ApplicationStep({
  clauseVariant,
  setClauseVariant,
  documentId,
}) {
  function handleNewConditionDialogSubmit(
    /** @type {Condition} */ condition,
    /** @type {"create" | "update"} */ type
  ) {
    switch (type) {
      case "create": {
        setClauseVariant((previous) => ({
          ...previous,
          application: {
            ...previous.application,
            conditions: [...clauseVariant.application.conditions, condition],
          },
        }));

        break;
      }

      case "update": {
        const updatedConditions = [...clauseVariant.application.conditions];
        let conditionToUpdateIndex = updatedConditions.findIndex(
          (x) => x.id === condition.id
        );

        if (conditionToUpdateIndex === -1) {
          throw new Error("Condition not found.");
        }

        updatedConditions[conditionToUpdateIndex] = condition;

        setClauseVariant((previous) => ({
          ...previous,
          application: {
            ...previous.application,
            conditions: updatedConditions,
          },
        }));

        break;
      }

      default: {
        throw new Error("Invalid type.");
      }
    }
  }
  return (
    <>
      <Typography fontWeight="bold">
        When should this Variant be applied?
      </Typography>

      <Grid container>
        <FormControl>
          <RadioGroup
            value={clauseVariant.application.type}
            onChange={(event) =>
              setClauseVariant((previous) => ({
                ...previous,
                application: {
                  ...previous.application,
                  type: event.target.value,
                },
              }))
            }
          >
            <FormControlLabel
              value="manual"
              control={<Radio />}
              label="Manually upon user selection"
            />
            <FormControlLabel
              value="automatic"
              control={<Radio />}
              label="Automatically depending on certain conditions "
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {clauseVariant.application.type === "automatic" && (
        <Grid container mt={2}>
          <AddClauseVariantCondition
            conditions={clauseVariant.application.conditions}
            setConditions={(conditions) =>
              setClauseVariant((previous) => ({
                ...previous,
                application: {
                  ...previous.application,
                  conditions: conditions,
                },
              }))
            }
            newConditionDialogProps={{
              isList: false,
              isLoading: false,
              docId: documentId,
              listOptions: [],
              mergeFieldValueType: "freeText",
              submit: handleNewConditionDialogSubmit,
            }}
          />
        </Grid>
      )}

      <Grid container mt={2}></Grid>
    </>
  );
}
