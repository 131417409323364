import { supportedHeadingsMap } from "./headings";

/**
 * @typedef {"TOC" | "Heading" | "Table" | "List Paragraph" | "Caption" | "Subtitle" | "Title" | "Paragraph"} BlockType
 */

/**
 * Receives an SFDT Block and returns its type.
 *
 * @param {import("../../types/sfdt").Block} block
 * @returns {BlockType}
 */
export function checkBlockType(block) {
  // The default value is "Paragraph".
  const blockType =
    /** @type {BlockType | null | undefined} */ (
      block.paragraphFormat?.styleName
    ) || "Paragraph";

  if (blockType.startsWith("TOC")) {
    return "TOC";
  }

  if (supportedHeadingsMap.get(blockType)) {
    return "Heading";
  }

  if (!block.inlines && block.rows) {
    return "Table";
  }

  return blockType;
}
