import { DateTime } from "luxon";

/**
 * @param {{ value: Date; }} params
 * @returns {Date | string}
 */
export function dateColumnFormatter(params) {
  if (typeof params.value === "string") {
    return params.value;
  }

  if (params.value) {
    return DateTime.fromJSDate(params.value).toFormat("dd/MM/yyyy");
  }

  return "";
}
