import { Autocomplete, Chip, Grid, TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import { globalStore } from "../../../../state/store";

/**
 * @typedef {object} TopicStepProps
 * @property {string} documentId
 * @property {ClauseVariant} clauseVariant
 * @property {React.Dispatch<React.SetStateAction<ClauseVariant>>} setClauseVariant
 */

/**
 * @param {TopicStepProps} props
 * @returns {JSX.Element}
 */
export default function TopicStep({ clauseVariant, setClauseVariant }) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const clauseTypes = state.clauseTypes;

  return (
    <>
      <Grid container>
        <Typography fontWeight="bold">
          Do you want to add any labels to this variant?
        </Typography>
      </Grid>

      <Grid container mt={4}>
        <Autocomplete
          fullWidth
          multiple
          options={clauseTypes}
          getOptionLabel={(option) => option.name}
          value={clauseTypes.filter(
            (/** @type {{ _id: string; }} */ clauseTopic) =>
              clauseVariant.topicsIds.includes(clauseTopic._id)
          )}
          onChange={(_event, items) => {
            setClauseVariant((previous) => ({
              ...previous,
              topicsIds: items.map((item) => item._id),
            }));
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label="Topics"
              placeholder="Select Topic ..."
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                      padding: "5px 4px 5px 5px",
                    }}
                  >
                    {option.name}
                  </span>
                }
                color="primary"
              />
            ))
          }
        />
      </Grid>
    </>
  );
}
