export const emptyDefaultHeadersFooters = {
  header: {
    blocks: [
      {
        paragraphFormat: {
          styleName: "Header",
        },
        inlines: [],
      },
    ],
  },
  footer: {
    blocks: [
      {
        paragraphFormat: {
          styleName: "Footer",
        },
        inlines: [],
      },
    ],
  },
  evenHeader: {
    blocks: [
      {
        paragraphFormat: {
          styleName: "Header",
        },
        inlines: [],
      },
    ],
  },
  evenFooter: {
    blocks: [
      {
        paragraphFormat: {
          styleName: "Footer",
        },
        inlines: [],
      },
    ],
  },
  firstPageHeader: {
    blocks: [
      {
        paragraphFormat: {
          styleName: "Header",
        },
        inlines: [],
      },
    ],
  },
  firstPageFooter: {
    blocks: [
      {
        paragraphFormat: {
          styleName: "Footer",
        },
        inlines: [],
      },
    ],
  },
};
