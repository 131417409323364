import { $isTextNode } from "lexical";

/**
 * @param {import("lexical").RangeSelection} selection
 * @returns {boolean}
 */
export function selectionIsAtEndOfInline(selection) {
  if (selection.isCollapsed()) {
    // When the selection is collapsed, its anchor and focus both point to the same node
    // so we can get it from either one.
    const node = selection.anchor.getNode();
    if ($isTextNode(node)) {
      // If there are no more next siblings that means we are at the end of the inline.
      if (node.getNextSiblings().length === 0) {
        const nodeTextContentSize = node.getTextContentSize();
        // If both the anchor and offset are the same as the text content size of the node
        // that means that the selection is at the end of the node.
        if (
          selection.anchor.offset === nodeTextContentSize &&
          selection.focus.offset === nodeTextContentSize
        ) {
          return true;
        }
      }
    } else {
      if (node.getChildrenSize() === 0) return true;
      // TODO: Handle element node.
    }
  } else {
    // TODO: Handle not collapsed selection.
  }

  return false;
}
