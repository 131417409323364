export const baseValueTypes = [
  {
    label: "Merge Field",
    value: "mergeField",
  },
  // As per discussion with David this is removed for the time being.
  // {
  //   label: "Questionnaire Input",
  //   value: "questionnaireInput",
  // },
  // TODO: Uncomment when we implement scenarios (requires conditional assembly).
  // {
  //   label: "Scenario",
  //   value: "scenario",
  // },
  {
    label: "Agreement Properties",
    value: "agreementMetadata",
  },
  { label: "Party Information", value: "partyInformation" },
];

export const operators = {
  freeText: [
    {
      label: "is equal to",
      value: "isEqualToAnyOf",
    },
    {
      label: "is not equal to",
      value: "isNotEqualToAnyOf",
    },
    {
      label: "contains",
      value: "containsAnyOf",
    },
    {
      label: "does not contain",
      value: "doesNotContainAnyOf",
    },
    {
      label: "is known",
      value: "isKnown",
    },
    {
      label: "is unknown",
      value: "isUnknown",
    },
  ],
  date: [
    {
      label: "is equal to",
      value: "isEqualTo",
    },
    {
      label: "is not equal to",
      value: "isNotEqualTo",
    },
    {
      label: "is later than",
      value: "isLaterThan",
    },
    {
      label: "is later than or equal",
      value: "isLaterThanOrEqual",
    },
    {
      label: "is earlier than",
      value: "isEarlierThan",
    },
    {
      label: "is earlier than or equal",
      value: "isEarlierThanOrEqual",
    },
    {
      label: "is known",
      value: "isKnown",
    },
    {
      label: "is unknown",
      value: "isUnknown",
    },
  ],
  number: [
    {
      label: "is equal to",
      value: "isEqualTo",
    },
    {
      label: "is not equal to",
      value: "isNotEqualTo",
    },
    {
      label: "is greater than",
      value: "isGreaterThan",
    },
    {
      label: "is greater than or equal",
      value: "isGreaterThanOrEqual",
    },
    {
      label: "is less than",
      value: "isLessThan",
    },
    {
      label: "is less than or equal",
      value: "isLessThanOrEqual",
    },
    {
      label: "is known",
      value: "isKnown",
    },
    {
      label: "is unknown",
      value: "isUnknown",
    },
  ],
  duration: [
    {
      label: "is equal to",
      value: "isEqualTo",
    },
    {
      label: "is not equal to",
      value: "isNotEqualTo",
    },
    {
      label: "is greater than",
      value: "isGreaterThan",
    },
    {
      label: "is greater than or equal",
      value: "isGreaterThanOrEqual",
    },
    {
      label: "is less than",
      value: "isLessThan",
    },
    {
      label: "is less than or equal",
      value: "isLessThanOrEqual",
    },
    {
      label: "is known",
      value: "isKnown",
    },
    {
      label: "is unknown",
      value: "isUnknown",
    },
  ],
  currency: [
    {
      label: "is equal to",
      value: "isEqualTo",
    },
    {
      label: "is not equal to",
      value: "isNotEqualTo",
    },
    {
      label: "is greater than",
      value: "isGreaterThan",
    },
    {
      label: "is greater than or equal",
      value: "isGreaterThanOrEqual",
    },
    {
      label: "is less than",
      value: "isLessThan",
    },
    {
      label: "is less than or equal",
      value: "isLessThanOrEqual",
    },
    {
      label: "is known",
      value: "isKnown",
    },
    {
      label: "is unknown",
      value: "isUnknown",
    },
  ],
  percentage: [
    {
      label: "is equal to",
      value: "isEqualTo",
    },
    {
      label: "is not equal to",
      value: "isNotEqualTo",
    },
    {
      label: "is greater than",
      value: "isGreaterThan",
    },
    {
      label: "is greater than or equal",
      value: "isGreaterThanOrEqual",
    },
    {
      label: "is less than",
      value: "isLessThan",
    },
    {
      label: "is less than or equal",
      value: "isLessThanOrEqual",
    },
    {
      label: "is known",
      value: "isKnown",
    },
    {
      label: "is unknown",
      value: "isUnknown",
    },
  ],
  isList: [
    {
      label: "is equal to",
      value: "isEqualTo",
    },
    {
      label: "is not equal to",
      value: "isNotEqualTo",
    },
    {
      label: "is known",
      value: "isKnown",
    },
    {
      label: "is unknown",
      value: "isUnknown",
    },
  ],
};

export const partyInformationFields =
  /** @type {import("../../../../../../editor/nodes/MarkNode").MergeField[]} */ (
    /** @type {unknown} */ ([
      {
        _id: "Party Address (L1)",
        name: "Party Address (L1)",
        type: "partyInformation",
        isTemplate: true,
      },
      {
        _id: "Party Address (L2)",
        name: "Party Address (L2)",
        type: "partyInformation",
        isTemplate: true,
      },
      {
        _id: "Party City",
        name: "Party City",
        type: "partyInformation",
        isTemplate: true,
      },
      {
        _id: "Party Country",
        name: "Party Country",
        type: "partyInformation",
        isTemplate: true,
      },
      {
        _id: "Party Entity Name",
        name: "Party Entity Name",
        type: "partyInformation",
        isTemplate: true,
      },
      {
        _id: "Party Org Name",
        name: "Party Org Name",
        type: "partyInformation",
        isTemplate: true,
      },
      {
        _id: "Party State/Province",
        name: "Party State/Province",
        type: "partyInformation",
        isTemplate: true,
      },
      {
        _id: "Party ZIP Code",
        name: "Party ZIP Code",
        type: "partyInformation",
        isTemplate: true,
      },
    ])
  );
