const generateDefaultExportFilename = (
  orgShortName,
  agreement,
  parties,
  isTemplating = false
) => {
  // Will generate a date string like "14Feb2023".
  const date = new Date()
    // Using the "en-uk" locale is the easiest way of getting months written in English with the day appearing before the month.
    .toLocaleDateString("en-uk", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })
    .split(" ")
    .join("");

  if (isTemplating) {
    return `${orgShortName} - Template ${agreement.agrShortName} - ${date}`;
  } else {
    const primaryEntity = agreement.ents.find((x) => x.side === "primary");
    const secondaryEntity = agreement.ents.find((x) => x.side === "secondary");

    const creatorParty = parties.find(
      (x) => x.partyID === primaryEntity?.partyID
    );
    const receiverParty = parties.find(
      (x) => x.partyID === secondaryEntity?.partyID
    );

    const receiverPartyLegalName =
      agreement.ents.length > 2
        ? `${receiverParty?.legalName} et al.`
        : receiverParty?.legalName;

    const shortAgreementTitle = agreement.agrTitle
      .split(" ")
      .map((word) => word.charAt(0))
      .join("")
      .toUpperCase();

    // TODO: Check with David how he wants to solve this.
    if (!receiverPartyLegalName || !creatorParty?.legalName) {
      return `${shortAgreementTitle} - ${agreement.agrStatus} - ${date}`;
    }

    return `${receiverPartyLegalName} - ${creatorParty?.legalName} - ${shortAgreementTitle} - ${agreement.agrStatus} - ${date}`;
  }
};

export default generateDefaultExportFilename;
