/**
 * @param {import("../../../components/MergeFieldMenu/dialogs/NewConditionDialog/condition").Condition} condition
 */
export const isUnknown = ({ baseValue, comparisonValue, mergeFieldValue }) => {
  if (
    !baseValue.isList &&
    baseValue.value.type === "freeText" &&
    !(comparisonValue instanceof Array)
  ) {
    throw new Error(
      "Comparison value needs to be a string array when the base value is of type 'freeText'."
    );
  }

  if (
    !(comparisonValue instanceof Array) &&
    baseValue.value.type !== comparisonValue.type
  ) {
    throw new Error(
      `Comparison value needs to be of type '${baseValue.value.type}'.`
    );
  }

  // TODO: Check if this is the valid way of evaluating this operator.
  if (baseValue.setValueLater) {
    return mergeFieldValue;
  }
};
