import {
  faArrowLeft,
  faTrash,
  faUserPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useContext, useState } from "react";
import { SelectUserForOrg } from "../../components";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import { countryToFlag, getAddressForEntity, trunc } from "../../utils";
import defaultUser from "../../assets/img/defaultuser.png";

const Signers = () => {
  const [state, dispatch] = useContext(globalStore);
  const [addingSignerForEntity, setAddingSignerForEntity] = useState(null);
  const [curPage, setCurPage] = useState(1);
  const pageSize = 10;

  const handleSubmitNewDefaultSigner = () => {
    if (
      addingSignerForEntity.ent !== undefined &&
      addingSignerForEntity.ent._id !== undefined &&
      addingSignerForEntity.user !== undefined &&
      addingSignerForEntity.user._id !== undefined
    ) {
      let newent = addingSignerForEntity.ent;
      let newDS =
        newent.defaultSigners !== undefined &&
        newent.defaultSigners !== null &&
        newent.defaultSigners.length > 0
          ? newent.defaultSigners
          : [];
      if (!newDS.some((ds) => ds._id === addingSignerForEntity.user._id)) {
        newDS.push(addingSignerForEntity.user);
      }
      newent.defaultSigners = newDS;
      pushNewEntity(newent);
    }
    setAddingSignerForEntity(null);
  };

  const removeDefaultSignerForEntity = (uid, entity) => {
    let newent = entity;
    newent.defaultSigners = entity.defaultSigners.filter(
      (ds) => ds._id !== uid
    );
    pushNewEntity(newent);
  };

  const pushNewEntity = (newent) => {
    axios
      .put(state.settings.api + "entity/" + newent._id, { entity: newent })
      .then((resEnt) => {
        if (resEnt.data.success) {
          // Add newly updated entity to the reducer
          dispatch({ type: "UPDATE_SUB", payload: newent });
          dispatch({ type: "NEW_SNACKBAR", payload: "The entity was updated" });
        } else {
          dispatch({
            type: "NEW_SNACKBAR",
            payload: "An error occurred while updating the entity",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "NEW_SNACKBAR",
          payload: "An error occurred while updating the entity",
        });
      });
  };

  return (
    <Box>
      <Grid container direction="column" alignItems="center" sx={{ my: 5 }}>
        <Grid item>
          <Typography variant="h4">Default Signers</Typography>
        </Grid>

        <Grid item sx={{ mt: 2 }}>
          <Typography variant="body1" display="block" align="center">
            Default signers are automatically added as signers to new agreements
          </Typography>
        </Grid>

        {addingSignerForEntity ? (
          <Grid item sx={{ my: 5, width: "320px" }}>
            <Typography align="center" style={{ marginBottom: "40px" }}>
              Set the default signer(s) for
              <br />
              <span className="bold">
                {addingSignerForEntity.ent.legalName}
              </span>
              :
            </Typography>
            <SelectUserForOrg
              orgID={state.user.orgID}
              handleSelectUser={(e) =>
                setAddingSignerForEntity({
                  ...addingSignerForEntity,
                  user: e,
                })
              }
              hiddenUsers={addingSignerForEntity.hidden}
              userSelected={addingSignerForEntity.user}
            />
            <Button
              variant="contained"
              disableElevation
              fullWidth
              sx={{ mt: 2 }}
              onClick={handleSubmitNewDefaultSigner}
            >
              Submit
            </Button>
            <Button
              variant="text"
              sx={{ mt: 1 }}
              onClick={(e) => setAddingSignerForEntity(null)}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              &nbsp;&nbsp;Cancel
            </Button>
          </Grid>
        ) : (
          <>
            <Grid item sx={{ mt: 5 }}>
              {state.subs
                .filter((s) => s.active)
                .sort((a, b) => (a.legalName > b.legalName ? 1 : -1))
                .slice((curPage - 1) * pageSize, curPage * pageSize)
                .map((s, i) => (
                  <Grid
                    container
                    direction="row"
                    key={i}
                    sx={{
                      py: 3,
                      px: 2,
                      borderBottom: "1px dotted" + theme.palette.grey[300],
                      borderTop:
                        i === 0
                          ? "1px dotted" + theme.palette.grey[300]
                          : "0px",
                    }}
                  >
                    <Grid item sx={{ width: "300px" }}>
                      <Typography variant="subtitle1">
                        {trunc(s.legalName, 26)}
                        {getAddressForEntity(s, "billto").country !==
                        undefined ? (
                          <>
                            &nbsp;&nbsp;
                            {countryToFlag(
                              getAddressForEntity(s, "billto").country
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ width: "260px" }}>
                      {s.defaultSigners?.length > 0 ? (
                        s.defaultSigners.map((ds, j) => (
                          <Grid
                            direction="row"
                            container
                            alignItems={"center"}
                            key={j}
                          >
                            <Grid item>
                              <Avatar
                                src={
                                  state.users.filter(
                                    (u) => u._id === ds._id
                                  )[0] !== undefined
                                    ? state.users.filter(
                                        (u) => u._id === ds._id
                                      )[0].photoURL
                                    : defaultUser
                                }
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  margin: "5px 10px 5px 0px",
                                }}
                              />
                            </Grid>
                            <Grid item>
                              <Typography key={i + "_" + ds._id}>
                                {state.users.filter(
                                  (u) => u._id === ds._id
                                )[0] !== undefined
                                  ? trunc(
                                      state.users.filter(
                                        (u) => u._id === ds._id
                                      )[0].displayName,
                                      20
                                    )
                                  : "Unknown"}
                              </Typography>
                            </Grid>
                            <Grid item sx={{ paddingLeft: "10px" }}>
                              <IconButton
                                onClick={(e) =>
                                  removeDefaultSignerForEntity(ds._id, s)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  style={{
                                    color: theme.palette.error.main,
                                    fontSize: "14px",
                                    padding: "4px 5px",
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <Typography color="textSecondary">
                          No default signers defined
                        </Typography>
                      )}
                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        disableElevation
                        sx={{ mt: 2, padding: "2px 20px" }}
                        onClick={(e) =>
                          setAddingSignerForEntity({
                            ent: s,
                            user: {},
                            hidden: s.defaultSigners,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faUserPlus} />
                        &nbsp;&nbsp;Set Default Signer
                      </Button>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            <Grid item sx={{ mt: 5 }}>
              <Stack spacing={2}>
                <Pagination
                  count={Math.ceil(
                    state.subs.filter((s) => s.active).length / pageSize
                  )}
                  defaultPage={1}
                  page={curPage}
                  onChange={(e, newVal) => setCurPage(newVal)}
                  siblingCount={0}
                  boundaryCount={2}
                  renderItem={(item) => <PaginationItem {...item} />}
                />
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Signers;
