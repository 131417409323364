import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $dfs } from "@lexical/utils";
import { $getSelection, $isElementNode, $isTextNode } from "lexical";
import React, { useContext, useEffect, useState } from "react";
import { globalStore } from "../../../../state/store";
import { $isClauseNode } from "../../nodes/ClauseNode";
import { $isMarkNode } from "../../nodes/MarkNode";
import { $isRedlineNode } from "../../nodes/RedlineNode";
import OpenIssuesLineContainer from "./OpenIssuesLineContainer";

/**
 * @typedef {object} OpenIssue
 * @property {string} id
 * @property {string} [lid]
 * @property {import("../../nodes/MarkNode").MarkNode | import("../../nodes/ClauseNode").ClauseNode | import("../../nodes/RedlineNode").RedlineNode} node
 * @property {HTMLElement} element
 * @property {boolean} isSelected
 * @property {"add" | "del" | "xadd" | "xdel" | "mergeField" | "publicComment" | "internalComment" | "approvalRequest" | string & {}} openIssueType
 * @property {import("../../nodes/RedlineNode").RedlineMetadata | import("../../nodes/RedlineNode").NodeMetadata | import("../../nodes/ClauseNode").ClauseMetadata} [metadata]
 * @property {string} text
 * @property {import("../../nodes/MarkNode").MergeField} [mergeField]
 * @property {boolean} skipNavigation
 */

/**
 * @typedef {object} Workflow
 
 * @property {string} workflow.wfType
 * @property {string} workflow.orgID
 * @property {string} workflow.lid
 * @property {string} workflow.docID
 * @property {object[]} workflow.comments
 * @property {string} workflow.comments.id
 * @property {object} workflow.comments.creator
 * @property {string} workflow.comments.creator.uid
 * @property {string} workflow.comments.creator.email
 * @property {string} workflow.comments.creator.displayName
 * @property {string} workflow.comments.content
 * @property {string} workflow.comments.date
 * @property {boolean} workflow.comments.isEdited
 * @property {string} workflow.quote
 * @property {string} workflow.note
 * @property {string[]} workflow.subscribers
 * @property {object} workflow.creator
 * @property {string} workflow.creator.uid
 * @property {string} workflow.creator.email
 * @property {string} workflow.creator.password
 * @property {string} workflow.creator.firstName
 * @property {string} workflow.creator.lastName
 * @property {string} workflow.creator.displayName
 * @property {string} workflow.creator.title
 * @property {string} workflow.creator.orgID
 * @property {string} workflow.creator.photoURL
 * @property {object} workflow.creator.role
 * @property {string} workflow.creator.role._id
 * @property {string} workflow.creator.role.name
 * @property {string} workflow.creator.role.description
 * @property {string} workflow.creator.role.orgID
 * @property {boolean} workflow.creator.role.active
 * @property {boolean} workflow.creator.role.hasReadOnly
 * @property {string} workflow.creator.role.description2
 * @property {[]} workflow.creator.role.labels
 * @property {number} workflow.creator.role.priority
 * @property {number} workflow.creator.role.__v
 * @property {boolean} workflow.creator.active
 * @property {number} workflow.creator.__v
 * @property {[]} workflow.assignee
 * @property {string} workflow.wfStatus
 * @property {string} workflow.creationDate
 * @property {string[]} workflow.visibleTo
 */

/**
 * Returns a map where the value is a list of open issues and the key
 * is the height of the line.
 *
 * @param {import("lexical").LexicalEditor} editor
 * @param {string} selectedOpenIssueId
 * @param {boolean} showInfoIcons
 * @param {Workflow[]} workflows
 * @param {string} orgID
 * @returns {Map<number, OpenIssue[]>}
 */
export function $getOpenIssuesGroupedByLine(
  editor,
  selectedOpenIssueId,
  showInfoIcons,
  workflows,
  orgID
) {
  const depthFirstSearchList = $dfs();

  const allMarkNodes = depthFirstSearchList.filter((x) => $isMarkNode(x.node));
  /** @type {Map<number, OpenIssue[]>} */
  const openIssuesLinesMap = new Map();

  const /**@type {string[]}*/ markNodeIds = [];

  for (const item of depthFirstSearchList) {
    const { node } = item;

    // TODO: Clause level items are only shown to the author organisation.
    if ($isClauseNode(node)) {
      const [child] = node.getChildren();
      if ($isElementNode(child)) {
        // TODO: Check this.
        const [textNode] = child.getAllTextNodes();
        let element;

        if (textNode) {
          const nodeKey = textNode.getKey();
          element = editor.getElementByKey(nodeKey);
        }

        if (!element) {
          const nodeKey = node.getKey();
          element = editor.getElementByKey(nodeKey);
          if (!element) {
            throw new Error(
              `Node with key ${node.getKey()} is not currently represented in the DOM.`
            );
          }
        }

        if (node.getVariants().length) {
          const top = element.getBoundingClientRect().top;
          const existingValue = openIssuesLinesMap.get(top);
          const openIssue = {
            id: `${node.getKey()}_variants`,
            node,
            element,
            openIssueType: "variants",
            text: "",
            isSelected: selectedOpenIssueId === node.id,
            skipNavigation: true,
            metadata: {
              clauseId: node.id,
              clauseTypes: node.getClauseTypes() || [],
            },
          };

          if (existingValue) {
            openIssuesLinesMap.set(top, [...existingValue, openIssue]);
          } else {
            openIssuesLinesMap.set(top, [openIssue]);
          }
        }

        if ($isTextNode(textNode)) {
          for (const workflow of node.getWorkflows()) {
            const workflowData = workflows.find((w) => w.lid === workflow);

            if (!workflowData) {
              throw new Error(
                `Could not find data pertaining to workflow with ID ${workflow}`
              );
            }

            // If the workflow is not visible to the current party, we jump over it
            if (!workflowData.visibleTo.includes(orgID)) continue;

            let openIssueType = "";

            if (workflow.startsWith("cp")) {
              openIssueType = "internalComment";
            }

            if (workflow.startsWith("ap")) {
              openIssueType = "approvalRequest";
            }

            const top = element.getBoundingClientRect().top;
            const existingValue = openIssuesLinesMap.get(top);

            // Since a clause node can have multiple open issues (i.e., internal comments and approval requests)
            // we need a way to uniquely identify them since we cannot use the node key which would be the same
            // for all of them. In order to get around that issue, we use a combination of the node key plus the
            // workflow (id).
            const id = `${node.getKey()}_${workflow}`;

            const partyId = workflow.split("_")[2];

            if (!partyId) {
              throw new Error("Workflow ID does not have a party ID.");
            }

            /** @type {OpenIssue} */
            const openIssue = {
              id,
              node,
              element,
              isSelected: selectedOpenIssueId === id,
              openIssueType,
              text: "",
              // @ts-ignore
              metadata: {
                partyId,
              },
            };

            if (existingValue) {
              openIssuesLinesMap.set(top, [...existingValue, openIssue]);
            } else {
              openIssuesLinesMap.set(top, [openIssue]);
            }
          }
        }

        if (node.getChildrenSize()) {
          const [textNode] = node.getAllTextNodes();
          if (textNode) {
            const element = editor.getElementByKey(textNode.getKey());
            if (element) {
              const top = element.getBoundingClientRect().top;
              const existingValue = openIssuesLinesMap.get(top);
              //return only if owner
              if (showInfoIcons) {
                if (node.getClauseTypes().length) {
                  const openIssue = {
                    //use node id and not clause type id because there can be more than one but icon behavior is the same
                    id: node.id,
                    node,
                    element,
                    openIssueType: "info",
                    text: "",
                    isSelected: selectedOpenIssueId === node.id,
                    skipNavigation: true,
                    metadata: {
                      clauseId: node.id,
                      clauseTypes: node.getClauseTypes(),
                    },
                  };
                  if (existingValue) {
                    openIssuesLinesMap.set(top, [...existingValue, openIssue]);
                  } else {
                    openIssuesLinesMap.set(top, [openIssue]);
                  }
                }
              }
            }
          }
        }
      }
    }

    if ($isMarkNode(node)) {
      const [textNode] = node.getChildren();

      if (!node.getIsVisible()) continue;

      if (textNode) {
        const nodeKey = textNode.getKey();
        const element = editor.getElementByKey(nodeKey);
        if (!element) {
          throw new Error(
            `Node with key ${node.getKey()} is not currently represented in the DOM.`
          );
        }

        // Group open issues by line so that they can be displayed near each other.
        const top = element.getBoundingClientRect().top;

        const openIssueType = node.getMarkType();
        if (!openIssueType) {
          throw new Error("Mark node does not have an open issue type.");
        }

        let idsToAdd = node.getIDs();
        if (idsToAdd.length > 1) {
          // first filter out the node i'm checking
          const nodesToCheck = allMarkNodes.filter(
            (n) => n.node.getKey() !== node.getKey()
          );
          //combine all ids into an array
          const allIds = /** @type {string[]} */ (
            [].concat(...nodesToCheck.map((n) => n.node.getIDs()))
          );
          //find the unique ids which are to be added
          idsToAdd = node.getIDs().filter((id) => !allIds.includes(id));
        }

        idsToAdd = idsToAdd.filter((i) => !markNodeIds.includes(i));

        markNodeIds.push(...idsToAdd);

        for (const lid of idsToAdd) {
          const existingValue = openIssuesLinesMap.get(top);
          const id = `${node.getKey()}_${lid}`;
          /** @type {OpenIssue} */
          const openIssue = {
            id,
            node,
            element,
            isSelected:
              selectedOpenIssueId === id ||
              selectedOpenIssueId === node.getMergeField()?._id,
            openIssueType,
            text: "",
            metadata: node.getMetadata(),
            skipNavigation: false,
          };

          if (existingValue) {
            openIssuesLinesMap.set(top, [...existingValue, openIssue]);
          } else {
            openIssuesLinesMap.set(top, [openIssue]);
          }
        }
      }
    }
  }

  return openIssuesLinesMap;
}

/**
 * Returns a list of open issues that currently exist within a given editor.
 *
 * @param {import("lexical").LexicalEditor} editor
 * @param {Workflow[]} workflows
 * @param {string} orgID
 * @returns {OpenIssue[]}
 */
export function $getOpenIssuesList(editor, workflows, orgID) {
  /** @type {OpenIssue[]} */
  const openIssuesList = [];

  const selection = $getSelection();
  const selectionNodesKeys = selection
    ? selection.getNodes().map((selectedNode) => selectedNode.getKey())
    : [];

  const depthFirstSearchList = $dfs();

  for (const item of depthFirstSearchList) {
    const { node } = item;

    // TODO: Clause level items should only be shown to the organisation that created them.
    if ($isClauseNode(node)) {
      if (node.getVariants().length) {
        const openIssue = {
          id: `${node.getKey()}_variants`,
          node,
          isSelected: false,
          openIssueType: "variants",
          skipNavigation: true,
          metadata: {
            clauseId: node.id,
            clauseTypes: node.getClauseTypes() || [],
          },
        };
        // @ts-ignore
        openIssuesList.push(openIssue);
      }

      for (const workflow of node.getWorkflows()) {
        let openIssueType = "";

        if (workflow.startsWith("cp")) {
          openIssueType = "internalComment";
        }

        if (workflow.startsWith("ap")) {
          openIssueType = "approvalRequest";
        }

        const workflowData = workflows.find((w) => w.lid === workflow);

        if (!workflowData) {
          throw new Error(
            `Could not find data pertaining to workflow with ID ${workflow}`
          );
        }

        if (!workflowData.visibleTo.includes(orgID)) continue;

        const openIssue = {
          id: `${node.getKey()}_${workflow}`,
          node,
          // element,
          isSelected: false,
          openIssueType,
          metadata: {
            creatorId: workflowData.creator.uid,
            creationDate: workflowData.creationDate,
            creatorDisplayName: workflowData.creator.displayName,
            creatorEmail: workflowData.creator.email,
            creatorPhotoUrl: workflowData.creator.photoURL,
          },
          text: workflowData.quote,
          lid: workflowData.lid,
          skipNavigation: workflowData.wfStatus === "resolved",
        };

        // @ts-ignore
        openIssuesList.push(openIssue);
      }

      if (node.getClauseTypes().length) {
        const openIssue = {
          id: node.id,
          node,
          isSelected: false,
          openIssueType: "info",
          skipNavigation: true,
          metadata: {
            clauseId: node.id,
            clauseTypes: node.getClauseTypes(),
          },
        };
        // @ts-ignore
        openIssuesList.push(openIssue);
      }
    }

    if ($isMarkNode(node)) {
      const [textNode] = node.getChildren();

      if (!node.getIsVisible()) continue;

      if (textNode) {
        const nodeKey = textNode.getKey();
        const element = editor.getElementByKey(nodeKey);
        if (element) {
          const isSelected = selectionNodesKeys.includes(nodeKey);
          const openIssueType = node.getMarkType() || "";

          for (const lid of node.getIDs()) {
            const openIssue = {
              id: `${node.getKey()}_${lid}`,
              node,
              element,
              isSelected,
              openIssueType,
              metadata: node.getMetadata(),
              text: node.getTextContent(),
              mergeField: node.getMergeField(),
              lid: lid,
              skipNavigation: false,
            };

            if (node.getMarkType() === "mergeField") {
              const mergeFieldData = node.getMergeField();
              if (!mergeFieldData) {
                throw new Error(
                  "Missing Merge Field data for mark node of type mergeField."
                );
              }

              // We want to skip mark nodes that are merge fields when navigating open issues,
              // if they were created without asking the counterparty to confirm, which means
              // that they are not open issues.
              if (!mergeFieldData.askCounterpartyToConfirm) {
                openIssue.skipNavigation = true;
              }
            }

            if (node.getMarkType() === "publicComment") {
              const workflowData = workflows.find(
                (w) => w.lid === openIssue.lid
              );
              if (!workflowData) {
                throw new Error(
                  `Could not find data pertaining to workflow with ID ${openIssue.lid}`
                );
              }

              openIssue.skipNavigation = workflowData.wfStatus === "resolved";
              openIssue.text = workflowData.quote;
            }

            // if (!openIssuesList.find(i=> i.lid === lid))
            openIssuesList.push(openIssue);
          }
        } else {
          console.warn(
            `Node with key ${node.getKey()} is not currently represented in the DOM.`
          );
        }
      }
    }

    if ($isRedlineNode(node)) {
      const nodeKey = node.getKey();
      const element = editor.getElementByKey(nodeKey);
      if (element) {
        const isSelected = selectionNodesKeys.includes(nodeKey);

        const openIssue = {
          id: node.getKey(),
          node,
          element,
          isSelected,
          openIssueType: node.getRedlineType(),
          metadata: node.getMetadata(),
          text: node.getTextContent(),
          skipNavigation: false,
        };

        openIssuesList.push(openIssue);
      } else {
        console.warn(
          `Node with key ${node.getKey()} is not currently represented in the DOM.`
        );
      }
    }
  }

  return openIssuesList;
}

/**
 * @param {Map<number, OpenIssue[]>} openIssuesLines
 * @param {HTMLElement} anchorElem
 * @param {import("lexical").LexicalEditor} editor
 * @param {string} partyId
 */
function createOpenIssuesLines(openIssuesLines, anchorElem, editor, partyId) {
  /** @type {JSX.Element[]} */
  const html = [];

  let counter = 0;

  openIssuesLines.forEach((line) => {
    html.push(
      <OpenIssuesLineContainer
        key={counter}
        anchorElem={anchorElem}
        line={line}
        editor={editor}
        partyId={partyId}
      />
    );
    counter++;
  });

  return html;
}

/**
 * @param {{anchorElem: HTMLElement; partyId: PartyId; isTemplating: boolean; docID: string;}} props
 * @returns {JSX.Element}
 */
export default function OpenIssuesPlugin({
  anchorElem,
  partyId,
  isTemplating,
  docID,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  /** @type {Map<number, OpenIssue[]>} */
  const defaultOpenIssues = new Map();
  const [openIssuesLines, setOpenIssuesLines] = useState(defaultOpenIssues);

  const [editor] = useLexicalComposerContext();
  const editorState = editor.getEditorState();
  const [showInfoIcons] = useState(
    isTemplating ||
      state.agrs?.find((/** @type {*} */ a) => a._id === docID)?.owner ===
        state.org._id
  );

  useEffect(() => {
    editorState.read(() => {
      const openIssuesLinesMap = $getOpenIssuesGroupedByLine(
        editor,
        state.selectedOpenIssue.id,
        showInfoIcons,
        state.workflows,
        state.org._id
      );

      setOpenIssuesLines(openIssuesLinesMap);
    });
  }, [
    editorState,
    editor,
    showInfoIcons,
    state.selectedOpenIssue.id,
    state.workflows,
    state.org._id,
  ]);

  // Counterparties cannot see regular merge fields, so if the user is a counterparty we search for
  // all the existing merge fields and if they are of type "regular" we set their visibility to false.
  useEffect(() => {
    if (state.user.role.name !== "Counterparty") return;

    editor.update(() => {
      for (const { node } of $dfs()) {
        if (!$isMarkNode(node) || node.getMarkType() !== "mergeField") continue;

        const mergeField = node.getMergeField();
        if (!mergeField || mergeField.type !== "regular") continue;

        node.setIsVisible(false);
      }
    });
  }, [editor, state.user.role.name, state.drawerVersions.active?._id]);

  return (
    <>{createOpenIssuesLines(openIssuesLines, anchorElem, editor, partyId)}</>
  );
}
