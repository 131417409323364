import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import {
  Box,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FabStandard } from "../../components";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import PlaybookCard from "./components/PlaybookCard";

const PAGE_SIZE = 8;

export function ManagePlaybooks() {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const navigate = useNavigate();

  const [playbooks, setPlaybooks] = useState(/** @type {*[]} */ ([]));
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(
    () => {
      loadPlaybooks();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  async function loadPlaybooks() {
    const getPlaybooksResponse = await axios.get(
      `${state.settings.api}playbook`
    );
    const playbooks = getPlaybooksResponse.data.data;
    setPlaybooks(playbooks);
  }

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item display={"flex"} justifyContent={"center"} xs={8}>
          <Typography variant="h4">Manage Playbooks</Typography>
        </Grid>
        <Grid
          item
          display={"flex"}
          justifyContent={"center"}
          xs={8}
          sx={{ m: 2 }}
        >
          <Typography variant="body1" textAlign={"center"}>
            Manage your playbooks.
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="center">
        <FabStandard
          click={(/** @type {any} */ e) => navigate("/templates/playbook/new")}
          text="New"
          icon={faCirclePlus}
          sx={{
            left: "100px",
            top: "80px",
            right: "unset",
          }}
        />

        <Grid item mt={10}>
          {playbooks.length > 0 ? (
            <Grid
              container
              direction="row"
              spacing={2}
              sx={{
                [theme.breakpoints.only("xs")]: { width: "340px" },
                [theme.breakpoints.only("sm")]: { width: "410px" },
                [theme.breakpoints.up("md")]: { width: "880px" },
              }}
            >
              {playbooks
                .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
                .sort(
                  (a, b) =>
                    // @ts-ignore
                    new Date(b.lastUpdateDate) -
                    // @ts-ignore
                    new Date(a.lastUpdateDate)
                )
                .map((playbook) => {
                  playbook.agrStatus = playbook.active ? "Active" : "Draft";
                  return (
                    <Grid item key={playbook._id} xs={6} md={3}>
                      <PlaybookCard
                        ag={playbook}
                        actionReq={!playbook.active}
                        thumbClick={(/** @type {any} */ e) =>
                          navigate(`/templates/playbooks/${playbook._id}`)
                        }
                        showLogo={state.org.logoURL}
                        primaryLegalName={playbook.reference}
                        secondaryLegalName={"Version " + playbook.version}
                        isTemplate
                      />
                    </Grid>
                  );
                })}
            </Grid>
          ) : (
            <Box sx={{ mx: 5, my: 5 }}>
              <Typography align="center">No playbooks yet.</Typography>
            </Box>
          )}
        </Grid>
        <Grid item sx={{ mt: 7 }}>
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil(playbooks.length / PAGE_SIZE)}
              defaultPage={1}
              page={currentPage}
              onChange={(e, newVal) => setCurrentPage(newVal)}
              siblingCount={0}
              boundaryCount={2}
              renderItem={(item) => <PaginationItem {...item} />}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
