import { Link } from "@mui/material";
import React from "react";
import theme from "../theme/theme";

/**
 * @param {*} origin
 * @param {*} handleDownloadFile
 */
const mapOrigin = (origin, handleDownloadFile) => {
  const origins = [];
  for (const originKey in origin) {
    if (originKey === "version") {
      for (const version of origin[originKey]) {
        origins.push(`- Version ${version}`);
      }
    }

    if (originKey === "template") {
      origins.push(`- Template ${origin[originKey]}`);
    }

    if (originKey === "scratch") origins.push(`- ${origin[originKey]}`);

    if (originKey === "import") {
      const [fileName, file] = origin[originKey].split("#");
      origins.push(
        <Link
          component="button"
          // @ts-ignore
          color={theme.palette.link.main}
          sx={{ textAlign: "unset" }}
          onClick={() => handleDownloadFile(file, fileName)}
        >
          - {fileName}
        </Link>
      );
    }
  }
  return origins;
};

export default mapOrigin;
