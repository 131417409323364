import {
  Box,
  Button,
  Step,
  StepContent,
  StepLabel,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

/**
 * @param {*} props
 */
export default function CustomStep({
  stepIndex,
  title,
  subtitle,
  activeStep,
  backText,
  nextText,
  handleBack,
  handleNext,
  canProceed,
  children,
  ...props
}) {
  // We need this useEffect in order to allow the user to navigate the steps with the Enter
  // key to go forward and the Shift + Enter to go backwards.
  useEffect(() => {
    const keyDownHandler = (/** @type {KeyboardEvent} */ event) => {
      const activeDialog = document.querySelector(".MuiDialog-container");
      if (activeDialog) return;

      if (stepIndex === activeStep) {
        if (event.key === "Enter") {
          event.preventDefault();

          if (event.shiftKey) {
            if (activeStep !== 0) {
              handleBack();
            }
          } else {
            if (canProceed) {
              handleNext();
            }
          }
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [activeStep, canProceed, handleBack, handleNext, stepIndex]);

  return (
    <Step {...props}>
      <StepLabel>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
      </StepLabel>
      <StepContent TransitionProps={{ unmountOnExit: false }}>
        <Box>
          <Typography variant="body1" color="grey">
            {subtitle}
          </Typography>
        </Box>
        <Box>{children}</Box>

        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            disabled={activeStep === 0}
            onClick={handleBack}
            size="small"
            sx={{ mt: 1, mr: 1 }}
          >
            {backText || "Back"}
          </Button>

          <Button
            variant="contained"
            disableElevation
            disabled={!canProceed}
            onClick={handleNext}
            size="small"
            sx={{ mt: 1, mr: 1 }}
          >
            {nextText || "Next"}
          </Button>
        </Box>
      </StepContent>
    </Step>
  );
}
