import { HeadingNode } from "@lexical/rich-text";
import { $applyNodeReplacement } from "lexical";
import { CUSTOM_HEADING_TYPE } from "../utils/constants";
import { removeRevisionIdsFromCharacterFormat } from "./utils/removeRevisionIdsFromCharacterFormat";

/**
 * @typedef BaseSerializedCustomHeadingNode
 * @property {import("../types/lexical").LineSpacing} [lineSpacing]
 * @property {import("../types/lexical").ParagraphSpacing} [paragraphSpacing]
 * @property {import("../types/lexical").Indentation} [indentation]
 * @property {import("../types/sfdt").CharacterFormat} [characterFormat]
 */

/**
 * @typedef {BaseSerializedCustomHeadingNode & import("@lexical/rich-text").SerializedHeadingNode} SerializedCustomHeadingNode
 */

export class CustomHeadingNode extends HeadingNode {
  /** @private @type {import("../types/lexical").LineSpacing | undefined} */
  __lineSpacing;
  /** @private @type {import("../types/lexical").ParagraphSpacing | undefined} */
  __paragraphSpacing;
  /** @private @type {import("../types/lexical").Indentation | undefined} */
  __indentation;
  /** @private @type {import("../types/sfdt").CharacterFormat | undefined} */
  __characterFormat;

  static getType() {
    return CUSTOM_HEADING_TYPE;
  }
  /**
   * @param {import("@lexical/rich-text").HeadingTagType} tag
   * @param {import("../types/lexical").LineSpacing} [lineSpacing]
   * @param {import("../types/lexical").ParagraphSpacing} [paragraphSpacing]
   * @param {import("../types/lexical").Indentation} [indentation]
   * @param {import("../types/sfdt").CharacterFormat} [characterFormat]
   * @param {string} [key]
   */
  constructor(
    tag,
    lineSpacing,
    paragraphSpacing,
    indentation,
    characterFormat,
    key
  ) {
    super(tag, key);

    this.__lineSpacing = lineSpacing;
    this.__paragraphSpacing = paragraphSpacing;
    this.__indentation = indentation;
    this.__characterFormat =
      removeRevisionIdsFromCharacterFormat(characterFormat);
  }

  /**
   * @param {CustomHeadingNode} node
   * @returns {CustomHeadingNode}
   */
  static clone(node) {
    const clonedNode = new CustomHeadingNode(
      node.__tag,
      node.lineSpacing,
      node.paragraphSpacing,
      node.indentation,
      node.characterFormat,
      node.__key
    );
    return clonedNode;
  }

  get lineSpacing() {
    const self = this.getLatest();
    return self.__lineSpacing;
  }

  get paragraphSpacing() {
    const self = this.getLatest();
    return self.__paragraphSpacing;
  }

  get indentation() {
    const self = this.getLatest();
    return self.__indentation;
  }

  get characterFormat() {
    const self = this.getLatest();
    return self.__characterFormat;
  }

  set lineSpacing(value) {
    const self = this.getWritable();
    self.__lineSpacing = value;
  }

  set paragraphSpacing(value) {
    const self = this.getWritable();
    self.__paragraphSpacing = value;
  }

  set indentation(value) {
    const self = this.getWritable();
    self.__indentation = value;
  }

  set characterFormat(value) {
    const self = this.getWritable();
    self.__characterFormat = removeRevisionIdsFromCharacterFormat(value);
  }

  /**
   * @param {import("lexical").EditorConfig} config
   * @returns
   */
  createDOM(config) {
    const dom = super.createDOM(config);
    const formatType = this.getFormatType();
    if (formatType) dom.style.textAlign = formatType;
    if (this.__lineSpacing) {
      const { lineSpacing, lineSpacingType } = this.__lineSpacing;
      switch (lineSpacingType) {
        case "AtLeast":
        case "Exactly":
          dom.style.lineHeight = `${lineSpacing.toPrecision(2)}px`;
          break;
        case "Multiple":
        default:
          dom.style.lineHeight = lineSpacing.toPrecision(2);
          break;
      }
    }
    if (this.__indentation) {
      // eslint-disable-next-line no-unused-vars
      const { firstLineIndent, leftIndent, rightIndent, tabs } =
        this.__indentation;
      if (firstLineIndent) {
        dom.style.textIndent = `${firstLineIndent}px`;
      }
      if (leftIndent) {
        dom.style.marginInlineStart = `${leftIndent}px`;
      }
      if (rightIndent) {
        dom.style.marginInlineEnd = `${rightIndent}px`;
      }
    }
    if (this.__paragraphSpacing) {
      const { afterSpacing, beforeSpacing } = this.__paragraphSpacing;
      if (beforeSpacing) dom.style.paddingBlockStart = `${beforeSpacing}px`;
      if (afterSpacing) dom.style.paddingBlockEnd = `${afterSpacing}px`;
    }

    return dom;
  }

  /**
   * @param {SerializedCustomHeadingNode} serializedNode
   * @returns {CustomHeadingNode}
   */
  static importJSON(serializedNode) {
    const customHeadingNode = new CustomHeadingNode(
      serializedNode.tag,
      serializedNode.lineSpacing,
      serializedNode.paragraphSpacing,
      serializedNode.indentation,
      serializedNode.characterFormat
    );
    customHeadingNode.setFormat(serializedNode.format);
    customHeadingNode.setIndent(serializedNode.indent);
    customHeadingNode.setDirection(serializedNode.direction);
    return customHeadingNode;
  }

  exportJSON() {
    const serializedNode = {
      ...super.exportJSON(),
      lineSpacing: this.__lineSpacing,
      paragraphSpacing: this.__paragraphSpacing,
      indentation: this.__indentation,
      characterFormat: removeRevisionIdsFromCharacterFormat(
        this.__characterFormat
      ),
      type: CUSTOM_HEADING_TYPE,
    };
    return serializedNode;
  }
}

/**
 * @param {import("@lexical/rich-text").HeadingTagType} tag
 * @param {import("../types/lexical").LineSpacing} [lineSpacing]
 * @param {import("../types/lexical").ParagraphSpacing} [paragraphSpacing]
 * @param {import("../types/lexical").Indentation} [indentation]
 * @param {import("../types/sfdt").CharacterFormat} [characterFormat]
 * @returns {CustomHeadingNode}
 */
export function $createCustomHeadingNode(
  tag,
  lineSpacing,
  paragraphSpacing,
  indentation,
  characterFormat
) {
  // TODO: What does $applyNodeReplacement do?
  return $applyNodeReplacement(
    new CustomHeadingNode(
      tag,
      lineSpacing,
      paragraphSpacing,
      indentation,
      characterFormat
    )
  );
}

/**
 * @param {import("lexical").LexicalNode | null | undefined} node
 * @return {node is CustomHeadingNode}
 */
export function $isCustomHeadingNode(node) {
  const isInstanceOfCustomHeadingNode = node instanceof CustomHeadingNode;
  return isInstanceOfCustomHeadingNode;
}
