import { FormHelperText, styled } from "@mui/material";

const StyledFormHelperText = styled(FormHelperText)(
  ({ m = "16px", color = "#827f8f" }) => `
  margin-top: ${m};
  color: ${color};
  font-size: 0.8rem;
  line-height: 1.6;
  text-align: justify;
`
);

export default StyledFormHelperText;
