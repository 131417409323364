import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faPen, faTrashXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import useLabelService from "../../hooks/useLabelService";
import { CanveoCircularProgress } from "../index";

function DialogLabel({
  open,
  type,
  label,
  options,
  handleClose,
  handleCreate,
}) {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const {
    errorMessage,
    loading,
    createLabel,
    updateLabel,
    deactivateLabel,
    resetState,
  } = useLabelService();

  useEffect(() => {
    if (open) {
      setConfirmDelete(false);
      resetState();
    }
  }, [open]);

  const formSchema = Yup.object({
    type: Yup.object().nullable().required("Label type is required"),
    name: Yup.string()
      .min(2, "The label name is too short")
      .max(40, "The label name is too long")
      .required("Label name is required"),
    description: Yup.string()
      .min(10, "The label description is too short")
      .max(100, "The label description is too long")
      .required("Label description is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: type ?? label?.type ?? null,
      name: label?.name ?? "",
      description: label?.description ?? "",
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      let success;
      const hasNameChanged = formik.initialValues.name !== values.name;
      if (label?._id) {
        success = await updateLabel({ ...label, ...values }, hasNameChanged);
      } else {
        success = await createLabel(values);
        if (!!success && !!handleCreate) handleCreate(success);
      }

      if (success) {
        handleClose();
        formik.resetForm();
      }
    },
  });

  const handleDeactivateLabel = async () => {
    setConfirmDelete(false);
    const success = await deactivateLabel(label);

    if (success) handleClose();
  };

  const getDialogTitle = () => {
    if (confirmDelete) return "Delete Label";
    if (!label?._id) return "Create New Label";

    return "Update Label";
  };

  if (loading) {
    return (
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 12,
            }}
          >
            <CanveoCircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>
      <DialogTitle>{getDialogTitle()}</DialogTitle>
      <DialogContent>
        {confirmDelete && (
          <span>
            Are you sure you want to delete the label <b>{label?.name}</b>?
          </span>
        )}

        {!confirmDelete && (
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ my: 1 }}
            >
              {!!errorMessage && (
                <Grid item container xs={12} justifyContent="center">
                  <Box mb={2}>
                    <Typography color="error">{errorMessage}</Typography>
                  </Box>
                </Grid>
              )}
              {!formik.initialValues.type && (
                <Grid item container xs={12}>
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={options}
                    value={formik.values.type}
                    autoHighlight
                    disabled={!!label?.type || !!type}
                    onChange={(e, value) => formik.setFieldValue("type", value)}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label={"Label Type"}
                        variant="outlined"
                        error={!!(formik.touched.type && formik.errors.type)}
                        helperText={
                          formik.touched.type && formik.errors.type
                            ? formik.errors.type
                            : ""
                        }
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item container xs={12}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  id="name"
                  label="Label Name"
                  name="name"
                  {...formik.getFieldProps("name")}
                  error={!!(formik.touched.name && formik.errors.name)}
                  helperText={
                    formik.touched.name && formik.errors.name
                      ? formik.errors.name
                      : ""
                  }
                />
              </Grid>
              <Grid item container xs={12}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={3}
                  id="name"
                  label="Label Description"
                  name="name"
                  {...formik.getFieldProps("description")}
                  error={
                    !!(formik.touched.description && formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                      ? formik.errors.description
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ marginRight: "auto" }}
          onClick={() =>
            confirmDelete ? setConfirmDelete(false) : handleClose()
          }
        >
          Cancel
        </Button>
        {confirmDelete && (
          <Button color="secondary" onClick={handleDeactivateLabel}>
            Confirm
          </Button>
        )}
        {!confirmDelete && (
          <>
            {!label && (
              <Button
                color="primary"
                disabled={!formik.dirty}
                onClick={() => formik.resetForm()}
              >
                Reset
              </Button>
            )}
            {label?._id && (
              <Button color="secondary" onClick={() => setConfirmDelete(true)}>
                <FontAwesomeIcon icon={faTrashXmark} />
                &nbsp; Delete
              </Button>
            )}
            <Button
              color="primary"
              disabled={!formik.dirty}
              onClick={formik.handleSubmit}
            >
              <FontAwesomeIcon icon={faPen} />
              &nbsp;&nbsp;
              {label?._id ? "Update" : "Create"}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default DialogLabel;
