import { $createParagraphNode, $createTextNode } from "lexical";
import {
  applyParagraphFormatting,
  applySimpleFormatting,
  parseTextNodeFormat,
} from "../../converters/utils/text";
import { $isCustomListNode } from "../../nodes/CustomListNode";
import { PREVENT_EVENT_PROPAGATION } from "../TrackChangesPlugin/utils";
import { GlobalListHandler } from "./GlobalListHandler";
/**
 *
 * @param {import("../../types/sfdt").AbstractListLevel} targetListLevel
 * @param {string} paddingStyles
 * @param {boolean} isFallback
 * @param {boolean} [shouldInherit]
 * @param {import("../../types/sfdt").Block} [block]
 * @returns
 */
export const getNonThemePaddingStyles = (
  targetListLevel,
  paddingStyles,
  isFallback = false,
  shouldInherit = true,
  block = undefined
) => {
  const listHandler = GlobalListHandler.getInstance();
  let p = paddingStyles,
    pLeft = 0,
    pRight = 0;
  //gets styles from list level
  const { left, right } = getIndentationStyles(
    block,
    undefined,
    targetListLevel
  );
  pLeft += left ?? 0;
  pRight += right ?? 0;
  if (targetListLevel.blockPaddingStyles && shouldInherit) {
    //if contains block padding styles we'll use them too
    const options = targetListLevel.blockPaddingStyles.split(";");
    options.forEach((opt) => {
      // @ts-ignore
      const match = opt.match(/padding-(?<type>\w+):\s*(?<value>\d+)(px|pt)/);
      if (match && match.groups?.type && match.groups?.value) {
        //found type and value
        if (match.groups.type === "left") {
          pLeft += parseInt(match.groups.value);
        } else if (match.groups?.type === "right") {
          pRight += parseInt(match.groups.value);
        }
      }
    });
  }

  if (pLeft && pLeft > 0) {
    const val = isFallback
      ? pLeft + listHandler.currentSfdt.defaultTabWidth
      : pLeft;
    p += `padding-left: ${val}px;`;
  }
  if (pRight && pRight > 0) {
    const val = isFallback
      ? pRight + listHandler.currentSfdt.defaultTabWidth
      : pRight;
    p += `padding-right: ${val}px;`;
  }
  return p;
};

/**
 * @deprecated
 * @param {import("lexical").LexicalNode} node
 */
export const updateNodeStyling = (node) => {
  const listHandler = GlobalListHandler.getInstance();
  const currentItem = listHandler.root.find((it) => it.id === node.getKey());
  if (currentItem) {
    const list =
      listHandler.lists[currentItem.listId].levels[currentItem.level];
    if (list) {
      //does list have styles?
      if (list.blockStyles) {
        node.setBlockStyles(list.blockStyles);
      }
    } else {
      //we need to look for styleName styles
    }
  }
};

/**
 *
 * @param {import("../../types/sfdt").Block} block
 * @param {import("../../types/sfdt").styles | undefined} style
 * @returns
 */
export const getListLevelNumber = (block, style) => {
  const blockListLevelNumber =
      block.paragraphFormat?.listFormat?.listLevelNumber,
    styleListFormat = style?.paragraphFormat?.listFormat;
  if (blockListLevelNumber !== undefined) {
    return blockListLevelNumber;
  } else if (styleListFormat) {
    if (styleListFormat.listLevelNumber !== undefined) {
      return styleListFormat.listLevelNumber;
    } else {
      return 0;
    }
  } else {
    return undefined;
  }
};

/**
 *
 * @param {import("../../types/sfdt").Block} [block]
 * @param {import("../../types/sfdt").styles} [style]
 * @param {import("../../types/sfdt").AbstractListLevel} [absListLevel]
 * @returns {{left: number|undefined, right: number|undefined}}
 */
export const getIndentationStyles = (block, style, absListLevel) => {
  let left = 0,
    right = 0;
  /**
   * Text indentation is retrieved from block level
   * paragraphFormat > leftIndent
   * Default representation is in pt although we need to append "pt"
   * when applying on lexical
   */
  if (
    block?.paragraphFormat?.leftIndent ||
    block?.paragraphFormat?.firstLineIndent ||
    style?.paragraphFormat?.firstLineIndent ||
    style?.paragraphFormat?.leftIndent ||
    absListLevel?.paragraphFormat?.firstLineIndent ||
    absListLevel?.paragraphFormat?.leftIndent
  ) {
    //style overrides everything
    if (
      style?.paragraphFormat?.firstLineIndent ||
      style?.paragraphFormat?.leftIndent
    ) {
      left +=
        (style?.paragraphFormat?.leftIndent ?? 0) +
        (style?.paragraphFormat?.firstLineIndent ?? 0);
    } else {
      //get block indent
      if (
        block?.paragraphFormat?.firstLineIndent ||
        block?.paragraphFormat?.leftIndent
      ) {
        left +=
          (block.paragraphFormat?.firstLineIndent ?? 0) +
          (block.paragraphFormat?.leftIndent ?? 0);
      }
      // Get level indent.
      if (
        absListLevel?.paragraphFormat?.firstLineIndent ||
        absListLevel?.paragraphFormat?.leftIndent
      ) {
        left +=
          (absListLevel?.paragraphFormat?.firstLineIndent ?? 0) +
          (absListLevel?.paragraphFormat?.leftIndent ?? 0);
      }
    }
  } else if (
    block?.paragraphFormat?.rightIndent ||
    style?.paragraphFormat?.rightIndent ||
    absListLevel?.paragraphFormat?.rightIndent
  ) {
    if (block?.paragraphFormat?.rightIndent) {
      right += block.paragraphFormat?.rightIndent;
    }
    if (absListLevel?.paragraphFormat?.rightIndent) {
      right += absListLevel?.paragraphFormat?.rightIndent;
    }
    if (style?.paragraphFormat?.rightIndent) {
      right += style?.paragraphFormat?.rightIndent;
    }
  } else {
    return { left: undefined, right: undefined };
  }
  return { left, right };
};

/**
 * @param {import("../../types/sfdt").AbstractListLevel} abslistlevel
 */
export const getMarkerStyles = (abslistlevel) => {
  // if (!abslistlevel.characterFormat) return "";
  const tmpTextNode = $createTextNode(" ");
  applySimpleFormatting(abslistlevel, tmpTextNode);
  applyParagraphFormatting(abslistlevel, abslistlevel, tmpTextNode, null);
  return `${tmpTextNode.getStyle()}${parseTextNodeFormat(tmpTextNode)}`;
};

/** @param {import("lexical").ElementNode | import("lexical").TextNode} listItem*/
export const customGetTopListNode = (listItem) => {
  let list = listItem.getParent();

  if (!$isCustomListNode(list)) {
    throw new Error("A ListItemNode must have a ListNode for a parent.");
  }

  /** @type {import("@lexical/list").ListNode | null} */
  let parent = list;

  while (parent !== null) {
    parent = parent.getParent();

    if ($isCustomListNode(parent)) {
      list = parent;
    }
  }

  return list;
};

/**
 * Searches for style name associated with the level and listId
 *
 * @param {import("../../nodes").CustomListItemNode} listItemNode
 * @returns {string|undefined}
 */
const getNewStyleName = (listItemNode) => {
  if (!listItemNode) return;
  const listHandler = GlobalListHandler.getInstance();
  const current = listHandler.root.find(
    (el) => el.id === listItemNode.getKey()
  );
  if (!current) return;
  const themeStyle = listHandler.currentSfdt.styles.find(
    (style) =>
      style.paragraphFormat &&
      style.paragraphFormat.listFormat?.listId === current.listId &&
      style.paragraphFormat.listFormat.listLevelNumber === current.level
  );
  if (!themeStyle) return;
  return themeStyle.name;
};

/**
 * @param {import("../../nodes").CustomListItemNode} listItemNode
 * @param {import("lexical").LexicalEditor} editor
 */
export const updateStyleName = (listItemNode, editor) => {
  const styleName = getNewStyleName(listItemNode);
  if (styleName) {
    if (styleName !== listItemNode.styleName) {
      listItemNode.styleName = styleName;
      if (styleName.startsWith("Heading")) {
        // const hTag = supportedHeadingsMap.get(styleName);
        // const firstChild = listItemNode.getFirstChild();
        //TODO: change tags if need be
      }
    }
  } else {
    listItemNode.styleName = "";
  }
};

/**
 * Creates new paragraph on current clause
 *
 * @param {import("../../nodes").ClauseNode} currentClause
 * @returns
 */
export const createNewParagraph = (currentClause) => {
  const newParagraph = $createParagraphNode();
  currentClause.append(newParagraph);
  newParagraph.select();
  return PREVENT_EVENT_PROPAGATION;
};

/**
 * Inserts new pseudo item on listhandler
 *
 * @param {number} indexOfCurrentItem
 * @param {string} id
 * @param {PseudoListItem} current
 */
export const createNewPseudoItem = (indexOfCurrentItem, id, current) => {
  const listHandler = GlobalListHandler.getInstance();
  listHandler.root.splice(indexOfCurrentItem + 1, 0, {
    id: id,
    level: current.level,
    value: current.value + 1,
    parentId: current.parentId,
    listId: current.listId,
  });
  const newItem = listHandler.root[indexOfCurrentItem + 1];
  //TODO: This does not cover all nested
  //this should update parentIds
  const itemsToUpdate = listHandler.root.filter((it) => id === it.parentId);
  itemsToUpdate.forEach((item) => {
    if (item.level === newItem.level + 1) item.parentId = newItem.id;
    else item.parentId = "";
  });
  return newItem;
};
