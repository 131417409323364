import { $getNearestNodeOfType } from "@lexical/utils";
import {
  $getSelection,
  $isElementNode,
  $isLineBreakNode,
  $isRangeSelection,
  $isTextNode,
} from "lexical";
import {
  ClauseNode,
  CustomListItemNode,
  CustomTableCellNode,
} from "../../../../nodes";
import { $isClauseNode } from "../../../../nodes/ClauseNode";
import { handleSelectionDelete } from "../../../../utils/handleSelectionDelete";
import { selectionIsAtBeginningOfInline } from "../../../CanveoPlugin/utils/selectionIsAtBeginningOfInline";
import { selectionIsAtEndOfInline } from "../../../CanveoPlugin/utils/selectionIsAtEndOfInline";
import {
  PREVENT_EVENT_PROPAGATION,
  getTargetNodeFromSplitNodes,
  handleNodeDeletion,
} from "../../utils";
import { copyClauseWorkflows } from "../utils/copyClauseWorkflows";
import { getAppendableClauseChildNodes } from "../utils/getAppendableClauseChildNodes";
import { preventEventPropagation } from "../utils/preventEventPropagation";
import { propagateEvent } from "../utils/propagateEvent";

/**
 * @param {KeyboardEvent} event
 * @param {RedlineData} defaultRedlineData
 * @returns {boolean}
 */
export function deleteCommandHandler(event, defaultRedlineData) {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return preventEventPropagation(event);
  }

  if (selection.isCollapsed()) {
    // Handle line break nodes.
    const selectionNodes = selection.getNodes();
    const [firstNode] = selectionNodes;
    const selectedNode = firstNode.getNextSibling();
    if (selectionNodes.length === 1 && $isLineBreakNode(selectedNode)) {
      const previousSibling = selectedNode.getPreviousSibling();
      previousSibling?.selectPrevious();
      selectedNode.remove();

      if (event) event.preventDefault();
      return PREVENT_EVENT_PROPAGATION;
    }

    const node = (
      selection.isBackward() ? selection.focus : selection.anchor
    ).getNode();
    const clauseNode = $getNearestNodeOfType(node, ClauseNode);

    // If the selection is inside a list node and at the end of the inline we let the
    // default list plugin handle the event.
    const selectionCustomListItemNode = $getNearestNodeOfType(
      node,
      CustomListItemNode
    );
    if (selectionCustomListItemNode && selectionIsAtEndOfInline(selection)) {
      // Copy workflows from the next clause (if it exists) because it is going to be merged
      // with the current clause by the default list plugin.
      if (clauseNode) {
        const nextClause = clauseNode.getNextSibling();
        if ($isClauseNode(nextClause)) {
          copyClauseWorkflows(nextClause, clauseNode);
        }
      }

      return propagateEvent();
    }

    // If the selection is inside a table cell that means we do not have to do the clause
    // handling logic so we can just propagate the event and let the appropriate plugins
    // handle the enter behaviour.
    const selectionTableCell = $getNearestNodeOfType(node, CustomTableCellNode);
    if (selectionTableCell && selectionIsAtEndOfInline(selection)) {
      return propagateEvent();
    }

    if (clauseNode) {
      const textContentSize = clauseNode.getTextContentSize();
      const selectionAtEnd = selectionIsAtEndOfInline(selection);

      const selectionTableCell = $getNearestNodeOfType(
        node,
        CustomTableCellNode
      );
      if (selectionAtEnd && selectionTableCell) {
        return preventEventPropagation(event);
      }

      // If there is not any text content and the selection is at the beginning
      // or if there is text content and the selection is at the end.
      if (
        (textContentSize === 0 && selectionIsAtBeginningOfInline(selection)) ||
        (textContentSize > 0 && selectionAtEnd)
      ) {
        const nextClause = clauseNode.getNextSibling();
        if ($isClauseNode(nextClause)) {
          // If both the current clause and the previous are not empty.
          if (
            clauseNode.getTextContentSize() > 0 &&
            nextClause.getTextContentSize() > 0
          ) {
            const nodes = getAppendableClauseChildNodes(nextClause);
            copyClauseWorkflows(nextClause, clauseNode);
            nextClause.remove();

            const elementNode = clauseNode.getLastChild();
            if ($isElementNode(elementNode)) {
              elementNode.append(...nodes);
            }
          }
          // If the next clause is empty we remove it.
          else if (nextClause.getTextContentSize() === 0) {
            nextClause.remove();
          }
          // Otherwise, we select its end and if the current clause is empty, we delete it.
          else {
            if (clauseNode.getTextContentSize() === 0) {
              nextClause.selectStart();
              clauseNode.remove();
            }
          }
        }
      } else {
        const anchorNodeOffset = selection.anchor.offset;
        const focusNodeOffset = selection.focus.offset;

        selection.modify("extend", false, "character");

        if (
          selection.anchor.type === "element" ||
          selection.focus.type === "element"
        ) {
          selection.modify("move", false, "character");
        } else {
          const nodes = selection.getNodes();
          let nodeToSelect;

          for (let index = 0; index < nodes.length; index++) {
            let node = nodes[index];

            if ($isTextNode(node)) {
              if (nodes.length === 1) {
                const splitNodes = node.splitText(
                  ...selection.getCharacterOffsets()
                );

                const targetNode = getTargetNodeFromSplitNodes(
                  false,
                  splitNodes
                );

                if (targetNode) {
                  const handledNode = handleNodeDeletion(
                    targetNode,
                    defaultRedlineData
                  );

                  if (handledNode) {
                    handledNode.select();
                  } else {
                    const updatedSelection = $getSelection();
                    if ($isRangeSelection(updatedSelection)) {
                      const focusNode = updatedSelection.focus.getNode();

                      if (anchorNodeOffset === 0 && focusNodeOffset === 0) {
                        focusNode.select(0, 0);
                      } else {
                        focusNode.select();
                      }
                    }
                  }
                }

                break;
              }

              const atBeginningOfArray = index === 0;
              const atEndOfArray = index === nodes.length - 1;

              if (atBeginningOfArray) {
                const nodes = node.splitText(selection.anchor.offset);
                if (nodes.length === 1) {
                  [node] = nodes;
                } else {
                  [, node] = nodes;
                }
              } else if (atEndOfArray) {
                [node] = node.splitText(selection.focus.offset);
              }

              const handledNode = handleNodeDeletion(node, defaultRedlineData);

              // We want to bring the cursor to the end of the line we've just deleted, so we select the last node.
              if (atEndOfArray && $isTextNode(handledNode)) {
                nodeToSelect = handledNode;
              }
            }
          }

          if (nodes.length > 1) {
            if (nodeToSelect) {
              nodeToSelect.select();
            } else {
              // If our selection end node is a proposed addition from the author it is removed so we lose the node to
              // select, so we must fetch a new selection and select again.
              const updatedSelection = $getSelection();
              if ($isRangeSelection(updatedSelection)) {
                updatedSelection.focus.getNode().select();
              }
            }
          }
        }
      }
    }
  } else {
    handleSelectionDelete(selection, defaultRedlineData);
  }

  return preventEventPropagation(event);
}
