import { Box, Grid, useMediaQuery } from "@mui/material";
import React from "react";
import theme from "../../../theme/theme";
import { UserForm } from "../../forms";

/**
 * @typedef {object} CreateUserSectionProps
 * @property {(params: *) => void} handleSubmit
 * @property {import("../../forms/UserForm").UserFormData} [user]
 */

/**
 * @param {CreateUserSectionProps} props
 * @returns {JSX.Element}
 */
export default function CreateUserSection({ handleSubmit, user }) {
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box sx={{ px: isSmUp ? 3 : 0 }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item sx={{ mt: 1 }}>
          <UserForm
            handleSubmit={handleSubmit}
            hideFields={["phone", "userRole"]}
            disableFields={user ? ["email"] : []}
            initialValues={{
              title: user?.title ?? "",
              email: user?.email ?? "",
              firstName: user?.firstName ?? "",
              lastName: user?.lastName ?? "",
              phone: user?.phone ?? "",
              role: "Counterparty",
              readOnly: false,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
