import { ElementNode } from "lexical";

/**
 * @typedef {object} SectionNodeMetadata
 * @property {* | {}} headersFooters
 * @property {SectionFormat} sectionFormat
 */

/**
 * @typedef {object} SectionFormat
 * @property {number} headerDistance
 * @property {number} footerDistance
 * @property {number} pageWidth
 * @property {number} pageHeight
 * @property {number} leftMargin
 * @property {number} rightMargin
 * @property {number} topMargin
 * @property {number} bottomMargin
 * @property {boolean} differentFirstPage
 * @property {boolean} differentOddAndEvenPages
 * @property {boolean} bidi
 * @property {boolean} restartPageNumbering
 * @property {number} pageStartingNumber
 * @property {string} endnoteNumberFormat
 * @property {string} footNoteNumberFormat
 * @property {string} restartIndexForFootnotes
 * @property {string} restartIndexForEndnotes
 * @property {string} pageNumberStyle
 * @property {string} breakCode
 */

/**
 * @typedef {ReturnType<SectionNode["exportJSON"]>} SerializedSectionNode
 */

export class SectionNode extends ElementNode {
  /** @private @type {SectionNodeMetadata} Object containing Syncfusion section metadata. */ __metadata;

  /**
   * @param {SectionNodeMetadata} metadata
   * @param {string | undefined} [key]
   */
  constructor(metadata, key) {
    super(key);
    this.__metadata = metadata;
  }

  /**
   * @returns {'section'}
   */
  static getType() {
    return "section";
  }

  /**
   * @param {SectionNode} node
   * @returns {SectionNode}
   */
  static clone(node) {
    const clonedSectionNode = new SectionNode(node.__metadata, node.__key);
    return clonedSectionNode;
  }

  /**
   * @param {SerializedSectionNode} serializedNode
   * @returns {SectionNode}
   */
  static importJSON(serializedNode) {
    const node = $createSectionNode(serializedNode);
    return node;
  }

  exportJSON() {
    const serializedSectionNode = {
      ...super.exportJSON(),
      metadata: this.getMetadata(),
      type: "section",
      version: 1,
    };
    return serializedSectionNode;
  }

  /**
   * @param {import("lexical").LexicalEditor} editor
   * @returns {import("lexical").DOMExportOutput}
   */
  exportDOM(editor) {
    const domExportOutput = super.exportDOM(editor);
    return domExportOutput;
  }

  /**
   * @returns {HTMLElement}
   */
  createDOM() {
    const sectionHtmlElement = document.createElement("section");
    return sectionHtmlElement;
  }

  /**
   * @param {SectionNode} prevNode
   * @param {HTMLElement} dom
   * @returns {boolean}
   */
  updateDOM(prevNode, dom) {
    // Returning false tells Lexical that this node does not need its
    // DOM element replaced with a new copy from createDOM.
    return false;
  }

  /**
   * @returns {SectionNodeMetadata}
   */
  getMetadata() {
    const self = this.getLatest();
    return self.__metadata;
  }
}

/**
 * @param {{ metadata : SectionNodeMetadata}} props
 * @returns {SectionNode}
 */
export function $createSectionNode({ metadata }) {
  const sectionNode = new SectionNode(metadata);
  return sectionNode;
}

/**
 * @param {import("lexical").LexicalNode | null | undefined} node
 * @return {node is SectionNode}
 */
export function $isSectionNode(node) {
  const isInstanceOfSectionNode = node instanceof SectionNode;
  return isInstanceOfSectionNode;
}
