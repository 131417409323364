import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";
import FileConversionErrorDetails from "../FileConversionErrorDetails";

/**
 * @typedef {object} DialogFileConversionErrorDetailsProps
 * @property {() => void} close
 * @property {Error} conversionError
 */

/**
 * @param {DialogFileConversionErrorDetailsProps} props
 * @returns {React.JSX.Element}
 */
export default function DialogFileConversionErrorDetails({
  conversionError,
  close,
}) {
  return (
    <Dialog
      open={Boolean(conversionError)}
      onClose={close}
      fullWidth
      maxWidth="sm"
    >
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>Upload Error Details</DialogTitle>

      <DialogContent sx={{ paddingX: 10 }}>
        <FileConversionErrorDetails conversionError={conversionError} />
      </DialogContent>

      <DialogActions>
        <Button sx={{ marginRight: "auto" }} onClick={close}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
