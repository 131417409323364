import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ParagraphNode } from "lexical";
import {
  ClauseNode,
  CrossRefNode,
  CustomTableCellNode,
  CustomTableNode,
  CustomTableRowNode,
  MarkNode,
  RedlineNode,
} from "../nodes";
import { CaptionNode } from "../nodes/CaptionNode";
import { CustomHeadingNode } from "../nodes/CustomHeadingNode";
import { CustomListItemNode } from "../nodes/CustomListItemNode";
import { CustomListNode } from "../nodes/CustomListNode";
import { CustomParagraphNode } from "../nodes/CustomParagraphNode";
import { ImageNode } from "../nodes/ImageNode";
import { SectionNode } from "../nodes/SectionNode";
import { TocElementNode } from "../nodes/TableOfContentsElementNode";

/** @type {import("lexical").EditorThemeClasses} */
const editorTheme = {
  ltr: "ltr",
  rtl: "rtl",
  placeholder: "editor-placeholder",
  paragraph: "editor-paragraph",
  quote: "editor-quote",
  heading: {
    h1: "editor-heading-h1",
    h2: "editor-heading-h2",
    h3: "editor-heading-h3",
  },
  list: {
    /** @ts-ignore */
    listitemnomarker: "listItemNoMarker",
    listitem: "listItem",
    listitemChecked: "listItemChecked",
    listitemUnchecked: "listItemUnchecked",
    nested: {
      listitem: "nestedListItem",
    },
    olDepth: ["ol1", "ol2", "ol3", "ol4", "ol5", "ol6", "ol7", "ol8", "ol9"],
    ul: "ul",
  },
  image: "editor-image",
  link: "editor-link",
  text: {
    bold: "editor-text-bold",
    italic: "editor-text-italic",
    // @ts-ignore
    overflowed: "editor-text-overflowed",
    hashtag: "editor-text-hashtag",
    underline: "editor-text-underline",
    strikethrough: "editor-text-strikethrough",
    underlineStrikethrough: "editor-text-underlineStrikethrough",
    code: "editor-text-code",
  },
  table: "editor-table",
  caption: "editor-caption",
  tab: "tab",
  crossRef: "cross-ref",
};

/**
 * @type {import("lexical").CreateEditorArgs}
 */
export const editorConfig = {
  theme: editorTheme, // The editor theme
  onError: (/** @type {Error} */ error) =>
    console.error("Lexical Error: ", error),
  nodes: [
    SectionNode,
    CustomHeadingNode,
    {
      replace: HeadingNode,
      with: (/** @type {HeadingNode} */ node) => {
        return new CustomHeadingNode(node.__tag);
      },
    },
    ListNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
    ClauseNode,
    RedlineNode,
    MarkNode,
    CustomListNode,
    CustomListItemNode,
    {
      replace: ListItemNode,
      with: (/** @type {ListItemNode} */ node) => {
        return new CustomListItemNode(node.value, node.checked, node.key);
      },
    },
    CustomTableNode,
    CustomTableCellNode,
    CustomTableRowNode,
    CustomParagraphNode,
    {
      replace: ParagraphNode,
      with: (/** @type {ParagraphNode} */ _node) => {
        return new CustomParagraphNode();
      },
    },
    CaptionNode,
    ImageNode,
    CrossRefNode,
    TocElementNode,
  ],
};
