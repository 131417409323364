/**
 *
 * @param {*} value number > 0
 * @returns value formatted to be within 1 and 28,
 * if greater will subtract 28 a necessary number of times to circle around,
 * if value is 0 returns undefined
 */
export function normalizeValue(value) {
  if (value <= 0) return;
  let x = value,
    y = Math.floor(value / 28);
  for (let i = 0; i < y; i++) {
    if (value > 28) x -= 28;
  }
  if (value % 28 === 0 && value > 28) return 28;
  return x;
}
