import {
  faCheckCircle,
  faCircleLeft,
  faCircleRight,
  faEllipsisV,
  faFileImport,
  // faRefresh,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $dfs, $getNearestNodeOfType } from "@lexical/utils";
import { Grid, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import axios from "axios";
import {
  $createRangeSelection,
  $createTextNode,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  $setSelection,
  ElementNode,
} from "lexical";
import React, { useContext, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { dateTime } from "../utils/constants";
import { getCanveoTier } from "../utils/getCanveoTier";
import DialogRemoveMergeField from "./dialogs/DialogRemoveMergeField";
import { $isClauseNode, ClauseNode } from "./editor/nodes/ClauseNode";
import { $isMarkNode } from "./editor/nodes/MarkNode";
import { $createRedlineNode, $isRedlineNode } from "./editor/nodes/RedlineNode";
import ClauseTypesMenu from "./editor/plugins/ClauseOptionsPlugin/ClauseTypesMenu";
import VariantsMenu from "./editor/plugins/ClauseOptionsPlugin/VariantsMenu";
import { $getOpenIssuesList } from "./editor/plugins/OpenIssuesPlugin";
import OpenIssueCommentMenu from "./editor/plugins/OpenIssuesPlugin/OpenIssueCommentMenu";

const styles = {
  iconB: {
    color: theme.palette.grey[700],
    fontSize: "22px",
  },
  iconBsuc: {
    color: theme.palette.success.main,
    fontSize: "22px",
  },
  iconBerr: {
    color: theme.palette.error.main,
    fontSize: "22px",
  },
};

const openIssuesLabelsMap = new Map([
  ["mergeField", "Merge Field"],
  ["both", "Merge field with comment"],
  ["publicComment", "Comment"],
  ["internalComment", "Internal Comment"],
  ["approvalRequest", "Approval Request"],
  ["add", "Add"],
  ["xadd", "Reject Add"],
  ["del", "Delete"],
  ["xdel", "Reject Delete"],
  ["info", "Clause actions"],
]);

/**
 * @typedef {object} OpenIssueMenuProps
 * @property {PartyId} partyId
 * @property {{ id: string; type: "text" | "navigation"} | null} selectedOpenIssue
 * @property {boolean} showOpenIssueMenu
 * @property {(mergeFieldId: string) => void} deleteMergeField
 * @property {string} docID
 * @property {boolean} isTemplate
 * @property {*} agreementVersion
 * @property {boolean} isAgreementOwner
 */

/**
 * @param {OpenIssueMenuProps} props
 * @returns {JSX.Element}
 */
export default function OpenIssueMenu(props) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();

  const canveoTier = getCanveoTier(state?.user?.email);

  const {
    selectedOpenIssue,
    showOpenIssueMenu,
    isAgreementOwner,
    // deleteMergeField
  } = props;

  const [currentOpenIssue, setCurrentOpenIssue] = useState(
    /** @type {import("./editor/plugins/OpenIssuesPlugin").OpenIssue | undefined} */ (
      undefined
    )
  );

  const [openIssueMergeField, setOpenIssueMergeField] = useState(
    /** @type {import("./editor/nodes/MarkNode").MergeField | undefined} */ (
      undefined
    )
  );

  const { $navigateToNextOpenIssue } = useOpenIssues({
    editor,
    state,
    dispatch,
    currentOpenIssue,
  });

  const [openDeleteMergeFieldDialog, setOpenDeleteMergeFieldDialog] =
    useState(false);
  const [isInEffect, setIsInEffect] = useState(false);
  const closeDeleteMergeFieldDialog = () => {
    setOpenDeleteMergeFieldDialog(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // const handleRefreshMergeFieldClick = (/** @type {*} **/ event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElement, setAnchorElement] = React.useState(null);
  const openOperationsMenu = Boolean(anchorElement);
  const handleMergeFieldOperationsMenuClick = (/** @type {*} */ event) => {
    setAnchorElement(event.currentTarget);
  };
  const handleMergeFieldOperationsMenuClose = () => {
    setAnchorElement(null);
  };

  const mainAg = state.agrs.find(
    (/** @type {{ parentID: string; }} */ a) => !a.parentID
  );
  const isAgrExec = Boolean(state.agrExec) && Boolean(state.agrExec._id);
  const /** @type {boolean} */ isCurrentVersionOwner =
      !isAgrExec &&
      (props.isTemplate ||
        (!props.isTemplate &&
          Boolean(mainAg) &&
          mainAg.avOwners.some(
            (/** @type {string} */ owner) => owner === state.org._id
          )));

  const notVersionOwner =
    // isAgreementOwner &&
    !isCurrentVersionOwner &&
    !isAgrExec &&
    !["Execution", "InEffect"].includes(mainAg?.agrStatus);

  // This hook is triggered when the open issue is changed outside of this component.
  useEffect(() => {
    // If we have deselected an open issue (e.g., by clicking somewhere on the text where
    // there is no open issue) and there is an open issue selected at the moment.
    if (!selectedOpenIssue && currentOpenIssue) {
      setCurrentOpenIssue(undefined);
      return;
    }
    // We only want to update the selected open issue if the new open issue is different
    // from the one that is currently selected.
    if (selectedOpenIssue) {
      editor.update(() => {
        const openIssues = $getOpenIssuesList(
          editor,
          state.workflows,
          state.org._id
        );
        const openIssue = openIssues.find(
          (openIssue) =>
            openIssue.id === selectedOpenIssue.id ||
            // In case we are searching with the Merge Field ID.
            openIssue.mergeField?._id === selectedOpenIssue.id
        );

        // If we could not find the open issue the first time we try again
        // with the Merge Field object in case the ID is the Merge Field ID.
        if (!openIssue) {
          openIssues.find(
            (openIssue) => openIssue.mergeField?._id === selectedOpenIssue.id
          );
        }

        if (currentOpenIssue && $isClauseNode(currentOpenIssue.node)) {
          currentOpenIssue.node.changeFilter("none");
        }

        if (openIssue) {
          if (selectedOpenIssue.type === "navigation") {
            $wrapSelectionAroundOpenIssue(openIssue);
          }
          setCurrentOpenIssue(openIssue);
        } else {
          setCurrentOpenIssue(undefined);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOpenIssue]);

  useEffect(() => {
    let isInEffect;
    if (props.isTemplate) {
      const mainTemp = state.template.find((/** @type {*} */ t) => !t.parentID);
      isInEffect = mainTemp.active;
    } else {
      const mainAg = state.agrs.find((/** @type {*} */ agr) => !agr.parentID);
      isInEffect = ["InEffect"].includes(mainAg.agrStatus);
    }

    setIsInEffect(isInEffect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isTemplate, state.drawerVersions?.active?._id]);

  /**
   * @param {import("./editor/plugins/OpenIssuesPlugin").OpenIssue} openIssue
   */
  const $wrapSelectionAroundOpenIssue = (openIssue) => {
    const { node } = openIssue;

    if (
      $isClauseNode(node) &&
      (node
        .getWorkflows()
        .some(
          (workflow) => workflow.startsWith("cp") || workflow.startsWith("ap")
        ) ||
        node.getClauseTypes().length)
    ) {
      state.scroller.toElement(editor.getElementByKey(node.getKey()));

      return;
    }

    if ($isRedlineNode(node)) {
      const newSelection = node.select(0, node.getTextContentSize());
      state.scroller.toElement(editor.getElementByKey(newSelection.anchor.key));
      return;
    }

    if ($isMarkNode(node)) {
      const textNodes = node.getAllTextNodes();
      const firstTextNode = textNodes[0];
      const lastTextNode = textNodes[textNodes.length - 1];
      const rangeSelection = $createRangeSelection();

      rangeSelection.anchor.set(firstTextNode.getKey(), 0, "text");

      rangeSelection.focus.set(
        lastTextNode.getKey(),
        lastTextNode.getTextContentSize(),
        "text"
      );

      $setSelection(rangeSelection);

      state.scroller.toElement(editor.getElementByKey(firstTextNode.getKey()));
      return;
    }
  };

  const handleNextClick = () => {
    editor.update(() => {
      $navigateToNextOpenIssue();
    });
  };

  const $navigateToPreviousOpenIssue = () => {
    const openIssues = $getOpenIssuesList(
      editor,
      state.workflows,
      state.org._id
    );

    if (!openIssues.length) {
      dispatch({
        type: "NEW_OPEN_ISSUE_SELECTION",
        payload: { id: null, type: null, status: null },
      });
      return;
    }

    const reversedOpenIssues = [...openIssues].reverse();

    let selectedOpenIssueIndex;

    // If we have previously selected an open issue then we just need to find its index
    // within the list of existing open issues to find the next one.
    if (currentOpenIssue) {
      const selectedOpenIssueId = currentOpenIssue.id;

      selectedOpenIssueIndex = reversedOpenIssues.findIndex(
        (openIssue) => openIssue.id === selectedOpenIssueId
      );
    }
    // Otherwise, we get the current selection node and see what is the nearest open issue
    // (i.e, a node which is either a clause, a mark or a redline node).
    else {
      const selection = $getSelection();

      if (!$isRangeSelection(selection)) return;

      const selectedNode = selection.anchor.getNode();

      const reverseDepthFirstSearchItems = [...$dfs()].reverse();

      const selectedItemIndex = reverseDepthFirstSearchItems.findIndex(
        (item) => item.node.getKey() === selectedNode.getKey()
      );

      const depthFirstSearchFromSelectedNodeItems =
        reverseDepthFirstSearchItems.slice(selectedItemIndex);

      const searchItem = depthFirstSearchFromSelectedNodeItems.find(
        ({ node }) =>
          ($isClauseNode(node) || $isMarkNode(node) || $isRedlineNode(node)) &&
          // TODO: OpenIssues needs to be a map where the key is its ID.
          reversedOpenIssues.find((oi) => oi.node.getKey() === node.getKey())
      );

      // If no node can be found it means that our selection is located after any
      // open issue which means we need to reset the navigation to the first open issue.
      if (searchItem) {
        selectedOpenIssueIndex = reversedOpenIssues.findIndex(
          (openIssue) => openIssue.node.getKey() === searchItem.node.getKey()
        );
      } else {
        selectedOpenIssueIndex = 0;
      }
    }

    let openIssueToSelectIndex = currentOpenIssue
      ? selectedOpenIssueIndex + 1
      : selectedOpenIssueIndex;

    // If we've reached the end of the open issues.
    if (openIssueToSelectIndex === reversedOpenIssues.length) {
      // We start from the beggining.
      openIssueToSelectIndex = 0;
    }

    let openIssueToSelect = reversedOpenIssues[openIssueToSelectIndex];

    let counter = reversedOpenIssues.length;

    // We want to skip merge fields that have not been marked as open issues
    // AKA not asked the counterparty to confirm.
    while (openIssueToSelect.skipNavigation) {
      counter--;
      // If we've went through all open issues and they're all skipabble we
      // just prevent any navigation.
      if (counter === 0) {
        return;
      }

      openIssueToSelectIndex++;
      // If we've reached the end of the open issues.
      if (openIssueToSelectIndex === reversedOpenIssues.length) {
        // We start from the beggining.
        openIssueToSelectIndex = 0;
      }
      openIssueToSelect = reversedOpenIssues[openIssueToSelectIndex];
    }

    // If the previous selected open issue was a clause node and we are now navigating
    // to an open issue that is not part of that clause, we need to unselect the clause.
    if (
      currentOpenIssue &&
      $isClauseNode(currentOpenIssue.node) &&
      openIssueToSelect.node.getKey() !== currentOpenIssue.node.getKey()
    ) {
      currentOpenIssue.node.changeFilter("none");
    }

    dispatch({
      type: "NEW_OPEN_ISSUE_SELECTION",
      payload: {
        id: openIssueToSelect.id,
        type: "navigation",
        status: "ongoing",
      },
    });
  };

  const handlePreviousClick = () => {
    editor.update(() => {
      $navigateToPreviousOpenIssue();
    });
  };

  /**
   * @param {"prev" | "next" | "accept" | "reject" | string} clickType
   */
  const handleClick = (clickType) => {
    editor.update(() => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return;

      const selectionFocusNode = selection.focus.getNode();
      const nearestElementNode = $getNearestNodeOfType(
        selectionFocusNode,
        ElementNode
      );

      if (["accept", "reject"].includes(clickType)) {
        $navigateToNextOpenIssue();

        const isBackward = selection.isBackward();

        if (selection.isCollapsed()) {
          const node = selection.anchor.getNode();
          selection.focus.offset = node.getTextContentSize();
          selection.anchor.offset = 0;
        }
        const startKey = isBackward
          ? selection.focus.key
          : selection.anchor.key;
        const startOffset = isBackward
          ? selection.focus.offset
          : selection.anchor.offset;
        const endKey = isBackward ? selection.anchor.key : selection.focus.key;
        const endOffset = isBackward
          ? selection.anchor.offset
          : selection.focus.offset;

        const nodes = selection.isCollapsed()
          ? [selection.anchor.getNode()]
          : selection.getNodes();
        nodes.forEach((node) => {
          if (["redline"].includes(node.getType())) {
            const coversEntireNode =
              (startKey !== node.getKey() || startOffset === 0) &&
              (endKey !== node.getKey() ||
                endOffset >= node.getTextContent().length);
            const selectionStartPos =
              coversEntireNode ||
              startKey !== node.getKey() ||
              startOffset === 0
                ? 0
                : startOffset;
            const selectionEndPos =
              coversEntireNode ||
              endKey !== node.getKey() ||
              endOffset >= node.getTextContent().length
                ? node.getTextContent().length
                : endOffset;
            const textAffected = node
              .getTextContent()
              .substring(selectionStartPos, selectionEndPos);
            const isTwoSided =
              selectionStartPos > 0 &&
              selectionEndPos < node.getTextContent().length;

            const isXadd =
              ["reject"].includes(clickType) &&
              ["add"].includes(node.getRedlineType()) &&
              node.getPartyID() !== props.partyId;
            const isXdel =
              ["reject"].includes(clickType) &&
              ["del"].includes(node.getRedlineType()) &&
              node.getPartyID() !== props.partyId;
            const isBackToAdd =
              ["reject"].includes(clickType) &&
              ["xadd"].includes(node.getRedlineType());
            const isBackToDel =
              ["reject"].includes(clickType) &&
              ["xdel"].includes(node.getRedlineType());

            if (
              // Accept cpty add OR Reject own del OR Accept xdel - Replace redline by a regular textNode
              (["accept"].includes(clickType) &&
                ["add"].includes(node.getRedlineType())) ||
              (["reject"].includes(clickType) &&
                ["del"].includes(node.getRedlineType()) &&
                node.getPartyID() === props.partyId) ||
              (["accept"].includes(clickType) &&
                ["xdel"].includes(node.getRedlineType()))
            ) {
              const newNode = $createTextNode(textAffected);
              newNode.setFormat(node.getFormat());
              if ($isTextNode(node)) {
                newNode.setStyle(node.getStyle());
              }
              overwriteRedlineWithNewNode(
                coversEntireNode,
                selectionStartPos,
                selectionEndPos,
                isTwoSided,
                node,
                newNode
              );
            } else if (
              // Accept cpty del OR Reject own add OR Accept xadd - Remove entire node
              (["accept"].includes(clickType) &&
                ["del"].includes(node.getRedlineType())) ||
              (["reject"].includes(clickType) &&
                ["add"].includes(node.getRedlineType()) &&
                node.getPartyID() === props.partyId) ||
              (["accept"].includes(clickType) &&
                ["xadd"].includes(node.getRedlineType()))
            ) {
              overwriteRedlineWithNewNode(
                coversEntireNode,
                selectionStartPos,
                selectionEndPos,
                isTwoSided,
                node,
                "remove"
              );
            } else if (
              isXadd || // Reject cpty add - replace redline to 'xadd'
              isXdel || // Reject cpty del - replace redline to 'xdel'
              isBackToAdd || // Reject 'xadd' - revert to regular add
              isBackToDel
            ) {
              // Reject 'xadd' - revert to regular add

              /** @type {RedlineType | null} */
              let redlineType = isXadd
                ? "xadd"
                : isXdel
                ? "xdel"
                : isBackToAdd
                ? "add"
                : isBackToDel
                ? "del"
                : null;

              if (!redlineType) throw new Error("Invalid redline type.");

              let partyID =
                isXadd || isXdel
                  ? node.getPartyID() + "_" + props.partyId
                  : isBackToAdd || isBackToDel
                  ? node.getPartyID().substr(0, node.getPartyID().indexOf("_"))
                  : null;

              const creationDate = new Date().toISOString();
              const newNode = $createRedlineNode({
                date: creationDate,
                metadata: {
                  partyId: partyID,
                  creatorDisplayName: state.user.displayName,
                  creatorId: state.user._id,
                  creationDate: new Date().toISOString(),
                  creatorEmail: state.user.email,
                  creatorPhotoUrl: state.user.photoURL,
                  revisionId: uuid(),
                },
                redlineType: redlineType,
                partyID: partyID,
                text: textAffected,
              });
              newNode.setFormat(node.getFormat());
              if ($isTextNode(node)) {
                newNode.setStyle(node.getStyle());
              }
              overwriteRedlineWithNewNode(
                coversEntireNode,
                selectionStartPos,
                selectionEndPos,
                isTwoSided,
                node,
                newNode
              );
            }
          }
        });
      }

      // If the nearest element node (e.g., paragraph, list item, table cell) does
      // not have any text content we delete it so that after removing text nodes
      // we are not left with empty element nodes.
      if (nearestElementNode?.getTextContentSize() === 0) {
        const nearestClauseNode = $getNearestNodeOfType(
          nearestElementNode,
          ClauseNode
        );

        // If the nearest element node has a clause we delete it since that also
        // deletes any element node that is its child.
        if (nearestClauseNode?.getTextContentSize() === 0) {
          nearestClauseNode.remove();
        } else {
          nearestElementNode.remove();
        }
      }
    });
  };

  /**
   * @param {*} coversEntireNode
   * @param {*} selectionStartPos
   * @param {*} selectionEndPos
   * @param {*} isTwoSided
   * @param {*} node
   * @param {*} newNode
   */
  const overwriteRedlineWithNewNode = (
    coversEntireNode,
    selectionStartPos,
    selectionEndPos,
    isTwoSided,
    node,
    newNode
  ) => {
    const previousFormat = node.getFormat();

    if (coversEntireNode && newNode === "remove") {
      node.remove(); // TODO => Merge before and after the node back into one another (if possible)
    } else if (coversEntireNode) {
      node.replace(newNode); // TODO => Instead of just replacing, should also merge back into the paragraph
    } else {
      let newRedlineNodeTextPt1 = node
        .getTextContent()
        .substring(0, selectionStartPos);
      let newRedlineNodeTextPt2 = node
        .getTextContent()
        .substring(selectionEndPos);
      const updatedRedlineNode = $createRedlineNode({
        date: node.getDate(),
        metadata: node.getMetadata(),
        redlineType: node.getRedlineType(),
        partyID: node.getPartyID(),
        text:
          newRedlineNodeTextPt1 +
          (isTwoSided && newNode !== "remove" ? "" : newRedlineNodeTextPt2),
      });
      updatedRedlineNode.setFormat(previousFormat);

      node.replace(updatedRedlineNode);

      if (newNode !== "remove") {
        const newSelection = $getSelection();
        if (!$isRangeSelection(newSelection)) {
          throw new Error("Selection is undefined.");
        }

        newSelection.anchor.key = updatedRedlineNode.getKey();
        newSelection.anchor.offset = selectionStartPos;
        newSelection.focus = newSelection.anchor;
        newSelection.insertNodes([newNode]);

        if (isTwoSided) {
          // If the selection was in the middle of the redline, you'd need to add the second part of the previous redline

          const additionalRedlineNode = $createRedlineNode({
            date: node.getDate(),
            metadata: node.getMetadata(),
            redlineType: node.getRedlineType(),
            partyID: node.getPartyID(),
            text: newRedlineNodeTextPt2,
          });
          additionalRedlineNode.setFormat(previousFormat);

          const newSelection = $getSelection();
          if (!$isRangeSelection(newSelection)) {
            throw new Error("Selection is undefined.");
          }

          newSelection.anchor.key = newNode.getKey();
          newSelection.anchor.offset = newNode.getTextContent().length;
          newSelection.focus = newSelection.anchor;
          newSelection.insertNodes([additionalRedlineNode]);
        }
      }
    }
  };

  const openIssueIsRedline = ["add", "xadd", "del", "xdel"].includes(
    currentOpenIssue?.openIssueType || ""
  );

  const openMergeFieldMenu = () => {
    dispatch({
      type: "OPEN_MERGE_FIELD_MENU",
      payload: currentOpenIssue?.mergeField,
    });
  };

  useEffect(
    () => {
      if (currentOpenIssue && currentOpenIssue.openIssueType === "mergeField") {
        axios
          .get(
            `${state.settings.api}mergefield/${currentOpenIssue.mergeField?._id}`
          )
          .then((response) => {
            const mergeField = response.data.data;
            setOpenIssueMergeField(mergeField);
          })
          .catch((error) => {
            if (
              error.response.status === 404 &&
              error.response.data.message === "Error: Merge Field not found."
            ) {
              dispatch({
                type: "NEW_SNACKBAR",
                payload: {
                  message: "Merge field no longer exists.",
                  severity: "error",
                },
              });
              return;
            }

            throw error;
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOpenIssue, state.settings.api]
  );

  /**
   * Evaluates whether or not the "Accept Change" button should be disabled according to
   * multiple different rules.
   *
   * @returns {boolean}
   */
  function disableAcceptChange() {
    if (notVersionOwner) return true;

    if (currentOpenIssue?.openIssueType === "mergeField") {
      if (currentOpenIssue.mergeField?.askCounterpartyToConfirm === false) {
        return true;
      }
    } else {
      if (!openIssueIsRedline) return true;
    }

    if (isInEffect) return true;

    if (
      state.drawerVersions?.active?._id !==
      state.drawerVersions.versions[0]?._id
    ) {
      return true;
    }

    // If the user belongs to one of the agreement's counterparties.
    if (!isAgreementOwner) {
      const owner = (props?.agreementVersion?.owner || []).find(
        (/** @type {{ orgID: string; }} */ x) => x.orgID === state.org._id
      );
      // If the edit mode is "edit" (limited editing), the current user/party can only accept changes from
      // the agreement owner i.e., they cannot accept theirs or other counterparties' changes. This affects
      // only tracked changes (redlines). Other open issue types (e.g., comments) behave the same way.
      if (
        owner &&
        owner.editMode === "edit" &&
        currentOpenIssue?.openIssueType &&
        ["add", "xadd", "del", "xdel"].includes(currentOpenIssue?.openIssueType)
      ) {
        const openIssueCollab = mainAg.collabs.find(
          (/** @type {{ partyID: string; }} */ collab) =>
            // @ts-ignore
            collab.partyID === currentOpenIssue?.metadata?.partyId
        );
        if (openIssueCollab && openIssueCollab.orgID !== mainAg.owner) {
          return true;
        }
      }
    }

    return false;
  }

  return (
    <>
      {showOpenIssueMenu && currentOpenIssue && (
        <>
          <div>
            <Grid
              container
              sx={{
                width: "260px",
                position: "fixed",
                right: "20px",
                top: "75px",
                maxHeight: "calc(100% - 100px)",
                overflow: "auto",
                borderRadius: "30px",
                backgroundColor: "rgba(255,255,255,0.7)",
                backdropFilter: "blur(10px)",
                zIndex: "1250",
                padding: "13px 0 8px 0",
                border: "1px solid" + theme.palette.grey[200],
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 3px 24px 0px",
              }}
            >
              {!["publicComment", "internalComment"].includes(
                currentOpenIssue?.openIssueType
              ) && (
                <Grid container direction={"row"} justifyContent="center">
                  <Grid item>
                    <Tooltip title="Previous Change" placement={"top"}>
                      <IconButton
                        sx={styles.iconB}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          handlePreviousClick();
                        }}
                      >
                        <FontAwesomeIcon icon={faCircleLeft} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Next Change" placement={"top"}>
                      <IconButton
                        sx={styles.iconB}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          handleNextClick();
                        }}
                      >
                        <FontAwesomeIcon icon={faCircleRight} />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item>
                    <Tooltip title="Accept Change" placement={"top"}>
                      <span>
                        <IconButton
                          sx={styles.iconBsuc}
                          onMouseDown={(e) => {
                            e.preventDefault();
                            if (openIssueIsRedline) handleClick("accept");
                          }}
                          disabled={disableAcceptChange()}
                        >
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Reject Change" placement={"top"}>
                      <span>
                        <IconButton
                          sx={styles.iconBerr}
                          onMouseDown={(e) => {
                            e.preventDefault();
                            if (openIssueIsRedline) handleClick("reject");
                          }}
                          disabled={
                            notVersionOwner ||
                            !openIssueIsRedline ||
                            isInEffect ||
                            (state.user.role.name === "Counterparty" &&
                              state.drawerVersions.active.editMode ===
                                "read") ||
                            state.drawerVersions?.active?._id !==
                              state.drawerVersions.versions[0]?._id
                          }
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
              {["internalComment", "publicComment"].includes(
                currentOpenIssue?.openIssueType
              ) && (
                <OpenIssueCommentMenu
                  currentOpenIssue={currentOpenIssue}
                  isTemplate={props.isTemplate}
                  docID={props.docID}
                  isInEffect={isInEffect}
                  handleNextIssue={handleNextClick}
                  handlePreviousIssue={handlePreviousClick}
                  canMakeChanges={notVersionOwner}
                />
              )}
              {["mergeField", "both"].includes(
                currentOpenIssue?.openIssueType
              ) && (
                <>
                  <Grid
                    container
                    sx={{
                      marginTop: "10px",
                      borderTop: "1px dotted rgba(0, 0, 0, 0.26)",
                    }}
                  ></Grid>
                  <br></br>

                  <div style={{ padding: "10px", width: "100%" }}>
                    <Grid container direction={"row"} alignItems={"center"}>
                      <Grid container item xs={2} justifyContent={"center"}>
                        <FontAwesomeIcon
                          icon={faFileImport}
                          style={{
                            fontSize: "22px",
                            color: "#5B5B5B",
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={8}
                        alignItems={"center"}
                        sx={{ paddingLeft: "10px" }}
                      >
                        <b>Merge Field</b>
                      </Grid>

                      {notVersionOwner ||
                        (!isInEffect &&
                          state.user.role.name !== "Counterparty" &&
                          state.drawerVersions?.active?._id ===
                            state.drawerVersions.versions[0]?._id && (
                            <Grid
                              item
                              xs={2}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <IconButton
                                onClick={(event) => {
                                  handleMergeFieldOperationsMenuClick(event);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faEllipsisV}
                                  style={{
                                    fontSize: "18px",
                                    color: "#5B5B5B",
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          ))}

                      <Menu
                        id="operations-menu"
                        anchorEl={anchorElement}
                        open={openOperationsMenu}
                        onClose={handleMergeFieldOperationsMenuClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {(props.isTemplate ||
                          canveoTier === "experimental") && (
                          <MenuItem
                            onClick={() => {
                              openMergeFieldMenu();
                              handleMergeFieldOperationsMenuClose();
                            }}
                          >
                            <span>Edit</span>
                          </MenuItem>
                        )}

                        <MenuItem
                          onClick={() => {
                            setOpenDeleteMergeFieldDialog(true);
                            handleMergeFieldOperationsMenuClose();
                          }}
                        >
                          Remove
                        </MenuItem>
                      </Menu>
                    </Grid>

                    <Grid container direction={"row"}>
                      <br />
                    </Grid>

                    <Grid container direction={"row"} alignItems={"center"}>
                      <Grid item xs={2}></Grid>
                      <Grid
                        item
                        xs={10}
                        alignItems={"center"}
                        sx={{ paddingLeft: "10px" }}
                      >
                        <div>
                          <b>
                            <div
                              style={{
                                wordBreak: "break-word",
                                maxWidth: "200px",
                                whiteSpace: "break-spaces",
                              }}
                            >
                              {openIssueMergeField?.name}
                            </div>
                            {openIssueMergeField?.partyRole
                              ? ` (${openIssueMergeField?.partyRole})`
                              : ""}
                          </b>
                        </div>
                        <div>{openIssueMergeField?.organizationName}</div>
                      </Grid>
                    </Grid>

                    <Grid container direction={"row"}>
                      <br />
                    </Grid>

                    <Grid container direction={"row"} alignItems={"center"}>
                      <Grid item xs={2}></Grid>
                      <Grid
                        item
                        xs={10}
                        alignItems={"center"}
                        sx={{ paddingLeft: "10px" }}
                      >
                        <i>
                          "
                          {openIssueMergeField?.setValueLater
                            ? "[No value set yet]"
                            : openIssueMergeField?.displayValue}
                          "
                        </i>
                      </Grid>
                    </Grid>

                    <Grid container direction={"row"}>
                      <br />
                    </Grid>

                    {/* Commented out for the Octorber 2023 release. */}
                    {/* <Grid container direction={"row"} alignItems={"center"}>
                        <Grid item xs={10}></Grid>
                      {!isInEffect &&
                        state.user.role.name !== "Counterparty" &&
                        state.drawerVersions?.active?._id ===
                          state.drawerVersions.versions[0]?._id && (
                          <Grid item xs={1} sx={{ marginRight: "12px" }}>
                            <IconButton
                              onClick={(event) => {
                                handleRefreshMergeFieldClick(event);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faRefresh}
                                style={{
                                  fontSize: "18px",
                                  color: "#7243DD",
                                }}
                              />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid> */}

                    <Menu
                      sx={{
                        maxWidth: "230px",
                      }}
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <span>Refresh this merge field</span>
                      </MenuItem>
                      <MenuItem
                        onClick={handleClose}
                        sx={{
                          whiteSpace: "unset",
                          wordBreak: "break-word",
                        }}
                      >
                        Refresh all "{openIssueMergeField?.name} (
                        {openIssueMergeField?.partyRole})" Merge Fields
                      </MenuItem>
                      <MenuItem
                        onClick={handleClose}
                        sx={{
                          whiteSpace: "unset",
                          wordBreak: "break-word",
                        }}
                      >
                        <span>Refresh all merge fields in this agreement</span>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <span>Push to source ...</span>
                      </MenuItem>
                    </Menu>
                  </div>
                </>
              )}

              {currentOpenIssue &&
                ![
                  "publicComment",
                  "mergeField",
                  "internalComment",
                  "info",
                  "both",
                  "variants",
                ].includes(currentOpenIssue?.openIssueType) && (
                  <>
                    <Grid
                      container
                      sx={{
                        marginTop: "10px",
                        borderTop: "1px dotted rgba(0, 0, 0, 0.26)",
                      }}
                    ></Grid>
                    <br></br>
                    <div style={{ padding: "10px", width: "100%" }}>
                      <Grid container direction={"row"}>
                        <Grid
                          container
                          item
                          xs={3}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          {
                            // @ts-ignore
                            currentOpenIssue.metadata?.creatorPhotoUrl && (
                              <img
                                alt=""
                                // @ts-ignore
                                src={currentOpenIssue.metadata.creatorPhotoUrl}
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                }}
                              ></img>
                            )
                          }
                        </Grid>
                        <Grid item xs={9}>
                          {
                            // @ts-ignore
                            currentOpenIssue.metadata?.creatorDisplayName && (
                              <Grid
                                container
                                direction={"row"}
                                justifyContent="left"
                              >
                                <b>
                                  {
                                    // @ts-ignore
                                    currentOpenIssue.metadata.creatorDisplayName
                                  }
                                </b>
                              </Grid>
                            )
                          }
                          {
                            // @ts-ignore
                            currentOpenIssue.metadata?.creationDate && (
                              <Grid
                                container
                                direction={"row"}
                                justifyContent="left"
                              >
                                {dateTime.format(
                                  // @ts-ignore
                                  new Date(
                                    // @ts-ignore
                                    currentOpenIssue.metadata.creationDate
                                  )
                                )}
                              </Grid>
                            )
                          }
                        </Grid>
                      </Grid>
                      <Grid container direction={"row"}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={9}>
                          <span>
                            <b>
                              {openIssuesLabelsMap.get(
                                currentOpenIssue?.openIssueType
                              )}
                              :
                            </b>
                            <br />
                            <i>"{currentOpenIssue?.text}"</i>
                          </span>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                )}

              {currentOpenIssue &&
                currentOpenIssue.openIssueType === "info" && (
                  <ClauseTypesMenu
                    partyId={props.partyId}
                    currentOpenIssue={currentOpenIssue}
                    isInEffect={isInEffect}
                  />
                )}

              {currentOpenIssue &&
                currentOpenIssue.openIssueType === "variants" && (
                  <VariantsMenu
                    variants={currentOpenIssue.node.__variants}
                    valueChange={(variant) => {
                      // TODO: This is a very naive way of doing the replacement.
                      editor.update(() => {
                        const item = $dfs().find(
                          (x) => x.node.getKey() === currentOpenIssue.node.__key
                        );
                        if (!item || !$isClauseNode(item.node)) return;

                        const help = item.node.getLastDescendant();
                        if ($isTextNode(help)) {
                          const parent = help.getParent();

                          if (parent) {
                            item.node
                              .getAllTextNodes()
                              .forEach((node) => node.remove());
                            parent.append($createTextNode(variant.text));
                          }
                        }
                      });
                    }}
                  />
                )}
            </Grid>
          </div>

          {currentOpenIssue?.mergeField && (
            <DialogRemoveMergeField
              open={openDeleteMergeFieldDialog}
              close={closeDeleteMergeFieldDialog}
              mergeField={currentOpenIssue?.mergeField}
            />
          )}
        </>
      )}
    </>
  );
}

//@ts-ignore
export function useOpenIssues(props) {
  //TODO: Move all functions that make sense into this hook
  const { editor, state, dispatch, currentOpenIssue } = props;
  const $navigateToNextOpenIssue = () => {
    const openIssues = $getOpenIssuesList(
      editor,
      state.workflows,
      state.org._id
    );
    if (!openIssues.length) {
      dispatch({
        type: "NEW_OPEN_ISSUE_SELECTION",
        payload: { id: null, type: null, status: null },
      });
      return;
    }

    let selectedOpenIssueIndex;

    // If we have previously selected an open issue then we just need to find its index
    // within the list of existing open issues to find the next one.
    if (currentOpenIssue) {
      const selectedOpenIssueId = currentOpenIssue.id;

      selectedOpenIssueIndex = openIssues.findIndex(
        (openIssue) => openIssue.id === selectedOpenIssueId
      );
    }
    // Otherwise, we get the current selection node and see what is the nearest open issue
    // (i.e, a node which is either a clause, a mark or a redline node).
    else {
      const selection = $getSelection();

      if (!$isRangeSelection(selection)) return;

      const selectedNode = selection.anchor.getNode();

      const depthFirstSearchItems = $dfs();

      const selectedItemIndex = depthFirstSearchItems.findIndex(
        (item) => item.node.getKey() === selectedNode.getKey()
      );

      const depthFirstSearchFromSelectedNodeItems =
        depthFirstSearchItems.slice(selectedItemIndex);

      const searchItem = depthFirstSearchFromSelectedNodeItems.find(
        ({ node }) =>
          ($isClauseNode(node) || $isMarkNode(node) || $isRedlineNode(node)) &&
          // TODO: OpenIssues needs to be a map where the key is its ID.
          openIssues.find((oi) => oi.node.getKey() === node.getKey())
      );

      // If no node can be found it means that our selection is located after any
      // open issue which means we need to reset the navigation to the first open issue.
      if (searchItem) {
        selectedOpenIssueIndex = openIssues.findIndex(
          (openIssue) => openIssue.node.getKey() === searchItem.node.getKey()
        );
      } else {
        selectedOpenIssueIndex = 0;
      }
    }

    let openIssueToSelectIndex = currentOpenIssue
      ? selectedOpenIssueIndex + 1
      : selectedOpenIssueIndex;

    // If we've reached the end of the open issues.
    if (openIssueToSelectIndex === openIssues.length) {
      // We start from the beggining.
      openIssueToSelectIndex = 0;
    }

    let openIssueToSelect = openIssues[openIssueToSelectIndex];

    let counter = openIssues.length;

    // We want to skip merge fields that have not been marked as open issues
    // AKA not asked the counterparty to confirm.
    while (openIssueToSelect.skipNavigation) {
      counter--;
      // If we've went through all open issues and they're all skipabble we
      // just prevent any navigation.
      if (counter === 0) {
        return;
      }

      openIssueToSelectIndex++;
      // If we've reached the end of the open issues.
      if (openIssueToSelectIndex === openIssues.length) {
        // We start from the beggining.
        openIssueToSelectIndex = 0;
      }
      openIssueToSelect = openIssues[openIssueToSelectIndex];
    }

    // If the previous selected open issue was a clause node and we are now navigating
    // to an open issue that is not part of that clause, we need to unselect the clause.
    if (
      currentOpenIssue &&
      $isClauseNode(currentOpenIssue.node) &&
      openIssueToSelect.node.getKey() !== currentOpenIssue.node.getKey()
    ) {
      currentOpenIssue.node.changeFilter("none");
    }

    dispatch({
      type: "NEW_OPEN_ISSUE_SELECTION",
      payload: {
        id: openIssueToSelect.id,
        type: "navigation",
        status: "ongoing",
      },
    });
    return openIssueToSelect.id;
  };

  return { $navigateToNextOpenIssue };
}
