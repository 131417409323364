import { faTag } from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { globalStore } from "../../state/store";
import SelectLabels from "../SelectLabels";

/**
 * @typedef {object} LabelsSelectorProps
 * @property {*} agreement
 * @property {function} handleAgreementChange
 */

/**
 * @param {LabelsSelectorProps} props
 * @returns {React.JSX.Element}
 */
export default function LabelsSelector({ agreement, handleAgreementChange }) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  /**
   * @param {{ _id: string }[]} newLabels
   */
  function handleLabelSelect(newLabels) {
    const labelIds = newLabels.map((label) => label._id);

    handleAgreementChange("labels", labelIds);
  }

  return (
    <>
      <Grid container sx={{ mt: 4 }}>
        <Grid item xs={6}>
          <SelectLabels
            adornIcon={faTag}
            handleLabelSelect={handleLabelSelect}
            selectedIDs={agreement.labels}
            options={state.labels
              .filter(
                (
                  /** @type {{ active: any; type: { name: string; }; }} */ label
                ) => label.active && label.type.name === "Agreement"
              )
              .sort(
                (
                  /** @type {{ name: number; }} */ previous,
                  /** @type {{ name: number; }} */ next
                ) => (previous.name > next.name ? 1 : -1)
              )
              .sort(
                (
                  /** @type {{ type: { color: number; }; }} */ previous,
                  /** @type {{ type: { color: number; }; }} */ next
                ) => (previous.type.color > next.type.color ? 1 : -1)
              )}
            labelType={"Agreement"}
          />
        </Grid>
      </Grid>
    </>
  );
}
