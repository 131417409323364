import { mergeRegister } from "@lexical/utils";
import { $getSelection, $isRangeSelection, $isTextNode } from "lexical";
import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import getSelectedNode from "../../utils/getSelectedNode";
import FloatingContextMenu from "./FloatingContextMenu";

/**
 * @param {import("lexical").LexicalEditor} editor
 * @param {HTMLElement} anchorElem
 * @param {boolean} isInEffect
 * @param {boolean} isTemplate
 * @returns {React.ReactPortal | null}
 */
function useFloatingContextMenu(editor, anchorElem, isInEffect, isTemplate) {
  const [isText, setIsText] = useState(false);

  const updatePopup = useCallback(() => {
    editor.getEditorState().read(() => {
      // Should not to pop up the floating toolbar when using IME input
      if (editor.isComposing()) return;

      const selection = $getSelection();
      const nativeSelection = window.getSelection();
      const rootElement = editor.getRootElement();

      if (
        nativeSelection !== null &&
        (!$isRangeSelection(selection) ||
          rootElement === null ||
          !rootElement.contains(nativeSelection.anchorNode))
      ) {
        setIsText(false);
        return;
      }

      if (!$isRangeSelection(selection)) {
        return;
      }

      const node = getSelectedNode(selection);

      if (selection.getTextContent() !== "") {
        setIsText($isTextNode(node));
      } else {
        setIsText(false);
      }

      const rawTextContent = selection.getTextContent().replace(/\n/g, "");
      if (!selection.isCollapsed() && rawTextContent === "") {
        setIsText(false);
      }
    });
  }, [editor]);

  useEffect(() => {
    document.addEventListener("selectionchange", updatePopup);
    return () => {
      document.removeEventListener("selectionchange", updatePopup);
    };
  }, [updatePopup]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updatePopup();
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setIsText(false);
        }
      })
    );
  }, [editor, updatePopup]);

  if (!isText) {
    return null;
  }

  return createPortal(
    <FloatingContextMenu
      editor={editor}
      anchorElem={anchorElem}
      isInEffect={isInEffect}
      isTemplate={isTemplate}
    />,
    anchorElem
  );
}

export default useFloatingContextMenu;
