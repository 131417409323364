import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faCircleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import docx from "../../assets/img/docx.png";
import pdf from "../../assets/img/pdf.png";
import xlsx from "../../assets/img/xlsx.png";
import UploadSignedVersionForm from "../forms/UploadSignedVersionForm";
import UploadVersionForm from "../forms/UploadVersionForm";

const StyledImage = styled("img", {
  // Configure which props should be forwarded on DOM.
  shouldForwardProp: (prop) => prop !== "disabled",
  // @ts-ignore
})(({ disabled }) => ({
  width: "120px",
  "&:hover": {
    cursor: "pointer",
  },
  opacity: disabled ? "0.5" : "1",
  paddingBottom: "16px",
}));

/**
 * @typedef {*} DialogUploadVersionProps
 */

/**
 * @param {DialogUploadVersionProps} props
 * @returns {React.JSX.Element}
 */
export default function DialogUploadVersion({
  open,
  originVersion,
  isOwner,
  canSubmitSigned,
  versionType,
  isMainBody,
  handleClose,
  isTemplate,
}) {
  const uploadFormRef = useRef(/** @type {* | null} */ (null));
  const [canSubmit, setCanSubmit] = useState(false);
  const [docType, setDocType] = useState("");
  const [searchParams] = useSearchParams();
  const newVersionFromFilename = searchParams.get("newVersionFromFilename");

  useEffect(() => {
    if (newVersionFromFilename) {
      setDocType("docx");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newVersionFromFilename]);

  const onUploadVersionSubmit = () => {
    uploadFormRef.current?.requestSubmit();
  };

  /**
   * @param {*} success
   */
  const handleFormSubmit = (success) => {
    if (success) handleClose();
  };

  const handleSignedSubmitClick = () => {
    if (canSubmitSigned) setDocType("signed");
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>Upload New Agreement Version</DialogTitle>

      <DialogContent>
        {!docType && (
          <Grid container direction="row" justifyContent="center" py={6} px={2}>
            {["docx", "canveo"].includes(versionType) && (
              <Grid
                container
                item
                direction="column"
                alignItems="center"
                xs={6}
                sx={{ textAlign: "center", gap: "10px" }}
              >
                <StyledImage
                  src={docx}
                  alt="Export to MS Word"
                  onClick={() => setDocType("docx")}
                />
                <Typography variant="subtitle1">
                  Work in Progress Version
                </Typography>
                <Typography variant="body2">
                  Upload a Word document as a new working version
                </Typography>
              </Grid>
            )}

            {versionType === "xlsx" && (
              <Grid
                container
                item
                direction="column"
                alignItems="center"
                xs={6}
                sx={{ textAlign: "center", gap: "10px" }}
              >
                <StyledImage
                  src={xlsx}
                  alt="Export to MS Word"
                  onClick={() => setDocType("xlsx")}
                />
                <Typography variant="subtitle1">
                  Work in Progress Version
                </Typography>
                <Typography variant="body2">
                  Upload a spreadsheet as a new working version
                </Typography>
              </Grid>
            )}

            {versionType === "pdf" && (
              <Grid
                container
                item
                direction="column"
                alignItems="center"
                xs={6}
                sx={{ textAlign: "center", gap: "10px" }}
              >
                <StyledImage
                  src={pdf}
                  alt="Export to MS Word"
                  onClick={() => setDocType("pdf")}
                />
                <Typography variant="subtitle1">
                  Work in Progress Version
                </Typography>
                <Typography variant="body2">
                  Upload a PDF as a new working version
                </Typography>
              </Grid>
            )}

            {isOwner && isMainBody && (
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                xs={6}
                sx={{ textAlign: "center", gap: "10px" }}
              >
                <Tooltip
                  title={
                    !canSubmitSigned
                      ? "Feedback from counterparties pending - first retrieve the agreement from your counterparties and then try again"
                      : ""
                  }
                  placement="bottom"
                  onClick={handleSignedSubmitClick}
                >
                  <StyledImage
                    src={pdf}
                    alt="Export to PDF"
                    // @ts-ignore
                    disabled={!canSubmitSigned}
                  />
                </Tooltip>

                <Typography
                  variant="subtitle1"
                  color={canSubmitSigned ? "textPrimary" : "textSecondary"}
                >
                  Signed Version
                </Typography>

                <Typography
                  variant="body2"
                  color={canSubmitSigned ? "textPrimary" : "textSecondary"}
                >
                  Upload a signed PDF as the final version of the contract
                </Typography>
              </Grid>
            )}
          </Grid>
        )}

        {docType && docType !== "signed" && (
          <UploadVersionForm
            ref={uploadFormRef}
            // @ts-ignore
            originVersion={originVersion}
            isOwner={isOwner}
            handleCanSubmit={(/** @type {boolean} */ canSubmit) =>
              setCanSubmit(canSubmit)
            }
            handleFormSubmit={handleFormSubmit}
            acceptedType={docType}
            isTemplate={isTemplate}
          />
        )}

        {docType === "signed" && (
          <UploadSignedVersionForm
            ref={uploadFormRef}
            // @ts-ignore
            originVersion={originVersion}
            handleCanSubmit={(/** @type {boolean} */ canSubmit) =>
              setCanSubmit(canSubmit)
            }
            handleFormSubmit={handleFormSubmit}
          />
        )}
      </DialogContent>

      <DialogActions>
        {!docType && (
          <Button sx={{ marginRight: "auto" }} onClick={handleClose}>
            Cancel
          </Button>
        )}
        {docType && (
          <Button sx={{ marginRight: "auto" }} onClick={() => setDocType("")}>
            Back
          </Button>
        )}
        {docType && (
          <Button
            color="primary"
            onClick={onUploadVersionSubmit}
            disabled={!canSubmit}
          >
            <FontAwesomeIcon icon={faCircleRight} />
            &nbsp; Continue
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
