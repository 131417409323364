import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";
import useDialogNewClauseVariant from "./hooks/useDialogNewClauseVariant";
import ApplicationStep from "./steps/ApplicationStep";
import ConfigurationStep from "./steps/ConfigurationStep";
import TopicStep from "./steps/TopicStep";
import VisibilityStep from "./steps/VisibilityStep";

/**
 * @param {DialogNewClauseVariantProps} props
 * @returns {JSX.Element}
 */
export default function DialogNewClauseVariant(props) {
  const options = useDialogNewClauseVariant(props);
  const { open, close, steps, step } = options;

  const currentStep = steps[step];

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>New Clause Variant</DialogTitle>

      <DialogContent>
        {/* {currentStep.component(options)} */}
        {step === "configuration" && <ConfigurationStep {...options} />}
        {step === "application" && <ApplicationStep {...options} />}
        {step === "topic" && <TopicStep {...options} />}
        {step === "visibility" && <VisibilityStep {...options} />}
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button {...currentStep.actions.secondary.buttonProps}>
          {currentStep.actions.secondary.label}
        </Button>

        <Button {...currentStep.actions.primary.buttonProps}>
          {currentStep.actions.primary.label}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
