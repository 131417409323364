/**
 * Checks if a selection is at the very end of a TextNode.
 *
 * @param {import("lexical").RangeSelection} selection
 * @param {import("lexical").TextNode} textNode
 *
 * @returns {boolean}
 */
export function selectionIsAtEndOfTextNode(selection, textNode) {
  const textNodeLength = textNode.getTextContent().length;

  const [anchor, focus] = selection.getCharacterOffsets();

  // We can tell if a selection is at the end of a TextNode if both its anchor and
  // focus have the same value as the length of the text contents of a TextNode.
  const selectionIsAtEndOfTextNode =
    anchor === textNodeLength && focus === textNodeLength;
  return selectionIsAtEndOfTextNode;
}
