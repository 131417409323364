const snackbarReducer = (state, action) => {
  switch (action.type) {
    case "NEW_SNACKBAR":
      return { snackbar: action.payload };
    default:
      return { snackbar: state.snackbar };
  }
};

export default snackbarReducer;
