import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React from "react";

/**
 * @typedef {object} FileConversionErrorDetailsProps
 * @property {Error} conversionError
 */

/**
 * @param {FileConversionErrorDetailsProps} props
 * @returns {React.JSX.Element}
 */
export default function FileConversionErrorDetails({ conversionError }) {
  return (
    <>
      <Typography textAlign="justify" px={1}>
        This document failed to import. Our platform supports approximately 90%
        of Word features at the moment. We are continuously working to make it
        100%.
      </Typography>

      <Typography textAlign="justify" px={1} pt={2}>
        Please send us the file you tried to import, together with a copy of the
        error message below.
      </Typography>

      <Typography textAlign="justify" px={1} pt={2}>
        You can either use the chatbot on this page, or{" "}
        <a href="mailto:support@getcanveo.com">support@getcanveo.com</a>, and we
        will get back to you immediately with a proposed a solution.
      </Typography>

      <br />

      {conversionError.stack && (
        <Accordion variant="outlined">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Error Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              component="pre"
              fontWeight="bold"
              fontSize={14}
              sx={{ textWrap: "wrap" }}
            >
              {conversionError.stack}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}
