import axios from "axios";
import { useContext } from "react";
import { globalStore } from "../state/store";
import { randomString as generateRandomString } from "../utils";

/**
 * @param {{ email: string }[]} allUsers
 * @returns {{ verifyCreateUser: (userInputData: any, orgID: string) => Promise<any> }}
 */
export default function useCreateUser(allUsers) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  /**
   * @param {string} email
   * @param {string} orgID
   * @returns {Promise<string | void>}
   */
  async function verifyUser(email, orgID) {
    try {
      if (allUsers.some((au) => au.email === email)) {
        return "This user (email address) is already created, you can select it instead.";
      }

      // Verify if we are creating the user to a valid party
      if (
        !state.parties?.some(
          (/** @type {{ orgID: string; }} */ p) => p.orgID === orgID
        )
      ) {
        return "Unable to identify the company for the user";
      }
      const resUserVerify = await axios.get(
        state.settings.api + "user/email/" + email
      );
      // To verify if the user already exists in the database
      if (!resUserVerify?.data) {
        return "Unable to verify if the user already exists - please provide a valid email";
      }
      if (resUserVerify.data?.data?.role?.name === "Counterparty") {
        return "This user is already setup for a different organisation, please contact canveo support for help.";
      }
      if (resUserVerify.data.data) {
        return "This user is setup for a Canveo customer, please contact canveo support for help.";
      }
    } catch (e) {
      return "An error occurred while verifying the user";
    }
  }

  /**
   * @param {*} userInputData
   * @param {string} orgID
   */
  async function createUser(userInputData, orgID) {
    const newUser = {
      firstName: userInputData.firstName,
      lastName: userInputData.lastName,
      displayName: `${userInputData.firstName} ${userInputData.lastName}`,
      email: userInputData.email.toLowerCase().trim(),
      password: generateRandomString(20),
      title: userInputData.title,
      phone: userInputData.phone || "",
      orgID: orgID,
      role: "Counterparty",
      readOnly: false,
      creationBy: state.user._id,
      creationDate: new Date().toISOString(),
      active: true,
      sourceData:
        !!userInputData.sourceData?.source &&
        !!userInputData.sourceData?.sourceID
          ? userInputData.sourceData
          : null,
    };

    try {
      const resUser = await axios.post(state.settings.api + "user", {
        user: newUser,
      });

      if (!resUser.data.success) {
        return { error: "Unable to successfully create the new user" };
      }
      return { user: resUser.data.data };
    } catch (e) {
      return { error: "An error occurred while creating the user" };
    }
  }

  /**
   * @param {*} userInputData
   * @param {string} orgID
   * @returns {Promise<*>}
   */
  async function verifyCreateUser(userInputData, orgID) {
    const verifyError = await verifyUser(userInputData.email, orgID);
    if (verifyError) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: verifyError,
          severity: "error",
        },
      });
      return {};
    }

    const result = await createUser(userInputData, orgID);
    if (result.error) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: verifyError,
          severity: "error",
        },
      });
      return {};
    }

    return result.user;
  }

  return { verifyCreateUser };
}
