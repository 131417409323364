/* eslint-disable no-unused-vars */

import { Box, Grid } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CanveoCircularProgress, Editor } from "../../components";
import { getLegalDrawerItems } from "../../components/drawer/getDrawerItems";
import { globalStore } from "../../state/store";
import PlaybookEditor from "./components/PlaybookEditor";

export default function PlaybookDetails() {
  const navigate = useNavigate();
  const tid = useParams()?.playbookId || "";

  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [filters, setFilters] = React.useState({
    agrTypeID: "",
    reference: "",
    labels: [],
  });
  const [filteredTemplates, setFilteredTemplates] = React.useState(
    /** @type {*[]} */ ([])
  );
  const [filteredTopics, setFilteredTopics] = React.useState(
    /** @type {*[]} */ ([])
  );
  const [curPageTemplates, setCurPageTemplates] = React.useState(1);
  const [curPageTopics, setCurPageTopics] = React.useState(1);
  const [dialogTemplateOpen, setDialogTemplateOpen] = React.useState(false);
  const [dialogClauseOpen, setDialogClauseOpen] = React.useState(
    /** @type {* | null} */ (null)
  );
  const [loading, setLoading] = React.useState(false);
  const [template, setTemplate] = React.useState(
    /** @type {* | null} */ (null)
  );
  const [drawerItems, setDrawerItems] = useState(/** @type {*[]} */ ([]));

  useEffect(() => {
    const navigateAction = (/** @type {string} */ target) => navigate(target);

    const drawerItems = getLegalDrawerItems(
      tid,
      navigateAction,
      state?.user?.email
    );

    setDrawerItems(drawerItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid]);

  useEffect(() => {
    // Update the filteredTemplates array upon change of filters or Templates in state
    const ft = state.templates
      .filter((/** @type {{ deleted: any; }} */ t) => !t.deleted) // Filters out any recently deleted template
      //Commenting this code as i don't understand it and it's filtering out some templates, which shouldn't
      // .filter(
      //   (t) =>
      //     !state.templates.some(
      //       (temp) =>
      //         temp._id !== t._id &&
      //         temp.blueprintID === t.blueprintID &&
      //         (temp.active || (!t.active && temp.version > t.version))
      //     )
      // )
      .filter(
        (/** @type {{ agrTypeID: string; }} */ t) =>
          filters.agrTypeID === "" || t.agrTypeID === filters.agrTypeID
      ) // FILTER: agr types
      .filter(
        (/** @type {{ reference: string; }} */ t) =>
          filters.reference !== undefined &&
          t.reference.toLowerCase().includes(filters.reference.toLowerCase())
      ) // FILTER: reference
      .filter(
        (/** @type {{ labelIDs: string | never[]; }} */ t) =>
          filters.labels.length === 0 ||
          filters.labels.every((fl) => t.labelIDs.includes(fl))
      ) // FILTER: labels
      .sort(
        (
          /** @type {{ agrTitle: number; }} */ a,
          /** @type {{ agrTitle: number; }} */ b
        ) => (a.agrTitle > b.agrTitle ? 1 : -1)
      );

    setFilteredTemplates(ft);
    setCurPageTemplates(1);
  }, [state.templates, filters, tid]);

  useEffect(() => {
    const ft = state.clauseTypes
      .filter((/** @type {{ active: any; }} */ t) => t.active) // FILTER: labels
      .sort(
        (
          /** @type {{ name: number; }} */ a,
          /** @type {{ name: number; }} */ b
        ) => (a.name - b.name ? 1 : -1)
      );

    setFilteredTopics(ft);
    setCurPageTopics(1);
  }, [state.clauseTypes, dialogClauseOpen]);

  useEffect(() => {
    // Pull the entire template (incl. content) when you're loading a new template page

    if (Boolean(tid) && tid.length > 10 && tid.length < 30) {
      setLoading(true);

      axios
        .get(`${state.settings.api}playbook/${tid}/details`)
        .then((resTemp) => {
          if (resTemp.data.success) {
            dispatch({
              type: "INIT_WORKFLOWS",
              payload: resTemp.data.data.workflows,
            });
            dispatch({
              type: "INIT_PARAMS_DOC",
              payload: resTemp.data.data.params,
            });
            dispatch({
              type: "INIT_TEMPLATE",
              payload: resTemp.data.data.templates,
            });
            const template = resTemp.data.data.templates.find(
              (/** @type {{ _id: string | undefined; }} */ t) => t._id === tid
            );
            setTemplate(template);
            setLoading(false);
          } else {
            dispatch({
              type: "NEW_SNACKBAR",
              payload: {
                message: "Unable to open the template",
                severity: "error",
              },
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          dispatch({
            type: "NEW_SNACKBAR",
            payload: {
              message: "Unable to open the template",
              severity: "error",
            },
          });
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid]);

  if (loading) {
    return (
      <div>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          <CanveoCircularProgress />
        </Grid>
      </div>
    );
  }

  return (
    <div>
      <Box sx={{ mt: 10, mb: 6, mx: 0 }}>
        {Boolean(template) && (
          <PlaybookEditor page="Template" template={template} />
        )}
      </Box>
    </div>
  );
}
