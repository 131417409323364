import axios from "axios";
import { useContext } from "react";
import { globalStore } from "../state/store";

const useFileService = () => {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  /**
   * @param {string} key
   * @param {string} filename
   */
  const downloadFile = async (key, filename) => {
    try {
      const downloadFileResponse = await axios.get(
        `${state.settings.api}document/${key}`
      );

      const downloadFileResponseData = await downloadFileResponse.data;
      const fileResponse = await axios.get(downloadFileResponseData.data.url, {
        responseType: "blob",
      });

      const downloadFileAnchor = document.createElement("a");
      downloadFileAnchor.href = window.URL.createObjectURL(fileResponse.data);
      downloadFileAnchor.download = filename;
      downloadFileAnchor.click();
    } catch (e) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "Unable to download origin file - contact Canveo Support",
          severity: "error",
        },
      });
    }
  };

  return { downloadFile };
};

export default useFileService;
