import { Autocomplete, Grid, TextField } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { currencyCodes, durationUnits } from "../MergeFieldMenu/constants";
import { getMergeFieldDisplayValue } from "../MergeFieldMenu/utils";

/**
 * @typedef {object} MergeFieldSelectorProps
 * @property {{mergeFields: import("../editor/nodes/MarkNode").MergeField []}} agreement
 * @property {(property: string, value: *) => void} handleAgreementChange
 * @property {import("../editor/nodes/MarkNode").MergeField} mergeField
 * @property {string} mergeFieldId
 */

/**
 * @param {MergeFieldSelectorProps} props
 */
export default function MergeFieldSelector({
  agreement,
  handleAgreementChange,
  mergeFieldId,
}) {
  const index = agreement.mergeFields.findIndex(
    (mergeField) => mergeField._id === mergeFieldId
  );

  const mergeField = agreement.mergeFields[index];
  if (!mergeField) return <>n/a</>;

  return (
    <>
      <Grid container direction={"row"}>
        <span style={{ fontSize: 14, fontWeight: 400 }}>
          {mergeField.wizardQuestion}
        </span>
      </Grid>

      <br></br>

      <Grid container direction={"row"}>
        <span style={{ fontSize: 14, color: "grey" }}>
          {mergeField.wizardGuidance}
        </span>
      </Grid>

      <br></br>

      <Grid container direction={"row"}>
        {mergeField.isList ? (
          <>
            {mergeField.allowSelectingMultipleListOptions ? (
              <>
                <Autocomplete
                  multiple
                  sx={{ width: "286px" }}
                  options={mergeField.listOptions}
                  value={
                    mergeField.listOptions.filter((option) =>
                      mergeField.selectedListOptionsIds.includes(option.id)
                    ) ||
                    mergeField.listOptions[0] ||
                    null
                  }
                  autoHighlight
                  getOptionLabel={(option) =>
                    getMergeFieldDisplayValue(option.mergeFieldValue)
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(_, option) => {
                    if (option) {
                      const updatedMergeFields = agreement.mergeFields;
                      updatedMergeFields[index] = {
                        ...updatedMergeFields[index],
                        selectedListOptionsIds: option.map((x) => x.id),
                        setValueLater: false,
                      };

                      handleAgreementChange("mergeFields", updatedMergeFields);
                    }
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField {...params} variant="outlined" />
                    </>
                  )}
                />
              </>
            ) : (
              <>
                <Autocomplete
                  sx={{ width: "286px" }}
                  options={mergeField.listOptions}
                  value={
                    mergeField.listOptions.find(
                      (option) =>
                        option.id === mergeField.selectedListOptionsIds[0]
                    ) ||
                    mergeField.listOptions[0] ||
                    null
                  }
                  autoHighlight
                  getOptionLabel={(option) =>
                    getMergeFieldDisplayValue(option.mergeFieldValue)
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(_, option) => {
                    if (option) {
                      const updatedMergeFields = agreement.mergeFields;
                      updatedMergeFields[index] = {
                        ...updatedMergeFields[index],
                        selectedListOptionsIds: [option.id],
                        value: option.mergeFieldValue,
                        displayValue: getMergeFieldDisplayValue(
                          option.mergeFieldValue
                        ),
                        setValueLater: false,
                      };

                      handleAgreementChange("mergeFields", updatedMergeFields);
                    }
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField {...params} variant="outlined" />
                    </>
                  )}
                />
              </>
            )}
          </>
        ) : (
          <>
            {mergeField.value.type === "freeText" && (
              <TextField
                label={"Type text here ..."}
                autoFocus={true}
                value={mergeField.value.value}
                sx={{ width: "286px" }}
                variant="outlined"
                onChange={(event) => {
                  const updatedMergeFields = agreement.mergeFields;
                  updatedMergeFields[index] = {
                    ...updatedMergeFields[index],
                    value: {
                      ...updatedMergeFields[index].value,
                      type: "freeText",
                      value: event.target.value,
                    },
                    displayValue: event.target.value,
                    setValueLater: false,
                  };

                  handleAgreementChange("mergeFields", updatedMergeFields);
                }}
              />
            )}

            {mergeField.value.type === "date" && (
              <TextField
                type="date"
                autoFocus={true}
                value={
                  mergeField.value.value
                    ? format(new Date(mergeField.value.value), "yyyy-MM-dd")
                    : ""
                }
                sx={{ width: "286px" }}
                variant="outlined"
                onChange={(event) => {
                  const date = new Date(event.target.value);
                  if (!isNaN(date.getDate())) {
                    const isoStringDate = date.toISOString();

                    const updatedMergeFields = agreement.mergeFields;
                    updatedMergeFields[index] = {
                      ...updatedMergeFields[index],
                      value: {
                        ...updatedMergeFields[index].value,
                        type: "date",
                        value: isoStringDate,
                      },
                      displayValue: getMergeFieldDisplayValue({
                        ...updatedMergeFields[index].value,
                        type: "date",
                        value: isoStringDate,
                      }),
                      setValueLater: false,
                    };

                    handleAgreementChange("mergeFields", updatedMergeFields);
                  } else {
                    const updatedMergeFields = agreement.mergeFields;
                    updatedMergeFields[index] = {
                      ...updatedMergeFields[index],
                      value: {
                        ...updatedMergeFields[index].value,
                        type: "date",
                        value: "",
                      },
                      displayValue: "",
                    };

                    handleAgreementChange("mergeFields", updatedMergeFields);
                  }
                }}
              />
            )}

            {mergeField.value.type === "duration" && (
              <>
                <Grid item xs={2}>
                  <TextField
                    type="number"
                    autoFocus={true}
                    value={mergeField.value.durationValue}
                    variant="outlined"
                    onChange={(event) => {
                      const number = Number(event.target.value);
                      if (number) {
                        const updatedMergeFields = agreement.mergeFields;
                        updatedMergeFields[index] = {
                          ...updatedMergeFields[index],
                          // @ts-ignore
                          value: {
                            ...updatedMergeFields[index].value,
                            type: "duration",
                            durationValue: event.target.value,
                          },
                          // @ts-ignore
                          displayValue: getMergeFieldDisplayValue({
                            ...updatedMergeFields[index].value,
                            type: "duration",
                            durationValue: event.target.value,
                          }),
                          setValueLater: false,
                        };

                        handleAgreementChange(
                          "mergeFields",
                          updatedMergeFields
                        );
                      } else {
                        const updatedMergeFields = agreement.mergeFields;
                        updatedMergeFields[index] = {
                          ...updatedMergeFields[index],
                          // @ts-ignore
                          value: {
                            ...updatedMergeFields[index].value,
                            type: "duration",
                            durationValue: event.target.value,
                          },
                          displayValue: "",
                        };

                        handleAgreementChange(
                          "mergeFields",
                          updatedMergeFields
                        );
                      }
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={2}
                  sx={{
                    marginLeft: "20px",
                  }}
                >
                  <Autocomplete
                    options={durationUnits}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    value={
                      durationUnits.find(
                        // @ts-ignore
                        (x) => x.value === mergeField.value.durationUnit.value
                      ) || null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(_, option) => {
                      if (option) {
                        const updatedMergeFields = agreement.mergeFields;
                        updatedMergeFields[index] = {
                          ...updatedMergeFields[index],
                          // @ts-ignore
                          value: {
                            ...updatedMergeFields[index].value,
                            type: "duration",
                            durationUnit: option,
                          },
                          // @ts-ignore
                          displayValue: getMergeFieldDisplayValue({
                            ...updatedMergeFields[index].value,
                            type: "duration",
                            durationUnit: option,
                          }),
                          setValueLater: false,
                        };

                        handleAgreementChange(
                          "mergeFields",
                          updatedMergeFields
                        );
                      } else {
                        const updatedMergeFields = agreement.mergeFields;
                        updatedMergeFields[index] = {
                          ...updatedMergeFields[index],
                          // @ts-ignore
                          value: {
                            ...updatedMergeFields[index].value,
                            type: "duration",
                            durationUnit: {
                              label: "",
                              value: "",
                            },
                          },
                          displayValue: "",
                        };

                        handleAgreementChange(
                          "mergeFields",
                          updatedMergeFields
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Duration Unit"
                          placeholder="Select Duration Unit ..."
                          variant="outlined"
                        />
                      </>
                    )}
                  />
                </Grid>
              </>
            )}

            {mergeField.value.type === "number" && (
              <TextField
                type="number"
                autoFocus={true}
                value={mergeField.value.value}
                sx={{ width: "286px" }}
                variant="outlined"
                onChange={(event) => {
                  const number = Number(event.target.value);
                  if (number) {
                    const updatedMergeFields = agreement.mergeFields;
                    updatedMergeFields[index] = {
                      ...updatedMergeFields[index],
                      value: {
                        ...updatedMergeFields[index].value,
                        type: "number",
                        value: event.target.value,
                      },
                      displayValue: getMergeFieldDisplayValue({
                        ...updatedMergeFields[index].value,
                        type: "number",
                        value: event.target.value,
                      }),
                      setValueLater: false,
                    };

                    handleAgreementChange("mergeFields", updatedMergeFields);
                  } else {
                    const updatedMergeFields = agreement.mergeFields;
                    updatedMergeFields[index] = {
                      ...updatedMergeFields[index],
                      value: {
                        ...updatedMergeFields[index].value,
                        type: "number",
                        value: event.target.value,
                      },
                      displayValue: "",
                    };

                    handleAgreementChange("mergeFields", updatedMergeFields);
                  }
                }}
              />
            )}

            {mergeField.value.type === "currency" && (
              <>
                <Grid item xs={2}>
                  <Autocomplete
                    options={currencyCodes}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    value={
                      currencyCodes.find(
                        // @ts-ignore
                        (x) => x.value === mergeField.value.currencyUnit.value
                      ) || null
                    }
                    onChange={(_, option) => {
                      if (option) {
                        const updatedMergeFields = agreement.mergeFields;
                        updatedMergeFields[index] = {
                          ...updatedMergeFields[index],
                          // @ts-ignore
                          value: {
                            ...updatedMergeFields[index].value,
                            type: "currency",
                            currencyUnit: option,
                          },
                          // @ts-ignore
                          displayValue: getMergeFieldDisplayValue({
                            ...updatedMergeFields[index].value,
                            type: "currency",
                            currencyUnit: option,
                          }),
                          setValueLater: false,
                        };

                        handleAgreementChange(
                          "mergeFields",
                          updatedMergeFields
                        );
                      } else {
                        const updatedMergeFields = agreement.mergeFields;
                        updatedMergeFields[index] = {
                          ...updatedMergeFields[index],
                          // @ts-ignore
                          value: {
                            ...updatedMergeFields[index].value,
                            type: "currency",
                            currencyUnit: {
                              label: "",
                              value: "",
                            },
                          },
                          displayValue: "",
                        };

                        handleAgreementChange(
                          "mergeFields",
                          updatedMergeFields
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          autoFocus={true}
                          label="Currency Unit"
                          placeholder="Select Currency Unit ..."
                          variant="outlined"
                        />
                      </>
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={2}
                  sx={{
                    marginLeft: "20px",
                  }}
                >
                  <TextField
                    type="number"
                    value={mergeField.value.currencyValue}
                    variant="outlined"
                    onChange={(event) => {
                      const number = Number(event.target.value);
                      if (number) {
                        const updatedMergeFields = agreement.mergeFields;
                        updatedMergeFields[index] = {
                          ...updatedMergeFields[index],
                          // @ts-ignore
                          value: {
                            ...updatedMergeFields[index].value,
                            type: "currency",
                            currencyValue: event.target.value,
                          },
                          // @ts-ignore
                          displayValue: getMergeFieldDisplayValue({
                            ...updatedMergeFields[index].value,
                            type: "currency",
                            currencyValue: event.target.value,
                          }),
                          setValueLater: false,
                        };

                        handleAgreementChange(
                          "mergeFields",
                          updatedMergeFields
                        );
                      } else {
                        const updatedMergeFields = agreement.mergeFields;
                        updatedMergeFields[index] = {
                          ...updatedMergeFields[index],
                          // @ts-ignore
                          value: {
                            ...updatedMergeFields[index].value,
                            type: "currency",
                            currencyValue: event.target.value,
                          },
                          displayValue: "",
                        };

                        handleAgreementChange(
                          "mergeFields",
                          updatedMergeFields
                        );
                      }
                    }}
                  />
                </Grid>
              </>
            )}

            {mergeField.value.type === "percentage" && (
              <TextField
                type="number"
                autoFocus={true}
                value={mergeField.value.value}
                variant="outlined"
                sx={{ width: "250px" }}
                onChange={(event) => {
                  const number = Number(event.target.value);
                  if (number) {
                    const updatedMergeFields = agreement.mergeFields;
                    updatedMergeFields[index] = {
                      ...updatedMergeFields[index],
                      value: {
                        ...updatedMergeFields[index].value,
                        type: "percentage",
                        value: event.target.value,
                      },
                      displayValue: getMergeFieldDisplayValue({
                        ...updatedMergeFields[index].value,
                        type: "percentage",
                        value: event.target.value,
                      }),
                      setValueLater: false,
                    };

                    handleAgreementChange("mergeFields", updatedMergeFields);
                  } else {
                    const updatedMergeFields = agreement.mergeFields;
                    updatedMergeFields[index] = {
                      ...updatedMergeFields[index],
                      value: {
                        ...updatedMergeFields[index].value,
                        type: "percentage",
                        value: event.target.value,
                      },
                      displayValue: "",
                    };

                    handleAgreementChange("mergeFields", updatedMergeFields);
                  }
                }}
              />
            )}
          </>
        )}
      </Grid>
    </>
  );
}
