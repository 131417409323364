import * as chrono from "chrono-node";
import { format } from "date-fns";
import extractNumbers from "extract-numbers";
import { toWords } from "number-to-words";

/**
 * Attempts to extract a ISO string date from any given text. If no date is
 * found, returns empty string.
 *
 * @param {string} text
 * @returns {string} date string in the ISO format.
 */
export const extractIsoStringDateFromText = (text) => {
  const date = chrono.parseDate(text);
  if (!date) return "";

  const isoStringDate = date.toISOString();
  return isoStringDate;
};

/**
 * Attempts to extract a date from any given text. If no date is
 * found, returns current date.
 *
 * @param {string} text
 * @returns {string} date in the format "yyyy-MM-dd".
 */
export const extractFormattedDateFromText = (text) => {
  const parsedDate = chrono.parseDate(text);
  if (!parsedDate) return format(new Date(), "yyyy-MM-dd");

  return format(parsedDate, "yyyy-MM-dd");
};

/**
 * Attempts to extract a number from any given text. If no number is
 * found, returns an empty string. We assum that a "," is used to divide
 * integer parts and the "." to divide the decimal part.
 *
 * @param {string} text
 * @returns {string}
 */
export const extractNumberFromText = (text) => {
  const treatedText = text.replaceAll(",", "").replaceAll("'", "");
  const numbers = extractNumbers(treatedText);
  if (numbers && numbers.length) {
    const firstNumber = numbers[0];
    return firstNumber.toString();
  } else {
    return "";
  }
};

/**
 * Checks whether a give Merge Field value is valid i.e., contains a valid value
 * or a combination of values that results in a valid value.
 *
 * @param {import("../editor/nodes/MarkNode").MergeFieldValue} mergeFieldValue
 * @returns {boolean}
 */
export const mergeFieldValueIsValid = (mergeFieldValue) => {
  const isValid = Boolean(getMergeFieldDisplayValue(mergeFieldValue));
  return isValid;
};

// /**
//  *
//  * @param {string} string
//  * @returns
//  */
// function capitalizeFirstLetter(string) {
//   return string.charAt(0).toUpperCase() + string.slice(1);
// }

/**
 * Returns the appropriate Merge Field value based on the type
 * of the Merge Field.
 *
 * @param {import("../editor/nodes/MarkNode").MergeFieldValue} mergeFieldValue
 * @returns {string}
 */
export const getMergeFieldDisplayValue = (mergeFieldValue) => {
  const mergeFieldValueType = mergeFieldValue.type;
  switch (mergeFieldValueType) {
    case "freeText": {
      const displayValue = mergeFieldValue.value;
      return displayValue;
    }
    case "date": {
      const date = new Date(mergeFieldValue.value);
      if (!isNaN(date.getDate())) {
        const displayValue = format(date, "dd/MM/yyyy");
        return displayValue;
      }
      return "";
    }
    case "duration": {
      const number = Number(mergeFieldValue.durationValue);
      const durationUnit = mergeFieldValue.durationUnit;
      if (number && durationUnit.value) {
        const word = toWords(number);
        let durationUnitLabel = durationUnit.label;

        // If the duration is one (1) we need to remove the "s" from
        // the duration unit (e.g., one (1) day instead of one (1) days).
        if (number === 1) {
          durationUnitLabel = durationUnitLabel.slice(0, -1);
        }

        const displayValue = `${word} (${number.toLocaleString(
          "en-GB"
        )}) ${durationUnitLabel.toLowerCase()}`;
        return displayValue;
      }
      return "";
    }
    case "number": {
      const number = Number(mergeFieldValue.value);
      if (number) {
        // This logic will be reused once we implement proper formatting.
        // const word = toWords(number);
        // const displayValue = `${word} (${number})`;
        const displayValue = number.toLocaleString("en-GB");
        return displayValue;
      }
      return "";
    }
    case "currency": {
      const number = Number(mergeFieldValue.currencyValue);
      const durationUnit = mergeFieldValue.currencyUnit;
      if (number && durationUnit.value) {
        const formattedNumber = number.toLocaleString("en-GB", {
          minimumFractionDigits: 2,
        });

        const displayValue = `${durationUnit.label} ${formattedNumber}`;
        return displayValue;
      }
      return "";
    }
    case "percentage": {
      const number = Number(mergeFieldValue.value);
      if (number) {
        const displayValue = `${number.toLocaleString("en-GB")}%`;
        return displayValue;
      }
      return "";
    }
    default: {
      throw new Error(`Invalid Merge Field type: ${mergeFieldValueType}.`);
    }
  }
};

/**
 * @param {"number" | "duration" | "percentage" | "freeText" | "date" | "currency" | string} mergeFieldValueType
 * @returns {import("../editor/nodes/MarkNode").MergeFieldValue}
 */
export const getMergeFieldDefaultValue = (mergeFieldValueType) => {
  switch (mergeFieldValueType) {
    case "freeText": {
      return {
        type: "freeText",
        value: "",
      };
    }
    case "date": {
      return {
        type: "date",
        value: "",
      };
    }
    case "duration": {
      return {
        type: "duration",
        durationValue: "",
        durationUnit: {
          label: "",
          value: "",
        },
      };
    }
    case "number": {
      return {
        type: "number",
        value: "",
      };
    }
    case "currency": {
      return {
        type: "currency",
        currencyValue: "",
        currencyUnit: {
          label: "",
          value: "",
        },
      };
    }
    case "percentage": {
      return {
        type: "percentage",
        value: "",
      };
    }
    default: {
      throw new Error(`Invalid Merge Field type: ${mergeFieldValueType}.`);
    }
  }
};

/**
 * Returns the appropriate Merge Field value based on the type
 * of the Merge Field.
 *
 * @param {import("../editor/nodes/MarkNode").MergeFieldValue} mergeFieldValue
 * @returns {string}
 */
export const getMergeFieldValue = (mergeFieldValue) => {
  const type = mergeFieldValue.type;

  switch (type) {
    case "freeText": {
      return mergeFieldValue.value;
    }
    case "date": {
      return mergeFieldValue.value;
    }
    case "duration": {
      return mergeFieldValue.durationValue;
    }
    case "number": {
      return mergeFieldValue.value;
    }
    case "currency": {
      return mergeFieldValue.currencyValue;
    }
    case "percentage": {
      return mergeFieldValue.value;
    }
    default: {
      throw new Error(`Invalid Merge Field type: ${type}.`);
    }
  }
};

/**
 * @param {import("./dialogs/NewConditionDialog/condition").Condition} condition
 */
export const getConditionSummary = (condition) => {
  const summary = {
    baseValueName: condition.baseValue.name,
    conditions: [
      {
        operatorLabel: condition.operator.label,
        comparisonValues: condition.comparisonValue,
      },
    ],
    value: getMergeFieldDisplayValue(condition.mergeFieldValue),
  };

  return summary;
};

/**
 * @param {import("./dialogs/NewConditionDialog/condition").Condition} condition
 */
export const getExhibitsConditionSummary = (condition) => {
  const summary = {
    baseValueName: condition.baseValue.name,
    conditions: [
      {
        operatorLabel: condition.operator.label,
        comparisonValues: condition.comparisonValue,
      },
    ],
  };

  return summary;
};
