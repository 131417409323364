import { faFileLines } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { globalStore } from "../state/store";
import DialogAgreementType from "./dialogs/DialogAgreementType";

const filter = createFilterOptions();

/**
 * @typedef {*} SelectOrCreateAgrTypeProps
 */

/**
 * @param {SelectOrCreateAgrTypeProps} _
 * @returns {React.JSX.Element}
 */
export default function SelectOrCreateAgrType({
  agrType,
  width,
  isExhibit,
  canCreate,
  setAgrType,
  justifyContent = "center",
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [creatingType, setCreatingType] = useState(
    /** @type {* | null} */ (null)
  );
  const [options, setOptions] = useState(/** @type {* | []} */ ([]));

  useEffect(() => {
    const filteredOptions = state.agrTypes
      .filter(
        (/** @type {{ active: boolean; shortName: string; }} */ at) =>
          at.active && at.shortName !== "EWAT"
      )
      .sort(
        (
          /** @type {{ fullName: number[]; }} */ a,
          /** @type {{ fullName: number[]; }} */ b
        ) => (a.fullName[0] > b.fullName[0] ? 1 : -1)
      );

    const exhibitOption = state.agrTypes.find(
      (/** @type {{ shortName: string; }} */ at) => at.shortName === "EWAT"
    );

    if (isExhibit) {
      setOptions([exhibitOption, ...filteredOptions]);
    } else {
      setOptions(filteredOptions);
    }
  }, [isExhibit, state.agrTypes]);

  /**
   * @param {*} _
   * @param {*} newValue
   */
  const onChange = (_, newValue) => {
    if (typeof newValue === "string") {
      setCreatingType({ longName: newValue });
    } else if (
      newValue &&
      (newValue.inputValue || newValue.inputValue === "")
    ) {
      // Create a new value from the user input.
      setCreatingType({ longName: newValue.inputValue });
    } else {
      setAgrType("agrType", newValue);
    }
  };

  /**
   * @param {*} options
   * @param {*} params
   */
  const filteredOptions = (options, params) => {
    const filtered = filter(options, params);
    if (!canCreate) return filtered;

    const { inputValue } = params;
    // Suggest the creation of a new value.
    const isExisting = options.some(
      (/** @type {{ fullName: string[]; }} */ option) =>
        inputValue === option.fullName[0]
    );
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        inputValue,
        title: `Create "${inputValue}"`,
      });
    } else if (params.inputValue === "") {
      filtered.unshift({
        inputValue,
        title: `Create New Agreement Type`,
      });
    }

    return filtered;
  };

  /**
   * @param {*} option
   */
  const getOptionLabel = (option) => {
    // Value selected with enter, right from the input.
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically.
    if (option.inputValue || option.newName) {
      return option.newName ? option.newName : option.inputValue;
    }
    // Regular option.
    return option.fullName !== undefined
      ? option.fullName[0]
      : state.agrTypes.filter(
          (/** @type {{ _id: string; }} */ at) => at._id === option.agrTypeID
        )[0] !== undefined
      ? state.agrTypes.filter(
          (/** @type {{ _id: string; }} */ at) => at._id === option.agrTypeID
        )[0].fullName[0]
      : "Please select ...";
  };

  /**
   * @param {*} props
   * @param {*} option
   */
  const renderOption = (props, option) => (
    <Typography
      variant={option.fullName ? "body1" : "subtitle1"}
      color="textPrimary"
      {...props}
    >
      {option.fullName !== undefined ? option.fullName[0] : option.title}
    </Typography>
  );

  /**
   * @param {*} params
   */
  const renderInput = (params) => (
    <TextField
      {...params}
      required
      label="Agreement Type"
      placeholder="Agreement Type ..."
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <>
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faFileLines} />
            </InputAdornment>
            {params.InputProps.startAdornment}
          </>
        ),
      }}
    />
  );

  return (
    <>
      <Grid container justifyContent={justifyContent}>
        <Grid item sx={{ my: 2, width: width }}>
          <Autocomplete
            value={agrType}
            onChange={onChange}
            filterOptions={filteredOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={options}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            freeSolo
            renderInput={renderInput}
          />
        </Grid>
      </Grid>

      <DialogAgreementType
        open={!!creatingType}
        type={creatingType}
        handleCreation={(/** @type {*} */ newType) =>
          setAgrType("agrType", newType)
        }
        closeDialog={() => setCreatingType(null)}
      />
    </>
  );
}
