// Template reducer is used for the state of the template screen
const templateReducer = (state, action) => {
  switch (action.type) {
    case "INIT_TEMPLATE":
      return { template: action.payload };
    case "ADD_TEMPLATE":
      return { template: [...state.template, action.payload] };
    case "UPDATE_TEMPLATE":
      const updatedTemplate = state.template.map((template) => {
        if (template._id === action.payload._id) {
          return { ...action.payload, origin: template.origin };
        }
        return template;
      });
      return { template: updatedTemplate };
    case "DELETE_TEMPLATE":
      const filteredTemplate = state.template.filter(
        (t) => t._id !== action.payload
      );
      return { template: filteredTemplate };
    default:
      return { template: state.template };
  }
};

export default templateReducer;
