import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { Box } from "@mui/material";
import {
  $createLineBreakNode,
  $createTextNode,
  $getRoot,
  CLEAR_EDITOR_COMMAND,
  KEY_ESCAPE_COMMAND,
} from "lexical";
import React, { useEffect } from "react";
import { MentionNode } from "../../nodes/MentionNode";
import { useLayoutEffect } from "../../utils";
import MentionsPlugin from "../MentionsPlugin";

/**
 * @param {*} props
 * @returns {null}
 */
function EditorRefPlugin({ editorRef }) {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    editorRef.current = editor;
    return () => {
      editorRef.current = null;
    };
  }, [editor, editorRef]);

  return null;
}

/**
 * @param {*} props
 * @returns {null}
 */
function EditingPlugin({ editing }) {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () => {
      if (editing) {
        console.log("clear x1");
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        editor.update(() => {
          let pars = editing.content.split("\n");
          let root = $getRoot();
          /**
           * @type {(import("lexical").TextNode | import("lexical").LineBreakNode)[]}
           */
          let tNodes = [];
          pars.forEach(
            (
              /** @type {string | undefined} */ par,
              /** @type {number} */ i
            ) => {
              if (i > 0) {
                tNodes.push($createLineBreakNode());
              }
              tNodes.push($createTextNode(par));
            }
          );
          let firstNode = root.getChildren()[0];
          if (Boolean(firstNode) && firstNode.getType() === "paragraph") {
            let selection = firstNode.select();
            selection.insertNodes(tNodes);
          }
        });
      } else {
        editor.getEditorState().read(() => {
          let root = $getRoot();
          if (
            Boolean(root.getTextContent()) &&
            root.getTextContent().length > 0
          ) {
            editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editing]
  );

  return null;
}

/**
 * @param {*} props
 * @returns {null}
 */
function EscapeHandlerPlugin({ onEscape }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      KEY_ESCAPE_COMMAND,
      (event) => {
        return onEscape(event);
      },
      2
    );
  }, [editor, onEscape]);

  return null;
}

/**
 * @param {*} props
 * @returns {JSX.Element}
 */
export function PlainTextEditor({
  autoFocus,
  onEscape,
  onChange,
  editorRef,
  placeholder = "Type a comment ...",
  isReply,
  isApproval,
  editing,
  isTemplate,
  docID,
  visibleTo,
  ...props
}) {
  /**
   * @type {import("@lexical/react/LexicalComposer").InitialConfigType}
   */
  const initialConfig = {
    namespace: "CommentEditor",
    nodes: [MentionNode],
    onError: (/** @type {any} */ error) => {
      throw error;
    },
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <Box className="CommentPlugin_CommentInputBox_EditorContainer" {...props}>
        <PlainTextPlugin
          contentEditable={
            <ContentEditable
              className={
                isReply ? "ContentEditable__rootReply" : "ContentEditable__root"
              }
              spellCheck={false}
            />
          }
          placeholder={
            <div
              className={"Placeholder__root"}
              style={isReply ? { top: "15px" } : {}}
            >
              {placeholder}
            </div>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        {!isApproval && (
          <MentionsPlugin
            isTemplate={isTemplate}
            docID={docID}
            allowedOrgs={visibleTo}
          />
        )}
        <EditingPlugin editing={editing} />
        <OnChangePlugin onChange={onChange} />
        <HistoryPlugin />
        {autoFocus !== false && <AutoFocusPlugin />}
        <EscapeHandlerPlugin onEscape={onEscape} />
        <ClearEditorPlugin />
        {editorRef !== undefined && <EditorRefPlugin editorRef={editorRef} />}
      </Box>
    </LexicalComposer>
  );
}
