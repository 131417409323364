import React from "react";
import theme from "../../theme/theme";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";

export default function DialogConfirmDelete({
  open,
  title,
  message,
  handleClose,
  handleConfirm,
}) {
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
          <IconButton onClick={handleClose}>
            <FontAwesomeIcon
              icon={faTimes}
              style={{ padding: "4px 7px", fontSize: "20px" }}
            />
          </IconButton>
        </Box>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ my: 3 }}>
          <Box
            sx={{
              textAlign: "center",
              mb: 2,
              fontSize: "30px",
              color: theme.palette.primary.main,
            }}
          >
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography align="center">{message}</Typography>
          </Box>
          <Typography variant="subtitle1" align="center">
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ marginRight: "auto" }}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} variant="contained" disableElevation>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
