import { isValid } from "date-fns";

/**
 * @param {import("../../../components/MergeFieldMenu/dialogs/NewConditionDialog/condition").Condition} condition
 */
export const isEarlierThanOrEqual = ({
  baseValue,
  comparisonValue,
  mergeFieldValue,
}) => {
  if (baseValue.value.type !== "date") {
    throw new Error(
      `Base value type '${baseValue.value.type}' is not a valid type for operator 'isEarlierThanOrEqual'.`
    );
  }

  if (
    comparisonValue instanceof Array ||
    comparisonValue.type !== baseValue.value.type
  ) {
    throw new Error(
      `Comparison value needs to be of type '${baseValue.value.type}'.`
    );
  }

  const baseValueDate = new Date(baseValue.value.value);
  if (!isValid(baseValueDate)) {
    throw new Error("Invalid base value date.");
  }

  const comparisonValueDate = new Date(comparisonValue.value);
  if (!isValid(comparisonValueDate)) {
    throw new Error("Invalid comparison value date.");
  }

  if (baseValueDate <= comparisonValueDate) {
    return mergeFieldValue;
  }
};
