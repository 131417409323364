import {
  faArrowLeft,
  faCheck,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import axios from "axios";
import React, { useContext } from "react";
import { CanveoCircularProgress, ThumbAgr, ThumbExhibitView } from ".";
import { globalStore } from "../state/store";
import { randomString as generateRandomString } from "../utils";
import { getExhibit } from "../utils/agreementCreation";

/**
 * @param {*} props
 * @returns {JSX.Element}
 */
export default function DialogExhibits({
  open,
  ags,
  isTemplate,
  mainBody,
  closeDialog,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [loading, setLoading] = React.useState(false);
  const [addingExhibit, setAddingExhibit] = React.useState(false);
  const [stagedExhibits, setStagedExhibits] = React.useState(
    ags
      .filter((/** @type {{ parentID: any; }} */ a) => a.parentID)
      .sort(
        (
          /** @type {{ priority: number; }} */ a,
          /** @type {{ priority: number; }} */ b
        ) => (a.priority > b.priority ? 1 : -1)
      )
  );
  const [isDirty, setIsDirty] = React.useState(false);

  const originalExhibits = ags.filter(
    (/** @type {{ parentID: any; }} */ a) => a.parentID
  );

  const reInitalize = () => {
    setLoading(false);
    setIsDirty(false);
    setAddingExhibit(false);
    setStagedExhibits(
      ags.filter((/** @type {{ parentID: null; }} */ a) => a.parentID !== null)
    );
  };

  const handleCloseDialog = () => {
    closeDialog();
    reInitalize();
  };

  // eslint-disable-next-line no-unused-vars
  const handleSelectOrUploadTemplate = (
    /** @type {any} */ type,
    /** @type {{ isNew: boolean; tempRef: string; map: (arg0: (a: any) => any) => any; }} */ newAg
  ) => {
    // type: main, exhibit

    let newExhibits = stagedExhibits;
    newAg.isNew = true;
    newAg.tempRef = generateRandomString(20);
    if (Array.isArray(newAg)) {
      newExhibits.push(...newAg.map((a) => ({ ...a, isNew: true })));
    } else newExhibits.push(newAg);
    // TODO something with priority
    setStagedExhibits(newExhibits);
    setAddingExhibit(false);
    setIsDirty(true);
  };

  const handleFinalizeUpdateExhibits = () => {
    setLoading(true);

    const url = isTemplate ? "template" : "agr";

    let creationDate = new Date().toISOString();

    /**
     * @type {any[]}
     */
    let deletedExhibits = [];
    /**
     * @type {any[]}
     */
    let changedExhibits = [];

    originalExhibits.forEach(
      (/** @type {{ _id: any; priority: number; }} */ oex) => {
        if (
          !stagedExhibits.find(
            (/** @type {{ _id: any; }} */ uex) => uex._id === oex._id
          )
        ) {
          deletedExhibits.push(oex._id); // Deleted exhibits
        } else {
          let sexIdx = stagedExhibits.findIndex(
            (/** @type {{ _id: any; }} */ sex) => sex._id === oex._id
          );
          let sexPrio = (sexIdx + 1) * 10;
          if (oex.priority !== sexPrio) {
            let newEx = stagedExhibits.filter(
              (/** @type {{ _id: any; }} */ sex) => sex._id === oex._id
            )[0];
            if (newEx) {
              newEx.priority = sexPrio;
              changedExhibits.push(newEx);
            }
          }
        }
      }
    );

    // Newly added exhibits.
    const newExhibits = stagedExhibits.filter(
      (/** @type {{ isNew: any; }} */ uex) => uex.isNew
    );

    deletedExhibits.forEach((dex) => {
      axios.delete(`${state.settings.api + url}/${dex}`);
    });

    changedExhibits.forEach((cex) => {
      const payload = isTemplate ? { template: cex } : { agr: cex };
      axios.put(`${state.settings.api + url}/${cex._id}`, payload);
    });

    const mainAg = ags.find(
      (/** @type {{ parentID: any; }} */ a) => !a.parentID
    );
    if (!mainAg) {
      throw new Error("Main agreement does not exist.");
    }

    const description = `Contains work done by ${state.org.shortName}`;
    newExhibits.forEach((/** @type {{ tempRef: any; }} */ newExhibit) => {
      const index = stagedExhibits.findIndex(
        (/** @type {{ tempRef: any; }} */ e) => e.tempRef === newExhibit.tempRef
      );
      const exhibitData = {
        ...newExhibit,
        priority: (index + 1) * 10,
        parentID: mainAg._id,
        description: description,
        organizations: mainAg.orgs,
        entities: mainAg.ents,
      };

      const exhibit = getExhibit(
        exhibitData,
        creationDate,
        state.user._id,
        state.org._id
      );

      let payload;
      if (isTemplate) {
        payload = { template: exhibit };
      } else {
        payload = { agr: exhibit };
      }

      axios.post(state.settings.api + url, payload);
    });

    setTimeout(function () {
      handleCloseDialog();
      // window.location.reload(true);
    }, 2000); // Wait for 2 seconds
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={handleCloseDialog}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>{loading ? "Loading ..." : "Manage Exhibits"}</DialogTitle>

      <DialogContent>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 15,
            }}
          >
            <CanveoCircularProgress />
          </Box>
        ) : (
          <Box sx={{ mt: 4, mb: 2 }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              sx={{ minHeight: "300px" }}
            >
              <Grid item container justifyContent="center">
                <ThumbAgr
                  ag={mainBody}
                  actionReq={mainBody?.avOwners?.includes(state.org._id)}
                  thumbClick={null}
                  showLogo={state.org.logoURL}
                  primaryLegalName={""}
                  secondaryLegalName={""}
                  isTemplate={isTemplate}
                />
              </Grid>

              <Grid item container justifyContent="center" sx={{ mb: 1 }}>
                <ThumbExhibitView
                  exhibits={stagedExhibits}
                  handleUpdate={(/** @type {any} */ newExhibits) => {
                    setStagedExhibits([...newExhibits]);
                    setIsDirty(true);
                  }}
                  preventDelete
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        {addingExhibit ? (
          <Button
            sx={{ marginRight: "auto" }}
            onClick={() => setAddingExhibit(false)}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            &nbsp;&nbsp;Go back
          </Button>
        ) : (
          <Button sx={{ marginRight: "auto" }} onClick={handleCloseDialog}>
            Cancel
          </Button>
        )}

        <Button
          variant="contained"
          disableElevation
          disabled={addingExhibit || !isDirty || loading}
          onClick={handleFinalizeUpdateExhibits}
        >
          Update Exhibits&nbsp;&nbsp;
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
