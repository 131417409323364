const fileUploadReducer = (state, action) => {
  switch (action.type) {
    case "FILE_UPLOAD":
      return { fileUpload: action.payload };
    default:
      return { fileUpload: state.fileUpload };
  }
};

export default fileUploadReducer;
