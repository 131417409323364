import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { globalStore } from "../../state/store";

/**
 * @param {*} props
 */
export default function DialogIntegrationSettings({
  open,
  currentIntegration,
  closeDialog,
  pushOrg,
}) {
  const [state] = /** @type {*} */ (useContext(globalStore));

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={closeDialog}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>
      <DialogTitle>Integration with {currentIntegration.name}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={2}>
            <Switch
              checked={state?.org?.integrations?.docusign?.useOwnSubscription}
              color="secondary"
              onChange={(_, checked) => {
                const orgToUpdate = { ...state.org };

                orgToUpdate.integrations.docusign.useOwnSubscription = checked;

                pushOrg(orgToUpdate);
              }}
            />
          </Grid>

          <Grid item xs={10}>
            <Box sx={{ fontWeight: "400", fontSize: "20px" }}>
              Use my own Docusign subscription
            </Box>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <Typography variant="body2" color={"#989898"}>
              If turned on, all contracts in Canveo will be signed using your
              DocuSign subscription.
              <br />
              <br />
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
