import { $isTextNode } from "lexical";

export function hasRedline(node) {
  let isRedline = false;
  if (["redline"].includes(node.getType())) {
    isRedline = true;
  } else if (
    !$isTextNode(node) &&
    Boolean(node.getChildren()) &&
    node.getChildren().length > 0
  ) {
    node.getChildren().forEach((n) => {
      if (hasRedline(n)) {
        isRedline = true;
      }
    });
  }
  return isRedline;
}
