import {
  faImage,
  faSearchPlus,
  faSyncAlt,
  faUserCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Slider,
} from "@mui/material";
import axios from "axios";
import React, { useContext } from "react";
import AvatarEditor from "react-avatar-editor";
import { globalStore } from "../state/store";
import theme from "../theme/theme";

const styles = {
  greyIcon: {
    color: theme.palette.grey[600],
  },
  input: {
    display: "none",
  },
  iconSize: {
    fontSize: "100px",
  },
  iconButtonSize: {
    padding: 30,
  },
  avatarEdit: {
    margin: "40px 0px 40px 0px",
    height: "150px",
    width: "150px",
  },
  avatarOrgEdit: {
    margin: "40px 0px 40px 0px",
    height: "150px",
    width: "150px",
  },
};

/**
 * @typedef {*} UploadImgProps
 */

/**
 * @param {UploadImgProps} props
 * @returns {React.JSX.Element}
 */
export default function UploadImg(props) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const editor = React.createRef();
  const [zoom, setZoom] = React.useState(1.0); // default zoom is 0% (range -50% to +50%)
  const [rotate, setRotate] = React.useState(0);
  const [submitting, setSubmitting] = React.useState(false);
  const [img, setImg] = React.useState(null);

  /**
   * @param {*} event
   * @param {*} newValue
   */
  const handleZoomChange = (event, newValue) => {
    setZoom(newValue);
  };

  /**
   * @param {*} event
   */
  const handleRotateChange = (event) => {
    setRotate(rotate + 90);
  };

  const clearUpload = () => {
    setImg(null);
    if (props.setLogoCanvas !== undefined && props.setLogoCanvas !== null) {
      props.setLogoCanvas(false);
    }
  };

  /**
   * @param {*} event
   */
  const handleUploadChange = (event) => {
    setImg(event.target.files[0]);
    if (props.setLogoCanvas !== undefined && props.setLogoCanvas !== null) {
      props.setLogoCanvas(true);
    }
  };

  /**
   * @param {*} event
   */
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      setSubmitting(true);

      const dataURL = editor.current.getImageScaledToCanvas().toDataURL();
      const blob = dataURItoBlob(dataURL);

      const uploadSignedUrlResponse = await axios.post(
        state.settings.api + "upload/signedUrl",
        {
          contentType: blob.type,
          bucketAlias: "images",
        }
      );

      const { uploadSignedUrl, publicUrl } = uploadSignedUrlResponse.data.data;

      await fetch(uploadSignedUrl, {
        method: "PUT",
        body: blob,
      });

      setSubmitting(false);
      setImg(null);

      if (props.setLogoCanvas !== undefined && props.setLogoCanvas !== null) {
        props.setLogoCanvas(false);
      }

      props.handleSubmitImage(publicUrl);
    } catch (error) {
      props.handleSubmitImage(null);
    }
  };

  /**
   * @param {string} dataURI
   */
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
      byteString = atob(dataURI.split(",")[1]);
    } else {
      byteString = unescape(dataURI.split(",")[1]);
    }

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  /**
   * @param {{ type: string; }} props
   * @returns {React.JSX.Element}
   */
  function RenderIconButton({ type }) {
    if (type === "avatar") {
      return <FontAwesomeIcon icon={faUserCircle} style={styles.iconSize} />;
    } else {
      return <FontAwesomeIcon icon={faImage} style={styles.iconSize} />;
    }
  }

  return (
    <div>
      {img === null ? (
        <div>
          <input
            type="file"
            accept="image/*"
            id="icon-button-file"
            onChange={handleUploadChange}
            style={styles.input}
          />
          <label htmlFor="icon-button-file">
            {props.initialImg !== null ? (
              <Avatar
                src={props.initialImg}
                alt="Current Image"
                style={
                  props.type === "avatar"
                    ? {
                        margin: "40px 0px 40px 0px",
                        height: "120px",
                        width: "120px",
                        cursor: "pointer",
                      }
                    : {
                        margin: "40px 0px 40px 0px",
                        height: "120px",
                        width: "120px",
                        cursor: "pointer",
                        backgroundColor: "#ffffff",
                      }
                }
              />
            ) : (
              <IconButton component="span" style={styles.iconButtonSize}>
                <RenderIconButton type={props.type} />
              </IconButton>
            )}
          </label>
        </div>
      ) : (
        <div>
          <AvatarEditor
            image={img}
            width={props.cardUpdating ? 100 : 200}
            height={props.cardUpdating ? 100 : 200}
            border={props.cardUpdating ? 25 : 50}
            color={
              props.type === "avatar"
                ? [255, 255, 255, 0.7]
                : [126, 80, 223, 0.7]
            } // RGBA
            scale={zoom}
            borderRadius={props.cardUpdating ? 50 : 100}
            rotate={rotate}
            ref={editor}
          />

          <Box width={props.cardUpdating ? 160 : 320}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={2}>
                <FontAwesomeIcon icon={faSearchPlus} style={styles.greyIcon} />
              </Grid>

              <Grid item xs={8}>
                <Slider
                  value={zoom}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="off"
                  step={0.005}
                  min={0.8}
                  max={1.5}
                  onChange={handleZoomChange}
                />
              </Grid>

              <Grid item xs={2}>
                {props.type === "avatar" ? (
                  <IconButton color="primary" onClick={handleRotateChange}>
                    <FontAwesomeIcon icon={faSyncAlt} />
                  </IconButton>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Box>

          <Grid container justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleSubmit}
              disabled={submitting}
              style={
                props.cardUpdating
                  ? { marginTop: "30px", width: "150px" }
                  : { margin: "20px 0px 10px 0px", width: "250px" }
              }
              size={props.cardUpdating ? "small" : "medium"}
            >
              {submitting && <CircularProgress size={24} />}
              {submitting ? "Submitting ..." : "Submit"}
            </Button>
          </Grid>

          <Grid container justifyContent="center" sx={{ mb: 3 }}>
            <Button
              size="small"
              variant="text"
              color="primary"
              onClick={clearUpload}
            >
              Clear Upload and retry
            </Button>
          </Grid>
        </div>
      )}
    </div>
  );
}
