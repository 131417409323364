import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot } from "lexical";
import { useContext, useEffect } from "react";
import SweetScroll from "sweet-scroll";
import { globalStore } from "../../../state/store";
import { $isClauseNode } from "../nodes/ClauseNode";

export default function ClauseTopicScrollerPlugin() {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();

  useEffect(
    () => {
      if (!state?.selectedClauseTopic?.clauseTopicId) return;

      const selectedClauseTopicId = state?.selectedClauseTopic?.clauseTopicId;

      editor.getEditorState().read(() => {
        const sectionNodes = $getRoot().getChildren();
        const clauseNodes = sectionNodes.flatMap((node) => node.getChildren());
        for (const clauseNode of clauseNodes) {
          if (!$isClauseNode(clauseNode)) continue;

          const clauseTypes = clauseNode.getClauseTypes();
          if (
            clauseTypes.some(
              (clauseType) => clauseType === selectedClauseTopicId
            )
          ) {
            const element = editor.getElementByKey(clauseNode.getKey());
            if (!element) return;

            const containerElement = document.getElementById(
              "playbook-drawer-editor-container"
            );
            if (!containerElement) return;

            const scroller = new SweetScroll(
              {
                duration: 800,
                offset: -30,
                easing: "easeInOutQuart",
              },
              containerElement
            );
            scroller.toElement(element);
            return;
          }
        }
      });
    },
    // This useEffect should only run whenever there are changes to state?.selectedClauseTopic?.clauseTopicId.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state?.selectedClauseTopic?.clauseTopicId]
  );

  return null;
}
