import { mergeRegister } from "@lexical/utils";
import {
  COMMAND_PRIORITY_CRITICAL,
  CUT_COMMAND,
  DELETE_LINE_COMMAND,
  DELETE_WORD_COMMAND,
  DRAGEND_COMMAND,
  DRAGOVER_COMMAND,
  DRAGSTART_COMMAND,
  DROP_COMMAND,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  KEY_DOWN_COMMAND,
  PASTE_COMMAND,
} from "lexical";
import { useContext, useEffect } from "react";
import { globalStore } from "../../../../../state/store";
import { getDefaultRedlineData } from "../../../utils/getDefaultRedlineData";
import { backspaceCommandHandler } from "./handlers/backspaceCommandHandler";
import { cutCommandHandler } from "./handlers/cutCommandHandler";
import { deleteCommandHandler } from "./handlers/deleteCommandHandler";
import { deleteLineCommandHandler } from "./handlers/deleteLineCommandHandler";
import { deleteWordCommandHandler } from "./handlers/deleteWordCommandHandler";
import { dragEndCommandHandler } from "./handlers/dragEndCommandHandler";
import { dragOverCommandHandler } from "./handlers/dragOverCommandHandler";
import { dragStartCommandHandler } from "./handlers/dragStartCommandHandler";
import { dropCommandHandler } from "./handlers/dropCommandHandler";
import { keyDownCommandHandler } from "./handlers/keyDownCommandHandler";
import { pasteCommandHandler } from "./handlers/pasteCommandHandler";

/**
 * Registers commands to enable redline (tracking changes) functionality on the editor.
 *
 * @param {import("lexical").LexicalEditor} editor
 * @param {PartyId} partyId
 * @param {PartialUser} user
 * @param {boolean} isEditable
 * @returns {void}
 */
export function useRedline(editor, partyId, user, isEditable) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  useEffect(
    () => {
      const defaultRedlineData = getDefaultRedlineData(partyId, user);
      return mergeRegister(
        editor.registerCommand(
          DROP_COMMAND,
          dropCommandHandler,
          COMMAND_PRIORITY_CRITICAL
        ),
        editor.registerCommand(
          DRAGSTART_COMMAND,
          dragStartCommandHandler,
          COMMAND_PRIORITY_CRITICAL
        ),
        editor.registerCommand(
          DRAGOVER_COMMAND,
          dragOverCommandHandler,
          COMMAND_PRIORITY_CRITICAL
        ),
        editor.registerCommand(
          DRAGEND_COMMAND,
          dragEndCommandHandler,
          COMMAND_PRIORITY_CRITICAL
        ),
        editor.registerCommand(
          KEY_DOWN_COMMAND,
          (event) =>
            keyDownCommandHandler(event, isEditable, state, defaultRedlineData),
          COMMAND_PRIORITY_CRITICAL
        ),
        editor.registerCommand(
          PASTE_COMMAND,
          (event) => pasteCommandHandler(event, editor, defaultRedlineData),
          COMMAND_PRIORITY_CRITICAL
        ),
        editor.registerCommand(
          CUT_COMMAND,
          (event) => cutCommandHandler(event, editor, defaultRedlineData),
          COMMAND_PRIORITY_CRITICAL
        ),
        editor.registerCommand(
          KEY_BACKSPACE_COMMAND,
          (event) => backspaceCommandHandler(event, defaultRedlineData),
          COMMAND_PRIORITY_CRITICAL
        ),
        editor.registerCommand(
          KEY_DELETE_COMMAND,
          (event) => deleteCommandHandler(event, defaultRedlineData),
          COMMAND_PRIORITY_CRITICAL
        ),
        editor.registerCommand(
          DELETE_WORD_COMMAND,
          (isBackwards) =>
            deleteWordCommandHandler(isBackwards, defaultRedlineData),
          COMMAND_PRIORITY_CRITICAL
        ),
        editor.registerCommand(
          DELETE_LINE_COMMAND,
          (isBackwards) =>
            deleteLineCommandHandler(isBackwards, defaultRedlineData),
          COMMAND_PRIORITY_CRITICAL
        )
      );
    },
    // This useEffect should only run when there are changes to the editor, partyId, user or the isEditable variable.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, partyId, user, isEditable]
  );
}
