import { $applyNodeReplacement, ParagraphNode } from "lexical";
import { GlobalListHandler } from "../plugins/ListExtendedPlugin/GlobalListHandler";
import { CUSTOM_PARAGRAPH_TYPE } from "../utils/constants";

/**
 * @typedef BaseSerializedCustomParagraphNode
 * @property {import("../types/lexical").LineSpacing} [lineSpacing]
 * @property {import("../types/lexical").ParagraphSpacing} [paragraphSpacing]
 * @property {import("../types/lexical").Indentation} [indentation]
 * @property {*} paragraphFormat
 */

/**
 * @typedef {BaseSerializedCustomParagraphNode & import("lexical").SerializedParagraphNode} SerializedCustomParagraphNode
 */

/**
 * @typedef {object} ParagraphFormat
 * @property {number} leftIndent
 * @property {number} firstLineIndent
 * @property {number} beforeSpacing
 * @property {number} afterSpacing
 * @property {string} outlineLevel
 * @property {object} listFormat
 * @property {number} listFormat.listId
 * @property {number} listFormat.nsid
 * @property {boolean} keepWithNext
 * @property {boolean} keepLinesTogether
 * @property {string} styleName
 */

export class CustomParagraphNode extends ParagraphNode {
  /** @type {import("../types/lexical").LineSpacing | undefined} */ __lineSpacing;
  /** @type {import("../types/lexical").ParagraphSpacing | undefined} */ __paragraphSpacing;
  /** @type {import("../types/lexical").Indentation | undefined} */ __indentation;
  /** @type {Partial<ParagraphFormat>} */ __paragraphFormat = {};

  static getType() {
    return CUSTOM_PARAGRAPH_TYPE;
  }

  /**
   * @param {import("../types/lexical").LineSpacing} [lineSpacing]
   * @param {import("../types/lexical").ParagraphSpacing} [paragraphSpacing]
   * @param {import("../types/lexical").Indentation} [indentation]
   * @param {string} [key]
   */
  constructor(lineSpacing, paragraphSpacing, indentation, key) {
    super(key);
    this.__lineSpacing = lineSpacing;
    this.__paragraphSpacing = paragraphSpacing;
    this.__indentation = indentation;
  }

  /**
   * @param {CustomParagraphNode} node
   * @returns {CustomParagraphNode}
   */
  static clone(node) {
    const customParagraphNode = new CustomParagraphNode(
      node.__lineSpacing,
      node.__paragraphSpacing,
      node.__indentation,
      node.__key
    );

    customParagraphNode.__format = node.__format;
    customParagraphNode.__indent = node.__indent;
    customParagraphNode.__dir = node.__dir;
    customParagraphNode.__paragraphFormat = node.__paragraphFormat || {};

    return customParagraphNode;
  }

  /**
   * @param {CustomParagraphNode} node
   * @returns {CustomParagraphNode}
   */
  static copy(node) {
    const customParagraphNode = new CustomParagraphNode(
      node.__lineSpacing,
      node.__paragraphSpacing,
      node.__indentation
    );

    customParagraphNode.__format = node.__format;
    customParagraphNode.__indent = node.__indent;
    customParagraphNode.__dir = node.__dir;
    customParagraphNode.__paragraphFormat = node.__paragraphFormat || {};

    return customParagraphNode;
  }

  /** @returns {import("../types/lexical").LineSpacing | undefined} */
  get lineSpacing() {
    const self = this.getLatest();
    return self.__lineSpacing;
  }

  /** @returns {import("../types/lexical").ParagraphSpacing | undefined} */
  get paragraphSpacing() {
    const self = this.getLatest();
    return self.__paragraphSpacing;
  }

  /** @returns {import("../types/lexical").Indentation | undefined} */
  get indentation() {
    const self = this.getLatest();
    return self.__indentation;
  }

  /** @param {import("../types/lexical").LineSpacing} value */
  set lineSpacing(value) {
    const self = this.getWritable();
    self.__lineSpacing = value;
  }

  /** @param {import("../types/lexical").ParagraphSpacing} value */
  set paragraphSpacing(value) {
    const self = this.getWritable();
    self.__paragraphSpacing = value;
  }

  /** @param {import("../types/lexical").Indentation} value */
  set indentation(value) {
    const self = this.getWritable();
    self.__indentation = value;
  }

  /**
   * @param {*} value
   */
  setParagraphFormat(value = {}) {
    const self = this.getWritable();
    self.__paragraphFormat = value;
  }

  /**
   * @returns {Partial<ParagraphFormat>}
   */
  getParagraphFormat() {
    const self = this.getLatest();
    return self.__paragraphFormat;
  }

  /**
   * @param {import("lexical").EditorConfig} config
   * @returns {HTMLElement}
   */
  createDOM(config) {
    const htmlElement = super.createDOM(config);

    const formatType = this.getFormatType();
    if (formatType) htmlElement.style.textAlign = formatType;

    if (this.__indentation) {
      // eslint-disable-next-line no-unused-vars
      const { firstLineIndent, leftIndent, rightIndent, tabs } =
        this.__indentation;
      if (firstLineIndent) {
        htmlElement.style.textIndent = `${firstLineIndent}px`;
      }
      if (leftIndent) {
        htmlElement.style.marginInlineStart = `${leftIndent}px`;
      }
      if (rightIndent) {
        htmlElement.style.marginInlineEnd = `${rightIndent}px`;
      }
    }

    // Old logic.
    // if (this.__paragraphSpacing) {
    //   const { afterSpacing, beforeSpacing } = this.__paragraphSpacing;
    //   if (beforeSpacing) dom.style.paddingBlockStart = `${beforeSpacing}px`;
    //   if (afterSpacing) dom.style.paddingBlockEnd = `${afterSpacing}px`;
    // }

    const paragraphFormat = this.__paragraphFormat;
    if (paragraphFormat?.beforeSpacing) {
      // Old logic.
      // dom.style.marginBottom = `${paragraphFormat.beforeSpacing}px`;
      htmlElement.style.paddingBlockStart = `${paragraphFormat?.beforeSpacing}px`;
    }
    if (paragraphFormat?.afterSpacing) {
      // Old logic.
      // dom.style.marginBottom = `${paragraphFormat.afterSpacing}px`;
      htmlElement.style.paddingBlockEnd = `${paragraphFormat?.afterSpacing}px`;
    }
    // @ts-ignore
    if (paragraphFormat?.lineSpacing) {
      // @ts-ignore
      const lineSpacing = paragraphFormat?.lineSpacing;
      // @ts-ignore
      const lineSpacingType = paragraphFormat?.lineSpacingType;

      switch (lineSpacingType) {
        case "AtLeast":
        case "Exactly":
          htmlElement.style.lineHeight = `${lineSpacing.toPrecision(2)}px`;
          break;
        case "Multiple":
        default:
          // Arbitrarily adding 1 so that the line spacing looks closer to Word.
          htmlElement.style.lineHeight = lineSpacing;
          break;
      }
    }

    // Old logic.
    // // Fallback to previous way of doing line spacing.
    // else {
    //   if (this.__lineSpacing) {
    //     const { lineSpacing, lineSpacingType } = this.__lineSpacing;
    //     switch (lineSpacingType) {
    //       case "AtLeast":
    //       case "Exactly":
    //         dom.style.lineHeight = `${lineSpacing.toPrecision(2)}px`;
    //         break;
    //       case "Multiple":
    //       default:
    //         dom.style.lineHeight = lineSpacing.toPrecision(2);
    //         break;
    //     }
    //   }
    // }

    const blockParagraphFormat = this.getParagraphFormat();
    const listHandler = GlobalListHandler.getInstance();

    let beforeSpacing = -1;
    if (
      blockParagraphFormat &&
      typeof blockParagraphFormat.beforeSpacing === "number" &&
      Number.isFinite(blockParagraphFormat.beforeSpacing)
    ) {
      beforeSpacing = blockParagraphFormat.beforeSpacing;
    } else {
      const styleName = blockParagraphFormat?.styleName;

      const style = listHandler?.currentSfdt?.styles?.find(
        (style) => style?.name === styleName
      );

      if (style && style.paragraphFormat) {
        const styleParagraphFormat = style.paragraphFormat;
        if (
          styleParagraphFormat &&
          typeof styleParagraphFormat.beforeSpacing === "number" &&
          Number.isFinite(styleParagraphFormat.beforeSpacing)
        ) {
          beforeSpacing = styleParagraphFormat.beforeSpacing;
        }
      }
    }
    if (beforeSpacing > 0) htmlElement.style.marginTop = `${beforeSpacing}px`;

    let afterSpacing = -1;
    if (
      blockParagraphFormat &&
      typeof blockParagraphFormat.afterSpacing === "number" &&
      Number.isFinite(blockParagraphFormat.afterSpacing)
    ) {
      afterSpacing = blockParagraphFormat.afterSpacing;
    } else {
      const styleName = blockParagraphFormat?.styleName;

      const style = listHandler?.currentSfdt?.styles?.find(
        (style) => style?.name === styleName
      );

      if (style && style.paragraphFormat) {
        const styleParagraphFormat = style.paragraphFormat;
        if (
          styleParagraphFormat &&
          typeof styleParagraphFormat.afterSpacing === "number" &&
          Number.isFinite(styleParagraphFormat.afterSpacing)
        ) {
          afterSpacing = styleParagraphFormat.afterSpacing;
        }
      }
    }
    if (afterSpacing > 0) htmlElement.style.marginBottom = `${afterSpacing}px`;

    return htmlElement;
  }

  /**
   * @param {SerializedCustomParagraphNode} serializedNode
   * @returns {CustomParagraphNode}
   */
  static importJSON(serializedNode) {
    const p = new CustomParagraphNode(
      serializedNode.lineSpacing,
      serializedNode.paragraphSpacing,
      serializedNode.indentation
    );
    p.setFormat(serializedNode.format);
    p.setIndent(serializedNode.indent);
    p.setDirection(serializedNode.direction);
    p.__paragraphFormat = serializedNode.paragraphFormat || {};
    return p;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      lineSpacing: this.__lineSpacing,
      paragraphSpacing: this.__paragraphSpacing,
      indentation: this.__indentation,
      type: CUSTOM_PARAGRAPH_TYPE,
      paragraphFormat: this.__paragraphFormat,
    };
  }
}

/**
 * @param {import("../types/lexical").LineSpacing} [lineSpacing]
 * @param {import("../types/lexical").ParagraphSpacing} [paragraphSpacing]
 * @param {import("../types/lexical").Indentation} [indentation]
 * @returns {CustomParagraphNode}
 */
export function $createCustomParagraphNode(
  lineSpacing,
  paragraphSpacing,
  indentation
) {
  return $applyNodeReplacement(
    new CustomParagraphNode(lineSpacing, paragraphSpacing, indentation)
  );
}

/**
 * @param {import("lexical").LexicalNode | null | undefined} node
 * @returns {node is CustomParagraphNode}
 */
export function $isCustomParagraphNode(node) {
  return node instanceof CustomParagraphNode;
}
