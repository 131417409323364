import { Button, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ThumbExhibitView } from "../../../components";
import { globalStore } from "../../../state/store";
import PlaybookCard from "../../ManagePlaybooks/components/PlaybookCard";
import SelectOrUploadTemplate from "./SelectOrUploadTemplate";

/**
 * @typedef {*} TemplateSelectorProps
 */

/**
 * @param {TemplateSelectorProps} props
 * @returns {React.JSX.Element}
 */
export default function TemplateSelector({ agreement, handleAgreementChange }) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [addingExhibit, setAddingExhibit] = React.useState(false);

  /**
   * @param {string} type
   * @param {*} newAg
   */
  const handleSelectOrUploadTemplate = (type, newAg) => {
    // Make sure roles are always defined.
    if (!newAg?.roles) {
      newAg.roles = [];
    }
    // type: main, exhibit
    if (!newAg) return;
    if (type === "main") {
      return handleAgreementChange("template", {
        ...newAg,
        agrStatus: "Active",
      });
    }

    if (type === "exhibit") {
      const newExhibits = Array.isArray(newAg) ? [...newAg] : [newAg];
      const exhibits = agreement.exhibits?.length
        ? [...agreement.exhibits, ...newExhibits]
        : [...newExhibits];

      setAddingExhibit(false);
      handleAgreementChange("exhibits", exhibits);
    }
  };

  const resetTemplate = () => {
    handleAgreementChange("template", null);
    handleAgreementChange("exhibits", null);
  };

  if (agreement.template) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        alignContent="flex-start"
        sx={{
          px: 2,
          mt: 4,
        }}
      >
        {!addingExhibit && (
          <>
            {["import", "scratch"].includes(agreement.template.uploadType) ? (
              <PlaybookCard
                ag={{
                  ...agreement.template,
                  lastUpdateDate: "To be created",
                  agrStatus: "Draft",
                }}
                actionReq={
                  !["backload"].includes(agreement.template.uploadType)
                }
                thumbClick={null}
                showLogo={state.org.logoURL}
                primaryLegalName={""}
                secondaryLegalName={""}
                isTemplate
              />
            ) : (
              <PlaybookCard
                ag={agreement.template}
                actionReq={
                  !["backload"].includes(agreement.template.uploadType)
                }
                thumbClick={null}
                showLogo={state.org.logoURL}
                primaryLegalName={agreement.template.reference}
                secondaryLegalName={"Version " + agreement.template.version}
                isTemplate
              />
            )}

            {!!agreement.exhibits?.length && (
              <Grid item sx={{ mb: 1 }}>
                <ThumbExhibitView
                  exhibits={agreement.exhibits}
                  handleUpdate={(/** @type {*} */ newExhibits) =>
                    handleAgreementChange("exhibits", newExhibits)
                  }
                />
              </Grid>
            )}
          </>
        )}

        {!addingExhibit && (
          <Grid item sx={{ mt: 1 }}>
            <Button
              variant="text"
              sx={{ padding: "5px 25px" }}
              onClick={resetTemplate}
              color={addingExhibit ? "secondary" : "primary"}
            >
              <Typography color="grey.800">
                Pick a different template …
              </Typography>
            </Button>
          </Grid>
        )}
        {addingExhibit && !!agreement?.template && !!agreement?.exhibits && (
          <Grid item container justifyContent="center" sx={{ mt: 4 }}>
            <SelectOrUploadTemplate
              handleSelectOrUploadTemplate={handleSelectOrUploadTemplate}
              label={agreement.areaLabel}
              allowedIds={["import"]}
            />
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        px: 2,
      }}
    >
      <SelectOrUploadTemplate
        isMain
        handleSelectOrUploadTemplate={handleSelectOrUploadTemplate}
        label={agreement.areaLabel}
        allowedIds={["import"]}
      />
    </Grid>
  );
}
