import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_LOW,
  INDENT_CONTENT_COMMAND,
  INSERT_PARAGRAPH_COMMAND,
  OUTDENT_CONTENT_COMMAND,
} from "lexical";
import { useEffect, useLayoutEffect } from "react";
import { CustomListItemNode } from "../../nodes/CustomListItemNode";
import { preventEventPropagation } from "../TrackChangesPlugin/useRedline/utils/preventEventPropagation";
import { propagateEvent } from "../TrackChangesPlugin/useRedline/utils/propagateEvent";
import { PROPAGATE_EVENT } from "../TrackChangesPlugin/utils";
import { populateGlobalListHandler } from "./populateGlobalListHandler";
import useExtendedLists from "./useExtendedLists";
import { updateStyleName } from "./utils";

const ignoreSelectionChange = true;
const ignoreHistoryMergeTagChange = true;

/**
 * @typedef {ExtendedListProps & ListExtendedPluginPropsBase} ListExtendedPluginProps
 */

/**
 * @param {ListExtendedPluginProps} props
 * @returns {null}
 */
export default function ListExtendedPlugin(props) {
  const [editor] = useLexicalComposerContext();
  const {
    customHandleListInsertParagraph,
    customHandleIndentAndOutdent,
    updateValuesOnIndent,
    updateValuesOnOutdent,
  } = useExtendedLists({ ...props, editor });

  useEffect(
    () => {
      // Prevents the footer and header editors from populating the global list
      // handler which is a singleton. Currently, only the main body editor is
      // allowed to populate it.
      if (
        editor._config.namespace === "mainBody" &&
        props?.sfdt?.abstractLists
      ) {
        // console.log("ListExtendedPlugin > useEffect");
        populateGlobalListHandler(editor, props.sfdt);
      }
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useLayoutEffect(() => {
    return editor.registerUpdateListener(
      ({
        editorState,
        dirtyElements,
        dirtyLeaves,
        prevEditorState,
        tags,
        normalizedNodes,
      }) => {
        if (
          (ignoreSelectionChange &&
            dirtyElements.size === 0 &&
            dirtyLeaves.size === 0) ||
          (ignoreHistoryMergeTagChange && tags.has("history-merge")) ||
          prevEditorState.isEmpty()
        ) {
          return;
        }

        if (
          editor._config.namespace === "mainBody" &&
          props?.sfdt?.abstractLists
        ) {
          populateGlobalListHandler(editor, props.sfdt);

          // if (dirtyLeaves.size !== 0) {
          //   editor.update(() => {
          //     // const visitedListItems = new Set();
          //     for (const value of dirtyLeaves) {
          //       const node = $getNodeByKey(value);
          //       if (!node) continue;

          //       const customListItemNode = $getNearestNodeOfType(
          //         node,
          //         CustomListItemNode
          //       );
          //       if (!customListItemNode) continue;

          //       console.log("update all lists");

          //       // const customListItemNodeKey = customListItemNode.getKey();

          //       // if (visitedListItems.has(customListItemNodeKey)) continue;
          //       // visitedListItems.add(customListItemNodeKey);

          //       // customListItemNode.setValue(customListItemNode.getValue());
          //       const dfs = $dfs();
          //       for (const { node } of dfs) {
          //         if (!$isCustomListItemNode(node)) continue;

          //         node.setValue(node.getValue());
          //       }
          //       break;
          //     }
          //   });
          // }
        }
      }
    );
  }, [editor, props.sfdt]);

  useEffect(() => {
    return mergeRegister(
      // Need to address changes on list handler here somehow
      // opt 1: copy listhandler state and reverse
      // opt 2: might store prev state and just reinstate it here
      // editor.registerCommand(
      //   UNDO_COMMAND,
      //   (payload, editor) => {
      //     console.log(payload);
      //   },
      //   COMMAND_PRIORITY_HIGH
      // ),
      editor.registerCommand(
        INSERT_PARAGRAPH_COMMAND,
        () => {
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) {
            return PROPAGATE_EVENT;
          }

          const node = selection.anchor.getNode();
          if ($getNearestNodeOfType(node, CustomListItemNode)) {
            return customHandleListInsertParagraph(editor);
          }
          return PROPAGATE_EVENT;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      editor.registerCommand(
        INDENT_CONTENT_COMMAND,
        () => {
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) {
            return preventEventPropagation();
          }

          const selectionFocusNode = selection.focus.getNode();
          const customListItemNode = $getNearestNodeOfType(
            selectionFocusNode,
            CustomListItemNode
          );
          if (!customListItemNode) {
            return propagateEvent();
          }

          customHandleIndentAndOutdent(
            (/** @type {CustomListItemNode} */ block) => {
              const indent = block.getIndent();
              const level = indent + 1;
              if (level <= 8) {
                //0-8 => 9 levels
                //value before any change
                let previousListVal = block.getValue();
                /** Scenarios covered here
                 *
                 * 1. target moves to the end of next list
                 *  - value becomes plus 1 of last before it
                 *  - no need to update more values
                 *
                 * 2. target moves to beginning of next list
                 *  - value becomes the value of last before it
                 *  - next list need to be updated
                 *
                 * 3. target moves into middle of next list
                 *  - value becomes plus 1 of last before it
                 *  - next list need to be updated
                 *
                 * 4. target moves to new list
                 *  - value becomes 1
                 *
                 * Always update previous list
                 */

                //INDENT
                block.setIndent(level);

                updateValuesOnIndent(previousListVal, level, block);

                // We only want to update the styleName if the list ID is not -1.
                // if (block.getListId() !== -1) {
                updateStyleName(block, editor);
                // }
              }
            }
          );
          return true;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        OUTDENT_CONTENT_COMMAND,
        () => {
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) {
            return preventEventPropagation();
          }

          const selectionFocusNode = selection.focus.getNode();
          const customListItemNode = $getNearestNodeOfType(
            selectionFocusNode,
            CustomListItemNode
          );
          if (!customListItemNode) {
            return propagateEvent();
          }

          customHandleIndentAndOutdent(
            (/** @type {CustomListItemNode} */ block) => {
              const indent = block.getIndent();
              if (indent > 0) {
                const level = indent - 1;
                //INDENT
                block.setIndent(level);

                //change
                let previousListVal =
                  block.getPreviousSibling()?.getValue() ?? 1;
                updateValuesOnOutdent(previousListVal, level, block);
                updateStyleName(block, editor);
              }
            }
          );
          return true;
        },
        COMMAND_PRIORITY_LOW
      )
      /* editor.registerCommand(
        RESTART_NUMBERING_COMMAND,
        () => {
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) {
            return PROPAGATE_EVENT;
          }
          const node = selection.anchor.getNode();
          if (
            $isCustomListItemNode(node) ||
            $isCustomListItemNode(node.getParent()) ||
            $isCustomListItemNode(node.getParent().getParent())
          ) {
            const listItem = $isCustomListItemNode(node)
              ? node
              : $isCustomListItemNode(node.getParent())
              ? node.getParent()
              : node.getParent().getParent();
            if (!$isCustomListItemNode(listItem)) return PROPAGATE_EVENT;
            // @type {import("../nodes").CustomListNode} 
            const parentList = $findMatchingParent(listItem, (n) =>
              $isCustomListNode(n)
            );
            if (!parentList) {
              return PROPAGATE_EVENT;
            }
            parentList.setStart(1);
            updateChildrenListItemValue(parentList);
            return PREVENT_EVENT_PROPAGATION;
          }
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        CONTINUE_NUMBERING_COMMAND,
        () => {
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) {
            return PROPAGATE_EVENT;
          }
          const node = selection.anchor.getNode();
          if (
            $isCustomListItemNode(node) ||
            $isCustomListItemNode(node.getParent()) ||
            $isCustomListItemNode(node.getParent().getParent())
          ) {
            const listItem = $isCustomListItemNode(node)
              ? node
              : $isCustomListItemNode(node.getParent())
              ? node.getParent()
              : node.getParent().getParent();
            if (!$isCustomListItemNode(listItem)) return PROPAGATE_EVENT;
            // @type {import("../nodes").CustomListNode}
            const parentList = $findMatchingParent(listItem, (n) =>
              $isCustomListNode(n)
            );
            if (!parentList) {
              return PROPAGATE_EVENT;
            }
            const isNestedList = (node) => {
              return (
                $isCustomListItemNode(node) &&
                $isCustomListNode(node.getFirstChild())
              );
            };

            const dfs = $dfs($getRoot(), listItem),
              listDepth = $getListDepth(parentList),
              dfsFiltered = dfs.filter((el) => {
                if ($isCustomListItemNode(el.node) && !isNestedList(el.node)) {
                  const node = $getNodeByKey(el.node.__key);
                  return (
                    $getListDepth(node.getParent()) === listDepth &&
                    node.getKey() !== listItem.getKey()
                  );
                }
                return false;
              });
            if ($isCustomListItemNode(listItem)) {
              const max = Math.max(
                ...dfsFiltered.map((el) => el.node.getValue())
              );
              listItem.setValue(max + 1);
              let currVal = max + 1;
              for (const sibling of listItem.getNextSiblings()) {
                if ($isCustomListItemNode(sibling)) {
                  sibling.setValue(currVal + 1);
                  currVal++;
                }
              }
              return PREVENT_EVENT_PROPAGATION;
            }
            return PREVENT_EVENT_PROPAGATION;
          }
          return PROPAGATE_EVENT;
        },
        COMMAND_PRIORITY_EDITOR
      ) */
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  return null;
}
