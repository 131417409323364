const labelsReducer = (state, action) => {
  switch (action.type) {
    case "INIT_LABELS":
      return { labels: action.payload };
    case "ADD_LABEL":
      return {
        labels: [...state.labels, action.payload],
      };
    case "UPDATE_LABEL": {
      let newVal = action.payload;
      let newLabels = [...state.labels];
      let idx = state.labels.findIndex((item) => item._id === newVal._id);
      if (idx > -1) {
        newLabels[idx] = newVal;
      }
      return { labels: newLabels };
    }
    case "REMOVE_LABEL": {
      const labelToRemove = action.payload;
      const labels = state.labels.filter((l) => l._id !== labelToRemove._id);
      return { labels: labels };
    }
    default:
      return { labels: state.labels };
  }
};

export default labelsReducer;
