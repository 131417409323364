/**
 * @type {ClauseSettings}
 */
export const defaultClauseNodeSettings = {
  clauseTypes: [],
  workflows: [],
  libIDs: [],
  filter: "none",
  lock: "none",
  id: undefined,
  key: undefined,
  variants: [],
};
