/**
 * Removes `revisionIds` property from a `characterFormat` to prevent issues with serialization.
 *
 * @param {import("../../types/sfdt").CharacterFormat | undefined} characterFormat
 * @returns {import("../../types/sfdt").CharacterFormat | undefined}
 */
export function removeRevisionIdsFromCharacterFormat(characterFormat) {
  if (!characterFormat) return characterFormat;

  const { revisionIds, ...rest } = characterFormat;
  return rest;
}
