import React from "react";
import { Box, IconButton, InputBase, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import theme from "../../../theme/theme";

function RemindersSection({ tooltip, reminders, handleChangeReminder }) {
  const styles = {
    reminderInput: {
      backgroundColor: theme.palette.primary.contrastText,
      width: "35px",
      fontWeight: "700",
    },
    cancelReminders: {
      marginLeft: "20px",
      fontSize: "14px",
      padding: "4px 7px 4px 7px",
    },
  };

  const handleReminderInput = (val, type) => {
    if ((type === "update" && /^[0-9]+$/.test(val)) || val === "") {
      handleChangeReminder(val);
    } else if (type === "blur" && (["", "0"].includes(val) || val.length > 2)) {
      handleChangeReminder("7");
    }
  };

  return (
    <Tooltip arrow title={tooltip}>
      <Box
        mt={1}
        style={{
          width: "270px",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        Remind every&nbsp;
        <InputBase
          value={reminders}
          sx={styles.reminderInput}
          inputProps={{ style: { textAlign: "center" } }}
          onChange={(e) => handleReminderInput(e.target.value, "update")}
          onBlur={(e) => handleReminderInput(e.target.value, "blur")}
        />
        &nbsp;day{reminders === "1" ? "" : "s"}
        <IconButton
          size="small"
          sx={styles.cancelReminders}
          onClick={() => handleChangeReminder(null)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </Box>
    </Tooltip>
  );
}

export default RemindersSection;
