import {
  faLockKeyhole,
  faRotateLeft,
  faTimes,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { Fragment, useContext, useState } from "react";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { trunc } from "../utils";

/**
 * @typedef {object} LockBoxProps
 * @property {*} agreement
 * @property {*[]} parties
 */

/**
 * @param {LockBoxProps} props
 * @returns {React.JSX.Element}
 */
export default function LockBox({ agreement, parties }) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  // const [editor] = useLexicalComposerContext();

  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");

  const counterpartyNames = agreement.avOwners.map(
    (/** @type {string} */ versionOwner, /** @type {number} */ index) => {
      const legalName =
        Boolean(parties) &&
        Boolean(
          parties
            .filter(
              (/** @type {{ orgID: string; }} */ party) =>
                party.orgID === versionOwner
            )
            ?.at(0)
        )
          ? parties
              .filter(
                (/** @type {{ orgID: string; }} */ party) =>
                  party.orgID === versionOwner
              )
              ?.at(0)?.legalName
          : "Unknown entity";

      return (
        <Fragment key={index}>
          <br />- {trunc(legalName, 20)}
        </Fragment>
      );
    }
  );

  function closeDialog() {
    setOpenDialog(false);
    setComment("");
  }

  /**
   * @param {string} agreementId
   */
  async function retrieveAgreement(agreementId) {
    try {
      setIsLoading(true);

      const postRetrieveAgreementResponse = await axios.post(
        state.settings.api + `agr/${agreementId}/retrieve`,
        {
          comment,
        }
      );
      if (!postRetrieveAgreementResponse?.data?.success) {
        throw new Error("Error retrieving agreement.");
      }

      // await reinitializeAgreement(agreementId);
      window.location.reload();

      closeDialog();

      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "Agreement retrieved.",
          severity: "success",
        },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "Error retrieving agreement.",
          severity: "error",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  // /**
  //  * @param {string} agreementId
  //  */
  // async function reinitializeAgreement(agreementId) {
  //   const getAgreementVersionResponse = await axios.get(
  //     `${state.settings.api}agrv/${agreementId}`
  //   );
  //   if (!getAgreementVersionResponse?.data?.success) {
  //     throw new Error("Error retrieving agreement version.");
  //   }
  //   const [latestVersion] = getAgreementVersionResponse.data.data;
  //   dispatch({
  //     type: "INIT_VERSIONS",
  //     payload: {
  //       versions: getAgreementVersionResponse.data.data,
  //       active: { _id: latestVersion._id, editMode: "full" },
  //     },
  //   });

  //   const getAgreementDetailsResponse = await axios.get(
  //     state.settings.api + "agr/agrdetails/" + agreementId
  //   );
  //   if (!getAgreementDetailsResponse?.data?.success) {
  //     throw new Error("Error retrieving agreement details.");
  //   }
  //   const { ags, avs, parties, agrExec, workflows, params } =
  //     getAgreementDetailsResponse.data.data;
  //   dispatch({
  //     type: "INIT_AGRS",
  //     payload: ags,
  //   });
  //   dispatch({
  //     type: "INIT_AVS",
  //     payload: avs,
  //   });
  //   dispatch({
  //     type: "INIT_PARTIES",
  //     payload: parties,
  //   });
  //   dispatch({
  //     type: "UPDATE_AGREXEC",
  //     payload: agrExec,
  //   });
  //   dispatch({
  //     type: "INIT_WORKFLOWS",
  //     payload: workflows,
  //   });
  //   dispatch({
  //     type: "INIT_PARAMS_DOC",
  //     payload: params,
  //   });

  //   const [latestAgreementVersion] = avs;
  //   editor.setEditorState(
  //     editor.parseEditorState(latestAgreementVersion.content)
  //   );
  // }

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          zIndex: "100",
          right: "20px",
          bottom: "20px",
          width: "200px",
          borderRadius: "20px",
          padding: "30px 20px 25px 20px",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 24px 0px",
          backgroundColor: "rgba(255,255,255,0.7)",
          backdropFilter: "blur(10px)",
        }}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <FontAwesomeIcon
              icon={faLockKeyhole}
              style={{ color: theme.palette.grey[800], fontSize: "26px" }}
            />
          </Grid>

          <Grid item sx={{ mt: 2, mb: 1 }}>
            <Typography variant="body2" color="textSecondary" align="center">
              Awaiting response by
              {counterpartyNames.length > 1
                ? " counterparties "
                : " counterparty "}
            </Typography>
          </Grid>

          {state.user.role.name !== "Counterparty" && (
            <Grid item>
              <Button size="small" onClick={() => setOpenDialog(true)}>
                Retrieve
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>

      {openDialog && (
        <Dialog open={openDialog} onClose={closeDialog} fullWidth maxWidth="sm">
          <DialogTitle>Retrieve Agreement</DialogTitle>

          <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
            <IconButton onClick={closeDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{ padding: "4px 7px", fontSize: "20px" }}
              />
            </IconButton>
          </Box>

          <DialogContent>
            <Box px={4}>
              {isLoading ? (
                <Grid container direction="row" justifyContent="center">
                  <Grid item>
                    <CircularProgress size={50} />
                  </Grid>
                </Grid>
              ) : (
                <Box>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item>
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        color="orange"
                        size="2x"
                      />
                    </Grid>
                  </Grid>

                  <Grid container direction="row" mt={2}>
                    This may reveal edits and comments that the
                    {counterpartyNames.length > 1
                      ? " counterparties "
                      : " counterparty "}
                    did not intend to share with you yet. Please include a
                    message explaining why you are retrieving the agreement.
                  </Grid>

                  <Grid container direction="row" mt={3}>
                    <TextField
                      multiline
                      rows={4}
                      fullWidth
                      label="Add comment"
                      placeholder="Add note for counterparty..."
                      value={comment}
                      onChange={(event) => {
                        setComment(event.target.value);
                      }}
                    />
                  </Grid>
                </Box>
              )}
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={isLoading}
              disableElevation
              color="primary"
              variant="contained"
              onClick={() => retrieveAgreement(agreement._id)}
            >
              Retrieve&nbsp;&nbsp;
              <FontAwesomeIcon icon={faRotateLeft} />
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
