import { $getNearestNodeOfType } from "@lexical/utils";
import { $getSelection, $isRangeSelection, $isTextNode } from "lexical";
import {
  ClauseNode,
  CustomListItemNode,
  CustomTableCellNode,
} from "../../../../nodes";
import { $createClauseNode } from "../../../../nodes/ClauseNode";
import {
  $createCustomParagraphNode,
  CustomParagraphNode,
} from "../../../../nodes/CustomParagraphNode";
import { handleSelectionDelete } from "../../../../utils/handleSelectionDelete";
import { preventEventPropagation } from "../../../TrackChangesPlugin/useRedline/utils/preventEventPropagation";
import { propagateEvent } from "../../../TrackChangesPlugin/useRedline/utils/propagateEvent";
import { defaultClauseNodeSettings } from "../../constants/defaultClauseNodeSettings";
import { selectionIsAtBeginningOfInline } from "../../utils/selectionIsAtBeginningOfInline";
import { selectionIsAtBeginningOfTextNode } from "../../utils/selectionIsAtBeginningOfTextNode";
import { selectionIsAtEndOfInline } from "../../utils/selectionIsAtEndOfInline";
import { selectionIsAtEndOfTextNode } from "../../utils/selectionIsAtEndOfTextNode";

/**
 * @param {LexicalEditor} editor
 * @param {KeyboardEvent | null} event
 * @param {RedlineData} defaultRedlineData
 * @returns {boolean}
 */
export function enterCommandHandler(editor, event, defaultRedlineData) {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) return preventEventPropagation(event);

  if (selection.isCollapsed()) {
    const node = selection.anchor.getNode();
    const selectionParagraphNode = $getNearestNodeOfType(
      node,
      CustomParagraphNode
    );

    const selectionCustomListItemNode = $getNearestNodeOfType(
      node,
      CustomListItemNode
    );
    if (selectionCustomListItemNode) return propagateEvent();

    // If the selection is inside a table cell that means we do not have to do the clause
    // creation logic so we can just propagate the event and let the appropriate plugins
    // handle the enter behaviour.
    const selectionTableCell = $getNearestNodeOfType(node, CustomTableCellNode);
    if (selectionTableCell) return propagateEvent();

    const selectionClauseNode = $getNearestNodeOfType(node, ClauseNode);
    if (!selectionClauseNode) return preventEventPropagation(event);

    const newClauseNode = $createClauseNode(defaultClauseNodeSettings);
    const paragraphNode = selectionParagraphNode
      ? CustomParagraphNode.copy(selectionParagraphNode)
      : $createCustomParagraphNode();

    newClauseNode.append(paragraphNode);

    // Selection of type element occurs when there are no text nodes in an element node e.g., a
    // paragraph without text or an empty paragraph that has just been created from an enter press.
    if (
      selection.anchor.type === "element" &&
      selection.focus.type === "element"
    ) {
      selectionClauseNode.insertAfter(newClauseNode);
      newClauseNode.select();
    } else if (selectionIsAtBeginningOfInline(selection)) {
      selectionClauseNode.insertBefore(newClauseNode);
      selectionClauseNode.selectStart();
    } else if (selectionIsAtEndOfInline(selection)) {
      selectionClauseNode.insertAfter(newClauseNode);
      newClauseNode.select();
    }
    // Selection is in the middle of an inline.
    else {
      if ($isTextNode(node)) {
        // If selection is in the middle of the text node.
        if (
          !selectionIsAtBeginningOfTextNode(selection) &&
          !selectionIsAtEndOfTextNode(selection, node)
        ) {
          // If selection is in the middle of the text node we need to split it.
          const [, remainderNode] = node.splitText(
            ...selection.getCharacterOffsets()
          );
          const remainderNodeSiblings = remainderNode.getNextSiblings();
          paragraphNode.append(remainderNode, ...remainderNodeSiblings);

          selectionClauseNode.insertAfter(newClauseNode);
          newClauseNode.selectStart();
        } else {
          const remainderNodeSiblings = node.getNextSiblings();
          if (remainderNodeSiblings.length) {
            paragraphNode.append(...remainderNodeSiblings);
          } else {
            paragraphNode.append(node);
          }

          selectionClauseNode.insertAfter(newClauseNode);
          newClauseNode.selectStart();
        }
      } else {
        // TODO: Handle element node.
      }
    }
  } else {
    // Handle deletion of text nodes from the selection.
    handleSelectionDelete(selection, defaultRedlineData);
    // Call this function again to add the enter behaviour.
    enterCommandHandler(editor, event, defaultRedlineData);
  }

  return preventEventPropagation(event);
}
