import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { globalStore } from "../../../../../../state/store";
import theme from "../../../../../../theme/theme";

/**
 * @param {{ variant: ClauseVariant}} props
 * @returns {JSX.Element}
 */
export default function VariantsTabPanel({ variant }) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [anchorElement, setAnchorElement] = React.useState(
    /** @type {null | HTMLElement} */ (null)
  );
  const open = Boolean(anchorElement);

  /**
   * @param {React.MouseEvent<HTMLButtonElement>} event
   * @returns {void}
   */
  function handleClick(event) {
    setAnchorElement(event.currentTarget);
  }
  function handleClose() {
    setAnchorElement(null);
  }

  /**
   * @param {ClauseVariant["application"]["type"]} applicationType
   * @returns {string}
   */
  function getApplicationTypeLabel(applicationType) {
    switch (applicationType) {
      case "automatic": {
        return "Automatic";
      }

      case "manual": {
        return "Manual selection by user";
      }

      default: {
        throw new Error(
          `${applicationType} is not a valid clause variant application type.`
        );
      }
    }
  }

  /**
   * @param {ClauseVariant["visibility"]} applicationType
   * @returns {string}
   */
  function getVisibilityLabel(applicationType) {
    switch (applicationType) {
      case "notVisible": {
        return "Not Visible";
      }

      case "visible": {
        return "Visible";
      }

      default: {
        throw new Error(
          `${applicationType} is not a valid clause variant visibility.`
        );
      }
    }
  }

  return (
    <Grid container direction="column">
      <Grid item mt={2}>
        <Typography fontWeight="400" color={theme.palette.grey[500]}>
          Name
        </Typography>
      </Grid>

      <Grid item>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography fontWeight="400">{variant.name}</Typography>
          </Grid>

          <Grid item>
            <IconButton onClick={handleClick}>
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                fontSize="15px"
                style={{
                  padding: "0 4px",
                }}
              />
            </IconButton>

            <Menu anchorEl={anchorElement} open={open} onClose={handleClose}>
              <MenuItem onClick={handleClose}>Edit variant ...</MenuItem>
              <MenuItem onClick={handleClose}>Remove variant ...</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid>

      <Grid item mt={2}>
        <Typography fontWeight="400" color={theme.palette.grey[500]}>
          Description
        </Typography>
      </Grid>

      <Grid item>
        <Typography fontWeight="400" color={theme.palette.grey[600]}>
          {variant.description}
        </Typography>
      </Grid>

      <Grid item mt={2}>
        <Typography fontWeight="400" color={theme.palette.grey[500]}>
          When does it apply?
        </Typography>
      </Grid>

      <Grid item>
        <Typography fontWeight="400">
          {getApplicationTypeLabel(variant.application.type)}
        </Typography>
      </Grid>

      <Grid item mt={2}>
        <Typography fontWeight="400" color={theme.palette.grey[500]}>
          Visible for counterparties?
        </Typography>
      </Grid>

      <Grid item>
        <Typography fontWeight="400">
          {getVisibilityLabel(variant.visibility)}
        </Typography>
      </Grid>

      <Grid item mt={2}>
        <Typography fontWeight="400" color={theme.palette.grey[500]}>
          Topics
        </Typography>
      </Grid>

      <Grid item>
        <Grid container gap={1} mt={1}>
          {variant.topicsIds.map((topicId, index) => {
            const clauseTopic = state.clauseTypes.find(
              (/** @type {{ _id: string; }} */ ct) => ct._id === topicId
            );

            return (
              <Grid key={index} item>
                <Chip
                  label={clauseTopic.name}
                  color="primary"
                  sx={{ fontWeight: "bold" }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}
