import { $isListItemNode, $isListNode } from "@lexical/list";
import { $isParagraphNode } from "lexical";

/**
 * Returns the child nodes of a clause that can be appended to another clause. Ignores the ones
 * ones that cannot i.e., `ClauseNode`, `ParagraphNode`, `ListNode`, `ListItemNode`.
 *
 * @param {ClauseNode} clauseNode
 * @returns {LexicalNode[]}
 */
export function getAppendableClauseChildNodes(clauseNode) {
  const clauseNodeChildren = clauseNode.getChildren() || [];
  // If the Clause node does not have direct children or if they are greater than one
  // (something that should never happen as Clause nodes should only have one child),
  // we return an empty array.
  if (clauseNodeChildren.length !== 1) return [];

  // Get the first direct child of the clause.
  const [clauseNodeChild] = clauseNodeChildren;

  // Ignore the paragraph node and return its children.
  if ($isParagraphNode(clauseNodeChild)) {
    const children = clauseNodeChild.getChildren() || [];
    return children;
  }

  // Ignore the list and list item nodes and return the children of the last list item.
  if ($isListNode(clauseNodeChild)) {
    /** @type {LexicalNode} */
    let currentNode = clauseNodeChild;
    while ($isListNode(currentNode) || $isListItemNode(currentNode)) {
      [currentNode] = currentNode.getChildren() || [];
    }

    // Get that last list item.
    const parent = currentNode.getParent();
    if (!parent) return [];

    // Return its children.
    const children = parent.getChildren();
    return children;
  }

  // Table nodes and all other cases are handled here.
  return [];
}
