import { faFile } from "@fortawesome/pro-regular-svg-icons";
import {
  faArrowLeft,
  faArrowRight,
  faUpload,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import docx from "../../../assets/img/docx.png";
import pdf from "../../../assets/img/pdf.png";
import xlsx from "../../../assets/img/xlsx.png";
import { CanveoCircularProgress } from "../../../components";
import DialogFileConversionErrorDetails from "../../../components/dialogs/DialogFileConverionErrorDetails";
import { convertSfdtToLexical } from "../../../components/editor/utils";
import { globalStore } from "../../../state/store";
import theme from "../../../theme/theme";
import { randomString as generateRandomString } from "../../../utils";

const fileAppTypes = {
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

const fileSources = [
  { label: "Template by counterparty", value: "Counterparty" },
  {
    label: "Template by us",
    value: "Owner",
  },
  { label: "Other", value: "Other" },
];

/**
 * @typedef {*} SelectOrUploadTemplateProps
 */

/**
 * @param {SelectOrUploadTemplateProps} props
 * @returns {React.JSX.Element}
 */
export default function SelectOrUploadTemplate(props) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [userRole] = useState(state.user.role?.name);
  const [dropHover, setDropHover] = React.useState(false);

  const [templateSource] = React.useState("import");
  const [stagedUpload, setStagedUpload] = React.useState(
    /** @type {* | null} */ (null)
  );
  const [loading, setLoading] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(
    /** @type {string | null} */ (null)
  );

  /**
   * @param {{name: string; type: string;}} _
   */
  const getFileType = ({ name, type }) => {
    if (name?.endsWith(".pdf") && type === fileAppTypes.pdf) return "pdf";
    if (name?.endsWith(".doc") && type === fileAppTypes.doc) return "doc";
    if (name?.endsWith(".docx") && type === fileAppTypes.docx) return "docx";
    if (name?.endsWith(".xlsx") && type === fileAppTypes.xlsx) return "xlsx";

    return null;
  };

  const [conversionError, setConversionError] = React.useState(
    /** @type {Error | undefined} */ (undefined)
  );

  /**
   * @param {*} file
   */
  const handleUploadChange = async (file) => {
    try {
      setErrMsg(null);
      setLoading(true);

      const fileType = getFileType(file);
      if (!fileType) {
        setErrMsg("Unable to recognize the file type");
        setLoading(false);
        return;
      }

      const uploadSignedUrlResponse = await axios.post(
        state.settings.api + "upload/signedUrl",
        {
          contentType: file.type,
          bucketAlias: "documents",
        }
      );

      const uploadSignedUrl = uploadSignedUrlResponse.data.data.uploadSignedUrl;
      const fileName = uploadSignedUrlResponse.data.data.key;

      await fetch(uploadSignedUrl, { method: "PUT", body: file });

      let uploadFile = {
        fileType: fileType,
        file: fileName,
        fileName: file.name,
        uploadType:
          ["pdf"].includes(fileType) && props.isMain
            ? "backload" // A PDF file as main means: handled outside Canveo, set to InEffect
            : "import", // A PDF as exhibit means: attachment
        agrType: null,
        fileSource: fileSources[0], // "Template by counterparty" is always the default option.
        tempKey: generateRandomString(20),
      };

      if (fileType === "doc" || fileType === "docx") {
        const importResult = await axios.get(
          `${state.settings.api}document/import/${fileName}`
        );

        if (!importResult?.data?.data) {
          setErrMsg(
            "An error occurred while converting the file (interim step)"
          );
          setLoading(false);
          return;
        }

        const lexical = await convertSfdtToLexical(
          importResult.data.data,
          state
        );

        uploadFile = {
          ...uploadFile,
          // @ts-ignore
          content: lexical?.content ?? [],
          contentMetadata: {
            listsStructure: lexical.listsStructure,
          },
          sfdt: importResult.data.data,
          firstPageHeader: lexical.header,
          firstPageFooter: lexical.footer,
        };
      }

      setLoading(false);
      setStagedUpload(uploadFile);
    } catch (error) {
      setErrMsg("An error occurred while processing the Word document.");
      if (error instanceof Error) {
        setConversionError(error);
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUploadSubmit = () => {
    props.handleSelectOrUploadTemplate(
      props.isMain ? "main" : "exhibit",
      stagedUpload
    );
  };

  const isSubmitDisabled = () => {
    return !stagedUpload.playbookName;
  };

  return (
    <>
      {errMsg !== null && (
        <Grid item sx={{ my: 4 }}>
          <Typography align="center" color="error">
            {errMsg}
          </Typography>
        </Grid>
      )}

      <Grid
        item
        container
        sx={{ mt: userRole === "Counterparty" ? 0 : 2 }}
        justifyContent="center"
      >
        <Box>
          {loading ? (
            <Box
              sx={{
                my: 6,
                width: "fit-content",
                margin: "0 auto",
              }}
            >
              <CanveoCircularProgress />
            </Box>
          ) : stagedUpload !== null ? (
            <Grid
              container
              direction="column"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item>
                {stagedUpload.fileType ? (
                  <img
                    src={
                      ["pdf"].includes(stagedUpload.fileType)
                        ? pdf
                        : ["xlsx"].includes(stagedUpload.fileType)
                        ? xlsx
                        : docx
                    }
                    alt=""
                    height={30}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faFile}
                    size="3x"
                    color={theme.palette.grey[700]}
                  />
                )}
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" color={"textSecondary"} mt={1}>
                  {stagedUpload.fileName || "Empty Agreement"}
                </Typography>
              </Grid>

              <Grid item sx={{ mt: 2 }}>
                <TextField
                  required
                  label="Name"
                  placeholder="Enter playbook name ..."
                  variant="outlined"
                  onChange={(event) =>
                    setStagedUpload((/** @type {*} */ previous) => ({
                      ...previous,
                      playbookName: event.target.value,
                    }))
                  }
                />
              </Grid>

              <Grid
                item
                container
                direction="row"
                justifyContent={
                  templateSource !== "scratch" ? "space-between" : "flex-end"
                }
                mt={2}
              >
                {templateSource !== "scratch" && (
                  <Grid item>
                    <Button onClick={() => setStagedUpload(null)}>
                      <FontAwesomeIcon icon={faArrowLeft} />
                      &nbsp;&nbsp;Cancel
                    </Button>
                  </Grid>
                )}

                <Grid item>
                  <Button
                    variant="contained"
                    disableElevation
                    disabled={isSubmitDisabled()}
                    onClick={handleFileUploadSubmit}
                  >
                    Submit&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <FileUploader
              handleChange={handleUploadChange}
              name="uploadfile"
              types={
                props.isMain
                  ? ["doc", "docx"]
                  : [
                      "doc",
                      "docx",
                      // "pdf", "xlsx"
                    ]
              }
              label={"Upload or drop a file here"}
              maxSize={20}
              minSize={0}
              onDraggingStateChange={(/** @type {boolean} */ dragging) =>
                setDropHover(dragging)
              }
              hoverTitle={" "}
              children={
                <Box
                  sx={{
                    width: "320px",
                    height: "180px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    border: dropHover
                      ? "2px solid" + theme.palette.primary.main
                      : "1px dotted" + theme.palette.grey[300],
                    backgroundColor: dropHover
                      ? theme.palette.grey[200]
                      : theme.palette.grey[50],
                    padding: "30px",
                    fontSize: "14px",
                    fontWeight: "600",
                    borderRadius: "20px",
                  }}
                >
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{
                          color: theme.palette.primary.main,
                          fontSize: "30px",
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <Box
                        sx={{ mt: 1, display: "block", textAlign: "center" }}
                      >
                        <>
                          {dropHover
                            ? "Time to let the file go ..."
                            : "Upload or drop your file here ..."}
                          <br />
                          <Grid
                            container
                            direction="row"
                            spacing={1}
                            justifyContent="center"
                            sx={{ mt: 1 }}
                          >
                            <Grid item>
                              <img src={docx} alt="docx" width={20} />
                            </Grid>
                          </Grid>
                        </>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              }
            />
          )}
        </Box>
      </Grid>

      {conversionError && (
        <DialogFileConversionErrorDetails
          conversionError={conversionError}
          close={() => setConversionError(undefined)}
        />
      )}
    </>
  );
}
