/**
 * @param {*} agreement
 */
const getOrigin = (agreement) => {
  const originType = agreement?.uploadType?.toUpperCase() ?? "TEMPLATE";

  if (originType === "TEMPLATE") {
    return {
      template: `${agreement.agrTypeID?.shortName} (${agreement.reference})`,
    };
  }

  if (originType === "IMPORT") {
    return {
      import: `${agreement.fileName}#${agreement.file}`,
    };
  }

  if (originType === "SCRATCH") {
    return {
      scratch: "Blank page",
    };
  }

  throw new Error(`Invalid origin ${originType}`);
};

/**
 * @param {*} exhibitData
 * @param {*} creationDate
 * @param {*} userID
 * @param {*} orgID
 * @param {boolean} withId
 */
const getExhibit = (
  exhibitData,
  creationDate,
  userID,
  orgID,
  withId = false
) => {
  const origin = getOrigin(exhibitData);
  return {
    _id: withId ? exhibitData._id : undefined,
    parentID: exhibitData.parentID,
    versionType: ["pdf", "docx", "xlsx", "pptx"].includes(exhibitData.fileType) // AgreementVersion related
      ? exhibitData.fileType // This is an attachment
      : "canveo", // This is a Canveo Doc
    content: exhibitData.content,
    contentMetadata: exhibitData.contentMetadata,
    agrTypeID: exhibitData.agrTypeID,
    agrTitle: exhibitData.agrTitle,
    agrStatus: "Exhibit",
    orgs: exhibitData.organizations,
    ents: exhibitData.entities,
    collabs: [],
    signers: [],
    priority: exhibitData.priority,
    amendment: null,
    effectiveDate: null,
    renewalType: "n/a",
    effectiveTerm: null,
    expiryDate: null,
    sigConfig: null,
    customURL: null,
    whiteLabel: null,
    avOwners: [orgID],
    createdByOrg: orgID,
    owner: orgID,
    creationBy: userID,
    creationDate: creationDate,
    lastUpdateBy: userID,
    lastUpdateDate: creationDate,
    origin: origin,
    description: exhibitData.description,
    sfdt: exhibitData.sfdt,
    firstPageHeader: exhibitData.firstPageHeader,
    firstPageFooter: exhibitData.firstPageFooter,
    visibleTo: [orgID],
    fileSource: exhibitData.fileSource?.value,
    embeddedFileId: ["pdf", "xlsx", "pptx"].includes(exhibitData.fileType)
      ? exhibitData.file
      : null,
  };
};

export { getExhibit, getOrigin };
