import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { globalStore } from "../state/store";

/**
 * @param {string} [docID]
 * @param {boolean} [isTemplate]
 */
const useVersionService = (docID, isTemplate) => {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [loading, setLoading] = useState(false);

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!docID) return;

    if (isTemplate) {
      fetchTemplateVersions(docID);
    } else {
      fetchAgreementVersions(docID);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docID, isTemplate]);

  /**
   * @param {string} docID
   */
  const fetchTemplateVersions = async (docID) => {
    try {
      setLoading(true);
      const result = await axios.get(
        `${state.settings.api}template/versions/${docID}`
      );
      if (!result?.data?.success) {
        throw new Error("Error fetching template version.");
      }
      const firstVersion = result.data.data[0];
      dispatch({
        type: "INIT_WORKFLOWS",
        payload: firstVersion.workflows,
      });
      dispatch({
        type: "INIT_VERSIONS",
        payload: {
          versions: result.data.data,
          active: { _id: firstVersion._id, editMode: "full" },
        },
      });
    } catch (e) {
      // dispatch({
      //   type: "NEW_SNACKBAR",
      //   payload: {
      //     message: "Unable to load versions - contact Canveo Support",
      //     severity: "error",
      //   },
      // });
    } finally {
      setLoading(false);
    }
  };

  /**
   * @param {string} docID
   */
  const fetchAgreementVersions = async (docID) => {
    try {
      setLoading(true);
      const result = await axios.get(`${state.settings.api}agrv/${docID}`);
      if (!result?.data?.success) throw new Error("Error fetching version.");

      const firstVersion = result.data.data[0];
      const editMode = firstVersion.owner.find(
        (/** @type {{orgID: string}} */ o) => o.orgID === state.org._id
      )?.editMode;

      dispatch({
        type: "INIT_VERSIONS",
        payload: {
          versions: result.data.data,
          active: { _id: firstVersion._id, editMode },
        },
      });

      // dispatch({
      //   type: "INIT_WORKFLOWS",
      //   payload: {
      //     versions: result.data.data,
      //     active: { _id: firstVersion._id, editMode },
      //   },
      // });
    } catch (e) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "Unable to retrieve versions - contact Canveo Support",
          severity: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * @param {string} docID
   * @param {string} versionID
   * @param {boolean} isTemplate
   */
  const fetchVersion = async (docID, versionID, isTemplate) => {
    try {
      setLoading(true);

      const url = isTemplate ? "template" : "agrv";
      const result = await axios.get(
        `${state.settings.api}${url}/${docID}/${versionID}`
      );
      if (!result?.data?.success) throw new Error("Error fetching version.");

      return result.data.data;
    } catch (e) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message:
            "Unable to retrieve version content - contact Canveo Support",
          severity: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * @param {*[]} versions
   */
  const updateStateVersions = (versions) => {
    for (const version of versions) {
      const exists = state.drawerVersions.versions.find(
        (/** @type {*} */ v) => v._id === version._id
      );
      if (exists) {
        dispatch({
          type: "UPDATE_VERSION",
          payload: version,
        });
      } else {
        dispatch({
          type: "ADD_VERSION",
          payload: version,
        });
      }
    }
  };

  /**
   * @param {string} versionID
   * @param {*} data
   */
  const updateVersion = async (versionID, data) => {
    try {
      setLoading(true);
      const result = await axios.put(`${state.settings.api}agrv/${versionID}`, {
        versionData: data,
      });
      if (!result?.data?.success) throw new Error("Error updating version.");

      updateStateVersions([result.data.data]);

      const editMode = result.data.data.owner?.find(
        (/** @type {*} */ o) => o.orgID === state.org._id
      )?.editMode;

      dispatch({
        type: "CHANGE_ACTIVE",
        payload: {
          _id: result.data.data._id,
          editMode,
          forceReload: true,
          versionType: result.data.data.versionType,
          embeddedFileId: result.data.data.embeddedFileId,
        },
      });

      return result.data.data;
    } catch (e) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "Unable to update version data - contact Canveo Support",
          severity: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Duplicates version into a new one.
   *
   * @param {string} versionID
   * @param {*} newDetails
   * @param {boolean} isTemplate
   * @param {boolean} reloadWindow Whether or not window should reload after duplicating the version.
   */
  const duplicateVersion = async (
    versionID,
    newDetails,
    isTemplate,
    reloadWindow = true
  ) => {
    try {
      setLoading(true);

      const url = isTemplate ? "template/version" : "agrv/duplicate";

      const result = await axios.post(`${state.settings.api}${url}`, {
        versionID: versionID,
        newDetails: newDetails,
      });
      if (!result?.data?.success) throw new Error("Error duplicating version.");

      if (reloadWindow) {
        window.location.reload();
        return;
      }

      updateStateVersions(result.data.data);

      const newVersion = result.data.data[0];
      // dispatch({ type: "INIT_AVS", payload: [newVersion] });
      dispatch({ type: "INIT_AVS", payload: [newVersion] });

      // TODO: Make sure we are updating the state with the new workflows.
      if (newVersion.workflows) {
        dispatch({
          type: "INIT_WORKFLOWS",
          payload: newVersion.workflows,
        });
      }

      const editMode = isTemplate
        ? "full"
        : newVersion.owner.find(
            (/** @type {{orgID: string}} */ o) => o.orgID === state.org._id
          )?.editMode;

      dispatch({
        type: "CHANGE_ACTIVE",
        payload: {
          _id: newVersion._id,
          editMode,
          forceReload: true,
          versionType: newVersion.versionType,
          embeddedFileId: newVersion.embeddedFileId,
        },
      });

      setSearchParams({ openedDrawer: "versions" });

      return result.data.data;
    } catch (e) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "Unable to duplicate version - contact Canveo Support",
          severity: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * @param {string} versionID
   * @param {boolean} isTemplate
   */
  const deleteVersion = async (versionID, isTemplate) => {
    try {
      setLoading(true);

      const url = isTemplate ? "template/version/delete" : "agrv/delete";
      const result = await axios.put(
        `${state.settings.api}${url}/${versionID}`
      );
      if (!result?.data?.success) throw new Error("Error deleting version");

      dispatch({
        type: "DELETE_VERSION",
        payload: result.data.data[0],
      });

      updateStateVersions(result.data.data.slice(1));

      return result.data.data;
    } catch (e) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "Unable to delete version - contact Canveo Support",
          severity: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * @param {string} agreementID
   * @param {*} data
   */
  const uploadSignedVersion = async (agreementID, data) => {
    try {
      setLoading(true);
      const result = await axios.post(
        `${state.settings.api}agr/${agreementID}/uploadSigned`,
        data
      );
      if (!result?.data?.success) {
        throw new Error("Error uploading signed version.");
      }

      updateStateVersions([result.data.data].flat());

      if (data.source === "new") {
        const editMode = result.data.data[0].owner.find(
          (/** @type {{orgID: string}} */ o) => o.orgID === state.org._id
        )?.editMode;
        dispatch({
          type: "CHANGE_ACTIVE",
          payload: {
            _id: result.data.data[0]._id,
            editMode,
            forceReload: true,
            versionType: result.data.data[0].versionType,
            embeddedFileId: result.data.data[0].embeddedFileId,
          },
        });
      }

      return result.data.data;
    } catch (e) {
      console.log(e);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "Unable to create signed version - contact Canveo Support",
          severity: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * @param {string} agreementID
   * @param {*} data
   * @param {boolean} fromAgreement Whether the upload version was triggered from within an agreement or outside.
   */
  const uploadVersion = async (agreementID, data, fromAgreement = true) => {
    try {
      setLoading(true);
      const agreementResult = await axios.get(
        state.settings.api + "agr/" + agreementID
      );

      const agreement = agreementResult?.data?.data;
      if (!agreement) {
        setLoading(false);
        return;
      }

      agreement.avOwners.push(agreement.owner);
      const updatedAgr = {
        ...agreement,
        avOwners: agreement.avOwners.filter(
          (/** @type {{orgID: string}} */ o) => o !== data.source.orgID
        ),
      };

      await axios.put(state.settings.api + "agr/" + agreementID, {
        agr: updatedAgr,
      }); // Update the Agreement/Exhibit in the Database

      const result = await axios.post(state.settings.api + "agrv/return", {
        newAvDetail: {
          ...data,
          owner: [{ orgID: state.org._id, editMode: "full" }],
          agreementID: agreementID,
        },
      });
      if (!result?.data?.success) {
        throw new Error("Error returning agreement version.");
      }

      if (fromAgreement) {
        updateStateVersions([result.data.data].flat());

        const editMode = result.data.data[0].owner.find(
          (/** @type {{orgID: string}} */ o) => o.orgID === state.org._id
        )?.editMode;
        dispatch({
          type: "CHANGE_ACTIVE",
          payload: {
            _id: result.data.data[0]._id,
            editMode,
            forceReload: true,
            versionType: result.data.data[0].versionType,
            embeddedFileId: result.data.data[0].embeddedFileId,
          },
        });

        const getAgreementDetailsResponse = await axios
          .get(state.settings.api + "agr/agrdetails/" + agreementID)
          .catch((err) => {
            console.log(
              "err while loading! todo err handling and signalling",
              err
            );
          });
        if (!getAgreementDetailsResponse?.data?.success) {
          throw new Error("Error getting agreement details.");
        }

        dispatch({
          type: "INIT_AGRS",
          payload: getAgreementDetailsResponse.data.data.ags,
        });
      }

      return result.data.data;
    } catch (e) {
      console.log(e);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "Unable to create signed version - contact Canveo Support",
          severity: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * @param {string} agreementId
   * @param {string} organizationId
   */
  const getLatestAgreementVersionByOrganization = async (
    agreementId,
    organizationId
  ) => {
    try {
      setLoading(true);

      const latestAgreementVersionByOrganizationResponse = await axios.get(
        state.settings.api + `agrv/${agreementId}/org/${organizationId}/latest`
      );
      if (!latestAgreementVersionByOrganizationResponse?.data?.success) {
        throw new Error("Error getting latest agreement version.");
      }

      const latestAgreementVersionByOrganization =
        latestAgreementVersionByOrganizationResponse.data.data;

      return latestAgreementVersionByOrganization;
    } catch (error) {
      console.log(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message:
            "Unable to get latest agreement version - contact Canveo Support",
          severity: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    fetchVersion,
    updateVersion,
    duplicateVersion,
    deleteVersion,
    uploadSignedVersion,
    uploadVersion,
    getLatestAgreementVersionByOrganization,
  };
};

export default useVersionService;
