import React, { useContext } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import RemovePartyConfirmation from "../RemovePartyConfirmation";
import { globalStore } from "../../state/store";

function DialogRemoveParty({ open, party, handleRemove, handleClose }) {
  const [state] = useContext(globalStore);

  return (
    <Dialog open={!!open} onClose={handleClose} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>
      <DialogTitle>Remove Counterparty</DialogTitle>
      <DialogContent style={{ paddingTop: "20px" }}>
        <RemovePartyConfirmation
          legalName={party?.legalName}
          isOwnOrganization={party?.orgID === state.org._id}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={{ mx: 1, mr: "auto" }}
          disableElevation
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="primary"
          sx={{ mx: 1 }}
          disableElevation
          onClick={handleRemove}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogRemoveParty;
